import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Input, DatePicker, Select } from 'antd'
import { useQueryClient } from 'react-query'
import moment from 'moment'
import * as _ from 'lodash'
import { ResourcesAPI } from '../../../../shared/api/resources'
import { BuildingsAPI } from '../../../../shared/api/buildings'
import { MultiInput, Toast, Switch, Modal } from '../../../../shared/components'
import { Resource, Building, Group, ResourceObject, Contract } from '../../../../shared/models/resource'
import { EditResourcesStrings, SharedOrganizationsStrings } from '../../../../shared/strings/OrganizationsContent'
import { ReactComponent as SearchIcon } from '../../../../assets/icon-search-find.svg'
import BuildingResourceList from './BuildingResourceList'
import ProductOptions from './ProductOptions'
import { GroupDevice, getDevices } from '../../../../shared/utils/deviceParser'
import {
  FormContainer,
  InputsContainer,
  Line,
  LineTop,
  MessageContainer,
  TypeSelector,
  InputRowContainer,
  SwitchContainer,
  SwitchLabel,
  CancelButton,
  BackButtonContainer,
  SearchDeviceInputCol,
  SaveButton,
  ResourceButtonContainer,
  CancelResourceAddButton,
  BidInputContainer,
  ProductSelectionContainer,
  SearchButton,
  AddManuallyButton,
  ButtonsContainer,
  DatePickerContainer,
  MultiInputContainer,
  UpdateCounter,
  WarningLabel,
  KenProgressBar,
  OkButton,
} from './Styles'
import { OrgErrors } from '../../../../shared/strings/errorMsg'

const { Option } = Select

type Props = {
  organizationId: number
  onCloseAdd: () => void
  isEdit?: boolean
  editMode?: string
  resource?: ResourceObject
  building?: Building
}

const resourceWithNoContract: string[] = []

const AddResourcesForm: FC<Props> = ({ organizationId, onCloseAdd, isEdit, resource, editMode }) => {
  const [date, setDate] = useState<string | undefined>('')
  const [isLoading, setIsLoading] = useState(false)
  const [apiErrors, setAPIErrors] = useState<string[]>()
  const [inputErrors, setInputErrors] = useState<string[]>()
  const [searchError, setSearchError] = useState<string>('')
  const [isSuccessVisible, setIsSuccessVisible] = useState(false)
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [isInputErrorVisible, setIsInputErrorVisible] = useState(false)
  const [resourceType, setResourceType] = useState<string>('')
  const [isExpires, setIsExpiry] = useState(false)
  const [isMultiInput, setIsMultiInput] = useState(false)
  const [resources, setResources] = useState<string[]>([])
  const [getResponse, setGetResponse] = useState(false)
  const [tempResources, setTempResources] = useState<string[]>([])
  const [isDevicesFound, setIsDevicesFound] = useState(true)
  const [multiInputError, setMultiInputError] = useState('')
  const queryClient = useQueryClient()
  const [isAddResourceManual, setAddResourceManual] = useState(false)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [productsSelected, setProductsSelected] = useState<Array<string>>([])
  const [expiredDate, setExpiredDate] = useState<Array<string>>([])
  const [resourceId, setResourceId] = useState<string>('')
  const [searchDevice, setSearchDevice] = useState<string>('')
  const [isSaving, setIsSaving] = useState(false)
  const [isBuildingLatest, setIsBuildingLatest] = useState(true)
  const [isDeviceSearchErrorVisible, setIsDeviceSearchErrorVisible] = useState(false)
  const [building, setBuilding] = useState<Building>()
  const [contract, setContract] = useState<Contract>()
  const [contractError, setContractError] = useState<string>('')
  const [isContractError, setIsContractError] = useState(false)
  const [isProductsClear, setIsProductsClear] = useState(false)
  const [isAddWithoutExpiryError, setAddWithoutExpiryError] = useState(false)
  const [contractDatForSelectedProducts, setContractDatForSelectedProducts] = useState<any>({})
  const [expiredDateManual, setExpiredDateManual] = useState<string>('')
  const [noContractFound, setNoContractFound] = useState(false)
  const [productContractMap, setProductContractMap] = useState({})
  const [currentProduct, setCurrentProduct] = useState<string>('')
  const [ownProductDateMap, setOwnProductDateMap] = useState<any>({})
  const [ownProductDateMapv1, setOwnProductDateMapv1] = useState<any>({})
  const [multiKenProgress, setMultiKenProgress] = useState<number>(0)
  const [processedKens, setProcessedKens] = useState<number>(0)
  const [kensToProcess, setKensToProcess] = useState<number>(0)
  const [kenListWithNoContract, setKenListWithNoContract] = useState<string>('')
  const [kenListWithNoContractVisible, setKenListWithNoContractVisible] = useState<boolean>(false)

  let finalContractDatForSelectedProducts: any = {}
  //current product like serviceInfo, servieInfo2 ,productContractMap = {serviceInfo: 0, serviceInfo2:1}
  useEffect(() => {
    if (isEdit && editMode === 'single') {
      const type: string = resource?.resourceId.type || ''
      const id: string = resource?.resourceId.uniqueId || ''
      setResourceType(type)
      setResourceId(id?.trim())
    }

    console.log('resourceeee::', resource)
    //init(resource)
  }, [])

  useEffect(() => {
    console.log('useeffect for product select')
    // search contract for the first time
    if (contract === undefined && productsSelected.length > 0 && !isMultiInput && resourceId?.length > 0) {
      setGetResponse(true)
      checkContractExpiry(resourceType, resourceId).then((contractData) => {
        setContract(contractData)
        console.log('contract data', contractData)
      })
    }
  }, [productsSelected])

  const cancelAdd = () => {
    onCloseAdd()
    setResources([])
    setIsMultiInput(false)
    setProductsSelected([])
    setResourceType('')
    setGetResponse(false)
  }

  useEffect(() => {
    if (contract) {
      // setContract(contract)
      if (contract?.message) {
        setIsContractError(true)
        setContractError(contract.message)
        setNoContractFound(true)
        setGetResponse(false)
        return
      }

      if (contract?.contractData?.length === 0) {
        setNoContractFound(true)
      }
      setGetResponse(false)
      addExpiryDate(productsSelected, resourceId)
      console.log('ownProductDateMap:::', ownProductDateMap)
    }
  }, [contract])

  useEffect(() => {
    init(resource)
  }, [ownProductDateMap])

  const removeV1 = (value: string) => {
    if (value.includes('v1')) {
      value = value.split('-')[0]
    }
    return value
  }

  function addExpiryDate(products: string[], expiryResourceId: string) {
    if (contract) {
      if (products.length === 0) {
        setIsExpiry(false)
        setIsDatePickerOpen(false)
        onDateChange('', '')
        setIsContractError(false)
        setAddWithoutExpiryError(false)
        return
      }

      console.log('product', products, expiryResourceId)
      const temp = { ...contractDatForSelectedProducts }
      const tempMap = {}
      for (let productIndex = 0; productIndex < products.length; productIndex++) {
        // Step 1: Filter and sort contracts for the current product
        let myResourceContract
        if (contract && Array.isArray(contract.contractData)) {
          myResourceContract = contract.contractData
            .filter((contractItem) => contractItem.product === products[productIndex])
            .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())[0] // Get the contract with the latest endDate
        }

        // Step 2: Handle if no contract is found but the temp already has the expiry resource data
        if (!myResourceContract && temp[expiryResourceId]) {
          let newProduct = products[productIndex]
          if (products[productIndex]?.includes('v1')) {
            newProduct = products[productIndex].split('-')[0]
          }
          myResourceContract = temp[expiryResourceId][newProduct]
          tempMap[products[productIndex]] = 1
        }

        // Step 3: Handle product removal from selected products
        if (Object.keys(contractDatForSelectedProducts).length > 0) {
          for (const currentProduct in temp[expiryResourceId]) {
            let newProduct: string = currentProduct
            if (currentProduct.includes('v1')) {
              newProduct = currentProduct.split('-')[0]
            }
            console.log('products:::', products, currentProduct, newProduct)
            if (!products.includes(newProduct)) {
              delete temp[expiryResourceId][currentProduct]
            }
          }
        }

        // Step 4: Update state with the found contract or set an error if no contract is found
        if (myResourceContract) {
          if (!temp[expiryResourceId]) {
            temp[expiryResourceId] = {}
          }
          // Add the found contract to the expiry data
          temp[expiryResourceId][myResourceContract.product] = { ...myResourceContract }
          setIsExpiry(false)
          onDateChange('', myResourceContract.endDate)
        } else {
          tempMap[products[productIndex]] = 1
          setIsContractError(true)
          setContractError(
            `No valid contract found for resource ${expiryResourceId} for product ${products[productIndex]}. You can add it manually.`
          )
          setIsExpiry(false)
          onDateChange('', '')
        }
      }

      console.log('temp::', temp)
      setContractDatForSelectedProducts(temp)
      setProductContractMap({ ...productContractMap, ...tempMap })
    }
  }

  const init = (resource: any) => {
    if (resourceId?.length === 0) {
      return
    }
    const temp = { ...contractDatForSelectedProducts }
    for (const key in ownProductDateMap) {
      const keyProd = removeV1(key)
      console.log('Now Change Date')
      if (!temp[resourceId]) {
        temp[resourceId] = {}
      }
      if (!temp[resourceId][keyProd]) {
        temp[resourceId][keyProd] = {}
      }
      temp[resourceId][keyProd] = {
        contractType: null,
        endDate: moment(ownProductDateMap[keyProd]).format(EditResourcesStrings.dateFormat),
        product: keyProd,
      }
      console.log(
        'contractDatForSelectedProducts111: ',
        temp,
        date,
        moment(date).format(EditResourcesStrings.dateFormat)
      )
    }
    setContractDatForSelectedProducts(temp)
  }

  const resetFlags = () => {
    setIsSuccessVisible(false)
    setIsLoading(false)
    setAPIErrors([])
    setInputErrors([])
    setIsInputErrorVisible(false)
    setIsErrorVisible(false)
    setIsDevicesFound(true)
    setExpiredDate([])
  }

  const onTypeChange = (data: any) => {
    setResourceType(data)
  }

  const updateProductExpiryDate = (date: any) => {
    if (resourceId?.length === 0) {
      return
    }
    if (date && currentProduct?.length > 0) {
      if (date == '' && !ownProductDateMap[currentProduct]) {
        return
      }

      if (date == '' && ownProductDateMap[currentProduct]) {
        date = ownProductDateMap[currentProduct]
      }
      if (productContractMap[currentProduct] === 1) {
        const temp = { ...contractDatForSelectedProducts }
        if (!temp[resourceId]) {
          temp[resourceId] = {}
        }
        if (!temp[resourceId][currentProduct]) {
          temp[resourceId][currentProduct] = {}
        }
        temp[resourceId][currentProduct] = {
          contractType: null,
          endDate: moment(date).format(EditResourcesStrings.dateFormat),
          block: 0,
          product: currentProduct,
        }
        setContractDatForSelectedProducts(temp)
      }
    }
  }

  const onDateChange = (date: any, dateString: any) => {
    updateProductExpiryDate(date)
    setDate(dateString)
    setIsDatePickerOpen(false)
    // check if no contract set it manually
    if (noContractFound) {
      setExpiredDateManual(dateString)
    }
    resetFlags()
  }

  const checkIfResourceExists = async (resourceType: string, resourceId: string) => {
    try {
      await ResourcesAPI.getResourcesByTypeAndId(resourceType, resourceId)
      return true
    } catch (e) {
      if (e.response.status === 404) {
        return false
      } else {
        throw new Error(EditResourcesStrings.getResourceError)
      }
    }
  }

  const checkContractExpiry = async (resourceType: string, resourceId: string) => {
    try {
      const response = await ResourcesAPI.getResourceContractExpiry(resourceType, resourceId)
      return response
    } catch (e: any) {
      if (e.response.status === 400) {
        setContractError('error')
        return e.response.data
      } else {
        throw new Error(EditResourcesStrings.getContractError)
      }
    }
  }

  function disabledDate(current: any) {
    return current && current <= moment().endOf('day')
  }

  function getProductsFromVersion(version: string[] | undefined) {
    const products: string[] | undefined = []
    if (version) {
      version.forEach((v) => {
        if (!v.includes('v1')) {
          products.push(v)
        } else {
          products.push(v.split('-')[0])
        }
      })
      return products
    }
    return []
  }

  function getDateFromResource(resource: Resource) {
    if (resource?.version && resource?.expiredDate) {
      const obj = {}
      const objv1 = {}
      resource?.version?.map((apiName: string) => {
        resource?.expiredDate?.map((str: string) => {
          if (str.search(apiName) > -1) {
            obj[apiName] = str.split('--')[1]
            const apiNameV1 = removeV1(apiName)
            objv1[apiNameV1] = obj[apiName]
          }
        })
      })
      console.log('OOOBBJJ:', obj, objv1)
      setOwnProductDateMap({ ...obj })
      setOwnProductDateMapv1({ ...objv1 })
      const tempDate: string[] = Object?.values(obj)?.slice(-1) as string[]
      if (tempDate?.length > 0 && tempDate[0]?.length) {
        console.log('tempDate:', tempDate)
        setDate(tempDate[0])
      }
    }
  }

  useEffect(() => {
    if (resource?.products) {
      getDateFromResource(resource)
      // const products = getProductsFromVersion(resource?.version)
      // setProductsSelected(products)
    }
  }, [resource])

  async function setProductExpiryDate(products: string[], expiryResourceType: string, expiryResourceId: string) {
    if (expiryResourceId) {
      addExpiryDate(products, expiryResourceId)
    }
  }

  async function setProductExpiryDateMulti(expiryResourceType: string, expiryResourceId: string): Promise<any> {
    let contractDataMulti: Contract
    if (expiryResourceId) {
      // get contract if not there
      try {
        contractDataMulti = await checkContractExpiry(expiryResourceType, expiryResourceId)
      } catch {
        setContractError('Invalid request.')
        setIsContractError(true)
        return
      }

      // if contract
      if (contractDataMulti) {
        if (
          (contractDataMulti?.message && resourceType === 'building') ||
          (contractDataMulti?.contractData?.length === 0 && resourceType === 'ken')
        ) {
          if (!resourceWithNoContract.includes(expiryResourceId)) {
            resourceWithNoContract.push(expiryResourceId)
          }
          setNoContractFound(true)
          return contractDataMulti
        }

        const temp = {}

        for (let productIndex = 0; productIndex < productsSelected.length; productIndex++) {
          // Step 1: Filter and sort contracts for the current product
          let myResourceContract
          if (contractDataMulti && Array.isArray(contractDataMulti.contractData)) {
            myResourceContract = contractDataMulti.contractData
              .filter((contractItem) => contractItem.product === productsSelected[productIndex])
              .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())[0] // Get the contract with the latest endDate
          }

          // Step 2: Handle found contract
          if (myResourceContract) {
            if (!temp[expiryResourceId]) {
              temp[expiryResourceId] = {}
            }
            temp[expiryResourceId][myResourceContract.product] = myResourceContract // Store the contract for the product
            setIsExpiry(false)
          } else {
            // Step 3: Handle case where no contract is found
            setIsContractError(true)
            setContractError(
              `No valid contract found resource ${expiryResourceId} for product ${productsSelected[productIndex]}`
            )
            setIsExpiry(false)
          }
        }
        setContractDatForSelectedProducts({ ...contractDatForSelectedProducts, ...temp })
        finalContractDatForSelectedProducts = { ...finalContractDatForSelectedProducts, ...temp }
      }
      return contractDataMulti
    }
    return
  }

  const addResource = async (id: string) => {
    const expiryDateForProducts: any[] = []

    if (resourceType == 'building') {
      const exp = new RegExp('^[a-zA-Z0-9]{0,12}$')
      if (!exp.test(id)) {
        throw new Error('Invalid building format')
      }
    } else if (resourceType == 'ken') {
      const exp = new RegExp('^[0-9]{0,12}$')
      if (!exp.test(id)) {
        throw new Error('Invalid ken format')
      }
    }

    if (noContractFound) {
      for (let i = 0; i < productsSelected.length; i++) {
        expiryDateForProducts.push(expiredDateManual)
      }
    } else {
      productsSelected.forEach((product) => {
        expiryDateForProducts.push(contractDatForSelectedProducts[id][product].endDate)
      })
    }

    const body: Resource = {
      products: productsSelected,
      expiredDate: expiryDateForProducts,
      resourceId: { type: resourceType, uniqueId: id },
    }
    const resourceExists = await checkIfResourceExists(resourceType, id)
    if (!resourceExists) {
      console.log('checking date', date)
      console.log('checking expiryDateForProducts', expiryDateForProducts)
      await ResourcesAPI.createResource({
        id: { type: resourceType, uniqueId: id, expiredDate: date },
        info: { address: JSON.stringify({}) },
      })
    }
    await ResourcesAPI.link(organizationId, body)
  }

  const onResouceInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setResourceId(event.target.value?.trim())
  }

  const onSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchDevice(event.target.value.toLowerCase())
  }

  const onValidationError = (error: string) => {
    setMultiInputError(error)
  }

  const onInputChange = () => {
    setMultiInputError('')
  }

  async function promiseAllInBatches(
    task: (resourceType: string, value: string) => void,
    items: string[],
    batchSize: number,
    resourceType: string
  ) {
    let position = 0
    let results: any = []
    while (position < items.length) {
      const itemsForBatch = items.slice(position, position + batchSize)
      const batchResult = await Promise.all(itemsForBatch?.map((item: string) => task(resourceType, item)))
      console.log('batchResult:', batchResult)
      results = [...results, ...batchResult]
      position += batchSize
      setMultiKenProgress((position / items.length) * 100)
      setProcessedKens(position)
    }
    return results
  }

  const onValuesChange = async (values: string[]) => {
    setResources(values)
    const KEN_LIMIT = 60
    if (values && values.length > KEN_LIMIT) {
      setContractError(`you can not enter more than ${KEN_LIMIT} kens`)
      setIsContractError(true)
      return
    }
    // check if expR does not match with resources, set no contract false
    resourceWithNoContract.forEach((removedResource) => {
      if (!values.includes(removedResource)) {
        setNoContractFound(false)
      }
    })
    const noContractKens: number[] = []
    const kenWithContracts: string[] = []
    try {
      setGetResponse(true)
      setIsContractError(false)
      const batchSizeValue = 4
      setKensToProcess(values?.length ? values?.length : 0)
      finalContractDatForSelectedProducts = {}
      const results: any[] = await promiseAllInBatches(setProductExpiryDateMulti, values, batchSizeValue, resourceType)
      setContractDatForSelectedProducts(finalContractDatForSelectedProducts)
      results.forEach((oneResult: any, index: number) => {
        if (!oneResult?.contractData?.length) {
          noContractKens.push(values[`${index}` as string])
        } else {
          kenWithContracts.push(values[`${index}` as string])
        }
      })

      if (noContractKens.length > 0 && noContractKens.length !== values.length) {
        setIsContractError(true)
        setKenListWithNoContractVisible(true)
        setKenListWithNoContract(noContractKens.join(','))
        setContractError(OrgErrors.no_valid_contract_add_seperatly)
        setNoContractFound(false)
        setResources(kenWithContracts)
      } else if (noContractKens.length === values.length) {
        setResources(values)
        setIsContractError(true)
        setContractError(OrgErrors.no_contracts_for_any_ken)
      } else {
        setResources(values)
      }
      setGetResponse(false)
    } catch (e: any) {
      setGetResponse(false)
      setContractError('Invalid request.')
      setIsContractError(true)
    }
    setTempResources(values)
    resetFlags()
    return
  }

  const validate = (value: string) => {
    if (!value) {
      return EditResourcesStrings.resourceCannotBeEmpty
    }
    return
  }

  const onSearchKen = async () => {
    setIsDevicesFound(true)
    setBuilding(undefined)
    setIsSaving(true)
    try {
      const res = await BuildingsAPI.getDeviceMappingByKen(searchDevice)
      if (res.thingName != null) {
        const inventoryRes = await ResourcesAPI.deviceSearchByThingName(res.thingName)
        const devices = getDevices(inventoryRes)

        const buildingContract = await checkContractExpiry('ken', searchDevice)
        let isLatest = false
        const building: Building = {
          id: res.buildingId,
          groups: [],
          address: {},
        }
        if (devices === null) {
          setSearchError('No device found in Inventory')
          setIsDeviceSearchErrorVisible(true)
        } else if (typeof devices === 'number') {
          if (devices > 0) {
            const group: Group = {
              id: '1',
              deviceId: '',
              kens: [],
              type: '',
            }
            res.businessAssetNumbers.forEach((ken) => {
              group.kens.push({ id: ken.replace('ken', '') })
            })
            building.groups.push(group)
          } else {
            setIsDevicesFound(false)
          }
        } else {
          devices.forEach((device: GroupDevice) => {
            const group: Group = {
              id: device.group_id,
              deviceId: device.device_id,
              kens: [],
              type: device.type_id,
            }
            device.ken_ids.forEach((ken) => {
              group.kens.push({ id: ken.replace('ken', '') })
            })
            building.groups.push(group)
          })
          building.address = devices[0].address
          isLatest = true
        }
        setIsBuildingLatest(isLatest)
        setBuilding(building)
        setContract(buildingContract)
        setIsSaving(false)
      }
    } catch (e) {
      if (e instanceof Error) {
        setSearchError(e.message.toString())
        setIsDeviceSearchErrorVisible(true)
      }
      setIsSaving(false)
    }
  }

  const getMultiInputView = () => {
    return (
      <>
        <InputRowContainer $isMultiInput={true}>
          <ProductSelectionContainer $isMultiInput={true}>
            <ProductOptions
              onChange={(products) => {
                setProductsSelected(products)
              }}
              defaultProducts={getProductsFromVersion(resource?.version)}
              resourceType={resource?.resourceId.type ?? resourceType}
              isClear={isProductsClear}
            />
          </ProductSelectionContainer>
          {noContractFound === true && (
            <SwitchContainer>
              <SwitchLabel className={isExpires ? '' : OrgErrors.switch_disabled}>Expires</SwitchLabel>
              <Switch
                id="only-customers-toggle-js"
                checked={isExpires}
                onChange={() => {
                  setIsExpiry(!isExpires)
                  setIsDatePickerOpen(true)
                }}
              />
              <DatePickerContainer>
                <DatePicker
                  onChange={onDateChange}
                  disabled={!isExpires}
                  disabledDate={disabledDate}
                  value={date !== '' ? moment(date) : null}
                />
              </DatePickerContainer>
            </SwitchContainer>
          )}
        </InputRowContainer>
        <InputRowContainer>
          {multiKenProgress > 0 && multiKenProgress < 100 && <KenProgressBar percent={Math.floor(multiKenProgress)} />}
        </InputRowContainer>
        <InputRowContainer>
          {multiKenProgress > 0 && multiKenProgress < 100 && (
            <div>
              {processedKens}/{kensToProcess} processed
            </div>
          )}
        </InputRowContainer>
        <InputRowContainer>
          <MultiInputContainer>
            <MultiInput
              values={resources}
              validate={validate}
              onValuesChange={onValuesChange}
              onValidationError={onValidationError}
              onInputChange={onInputChange}
              disabled={isLoading || !resourceType || getResponse}
            />
          </MultiInputContainer>
        </InputRowContainer>
      </>
    )
  }

  const getFilteredDate = (date: string | undefined) => {
    const newDate = date !== '' ? moment(date) : null
    console.log('date::', date, newDate)
    return newDate
  }

  const getResourceAddForm = (resource?: ResourceObject) => {
    return (
      <>
        <LineTop />
        <FormContainer>
          <WarningLabel>If no valid contract found, set the date manually.</WarningLabel>
          <InputRowContainer>
            <TypeSelector
              id="search-resource-type-selector-js"
              defaultValue={resource?.resourceId.type ?? 'Select Type'}
              onChange={onTypeChange}
              disabled={isLoading}
              getPopupContainer={(trigger) => trigger.parentNode}
              value={resourceType}
            >
              <Option id="selector-building-option-js" value="building">
                {'Building'}
              </Option>
              <Option id="selector-ken-option-js" value="ken">
                {'Ken'}
              </Option>
            </TypeSelector>
            {!isEdit && (
              <>
                <SwitchLabel style={{ marginLeft: '10px' }} className={isExpires ? '' : OrgErrors.switch_disabled}>
                  Multi input
                </SwitchLabel>
                <Switch
                  id="only-customers-toggle-js"
                  checked={isMultiInput}
                  onChange={() => {
                    isMultiInput ? setResources([]) : setResourceId('')
                    setIsMultiInput(!isMultiInput)
                    resetFlags()
                  }}
                />
              </>
            )}
          </InputRowContainer>
          <>
            {isMultiInput && getMultiInputView()}
            {!isMultiInput && (
              <InputRowContainer>
                <BidInputContainer>
                  <Input
                    id="search-organization-input-js"
                    style={{ width: '150px' }}
                    placeholder={resource?.resourceId.uniqueId ?? 'enter resource id'}
                    onChange={onResouceInputChange}
                    disabled={isEdit && true}
                    value={resourceId}
                  />
                </BidInputContainer>
                <ProductSelectionContainer>
                  <ProductOptions
                    onChange={(products) => {
                      console.log('selected Products:', products)
                      // const difference: string[] = productsSelected.filter((x: string) => !products.includes(x))
                      // const temp = { ...contractDatForSelectedProducts }
                      // if (
                      //   productsSelected?.length > products.length &&
                      //   difference?.length > 0 &&
                      //   Object.values(productContractMap)?.length > 0 &&
                      //   productContractMap[difference[0]] &&
                      //   Object.values(temp)?.length > 0 &&
                      //   productContractMap[difference[0]] === 1
                      // ) {
                      //   //delete temp[resourceId][difference[0]]
                      //   //setContractDatForSelectedProducts(temp)
                      // }

                      setProductsSelected(products)
                      setProductExpiryDate(products, resourceType, resourceId)
                      setCurrentProduct(products[products.length - 1])
                    }}
                    defaultProducts={getProductsFromVersion(resource?.version)}
                    resourceType={resource?.resourceId.type ?? resourceType}
                    isClear={isProductsClear}
                    disabled={getResponse}
                  />
                </ProductSelectionContainer>
                <SwitchContainer>
                  <SwitchLabel className={isExpires ? '' : OrgErrors.switch_disabled}>Expires</SwitchLabel>
                  {/* {//contract found is null} */}
                  {(noContractFound === true ||
                    (currentProduct?.length > 0 && productContractMap[currentProduct] === 1)) && (
                    <Switch
                      id="only-customers-toggle-js"
                      checked={isExpires}
                      onChange={() => {
                        setIsExpiry(!isExpires)
                        setIsDatePickerOpen(true)
                      }}
                    />
                  )}
                  <DatePickerContainer>
                    <DatePicker
                      onChange={onDateChange}
                      disabled={!isExpires}
                      disabledDate={disabledDate}
                      value={getFilteredDate(date)}
                    />
                  </DatePickerContainer>
                  {Object.keys(contractDatForSelectedProducts).length !== 0 &&
                    resourceId?.length > 0 &&
                    contractDatForSelectedProducts[resourceId] && (
                      <UpdateCounter>
                        {resourceId?.length > 0 &&
                          contractDatForSelectedProducts[resourceId] &&
                          `+` + `${Object.keys(contractDatForSelectedProducts[resourceId]).length}`}
                      </UpdateCounter>
                    )}
                </SwitchContainer>
              </InputRowContainer>
            )}
            <ResourceButtonContainer>
              <SaveButton id="save-resource-button-js" type="primary" onClick={onClickAdd} loading={isLoading}>
                {isEdit ? 'Update' : 'Add'}
              </SaveButton>
              {!isEdit && (
                <CancelResourceAddButton
                  id="cancel-resource-button-js"
                  type="primary"
                  ghost={true}
                  onClick={() => {
                    setAddResourceManual(false)
                  }}
                >
                  Cancel
                </CancelResourceAddButton>
              )}
            </ResourceButtonContainer>
          </>
        </FormContainer>
        <Modal
          title={SharedOrganizationsStrings.pleaseNoteModalTitle}
          width={672}
          visible={kenListWithNoContractVisible}
          onCancel={() => setKenListWithNoContractVisible(false)}
          closable={false}
          footer={null}
        >
          <>
            <div>{SharedOrganizationsStrings.msgForNoContract}</div>
            <div>{kenListWithNoContract}</div>
            <OkButton
              id="close-resource-button-js"
              size="large"
              style={{ width: '272px' }}
              key="back"
              type="primary"
              onClick={() => setKenListWithNoContractVisible(false)}
            >
              {SharedOrganizationsStrings.ok}
            </OkButton>
          </>
        </Modal>
        {/* </ContextProvider> */}
      </>
    )
  }

  const getSearchContent = () => {
    return (
      <>
        <InputsContainer>
          <InputRowContainer>
            <SearchDeviceInputCol>
              {/* TODO-V2 add controller here, allow only number */}
              <Input
                type="number"
                id="search-organization-input-js"
                style={{ width: 200 }}
                placeholder="Search using Ken"
                onChange={onSearchInputChange}
                suffix={<SearchIcon />}
              />
            </SearchDeviceInputCol>
            <SearchButton id="save-resource-button-js" type="primary" onClick={onSearchKen} loading={isSaving}>
              Search Devices
            </SearchButton>
          </InputRowContainer>
        </InputsContainer>
      </>
    )
  }
  const onClickAdd = async () => {
    resetFlags()
    const errorList: string[] = []
    !resourceType && errorList.push('Resource Type is required')
    productsSelected.length === 0 && errorList.push('API product is required')
    if (!isMultiInput) {
      !resourceId && errorList.push(resourceType + ' is required')
    } else {
      !resources.length && errorList.push(`No ${resourceType} resources added`)
    }

    if (errorList.length >= 1) {
      setInputErrors(errorList)
      setIsInputErrorVisible(true)
      return
    }
    if (!isMultiInput && !noContractFound) {
      if (
        resourceId?.length > 0 &&
        Object.values(contractDatForSelectedProducts)?.length &&
        Object.keys(contractDatForSelectedProducts[resourceId]).length !== productsSelected.length
      ) {
        setIsContractError(false)
        setAddWithoutExpiryError(true)
        return
      }
    }
    if ((noContractFound && expiredDateManual.length === 0) || (noContractFound && isExpires === false)) {
      setContractError('Cannot add without expiry date')
      return
    }
    setIsLoading(true)
    setIsContractError(false)
    setAddWithoutExpiryError(false)
    const errors = []
    const invalidIds = []
    if (isMultiInput) {
      const uniqueResources: string[] = [...new Set(resources)]
      for (const resource of uniqueResources) {
        try {
          await addResource(resource)
        } catch (error) {
          const errorMessage = error?.response?.data?.message ?? error?.message ?? EditResourcesStrings.errorOccurred
          let shortResource
          if (resource.length > 15) {
            shortResource = resource.slice(0, 12) + '...'
          }
          errors.push(`${shortResource ?? resource}: ${errorMessage}`)
          invalidIds.push(resource)
        }
      }
    } else {
      try {
        await addResource(resourceId)
      } catch (error) {
        let shortResource
        const errorMessage = error?.response?.data?.message ?? error.message ?? EditResourcesStrings.errorOccurred
        if (resourceId.length > 15) {
          shortResource = resourceId.slice(0, 12) + '...'
        }
        errors.push(`${shortResource ?? resourceId}: ${errorMessage}`)
        invalidIds.push(resourceId)
      }
    }
    if (errors.length) {
      setAPIErrors(errors)
      setIsErrorVisible(true)
      if (isMultiInput) {
        setResources(invalidIds)
      } else {
        setResourceId(invalidIds.toString()?.trim())
      }
    } else {
      setIsInputErrorVisible(false)
      setIsErrorVisible(false)
      setIsSuccessVisible(true)
      setResources([])
      setResourceType('')
      setProductsSelected([])
      setExpiredDate([])
      setIsProductsClear(true)
      !isEdit && setResourceId('')
    }

    setIsLoading(false)
    setDate('')
    setGetResponse(false)
    queryClient.invalidateQueries(`${ResourcesAPI.queryNames.GET_BY_ORGANIZATION_ID}${organizationId}`)
  }
  {
    apiErrors && apiErrors.map((apiError) => <MessageContainer key={apiError}>{apiError}</MessageContainer>)
  }

  return (
    <>
      <Toast
        isVisible={isSuccessVisible}
        text={EditResourcesStrings.resourceAddedSuccess}
        textId="add-resources-success-js"
        onDismiss={() => setIsSuccessVisible(false)}
        type="success"
      />
      <Toast
        isVisible={isErrorVisible}
        text={apiErrors?.join(', ')}
        textId="add-resources-toast-error-js"
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <Toast
        isVisible={isDeviceSearchErrorVisible}
        text={searchError}
        textId="device-search-error-js"
        onDismiss={() => setIsDeviceSearchErrorVisible(false)}
        type="error"
      />
      <Toast
        isVisible={isInputErrorVisible}
        text={inputErrors?.join(', ')}
        textId={'api-product-toast-error-bid-js'}
        onDismiss={() => setIsInputErrorVisible(false)}
        type="error"
      />
      <Toast
        isVisible={isContractError}
        text={contractError}
        textId={'equipment-contract-toast-error-bid-js'}
        onDismiss={() => setIsContractError(false)}
        type="error"
      />
      <Toast
        isVisible={isAddWithoutExpiryError}
        text={'Please remove the product with no expiry date'}
        textId={'equipment-add-contract-no-expiry-toast-error-bid-js'}
        onDismiss={() => setAddWithoutExpiryError(false)}
        type="error"
      />
      <FormContainer>
        {!isEdit && getSearchContent()}
        {building! && isDevicesFound && (
          <BuildingResourceList
            organizationId={organizationId}
            building={building}
            isLatest={isBuildingLatest}
            contract={contract}
          />
        )}
        {!isDevicesFound && <p>No devices found from inventory</p>}
      </FormContainer>
      {!isEdit && (
        <ButtonsContainer>
          <AddManuallyButton
            id="save-resource-button-js"
            ghost={true}
            onClick={() => {
              setAddResourceManual(true)
            }}
            // loading={isSaving}
          >
            Add Resource
          </AddManuallyButton>
        </ButtonsContainer>
      )}
      {isAddResourceManual && getResourceAddForm()}
      {isEdit && editMode === 'single' && getResourceAddForm(resource)}
      <Line />
      <BackButtonContainer>
        <CancelButton id="cancel-create-org-button-js" onClick={cancelAdd} ghost disabled={isLoading}>
          {EditResourcesStrings.backToResources}
        </CancelButton>
      </BackButtonContainer>
    </>
  )
}

export default AddResourcesForm
