import { Dropdown } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ReactComponent as LeftArrow } from '../../assets/icons-back-arrow.svg'
import { ReactComponent as RightArrow } from '../../assets/icons-right-arrow.svg'
import DashboardPaths from '../../Dashboard/Paths'
import { NotificationIcon, SidePanel } from '../../shared/components'
import { useAuth } from '../../shared/contexts/authContext'
import { GeneralFormStrings } from '../../shared/strings/GeneralFormContent'
import { InvitationsStrings } from '../../shared/strings/InvitationsContent'
import { MenuStrings } from '../../shared/strings/MenuContent'
import awsConfig from '../../shared/utils/awsConfig'
import { Feature, isFeatureEnabled } from '../../shared/utils/featureFlags'
import { size } from '../../shared/utils/styles'
import { useIsDashboard } from '../../shared/hooks/useIsDashboard'
import AuthForms from './AuthForms'
import {
  Arrow,
  AuthMenuContainer,
  DashboardHeaderContainer,
  DropdownGlobalStyle,
  EnvironmentName,
  GoToDashboardLink,
  Hamburger,
  InitialsCircle,
  InitialsContainer,
  LoginButton,
  Logo,
  MenuLink,
  MenuRow,
  ProfileMenuContainer,
  PublicMenuContainer,
  PublicMenuHeaderContainer,
  RegisterButton,
  StyledBadge,
  StyledDropdownMenu,
  TitleContainer,
  TitleLink,
  TitleRow,
} from './Styles'

interface HeaderMenuItem {
  displayName: string
  key: string
  link: string
}
const prodEnvs = ['prod', 'china-prod']
const menuItems = [
  { key: '/inspiration', displayName: MenuStrings.menuItems.inspiration, link: '/inspiration' },
  { key: '/getting-started', displayName: MenuStrings.menuItems.gettingStarted, link: '/getting-started' },
  { key: '/kone-apis', displayName: MenuStrings.menuItems.koneApis, link: '/kone-apis' },
]

if (!prodEnvs.includes(awsConfig?.environment)) {
  menuItems.push({
    key: '/integrated-solutions',
    displayName: MenuStrings.menuItems.integratedSolutions,
    link: '/integrated-solutions',
  })
}

const Header: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const isDashboardView = useIsDashboard()
  const { signOut, isAuthenticated, currentUser, isAuthPanelOpen, closeAuthPanel, hasNewInvitation } = useAuth()

  const [selectedKey, setSelectedKey] = useState(location.pathname)

  const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

  const [width, setWidth] = useState(getWidth())
  const smallBreakpoint = Number.parseInt(size.small, 10)
  const largeBreakpoint = Number.parseInt(size.large, 10)

  const isMediumBreakpoint = width > smallBreakpoint && width <= largeBreakpoint

  useEffect(() => {
    const resizeListener = () => {
      setWidth(getWidth())
    }
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  useEffect(() => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false)
    }

    const pathname = location.pathname.toLowerCase()
    setSelectedKey(pathname)
  }, [location.pathname])

  const onLogout = async () => {
    await signOut()
  }

  const generateMenuItems = (items: HeaderMenuItem[]) => {
    return items.map((item) => {
      return (
        <MenuLink key={item.key} className={selectedKey === item.link ? 'active' : ''} to={item.link}>
          {item.displayName}
        </MenuLink>
      )
    })
  }

  const getProfileMenuItems = () => {
    return (
      <StyledDropdownMenu>
        {!currentUser?.isKoneAdmin && (
          <StyledDropdownMenu.Item id="my-profile-menuitem-js" key="my-profile">
            <Link to={DashboardPaths.MyProfile.path}>{DashboardPaths.MyProfile.name}</Link>
            {hasNewInvitation() && <StyledBadge>{InvitationsStrings.newInvitation}</StyledBadge>}
          </StyledDropdownMenu.Item>
        )}
        <StyledDropdownMenu.Item id="logout-menuitem-js" key="logout" onClick={onLogout}>
          {GeneralFormStrings.logout}
        </StyledDropdownMenu.Item>
      </StyledDropdownMenu>
    )
  }

  const getInitials = () => {
    const firstName = currentUser?.firstName
    const lastName = currentUser?.lastName
    const email = currentUser?.email

    if (firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`
    }

    return email?.charAt(0)
  }

  const ProfileMenu = () => {
    return (
      <ProfileMenuContainer>
        <DropdownGlobalStyle />
        <Dropdown overlay={getProfileMenuItems()} trigger={['click']}>
          <InitialsContainer>
            <InitialsCircle id="user-initials-button-js" type="primary" shape="circle">
              {getInitials()}
              {hasNewInvitation() && <NotificationIcon />}
            </InitialsCircle>
            <Arrow />
          </InitialsContainer>
        </Dropdown>
      </ProfileMenuContainer>
    )
  }

  const onLoginClick = () => {
    history.replace({ pathname: location.pathname, search: '?auth=login' })
  }

  const onRegisterClick = () => {
    history.replace({ pathname: location.pathname, search: '?auth=register' })
  }

  const GoToPublicSiteHeaderSegment = () => {
    return (
      <TitleContainer>
        <TitleLink id="go-to-public-site-link-js" $public={!isDashboardView} to="/">
          {isDashboardView && <LeftArrow />}
          {MenuStrings.koneApiPortal} {environmentName}
        </TitleLink>
      </TitleContainer>
    )
  }

  const GoToDashboardHeaderSegment = () => {
    return (
      <AuthMenuContainer>
        {isAuthenticated() ? (
          <GoToDashboardLink id="go-to-dashboard-link-js" to="/dashboard">
            {currentUser?.isKoneAdmin ? MenuStrings.goToAdminPanel : MenuStrings.goToDashboard}
            <RightArrow />
          </GoToDashboardLink>
        ) : (
          <>
            <LoginButton id="login" type="link" onClick={onLoginClick}>
              {GeneralFormStrings.loginButton}
            </LoginButton>
            <RegisterButton ghost onClick={onRegisterClick}>
              {GeneralFormStrings.registerButton}
            </RegisterButton>
          </>
        )}
      </AuthMenuContainer>
    )
  }

  const closeAuthSidePanel = () => {
    closeAuthPanel()
  }

  const environmentName =
    isFeatureEnabled(Feature.ShowEnvironmentName) && awsConfig ? (
      <EnvironmentName>({awsConfig.environment})</EnvironmentName>
    ) : (
      ''
    )

  const PublicMenuHeaderView = () => {
    return (
      <PublicMenuHeaderContainer>
        <TitleRow>
          <GoToPublicSiteHeaderSegment />
          <Link to="/">
            <Logo public />
          </Link>
          {isMediumBreakpoint && <GoToDashboardHeaderSegment />}
          <Hamburger active={isMobileMenuOpen} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            <span />
            <span />
            <span />
          </Hamburger>
        </TitleRow>
        <MenuRow isMobileMenuOpen={isMobileMenuOpen}>
          <PublicMenuContainer>{generateMenuItems(menuItems)}</PublicMenuContainer>
          {!isMediumBreakpoint && <GoToDashboardHeaderSegment />}
        </MenuRow>
      </PublicMenuHeaderContainer>
    )
  }

  const DashboardHeaderView = () => {
    return (
      <DashboardHeaderContainer>
        <GoToPublicSiteHeaderSegment />
        <Link to="/dashboard">
          <Logo />
        </Link>
        <ProfileMenu />
      </DashboardHeaderContainer>
    )
  }

  return (
    <>
      {isDashboardView ? <DashboardHeaderView /> : <PublicMenuHeaderView />}
      <SidePanel
        isOpen={isAuthPanelOpen}
        title={MenuStrings.accessKoneApiPortal}
        showBackButton={true}
        onClose={closeAuthSidePanel}
      >
        <AuthForms />
      </SidePanel>
    </>
  )
}

export default Header
