import React, { FC } from 'react'
import { useQuery } from 'react-query'
import ListHeader from '../ListHeader'
import TableHeaderRow from '../ListTableHeaderRow'
import TableContentRow from '../ListTableContentRow'
import { TierPlanStrings } from '../../../../shared/strings/OrganizationsContent'
import { TierPlanAPI } from '../../../../shared/api/tiers'
import { TierPlan } from '../../../../shared/models/tierPlan'
import { LoadingAnimated } from '../../../../shared/components'
import { ApiProductContainer, TierContainer } from './Styles'

type TierPlanListProps = {
  orgId: number
  openEditTierPlan: () => void
}

const TierPlanList: FC<TierPlanListProps> = ({ orgId, openEditTierPlan }) => {
  const queryName = `${TierPlanAPI.queryNames.GET_BY_ORG_ID}${orgId}`
  const {
    isLoading,
    error,
    data: tierPlans = [],
  } = useQuery(queryName, () => TierPlanAPI.getDefaultTierByOrganizationId(orgId))

  const fetchRtmTierPlan = (): string => {
    const rtmTierPlan = tierPlans.find((plan: TierPlan) => plan.productName === 'rtm')
    return rtmTierPlan?.tier || 'light'
  }

  // Only RTM product is applied rate limit
  const showTierPlan = () => {
    if (isLoading) {
      return <LoadingAnimated />
    }
    if (error) {
      return <p>{TierPlanStrings.errorLoadTierPlan}</p>
    }
    return (
      <>
        <TableHeaderRow>
          <ApiProductContainer>{TierPlanStrings.headerProduct}</ApiProductContainer>
          <TierContainer>{TierPlanStrings.headerTier}</TierContainer>
        </TableHeaderRow>
        <TableContentRow>
          <ApiProductContainer>{TierPlanStrings.rowRtmProduct}</ApiProductContainer>
          <TierContainer id="current-tier-js">{fetchRtmTierPlan()}</TierContainer>
        </TableContentRow>
      </>
    )
  }

  return (
    <>
      <ListHeader
        title={TierPlanStrings.title}
        addButtonId="open-edit-tier-button-js"
        addButtonText={TierPlanStrings.edit}
        onClickAdd={openEditTierPlan}
        disableAddButton={isLoading}
      />
      {showTierPlan()}
    </>
  )
}

export default TierPlanList
