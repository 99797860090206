import { API } from './API'
import { FeedbackData } from '../models/auth'

const FEEDBACK_PATH = '/feedback'

const sendFeedback = async (data: FeedbackData): Promise<any> => {
  const parameters = {
    body: data,
  }
  return await API.post(FEEDBACK_PATH, parameters)
}

export const FeedbackAPI = {
  sendFeedback,
}
