import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'

export const ReleaseNotesLogJanuary2 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        January - 2.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        January 2.0 release notes summarizes latest changes for Service Info Webhook API and API Portal documentation
        fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              <q>createdDateTime</q> is now available for unplanned service order in <b>ENA</b> region.
            </li>
            <li>
              <q>salesOrderNumber</q> is available for all service order types in{' '}
              <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}>Service Info Webhook API</Link>.
            </li>
            <li>
              For <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrderById'}>Service Order Details API</Link>{' '}
              <q>salesOrderNumber</q> is available for YSM6 Fixed Price Repair Order service order type.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Corrected error request payload in{' '}
              <Link to={Paths.ElevatorWebsocketAPIRobotsV2.path}> Elevator WebSocket API</Link>.
            </li>
            <li>
              <li>
                Updated comments in the request payload of{' '}
                <Link to={Paths.ElevatorCallAPI.path}>Elevator Call API</Link>.
              </li>
            </li>
            <li>
              Description of <q>time</q> field is updated in{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#service-status-event'}>
                Equipment Status Information Webhook payload
              </Link>
              .
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
