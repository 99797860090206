import React from 'react'
import { H4, P, UL, OL } from '../../../../shared/components/Typography'

export const ReleaseNotesLogJuly2 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        July 2023 - 2.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        July 2.0 release notes summarizes API Portal documentation updates for Solution Creation and Glossary pages
        along with Experience API bug fix.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Renewed Solution Creation Process layout, instruction and documents to ease self-service solution
              creation.
            </li>
            <li>
              Renewed Glossary layout and content to easier understanding of elevator domain and terminology pertaining
              to elevator calls.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Elevator Call and Service Robot API</li>
          <UL>
            <li>Timeout for Elevator Call and Service Robot API has been decreased from 30 seconds to 15 seconds</li>
            <li>
              NOTE: Solutions also having internal timeout, it is now recommended to adjust to 17sec. This will be 2sec
              after the expected 1005 timeout. Or any value above 15sec will be fine as well.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>
            {' '}
            No product level bug fixes applicable and no new feature release for Equipment Status 2 and Service Info 2
            APIs.
          </li>
        </OL>
      </P>
    </>
  )
}
