import React, { createContext, ReactNode, useState } from 'react'
import { masterData } from '../../../shared/models/salespage'

const defaultPartner = {
  partnerName: '',
}
export const PartnerContext = createContext<any | null>(defaultPartner)
const PartnerProvider: React.FC<ReactNode> = ({ children }) => {
  const [partner, setPartner] = useState<any>({})
  const [allowPrev, setAllowPrev] = useState<boolean | false>(false)
  const [allowNext, setAllowNext] = useState<boolean | false>(false)
  const [masterData, setMasterData] = useState<masterData>({})

  return (
    <PartnerContext.Provider
      value={{ partner, setPartner, allowPrev, setAllowPrev, allowNext, setAllowNext, masterData, setMasterData }}
    >
      {children}
    </PartnerContext.Provider>
  )
}

export default PartnerProvider
