import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { AsyncApiProps } from '..'
import Paths from '../../../../Dashboard/Paths'
import { H3, H4, P, UL } from '../../../../shared/components/Typography'
import { PayloadProperty } from '../../components/PayloadProperty'
import { CodeBlock, LeftCol, Paragraph, RightCol, SectionRow, StyledEventName } from '../../Styles'

export interface LiftCallProps {
  asyncApiProps: AsyncApiProps
}

export const LiftCall: FC<LiftCallProps> = ({ asyncApiProps }) => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H3>WebSocket Requests</H3>
        <Row>
          <P size="small">
            Requests will always receive an acknowledgement with matching requestId. Any response status of 2xx should
            be interpreted as success.
          </P>
        </Row>
      </LeftCol>
      <RightCol span="12"></RightCol>

      <LeftCol span="12">
        <H4 id="make-an-elevator-call">Make an elevator call</H4>

        <Row>
          <Col span={6}>
            <StyledEventName>lift-call</StyledEventName>
          </Col>
          <Col span={18}>
            <P size="small">
              Use <strong>lift-call</strong> to make a destination call or landing call. A destination call sets both
              the sourceId and the destinationId to move the elevator between specific areas. A landing call sets both
              the sourceId and the direction to order the elevator to a specific floor. Areas relate to a building
              topology information found from the <Link to={Paths.BuildingAPI.path}>{Paths.BuildingAPI.name}</Link>. The{' '}
              <strong>callType</strong> can be either <strong>normal</strong> or <strong>robot</strong> depending on the
              API used.
              <br />
              <br /> The <strong>passengerArrivalTimeSeconds</strong> attribute defines when a passenger is estimated to
              arrive (seconds from now) to a point-of-interest defined by the system. It does not dictate how the
              underlying system will behave, as there may be other factors (e.g. other ongoing calls) to be considered
              and optimized, but it may influence it.
              <br />
              <br />
              WebSocket API can handle multiple calls in the same socket. Call events are tied to the socket that
              creates them. By default, the connection is closed after the client is not expecting any more state events
              from the call. If the client does not specify any monitoring events, the connection will be closed after
              receiving the response. If client needs to create consecutive calls it can specify{' '}
              <strong>keepAlive</strong> flag to keep the connection open for up to two hours.
              <br />
              <br />
              Response status codes should be interpreted as in the HTTP specification:
            </P>
            <UL size="small">
              <li>201 - Call has been registered to the system</li>
              <li>400 - Call payload validation error</li>
              <li>401 - Not authenticated or token expired</li>
              <li>403 - Client is forbidden from performing the request. Please check the provided scopes.</li>
              <li>404 - Invalid building id</li>
              <li>
                409 - Conflicting request. Eg. <strong>requestId</strong> not unique within the connection.
              </li>
              <li>500 - Internal error</li>
            </UL>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="type"
              type="string"
              required={true}
              description={`Identifies this message as a request to create a lift call`}
              stringEnums={['lift-call']}
            />

            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`The type of the call defines whether the user is going to be a robot or a normal user. Robot type calls are only accepted from clients 
              subscribing to Service Robot API product. Robot call type ensures longer time (default 15 seconds) before doors close on source and destination areas. `}
              stringEnums={[asyncApiProps.callType]}
            />

            <PayloadProperty
              name="callAction"
              type="string"
              required={true}
              description={`Defines which kind of call the client is making. Currently the allowed values are 'destination' and 'landing'. 
              In a destination call the client requires both destinationId and a sourceId. In a landing call the client requires the sourceId and the direction`}
              stringEnums={['destination', 'landing']}
              stringEnumDescriptions={[
                {
                  value: 'destination',
                  description: `A destination call, which sets both the sourceId and destinationId`,
                },
                {
                  value: 'landing',
                  description: `A landing call, which sets both the sourceId and direction`,
                },
              ]}
            />

            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building `}
              format={`building:{buildingId}`}
            />

            <PayloadProperty
              name="sourceId"
              type="string"
              required={true}
              description={`The area of entry for the elevator ride`}
              format={`area:{buildingId}:{areaId}`}
            />

            <PayloadProperty
              name="destinationId"
              type="string"
              description={`The exit area for the elevator ride. This is a required field when making a destination call`}
              format={`area:{buildingId}:{areaId}`}
            />
            <PayloadProperty
              name="direction"
              type="string"
              stringEnums={['up', 'down']}
              stringEnumDescriptions={[
                {
                  value: 'up',
                  description: `The ride will be upwards from the source area`,
                },
                {
                  value: 'down',
                  description: `The ride will be downwards from the source area`,
                },
              ]}
              description={`The direction of the exit area from the source area. This is a required field when making a landing call`}
              format={`area:{buildingId}:{areaId}`}
            />

            <PayloadProperty
              name="requestId"
              type="string"
              description={`Identifier for the request. The identifier should be unique within one connection. If not provided, one will be generated automatically.`}
            />

            <PayloadProperty
              name="monitorEvents"
              type="string"
              description={`The monitoring desired by the user. This property defines which kind of events are delivered back to the stream about the call and journey being made.`}
              stringEnums={['call', 'deck', 'door']}
              stringEnumDescriptions={[
                {
                  value: 'call',
                  description: `Monitor the state of the call`,
                },
                {
                  value: 'deck',
                  description: `Monitors the position and status of the elevator deck during the journey. Events are only provided from assigned elevator deck. 
                  Assigned deck might change during the call before user boards the lift.`,
                },
                {
                  value: 'door',
                  description: `Monitor the status of the doors. Events are only provided from the allocated elevator deck.`,
                },
              ]}
            />

            <PayloadProperty
              name="keepAlive"
              type="boolean"
              description={`The connection can be either kept alive or closed after there are no more active calls`}
              stringEnums={['true', 'false']}
            />

            <PayloadProperty
              name="passengerArrivalTimeSeconds"
              type="number"
              description={`Optional parameter which defines the amount of time it takes for the passenger to arrive to an elevator. 
              Elevator group tries to land an elevator car at a specified time, but the behavior is not guaranteed, for example, in heavy traffic situations. 
              Accepted range is from 1 to 60 seconds.`}
            />

            <PayloadProperty
              name="allowPartialJourney"
              type="boolean"
              description={`Optional parameter to use in case if a single elevator cannot serve the given destination floor, 
              allocated lift gets served to the transfer floor and passenger makes another call from transfer floor to destination floor`}
              stringEnums={['true', 'false']}
            />
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Request example for destination call</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "lift-call",
  "callType": "${asyncApiProps.callType}",
  "callAction": "destination",
  "buildingId": "building:99900009301",
  "sourceId": "area:99900009301:1000",
  "destinationId": "area:99900009301:2000",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "monitorEvents": ["call"]
}`}
        </CodeBlock>
        <Paragraph>Request example for landing call</Paragraph>
        <CodeBlock language="json">
          {`{
  "type": "lift-call",
  "callType": "${asyncApiProps.callType}",
  "callAction": "landing",
  "buildingId": "building:99900009301",
  "sourceId": "area:99900009301:1000",
  "direction": "up",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "monitorEvents": ["call"]
}`}
        </CodeBlock>

        <Paragraph>Acknowledgement response</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "ok",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "statusCode": 201,
  "timestamp": "2022-02-14T04:53:40.169Z"
}`}
        </CodeBlock>

        <Paragraph>Request payload validation error</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "statusCode": 400,
  "message": "'callType' is required"
}`}
        </CodeBlock>
        <Paragraph>Request example for transfer floor call</Paragraph>
        <CodeBlock language="json">
          {`{
  "type": "lift-call",
  "callType": "${asyncApiProps.callType}",
  "callAction": "landing",
  "buildingId": "building:99900009301",
  "sourceId": "area:99900009301:1000",
  "direction": "up",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "monitorEvents": ["call"],
  "allowPartialJourney": true
}`}
        </CodeBlock>
        <Paragraph>Acknowledgement response</Paragraph>
        <CodeBlock language="json">
          {`{
   "type": "ok",
   "connectionId": "Fme8JdhTjoECG6Q=",
   "requestId": "destinationcall",
   "statusCode": 201,
   "timestamp": "2022-02-14T04:53:40.169Z",
   "data": {
        "partialJourney": true
     }
}`}
        </CodeBlock>
      </RightCol>
      <LeftCol span="12">
        <H4 id="cancel-an-elevator-call">Cancel an elevator call</H4>

        <Row>
          <Col span={6}>
            <StyledEventName>cancel-call</StyledEventName>
          </Col>
          <Col span={18}>
            <P size="small">
              Use the <strong>cancel-call</strong> request to cancel a previously created elevator call. For the
              request, you need <strong>requestId</strong> of the specific call.
            </P>
            <P size="small">
              Receiving a successful acknowledgment for cancel-call does not always guarantee that the cancellation will
              be acted upon. Successful cancellation results to lift-call-state event sent as canceled for the original
              lift-call request.
            </P>
            <UL size="small">
              <li>202 - Accepted</li>
              <li>400 - Payload validation error</li>
              <li>404 - Invalid cancel request id</li>
              <li>409 - Request was not in cancellable state</li>
              <li>500 - Internal error</li>
            </UL>
          </Col>
          <Row>
            <Col>
              <PayloadProperty
                name="type"
                type="string"
                required={true}
                description={`Identifies this message as a request to cancel a lift call`}
                stringEnums={['cancel-call']}
              />
              <PayloadProperty
                name="cancelRequestId"
                type="string"
                required={true}
                description={`The requestId of the in-progress request to cancel.`}
              />
              <PayloadProperty
                name="requestId"
                type="string"
                description={`Identifier for the elevator call request. The identifier should be unique within one connection. If not provided, 
                one will be generated automatically.`}
              />
            </Col>
          </Row>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Request example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "cancel-call",
  "cancelRequestId": "78c5ff6c-a8fe-405b-bde3-ffcd6935573e"
}`}
        </CodeBlock>

        <Paragraph>Acknowledgement response</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "accepted",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "ca3ca81d-84cf-466b-bd5e-899b7d92c9d5",
  "statusCode": 202
}`}
        </CodeBlock>

        <Paragraph>Request payload validation error</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "ca3ca81d-84cf-466b-bd5e-899b7d92c9d5",
  "statusCode": 400,
  "message": "'cancelRequestId' is required"
}`}
        </CodeBlock>
      </RightCol>
      <LeftCol span="12">
        <H4 id="create-a-session">Create a session</H4>

        <Row>
          <Col>
            <StyledEventName>create-session</StyledEventName>
          </Col>
          <Col span={18}>
            <P size="small">
              Each elevator call request is linked to a certain WebSocket connection and this link is automatically cut
              off once the connection is closed.
            </P>
            <P size="small">
              Use the <strong>create-session</strong> request to receive a new sessionId needed for reactivating a lost
              connection. Once a new connection is established, create-session or below explained resume-session should
              be the first requests made. Notice that this request is not mandatory. It is possible to make a successful
              lift-call without creating a session.
            </P>
            <UL size="small">
              <li>201 - Session was created</li>
              <li>400 - Invalid request. Either validation error or trying to resume own session</li>
              <li>401 - Not authenticated or token expired</li>
              <li>500 - Internal error</li>
            </UL>
          </Col>
          <Row>
            <Col>
              <PayloadProperty
                name="type"
                type="string"
                required={true}
                description={`Identifies this message as a request to create a session`}
                stringEnums={['create-session']}
              />
              <PayloadProperty
                name="requestId"
                type="string"
                description={`Identifier for the request. The identifier should be unique within one connection. If not provided, one will be generated automatically.`}
              />
            </Col>
          </Row>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Request example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "create-session",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b"
}`}
        </CodeBlock>
        <Paragraph>Acknowledgement response</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "ok",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "statusCode": 201,
  "timestamp": "2022-02-14T04:53:40.169Z",
  "data": {
    "sessionId": "55bf5b37-e0b8-a2s0-8dcf-dc8c4aefc321"
  }
}`}
        </CodeBlock>

        <Paragraph>Request payload validation error</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "statusCode": 400,
  "message": "'requestId' cannot exceed 50 characters"
}`}
        </CodeBlock>
      </RightCol>

      <LeftCol span="12">
        <H4 id="resume-a-session">Resume a session</H4>

        <Row>
          <Col>
            <StyledEventName>resume-session</StyledEventName>
          </Col>
          <Col span={18}>
            <P size="small">
              Use the <strong>resume-session</strong> request to reactivate a previously created session. The new
              connection will assume ownership of the previous sessions, active requests and new events related to
              requests made earlier. You can also receive latest state messages relevant to a session.
            </P>
            <P size="small">
              To prevent a potential session theft, authentication token with matching application and user ID (when
              present) are needed when resuming a session. There can only be one active session at once in one
              connection. In case there is an open connection linked to the session, the previous connection is
              immediately closed.
            </P>
            <UL size="small">
              <li>201 - The session has been resumed</li>
              <li>400 - Payload validation error</li>
              <li>401 - Not authenticated or token expired</li>
              <li>404 - Invalid session id</li>
              <li>500 - Internal error</li>
            </UL>
          </Col>
          <Row>
            <Col>
              <PayloadProperty
                name="type"
                type="string"
                required={true}
                description={`Identifies this message as a request to resume a session`}
                stringEnums={['resume-session']}
              />
              <PayloadProperty
                name="requestId"
                type="string"
                description={`Identifier for the request. The identifier should be unique within one connection. If not provided, one will be generated automatically.`}
              />
              <PayloadProperty name="sessionId" type="string" required={true} description={`Session identifier`} />
              <PayloadProperty
                name="resendLatestStateUpToSeconds"
                type="number"
                description={`The time in seconds until the latest state data is re-sent`}
              />
            </Col>
          </Row>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Request example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "resume-session",
  "requestId": "78c5ff6c-a8fe-405b-bde3-ffcd6935573e",
  "sessionId": "55bf5b37-e0b8-a2s0-8dcf-dc8c4aefc321",
  "resendLatestStateUpToSeconds": 30
}`}
        </CodeBlock>

        <Paragraph>Acknowledgement response</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "ok",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "timestamp": "2022-02-14T04:53:40.169Z",
  "requestId": "78c5ff6c-a8fe-405b-bde3-ffcd6935573e",
  "statusCode": 201
}`}
        </CodeBlock>

        <Paragraph>Request payload validation error</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "requestId": "78c5ff6c-a8fe-405b-bde3-ffcd6935573e",
  "statusCode": 400,
  "message": "'sessionId' is required"
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}
