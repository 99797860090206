import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Image } from 'antd'
import {
  UpperPageContainer,
  InnerPageContainer,
  CardContainer,
  CardContent,
  ContactButtonArea,
  ContactUsButton,
  Sticky,
  TabsContainer,
  StyledTabs,
} from './Styles'

import { H2, H4, H5, P, BackButton, Line, HeroImage } from '../../../shared/components'
import koneapisHeroImage from '../../../assets/bg-img-apis.jpg'
import serviceStatus from '../../../assets/img-equipment-status.png'
import equipmentStatus from '../../../assets/img-equip-status.png'
import koneapisHeroImageLarge from '../../../assets/bg-img-apis@2x.jpg'
import {
  KONEAPIsHeroImage,
  ElevatorCallApi,
  ServiceRobotApi,
  EquipmentStatusApi,
  ServiceInfoApiOverview,
  SEOPropsElevatorCallApi,
  SEOPropsEquipmentStatusApi,
  SEOPropsServiceInfoApi,
  SEOPropsServiceRobotApi,
  KONEApisSharedStrings,
} from '../../../shared/strings/KONEAPIsTextContent'
import TitleWrapper, { TitleWrapperProps } from '../../../shared/components/TitleWrapper'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

const { TabPane } = StyledTabs

type Props = {
  title: string
  subtitle: string
  body: string
  features: string
  cardText: string
  id: string
  SEOtags: TitleWrapperProps
  isEquipmentStatusApiPage?: boolean
  isServiceInfo?: boolean
}

const images = {
  small: koneapisHeroImage,
  large: koneapisHeroImageLarge,
}

const PageLayout: FC<Props> = ({
  title,
  subtitle,
  body,
  features,
  cardText,
  id,
  SEOtags,
  isEquipmentStatusApiPage,
  isServiceInfo,
}) => {
  const history = useHistory()

  useEffect(() => {
    const hashId = location.hash
    const scrollElement = document.getElementById(hashId)
    if (scrollElement !== null && scrollElement !== undefined) {
      //Scrolls to top of the element
      const y = scrollElement.getBoundingClientRect().top + window.pageYOffset - 55
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  })

  const navigateTo = () => {
    if (history.action !== 'POP') {
      history.goBack()
    } else {
      history.push('/kone-apis')
    }
  }

  return (
    <>
      {!isEquipmentStatusApiPage && !isServiceInfo ? (
        <>
          <TitleWrapper title={SEOtags.title} metaTags={SEOtags.metaTags} />
          <HeroImage
            title={KONEAPIsHeroImage.title}
            body={KONEAPIsHeroImage.body}
            image={images}
            ingressWidth={'838px'}
            button={false}
          />
          <UpperPageContainer id={id}>
            <Sticky>
              <BackButton onClick={navigateTo} />
            </Sticky>
            <InnerPageContainer>
              <H2 textAlign="center" dangerouslySetInnerHTML={{ __html: sanitizer(title) }} />
              <Line />
              <H4 textAlign="center">{subtitle}</H4>
              <P dangerouslySetInnerHTML={{ __html: sanitizer(body) }} />
              <H5>{KONEApisSharedStrings.availableFeaturesAndData}</H5>
              <P dangerouslySetInnerHTML={{ __html: sanitizer(features) }} />
            </InnerPageContainer>
          </UpperPageContainer>
          <CardContainer>
            <CardContent>
              <H2 textAlign="center">{cardText}</H2>
              <Line />
              <ContactButtonArea>
                <a href="https://www.kone.com/en/contact.aspx" target="_blank" rel="noreferrer">
                  <ContactUsButton ghost>{KONEApisSharedStrings.contactUsButton}</ContactUsButton>
                </a>
              </ContactButtonArea>
            </CardContent>
          </CardContainer>
        </>
      ) : (
        <>
          {isServiceInfo ? (
            <>
              <TitleWrapper title={SEOtags.title} metaTags={SEOtags.metaTags} />
              <HeroImage
                title={KONEAPIsHeroImage.title}
                body={KONEAPIsHeroImage.body}
                image={images}
                ingressWidth={'838px'}
                button={false}
              />
              <UpperPageContainer id={id}>
                <Sticky>
                  <BackButton onClick={navigateTo} />
                </Sticky>
                <InnerPageContainer>
                  <H2 textAlign="center" dangerouslySetInnerHTML={{ __html: sanitizer(title) }} />
                  <Line />
                  <H4 textAlign="center">{subtitle}</H4>
                </InnerPageContainer>
              </UpperPageContainer>
              <InnerPageContainer>
                <TabsContainer>
                  <StyledTabs centered={true} defaultActiveKey={'overview'} destroyInactiveTabPane={true}>
                    <TabPane tab="OVERVIEW" key="overview">
                      <Image src={serviceStatus} preview={false} />
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(body) }} />
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(features) }} />
                    </TabPane>
                    <TabPane tab="VERSION-1" key="version-1">
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApi.deprecated) }} />
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(ServiceInfoApiOverview.body_version_one) }} />

                      <P dangerouslySetInnerHTML={{ __html: sanitizer(ServiceInfoApiOverview.features_version_one) }} />
                    </TabPane>
                    <TabPane tab="VERSION-2" key="version-2">
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(ServiceInfoApiOverview.body_version_two) }} />

                      <P dangerouslySetInnerHTML={{ __html: sanitizer(ServiceInfoApiOverview.features_version_two) }} />
                    </TabPane>
                  </StyledTabs>
                </TabsContainer>
              </InnerPageContainer>
            </>
          ) : (
            <>
              <TitleWrapper title={SEOtags.title} metaTags={SEOtags.metaTags} />
              <HeroImage
                title={KONEAPIsHeroImage.title}
                body={KONEAPIsHeroImage.body}
                image={images}
                ingressWidth={'838px'}
                button={false}
              />
              <UpperPageContainer id={id}>
                <Sticky>
                  <BackButton onClick={navigateTo} />
                </Sticky>
                <InnerPageContainer>
                  <H2 textAlign="center" dangerouslySetInnerHTML={{ __html: sanitizer(title) }} />
                  <Line />
                  <H4 textAlign="center">{subtitle}</H4>
                </InnerPageContainer>
              </UpperPageContainer>
              <InnerPageContainer>
                <TabsContainer>
                  <StyledTabs centered={true} defaultActiveKey={'overview'} destroyInactiveTabPane={true}>
                    <TabPane tab="OVERVIEW" key="overview">
                      <Image src={equipmentStatus} preview={false} />
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(body) }} />
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(features) }} />
                    </TabPane>
                    <TabPane tab="VERSION-1" key="version-1">
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApi.deprecated) }} />
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApi.body_version_one) }} />
                      <H5>{KONEApisSharedStrings.versionOne}</H5>
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApi.features_version_one) }} />
                    </TabPane>
                    <TabPane tab="VERSION-2" key="version-2">
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApi.body_version_two) }} />
                      <H5>{KONEApisSharedStrings.versionTwo}</H5>
                      <P dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApi.features_version_two) }} />
                    </TabPane>
                  </StyledTabs>
                </TabsContainer>
              </InnerPageContainer>
            </>
          )}
        </>
      )}
      )
    </>
  )
}

export const ElevatorCallApiPage: FC = () => {
  return (
    <PageLayout
      SEOtags={SEOPropsElevatorCallApi}
      title={ElevatorCallApi.title}
      subtitle={ElevatorCallApi.subtitle}
      body={ElevatorCallApi.body}
      features={ElevatorCallApi.features}
      cardText={ElevatorCallApi.cardText}
      id="#koneapi-elevator-call"
    />
  )
}
export const ServiceRobotApiPage: FC = () => {
  return (
    <PageLayout
      SEOtags={SEOPropsServiceRobotApi}
      title={ServiceRobotApi.title}
      subtitle={ServiceRobotApi.subtitle}
      body={ServiceRobotApi.body}
      features={ServiceRobotApi.features}
      cardText={ServiceRobotApi.cardText}
      id="#koneapi-service-robot"
    />
  )
}
export const EquipmentStatusApiPage: FC = () => {
  return (
    <PageLayout
      SEOtags={SEOPropsEquipmentStatusApi}
      title={EquipmentStatusApi.title}
      subtitle={EquipmentStatusApi.subtitle}
      body={EquipmentStatusApi.body}
      features={EquipmentStatusApi.features}
      cardText={EquipmentStatusApi.cardText}
      id="#koneapi-equipment-status"
      isEquipmentStatusApiPage={true}
    />
  )
}
export const ServiceInfoApiPage: FC = () => {
  return (
    <PageLayout
      SEOtags={SEOPropsServiceInfoApi}
      title={ServiceInfoApiOverview.title}
      subtitle={ServiceInfoApiOverview.subtitle}
      body={ServiceInfoApiOverview.body}
      features={ServiceInfoApiOverview.features}
      cardText={ServiceInfoApiOverview.cardText}
      id="#koneapi-service-info"
      isServiceInfo={true}
    />
  )
}
