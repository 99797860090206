import Auth from '@aws-amplify/auth'
import React, { FC } from 'react'
import { H3, H5 } from '../../components'
import { ErrorPageStrings } from '../../strings/ErrorPageContent'
import { ContentWrapper } from '../Styles'

type Props = {
  withMargin?: boolean
}

const ErrorPage: FC<Props> = ({ withMargin }): JSX.Element => {
  const onLogout = async () => {
    // Has to use Amplify directly as the AuthContext is not available.
    await Auth.signOut()
    window.location.href = '/api-portal'
  }

  return (
    <ContentWrapper withMargin={withMargin}>
      <H3>{ErrorPageStrings.title}</H3>
      <H5>
        {ErrorPageStrings.tryReload} <a onClick={onLogout}>{ErrorPageStrings.signOut}</a> {ErrorPageStrings.and}{' '}
        <a href="mailto:api-support@kone.com">{ErrorPageStrings.contactApiSupport}</a>.
      </H5>
    </ContentWrapper>
  )
}
export default ErrorPage
