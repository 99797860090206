import React, { FC } from 'react'
import InspirationArticleTemplate from '../InspirationArticleTemplate'
import { SmarterCities, SmarterCitiesSEOProps } from '../../../shared/strings/InspirationPageContent'
import apiPoweredEcosystem from '../../../assets/api-powered-ecosystem.jpg'
import apiPoweredEcosystemLarge from '../../../assets/api-powered-ecosystem-2x.jpg'

const SmarterCitiesArticle: FC = () => {
  return (
    <InspirationArticleTemplate
      title={SmarterCities.title}
      metaTags={SmarterCitiesSEOProps.metaTags}
      firstTextBlock={SmarterCities.firstTextBlock}
      subtitle={SmarterCities.subtitle}
      secondTextBlock={SmarterCities.secondTextBlock}
      findOutMoreText={SmarterCities.findOutMoreText}
      contactUsLink={SmarterCities.contactUsLink}
      articleImage={{
        small: apiPoweredEcosystem,
        large: apiPoweredEcosystemLarge,
      }}
      imageAlt={SmarterCities.imageAlt}
    />
  )
}

export default SmarterCitiesArticle
