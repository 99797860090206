import { Input } from 'antd'
import styled from 'styled-components'
import { ReactComponent as CopyIcon } from '../../../assets/icons-copy.svg'
import { ReactComponent as OkCheckIcon } from '../../../assets/ok-check-icon.svg'
import Button from '../../../shared/components/Button'
import { color } from '../../../shared/utils/styles'

export const FormWrapper = styled.div`
  width: 362px;
  margin: 0 auto;
`

export const SuccessContainer = styled.div`
  text-align: center;
`

export const SuccessMessage = styled.p`
  font-size: 28px;
  margin-bottom: 26px;
`

export const InputContainer = styled.div``

export const FormLabel = styled.p`
  margin-bottom: 8px;
  display: block;
  color: ${color.gray20};
`

export const CenterContainer = styled.div`
  display: flex;
`

export const InputName = styled(Input)`
  color: ${color.gray75};
  width: 316px;
  height: 32px;
  margin-bottom: 36px;
  margin-right: 12px;
  cursor: default;
`

export const CopyIconStyle = styled(CopyIcon)`
  cursor: pointer;
  height: 24px;
  margin-top: 3px;
`

export const IconContainer = styled.div`
  margin-left: 6px;
  height: 32px;

  &:hover {
    svg .a {
      fill: ${color.blueTint20};
    }
  }
`

export const HeadersDocumentation = styled.p`
  font: normal normal normal 16px/19px KONE Information;
  margin-bottom: 12px;
`

export const Line = styled.hr`
  margin-top: 12px;
  margin-bottom: 20px;
  border: 1px solid ${color.gray70};
  width: 100%;
`

export const DocumentationLinksContainer = styled.div`
  margin-bottom: 54px;
`

export const DocumentationWord = styled.span`
  margin-right: 18px;
  text-transform: uppercase;
`

export const WiderCancelButton = styled(Button)`
  width: 100%;
`

export const OkIcon = styled(OkCheckIcon)`
  margin: 10px 0 45px 0;
`

export const StyledTooltip = styled.div`
  .ant-tooltip-inner {
    color: ${color.black};
    background-color: ${color.white};
    width: 55px;
    height: 16px;
    text-align: right;
    font: normal normal normal 14px/16px KONE Information;
    margin-top: -6px;
    margin-right: 8px;
    min-width: 28px;
    min-height: 28px;
  }
  .ant-tooltip-arrow {
    margin-top: -2px;
  }
  .ant-tooltip-arrow-content {
    background-color: ${color.white};
  }
`

export const LinkButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  color: ${color.blueBase};
  transition: all 0.1s;

  &:hover {
    color: ${color.blueTint20};
    svg .a {
      fill: ${color.blueTint20};
    }
  }
`

export const DocumentationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`

export const DocumentationLabel = styled.div`
  font-size: 18px;
`
