import React, { FC } from 'react'
import { PageContainer, StepBox, Circle, NumberText, VerticalText } from './Styles'
import { H3, P, HeroImage } from '../../shared/components'
import { DarkLayoutContainer } from '../../App/Styles'
import { QuickStartHeroImage, Step1, Step2, Step3, Step4, SEOProps } from '../../shared/strings/QuickStartTextContent'
import getStartedHeroImage from '../../assets/bg-img-getting-started.jpg'
import getStartedHeroImageLarge from '../../assets/bg-img-getting-started@2x.jpg'
import TitleWrapper from '../../shared/components/TitleWrapper'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

const QuickStart: FC = () => {
  const steps = [
    {
      number: '1',
      title: Step1.title,
      content: Step1.body,
    },
    {
      number: '2',
      title: Step2.title,
      content: Step2.body,
    },
    {
      number: '3',
      title: Step3.title,
      content: Step3.body,
    },
    {
      number: '4',
      title: Step4.title,
      content: Step4.body,
    },
  ]

  const images = {
    small: getStartedHeroImage,
    large: getStartedHeroImageLarge,
  }
  return (
    <>
      <TitleWrapper title={SEOProps.title} metaTags={SEOProps.metaTags} />
      <HeroImage
        title={QuickStartHeroImage.title}
        body={QuickStartHeroImage.body}
        image={images}
        button={false}
        ingressWidth={'610px'}
      />
      <DarkLayoutContainer>
        <PageContainer>
          {steps.map((step) => (
            <StepBox key={step.number}>
              <Circle>
                <NumberText>{step.number}</NumberText>
              </Circle>
              <VerticalText>
                <H3>{step.title}</H3>
                <P dangerouslySetInnerHTML={{ __html: sanitizer(step.content) }}></P>
              </VerticalText>
            </StepBox>
          ))}
        </PageContainer>
      </DarkLayoutContainer>
    </>
  )
}

export default QuickStart
