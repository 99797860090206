import styled from 'styled-components'
import { H3, H2, H4, H6, P, Button } from '../../../../shared/components'
import { color } from '../../../../shared/utils/styles'
import { Col } from 'antd'
import { ReactComponent as BuildingTopology } from '../../../../shared/assets/img-building-topology.svg'
import { ReactComponent as SelfIllustration } from '../../../../shared/assets/img-test-illustration.svg'
import { ReactComponent as GuidedTest } from '../../../../shared/assets/img-guided-test.svg'
import { ReactComponent as PilotTest } from '../../../../shared/assets/img-pilot-test.svg'
import { ReactComponent as BuildingOfficeIcon } from '../../../../assets/icons-office-building.svg'
import { ReactComponent as PreProductionTest } from '../../../../shared/assets/img-preproduction-test.svg'
import { ReactComponent as RightArrow } from '../../../../assets/right-arrow.svg'

export const CardContainer = styled.div`
  background: var(--gray-85-1c2226) 0% 0% no-repeat padding-box;
  background: #1c2226 0% 0% no-repeat padding-box;
  margin-top: 2%;
  padding: 2%;
  border-radius: 2% / 4%;
`

export const HeadingText = styled(H3)`
  margin-top: 0px;
  margin-left: 1%;
  border-bottom-style: solid;
  border-bottom-color: ${color.white};
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
  flex-direction: column;
`
export const SvgBottomText = styled(H2)`
  color: ${color.blueBase};
`

export const ContentHeading = styled(H4)`
  text-decoration: underline;
  text-underline-offset: 8px;
  text-align: center;
  margin-bottom: 3%;
  text-decoration-color: ${color.blueBase};
`

export const ContentHeadingLeft = styled(H4)`
  text-decoration: underline;
  text-underline-offset: 8px;
  margin-bottom: 3%;
  text-decoration-color: ${color.blueBase};
`

export const DownloadTittle = styled(H6)``

export const TestForSolutionValidationSubTittle = styled(P)`
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
`
export const ContentInterSection = styled.div`
  width: 80%;
  padding-right: 10%;
`

export const BuildingTopologyContainer = styled(BuildingTopology)``
export const PreProductionTestImage = styled(PreProductionTest)`
  width: 200px;
  height: 150px;
`
export const BuildingTopologyImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
`
export const SelfIllustrationSvg = styled(SelfIllustration)`
  width: 200px;
  height: 150px;
`
export const GuidedTestSvg = styled(GuidedTest)`
  width: 200px;
  height: 150px;
`
export const PilotTestSvg = styled(PilotTest)`
  width: 200px;
  height: 150px;
`

export const CardInnerContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2%;
`

export const KnowMoreButton = styled(Button)`
  margin-top: 3%;
`
export const VirtualBuildingButtonContainer = styled(Col)`
  display: flex;
  align-item: center;
  flex-direction: row;
`
export const VirtualBuildingButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  &:hover {
    border-bottom-style: solid;
    border-bottom-color: ${color.blueBase2};
  }
`

export const VirtualBuildingButtonText = styled.span`
  padding-left: 5px;
  cursor: pointer;
`

export const BuildingOfficeSvg = styled(BuildingOfficeIcon)`
  padding: 1%;
`

export const PopUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const PopUpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1%;
  padding-top: 2%;
  padding-bottom: 2%;
  overflow: hidden;
  height: 400px;
  text-align: center;
  &:hover {
    overflow: auto;
  }
  ::-webkit-scrollbar {
    width: 0.4em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
`
export const NewPopUpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1%;
  padding-top: 2%;
  padding-bottom: 2%;
  overflow: hidden;
  height: 240px;
  text-align: left;
  &:hover {
    overflow: auto;
  }
  ::-webkit-scrollbar {
    width: 0.4em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
`
export const BuildingTopologyPopUpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
  text-align: center;
`
export const ImagePreview = styled.img`
  width: 100%;
  height: 100vh;
`

export const ImagePreviewBuilding4 = styled.img`
  width: 100%;
  height: 62vh;
`
export const RightArrowContainer = styled(RightArrow)`
  width: 3.5%;
  height: 12vh;
  margin-left: -60px;
  margin-right: 30px;
`
