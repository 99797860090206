import { OpenAPIV3 } from 'openapi-types'
import React, { FC } from 'react'
import RestDocumentation from '../RestDocumentation'

const WebhookCallbackAPIDocumentation: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const openAPISpec = require(`./webhook-callback-api.json`) as OpenAPIV3.Document

  return <RestDocumentation apiName={'webhook-callback-api'} openAPISpec={openAPISpec} />
}

export default WebhookCallbackAPIDocumentation
