import { API } from './API'

const PATH_PREFIX = '/customer/subscription'

const queryNames = {
  GET_SUBSCRIPTION: '/',
  GET_ALL: 'types',
  UPDATE_SUBSCRIPTION: '/',
  CREATE_SUBSCRIPTION: 'create',
}
const getSubscription = async (): Promise<any> => {
  return await API.get(`${PATH_PREFIX}`)
}

const getSubscriptionType = async (): Promise<any> => {
  return await API.get(`${PATH_PREFIX}/types`)
}

const updateSubscription = async (data: any[]): Promise<any> => {
  const parameters = {
    body: data,
  }
  return await API.post(`${PATH_PREFIX}`, parameters)
}
const createSubscription = async (data: any): Promise<any> => {
  const parameters = {
    body: data,
  }
  return await API.post(`${PATH_PREFIX}/create`, parameters)
}

export const NotificationAPI = {
  queryNames,
  getSubscription,
  getSubscriptionType,
  updateSubscription,
  createSubscription,
}
