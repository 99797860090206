import React, { FC } from 'react'
import { ReactComponent as RightArrow } from '../../../assets/icons-right-arrow.svg'
import { APILink, Name, Description, NavigateTo } from './Styles'

type APIProps = {
  name: string
  description?: string
  path: string
}

const APILinkCard: FC<APIProps> = ({ name, description, path }) => {
  return (
    <APILink to={path}>
      <Name>{name}</Name>
      {description && <Description size="small">{description}</Description>}
      <NavigateTo>
        <RightArrow />
      </NavigateTo>
    </APILink>
  )
}

export default APILinkCard
