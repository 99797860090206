export enum MfaType {
  TOTP = 'SOFTWARE_TOKEN_MFA',
  SMS_MFA = 'SMS_MFA',
}

export enum MfaMethod {
  TOTP = 'TOTP',
  SMS = 'SMS',
  NOMFA = 'NOMFA',
}

export enum MfaSetting {
  PROFILE_ENABLE = 'MFA_ENABLE',
  PROFILE_DISABLE = 'MFA_DISABLE',
  REGISTRATION = 'MFA_REGISTRATION',
  LOGIN = 'MFA_LOGIN',
}
