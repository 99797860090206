import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogJune24 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        June 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        June release notes summarizes latest changes for Equipment Status API, Service Info API and API Portal
        documentation fix.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Equipment Status</li>
          <UL>
            <li>
              The field names in the equipment info object are updated for{' '}
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentAvailabilityByIds'}>
                {' '}
                Equipment Availability REST API
              </Link>{' '}
              and{' '}
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentMovementByIds'}>
                {' '}
                Get equipment movement information
              </Link>
              .{' '}
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              <q>salesOrderNumber</q> field from the
              <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrderById'}> Service Info Details API</Link> is
              now available in string format.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              The Equipment Info object from
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEscalatorMovementByIds'}>
                {' '}
                Get escalator movement information
              </Link>{' '}
              is updated as per response payload.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
