import styled from 'styled-components'
import { H3, H2, H6, H5, Button } from '../../../shared/components'
import { Col } from 'antd'
import { ReactComponent as BuildingTerminology } from '../../../shared/assets/img-glossary-building.svg'
import { ReactComponent as EquipmentTerminology } from '../../../shared/assets/img-glossary-equipment.svg'
import { ReactComponent as EventsTerminology } from '../../../shared/assets/img-glossary-events.svg'
import { ReactComponent as UseCase } from '../../../shared/assets/img-glossary-use-case.svg'
import { ReactComponent as DoubleDeck } from '../../../shared/assets/double-deck-elevator.svg'
import { ReactComponent as MediaId } from '../../../shared/assets/img-glossary-media-id.svg'
import { ReactComponent as TransferFloor } from '../../../shared/assets/transfer-floor.svg'
import { ReactComponent as SpecialCall } from '../../../shared/assets/special-call.svg'
import { ReactComponent as Events } from '../../../shared/assets/events.svg'
import { ReactComponent as Tier } from '../../../shared/assets/Tier.svg'
import { color } from '../../../shared/utils/styles'

export const LeftCol = styled(Col)`
  padding: 0 85px;
`

export const RightCol = styled(Col)`
  background-color: #0c0d0d;
  padding: 0 50px 0 0;
`

export const LeftColFirst = styled(Col)`
  padding: 45px 85px 0 85px;
`

export const RightColFirst = styled(Col)`
  background-color: #0c0d0d;
  padding: 4em 50px 0 50px;
`

export const LeftColLast = styled(Col)`
  padding: 0 85px 50px 85px;
`

export const NewSectionSubTitle = styled(H3)`
  margin-top: 150px;
  margin-bottom: 14px;
`

export const BuildingTerminologySvg = styled(BuildingTerminology)`
  width: 40%;
`

export const EquipmentTerminologySvg = styled(EquipmentTerminology)`
  width: 40%;
`

export const EventsTerminologySvg = styled(EventsTerminology)`
  width: 40%;
`

export const SpecialCallSvg = styled(SpecialCall)`
  width: 40%;
`
export const TierSvg = styled(Tier)`
  width: 40%;
`
export const MediaIdSvg = styled(MediaId)`
  width: 40%;
`

export const UseCaseSvg = styled(UseCase)`
  width: 40%;
`

export const DoubleDeckSvg = styled(DoubleDeck)`
  width: 40%;
`

export const TransferFloorSvg = styled(TransferFloor)`
  width: 40%;
`

export const EventsSvg = styled(Events)`
  width: 40%;
`

export const HeadingText = styled(H2)`
  padding-top: 2%;
  padding-bottom: 0%;
  text-align: center;
`

export const HeadingBellowText = styled(H6)`
  text-align: center;
  padding-right: 20%;
  padding-left: 20%;
`

export const HeadingSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const CardColumnSection = styled(Col)``

export const CardColumnInnerSection = styled.div`
  background-color: ${color.blackShadow};
  justify-content: center;
  display: flex;
  padding: 5%;
  align-items: center;
  flex-direction: column;
  margin: 5%;
`

export const ContentHeading = styled(H5)`
  text-align: center;
  margin-top: 3%;
`

export const KnowMoreButton = styled(Button)`
  margin-top: 2%;
`

export const PopUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const PopUpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1%;
  overflow: hidden;
  height: 180px;
  text-align: left;
  margin-bottom: 5%;
  &:hover {
    overflow: auto;
  }
  ::-webkit-scrollbar {
    width: 0.4em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
`
