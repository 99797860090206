export const color = {
  white: '#ffffff',
  black: '#000000',
  red: '#ed4337',
  green: '#58ac32',
  blueBase: '#0071b9',
  blueBase2: '#005a94',
  lightBlue: '#0C9FED',
  blueTint20: '#338dc7',
  blueTint60: '#99c6e3',
  gray03: '#f5f6f7',
  gray10: '#e3e5e6',
  gray15: '#D4D7D9',
  gray20: '#c6cacc',
  gray30: '#aaafb3',
  gray40: '#8C9499',
  gray60: '#566066',
  gray70: '#3d464c',
  gray75: '#313131',
  gray80: '#262e33',
  gray85: '#1C2226',
  gray85Opacity: 'rgba(28,34,38,0.92)',
  gray90: '#11161a',
  gray95: '#707070',
  gray100: '#C6CACC',
  greenBase: '#58AB27',
  petrolBlue: '#0F212D',
  darkGray: '#1A2125',
  orange: '#ffc500',
  blackShadow: '#00000033',
  linkHoverColorBlue: '#0071B9',
  apiRed: '#ed555a',
  apiOrange: '#f0a00c',
  apiGreen: '#64a340',
  magenta: '#de198d',
}

// media query breakpoints
export const size = {
  xsmall: '480px',
  small: '576px',
  medium: '768px',
  large: '1024px',
  xlarge: '1200px',
}
