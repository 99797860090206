export const InvitationsStrings = {
  failedToFetchInvitationsList: 'Failed to fetch invitations list',
  noOpenInvitationsMessage: 'You don’t have any open invitations',
  organization: 'Organization',
  expires: 'Expires',
  decline: 'Decline',
  declined: 'Declined',
  accept: 'Accept',
  accepted: 'Accepted',
  myInvitations: 'My invitations',
  modalTitle: 'Please note!',
  modalConfirmText: 'Are you sure you want to decline the invitation?',
  modalButtonDecline: 'Yes, decline',
  modalButtonGoBack: "No, let's go back",
  failedToAcceptInvitation: 'Failed to accept invitation',
  failedToDeclineInvitation: 'Failed to decline invitation',
  declinedInvitationSuccess: 'Successfully declined invitation',
  internalServerError: 'Internal server error',
  newInvitation: 'New invitation',
}
