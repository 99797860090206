import React, { FC, useEffect } from 'react'
import { Select, Tooltip } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { useAuth } from '../../shared/contexts/authContext'
import { Badge } from '../../shared/components'
import {
  OrganizationSelect,
  OrganizationSelectContainer,
  SelectDropdownStyle,
  StyledTooltip,
  OrganizationLabel,
} from './Styles'
import { OrganizationsStrings } from '../../shared/strings/OrganizationsContent'
import { TOOLTIP_VISIBILITY_MLS } from '../../shared/constants/common'
import { useIsMounted } from '../../shared/hooks/useIsMounted'

const { Option, OptGroup } = Select

const SelectOrganization: FC = () => {
  const isMounted = useIsMounted()

  const {
    currentUser,
    currentOrganization,
    customerOrganizations,
    changeCurrentOrganization,
    newOrganizationIdList,
    removeFromNewOrganizationIdList,
    isShowNewOrganizationTooltip,
    setIsShowNewOrganizationTooltip,
  } = useAuth()

  useEffect(() => {
    if (!isShowNewOrganizationTooltip) {
      return
    }

    setTimeout(() => {
      if (!isMounted) {
        return
      }
      setIsShowNewOrganizationTooltip(false)
    }, TOOLTIP_VISIBILITY_MLS)

    return () => {
      setIsShowNewOrganizationTooltip(false)
    }
  }, [isShowNewOrganizationTooltip])

  const getOrganizationOptions = () => {
    return customerOrganizations!.map((org) => {
      const isNew = newOrganizationIdList.includes(org.id)

      return (
        <Option id="select-organization-name-option-js" key={org.externalId} value={org.externalId}>
          {org.name} {isNew && <Badge>{OrganizationsStrings.new}</Badge>}
        </Option>
      )
    })
  }

  const onSelectChange = (value: SelectValue) => {
    const selectedOrganization = customerOrganizations!.find((org) => org.externalId === value)
    removeFromNewOrganizationIdList(selectedOrganization!.id)
    changeCurrentOrganization(selectedOrganization!)
  }

  if (currentUser?.isKoneAdmin || !currentOrganization || !customerOrganizations) {
    return null
  }

  return (
    <OrganizationSelectContainer>
      <OrganizationLabel>{OrganizationsStrings.selectOrganizationLabel}</OrganizationLabel>
      <SelectDropdownStyle />
      <Tooltip
        id="new-organization-added-tooltip-js"
        placement="right"
        title={OrganizationsStrings.newOrganizationAdded}
        visible={isShowNewOrganizationTooltip}
        getPopupContainer={(trigger) => {
          return trigger
        }}
      >
        <StyledTooltip>
          <OrganizationSelect
            id="organization-select-js"
            size="large"
            value={currentOrganization.externalId}
            onChange={onSelectChange}
          >
            <OptGroup label={OrganizationsStrings.selectOrganizationLabel}>{getOrganizationOptions()}</OptGroup>
          </OrganizationSelect>
        </StyledTooltip>
      </Tooltip>
    </OrganizationSelectContainer>
  )
}

export default SelectOrganization
