import { API } from './API'

export const SUBSCRIPTION_PATH = '/admin/subscription'

const queryNames = {
  GET_SUBSCRIPTION: '',
  GET_SUBSCRIPTION_TYPE: 'types',
}

const getSubscription = async (): Promise<any> => {
  return await API.get(`${SUBSCRIPTION_PATH}`)
}

const getSubscriptionTypes = async (): Promise<any> => {
  return await API.get(`${SUBSCRIPTION_PATH}/types`)
}

export const SubscriptionAPI = {
  queryNames,
  getSubscription,
  getSubscriptionTypes,
}
