import styled from 'styled-components'
import { Button, Select } from 'antd'
export const TypeSelector = styled(Select)`
  width: 96%;
  margin-bottom: 10px;
  overflow-y: 'scroll';
  margin-right: 70px;
`

export const ImageName = styled.div`
  text-align: center;
`
export const ImageBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 89%;
`
export const PreviewImage = styled.img`
  width: 250px;
  height: 150px;
`
export const UploadBtn = styled(Button)`
  width: 60%;
  margin-top: 5px;
`
export const InputColumnUp = styled.div`
  margin-bottom: 3%;
`
export {}
