import React, { FC, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import TableHeaderRow from '../ListTableHeaderRow'
import TableContentRow from '../ListTableContentRow'
import { TierPlanAPI } from '../../../../shared/api/tiers'
import { TierPlan } from '../../../../shared/models/tierPlan'
import { LoadingAnimated, Toast } from '../../../../shared/components'
import Button from '../../../../shared/components/Button'
import { TierPlanStrings } from '../../../../shared/strings/OrganizationsContent'
import { ApiProductContainer, TierContainer } from '../TierPlanList/Styles'
import { ButtonsContainer, ContentContainer, TierSelect } from './Styles'

type EditTierProps = {
  orgId: number
  onCancel: () => void
}

const EditTierPlan: FC<EditTierProps> = ({ orgId, onCancel }) => {
  const queryName = `${TierPlanAPI.queryNames.GET_BY_ORG_ID}${orgId}`
  const queryClient = useQueryClient()
  const {
    isLoading: isTierPlanLoading,
    error: loadTierError,
    data: tierPlans = [],
  } = useQuery(queryName, () => TierPlanAPI.getDefaultTierByOrganizationId(orgId))

  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [isSuccessVisible, setIsSuccessVisible] = useState(false)
  const [selectedTier, setSelectedTier] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const hideToastNotification = () => {
    setIsSuccessVisible(false)
    setIsErrorVisible(false)
  }

  const fetchTierOfApiProduct = (apiProduct: string): string => {
    const tierPlan = tierPlans.find((plan: TierPlan) => plan.productName === apiProduct)
    return tierPlan?.tier || TierPlanStrings.light
  }

  const onSelectTierPlan = (value: any) => {
    setSelectedTier(value)
  }

  const onSave = async () => {
    hideToastNotification()
    setIsSaving(true)
    try {
      await TierPlanAPI.saveTier(orgId, 'rtm', selectedTier)
      queryClient.invalidateQueries(queryName)
      setSelectedTier('')
      setIsSuccessVisible(true)
    } catch (err: any) {
      setIsErrorVisible(true)
    } finally {
      setIsSaving(false)
    }
  }

  if (isTierPlanLoading) {
    return <LoadingAnimated />
  }
  if (loadTierError) {
    return <ContentContainer>{TierPlanStrings.errorLoadTierPlan}</ContentContainer>
  }

  // Only RTM product is applied rate limit
  return (
    <ContentContainer>
      <Toast
        isVisible={isErrorVisible}
        text={TierPlanStrings.saveError}
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <Toast
        isVisible={isSuccessVisible}
        text={TierPlanStrings.saveSuccess}
        onDismiss={() => setIsSuccessVisible(false)}
        type="success"
      />
      <TableHeaderRow>
        <ApiProductContainer>{TierPlanStrings.headerProduct}</ApiProductContainer>
        <TierContainer>{TierPlanStrings.headerTier}</TierContainer>
      </TableHeaderRow>
      <TableContentRow>
        <ApiProductContainer>{TierPlanStrings.rowRtmProduct}</ApiProductContainer>
        <TierContainer>
          <TierSelect id="tier-plan-select-js" defaultValue={fetchTierOfApiProduct('rtm')} onChange={onSelectTierPlan}>
            {[TierPlanStrings.light, TierPlanStrings.standard, TierPlanStrings.premium].map(
              (plan: string, key: number) => (
                <TierSelect.Option id={`select-tier-plan-${plan}-js`} value={plan} key={key}>
                  {plan}
                </TierSelect.Option>
              )
            )}
          </TierSelect>
        </TierContainer>
      </TableContentRow>
      <ButtonsContainer>
        <Button id="edit-tier-cancel-button-js" onClick={onCancel} disabled={isSaving || isTierPlanLoading} ghost>
          Cancel
        </Button>
        <Button
          id="edit-tier-save-button-js"
          type="primary"
          onClick={onSave}
          disabled={!selectedTier || isSaving || isTierPlanLoading}
        >
          Save Changes
        </Button>
      </ButtonsContainer>
    </ContentContainer>
  )
}

export default EditTierPlan
