import React, { Fragment, FC, useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import Auth from '@aws-amplify/auth'
import { useAuth } from '../../../shared/contexts/authContext'
import { SidePanel, Modal, Table } from '../../../shared/components'
import { ErrorPageStrings } from '../../../shared/strings/ErrorPageContent'
import { MfaSetting } from '../../../shared/models/mfa'
import MFAInfo from '../../../App/Header/AuthForms/MFAInfo'
import { useLocation, useHistory } from 'react-router-dom'
import {
  ProfileDetails,
  Section,
  UserNameRowContent,
  RowButton,
  DeleteAccountCheckbox,
  CheckboxLabel,
  ModalBodyContainer,
  ModalBodyTitle,
  ModalBodyText,
  ModalFooterButtons,
  ModalButton,
} from './Styles'
import { HeaderRow, ContentRow } from '../Styles'
import ChangePasswordForm from '../ChangePasswordForm'
import { MyProfileStrings } from '../../../shared/strings/MyProfileContent'
import { GeneralFormStrings } from '../../../shared/strings/GeneralFormContent'
import { OrganizationsAPI } from '../../../shared/api/organizations'

const { Column } = Table

const Profile: FC = () => {
  const { signOut, currentUser, customerOrganizations, isMfaEnabledForUser, resetMfaCode } = useAuth()
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] = useState(false)
  const [isCannotDeleteAccountErrorVisible, setIsCannotDeleteAccountErrorVisible] = useState(false)
  const [isDeleteAccountInternalErrorVisible, setIsDeleteAccountInternalErrorVisible] = useState(false)
  const [isDeleteAccountSuccessVisible, setIsDeleteAccountSuccessVisible] = useState(false)
  const [isAcknowledgementCheckboxChecked, setIsAcknowledgementCheckboxChecked] = useState(false)
  const [singleUserOrganizations, setSingleUserOrganizations] = useState<string[]>([])
  const [isMfa, setIsMfa] = useState(false)
  const [isMfaProfileSettingOpen, setIsMfaProfileSettingOpen] = useState(false)

  const { mutateAsync: deleteAccount, isLoading: isDeleteAccountLoading } = useMutation(OrganizationsAPI.deleteAccount)
  const getOrganizationsList = () => {
    return customerOrganizations?.map((org, index) => {
      return <ContentRow key={index}>{org.name}</ContentRow>
    })
  }

  useEffect(() => {
    setIsMfa(isMfaEnabledForUser())
    if (location.state) {
      setIsMfaProfileSettingOpen(true)
      history.replace('/dashboard/my-profile')
    }
  }, [])

  const onChangePasswordClose = () => {
    setIsChangePasswordOpen(false)
  }

  const onChangePasswordOpen = () => {
    setIsChangePasswordOpen(true)
  }

  const onMfaProfileSettingClose = () => {
    resetMfaCode()
    setIsMfaProfileSettingOpen(false)
    setIsMfa(isMfaEnabledForUser())
  }

  const onShowDeleteAccountModal = () => {
    setIsDeleteAccountModalVisible(true)
  }

  const onHideDeleteAccountModal = () => {
    setIsDeleteAccountModalVisible(false)
    setIsCannotDeleteAccountErrorVisible(false)
    setIsDeleteAccountInternalErrorVisible(false)
    setIsDeleteAccountSuccessVisible(false)
    setIsAcknowledgementCheckboxChecked(false)
    setSingleUserOrganizations([])
  }

  const onHandleDelete = async () => {
    try {
      await deleteAccount()
      // remove Cognito tokens from the local storage so that a user will be signed out in case of page refresh
      await Auth.signOut()
      setIsDeleteAccountSuccessVisible(true)
    } catch (e) {
      if (e.response?.status === 400 && e.response?.data?.organizationNames?.length > 0) {
        setIsCannotDeleteAccountErrorVisible(true)
        setSingleUserOrganizations(e.response.data.organizationNames)
      } else {
        console.error(e)
        setIsDeleteAccountInternalErrorVisible(true)
      }
    }
  }

  const onReturnToHomePageClick = async () => {
    await signOut()
  }

  const toggleModalAcknowledgementAndConditions = () => {
    setIsAcknowledgementCheckboxChecked(!isAcknowledgementCheckboxChecked)
  }

  const onMfaDisable = () => {
    setIsMfaProfileSettingOpen(true)
  }

  const onMfaEnable = () => {
    setIsMfaProfileSettingOpen(true)
  }

  const handleMfaDisabled = () => {
    setIsMfaProfileSettingOpen(false)
    setIsMfa(isMfaEnabledForUser())
  }

  const getSingleUserOrganizationsText = () => {
    return singleUserOrganizations.map((org) => (
      <Fragment key={org}>
        <br />
        {org}
      </Fragment>
    ))
  }

  const getDeleteAccountModalBody = () => {
    if (isDeleteAccountSuccessVisible) {
      return (
        <>
          <ModalBodyTitle>{MyProfileStrings.successDeletedTitleMessage}</ModalBodyTitle>
          <ModalBodyText>{MyProfileStrings.successDeletedBodyMessage}</ModalBodyText>
        </>
      )
    }

    if (isDeleteAccountInternalErrorVisible) {
      return (
        <>
          <ModalBodyTitle>{ErrorPageStrings.title}</ModalBodyTitle>
          <ModalBodyText>
            {ErrorPageStrings.tryAgain}
            <br />
            {ErrorPageStrings.otherOption} <a href="mailto:api-support@kone.com">{ErrorPageStrings.ApiSupport}</a>.
          </ModalBodyText>
        </>
      )
    }

    if (isCannotDeleteAccountErrorVisible) {
      return (
        <>
          <ModalBodyTitle>
            {MyProfileStrings.lastMember}
            {getSingleUserOrganizationsText()}
          </ModalBodyTitle>
          <ModalBodyText>{MyProfileStrings.removeApplications}</ModalBodyText>
        </>
      )
    }

    return (
      <>
        <ModalBodyTitle>{MyProfileStrings.confirmAccountDeleteTopMessage}</ModalBodyTitle>
        <ModalBodyText>{MyProfileStrings.confirmAccountDeleteMiddleMessage}</ModalBodyText>
        <DeleteAccountCheckbox
          id="accept-acknowledgement-conditions-checkbox-js"
          checked={isAcknowledgementCheckboxChecked}
          onClick={toggleModalAcknowledgementAndConditions}
        >
          <CheckboxLabel htmlFor="acknowledgement-checkbox-js">{MyProfileStrings.acknowledgement}</CheckboxLabel>
        </DeleteAccountCheckbox>
      </>
    )
  }

  const getDeleteAccountModalButtons = () => {
    if (isDeleteAccountSuccessVisible) {
      return (
        <ModalButton
          id="delete-account-success-button-js"
          size="large"
          key="back"
          type="primary"
          onClick={onReturnToHomePageClick}
        >
          {MyProfileStrings.returnToHomePageButton}
        </ModalButton>
      )
    }

    if (isCannotDeleteAccountErrorVisible || isDeleteAccountInternalErrorVisible) {
      return (
        <ModalButton
          id="cancel-delete-account-button-js"
          size="large"
          key="back"
          type="primary"
          onClick={onHideDeleteAccountModal}
        >
          {MyProfileStrings.okGoBackButton}
        </ModalButton>
      )
    }

    return (
      <>
        <ModalButton
          id="delete-account-button-js"
          size="large"
          disabled={!isAcknowledgementCheckboxChecked}
          loading={isDeleteAccountLoading}
          key="submit"
          ghost={true}
          onClick={onHandleDelete}
        >
          {MyProfileStrings.confirmDeleteAccountButton}
        </ModalButton>
        <ModalButton
          id="cancel-delete-account-button-js"
          size="large"
          key="back"
          type="primary"
          onClick={onHideDeleteAccountModal}
        >
          {MyProfileStrings.goBackButton}
        </ModalButton>
      </>
    )
  }

  const fullName = `${currentUser?.firstName} ${currentUser?.lastName}`

  const getMFAProfileSetting = () => {
    return (
      <>
        {isMfa ? (
          <ContentRow>
            {MyProfileStrings.AccountEnabledWith2FAText}
            <RowButton id="mfa-profile-disable-button-js" type="primary" onClick={onMfaDisable}>
              {MyProfileStrings.disableMFAButton}
            </RowButton>
          </ContentRow>
        ) : (
          <ContentRow>
            {MyProfileStrings.ProtectAccountWith2FAText}
            <RowButton id="mfa-profile-enable-button-js" type="primary" onClick={onMfaEnable}>
              {MyProfileStrings.enableMFAButton}
            </RowButton>
          </ContentRow>
        )}
        <SidePanel
          isOpen={isMfaProfileSettingOpen}
          title={MyProfileStrings.twoFactorAuthHeaderText}
          showBackButton={true}
          onClose={onMfaProfileSettingClose}
        >
          <MFAInfo
            setting={isMfa ? MfaSetting.PROFILE_DISABLE : MfaSetting.PROFILE_ENABLE}
            callbackFn={handleMfaDisabled}
          />
        </SidePanel>
      </>
    )
  }

  return (
    <>
      <ProfileDetails>
        <Section>
          <HeaderRow>{MyProfileStrings.accountSettings}</HeaderRow>
          <ContentRow>
            <UserNameRowContent title={fullName}>{fullName}</UserNameRowContent>
            <Column title={currentUser?.email}>{currentUser?.email}</Column>
            <RowButton
              id="open-delete-account-button-js"
              danger
              ghost
              onClick={onShowDeleteAccountModal}
              style={{ display: currentUser?.isKoneOwner ? 'none' : 'block' }}
            >
              {MyProfileStrings.deleteAccount}
            </RowButton>
          </ContentRow>
          <ContentRow>
            <Column>{GeneralFormStrings.password}</Column>
            <RowButton id="open-change-password-button-js" ghost onClick={onChangePasswordOpen}>
              {MyProfileStrings.change}
            </RowButton>
          </ContentRow>
          {getMFAProfileSetting()}
        </Section>
        <Section>
          <HeaderRow>{MyProfileStrings.organizations}</HeaderRow>
          {getOrganizationsList()}
        </Section>
      </ProfileDetails>
      <SidePanel
        isOpen={isChangePasswordOpen}
        title={MyProfileStrings.changePassword}
        showBackButton={true}
        onClose={onChangePasswordClose}
      >
        <ChangePasswordForm />
      </SidePanel>

      <Modal
        key="delete-account-modal"
        title={MyProfileStrings.modalTitle}
        visible={isDeleteAccountModalVisible}
        width={672}
        onOk={onHandleDelete}
        onCancel={onHideDeleteAccountModal}
        closable={false}
        maskClosable={!isDeleteAccountSuccessVisible}
        keyboard={!isDeleteAccountSuccessVisible}
        footer={[<ModalFooterButtons key="modal-buttons">{getDeleteAccountModalButtons()}</ModalFooterButtons>]}
      >
        <ModalBodyContainer>{getDeleteAccountModalBody()}</ModalBodyContainer>
      </Modal>
    </>
  )
}

export default Profile
