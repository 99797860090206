import { OpenAPIV3 } from 'openapi-types'
import React, { FC } from 'react'
import RestDocumentation from '../RestDocumentation'

const WebhookManagementAPIDocumentation: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const openAPISpec = require(`./webhook-management-api.json`) as OpenAPIV3.Document

  return <RestDocumentation apiName={'webhook-management-api'} openAPISpec={openAPISpec} />
}

export default WebhookManagementAPIDocumentation
