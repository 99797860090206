import styled from 'styled-components'
import { color, size } from '../../utils/styles'

const Line = styled.hr`
  width: 50px;
  border-width: 1px;
  border-style: solid;
  color: ${color.gray10};
  margin-top: 0.6rem;

  @media (min-width: ${size.large}) {
    border-width: 2px;
    margin-top: 1.5rem;
    width: 90px;
  }
`

export default Line
