import React, { FC } from 'react'
import { H1, H3, H5 } from '../../components'
import { ContentWrapper } from '../Styles'
import { NotFoundPageStrings } from '../../strings/NotFoundPageContent'

type Props = {
  withMargin?: boolean
}

const NotFoundPage: FC<Props> = ({ withMargin }): JSX.Element => (
  <ContentWrapper withMargin={withMargin}>
    <H1>{NotFoundPageStrings.notFound}</H1>
    <H3>{NotFoundPageStrings.title}</H3>
    <H5>{NotFoundPageStrings.pageDoesNotExist} </H5>
  </ContentWrapper>
)

export default NotFoundPage
