import styled from 'styled-components'
import { Col } from 'antd'
import { color } from '../../../../shared/utils/styles'
import { Button } from '../../../../shared/components'
import { ReactComponent as SelfIllustration } from '../../../../shared/assets/img-test-illustration.svg'
import { ReactComponent as GuidedTest } from '../../../../shared/assets/img-guided-test.svg'
import { ReactComponent as PilotTest } from '../../../../shared/assets/img-pilot-test.svg'
import { ReactComponent as SolutionDeployment } from '../../../../shared/assets/img-solution-deployment.svg'
import { H3 } from '../../../../shared/components/Typography'

export const TypeContainer = styled.div`
  width: 224px;
`
export const ListTableHeaderRow = styled.div`
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
  margin-top: 20px;
`

export const TableContentRow = styled.div`
  display: flex;
  background-color: ${color.black};
  align-items: center;
  padding: 14px 10px;
  margin-bottom: 5px;
  width: 500px;
`

export const CardContainer = styled.div`
  margin-top: 2%;
  background: var(--gray-85-1c2226) 0% 0% no-repeat padding-box;
  background: #1c2226 0% 0% no-repeat padding-box;
  opacity: 1;
`
export const HeadingText = styled(H3)`
  margin-top: 0px;
  margin-left: 1%;
  border-bottom-style: solid;
  border-bottom-color: ${color.white};
`
export const Content = styled.div`
  display: flex;
`
export const SelfIllustrationSvg = styled(SelfIllustration)``
export const GuidedTestSvg = styled(GuidedTest)``
export const PilotTestSvg = styled(PilotTest)``
export const SolutionDeploymentSvg = styled(SolutionDeployment)`
  width: 100%;
`
export const RightColFirst = styled(Col)`
  padding: 12em 0px 0px 0px;
`
export const LeftColLast = styled(Col)`
  padding: 0 85px 50px 85px;
`
export const LeftColFirst = styled(Col)`
  padding: 25px 0px 0 30px;
`
export const ContactUsButton = styled(Button)`
  height: 40px;
  text-transform: uppercase;
`

export const ContentContainer = styled(Col)`
  padding: 3%;
`
