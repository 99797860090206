import React, { FC, useContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Steps } from 'antd'
import {
  ButtonContainer,
  ButtonContainer2,
  NextActiveArrow,
  NextPassiveArrow,
  PreviousActiveArrow,
  PreviousPassiveArrow,
  SingleStep,
  StepContainer,
  StepText,
  Wrapper,
  HeaderContainer,
  PartnerH4,
} from './Styles'
import PartnerInfo from './PartnerInfo'
import { BackButton, Toast } from '../../../shared/components'
import { PartnerStrings } from '../../../shared/strings/PartnersContent'
import ContactInfo from './ContactInfo'
import { IntegratorLinks } from './IntegratorLinks'
import { useHistory } from 'react-router-dom'
import { CommonAPI } from '../../../shared/api/salespage'
import { PartnerContext } from './state'
import SolutionInfo from './SolutionInfo'

const AddNewPartner: FC = () => {
  const [current, setCurrent] = useState<number | 0>(0)
  const [wizardStatus, setWizardStatus] = useState<any>('process')
  const [isCreateErrorVisible, setIsCreateErrorVisible] = useState(false)
  const [createIntegratorErrorMessage, setCreateIntegratorErrorMessage] = useState<string | ''>()
  const defaultSolution: any = {
    solutionHighlights: '',
    keyCustomerSegments: [],
  }
  const history = useHistory()
  const { allowNext, setMasterData } = useContext(PartnerContext)
  const getMasterData = useQuery([CommonAPI.commonQueryName.GET_MASTER_DATA], () => CommonAPI.getMasterData())
  const [isSuccess, setIsSuccess] = useState<boolean | false>(false)
  const [successMsg, setsuccessMsg] = useState<string | ''>('')
  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }
  useEffect(() => {
    if (getMasterData?.data) {
      setMasterData(getMasterData.data)
    }
  }, [getMasterData?.data])

  useEffect(() => {
    if (current === 3) {
      setsuccessMsg('Integrator added successfully.')
      setIsSuccess(true)
    }
  }, [current])
  const getCurrentContent = () => {
    switch (current) {
      case 0:
        return (
          <PartnerInfo
            next={next}
            current={current}
            isCreateErrorVisible={isCreateErrorVisible}
            setIsCreateErrorVisible={setIsCreateErrorVisible}
            createIntegratorErrorMessage={createIntegratorErrorMessage}
            setCreateIntegratorErrorMessage={setCreateIntegratorErrorMessage}
          />
        )
      case 1:
        return <SolutionInfo next={next} defaultSolution={defaultSolution} />
      case 2:
        return (
          <ContactInfo
            next={next}
            isCreateErrorVisible={isCreateErrorVisible}
            setIsCreateErrorVisible={setIsCreateErrorVisible}
            createIntegratorErrorMessage={createIntegratorErrorMessage}
            setCreateIntegratorErrorMessage={setCreateIntegratorErrorMessage}
          />
        )
      case 3:
        console.log('2', current)
        return <IntegratorLinks />
      case -1:
      default:
        return <IntegratorLinks />
    }
  }

  const onBack = () => {
    history.push('/dashboard/partners')
  }

  return (
    <>
      <Toast
        isVisible={isCreateErrorVisible}
        text={createIntegratorErrorMessage}
        onDismiss={() => setIsCreateErrorVisible(false)}
        type="error"
      />
      <Toast isVisible={isSuccess} text={successMsg} onDismiss={() => setIsSuccess(false)} type="success" />
      <HeaderContainer>
        <BackButton height={35} width={35} onClick={onBack}></BackButton>
        <PartnerH4>{PartnerStrings.AddNewMember}</PartnerH4>
      </HeaderContainer>
      <Wrapper>
        <StepContainer>
          <ButtonContainer>
            {current > 0 && current < 3 ? <PreviousActiveArrow onClick={prev} /> : <PreviousPassiveArrow />}
          </ButtonContainer>
          <Steps size="default" current={current} status={wizardStatus}>
            <SingleStep
              title={
                <StepText current={current} value={0}>
                  {PartnerStrings.partnerInformation}
                </StepText>
              }
              status={current < 0 ? 'wait' : 'process'}
            />
            <SingleStep
              title={
                <StepText current={current} value={1}>
                  {PartnerStrings.solutionInformation}
                </StepText>
              }
              status={current < 1 ? 'wait' : 'process'}
            />
            <SingleStep
              title={
                <StepText current={current} value={2}>
                  {PartnerStrings.contactInfotmation}
                </StepText>
              }
              status={current < 2 ? 'wait' : 'process'}
            />
            <SingleStep
              title={
                <StepText current={current} value={3}>
                  {PartnerStrings.integratorLinks}
                </StepText>
              }
              status={current < 3 ? 'wait' : 'process'}
            />
          </Steps>
          <ButtonContainer2>
            {current < 4 && allowNext ? <NextActiveArrow onClick={next} /> : <NextPassiveArrow />}
          </ButtonContainer2>
        </StepContainer>
        <div className="steps-content">{getCurrentContent()}</div>
      </Wrapper>
    </>
  )
}

export default AddNewPartner
