import { Tabs } from 'antd'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { AsyncApiProps } from '..'
import Paths from '../../../../Dashboard/Paths'
import { TabbedCodeContainer } from '../../components/TabbedCodeContainer'
import { CodeBlock, LeftCol, RightCol, SectionRow, Text } from '../../Styles'
import { H4, P, UL } from '../../../../shared/components/Typography'

const TabPane = Tabs.TabPane

export interface AuthenticationProps {
  asyncApiProps: AsyncApiProps
}

export const Authentication: FC<AuthenticationProps> = ({ asyncApiProps }) => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H4 id="authentication">Authentication</H4>
        <P size="small">
          In order to make an API call, you need to generate an access token by using the credentials of an application
          created with access to that specific API. Make POST request to generate the token:
        </P>
        <Text code>{`https://${window.location.hostname}/api/v1/oauth2/token`}</Text>
        <br />
        <br />

        <P size="small">Related token scopes:</P>
        <UL size="small">
          <li>{`${asyncApiProps.scopePrefix}/building:{buildingId}`}</li>
        </UL>
        <P size="small">
          For more information about authentication and scopes, see{' '}
          <Link to={Paths.AuthenticationAPI.path}> {Paths.AuthenticationAPI.name}</Link> and{' '}
          <Link to={{ pathname: Paths.IntroductionAPI.path, hash: 'scopes' }}> Scopes</Link>.
        </P>
      </LeftCol>
      <RightCol span="12">
        <TabbedCodeContainer>
          <TabPane tab="Typescript" key="1">
            <CodeBlock language="typescript">
              {`
const fetchAccessToken = async (clientId: string, clientSecret: string, buildingId: string): Promise<string> => {
  try {
    const requestData = querystring.stringify({
      grant_type: 'client_credentials',
      scope: '${asyncApiProps.scopePrefix}/building:99900009301',
    })

    const requestConfig = {
      auth: {
        username: clientId,
        password: clientSecret,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    const requestResult = await axios.post('https://${window.location.hostname}/api/v1/oauth2/token', requestData, requestConfig)
    return requestResult.data.access_token
  } catch (error) {
    console.error('Error during accessToken fetch', error.response.data)
    throw error
  }
}`}
            </CodeBlock>
          </TabPane>
        </TabbedCodeContainer>
      </RightCol>
    </SectionRow>
  )
}
