import styled from 'styled-components'
import { size, color } from '../../utils/styles'
import { animated } from 'react-spring'
import { Col } from 'antd'
import { H3, H5 } from '../Typography'
import backArrow from '../../../assets/icons-back-arrow.svg'

export const Title = styled(H3)`
  transform: translateY(0px);
  transition: transform 300ms ease-out;

  @media (max-width: ${size.large}) {
    margin-top: 20%;
  }
`

export const Text = styled(H5)`
  transform: translateY(0px);
  transition: transform 300ms ease-out;
  margin: 0;
  padding: 1rem;

  @media (max-width: ${size.small}) {
    margin-bottom: 10%;
  }
`

export const Container = styled(Col)<{ image: string | undefined; height: string; id: string }>`
  content: '';
  position: relative;
  overflow: hidden;
  background-color: ${color.gray85};
  display: ${(props) => (props.id.startsWith('koneapi') ? `table-cell` : `flex`)};
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  transition: transform 300ms ease-in;
  background-color: ${color.gray85};
  max-width: 344px;
  margin: 8px;
  min-height: ${(props) => props.height};
  vertical-align: ${(props) => (props.id.startsWith('koneapi') ? `middle` : `initial`)};
  cursor: pointer;

  @media (min-width: ${size.large}) {
    flex: 1;
    padding: 80px;
    max-width: initial;
    min-height: initial;
    flex-basis: 33%;
    &:hover {
      z-index: 2;
      transform: scale(1.05);
      transition: background-color 350ms ease-out, transform 300ms ease-out;
      background-color: ${color.petrolBlue};
      box-shadow: rgba(0, 0, 0, 0.8) 5px 5px 30px;
      .card-icon {
        transform: translateY(-30px);
      }
      .card-button {
        opacity: 1;
      }
      .card-title {
        transform: translateY(-20px);
      }
      .card-text {
        transform: translateY(-20px);
      }
    }
  }

  ${({ image }) =>
    image &&
    `
    :before {
      content: '';
      opacity: 0.09;
      background-image: url(${image});
      position: absolute;
      background-repeat: no-repeat;
      width: 100%;
      height:350px;
      background-size: contain;
      top: 0;
      left: 0;
      @media (min-width: ${size.large}) {
        background-size: auto;
        height:100%;
      }
    }
  `}
`

export const ButtonContainer = styled.div`
  position: relative;
  margin-top: '50px';
  opacity: 0;
  transition: opacity 300ms ease-out;
`

export const Icon = styled.div<{ icon: string; iconDimension: string }>`
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: 35px;
  width: 35px;
  height: 35px;
  transition: transform 300ms ease-out;
  @media (min-width: ${size.large}) {
    width: ${(props) => props.iconDimension};
    height: ${(props) => props.iconDimension};
    background-size: ${(props) => props.iconDimension};
  }
`

export const Circle = styled.div`
  color: ${color.white};
  height: 100px;
  width: 100px;
  background-color: ${color.blueBase};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 300ms ease-out;
  transform: translateY(30px);

  @media (max-width: ${size.large}) {
    display: none;
    height: 72px;
    width: 72px;
  }
`

export const ArrowIcon = styled(animated.div)`
  background-image: url(${backArrow});
  background-repeat: no-repeat;
  width: 28px;
  height: 25px;
  background-size: 28px;
  right: 16px;
  bottom: 14px;
  position: absolute;
  transform: scaleX(-1);
  @media (min-width: ${size.large}) {
    background-size: 42px;
    width: 42px;
    height: 38px;
    right: 22px;
    bottom: 22px;
  }
`
