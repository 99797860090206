import React, { FC, useState } from 'react'
import {
  AppName,
  InputContainer,
  FormElement,
  FormLabel,
  InputName,
  ButtonsContainer,
  SaveButton,
  MainContainer,
  StyledLine,
  ApiName,
  FormCheckboxesContainer,
  FormCheckboxContainer,
  FormCheckbox,
  CheckboxLabel,
  DeleteContainer,
  DeleteButton,
  SwitchInputContainer,
  SwitchContainer,
  SwitchLabel,
  CenterLoader,
  EditWrapper,
  SuccessContainer,
  OkIcon,
  SuccessMessage,
  FormURLElement,
  FormSecretElement,
  ClientInput,
  ChipContainer,
} from './Styles'
import { InputChipContainer, ResourceIdContainer, SelectAllResource } from '../../WebhookForm/Styles'
import { EMAIL_REG_EXP } from '../../../../shared/constants/auth'
import { ErrorStrings } from '../../../../shared/strings/AuthFormContent'
import { Controller, useForm } from 'react-hook-form'
import { CreateWebhookForm, EditWebhookForm } from '../../../../shared/strings/ApplicationsContent'
import { ErrorMessage, Toast, SwitchWebhook, Modal, LoadingAnimated, Button } from '../../../../shared/components'
import { useMutation } from 'react-query'
import { editSubscription, deleteSubscription } from '../../../../shared/api/webhookAPI'
import { CustomerApp } from '../../../../shared/models/application'
import { WebhookSubscription } from '../../../../shared/models/webhookSubscription'
import { ReactComponent as DeleteIcon } from '../../../../assets/icon-remove.svg'
import {
  LOADER_VISIBILITY_MLS,
  escalatorMovementDisableEnvs,
  movementStartedDisableEnvs,
  elvatorDoorButtonDisableEnvs,
  movCompletedFlagDisableEnvs,
} from '../../../../shared/constants/common'
import { Resources } from '../../../../shared/models/resource'
import { EventType } from '../../../../shared/models/events'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import awsConfig from '../../../../shared/utils/awsConfig'

type Props = {
  application: CustomerApp
  showSuccessfulEdit: () => void
  closeEditWebhookPanel: () => void
  editSuccess: () => void
  subscription: WebhookSubscription
  kenResources: Resources
}

const EditWebhooksForm: FC<Props> = ({
  application,
  showSuccessfulEdit,
  closeEditWebhookPanel,
  subscription,
  editSuccess,
  kenResources,
}) => {
  const [isCreateAppErrorVisible, setIsCreateAppErrorVisible] = useState(false)
  const [isStatusStateChanged, setIsStatusStateChanged] = useState(subscription.status)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
  const [isDeleteErrorVisible, setIsDeleteErrorVisible] = useState(false)
  const [isLoadingTimerFinished, setIsLoadingTimerFinished] = useState(false)
  const [saveError, setSaveError] = useState<Error | undefined>()
  const [enableWebhook, setEnableWebhook] = subscription.status === 'DISABLED' ? useState(false) : useState(true)
  const [subscribedResourceIds, setSubscribedResourceIds] = useState(subscription.subscribedResourceIds || [])
  const [typesArray, setTypesArray] = useState<string[]>(subscription.types)
  const [isTermsChecked, setIsTermsChecked] = useState(false)
  const [isCheckedTestType, setIsCheckedTestType] = useState(subscription.types.includes('com.kone.test.v1'))
  const [isCheckedMovementType, setIsCheckedMovementType] = useState(
    subscription.types.includes(EventType.ElevatorMovementCompletedV1)
  )
  const [isCheckedMovementStarted, setIsCheckedMovementStarted] = useState(
    subscription.types.includes(EventType.ElevatorMovementStartedV1)
  )
  const [isCheckedEquipmentType, setIsCheckedEquipmentType] = useState(
    subscription.types.includes(EventType.EquipmentAvailabilityV1)
  )
  const [isCheckedEquipmentServiceType, setIsCheckedEquipmentServiceType] = useState(
    subscription.types.includes(EventType.EquipmentStatusV1)
  )
  const [isCheckedServiceOrderType, setIsCheckedServiceOrderType] = useState(
    subscription.types.includes(EventType.ServiceOrderV2)
  )
  const [isCheckedDoorEventType, setIsCheckedDoorEventType] = useState(
    subscription.types.includes(EventType.ElevatorDoorEventV1)
  )
  const [isCheckedButtonEventType, setIsCheckedButtonEventType] = useState(
    subscription.types.includes(EventType.ElevatorButtonEventV1)
  )
  const [isCheckedMovCompletedTwoEventType, setMovCompletedTwoEventType] = useState(
    subscription.types.includes(EventType.ElevatorMovementCompletedV2)
  )
  const [isCheckedEscalatorMovementType, setIsCheckedEscalatorMovementType] = useState(
    subscription.types.includes(EventType.EscalatorMovementChangeV1)
  )
  const {
    mutateAsync: deleteOwnedSubscription,
    isLoading: isRemoving,
    isSuccess: appRemoved,
  } = useMutation(deleteSubscription)

  const { mutateAsync: saveSubscriptionWebhook, isLoading } = useMutation(editSubscription)

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors: formErrors },
    register,
  } = useForm({
    defaultValues: {
      webhookEndpoint: subscription.webhookEndpoint,
      types: subscription.types,
      contactEmail: subscription.contactEmail,
      subscriptionName: subscription.subscriptionName,
      subscribedResourceIds: subscribedResourceIds,
      availabilityEvent: subscription.types.includes(EventType.EquipmentAvailabilityV1),
      movementEvent: subscription.types.includes(EventType.ElevatorMovementCompletedV1),
      movementStartedEvent: subscription.types.includes(EventType.ElevatorMovementStartedV1),
      equipmentServiceEvent: subscription.types.includes(EventType.EquipmentStatusV1),
      elvDoorEvent: subscription.types.includes(EventType.ElevatorDoorEventV1),
      elvButtonEvent: subscription.types.includes(EventType.ElevatorButtonEventV1),
      escalatorMovementEvent: subscription.types.includes(EventType.EscalatorMovementChangeV1),
      serviceOrderEvent: subscription.types.includes(EventType.ServiceOrderV2),
      movCompEventTwo: subscription.types.includes(EventType.ElevatorMovementCompletedV2),
      testEvent: subscription.types.includes(EventType.TestV1),
    },
  })

  const handleCheckboxClick = (e: any, value: string) => {
    if (e.target.checked) {
      setIsTermsChecked(isTermsChecked)
      setTypesArray([...typesArray, value])
    } else {
      setIsTermsChecked(!isTermsChecked)
      setTypesArray(typesArray.filter((item) => item !== value))
    }
  }

  const onShowDeleteModal = () => {
    setModalDeleteVisible(true)
  }

  const onHideDeleteModal = () => {
    setModalDeleteVisible(false)
  }

  const hideToastNotifications = () => {
    setIsDeleteErrorVisible(false)
  }

  const startLoaderTimer = () => {
    setTimeout(() => {
      setIsLoadingTimerFinished(true)
    }, LOADER_VISIBILITY_MLS)
  }

  const onHandleDelete = async () => {
    hideToastNotifications()

    try {
      setModalDeleteVisible(false)
      startLoaderTimer()
      await deleteOwnedSubscription({ clientId: application.clientId!, subscriptionId: subscription.id })
    } catch (e) {
      setIsDeleteErrorVisible(true)
    }
  }

  function onResourceIdAdded(data: string) {
    if (!subscribedResourceIds.includes(data)) {
      setSubscribedResourceIds([...subscribedResourceIds, data])
    } else {
      setSubscribedResourceIds(subscribedResourceIds.filter((id) => id !== data))
    }
  }

  const onSaveChanges = async (data: WebhookSubscription) => {
    setIsCreateAppErrorVisible(false)
    try {
      const id = subscription.id
      const subscriptionName = data.subscriptionName
      const clientID = application.clientId!
      const body = {
        subscriptionName,
        subscribedResourceIds,
        types: typesArray,
      }
      if (data.contactEmail !== '') {
        Object.assign(body, { contactEmail: data.contactEmail })
      }
      if (subscription.status !== isStatusStateChanged) {
        Object.assign(body, { status: isStatusStateChanged })
      }
      await saveSubscriptionWebhook({
        clientID,
        id,
        body,
      })
      closeEditWebhookPanel()
      showSuccessfulEdit()
      editSuccess()
    } catch (err) {
      setSaveError(err.response.data.message)
      setIsCreateAppErrorVisible(true)
    }
  }

  const onClickSwitch = (checked: boolean) => {
    setEnableWebhook(checked)
    checked === true ? setIsStatusStateChanged('ENABLED') : setIsStatusStateChanged('DISABLED')
  }

  if (appRemoved || isRemoving) {
    return !isLoadingTimerFinished || isRemoving ? (
      <CenterLoader>
        <LoadingAnimated />
      </CenterLoader>
    ) : (
      <EditWrapper>
        <SuccessContainer>
          <OkIcon />
          <SuccessMessage>Subscription deleted</SuccessMessage>
        </SuccessContainer>
      </EditWrapper>
    )
  }

  const onSelectAll = (e: CheckboxChangeEvent) => {
    const selectedKens: string[] = []
    if (e?.target?.checked) {
      console.log('kenResources:', kenResources)
      kenResources?.map((data: any, key: number) => {
        console.log('uniqueid:', data)
        selectedKens.push(`ken:${data.resourceId.uniqueId}`)
      })
      setSubscribedResourceIds([...(subscription.subscribedResourceIds || []), ...selectedKens])
    } else {
      setSubscribedResourceIds(selectedKens)
    }
  }

  return (
    <>
      <Toast
        isVisible={isCreateAppErrorVisible}
        text={saveError}
        onDismiss={() => setIsCreateAppErrorVisible(false)}
        type="error"
      />
      <Toast
        key="delete-error"
        isVisible={isDeleteErrorVisible}
        text={EditWebhookForm.webhookDeleteError}
        textId="delete-app-error-message-js"
        onDismiss={() => setIsDeleteErrorVisible(false)}
        type="error"
      />
      {!appRemoved && (
        <form onSubmit={handleSubmit(onSaveChanges)}>
          <MainContainer>
            <InputContainer>
              <AppName>Name and Connection</AppName>
              <FormElement>
                <FormLabel htmlFor="edit-webhook-name-input-js">{EditWebhookForm.webhookName}</FormLabel>
                <Controller
                  render={({ field }) => <InputName id="webhook-name-input-js" {...field} />}
                  name="subscriptionName"
                  control={control}
                  rules={{
                    required: false,
                  }}
                />
                {formErrors.webhookEndpoint && (
                  <ErrorMessage>
                    {formErrors.webhookEndpoint.message || EditWebhookForm.webhookUrlRequiredError}
                  </ErrorMessage>
                )}
              </FormElement>
              <FormElement>
                <FormLabel htmlFor="edit-webhook-email-input-js">{EditWebhookForm.email}</FormLabel>
                <Controller
                  render={({ field }) => <InputName id="edit-webhook-email-input-js" {...field} />}
                  name="contactEmail"
                  control={control}
                  rules={{
                    pattern: {
                      value: EMAIL_REG_EXP,
                      message: ErrorStrings.invalidEmailError,
                    },
                    required: false,
                  }}
                />
                {formErrors.contactEmail && <ErrorMessage> {formErrors.contactEmail.message}</ErrorMessage>}
              </FormElement>
              <FormURLElement>
                <FormLabel htmlFor="edit-webhook-webhookEndpoint-input-js">
                  {EditWebhookForm.webhookTargetURL}
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <InputName id="edit-webhook-webhookEndpoint-input-js" {...field} disabled={true} />
                  )}
                  name="webhookEndpoint"
                  control={control}
                  rules={{
                    required: false,
                  }}
                />
                {formErrors.webhookEndpoint && (
                  <ErrorMessage>
                    {formErrors.webhookEndpoint.message || EditWebhookForm.webhookUrlRequiredError}
                  </ErrorMessage>
                )}
              </FormURLElement>
            </InputContainer>
            <SwitchInputContainer>
              <FormElement>
                <FormLabel htmlFor="edit-webhook-status-input-js">{EditWebhookForm.subscriptionStatus}</FormLabel>
                <SwitchContainer>
                  <SwitchLabel className={!enableWebhook ? '' : 'switch-disabled'}>Disabled</SwitchLabel>
                  <SwitchWebhook id="webhook-enable-toggle-js" checked={enableWebhook} onChange={onClickSwitch} />
                  <SwitchLabel className={enableWebhook ? '' : 'switch-enabled'}>Enabled</SwitchLabel>
                </SwitchContainer>
              </FormElement>
              <FormSecretElement>
                <FormLabel htmlFor="edit-webhook-secret-input-js">{EditWebhookForm.webhookSecret}</FormLabel>
                <ClientInput.Password value={Array(20).join('\u2022')} type="text" disabled />
              </FormSecretElement>
            </SwitchInputContainer>
          </MainContainer>
          {!application.sandbox && subscription.subscribedResourceIds !== undefined && (
            <InputChipContainer>
              <FormLabel htmlFor="webhook-resource-id-input-js">
                {EditWebhookForm.subscribeResourceIds}
                <SelectAllResource
                  checked={
                    subscription.subscribedResourceIds?.length + kenResources?.length === subscribedResourceIds?.length
                  }
                  onChange={onSelectAll}
                >
                  {CreateWebhookForm.webhookSelectAll}
                </SelectAllResource>
              </FormLabel>
              <ChipContainer>
                {subscription.subscribedResourceIds.map((data: string, key: number) => (
                  <ResourceIdContainer
                    type={subscribedResourceIds && subscribedResourceIds.includes(data) ? 'primary' : 'default'}
                    {...register('subscribedResourceIds', { required: true })}
                    id={`resource-id-${key}-js`}
                    key={key}
                    onClick={() => onResourceIdAdded(`${data}`)}
                  >
                    {data.replace('ken:', '')}
                  </ResourceIdContainer>
                ))}
                {kenResources.map((data: any, key: number) => (
                  <ResourceIdContainer
                    type={
                      subscribedResourceIds && subscribedResourceIds.includes(`ken:${data.resourceId.uniqueId}`)
                        ? 'primary'
                        : 'default'
                    }
                    id={`resource-id-${key}-js`}
                    key={key}
                    onClick={() => onResourceIdAdded(`ken:${data.resourceId.uniqueId}`)}
                    defaultValue={''}
                  >
                    {data.resourceId.uniqueId}
                  </ResourceIdContainer>
                ))}
              </ChipContainer>
            </InputChipContainer>
          )}
          <StyledLine />
          <AppName>Subscriptions</AppName>
          <FormCheckboxesContainer>
            <FormCheckboxContainer>
              {
                <FormCheckbox
                  id="edit-webhook-test-event-checkbox-js"
                  {...register('testEvent')}
                  readOnly
                  onClick={(e) => {
                    handleCheckboxClick(e, 'com.kone.test.v1')
                    setIsCheckedTestType(!isCheckedTestType)
                  }}
                  type="checkbox"
                  checked={isCheckedTestType}
                />
              }
              <CheckboxLabel htmlFor="edit-webhook-test-event-checkbox-js">
                {EditWebhookForm.webhookCheckboxTestEvent} &nbsp;
              </CheckboxLabel>
            </FormCheckboxContainer>
            <br />
            <ApiName>Equipment Status API 2</ApiName>
            <FormCheckboxContainer>
              {
                <FormCheckbox
                  id="edit-webhook-movement-completed-event-checkbox-js"
                  {...register('movementEvent')}
                  readOnly
                  onClick={(e) => {
                    handleCheckboxClick(e, 'com.kone.elevator.movement.completed.v1')
                    setIsCheckedMovementType(!isCheckedMovementType)
                  }}
                  type="checkbox"
                  checked={isCheckedMovementType}
                />
              }
              <CheckboxLabel htmlFor="edit-webhook-movement-completed-event-checkbox-js">
                {EditWebhookForm.webhookCheckboxMovementCompletedEvent} &nbsp;
              </CheckboxLabel>
            </FormCheckboxContainer>

            {!movementStartedDisableEnvs.includes(awsConfig?.environment) && (
              <FormCheckboxContainer>
                <FormCheckbox
                  id="edit-webhook-movement-started-event-checkbox-js"
                  {...register('movementStartedEvent')}
                  readOnly
                  onClick={(e) => {
                    handleCheckboxClick(e, 'com.kone.elevator.movement.started.v1')
                    setIsCheckedMovementStarted(!isCheckedMovementStarted)
                  }}
                  type="checkbox"
                  checked={isCheckedMovementStarted}
                />
                <CheckboxLabel htmlFor="edit-webhook-movement-started-event-checkbox-js">
                  {EditWebhookForm.webhookCheckboxMovementStartedEvent} &nbsp;
                </CheckboxLabel>
              </FormCheckboxContainer>
            )}

            <FormCheckboxContainer>
              {
                <FormCheckbox
                  id="edit-webhook-availability-event-checkbox-js"
                  {...register('availabilityEvent')}
                  readOnly
                  onClick={(e) => {
                    handleCheckboxClick(e, 'com.kone.equipment.availability.v1')
                    setIsCheckedEquipmentType(!isCheckedEquipmentType)
                  }}
                  type="checkbox"
                  checked={isCheckedEquipmentType}
                />
              }
              <CheckboxLabel htmlFor="edit-webhook-availability-event-checkbox-js">
                {EditWebhookForm.webhookCheckboxAvailabilityEvent} &nbsp;
              </CheckboxLabel>
            </FormCheckboxContainer>

            <FormCheckboxContainer>
              {
                <FormCheckbox
                  id="edit-webhook-equipment-service-event-checkbox-js"
                  {...register('equipmentServiceEvent')}
                  readOnly
                  onClick={(e) => {
                    handleCheckboxClick(e, 'com.kone.equipment.status.v1')
                    setIsCheckedEquipmentServiceType(!isCheckedEquipmentServiceType)
                  }}
                  type="checkbox"
                  checked={isCheckedEquipmentServiceType}
                />
              }
              <CheckboxLabel htmlFor="edit-webhook-equipment-service-event-checkbox-js">
                {EditWebhookForm.webhookCheckboxServiceStatusEvent} &nbsp;
              </CheckboxLabel>
            </FormCheckboxContainer>
            {!escalatorMovementDisableEnvs.includes(awsConfig?.environment) && (
              <FormCheckboxContainer>
                {
                  <FormCheckbox
                    id="edit-webhook-escalator-movement-change-event-checkbox-js"
                    {...register('escalatorMovementEvent')}
                    readOnly
                    onClick={(e) => {
                      handleCheckboxClick(e, EventType.EscalatorMovementChangeV1)
                      setIsCheckedEscalatorMovementType(!isCheckedEscalatorMovementType)
                    }}
                    type="checkbox"
                    checked={isCheckedEscalatorMovementType}
                  />
                }
                <CheckboxLabel htmlFor="edit-webhook-escalator-movement-change-event-checkbox-js">
                  {EditWebhookForm.webhookCheckboxEscMovementChangeEvent} &nbsp;
                </CheckboxLabel>
              </FormCheckboxContainer>
            )}
            {!elvatorDoorButtonDisableEnvs.includes(awsConfig?.environment) && (
              <FormCheckboxContainer>
                {
                  <FormCheckbox
                    id="edit-webhook-elevator-door-event-checkbox-js"
                    {...register('elvDoorEvent')}
                    readOnly
                    onClick={(e) => {
                      handleCheckboxClick(e, EventType.ElevatorDoorEventV1)
                      setIsCheckedDoorEventType(!isCheckedDoorEventType)
                    }}
                    type="checkbox"
                    checked={isCheckedDoorEventType}
                  />
                }
                <CheckboxLabel htmlFor="edit-webhook-elevator-door-event-checkbox-js">
                  {CreateWebhookForm.webhookCheckboxElvDoorEvent} &nbsp;
                </CheckboxLabel>
              </FormCheckboxContainer>
            )}
            {!elvatorDoorButtonDisableEnvs.includes(awsConfig?.environment) && (
              <FormCheckboxContainer>
                {
                  <FormCheckbox
                    id="edit-webhook-elevator-button-event-checkbox-js"
                    {...register('elvButtonEvent')}
                    readOnly
                    onClick={(e) => {
                      handleCheckboxClick(e, 'com.kone.elevator.button.event.v1')
                      setIsCheckedButtonEventType(!isCheckedButtonEventType)
                    }}
                    type="checkbox"
                    checked={isCheckedButtonEventType}
                  />
                }
                <CheckboxLabel htmlFor="edit-webhook-elevator-button-event-checkbox-js">
                  {CreateWebhookForm.webhookCheckboxElvButtonEvent} &nbsp;
                </CheckboxLabel>
              </FormCheckboxContainer>
            )}
            {!movCompletedFlagDisableEnvs.includes(awsConfig?.environment) && (
              <FormCheckboxContainer>
                {
                  <FormCheckbox
                    id="webhook-movement-completed-event-checkbox-js"
                    {...register('movCompEventTwo')}
                    readOnly
                    onClick={(e) => {
                      handleCheckboxClick(e, EventType.ElevatorMovementCompletedV2)
                      setMovCompletedTwoEventType(!isCheckedMovCompletedTwoEventType)
                    }}
                    type="checkbox"
                    defaultChecked={isCheckedMovCompletedTwoEventType}
                  />
                }
                <CheckboxLabel htmlFor="webhook-movement-completed-event-checkbox-js">
                  {CreateWebhookForm.webhookCheckboxMovCompTwoEvent} &nbsp;
                </CheckboxLabel>
              </FormCheckboxContainer>
            )}
            <br />
            <ApiName>Service Info API 2</ApiName>
            <FormCheckboxContainer>
              {
                <FormCheckbox
                  id="edit-webhook-service-order-event-checkbox-js"
                  {...register('serviceOrderEvent')}
                  readOnly
                  onClick={(e) => {
                    handleCheckboxClick(e, 'com.kone.equipment.serviceorder.v2')
                    setIsCheckedServiceOrderType(!isCheckedServiceOrderType)
                  }}
                  type="checkbox"
                  checked={isCheckedServiceOrderType}
                />
              }
              <CheckboxLabel htmlFor="edit-webhook-service-order-event-checkbox-js">
                {EditWebhookForm.webhookCheckboxServiceOrderEvent} &nbsp;
              </CheckboxLabel>
            </FormCheckboxContainer>
            {formErrors.types && <ErrorMessage>{EditWebhookForm.webhookTypesRequiredError}</ErrorMessage>}
          </FormCheckboxesContainer>
          <StyledLine />
          <ButtonsContainer>
            <SaveButton
              id="edit-save-changes-webhook-button-js"
              type="primary"
              htmlType="submit"
              disabled={false}
              // disabled={
              //   !isDirty &&
              //   (subscription.status === isStatusStateChanged ||
              //     subscribedResourceIds.length >
              //       (subscription!.subscribedResourceIds?.length ?? subscribedResourceIds.length))
              // }
              loading={isLoading}
            >
              {EditWebhookForm.saveChanges}
            </SaveButton>
          </ButtonsContainer>
          <StyledLine />
          <DeleteContainer id="open-edit-app-button-js">
            <DeleteButton onClick={onShowDeleteModal}>{EditWebhookForm.deleteWebhook}</DeleteButton>
            <DeleteIcon />
          </DeleteContainer>
        </form>
      )}
      <Modal
        key="remove-subscription-modal"
        title="delete"
        width={672}
        visible={modalDeleteVisible}
        onOk={onHandleDelete}
        onCancel={onHideDeleteModal}
        closable={false}
        footer={[
          <Button
            id="remove-subscription-button-js"
            size="large"
            style={{ width: '304px', marginRight: '24px' }}
            key="submit"
            ghost={true}
            onClick={onHandleDelete}
          >
            {EditWebhookForm.webhookConfirmDeletion}
          </Button>,
          <Button
            id="cancel-remove-subscription-button-js"
            size="large"
            style={{ width: '272px' }}
            key="back"
            type="primary"
            onClick={onHideDeleteModal}
          >
            {EditWebhookForm.webhookCancelDeletion}
          </Button>,
        ]}
      >
        {`${EditWebhookForm.webhookDeletePopupText}: ${subscription.subscriptionName}`}
      </Modal>
    </>
  )
}

export default EditWebhooksForm
