import React, { FC, useState } from 'react'
import { DownloadsAPI } from '../../../../../shared/api/downloads'
import { DownloadButtonContainer, DownloadButtonName } from '../style'
import { Row, Col } from 'antd'
import { ReactComponent as DownloadIcon } from '../../../../../assets/icon-download.svg'
import { Toast } from '../../../../../shared/components'

type DownloadFileButtonProps = {
  fileName: string
  buttonName: string
  onDownloadStart: () => void
  onDownloadFailed: () => void
}

const DownloadFileButton: FC<DownloadFileButtonProps> = ({
  fileName,
  buttonName,
  onDownloadStart,
  onDownloadFailed,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const onDownloadClick = async () => {
    setIsLoading(true)
    onDownloadStart()

    try {
      const downloadUrl = await DownloadsAPI.getDownloadURL(fileName)
      const temporaryLink = document.createElement('a')
      temporaryLink.setAttribute('href', downloadUrl)
      temporaryLink.setAttribute('download', fileName)

      temporaryLink.style.display = 'none'
      document.body.appendChild(temporaryLink)

      temporaryLink.click()

      document.body.removeChild(temporaryLink)
    } catch (err) {
      console.error(err)
      onDownloadFailed()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DownloadButtonContainer>
      <DownloadIcon />
      <DownloadButtonName onClick={onDownloadClick}>{buttonName}</DownloadButtonName>
    </DownloadButtonContainer>
  )
}

const SecurityAccess: FC = () => {
  const [isShowDownloadError, setIsShowDownloadError] = useState(false)

  const onDownloadStart = () => {
    setIsShowDownloadError(false)
  }

  const onDownloadFailed = () => {
    setIsShowDownloadError(true)
  }

  return (
    <>
      <Toast
        key="download-error"
        isVisible={isShowDownloadError}
        text="Download file error. Please try again."
        onDismiss={() => setIsShowDownloadError(false)}
        type="error"
      />
      <Row>
        <Col xs={2} sm={4} md={6} lg={8} xl={8}>
          <DownloadFileButton
            fileName="KONE API Services security and privacy self-assessment form.xlsx"
            buttonName="Security and Privacy Self-Assessment"
            onDownloadStart={onDownloadStart}
            onDownloadFailed={onDownloadFailed}
          />
        </Col>
      </Row>
    </>
  )
}

export default SecurityAccess
