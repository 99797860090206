import React, { FC, useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import { useQueryClient } from 'react-query'
import moment from 'moment'
import { ResourcesAPI } from '../../../../../shared/api/resources'
import { Toast } from '../../../../../shared/components'
import { Resource, Group, Building, Contract } from '../../../../../shared/models/resource'
import { EditResourcesStrings } from '../../../../../shared/strings/OrganizationsContent'
import ProductOptions from '../ProductOptions'
import { ReactComponent as OkIcon } from '../../../../../assets/ok-check-icon.svg'
import { ReactComponent as AddIcon } from '../../../../../assets/plus-icon.svg'
import RingSpinner from '../../../../../shared/components/RingSpinner'
import {
  Loader,
  AddContainer,
  SwitchContainer,
  TableRow,
  Row,
  DatePickerContainer,
  HeaderRow,
  RowContentSmall,
  RowContentLarge,
  RowContentMedium,
  ProductSelectionAuto,
} from './Styles'
import _ from 'lodash'
import { UpdateCounterBuilding } from '../Styles'
import { removeV1 } from '../../../../../shared/utils/common'
import {
  validateAddressLine1,
  validateAddressLine2,
  validateCity,
  validateCountryCode,
  validateZipCode,
} from '../../../../../shared/utils/validateAddress'

type Props = {
  organizationId: number
  building: Building
  buildingId: string
  group: Group
  contract?: Contract
}

type KenProps = {
  organizationId: number
  building: Building
  buildingId: string
  groupId: string
  kenId: string
  contract?: Contract
}

const KenResource: FC<KenProps> = ({ organizationId, building, buildingId, groupId, kenId, contract }) => {
  const [date, setDate] = useState<string>('')
  const [apiErrors, setAPIErrors] = useState<string[]>()
  const [isSuccessVisible, setIsSuccessVisible] = useState(false)
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const queryClient = useQueryClient()
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [productsSelected, setProductsSelected] = useState<Array<string>>([])
  const [resourceId, setResourceId] = useState<string>('')
  const [isSaving, setIsSaving] = useState(false)
  const [isOk, setIsOk] = useState(false)
  const [isInputErrorVisible, setIsInputErrorVisible] = useState(false)
  const [contractError, setContractError] = useState<string>('')
  const [isContractError, setIsContractError] = useState(false)
  const [isAddWithoutExpiryError, setAddWithoutExpiryError] = useState(false)
  const [isExpires, setIsExpiry] = useState(true)
  const [contractDatForSelectedProducts, setContractDatForSelectedProducts] = useState<any>({})
  const [expiredDateManual, setExpiredDateManual] = useState<string>('')
  const [noContractFound, setNoContractFound] = useState(false)
  const [productContractMap, setProductContractMap] = useState({})
  const [currentProduct, setCurrentProduct] = useState<string>('')
  const [ownProductDateMap, setOwnProductDateMap] = useState<any>({})
  const resetFlags = () => {
    setIsSuccessVisible(false)
    setIsSaving(false)
    setAPIErrors([])
    setIsErrorVisible(false)
    setIsOk(false)
    setIsInputErrorVisible(false)
  }

  useEffect(() => {
    const temp = {}
    contract?.contractData?.map((oneContract: any) => {
      temp[oneContract?.product] = oneContract?.endDate
    })

    setOwnProductDateMap(temp)
  }, [])

  const onDateChange = (date: any, dateString: any) => {
    updateProductExpiryDate(date)
    if (noContractFound) {
      setDate(dateString)
      setIsDatePickerOpen(true)
      setExpiredDateManual(dateString)
      return
    }
    setDate(dateString)
    setIsDatePickerOpen(false)
    resetFlags()
  }

  const updateProductExpiryDate = (date: any) => {
    console.log('updateProductExpiryDate:', date, ownProductDateMap)
    if (date && currentProduct?.length > 0) {
      if (date == '' && !ownProductDateMap[currentProduct]) {
        return
      }

      if (date == '' && ownProductDateMap[currentProduct]) {
        date = ownProductDateMap[currentProduct]
      }

      const temp = { ...contractDatForSelectedProducts }
      for (const productKey in productContractMap) {
        if (productContractMap[productKey] === 1) {
          if (!temp[productKey]) {
            temp[productKey] = {
              contractType: null,
              endDate: moment(date).format('YYYY-MM-DD'),
              block: 0,
              product: productKey,
            }
          }
        }
      }
      setContractDatForSelectedProducts(temp)
    }
  }

  const checkIfResourceExists = async (resourceType: string, resourceId: string) => {
    try {
      await ResourcesAPI.getResourcesByTypeAndId(resourceType, resourceId)
      return true
    } catch (e) {
      if (e.response.status === 404) {
        return false
      } else {
        throw new Error(EditResourcesStrings.getResourceError)
      }
    }
  }

  async function setProductExpiryDate(products: string[]) {
    if (contract) {
      if (products.length === 0) {
        setIsExpiry(false)
        setIsDatePickerOpen(false)
        onDateChange('', '')
        setIsContractError(false)
        setAddWithoutExpiryError(false)
        return
      }
      const temp = { ...contractDatForSelectedProducts }
      const tempMap = {}

      for (let productIndex = 0; productIndex < products.length; productIndex++) {
        // Step 1: Filter and sort contracts for the current product
        let myResourceContract
        if (contract && Array.isArray(contract.contractData)) {
          myResourceContract = contract.contractData
            .filter((contractItem) => contractItem.product === products[productIndex])
            .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())[0] // Get the contract with the latest endDate
        }

        // Step 2: Handle case when no contract is found
        if (!myResourceContract) {
          const newProduct = removeV1(products[productIndex])
          myResourceContract = temp[newProduct]
          tempMap[products[productIndex]] = 1
        }

        // Step 3: Handle product removal from selected products
        if (Object.keys(contractDatForSelectedProducts).length > 0) {
          for (const currentProduct in temp) {
            const newProduct = removeV1(currentProduct)
            if (!products.includes(newProduct)) {
              delete temp[currentProduct] // Remove products that are not in the selected list
            }
          }
        }

        // Step 4: Update state with the found contract or set an error if no contract is found
        if (myResourceContract) {
          setNoContractFound(false)
          temp[myResourceContract.product] = myResourceContract // Store the found contract
          setIsExpiry(false)
          onDateChange('', myResourceContract.endDate)
        } else {
          tempMap[products[productIndex]] = 1
          setIsContractError(true)
          setContractError('No valid contract found for this product. Please remove the product or set it manually.')
          setNoContractFound(true)
          setIsExpiry(true)
          onDateChange('', '')
        }
      }

      // Step 5: Update final state
      setContractDatForSelectedProducts(temp)
      setProductContractMap({ ...productContractMap, ...tempMap })
    }
  }

  function disabledDate(current: any) {
    return current && current <= moment().endOf('day')
  }

  const addResource = async () => {
    const type = 'ken'
    const parentId = `${buildingId}:${groupId}`
    const expiryDateForProducts: any[] = []

    productsSelected.forEach((product) => {
      expiryDateForProducts.push(contractDatForSelectedProducts[product].endDate)
    })
    // if (noContractFound) {
    //   for (let i = 0; i < productsSelected.length; i++) {
    //     expiryDateForProducts.push(expiredDateManual)
    //   }
    // } else {
    // }
    let address = {}
    if (building.address) {
      address = fetchAddressFields(building.address)
    }

    const body: Resource = {
      products: productsSelected,
      expiredDate: expiryDateForProducts,
      resourceId: { type: type, uniqueId: kenId },
    }

    const groupResourceExists = await checkIfResourceExists('group', parentId)
    if (!groupResourceExists) {
      await ResourcesAPI.createResource({
        id: { type: 'group', uniqueId: parentId, expiredDate: '' },
        info: { address: JSON.stringify(address) },
      })
    }

    const resourceExists = await checkIfResourceExists(type, kenId)
    if (!resourceExists) {
      await ResourcesAPI.createResourceWithParent({
        id: { type: type, uniqueId: kenId, expiredDate: date },
        parentId: { uniqueId: parentId, type: 'group' },
        info: { address: JSON.stringify(address) },
      })
    }
    await ResourcesAPI.link(organizationId, body)
  }

  const fetchAddressFields = (locationDetails: any) => {
    const addressLine1 = validateAddressLine1(locationDetails)
    const addressLine2 = validateAddressLine2(locationDetails)
    const countryCode = validateCountryCode(locationDetails)
    const city = validateCity(locationDetails)
    const zipCode = validateZipCode(locationDetails)

    const response = {
      city: city,
      country: countryCode,
      zipcode: zipCode,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
    }
    return response
  }

  const onClickAdd = async () => {
    resetFlags()

    if (productsSelected.length < 1) {
      setIsInputErrorVisible(true)
      return
    }

    if (!noContractFound) {
      if (Object.keys(contractDatForSelectedProducts).length !== productsSelected.length) {
        setIsContractError(false)
        setAddWithoutExpiryError(true)
        return
      }
    }

    if ((noContractFound && expiredDateManual.length === 0) || (noContractFound && isExpires === false)) {
      setContractError('Cannot add without expiry date')
      return
    }

    // if (Object.keys(contractDatForSelectedProducts).length !== productsSelected.length) {
    //   setIsContractError(false)
    //   setAddWithoutExpiryError(true)
    //   return
    // }

    setIsSaving(true)
    const errors = []
    try {
      await addResource()
      setAddWithoutExpiryError(false)
      setIsContractError(false)
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? EditResourcesStrings.errorOccurred
      errors.push(`${resourceId}: ${errorMessage}`)
    }

    if (errors.length) {
      setAPIErrors(errors)
      setIsErrorVisible(true)
    } else {
      setIsSuccessVisible(true)
    }

    setIsOk(true)
    setIsSaving(false)
    setResourceId('')
    queryClient.invalidateQueries(`${ResourcesAPI.queryNames.GET_BY_ORGANIZATION_ID}${organizationId}`)
  }

  const onProductsChange = (value: any) => {
    setProductsSelected(value)
  }

  return (
    <>
      <Toast
        isVisible={isSuccessVisible}
        text={EditResourcesStrings.resourceAddedSuccess}
        textId="add-resources-success-bid-js"
        onDismiss={() => setIsSuccessVisible(false)}
        type="success"
      />
      <Toast
        isVisible={isErrorVisible}
        text={apiErrors?.join(', ')}
        textId="add-resources-toast-error-bid-js"
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <Toast
        isVisible={isInputErrorVisible}
        text={'please select atleast one API product'}
        textId={'api-product-toast-error-bid-js'}
        onDismiss={() => setIsInputErrorVisible(false)}
        type="error"
      />
      <Toast
        isVisible={isContractError}
        text={contractError}
        textId={'equipment-contract-toast-error-bid-js'}
        onDismiss={() => setIsContractError(false)}
        type="error"
      />
      <Toast
        isVisible={isAddWithoutExpiryError}
        text={'Please remove invalid product or set the expiry date'}
        textId={'equipment-add-contract-no-expiry-toast-error-bid-js'}
        onDismiss={() => setAddWithoutExpiryError(false)}
        type="error"
      />
      <TableRow>
        <Row style={{ marginLeft: '10px' }}>
          <RowContentSmall>{kenId}</RowContentSmall>
          <RowContentLarge>
            <ProductSelectionAuto>
              <ProductOptions
                defaultProducts={[]}
                onChange={(products) => {
                  onProductsChange(products)
                  setProductExpiryDate(products)
                  setCurrentProduct(products[products.length - 1])
                }}
                resourceType={'ken'}
              />
            </ProductSelectionAuto>
          </RowContentLarge>
          <RowContentMedium>
            <SwitchContainer>
              <DatePickerContainer>
                <DatePicker
                  onChange={onDateChange}
                  disabled={!isExpires}
                  disabledDate={disabledDate}
                  value={date !== '' ? moment(date) : null}
                />
              </DatePickerContainer>
              {!noContractFound && productsSelected.length != 0 && (
                <UpdateCounterBuilding>
                  {`+` + `${Object.keys(contractDatForSelectedProducts).length}`}
                </UpdateCounterBuilding>
              )}
            </SwitchContainer>
          </RowContentMedium>
          <RowContentSmall>
            <AddContainer>
              <AddIcon onClick={onClickAdd} />
              {isSaving && (
                <Loader>
                  <RingSpinner />
                </Loader>
              )}
              {isOk && <OkIcon style={{ width: '20px', height: '20px', alignSelf: 'center' }} />}
            </AddContainer>
          </RowContentSmall>
        </Row>
      </TableRow>
    </>
  )
}

const KenResourceList: FC<Props> = ({ organizationId, building, buildingId, group, contract }) => {
  const getList = () => {
    return group.kens.map((ken) => {
      return (
        <KenResource
          key={ken.id}
          organizationId={organizationId}
          building={building}
          buildingId={buildingId}
          groupId={group.id}
          kenId={ken.id}
          contract={contract}
        />
      )
    })
  }

  return (
    <>
      <HeaderRow style={{ marginLeft: '15px' }}>
        <RowContentSmall>KenId</RowContentSmall>
        <RowContentLarge>Products</RowContentLarge>
        <RowContentMedium>Expiry</RowContentMedium>
        <RowContentSmall>Action</RowContentSmall>
      </HeaderRow>
      {getList()}
    </>
  )
}
export default React.memo(KenResourceList)
