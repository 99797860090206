import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  UpperPageContainer,
  InnerPageContainer,
  ContactButtonArea,
  ContactUsButton,
  Image,
  Sticky,
  CardContainer,
  CardContent,
  HeaderLine,
} from './Styles'
import { size } from '../../../shared/utils/styles'
import { HeroImage, BackButton, Line, H2, H5, H4, P } from '../../../shared/components'
import inspirationHeroImage from '../../../assets/bg-img-inspiration.png'
import inspirationHeroImageLarge from '../../../assets/bg-img-inspiration@2x.png'
import { InspirationPageHeroImage, SharedStrings } from '../../../shared/strings/InspirationPageContent'
import TitleWrapper, { MetaTag } from '../../../shared/components/TitleWrapper'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

type ArticleImage = {
  small: string
  large: string
}

type Props = {
  title: string
  firstTextBlock: string
  subtitle: string
  secondTextBlock: string
  findOutMoreText: string
  contactUsLink: string
  articleImage: ArticleImage
  imageAlt: string
  metaTags: MetaTag[]
}

const InspirationArticleTemplate: FC<Props> = ({
  title,
  firstTextBlock,
  subtitle,
  secondTextBlock,
  findOutMoreText,
  contactUsLink,
  articleImage,
  imageAlt,
  metaTags,
}) => {
  const history = useHistory()

  const scroll = useCallback((node) => {
    if (node !== null) {
      window.scrollTo({
        top: node.getBoundingClientRect().top,
      })
    }
  }, [])

  const images = {
    small: inspirationHeroImage,
    large: inspirationHeroImageLarge,
  }

  return (
    <>
      <TitleWrapper title={title} metaTags={metaTags} />
      <HeroImage
        title={InspirationPageHeroImage.title}
        body={InspirationPageHeroImage.body}
        image={images}
        button={false}
        ingressWidth={'700px'}
      />
      <UpperPageContainer ref={scroll}>
        <Sticky>
          <BackButton onClick={() => history.goBack()} />
        </Sticky>
        <InnerPageContainer>
          <H2 textAlign="center">{title}</H2>
          <HeaderLine />
          <P dangerouslySetInnerHTML={{ __html: sanitizer(firstTextBlock) }} />
          <picture>
            <source srcSet={articleImage.large} media={`(min-width: ${size.large})`} />
            <Image src={articleImage.small} alt={imageAlt} />
          </picture>
          <H4>{subtitle}</H4>
          <P dangerouslySetInnerHTML={{ __html: sanitizer(secondTextBlock) }} />
        </InnerPageContainer>
      </UpperPageContainer>
      <CardContainer>
        <CardContent>
          <H2 textAlign="center">{SharedStrings.findOutMoreTitle}</H2>
          <Line />
          <H5 textAlign="center" dangerouslySetInnerHTML={{ __html: sanitizer(findOutMoreText) }} />
          <ContactButtonArea>
            <a href={contactUsLink} target="_blank" rel="noreferrer noopener">
              <ContactUsButton ghost>{SharedStrings.findOutMoreButton}</ContactUsButton>
            </a>
          </ContactButtonArea>
        </CardContent>
      </CardContainer>
    </>
  )
}

export default InspirationArticleTemplate
