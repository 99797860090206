import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'

export const ReleaseNotesLogNovemeber23 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        November 2023 - 2.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        November 2.0 release notes summarizes API Portal documentation enhancements and bug fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              <q>subscribedResourceIds</q> in Create Webhook Subscription of{' '}
              <Link to={Paths.WebhookManagementAPIDocs.path + '#createWebhookSubscription'}>
                Webhook Management API{' '}
              </Link>
              is corrected with the actual datatype which is an array of strings.
            </li>
            <li>
              Checkout the improved and updated documentation for{' '}
              <Link to={Paths.WebhookManagementAPIDocs.path}> Webhook Management API </Link> and
              <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}> Service Info API </Link> introduction paragraphs.
            </li>
            <li>
              enum values for <q>equipmentStatus</q> field in{' '}
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentEntrapmentByIds'}>
                Equipment Status REST API
              </Link>{' '}
              has been updated with exact values.
            </li>
          </UL>
        </OL>
      </P>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        November 2023
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        November release notes summarizes API Portal documentation fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              <Link to={Paths.ServiceInfoWebhookAPIDocs.path}>Service Info API webhook payload </Link>
              is updated with exact payload received by the end user.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
