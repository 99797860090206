import { OpenAPIV3 } from 'openapi-types'
import React, { FC } from 'react'
import RestDocumentation from '../RestDocumentation'

const AuthenticationAPIDocumentationV2: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const openAPISpec = require(`./authentication-api-v2.json`) as OpenAPIV3.Document

  return <RestDocumentation apiName={'authentication-api-v2'} openAPISpec={openAPISpec} />
}

export default AuthenticationAPIDocumentationV2
