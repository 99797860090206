import React, { FC, useState, useRef, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { Form } from 'antd'
import { OrganizationsAPI } from '../../../shared/api/organizations'
import { SidePanel, Toast } from '../../../shared/components'
import { EMAIL_REG_EXP, REQUIRED_FIELD } from '../../../shared/constants/auth'
import { ErrorStrings } from '../../../shared/strings/AuthFormContent'
import { ApiError } from '../../../shared/models/error'
import ErrorMessage from '../../../shared/components/ErrorMessage'
import { CreateOrganizationData, Organization } from '../../../shared/models/organization'
import countries from 'country-code-lookup'
import EditProductsForm from './EditProductsForm'
import AddResourcesForm from './AddResourcesForm'
import InviteMembersForm from './InviteMembersForm'
import MemberList, { MemberListRef } from './MemberList'
import ResourceList from './ResourceList'
import countryLookup from 'country-code-lookup'
import { EditFormStrings, TierPlanStrings } from '../../../shared/strings/OrganizationsContent'
import {
  BottomLine,
  ButtonsContainer,
  CloseButton,
  FormLabel,
  InputName,
  Line,
  SaveButton,
  TypeSelector,
} from './Styles'
import TierPlanList from './TierPlanList'
import EditTierPlan from './EditTierPlan'

const { Option } = TypeSelector

type Props = {
  organization: Organization
  onCloseEdit: () => void
  isEditingNewOrganization: boolean
}

const OrganizationsEditForm: FC<Props> = ({ organization, onCloseEdit, isEditingNewOrganization }) => {
  const [editOrgErrorMessage, setEditOrgErrorMessage] = useState<string | undefined>()
  const [isEditOrgErrorVisible, setIsEditOrgErrorVisible] = useState(false)
  const [countryCode, setCountryCode] = useState<number>(organization.country ?? 0)

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: organization.name,
      salesforceAccountId: organization.salesforceAccountId || '',
      country: organization.country,
      distributionListEmailAddress: organization.distributionListEmailAddress || '',
    },
  })
  const { mutateAsync: updateOrganization, isLoading } = useMutation<
    Organization,
    ApiError,
    CreateOrganizationData & { id: number }
  >(OrganizationsAPI.update)
  const queryClient = useQueryClient()
  const memberListRef = useRef<MemberListRef>()

  const [isShowCreateSuccess, setIsShowCreateSuccess] = useState(isEditingNewOrganization)
  const [isShowEditSuccess, setIsShowEditSuccess] = useState(false)
  const [isInviteMembersPanelOpen, setIsInviteMembersPanelOpen] = useState(false)
  const [isEditResourcesPanelOpen, setIsEditResourcePanelOpen] = useState(false)
  const [isEditProductsPanelOpen, setIsEditProductsPanelOpen] = useState(false)
  const [isEditTierOpen, setIsEditTierOpen] = useState(false)

  const hideToastNotifications = () => {
    setIsShowCreateSuccess(false)
    setIsShowEditSuccess(false)
    setIsEditOrgErrorVisible(false)
    memberListRef?.current?.hideToastNotifications()
  }

  const onSubmit = async (data: CreateOrganizationData) => {
    hideToastNotifications()
    data.country = countryCode

    try {
      await updateOrganization(Object.assign({ id: organization.id }, data))
      queryClient.invalidateQueries(OrganizationsAPI.queryNames.GET_ALL)
      setIsShowEditSuccess(true)
      reset(data)
    } catch (err) {
      const data = err.response?.data
      const errorDetail = data?.errors ? `: ${data?.errors[0].message}` : ''
      setEditOrgErrorMessage(data?.message ? data?.message + errorDetail : EditFormStrings.editOrganizationError)
      setIsEditOrgErrorVisible(true)
    }
  }

  const onCountryChange = (value: any) => {
    setCountryCode(Number(value))
    setValue('country', Number(value), { shouldDirty: true })
  }

  const filterOption = (input: any, option: any) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    }
    return false
  }

  const getCountries = () => {
    return countries.countries.map((countryItem) => {
      return (
        <Option
          key={countryItem.isoNo}
          id={countryItem.country.replace('/ ./g', '-') + '.js'}
          value={countryItem.isoNo}
        >
          {countryItem.country}
        </Option>
      )
    })
  }

  const onClickInviteMembers = () => {
    hideToastNotifications()
    setIsInviteMembersPanelOpen(true)
  }

  const onClickEditResources = () => {
    hideToastNotifications()
    setIsEditResourcePanelOpen(true)
  }

  const onClickEditProducts = () => {
    hideToastNotifications()
    setIsEditProductsPanelOpen(true)
  }

  const onClickEditTierPlan = () => {
    hideToastNotifications()
    setIsEditTierOpen(true)
  }

  const closeEditResourcesPanel = () => {
    setIsEditResourcePanelOpen(false)
  }

  const closeInviteMembersPanel = () => {
    setIsInviteMembersPanelOpen(false)
  }

  const closeEditProductsPanel = () => {
    setIsEditProductsPanelOpen(false)
  }

  const closeEditTierPlan = () => {
    setIsEditTierOpen(false)
  }

  const isPersonalOrganization = organization.type === 'personal'

  return (
    <div>
      <Toast
        key="create-success"
        isVisible={isShowCreateSuccess}
        text={EditFormStrings.organizationCreated}
        textId="org-created-toast-success-js"
        onDismiss={() => setIsShowCreateSuccess(false)}
        type="success"
      />
      <Toast
        key="edit-success"
        isVisible={isShowEditSuccess}
        text={EditFormStrings.organizationSaved}
        onDismiss={() => setIsShowEditSuccess(false)}
        type="success"
      />
      <Toast
        key="create-org-error"
        isVisible={isEditOrgErrorVisible}
        text={editOrgErrorMessage}
        onDismiss={() => setIsEditOrgErrorVisible(false)}
        type="error"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Item>
          <FormLabel htmlFor="organization-name-input-js">{EditFormStrings.organizationName}</FormLabel>
          <Controller
            render={({ field }) => (
              <InputName id="organization-name-input-js" {...field} disabled={isPersonalOrganization} />
            )}
            name="name"
            control={control}
            rules={{
              required: true,
            }}
          />
          {errors.name && <ErrorMessage>{EditFormStrings.organizationNameRequired}</ErrorMessage>}
        </Form.Item>
        <Form.Item>
          <FormLabel htmlFor="distribution-list-email-address">{EditFormStrings.distributionList}</FormLabel>
          <Controller
            render={({ field }) => <InputName id="distribution-list-email-address-id" {...field} />}
            name="distributionListEmailAddress"
            control={control}
            rules={{
              pattern: {
                value: EMAIL_REG_EXP,
                message: ErrorStrings.invalidEmailError,
              },
            }}
          />
          {errors.distributionListEmailAddress && (
            <ErrorMessage id="email-error-message-js">
              {errors.distributionListEmailAddress.message || REQUIRED_FIELD}
            </ErrorMessage>
          )}
        </Form.Item>
        <Form.Item>
          <FormLabel htmlFor="salesforce-account-id">{EditFormStrings.salesforceAccountId}</FormLabel>
          <Controller
            render={({ field }) => (
              <InputName id="salesforce-account-id" {...field} disabled={isPersonalOrganization} />
            )}
            name="salesforceAccountId"
            control={control}
          />
        </Form.Item>
        <Form.Item>
          <FormLabel>Country</FormLabel>
          <Controller
            render={({ field }) => (
              <TypeSelector
                showSearch
                style={{ width: '50%' }}
                id="country-selector-js"
                {...field}
                value={countryLookup.byIso(countryCode)?.country}
                onChange={(value) => {
                  field.onChange(value)
                  onCountryChange(value)
                }}
                disabled={isPersonalOrganization}
                getPopupContainer={(trigger) => trigger.parentNode}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                {getCountries()}
              </TypeSelector>
            )}
            name="country"
            control={control}
            rules={{
              required: true,
            }}
          />
          {errors.country && <ErrorMessage>{EditFormStrings.countryRequired}</ErrorMessage>}
        </Form.Item>

        {!isPersonalOrganization && (
          <>
            <Line />
            <MemberList
              ref={memberListRef}
              organizationId={organization.id}
              showListHeader
              onClickAdd={onClickInviteMembers}
              onStartAction={hideToastNotifications}
            />
            <Line />
            <ResourceList
              organizationId={organization.id}
              onClickAdd={onClickEditResources}
              onStartAction={hideToastNotifications}
            />
            {/* <Line />
            <ProductList organizationId={organization.id} onClickEdit={onClickEditProducts} /> */}
            <Line />
            <TierPlanList orgId={organization.id} openEditTierPlan={onClickEditTierPlan} />
          </>
        )}
        <BottomLine />
        <ButtonsContainer>
          <CloseButton onClick={onCloseEdit} ghost>
            {EditFormStrings.closeButton}
          </CloseButton>
          <SaveButton id="save-org-button-js" type="primary" htmlType="submit" disabled={!isDirty} loading={isLoading}>
            {EditFormStrings.saveOrganization}
          </SaveButton>
        </ButtonsContainer>
      </form>
      {isEditResourcesPanelOpen && (
        <SidePanel
          key="edit-resources"
          isOpen={isEditResourcesPanelOpen}
          title={EditFormStrings.sidePanelEditResources}
          showBackButton={true}
          backButtonId="edit-resources-back-button-js"
          onClose={closeEditResourcesPanel}
          stacked={true}
        >
          <AddResourcesForm organizationId={organization.id} onCloseAdd={closeEditResourcesPanel} />
          {/* <EditResourcesForm organization={organization} onCloseAdd={closeEditResourcesPanel} /> */}
        </SidePanel>
      )}

      <SidePanel
        key="invite-members"
        isOpen={isInviteMembersPanelOpen}
        title={EditFormStrings.sidePanelInviteMembers}
        showBackButton={true}
        backButtonId="invite-members-back-button-js"
        onClose={closeInviteMembersPanel}
        stacked={true}
      >
        <InviteMembersForm organization={organization} />
      </SidePanel>
      <SidePanel
        key="edit-products"
        isOpen={isEditProductsPanelOpen}
        title={EditFormStrings.sidePanelEditApis}
        showBackButton={true}
        backButtonId="edit-products-back-button-js"
        onClose={closeEditProductsPanel}
        stacked={true}
      >
        <EditProductsForm organization={organization} onCancelEdit={closeEditProductsPanel} />
      </SidePanel>
      <SidePanel
        key="edit-tier-plan"
        isOpen={isEditTierOpen}
        title={TierPlanStrings.editTitle}
        showBackButton={true}
        backButtonId="edit-tier-plan-back-button-js"
        onClose={closeEditTierPlan}
        stacked={true}
      >
        <EditTierPlan orgId={organization.id} onCancel={closeEditTierPlan} />
      </SidePanel>
    </div>
  )
}

export default OrganizationsEditForm
