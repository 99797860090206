import React, { ChangeEvent, FC, useState } from 'react'
import { UserStrings } from '../../shared/strings/UsersContent'
import { H4, SidePanel } from '../../shared/components'
import { ReactComponent as SearchIcon } from '../../assets/icon-search-find.svg'
import { ReactComponent as OkIcon } from '../../assets/ok-check-icon.svg'
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg'
import { ReactComponent as ClearIcon } from '../../assets/icon-search-clear.svg'
import Pagination from '../../shared/components/Pagination'
import { TABLE_CONTENT_LENGTH } from '../../shared/constants/common'
import RingSpinner from '../../shared/components/RingSpinner'
import {
  SearchCreateRow,
  FilterContainer,
  SearchInput,
  MessageContainer,
  CountHeading,
  HeaderRow,
  OrgRowContentLarge,
  OrgRowContent,
  PaginationPlacing,
  Row,
  ProfileDetails,
  SelectFilter,
  CreateButton,
  TypeSelector,
  StatusSelector,
  RowExpand,
  IconExpand,
  IconHide,
  UserDetails,
  StatusHeader,
  EnabledHeader,
  CreatedHeader,
  UpdatedHeader,
  SubscriptionHeader,
  Status,
  UserStatus,
  Created,
  Updated,
  Subscriptions,
  EditContainer,
  CloseContainer,
} from './Styles'
import { CSVLink } from 'react-csv'
const { Option } = SelectFilter
import { useQuery } from 'react-query'
import { StatisticsAPI } from '../../shared/api/statistics'
import { SubscriptionAPI } from '../../shared/api/subscription'
import { UsersAPI } from '../../shared/api/users'
import { User, UserApp, orgUsersData, userData } from '../../shared/models/statistics'

type Props = {
  user: User
}

const joinNames = (firstName: string, lastName: string) => {
  const fullName = [firstName, lastName].filter(Boolean).join(' ')
  return fullName
}
const joinApps = (sandboxCount: number, prodCount: number) => {
  const sandbox = sandboxCount ? sandboxCount : 0
  const prod = prodCount ? prodCount : 0
  const app = [sandbox, prod].join('/')
  return app
}

const UserExtended: FC<Props> = ({ user }) => {
  const [status, setStatus] = useState('')
  const [isEditStatus, setEditStatus] = useState(false)
  const [enableUserError, setEnableUserError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const updateUserStatus = async () => {
    setEnableUserError(false)
    setIsLoading(true)
    try {
      await UsersAPI.enableUser(user.username, user.organizationIds)
      user.enabled = 'true'
      setEditStatus(false)
    } catch (e) {
      setEnableUserError(true)
    }
    setIsLoading(false)
  }

  return (
    <>
      <UserDetails>
        <StatusHeader>User Status</StatusHeader>
        <EnabledHeader>Account Status</EnabledHeader>
        <CreatedHeader>Created at</CreatedHeader>
        <UpdatedHeader>Updated at</UpdatedHeader>
        <SubscriptionHeader>Subscriptions</SubscriptionHeader>
        <Status>{user.status}</Status>
        <UserStatus style={{ display: 'flex' }}>
          {!isEditStatus && <p style={{ marginRight: '4px' }}>{user.enabled === 'true' ? 'Enabled' : 'Disabled'}</p>}
          {user.enabled === 'false' && (
            <>
              {!isEditStatus ? (
                <EditContainer onClick={() => setEditStatus(true)}>
                  <EditIcon />
                </EditContainer>
              ) : (
                <>
                  <StatusSelector
                    id="subscription-type-selector-js"
                    defaultValue={user.enabled.includes('true') ? 'Enable' : 'Disable'}
                    onChange={(val: any) => {
                      setStatus(val)
                    }}
                  >
                    <Option key="Enable" value="Enable">
                      Enable
                    </Option>
                    <Option key="Disable" value="Disable" disabled>
                      Disable
                    </Option>
                  </StatusSelector>
                  {isLoading ? (
                    <EditContainer>
                      <RingSpinner />
                    </EditContainer>
                  ) : (
                    <>
                      <EditContainer onClick={updateUserStatus}>
                        <OkIcon style={{ width: '20px', height: '20px', marginRight: '4px', marginTop: '4px' }} />
                      </EditContainer>
                      <CloseContainer onClick={() => setEditStatus(false)}>
                        <ClearIcon />
                      </CloseContainer>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </UserStatus>
        <Created>{new Date(user.created).toISOString().substring(0, 10)}</Created>
        <Updated>{new Date(user.updated).toISOString().substring(0, 10)}</Updated>
        <Subscriptions>
          {user.subscriptionType.map((s, i) => (
            <>
              <span key={i}>{s}</span>
              <br />
            </>
          ))}
        </Subscriptions>
      </UserDetails>
    </>
  )
}

const ShowUserContent: FC<Props> = ({ user }) => {
  const [isIconExpand, setIsIconExpand] = useState(true)

  return (
    <ProfileDetails>
      <Row>
        <RowExpand onClick={() => setIsIconExpand(!isIconExpand)}>
          {isIconExpand ? (
            <>
              <IconExpand />
            </>
          ) : (
            <>
              <IconHide />
            </>
          )}
        </RowExpand>
        <OrgRowContentLarge>{user.email}</OrgRowContentLarge>
        <OrgRowContent id="tile-info-user-name-js">{joinNames(user.firstName, user.lastName)}</OrgRowContent>
        <OrgRowContentLarge>{user.organization?.toString()}</OrgRowContentLarge>
        <OrgRowContent>{joinApps(user.sandboxCount!, user.prodCount!)}</OrgRowContent>
        {/* <OrgRowContent>{new Date(user?.created).toISOString().substring(0, 10)}</OrgRowContent>
          <OrgRowContent>{new Date(user?.updated).toISOString().substring(0, 10)}</OrgRowContent> */}
        {/* <OrgRowContent>
          <UserRowContentLastCell>
            <DropdownGlobalStyle>
              <Dropdown overlay={getMoreMenuItems()} placement="bottomRight" trigger={['click']}>
                <MoreItem id="open-edit-org-button-js" />
              </Dropdown>
            </DropdownGlobalStyle>
          </UserRowContentLastCell>
        </OrgRowContent> */}
      </Row>
      {!isIconExpand && <UserExtended user={user} />}
    </ProfileDetails>
  )
}

const Users: FC = () => {
  let selectedValue: string
  const { isLoading, error, data } = useQuery(UsersAPI.queryNames.GET_USERS, () => UsersAPI.getUsers())

  const getAppcount = useQuery(UsersAPI.queryNames.GET_USER_APPS, () => UsersAPI.getUsersAppCount())
  const orgUserData = useQuery(StatisticsAPI.queryNames.GET_ORGANIZATIONS_AND_USERS, () =>
    StatisticsAPI.getOrgsWithUsers(true)
  )
  const subscriptionData = useQuery(SubscriptionAPI.queryNames.GET_SUBSCRIPTION, () =>
    SubscriptionAPI.getSubscription()
  )
  const subscriptionArray = useQuery(SubscriptionAPI.queryNames.GET_SUBSCRIPTION_TYPE, () =>
    SubscriptionAPI.getSubscriptionTypes()
  )

  const subscriptionSimpleArray: any = []
  subscriptionArray.data?.forEach((element: any) => {
    subscriptionSimpleArray.push(element.subscriptionType)
  })

  const [userFilter, setUserFilter] = useState('')
  const [subscriptionTypeFilter, setSubscriptionTypeFilter] = useState({ filter: '', excelData: [] })
  const [page, setPage] = useState(0)
  const [isEditUser, setIsEditUser] = useState(false)
  const subscriptionResult: any[] = []
  const optimizeSubscription = () => {
    let fil
    subscriptionData.data.forEach((obj: any) => {
      fil = subscriptionResult.filter((val) => val.email == obj.email)
      if (fil.length === 0) {
        subscriptionResult.push({
          email: obj.email,
          subscriptionType: obj.subscribed == true ? [obj.subscriptionType] : [],
        })
      } else {
        subscriptionResult.forEach((ob) => {
          if (ob.email == obj.email && obj.subscribed == true) {
            ob.subscriptionType.push(obj.subscriptionType)
          }
        })
      }
    })
  }

  const onTypeChange = (data: any) => {
    selectedValue = data
    generateFile(selectedValue)
    setPage(0)
  }
  const showContentRows = () => {
    data.forEach((userObj: User) => {
      if (getAppcount.data.length > 0) {
        getAppcount.data.forEach((userAppObj: UserApp) => {
          if (userObj.email === userAppObj.email) {
            userObj['appCount'] = userAppObj.count
            userObj['sandboxCount'] = userAppObj.sandboxCount ? userAppObj.sandboxCount : 0
            userObj['prodCount'] = userAppObj.prodCount ? userAppObj.prodCount : 0
          }
        })
      } else {
        userObj['appCount'] = 0
        userObj['sandboxCount'] = 0
        userObj['prodCount'] = 0
      }
    })

    data.forEach((userObj: User) => {
      subscriptionResult.forEach((userAppObj: any) => {
        if (userObj.email == userAppObj.email) {
          userObj['subscriptionType'] = userAppObj.subscriptionType
        }
      })
      if (!userObj.subscriptionType) {
        userObj['subscriptionType'] = subscriptionSimpleArray
      }
    })

    data.forEach((userObj: User) => {
      const orgArray: string[] = []
      const orgIds: string[] = []
      orgUserData.data.forEach((userOrgObj: orgUsersData) => {
        userOrgObj.users.forEach((user: userData) => {
          if (userObj.email === user.userEmail) {
            orgArray.push(userOrgObj.orgName)
            orgIds.push(userOrgObj.externalId)
          }
        })
      })
      userObj.organization = orgArray
      userObj.organizationIds = orgIds
    })
  }

  const onUserFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    setUserFilter(event.target.value.toLowerCase())
  }

  if (
    !isLoading &&
    !getAppcount.isLoading &&
    !orgUserData.isLoading &&
    !subscriptionData.isLoading &&
    !subscriptionArray.isLoading
  ) {
    optimizeSubscription()
    showContentRows()
  }

  const generateFile = (selectedValue: string) => {
    const csvArray = data
      ?.filter((sub: any) => sub.subscriptionType?.includes(selectedValue))
      .map((user: any) => {
        return { Name: joinNames(user.firstName, user.lastName), Email: user.email }
      })
    setSubscriptionTypeFilter({
      filter: selectedValue == 'Select subscription' ? '' : selectedValue,
      excelData: csvArray,
    })
  }

  const showUsersList = () => {
    if (isLoading) {
      return <MessageContainer>{UserStrings.loadingUserList}</MessageContainer>
    }
    if (error) {
      return <MessageContainer>{UserStrings.userListError}</MessageContainer>
    }

    const infoTable = data
      .filter((user: User) => {
        return userFilter !== undefined
          ? user.email.toLowerCase().includes(userFilter) ||
              joinNames(user.firstName, user.lastName).toLowerCase().includes(userFilter) ||
              user.organization?.toString().toLowerCase().includes(userFilter)
          : true
      })
      .filter((user: User) => {
        return subscriptionTypeFilter.filter !== undefined
          ? user.subscriptionType?.toString().includes(subscriptionTypeFilter.filter)
          : true
      })
      .map((user: User) => {
        return <ShowUserContent key={user.username} user={user} />
      })
    return (
      <>
        <SearchCreateRow>
          <FilterContainer>
            <SearchInput
              id="search-user-input-js"
              placeholder="Search users"
              onChange={onUserFilterChange}
              suffix={userFilter ? <ClearIcon onClick={() => setUserFilter('')} /> : <SearchIcon />}
              value={userFilter}
            />
            <OrgRowContentLarge>
              <TypeSelector
                id="subscription-type-selector-js"
                defaultValue="Select Subscription type"
                onChange={onTypeChange}
                disabled={isLoading}
              >
                <Option key="Select" value="Select subscription">
                  Select subscription
                </Option>
                {subscriptionSimpleArray.map((subscriptionType: any) => {
                  return (
                    <Option key={subscriptionType} id={subscriptionType} value={subscriptionType}>
                      {subscriptionType}
                    </Option>
                  )
                })}
              </TypeSelector>
            </OrgRowContentLarge>
            <CreateButton
              disabled={subscriptionTypeFilter.excelData.length <= 0 || userFilter.toString().length > 0}
              type="primary"
            >
              <CSVLink
                key="csv-generator"
                data={subscriptionTypeFilter.excelData}
                filename={subscriptionTypeFilter.filter ? subscriptionTypeFilter.filter : 'subscription.csv'}
              >
                EXPORT
              </CSVLink>
            </CreateButton>
          </FilterContainer>
        </SearchCreateRow>
        <CountHeading></CountHeading>
        <HeaderRow>
          <OrgRowContentLarge>Username</OrgRowContentLarge>
          <OrgRowContent>Profile</OrgRowContent>
          <OrgRowContentLarge>Organizations</OrgRowContentLarge>
          <OrgRowContent>Applications</OrgRowContent>
        </HeaderRow>
        {infoTable.slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))}
        <PaginationPlacing>
          <Pagination dataLength={infoTable.length == 0 ? 1 : infoTable.length} page={page} setPage={setPage} />
        </PaginationPlacing>
      </>
    )
  }

  return (
    <div>
      <H4>{UserStrings.users}</H4>
      {!isLoading &&
      !getAppcount.isLoading &&
      !orgUserData.isLoading &&
      !subscriptionData.isLoading &&
      !subscriptionArray.isLoading
        ? showUsersList()
        : 'Loading user data'}

      <SidePanel
        key="edit-user-side-panel"
        isOpen={isEditUser}
        title="Edit user details"
        showBackButton={true}
        onClose={() => setIsEditUser(false)}
      >
        <p> hi</p>
      </SidePanel>
    </div>
  )
}

export default Users
