import { useEffect } from 'react'

export default function useScrollToHash(): void {
  useEffect(() => {
    const { hash } = window.location
    if (hash) {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        // Ensure that the painting is done
        setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'start' }), 1)
      }
    }
  }, [])
}
