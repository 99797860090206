import styled from 'styled-components'
import { H4 } from '../../shared/components'
import { color } from '../../shared/utils/styles'
import { Checkbox, Input } from 'antd'
import Button from '../../shared/components/Button'

const { TextArea } = Input

export const PageTitleRow = styled.div`
  position: relative;
`

export const PageDescription = styled(H4)`
  margin: 0 0 32px 0;
  white-space: pre-line;
`

export const FeedbackForm = styled.div`
  max-width: 296px;
`

export const Label = styled.label`
  display: inline-block;
  line-height: 18px;
  margin-bottom: 10px;
  color: ${color.gray10};
`

export const InfoLabel = styled.div`
  font-size: 12px;
  line-height: 15px;
  margin-top: 20px;
  color: ${color.gray10};
  text-align: center;
`

export const FormElement = styled.div`
  max-width: 296px;
  border: 1px solid ${color.blueTint20};
  border-radius: 5px;
`
export const FormElementForBuilding = styled.div`
  max-width: 180px;
  border: 1px solid ${color.blueTint20};
  border-radius: 5px;
`
export const SelectItem = styled.div`
  margin-left: 25px;
`
export const MessageFormElement = styled.div`
  width: 296px;
  max-width: 500px;
`

export const InputDescription = styled(TextArea)`
  border: 1px solid ${color.blueTint20};
`

export const FeedbackCheckbox = styled(Checkbox)`
  font-size: 16px;
  line-height: 32px;
  color: ${color.gray20};

  .ant-checkbox {
    border: 1px solid ${color.blueTint20};
    border-radius: 1px;
    background: ${color.white} 0% 0% no-repeat padding-box;
  }
  .ant-checkbox-checked::after {
    border: 1px solid ${color.blueTint20};
  }
  .ant-checkbox-inner {
    border: transparent;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.blueTint20};
    border-radius: 0px;
    border: 1px solid ${color.blueTint20};
  }
`
export const FeedbackSubtopicCheckbox = styled(Checkbox)`
  font-size: 16px;
  line-height: 32px;
  color: ${color.gray20};

  .ant-checkbox {
    border: 1px solid ${color.blueTint20};
    border-radius: 1px;
    background: ${color.white} 0% 0% no-repeat padding-box;
  }
  .ant-checkbox-checked::after {
    border: 1px solid ${color.blueTint20};
  }
  .ant-checkbox-inner {
    border: transparent;
    widht: 14px;
    height: 14px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.blueTint20};
    border-radius: 0px;
    border: 1px solid ${color.blueTint20};
  }
`

export const SendButton = styled(Button)`
  max-width: 296px;
  height: 40px;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 18px;
`

export const ErrorMessage = styled.p`
  color: ${color.red};
  margin: 0;
`
