import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'

export const TypeContainer = styled.div`
  width: 224px;
`
export const ListTableHeaderRow = styled.div`
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
  margin-top: 20px;
`

export const TableContentRow = styled.div`
  display: flex;
  background-color: ${color.black};
  align-items: center;
  padding: 14px 10px;
  margin-bottom: 5px;
  width: 500px;
`
