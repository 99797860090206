import { Row } from 'antd'
import { OpenAPIV3 } from 'openapi-types'
import React, { FC, useState } from 'react'
import { ReactComponent as IconExpand } from '../../../../assets/icons-sort-chevron-down.svg'
import { ReactComponent as IconHide } from '../../../../assets/icons-sort-chevron-up.svg'
import { P } from '../../../../shared/components/Typography'
import useIsPrint from '../../../../shared/utils/isPrint'
import { CodeBlock, LeftColTight, RightColEmpty, RightColTight } from '../../Styles'
import { formatExampleResponse, solveResponseReference } from '../open-api-utils'
import Schema from './Schema'
import { ExpandResponseIconContainer, HTTPStatusCode, ResponseTitle } from './Styles'

type ResponseProps = {
  statusCode: string
  responseOrReference: OpenAPIV3.ReferenceObject | OpenAPIV3.ResponseObject
  document: OpenAPIV3.Document
}

const Response: FC<ResponseProps> = ({ statusCode, responseOrReference, document }: ResponseProps) => {
  const response = solveResponseReference(responseOrReference, document)

  const statusCodeNumber = parseInt(statusCode, 10)
  const isSuccessResponse = statusCodeNumber >= 200 && statusCodeNumber < 300

  const isPrint = useIsPrint()

  const [isExpanded, setIsExpanded] = useState(isSuccessResponse)

  return (
    <>
      <Row>
        <LeftColTight span="12">
          <ResponseTitle onClick={() => setIsExpanded(!isExpanded)}>
            <ExpandResponseIconContainer>
              {isExpanded || isPrint ? <IconHide /> : <IconExpand />}
            </ExpandResponseIconContainer>
            <HTTPStatusCode>{statusCode}</HTTPStatusCode> {response.description}
          </ResponseTitle>
        </LeftColTight>
        <RightColEmpty span="12"></RightColEmpty>
      </Row>

      {(isExpanded || isPrint) &&
        (response.content ? (
          Object.entries(response.content)?.map(([mediaType, content]) => (
            <Row key={mediaType}>
              <LeftColTight span="12">
                <P size="small">
                  <strong>Media type</strong>: {mediaType}
                </P>
                {content.schema && <Schema schemaOrRef={content.schema} document={document} />}
              </LeftColTight>
              <RightColTight span="12">
                {content.examples &&
                  Object.values(content.examples).map((example: any, index: number) => {
                    return (
                      <React.Fragment key={`example${index}`}>
                        <P size="small">Response example {index + 1}</P>
                        <CodeBlock language="typescript">
                          {formatExampleResponse(statusCode, mediaType, example.value)}
                        </CodeBlock>
                      </React.Fragment>
                    )
                  })}
                {!content.examples && (
                  <React.Fragment>
                    <P size="small">Response example</P>
                    <CodeBlock language="typescript">
                      {formatExampleResponse(statusCode, mediaType, content.example)}
                      {console.log('example:', content.example)}
                    </CodeBlock>
                  </React.Fragment>
                )}
              </RightColTight>
            </Row>
          ))
        ) : (
          <Row key="empty-response">
            <LeftColTight span="12">
              <P size="small">The response has no body.</P>
            </LeftColTight>
            <RightColTight span="12">
              <P size="small">Response example</P>
              <CodeBlock language="typescript">{formatExampleResponse(statusCode)}</CodeBlock>
            </RightColTight>
          </Row>
        ))}
    </>
  )
}

export default Response
