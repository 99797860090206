import styled from 'styled-components'
import { Input, Select } from 'antd'
import { Button, Text3 } from '../../../../shared/components'
import { color } from '../../../../shared/utils/styles'

const { TextArea } = Input

export const Title = styled(Text3)`
  margin-bottom: 26px;
`

export const FormContainer = styled.div`
  display: flex;
`

export const InputsContainer = styled.div`
  flex-grow: 1;
  margin-right: 16px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const AddButton = styled(Button)`
  width: 208px;
  margin-top: auto;
`

export const Line = styled.hr`
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #3d464c;
  opacity: 1;
`

export const FormLabel = styled.p`
  margin-bottom: 8px;
  display: block;
`

export const MessageContainer = styled.p`
  margin: 0;
`

export const InputRowContainer = styled.div`
  display: flex;
  margin-bottom: 0px;
`

export const InputRowContainerCoordinate = styled.div`
  display: flex;
  margin-bottom: 0px;
  width: 240px;
`

export const InputColumn = styled.div`
  width: 100%;
  margin: 0 auto;
`

export const InputField = styled(Input)`
  color: ${color.gray75};
`

export const InputName = styled(InputField)`
  width: 350px;
  color: ${color.gray75};
`

export const InputId = styled(InputField)`
  width: 220px;
  color: ${color.gray75};
`

export const Address = styled(InputField)`
  width: 350px;
  margin-right: 10px;
  margin-bottom: 20px;
`

export const InputCoordinate = styled(InputField)`
  width: 110px;
`
export const TypeSelector = styled(Select)`
  width: 270px;
  margin-bottom: 20px;
`

export const TypeSelectorSmall = styled(TypeSelector)`
  width: 225px;
  margin-bottom: 20px;
  margin-right: 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CancelButton = styled(Button)`
  width: 203px;
`

export const CreateButton = styled(Button)`
  width: 203px;
`

export const RemoveButton = styled(Button)`
  width: 165px;
  background-color: ${color.red};
  color: ${color.white};
  text-transform: uppercase;
`

export const InputDescription = styled(TextArea)`
  color: ${color.gray75};
  width: 100%;
  min-height: 100px;
`

export const LabelHolder = styled.div`
  padding-bottom: 20px;
`
