import { Col, Row } from 'antd'
import React, { FC } from 'react'
import useScrollToHash from '../../../shared/utils/scrollToHash'
import { Authentication } from './sections/Authentication'
import { Introduction } from './sections/Introduction'
import { TableOfContents } from './sections/TableOfContents'
import { UsageExample } from './sections/UsageExample'
import { DeckState, ErrorEvent, LiftCallState, LiftDoorState, OkEvent } from './sections/WebsocketEvents'
import { LiftCall } from './sections/WebsocketRequests'

export interface AsyncDocumentationProps {
  asyncApiProps: AsyncApiProps
}
export interface AsyncApiProps {
  apiName: string
  scopePrefix: string
  callType: string
  introText: string
}

export const elevatorCallApi: AsyncApiProps = {
  apiName: 'Elevator WebSocket API',
  scopePrefix: 'callgiving',
  callType: 'normal',
  introText:
    'With Elevator WebSocket API you can implement elevator call-giving features in the applications of your choice. Through Elevator Call API, an application can execute ' +
    'elevator calls and receive real-time data about the call and the assigned elevator.',
}

export const serviceRobotApi: AsyncApiProps = {
  apiName: 'Elevator WebSocket API',
  scopePrefix: 'robotcall',
  callType: 'robot',
  introText:
    'With Elevator WebSocket API you can implement elevator call-giving features in the applications of your choice. Through Service Robot API, an application can execute ' +
    'elevator calls and receive real-time data about the call and the assigned elevator. Robot call type ensures longer time (default 15 seconds) before doors close on source ' +
    'and destination areas.',
}

const AsyncDocumentation: FC<AsyncDocumentationProps> = ({ asyncApiProps }) => {
  useScrollToHash()

  return (
    <Row>
      {/* Everything is wrapped into one column, within that, rows with 2 columns each */}
      <Col span={24}>
        <Introduction asyncApiProps={asyncApiProps} />

        <TableOfContents />

        <Authentication asyncApiProps={asyncApiProps} />

        <UsageExample asyncApiProps={asyncApiProps} />

        <LiftCall asyncApiProps={asyncApiProps} />

        <LiftCallState />
        <LiftDoorState />
        <DeckState />
        <OkEvent />
        <ErrorEvent />
      </Col>
    </Row>
  )
}

export default AsyncDocumentation
