export const MenuStrings = {
  koneApiPortal: 'KONE API Portal',
  accessKoneApiPortal: 'Access KONE API Portal',
  goToDashboard: 'To Dashboard',
  goToAdminPanel: 'To Admin',
  menuItems: {
    inspiration: 'Inspiration',
    gettingStarted: 'Getting started',
    koneApis: 'KONE APIs',
    integratedSolutions: 'Integrated solutions',
  },
}
