import React, { FC } from 'react'
import { ServiceRobotApiV2 } from '../../../shared/strings/KONEAPIsTextContent'
import { PathNames } from '../../../shared/strings/PathsContent'
import Paths from '../../Paths'
import KONEAPIPage from '../KONEAPIPage'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

const apis_v2 = [Paths.AuthenticationAPIV2, Paths.ElevatorWebsocketAPIRobotsV2]

const ElevatorCallAPIPage: FC = () => {
  return (
    <>
      <KONEAPIPage title={PathNames.serviceRobotAPIV2} apis={apis_v2}>
        <div dangerouslySetInnerHTML={{ __html: sanitizer(ServiceRobotApiV2.body) }} />
      </KONEAPIPage>
    </>
  )
}

export default ElevatorCallAPIPage
