import styled, { css } from 'styled-components'
import { color } from '../../utils/styles'

const tableCommonStyle = css`
  color: ${color.gray10};
  width: 100%;
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
`

const HeaderRow = styled.div`
  ${tableCommonStyle};
  font-size: 14px;
`

const ContentRow = styled.div`
  ${tableCommonStyle};
  background-color: ${color.gray85};
  min-height: 48px;
  margin-bottom: 2px;
  border-radius: 4px;
`

const Column = styled.div`
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Table = {
  HeaderRow,
  ContentRow,
  Column,
}

export default Table
