import styled from 'styled-components'
import { color, size } from '../../utils/styles'
import backArrow from '../../../assets/icons-back-arrow.svg'

export const Circle = styled.div`
  height: ${(p: any) => (p?.height ? p.height : 64)}px;
  width: ${(p: any) => (p?.width ? p.width : 64)}px;
  background-color: ${color.blueBase};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    background: ${color.lightBlue};
  }

  @media (max-width: ${size.medium}) {
    height: 54px;
    width: 54px;
  }

  @media (max-width: ${size.small}) {
    height: 42px;
    width: 42px;
  }
`

export const Icon = styled.div`
  width: 28px;
  height: 24px;
  background-image: url(${backArrow});
  background-repeat: no-repeat;

  @media (max-width: ${size.medium}) {
    width: 24px;
    height: 22px;
  }

  @media (max-width: ${size.small}) {
    width: 20px;
    height: 18px;
  }
`
