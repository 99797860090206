import React, { FC } from 'react'
import { Container, H1, H4, P, Line } from '../Styles'
import { SEOProps } from '../../../shared/strings/TermsAndConditionsContent'
import { OL, TitleWrapper } from '../../../shared/components'
import { Feature, isFeatureEnabled } from '../../../shared/utils/featureFlags'

type Props = {
  isModal?: boolean
}

const TermsAndConditionsContent: FC<Props> = ({ isModal }) => {
  return (
    <>
      <TitleWrapper title={SEOProps.title} metaTags={SEOProps.metaTags} />
      <Container isModal={isModal}>
        <H1 textAlign="center" isModal={isModal}>
          Terms & Conditions for Use of KONE API Portal
        </H1>
        {!isModal && <Line />}
        <H4>1. Background</H4>
        <P>
          This is an agreement (the <strong>“Agreement”</strong>) between you and KONE Corporation (referred to
          hereinafter together with its affiliates and subsidiaries as <strong>“KONE”</strong>) who is offering the KONE
          API Portal (the <strong>“Portal”</strong>) for your use for:
        </P>
        <OL type="I">
          <li>
            testing and development purposes; (<strong>“Development Purpose”</strong>) or
          </li>
          <li>
            if so explicitly agreed between the organization you represent (the{' '}
            <strong>“API Consumer Organization”</strong>) and KONE, for the purposes of operating and maintain a
            solution (the <strong>“API Consuming Solution”</strong>) consuming the KONE application interfaces as
            offered by KONE from time to time for the selected KONE equipment and solutions (the{' '}
            <strong>“KONE API(s)”</strong>). (<strong>“Consuming Purpose”</strong>)
          </li>
        </OL>
        <P>
          Please read this Agreement carefully before using the Portal. By using the Portal, you agree to be bound by
          this Agreement. If you do not accept this Agreement, then you may not use the Portal.
        </P>
        <P>
          For the avoidance of doubt, your usage of the Portal for Consuming Purpose may be further governed by an
          agreement concluded between KONE and the API Consumer Organization regarding the usage of KONE APIs for
          benefit of common end-customers (the <strong>“API Consumer Agreement”</strong>), or an agreement the API
          Consuming Organization or you yourself have concluded with the common end-customer to whose benefit the KONE
          APIs are being used (the <strong>“API Consuming End-Customer”</strong>) for the provisioning of the API
          Consuming Solution (the <strong>“Sales Contract for API Consuming Solution”</strong>).
        </P>
        <H4>2. Liability of Credentials</H4>
        <P>
          You shall acknowledge and agree that you are liable for your use of the passwords and/or username granted for
          you for accessing the Portal (the <strong>“Credentials”</strong>). The Credentials are personal and may be
          used by you only. You are prohibited from sharing the Credentials with other users.
        </P>
        <H4>3. Right to use</H4>
        <P>
          Subject to your compliance with this Agreement, KONE hereby grants you a non-exclusive, non-transferable,
          non-sublicensable, limited right and license to use the Portal for the Development Purpose, which shall
          include only non-commercial use of the Portal and the materials provided therein, including the demo and test
          environment and data.
        </P>
        <P>
          Subject to your compliance with this Agreement, the API Consumer Agreement and the Sales Contract for API
          Consuming Solution (as applicable), KONE hereby grants you a non-exclusive, non-transferable,
          non-sublicensable, limited right and license to use the Portal and the materials provided therein, as well as
          the data flowing through the KONE API (the <strong>“API Data”</strong>) for Consuming Purpose.
        </P>
        <H4>4. Limitations and pre-conditions for right to use</H4>
        <H4>4.1 General Limitations</H4>
        <P>
          You shall provide KONE with legal, true, accurate, up-to-date and complete information of the API Consuming
          Solution and of yourself (as needed for granting the Credentials).
        </P>
        <P>
          You agree not to use the KONE API, the Portal and the material provided therein (including the demo and test
          environment and data) or the API Data (all hereinafter together the <strong>“KONE Property”</strong>)
        </P>
        <OL type="I">
          <li>
            for the purpose of or in connection with any illegal, harmful, fraudulent, infringing or offensive activity;
          </li>
          <li>
            to transmit, store, display, distribute or otherwise make available content, products or services that are
            illegal, harmful, fraudulent, infringing or offensive;
          </li>
          <li>
            to violate the security or integrity of any KONE equipment, KONE solutions or any IT or telecoms
            infrastructure, systems, devices, hardware or software; or
          </li>
          <li>
            to any activity competing with KONE in the area of elevator, escalator or automatic door manufacturing or
            maintenance industry.
          </li>
        </OL>
        {!isFeatureEnabled(Feature.IsChina) ? (
          <>
            <P>
              KONE retains the right to take serious action against those who use KONE Property for the purposes above,
              including blacklisting them, publicizing their complaints, deleting their user accounts, and other
              punitive measures available to KONE in accordance with applicable law.
            </P>
          </>
        ) : (
          <>
            <P>
              KONE retains the right to take serious action against those who use KONE Property for the purposes above,
              including blacklisting them, deleting their user accounts, and other punitive measures available to KONE
              in accordance with applicable law.
            </P>
          </>
        )}

        <H4>4.2 Limitations for right use for Development Purpose</H4>
        <P>
          When you are using the Portal for Development Purpose, you are not allowed to use the KONE Property for any
          commercial purposes such as a product, service, or solution made commercially available.
        </P>
        <H4>4.3 Pre-conditions for right to use for Consuming Purpose</H4>
        <P>Any usage of the KONE Property for Consuming Purpose shall require following conditions to be fulfilled:</P>

        <OL type="I">
          <li>
            The API Consumer Organization you represent, has been authorized and instructed by the API Consuming
            End-Customer to use the KONE API and the API Data for the Consuming Purpose (under the Sales Contract for
            API Consuming Solution or otherwise);
          </li>
          <li>
            You have been authorized and instructed by the API Consumer Organization to use the KONE Property for the
            Consuming Purpose;
          </li>
          <li>
            You or another representative of the Consumer Organization has submitted to KONE the relevant self-service
            test reports and other solution description and validation documentation for API Consuming Solution, as
            identified to you by KONE in the Portal or requested otherwise by KONE in writing (the{' '}
            <strong>“Solution Documentation”</strong>). Moreover, your right to use KONE Property is in any case limited
            only for such API Consuming Solutions that are described in the Solution Documentation;
          </li>
          <li>
            There is a valid service agreement in place for usage of the KONE API between KONE and the API Consuming
            End-Customer (<strong>“API Service Agreement”</strong>);
          </li>
          <li>
            There is a valid API Consumer Agreement in place between the API Consumer Organization and KONE, unless the
            API Consumer Organization is the API Consuming End-Customer or acting fully and only on behalf of the API
            Consuming End-Customer and under the API Consuming End-Customer’s authorization, in which case the API
            Service Agreement is sufficient together with this Agreement to govern your use of the Portal; and
          </li>
          <li>
            Your usage of the KONE Property is compliant with this Agreement, the API Consumer Agreement, the Sales
            Contract for API Consuming Solution and the API Service Agreement, as applicable in each case.
          </li>
        </OL>
        <H4>5. Right to Grant Further Credentials</H4>
        <P>
          Where instructed so by the API Consumer Organization, KONE may grant you a right to provide further
          Credentials to the Portal for other users representing the API Consumer Organization. You shall acknowledge
          and agree that you are fully liable for granting of such Credentials and especially ensuring that the users
          receiving Credentials are representatives of the API Consumer Organization and, where the KONE Property is
          being used for Consuming Purpose, that such users have been duly authorized by the API Consumer Organization
          and/or the API Consuming End-Customer (as applicable) to use the KONE API and access the API Data.
        </P>
        <H4>6. No warranty</H4>
        <P>
          UNLESS EXPLIXITLY AGREED OTHERWISE IN THE API CONSUMER AGREEMENT OR THE API SERVICE AGREEMENT (AS MAY BE
          APPLICABLE TO YOU DEPENDING ON WHETHER YOU ARE REPRESENTING THE API CONSUMING ORGANISATION OR API CONSUMING
          END-CUSTOMER), TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, THE KONE PROPERTY IS PROVIDED TO YOU
          “AS IS,” WITH ALL FAULTS, WITHOUT WARRANTY OF ANY KIND, WITHOUT PERFORMANCE ASSURANCES OR GUARANTEES OF ANY
          KIND, AND YOUR USE IS AT YOUR SOLE RISK. THE ENTIRE RISK OF SATISFACTORY QUALITY AND PERFORMANCE RESIDES WITH
          YOU. KONE DOES NOT MAKE, AND HEREBY DISCLAIMS, ANY AND ALL EXPRESS, IMPLIED OR STATUTORY WARRANTIES, INCLUDING
          IMPLIED WARRANTIES OF CONDITION, UNINTERRUPTED USE, ACCURACY OF DATA, MERCHANTABILITY, SATISFACTORY QUALITY,
          FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT OF THIRD PARTY RIGHTS, AND WARRANTIES (IF ANY) ARISING FROM
          A COURSE OF DEALING, USAGE, OR TRADE PRACTICE.
        </P>
        <H4>7. Limitation of Liability</H4>
        <P>
          UNLESS EXPLIXITLY AGREED OTHERWISE IN THE API CONSUMER AGREEMENT OR THE API SERVICE AGREEMENT (AS MAY BE
          APPLICABLE TO YOU DEPENDING ON WHETHER YOU ARE REPRESENTING THE API CONSUMING ORGANISATION OR API CONSUMING
          END-CUSTOMER), YOUR USE OF THE KONE PROPERTY IS AT YOUR SOLE RISK AND YOU SHALL BE FULLY RESPONSIBLE FOR ANY
          UNAUTHORIZED USE OF THE KONE PROPERTY. THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, KONE SHALL NOT BE
          LIABLE FOR ANY DIRECT OR INDIRECT DAMAGE RESULTING FROM THE USE, NON-FUNCTIONING OR MALFUNCTION OF THE KONE
          PROPERTY, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFIT, LOSS OF GOODWILL, COMPUTER FAILURE OR MALFUNCTION FROM
          ANY CAUSES OF ACTION ARISING OUT OF OR RELATED TO THIS AGREEMENT OR KONE PROPERTY, WHETHER ARISING IN TORT
          (INCLUDING NEGLIGENCE), CONTRACT, STRICT LIABILITY OR OTHERWISE AND WHETHER OR NOT KONE HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES.
        </P>
        <H4>8. Reporting</H4>
        <P>
          If you become aware of any violation of this Agreement, you will immediately notify KONE and provide KONE with
          reasonable assistance to stop or remedy the violation. To report any violation of this Agreement, please
          contact KONE through legal@kone.com.
        </P>
        <H4>9. Termination</H4>
        <P>
          Your rights under this Agreement will automatically terminate once you stop using the Portal and your user
          account is inactivated, or if you fail to comply with any term of this Agreement. In case of termination, KONE
          may immediately revoke your access to the Portal without any compensation. KONE may also restrict your right
          to use the KONE Property if you breach this Agreement, and restrict your right to use KONE Property for
          Consuming Purpose in case any of the pre-conditions stated in section 4.3 are not fulfilled or cease to exist.
          KONE’s failure to insist upon or enforce your strict compliance with this Agreement will not constitute a
          waiver of any of their rights hereunder.
        </P>
        {!isFeatureEnabled(Feature.IsChina) ? (
          <>
            <H4>10. Privacy</H4>
            <P>
              KONE takes the protection of your personal information very seriously and has launched the Privacy
              Statement for KONE API Portal in accordance with the latest regulatory requirements. The Privacy Statement
              is made available to you as part of the sign-in process (for your approval) and in the Portal. In case you
              have any questions or concerns regarding personal data processing, you may contact KONE through
              personaldatarequest@kone.com.
            </P>
          </>
        ) : (
          <>
            <H4>10. Privacy</H4>
            <P>
              KONE takes the protection of your personal information very seriously and has launched the KCC Rules of
              Processing Personal Information for KONE API Portal in accordance with the latest regulatory requirements.
              The Rules is made available to you as part of the sign-in process (for your approval) and in the Portal.
              In case you have any questions or concerns regarding personal data processing, you may contact KONE
              through personaldatarequest@kone.com.
            </P>
          </>
        )}
        <H4>11. Governing law</H4>
        <P>
          This Agreement is subject to the laws of Finland, without regard to-choice or conflicts of law principles.
        </P>
      </Container>
    </>
  )
}
export default TermsAndConditionsContent
