import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import koneLogo from '../../assets/kone-logo.svg'
import { Badge, Button } from '../../shared/components'
import { color, size } from '../../shared/utils/styles'

export const PublicMenuHeaderContainer = styled.header`
  margin: 50px 48px 0 48px;

  @media (max-width: ${size.large}) {
    margin: 30px 16px 0 30px;
  }

  @media (max-width: ${size.small}) {
    margin: 0;
  }
`

export const DashboardHeaderContainer = styled.header`
  position: relative;
  margin: 30px 56px 30px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TitleRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;

  @media (max-width: ${size.small}) {
    margin: 20px 16px;
  }
`

export const TitleContainer = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  height: 100%;
  align-items: center;
`

export const TitleLink = styled(Link)<{ $public?: boolean }>`
  color: ${color.gray10};
  font-size: ${(props) => (props.$public ? '24px' : '20px')};
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    margin-right: 14px;

    .a {
      transition: fill 0.3s ease;
      fill: ${color.gray10};
    }
  }

  &:hover {
    color: ${color.white};

    svg .a {
      fill: ${color.white};
    }
  }

  @media (max-width: ${size.large}) {
    font-size: 20px;
  }

  @media (max-width: ${size.small}) {
    font-size: 14px;
  }
`

export const Logo = styled.div<{ public?: boolean }>`
  width: ${(props) => (props.public ? '140px' : '100px')};
  height: ${(props) => (props.public ? '70px' : '50px')};
  background-image: url(${koneLogo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (max-width: ${size.large}) {
    width: 100px;
    height: 50px;
  }

  @media (max-width: ${size.small}) {
    width: 64px;
    height: 32px;
  }
`

export const Hamburger = styled.div<{ active: boolean }>`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span:first-of-type {
    margin-top: 4px;
  }

  span {
    height: 2px;
    width: 22px;
    background: ${(props) => (props.active ? color.blueTint60 : color.blueTint20)};
    margin-bottom: 4px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @media (max-width: ${size.small}) {
    position: absolute;
    right: 0;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`

export const MenuRow = styled.div<{ isMobileMenuOpen: boolean }>`
  height: 46px;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: ${size.large}) {
    height: auto;
    position: static;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: ${size.small}) {
    transition: visibility 0.5s, opacity 0.2s linear;
    visibility: ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? 'visible' : 'hidden')};
    opacity: ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? 1 : 0)};
    position: ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? 'unset' : 'absolute')};
    width: 100%;
    background-color: ${color.gray90};
    z-index: 100;
  }
`

export const PublicMenuContainer = styled.nav`
  width: 650px;
  display: flex;
  justify-content: space-around;

  @media (max-width: ${size.xlarge}) {
    width: 400px;
    justify-content: space-between;
  }

  @media (max-width: ${size.small}) {
    width: 100%;
    background-color: ${color.gray70};
    flex-direction: column;
    align-items: flex-start;
    padding: 0 32px;
  }
`

export const AuthMenuContainer = styled.div`
  position: absolute;
  right: 0;
  margin-right: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  padding-bottom: 10px;

  @media (min-width: ${size.small}) and (max-width: ${size.large}) {
    padding-bottom: 0px;
  }

  @media (max-width: ${size.small}) {
    position: static;
    margin-right: 0;
    justify-content: space-around;
    flex-direction: column;

    a {
      font-size: 18px;
    }
  }
`

export const GoToDashboardLink = styled(Link)`
  text-transform: uppercase;
  color: ${color.gray10};
  font-size: 20px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;

    .a {
      transition: fill 0.3s ease;
      fill: ${color.gray10};
    }
  }

  &:hover {
    color: ${color.white};
    border-bottom: none;

    svg .a {
      fill: ${color.white};
    }
  }
`

export const ProfileMenuContainer = styled.div`
  position: absolute;
  right: 0;

  @media print {
    display: none;
  }
`

export const LoginButton = styled(Button)`
  color: ${color.gray10};
  margin-right: 24px;
  font-size: 18px;

  @media (max-width: ${size.large}) {
    font-size: 16px;
  }

  @media (max-width: ${size.small}) {
    font-size: 16px;
    margin-right: 0;
  }
`

export const RegisterButton = styled(Button)`
  font-size: 18px;

  @media (max-width: ${size.large}) {
    font-size: 16px;
  }

  @media (max-width: ${size.small}) {
    width: 165px;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 4px;
  }
`

export const Arrow = styled.i`
  border: solid ${color.gray20};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  margin-bottom: 4px;
  margin-left: 12px;
  transform: rotate(45deg);
  transition: all 0.3s linear;
`

export const InitialsContainer = styled.div`
  cursor: pointer;

  &.ant-dropdown-open {
    i {
      transform: rotate(225deg);
    }
  }

  &:hover {
    ${Arrow} {
      border-color: ${color.gray10};
    }
    button {
      background-color: ${color.lightBlue};
    }
  }
`

export const InitialsCircle = styled(Button)`
  position: relative;
  width: 44px;
  height: 44px;
  padding: 0;
  font-size: 24px;
  line-height: 42px;
`

export const MenuLink = styled(Link)`
  color: ${color.gray20};
  font-size: 24px;
  line-height: 36px;
  padding-bottom: 10px;

  &:hover {
    color: ${color.gray10};
    border-bottom: none;
  }

  &.active {
    color: ${color.gray10};
    border-bottom: 4px solid ${color.blueBase};
    transition: border-bottom-width 100ms ease-in;
  }

  @media (max-width: ${size.large}) {
    font-size: 20px;
    line-height: 34px;
    &:hover,
    &.active {
      padding-bottom: 5px;
    }
  }

  @media (max-width: ${size.small}) {
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    margin-top: 22px;
    margin-bottom: 22px;

    &:hover,
    &.active {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }
`

export const DropdownGlobalStyle = createGlobalStyle`
  .ant-dropdown-menu {
    width: 240px;
    background-color: ${color.gray90};
    border: 1px solid ${color.gray70};

    @media (max-width: ${size.small}) {
      width: 200px;
    }
  }

  .ant-dropdown-menu-item {
    color: ${color.gray15};
    padding: 15px 12px;

    &> a {
      color: ${color.gray15};

      &:hover {
        color: ${color.gray15};
      }
    }

    &:hover {
      background-color: ${color.gray80};
    }
  }
`

export const EnvironmentName = styled.span`
  margin-left: 0.5em;
  color: ${color.red};

  @media print {
    display: none;
  }
`

export const StyledDropdownMenu = styled(Menu)`
  .ant-dropdown-menu-item {
    position: relative;
  }
`

export const StyledBadge = styled(Badge)`
  margin-right: 12px;
`
