import { Col, Layout, Radio as AntRadio, Row, Typography } from 'antd'
import { RadioGroupProps, RadioProps } from 'antd/lib/radio'
import React, { FC } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { H5, P } from '../../shared/components/Typography'
import styled, { css } from 'styled-components'
import { color } from '../../shared/utils/styles'
import { Table } from '../../shared/components'

const AntdTitle = Typography.Title
const AntdText = Typography.Text

const tableWidthMixin = css`
  width: 320px;
`

export const HeaderRow = styled(Table.HeaderRow)`
  ${tableWidthMixin}
  margin-left: 125px;
`
export const ContentRow = styled(Table.ContentRow)`
  ${tableWidthMixin}
`
export const Column = styled(Table.Column)`
  ${tableWidthMixin}
  flex: 1;
`

export const SectionRow = styled(Row)``

export const LeftCol = styled(Col)`
  padding: 45px 2vw 16px 2vw;

  @media print {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

export const RightCol = styled(Col)`
  background-color: #0c0d0d;
  padding: 45px 50px;

  @media print {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

export const RightColServiceStatus = styled(Col)`
  background-color: #0c0d0d;
  padding: 45px 25px;

  @media print {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

export const LeftColTight = styled(LeftCol)`
  padding: 0px 2vw;

  @media print {
    padding: 45px 2vw 16px 2vw;
  }
`

export const RightColTight = styled(RightCol)`
  padding: 0px 50px;

  @media print {
    padding: 45px 50px;
  }
`

export const RightColTightAndWide = styled(RightColTight)`
  padding: 0px;
`

export const LeftColFirst = styled(LeftCol)`
  padding: 45px 2vw 0 2vw;
`

export const RightColEmpty = styled(RightCol)`
  padding: 0px;
`
export const Container = styled(Col)`
  background-color: ${color.gray85} !important;
  max-width: fit-content;
  height: 600px;
  padding: 10px;
  overflow-y: auto;
  border-radius: 4px;
  && ::-webkit-scrollbar {
    width: 8px;
  }
  && ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  && ::-webkit-scrollbar-thumb {
    background: ${color.gray40};
    border-radius: 2px;
  }
  && ::-webkit-scrollbar-track-piece {
    background: ${color.gray70};
  }
`
const StyledCodeContainer = styled(SyntaxHighlighter)`
  background-color: ${color.gray85} !important;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  max-height: 600px;
  font-size: 14px;
  && ::-webkit-scrollbar {
    width: 12px;
  }
  && ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  && ::-webkit-scrollbar-thumb {
    background: ${color.gray40};
    border-radius: 4px;
  }
  && ::-webkit-scrollbar-track-piece {
    background: ${color.gray80};
    border-radius: 4px;
  }
`

const StyledLargeCodeContainer = styled(SyntaxHighlighter)`
  background-color: ${color.gray85} !important;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  height: 500px;
  overflow-y: auto;
  font-size: 14px;
  && ::-webkit-scrollbar {
    width: 12px;
  }
  && ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  && ::-webkit-scrollbar-thumb {
    background: ${color.gray40};
    border-radius: 4px;
  }
  && ::-webkit-scrollbar-track-piece {
    background: ${color.gray80};
    border-radius: 4px;
  }
`
export interface CodeContainerProps {
  language: string
  large?: boolean
}

export const CodeBlock: FC<CodeContainerProps> = ({ language, children, large }) =>
  large ? (
    <StyledLargeCodeContainer language={language} style={dracula}>
      {children}
    </StyledLargeCodeContainer>
  ) : (
    <StyledCodeContainer language={language} style={dracula}>
      {children}
    </StyledCodeContainer>
  )

export const Text = styled(AntdText)`
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  && {
    color: ${color.gray20};
  }
  padding-top: 10px;
  padding-bottom: 10px;
  code {
    white-space: pre-wrap;
    border: none;
    background: none;
  }
`

export const Paragraph = styled(P)``

export const FirstParagraphWrapper = styled.div`
  margin-top: 6rem;
`

export const Title = styled(AntdTitle)`
  && {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-family: KONE Information;
    color: ${color.gray20};
  }
`

export const ContentWrapper = styled(Layout)`
  min-height: 500px;
  color: ${color.gray20};
  background-color: ${color.gray80};
`

export const StyledEventName = styled.div`
  border: 1px solid #338dc7;
  border-radius: 4px;
  color: ${color.gray10};
  display: flex;
  width: fit-content;
  padding: 6px;
  margin-top: 1rem;
  margin-right: 5px;
`

export const StyledExample = styled.div`
  overflow: hidden;
  margin: 20px;
`

export const StyledGrayContainer = styled.div`
  background-color: ${color.gray85};
  padding: 10px;
`

export const RadioGroup: FC<RadioGroupProps> = styled(AntRadio.Group)`
  margin-left: 1rem;
`

export const RadioButton: FC<RadioProps> = styled(AntRadio.Button)`
  text-transform: uppercase;

  background: none;
  border-color: ${color.blueBase};

  &:first-child {
    border-color: ${color.blueBase};
  }

  span {
    color: ${color.blueBase};
  }

  &.ant-radio-button-wrapper-checked {
    background-color: ${color.blueBase};
    span {
      color: ${color.white};
    }
  }
`

type StickyToggleProps = {
  isFixed: boolean
  width?: number
}

export const StickyToggle = styled.div`
  padding: 1rem 0;
  top: 0;
  background-color: ${(props: StickyToggleProps) => (props.isFixed ? `${color.gray85Opacity}` : 'none')};
  text-align: center;
  transition: all 0.3s ease-out;
  z-index: 100;

  position: ${(props: StickyToggleProps) => (props.isFixed ? 'fixed' : 'relative')};
  width: ${(props: StickyToggleProps) => (props.width ? `${props.width}px` : 'auto')};
  padding-top: ${(props: StickyToggleProps) => (props.isFixed ? '1rem' : '55px')};
`

export const TableOfContentsStyles = {
  Items: styled.ul`
    list-style-type: none;
    padding-left: 0;
  `,
  Item: styled.li``,
  Path: styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  Name: styled(H5)`
    margin: 1.1rem 0 0 0;
  `,
}
