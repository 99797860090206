import { Row, Col } from 'antd'
import React, { FC } from 'react'
import { H4, P, UL } from '../../../../shared/components/Typography'

import { CardContainer, ContentContainer, SolutionDeploymentSvg, HeadingText } from './Styles'

const SolutionDeployment: FC = () => {
  return (
    <>
      <Row>
        <HeadingText>Solution Deployment</HeadingText>
      </Row>
      <CardContainer>
        <Row justify="space-around" align="middle">
          <ContentContainer xs={2} sm={4} md={6} lg={8} xl={12}>
            <H4>Solution Deployment</H4>
            <P size="small">
              KONE API Services add value to KONE equipment and maintenance service. Production access means that your
              application gets connected to certain KONE elevators or maintenance service data.
            </P>
            <P size="small">
              For this reason, access to KONE data or equipment through KONE APIs is available for those applications
              that are used by KONE maintenance service customers with KONE API Services contract.
            </P>
            <P size="small">
              Local KONE sales assist in questions about the available KONE equipment, maintenance service and KONE API
              Services offering and pricing.
            </P>
            <P size="small">Contact api-support@kone.com to request production access</P>
            <P size="small">
              Once you are ready with developing and testing your application in our Sandbox and preproduction (pilot
              site if possible) you can request production access to a customer site.
            </P>
            <P size="small">
              api-support@kone.com helps you with the activities needed to gain access to the correct KONE equipment or
              data. Access to production is granted to an authorized KONE API organization.
            </P>
            <P size="small">
              You can request the creation of a KONE API organization and access to production via email at Include in
              your email:
            </P>
            <P size="small">
              <UL size="small">
                <li> organization information (organization name and key contact information) </li>
              </UL>
              <UL size="small">
                <li>
                  Information about the KONE customer with maintenance service and KONE API Services contract (name,
                  country, other information if known)
                </li>
              </UL>
              <UL size="small">
                <li>
                  API(s) that your request concerns. If your request concerns Elevator Call API or Service Robot API,
                  include the location of the building(s).
                </li>
              </UL>
              <UL size="small">
                <li>Email addresses of those members that you wish to add to your KONE API organization</li>
              </UL>
              <P size="small">
                After checking the validity of your request, api-support@kone.com creates the organization with
                requested API access and adds the listed members.
              </P>
              <P size="small">You will receive confirmation when your organization is created.</P>
              <P size="small">
                From below you can download API Consumer agreement will need to be processed are returned.
              </P>
              <P size="small">
                Template will be provided on request :{' '}
                <a href="https://www.kone.com/en/contact.aspx" target="_blank" rel="noreferrer">
                  Contact us at api-support@kone.com
                </a>
              </P>
            </P>
          </ContentContainer>
          <Col xs={2} sm={16} md={12} lg={8} xl={12}>
            <SolutionDeploymentSvg />
          </Col>
        </Row>
      </CardContainer>
    </>
  )
}

export default SolutionDeployment
