import styled, { css } from 'styled-components'
import removeTag from '../../../assets/icon-tag-remove.svg'
import { color } from '../../utils/styles'

export const Container = styled.div<{ disabled: boolean }>`
  min-height: 200px;
  border-radius: 4px;
  padding: 16px 10px;
  overflow: auto;
  ${(props) =>
    props.disabled
      ? css`
          background: ${color.gray30};
        `
      : css`
          background: ${color.white};
        `}
`

export const Input = styled.input`
  color: ${color.gray75};
  outline: none !important;
  border: 0 none;
  margin: 5px;
  padding: 5px 0;
  max-width: 100%;
  &:disabled {
    background: ${color.gray30};
  }
`

export const Tag = styled.div`
  color: ${color.white};
  background-color: ${color.blueTint20};
  margin: 5px;
  padding: 10px 15px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
`

export const TagText = styled.div`
  margin-right: 12px;
`

export const TagRemove = styled.div<{ disabled: boolean }>`
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-image: url(${removeTag});
  background-repeat: no-repeat;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`
