import React, { FC } from 'react'
import { SEOProps } from '../../strings/PrivacyStatementContent'
import { OL, TitleWrapper } from '../../components'
import { Container, H1, H4, P, Line } from '../Styles'

type Props = {
  isModal?: boolean
}

const PrivacyStatementContentChina: FC<Props> = ({ isModal }) => {
  return (
    <>
      <TitleWrapper title={SEOProps.title} metaTags={SEOProps.metaTags} />
      <Container isModal={isModal}>
        <H1 textAlign="center" isModal={isModal}>
          通力 API Portal 隐私政策
          <br />
          KCC Rules of Processing Personal Information for Koine API Portal
        </H1>
        {!isModal && <Line />}
        <P>
          本个人信息处理规则（下称“本规则”）向用户提供通力电梯有限公司（以下称“通力”或“我们”） 在通力API
          Portal中处理（本规则声明下的“处理”包括收集、存储、使用、加工、传输、转移、跨境提供、提供、委托处理、公开、删除等）用户个人信息的信息。请在使用我们的网站前，仔细阅读并了解本规则，我们会适时对本规则进行修订。
        </P>
        <P className="translation">
          This KCC Rules of Processing Personal Information (hereinafter referred to as &rdquo;this Rule&rdquo;)
          provides users with the information of how KOBE Elevators Co., Ltd. and KANE Corporation (hereinafter referred
          to as &rdquo;KONE&rdquo; or &rdquo;we&rdquo;) process (the processing of personal information in the Rule
          includes the collection, storage, use, processing, transmission, provision, disclosure, and deletion of
          personal information) user’s personal information. Please read and understand this Rule carefully before using
          our website, and we may revise the Rule from time to time.
        </P>
        <P>本政策将帮助用户了解以下内容:</P>
        <P className="translation">This Statement will help users understand the following:</P>
        <OL>
          <li>
            用户个人信息处理的基本原则
            <br />
            Main principles of the processing of personal information
          </li>
          <li>
            用户个人信息的收集及使用
            <br />
            Collection and use of personal information of users
          </li>
          <li>
            用户个人信息的处理目的
            <br />
            Purposes of processing of users’ personal information
          </li>
          <li>
            用户个人信息存储的期限
            <br />
            Retention period of users’ personal information
          </li>
          <li>
            我们如何向他人提供、转让、公开所收集的用户个人信息
            <br />
            How we provide any user’s personal information to another person and how we transfer and publicly disclose
            personal information
          </li>
          <li>
            我们如何处理和委托处理用户个人信息
            <br /> How we process and contract processing of users’ personal information to another party{' '}
          </li>
          <li>
            我们如何保护用户的个人信息
            <br />
            How we protect users&&apos; personal information
          </li>
          <li>
            用户的个人信息权利
            <br />
            User&&apos;s right to personal information
          </li>
          <li>
            跨境提供
            <br />
            Cross-border Provision
          </li>
          <li>
            本规则如何更新
            <br />
            How this Rule is updated
          </li>
          <li>
            如何联系我们
            <br />
            How to contact us
          </li>
        </OL>
        <H4>1.用户个人信息处理的基本原则MAIN PRINCIPLES OF THE PROCESSING OF PERSONAL INFORMATION</H4>
        <P> 1.1.我们按照中华人民共和国法律规定，遵循合法、正当、必要和诚信原则处理用户个人信息；</P>
        <P className="translation">
          We shall process personal information in accordance with the principles of legality, legitimacy, necessity and
          good faith, and not in any manner that is misleading, fraudulent or coercive.
        </P>
        <P> 1.1.1. 我们在实现目的的最小范围内收集用户个人信息，并采取对个人权益影响最小的方式处理用户个人信息；</P>
        <P className="translation">
          We shall limit the collection of personal information to the minimum scope necessary for achieving the purpose
          of processing and shall not be excessive.
        </P>
        <P> 1.2. 我们遵循公开、透明原则，公示用户个人信息处理规则，明示处理的目的、方式和范围； </P>
        <P className="translation">
          We shall process personal information in accordance with the principles of openness and transparency, with the
          rules of processing of personal information disclosed, and the purpose, method and scope of processing
          expressly stated.
        </P>
        <P> 1.3. 我们将尽可能保证个人信息的质量，避免因个人信息不准确、不完整对个人权益造成不利影响；</P>
        <P className="translation">
          We shall ensure the quality of personal information when the personal information is processed in order to
          avoid any negative impact on personal rights and interests due to any inaccuracy or incompleteness of the
          personal information processed.
        </P>
        <P>1.4. 我们将采取取必要措施保障所处理的个人信息的安全。</P>
        <P className="translation">
          We shall take necessary measures to ensure the security of the personal information processed.
        </P>
        <H4> 2. 用户个人信息的收集及处理COLLECTION AND PROCESSING OF USERS’ PERSONAL INFORMATION </H4>
        <P>2.1. 个人信息及个人信息处理的定义</P>
        <P className="translation">
          The definition of the personal information and the processing of personal information
        </P>
        <P>
          个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。例如，姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。{' '}
        </P>
        <P>
          Personal information refers to any kind of information related to an identified or identifiable natural person
          as electronically or otherwise recorded, excluding information that has been anonymized, such as personal
          name, birthday, ID card number, personal biometric information, address, communication information,
          communication records and content, account number and code, personal property information, credit information,
          whereabouts, accommodation information, personal health physiological information, transaction records and so
          on.
        </P>
        <P> 个人信息的处理包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。</P>
        <P className="translation">
          Processing of personal information includes the collection, storage, use, processing, transmission, provision,
          disclosure, and deletion of personal information.
        </P>
        <P>2.2. 我们如何收集和处理用户的个人信息 </P>
        <P className="translation">How we collect and process Users&&apos; personal information </P>
        <P>
          （1）
          为实现向用户提供我们产品及/或服务的基本功能，用户须授权我们收集、处理的必要的个人信息。如用户拒绝提供相应信息，用户将无法正常使用我们的产品及/或服务；
        </P>
        <P className="translation">
          In order to provide users with the basic functions of our products and / or services, users shall authorize us
          to collect and process the necessary personal information. If the user refuses to provide the corresponding
          information, the user will not be able to use our products and / or services normally.
        </P>
        <P>
          （2）
          为实现向用户提供产品及/或服务的附加功能，用户可选择授权我们收集、处理个人信息。如用户拒绝提供，用户将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响用户正常使用产品及/或服务的基本功能。
        </P>
        <P className="translation">User understands and agrees that:</P>
        <P>
          为给用户带来更好的产品和服务体验, 我们在持续努力改进技术, 随之我们可能会不时推出新的或优化后的功能,
          可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此,
          我们将通过更新本政策、弹窗、页面提示等方式另行向用户说明对应信息的收集目的、范围及使用方式,
          并为用户提供自主选择同意的方式, 且在征得用户同意后收集、使用。
        </P>
        <P className="translation">
          In order to provide users with additional functions of products and / or services, users can choose to
          authorize us to collect and process the personal information. If the user refuses to provide, the user will
          not be able to use the relevant additional functions or achieve the function effect we intend to achieve, but
          it will not affect the normal use of the basic functions of the product and / or service
        </P>
        <P>2.3. 收集的用户信息类型</P>
        <P className="translation"> Types of user information collected</P>
        <P>我们会收集、处理以下几类个人信息：</P>
        <P className="translation"> We will collect and process the following types of personal information: </P>
        <P>（1） 个人基本资料：个人姓名、电子邮件地址。</P>
        <P className="translation">Personal basic information: Personal name, e-mail.</P>
        <P>（2） 网络身份标识信息：账户密码。</P>
        <P className="translation">Network identity information: Account password.</P>
        <P>（3） 个人工作信息：工作单位。</P>
        <P className="translation">Personal work information: organization.</P>
        <P>
          敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、
          行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。以上加粗字体标识的是用户敏感个人信息(如有)，在特定场景下需要用户提供，若不提供的则可能无法实现对应的目的。我们处理用户敏感个人信息的，
          将会向用户告知敏感个人信息的必要性以及对个人权益的影响，并提前取得用户的单独同意。
        </P>
        <P className="translation">
          Sensitive personal information refers to personal information that, once leaked or illegally used, will easily
          lead to infringement of the human dignity or harm to the personal or property safety of a natural person,
          including biometric recognition, religious belief, specific identity, medical and health, financial account,
          personal whereabouts and other information of a natural person, as well as any personal information of a minor
          under the age of 14. The bold font above (if there be) is sensitive personal information. We need users to
          provide them in some specific occasion, or the corresponding purpose cannot be achieved. When we process
          users’ sensitive personal information, we shall inform users of the necessity of it and the impact on an
          individual&apos;s rights and interests and obtain the specific consent in advance.{' '}
        </P>
        <P>2.4. 用户理解并同意：</P>
        <P className="translation">User understands and agrees that:</P>
        <P>
          为给用户带来更好的产品和服务体验，我们在持续努力改进技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、处理新的个人信息或变更个人信息使用目的或方式。对此，
          我们将通过更新本规则、弹窗、页面提示等方式另行向用户说明对应信息的收集目的、范围及使用方式，并为用户提供自主选择同意的方式，
          且在征得用户同意后收集、处理。但是，根据《个人信息保护法》的规定，以下情形无需取得用户同意：
        </P>
        <P className="translation">
          In order to bring better product and service experience to users, we are constantly striving to improve
          technology. As a result, we may introduce new or optimized functions from time to time. We may need to collect
          and use new personal information or change the purpose or way of using personal information. In this regard,
          we will update this Statement, pop-up windows, page prompts and other ways to explain to users the purpose,
          scope and use of the corresponding information, and provide users with the way to choose their own consent and
          collect and process the information after obtaining the user&apos;s consent. However, personal consent is not
          required under any of the following circumstances according to the Personal Information Protection Law of the
          People&apos;s Republic of China:
        </P>
        <P>
          （1）
          为订立、履行用户作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
        </P>
        <P className="translation">
          Where it is necessary for the conclusion or performance of a contract to which the individual is a contracting
          party, or where it is necessary for carrying out human resources management under an employment policy legally
          established or a collective contract legally concluded.
        </P>
        <P>（2） 为履行通力的法定职责或者法定义务所必需；</P>
        <P className="translation">
          Where it is necessary for performing a statutory responsibility or statutory obligation.
        </P>
        <P>（3） 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</P>
        <P className="translation">
          Where it is necessary for responding to a public health emergency, or for protecting the life, health or
          property safety of a natural person in the case of an emergency.
        </P>
        <P>（4） 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理用户个人信息；</P>
        <P className="translation">
          Where the personal information is processed within a reasonable scope to carry out any news reporting,
          supervision by public opinions or any other activity for public interest purposes.
        </P>
        <P>（5） 依照中华人民共和国法律规定在合理的范围内处理用户自行公开或者其他已经合法公开的用户个人信息；</P>
        <P className="translation">
          Where the personal information, which has already been disclosed by the individual or otherwise legally
          disclosed, is processed within a reasonable scope and in accordance with the laws and regulations.
        </P>
        <P>（6） 法律、行政法规规定的其他情形。</P>
        <P className="translation">Any other circumstance as provided by law or administrative regulations.</P>
        <P>
          2.5.
          我们或我们的第三方合作伙伴，可能通过COOKIES收集和处理用户的信息，并将该等信息储存为日志信息。我们使用自己的COOKIES，目的是为用户提供更个性化的用户体验和服务，并用于以下用途：
        </P>
        <P className="translation">
          We, or our third party partners, may collect and process information about our users through cookies and store
          this information as log messages. We use our own cookies in order to provide a more personalized user
          experience and service to our users and for the following purposes:
        </P>
        <P>
          （1）
          用户的用户名、电子邮件地址和联系偏好设定。这使我们能够为用户创建帐户，为用户提供方便和个性化的帐户访问体验，并提供和支持服务。这些信息也有助于我们就用户对服务的使用、产品公告、软件更新等与用户进行沟通，并响应网站用户的协助请求（包括在用户访问帐户遇到困难时提供帐户验证支持）。
        </P>
        <P className="translation">
          User&apos;s username, email address and contact preferences set. This allows us to create accounts for users,
          provide them with a convenient and personalized account access experience, and provide and support the
          services. This information also helps us communicate with users about their use of the services, product
          announcements, software updates, etc., and respond to requests for assistance from the users of websites
          (including providing account verification support if users experience difficulties accessing their accounts).
        </P>
        <P>
          （2）
          设备或软件信息，例如用户的移动设备、网页浏览器或用于接入服务的其他程序所提供的配置信息、用户的移动设备所用的版本和设备硬件信息。我们收集这些信息是为了更好的提供服务，包括对用户使用本软件服务的体验进行本地化处理。此外，还有助于我们更加了解用户是如何使用服务的。
        </P>
        <P className="translation">
          Device or software information, such as configuration information provided by the user&apos;s mobile device,
          web browser or other program used to access the service, the version of the user&apos;s mobile device used,
          and device hardware information. We collect this information to better provide the service, including to
          localize the user&apos;s experience with the software service. In addition, it helps us to better understand
          how users use the service.
        </P>
        <P>
          （3）
          用户使用的本产品和服务的信息。这些信息将帮助我们提供和支持服务，并响应网站用户的协助请求。我们收集这些信息也是为了更好地了解用户如何使用服务。
        </P>
        <P className="translation">
          Information about users&apos; use of the product and services. This information helps us to provide and
          support the services and to respond to requests for assistance from the users of websites. We also collect
          this information to better understand how users use the services.
        </P>
        <P>（4） 用户在帐户上添加的任何内容。我们仅为确保用户在使用服务时可以获取这些信息而使用这些信息。</P>
        <P className="translation">
          Any content that a user adds to their account. We use this information only to ensure that it is accessible to
          the user when using the services.
        </P>
        <P>
          （5）
          用户可根据自己的偏好管理或删除Cookie。用户可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如用户自行操作，则需要在每一次访问我们的客户端时亲自更改用户设置。
        </P>
        <P className="translation">
          Users can manage or delete cookies according to their own preferences, and they can clear all cookies stored
          on their computer, and most web browsers have features to block cookies. However, if the user does this
          himself, he will need to change the user settings himself each time he visits our client.
        </P>
        <H4>3. 用户个人信息的处理目的PURPOSES OF PROCESSING OF USERS’ PERSONAL INFORMATION</H4>
        <P className="translation">我们可能将在向用户提供服务的过程之中所收集的信息用作下列目的和用途：</P>
        <P>
          We may use the information collected in the course of providing our services to users for the following
          purposes and uses:
        </P>
        <P className="translation">（1） 向用户提供更精准的服务，使用户能够获取KONE API的信息并实际使用KONE API；</P>
        <P>
          Providing more precise services to users and enabling users to get access of KONE API information and actually
          use KONE API;
        </P>
        <P className="translation">
          （2）
          在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向用户提供的产品和服务的安全性；
        </P>
        <P>
          For authentication, customer service, security, fraud monitoring, archival and backup purposes in the
          provision of our services and to ensure the security of the products and services we provide to our users;
        </P>
        <P className="translation">（3） 帮助我们设计新服务，改善我们现有服务；</P>
        <P>To help us design new services and improve our existing services;</P>
        <P className="translation">
          （4）
          使我们更加了解用户如何接入和使用我们的服务，从而针对性地回应网站用户的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对用户和其他用户作出其他方面的回应；{' '}
        </P>
        <P>
          To improve our understanding of how users access and use our services so that we can tailor our
          websites/platforms to the individual needs of users, such as language settings, location settings,
          personalized help services and instructions, or otherwise respond to users and other users
        </P>
        <P className="translation">（5） 软件认证或管理软件升级；</P>
        <P>Software certification or management software upgrades;</P>
        <P className="translation">（6） 让用户参与有关我们的产品和服务的调查。</P>
        <P>Engaging users in surveys about our products and services.</P>
        <H4>4. 用户个人信息存储的期限RETENTION PERIOD OF USERS’ PERSONAL INFORMATION</H4>
        <P>
          我们只会在达成本规则所述目的所必要的最短期限内保留用户的个人信息，除非需要延长保留期或受到法律的允许。因技术原因无法及时删除个人信息的，我们会停止除存储和采取必要的安全保护措施之外的处理。
        </P>
        <P className="translation">
          We shall retain personal information for the minimum period necessary for achieving the purpose of processing.
          If it is technically difficult to delete the personal information, we shall cease the processing of the
          personal information, except for the storage and any necessary measure taken for security protection.
        </P>
        <H4>
          5. 我们如何向他人提供、转让、公开所收集的用户个人信息HOW WE PROVIDE ANY USER’S PERSONAL INFORMATION TO ANOTHER
          PERSON AND HOW WE TRANSFER AND PUBLICLY DISCLOSE PERSONAL INFORMATION
        </H4>
        <P className="translation">
          5.1.
          因我们是一家跨国企业，采用各类全球管理系统、工具，这意味着，用户的个人信息（个人信息种类详见本规则第2条）可能会提供给我们的全球总部，即科恩有限公司（KONE
          Corporation, Address：KONE Building, Keilasatama 3, 02150 Espoo, Finland, Email：api-support@kone.com）。
        </P>
        <P>
          Because we are multinational enterprises, we use various global management systems and tools, which means
          users’ personal information (see article 2 of the Rule for details) might be provided to the global
          headquarters, KONE Corporation (KONE CorporationAddress：KONE Building, Keilasatama 3, 02150 Espoo, Finland,
          Email：api-support@kone.com).
        </P>
        <P className="translation">
          5.2. 我们向他人提供、转让用户个人信息的，将提前取得用户的单独同意。针对对外提供、转让的所有用户个人信息，
          我们尽最大努力确认接收人具备接收个人信息的法律依据并且将所提供的个人信息控制在最低范围以及使用安全方法进行传输。我们会采取与接收人签署保密协议或其他中国法律法规所要求的方式，
          要求他们按照我们的说明、本规则以及其他任何相关的保密和安全措施来处理个人信息，禁止其将这些信息用于未经用户授权的用途，否则我们将要求接收人重新向用户征求授权同意。
        </P>
        <P>
          When we provide any user’s personal information to another person and transfer personal information, we shall
          obtain the specific consent in advance. To any involved personal information, we shall make efforts to ensure
          the recipient has the legal ground to receive personal information, limit the involved personal information to
          the minimum scope and transmit the personal information in safe way. We shall sign NDA with the recipient or
          use other approaches stipulated by laws and regulations, in order to require the recipient to process the
          personal information by our instructions, the Rule and other relevant measures which are taken to process the
          personal information confidentially and safely. We shall prohibit the recipient from using the information for
          unauthorized purpose, or we shall require the recipient to obtain consent for users.
        </P>
        <H4>
          6. 我们如何处理和委托处理用户的个人信息HOW WE PROCESS AND CONTRACT PROCESSING OF USERS’ PERSONAL INFORMATION
          TO ANOTHER PARTY
        </H4>
        <P>
          6.1.
          我们服务的某些具体的模块或功能可能会由外部供应商、关联公司或其他专业机构提供。就本服务而言，您的个人信息将被存储在新加坡。
        </P>
        <P className="translation">
          Some modes or functions of our service may be provided by external suppliers, affiliated companies or other
          professional institutions. For this service, your personal information will be stored in Singapore.{' '}
        </P>
        <P>
          6.2.
          对接受我们委托处理的公司、组织和个人，如其可能会接触到用户的个人信息，我们会采取与其签署委托处理协议和保密协议或其他中国法律法规所要求的方式，要求他们按照我们的说明、
          本规则以及其他任何相关的保密和安全措施来处理个人信息，禁止其将这些信息用于未经用户授权的用途，并对受托人的个人信息处理活动进行监督；
        </P>
        <P className="translation">
          For the company, organization and individual we contract to, when they may contact the user’s information, we
          shall sign NDA with the recipient or use other approaches stipulated by laws and regulations, in order to
          require the recipient to process the personal information by our instructions, the Rule and other relevant
          measures which are taken to process the personal information confidentially and safely. We shall prohibit the
          recipient from using the information for unauthorized purpose, and we shall supervise the activities of
          processing of personal information carried out by the contracted party.
        </P>
        <P>6.3. 在委托关系解除时，我们会停止提供用户的个人信息并要求受托方返还或者删除个人信息；</P>
        <P className="translation">
          When the contract of the contracted processing finishes, we shall cease the transmission and require the
          contracted party to return the personal information or delete it.
        </P>
        <P>6.4. 未经我们同意，受托方不得转委托他人处理用户的个人信息。</P>
        <P className="translation">
          Without the approval of us, the contracted party shall not subcontract the contracted processing of personal
          information to any other person.
        </P>
        <H4>7. 我们如何保护用户的个人信息HOW WE PROTECT USERS&apos; PERSONAL INFORMATION</H4>
        <P className="translation">
          7.1.
          我们已使用符合业界标准的安全防护措施保护用户提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护用户的个人信息。
        </P>
        <P>
          We have put in place industry standard security measures to protect the personal information provided by our
          users from unauthorized access, public disclosure, use, modification, corruption or loss. We will take all
          reasonable and practicable steps to protect your personal information.
        </P>
        <P className="translation">
          7.2.
          请用户理解：互联网环境并非100%安全，我们将尽力确保用户发送给我们的信息的安全性，但是由于技术的限制及可能存在的各种恶意手段，即便竭尽所能加强安全措施，也不可能始终保证100%安全。
          在不幸发生个人信息安全事件后，我们将按照法律法规的要求处理。
        </P>
        <P>
          Please understand that the internet environment is not 100% secure, and we make every effort to ensure the
          security of information sent to us by users, but due to technical limitations and possible malicious means, it
          is not always possible to guarantee 100% security, even with the best efforts to enhance security measures. In
          the unfortunate event that a personal information security incident occurs, we will take action in accordance
          with the requirements of laws and regulations.
        </P>
        <H4>8. 用户的个人信息权利USER&apos;S RIGHT TO PERSONAL INFORMATION</H4>
        <P>8.1. 查阅、复制用户的个人信息ACCESS OR MAKE COPIES OF THEIR PERSONAL INFORMATION</P>
        <P className="translation">
          用户可以要求查阅、复制用户的个人信息。用户也可以要求将个人信息转移至其指定的个人信息处理者，符合国家网信部门规定条件的，我们会提供转移的途径。
        </P>
        <P>
          Users could request to access or make copies of their personal information. Users could also request to
          transfer their personal information to a personal information processor designed by them who meets the
          conditions prescribed by the national cybersecurity authority.
        </P>
        <P className="translation">
          8.2. 更正、补充用户的个人信息CORRECTION AND COMPLETION OF THE USER&apos;S PERSONAL INFORMATION
        </P>
        <P>当用户发现我们处理的关于用户的个人信息不准确或者不完整时，用户有权要求我们做出更正、补充。</P>
        <P className="translation">
          When the User discovers an error or impletion in the personal information we have processed about the user,
          the user has the right to request that we make corrections and completion
        </P>
        <P>8.3. 删除用户的个人信息DELETING USERS&apos; PERSONAL INFORMATION</P>
        <P className="translation">在以下情形中，用户可以向我们提出删除个人信息的请求：</P>
        <P>Under any of the following circumstances, the user has the right to request the deletion:</P>
        <P className="translation">（1） 处理的目的已经实现、无法实现或者为实现处理目的不再必要；</P>
        <P>
          Where the purpose of processing has been achieved or is unable to be achieved, or the personal information is
          no longer necessary for achieving the purpose of processing.
        </P>
        <P className="translation">（2） 我们停止提供产品或者服务，或者保存期限已届满；</P>
        <P>Where we cease the provision of the product or service involved, or the retention period has expired</P>
        <P className="translation">（3） 用户撤回同意；</P>
        <P>Where consent is withdrawn by the user.</P>
        <P className="translation">（4） 我们违反法律、行政法规或者违反约定处理个人信息；</P>
        <P>
          Where the processing of personal information by us is in violation of any law, administrative regulations or
          agreement.
        </P>
        <P className="translation">（5） 法律、行政法规规定的其他情形。</P>
        <P>Any other circumstance as provided by law or administrative regulations.</P>
        <P className="translation">
          法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，我们将停止除存储和采取必要的安全保护措施之外的处理。
        </P>
        <P>
          If the retention period prescribed by laws or administrative regulations has not expired, or it is technically
          difficult to delete the personal information, the personal information processor shall cease the processing of
          the personal information, except for the storage and any necessary measure taken for security protection.
        </P>
        <P className="translation">8.4. 响应用户的上述请求RESPONDING TO THE ABOVE REQUESTS FROM USERS</P>
        <P>
          （1）
          如果用户想行使前述权利或者需要我们对本规则的具体条款进行解释说明的，可以通过发送邮件至api-support@kone.com的方式执行此类操作；
        </P>
        <P className="translation">
          When users would like to realize the aforementioned rights or need us to clarify the specific article of the
          Rule, please send e-mail to api-support@kone.com.
        </P>
        <P>
          （2）
          为保障安全，用户可能需要提供书面请求，或以其他方式证明用户的身份。我们可能会先要求用户验证自己的身份，然后再处理用户的请求。
        </P>
        <P className="translation">
          For security purposes, a user may be required to provide a written request or otherwise prove the user&apos;s
          identity. We may ask the user to verify his or her identity before we process the request.
        </P>
        <P>
          （3） 我们将在三十天内做出答复，如用户不满意，用户也有权向监管机构投诉，或者向有管辖权的司法机关提起诉讼。
        </P>
        <P className="translation">
          We will respond within thirty (30) days. If users are not satisfied with the response, users could file a
          complaint to an authority performing personal information protection duties or bring a lawsuit in a
          people&apos;s court。
        </P>
        <P>（4） 在以下情形中，按照法律法规要求，我们将无法响应用户的请求，但会向用户说明情况：</P>
        <P className="translation">
          We will not be able to respond to a user&apos;s request, as required by law or regulation, in the following
          circumstances, but we shall inform users:
        </P>
        <P>a) 与国家安全、国防安全直接相关的；</P>
        <P className="translation">Directly related to national security, national defence and security.</P>
        <P>b) 与公共安全、公共卫生、重大公共利益直接相关的；</P>
        <P className="translation">Directly related to public safety, public health or vital public interests.</P>
        <P>c) 与犯罪侦查、起诉、审判和判决执行等直接相关的；</P>
        <P className="translation">
          Directly related to the investigation, prosecution, trial and execution of the sentence.
        </P>
        <P>d) 有充分证据表明用户存在主观恶意或滥用权利的；</P>
        <P className="translation">Where there is sufficient evidence of subjective malice or abuse of rights.</P>
        <P>e) 响应用户的请求将导致用户或其他个人、组织的合法权益受到严重损害的。</P>
        <P className="translation">
          Where responding to the user&apos;s request will result in serious damage to the legal rights and interests of
          the user or other individuals or organizations.
        </P>
        <P>f) 涉及商业秘密的。</P>
        <P className="translation">Involvement of trade secrets.</P>
        <H4>9. 跨境提供CROSS-BORDER PROVISION</H4>
        <P className="translation">
          9.1.
          由于我们是一家跨国企业，采用各类全球管理系统、工具，由于技术和实践要求，我们的合作伙伴也会在境外处理个人信息，这意味着，用户的个人信息可能会被转移到境外管辖区，
          或者受到来自这些管辖区的访问，例如为实现本规则第3条所列之目的，通力电梯有限公司经过个人信息保护影响评估后，会将您的个人信息（个人信息种类详见本规则第2条）跨境提供给科恩有限公司（KONE
          Corporation, Address：KONE Building, Keilasatama 3, 02150 Espoo, Finland, Email：api-support@kone.com）。
        </P>
        <P>
          Because we are multinational enterprises, we use various global management systems and tools. For the sake of
          technique and practice, our partners process personal information overseas as well, which means users’
          personal information might be transferred outside the territory of the People&apos;s Republic of China or be
          accessed by the overseas party. For example, to achieve the purposes under the article 3, KONE Elevators Co.,
          Ltd. will provide the users’ personal information (see article 2 of the Rule for details) to KONE Corporation
          (KONE Corporation, Address：KONE Building, Keilasatama 3, 02150 Espoo, Finland, Email：api-support@kone.com)
          which is in Finland.
        </P>
        <P className="translation">
          9.2.
          我们会为运营管理、开展业务、实现产品功能、了解设备运行、帮助产品及服务改进的目的对用户的个人信息进行跨境提供。在此情况下，
          我们会通过协议等形式要求境外接收者对您的个人信息采取符合个人信息保护标准的保护措施。如需进行跨境提供，我们将提前取得用户的单独同意。
        </P>
        <P>
          We might transfer the users’ personal information overseas for operation management, business, function of
          product, knowledge of the running of device and improvement of product and service. Under the circumstance, we
          shall require the overseas recipient to take protection action complying to personal information protection
          standards by agreement. We shall obtain users specific consent before cross-border provision.
        </P>
        <H4>10. 本规则如何更新HOW THIS RULE IS UPDATED</H4>
        <P>
          随着业务的发展，我们可能需要更新本规则以适应服务、业务和适用于法律的变化。未经用户明确同意，我们不会削减用户按照本规则所应享有的权利。我们会以通告的形式发布对本政策所做的任何变更。
        </P>
        <P className="translation">
          As our business evolves, we may need to update this Rule to accommodate changes in our services, business and
          applicable laws. We will not reduce the rights of users under this Rule without their express consent. We will
          post any changes we make to this Rule by way of notice. In such cases, by continuing to use the services, the
          user agrees to be bound by the revised Rule.
        </P>
        <H4>11. 如何联系我们HOW TO CONTACT US</H4>
        <P>如果用户对本规则有任何疑问、意见或建议，可以随时通过拨打电话、发送邮件等方式与我们联系。</P>
        <P className="translation">
          If users have any questions, comments or suggestions on this Rule, they can contact us at any time by calling,
          sending e-mail and etc.
        </P>
        <P className="info">公司Company：通力电梯有限公司 KONE Elevators Co., Ltd.</P>
        <P className="info">
          地址Address：上海市延安西路2299号上海世贸商城8A88 Shanghai World Trade mall 8a88, 2299 Yan&apos;an west road,
          Shanghai
        </P>
        <P className="info">联系方式Contact information：86-21-2201 2222 </P>
        <P className="info">邮箱Email：api-support@kone.com </P>
        <br />
        <P>（以下无正文 No text below）</P>
        <P textAlign="right">
          通力电梯有限公司
          <br />
          KONE Elevators Co., Ltd.
          <br />
          2021年11月
          <br />
          November 2021
        </P>
      </Container>
    </>
  )
}

export default PrivacyStatementContentChina
