import React, { FC } from 'react'
import { CommonlyUsedAppsHeading, CommonlyUsedAppsText } from './Styles'
import { MFAStrings } from '../../strings/MFAContent'

const MfaCommonApps: FC = () => {
  return (
    <>
      <CommonlyUsedAppsHeading>{MFAStrings.commonlyUsedAppHeading}</CommonlyUsedAppsHeading>
      <CommonlyUsedAppsText>
        <a href="https://1Password.com" target="_blank" rel="noreferrer noopener nofollow">
          1Password
        </a>
        {', '}
        <a href="https://authy.com" target="_blank" rel="noreferrer noopener nofollow">
          Authy
        </a>
        {', '}
        <a href="https://www.lastpass.com" target="_blank" rel="noreferrer noopener nofollow">
          LastPass Authenticator
        </a>
        {', '}
        <a
          href="https://www.microsoft.com/en-us/account/authenticator"
          target="_blank"
          rel="noreferrer noopener nofollow"
        >
          Microsoft Authenticator
        </a>
        {', '}
        <a
          href="https://support.google.com/accounts/answer/1066447/get-verification-codes-with-google-authenticator-iphone-amp-ipad"
          target="_blank"
          rel="noreferrer noopener nofollow"
        >
          Google Authenticator
        </a>
      </CommonlyUsedAppsText>
    </>
  )
}

export default MfaCommonApps
