import React, { FC, useState } from 'react'
import {
  AppName,
  CreateButton,
  TabsContainer,
  ContainerTab,
  CreateButtonList,
  TitleContainer,
  RefreshTableButton,
  RefreshIcon,
} from './Styles'
import { CustomerApp } from '../../../shared/models/application'
import { listWebhookSubscriptions, queryNamesWebhook } from '../../../shared/api/webhookAPI'
import { useQuery, useQueryClient } from 'react-query'
import { OrgRowContent, OrgRowContentLarge, HeaderRow } from '../../Organizations/Styles'
import WebhookTable from './WebhookTable'
import LoadingComponent from '../../../shared/components/LoadingAnimated'
import { Toast, SidePanel } from '../../../shared/components'
import EditWebhookForm from './EditWebhooks'

import { WebhookSubscription } from '../../../shared/models/webhookSubscription'
import { Resources } from '../../../shared/models/resource'
import { AxiosError } from 'axios'

type Props = {
  application: CustomerApp
  isCreatePanelOpen: boolean
  closeEditSidePanel: () => void
  openNewWebhookPanel: () => void
  successfulCreation: boolean
  closeBanner: () => void
  isSecureUrl: boolean
  kenResources: Resources
}

const ManageWebhooks: FC<Props> = ({
  application,
  openNewWebhookPanel,
  successfulCreation,
  closeBanner,
  isSecureUrl,
  kenResources,
}) => {
  const {
    isLoading,
    error: errorInLoadingSubscriptions,
    data,
    refetch,
  } = useQuery(queryNamesWebhook.LIST, () => listWebhookSubscriptions(application.clientId!))

  const [isTriggerBannerSuccessful, setIsTriggerBannerSuccessful] = useState(false)
  const [isEditSuccess, setIsEditSuccess] = useState(false)
  const [isTriggerBannerError, setIsTriggerBannerError] = useState(false)
  const [currentEditApplication, setCurrentEditApplication] = useState<CustomerApp | null>(null)
  const [currentSubscription, setCurrentSubscription] = useState<WebhookSubscription | null>(null)
  const [isCreateSubscriptionSuccessful, setIsCreateSubscriptionSuccessful] = useState(false)

  const queryClient = useQueryClient()

  const setTriggerResponseBanner = () => {
    setIsTriggerBannerSuccessful(true)
    setIsEditSuccess(false)
  }

  const setTriggerResponseBannerError = () => {
    setIsTriggerBannerError(true)
    setIsEditSuccess(false)
  }

  const editApplication = (subscription: WebhookSubscription) => {
    setCurrentEditApplication(application)
    setCurrentSubscription(subscription)
  }

  const handleClick = () => {
    setIsEditSuccess(false)
    if (isTriggerBannerSuccessful) {
      setIsTriggerBannerSuccessful(false)
    }
    openNewWebhookPanel()
  }

  const refresh = () => {
    refetch()
  }

  const closeEditWebhookPanel = () => {
    setCurrentEditApplication(null)
    if (isTriggerBannerSuccessful) {
      setIsTriggerBannerSuccessful(false)
    }
    queryClient.removeQueries('subscription-list')
  }

  const editSuccessBanner = () => {
    setIsEditSuccess(true)
    if (isTriggerBannerSuccessful) {
      setIsTriggerBannerSuccessful(false)
    }
  }

  const showSuccessfulCreation = () => {
    setIsCreateSubscriptionSuccessful(true)
  }

  if (isLoading) {
    return (
      <TabsContainer>
        <LoadingComponent />
      </TabsContainer>
    )
  }
  if (errorInLoadingSubscriptions) {
    if ((errorInLoadingSubscriptions as AxiosError)?.response?.status === 403) {
      return (
        <h1 style={{ color: 'white' }}>
          This organization does not have access to webhook. Please contact api-support@kone.com.
        </h1>
      )
    }
    return <h1>error</h1>
  }

  const getUnsubscribedResources = () => {
    return kenResources.filter(
      (r: any) =>
        currentSubscription?.subscribedResourceIds &&
        !currentSubscription.subscribedResourceIds.includes('ken:' + r.resourceId.uniqueId)
    )
  }

  const infoTable = data.map((sub: any) => {
    return (
      <WebhookTable
        onClickEdit={editApplication}
        key={sub.id}
        subscription={sub}
        application={application}
        setTriggerResponseBanner={setTriggerResponseBanner}
        setTriggerResponseBannerError={setTriggerResponseBannerError}
        closeBanner={closeBanner}
        closeEditSuccessfulBanner={() => setIsEditSuccess(false)}
      />
    )
  })

  if (data.length > 0) {
    return (
      <>
        <Toast
          key={isSecureUrl ? 'subscription-creation-success' : 'subscription-creation-success-with-http-endpoint'}
          isVisible={successfulCreation}
          text={isSecureUrl ? 'Subscription created successfully' : 'Subscription created successfully with HTTP URL'}
          onDismiss={closeBanner}
          type={isSecureUrl ? 'success' : 'warning'}
        />
        <Toast
          key="subscription-edited-success"
          isVisible={isEditSuccess}
          text="Subscription updated successfully"
          onDismiss={() => setIsEditSuccess(false)}
          type="success"
        />
        <Toast
          key="event-trigger-success"
          isVisible={isTriggerBannerSuccessful}
          text="Event triggered successfully"
          onDismiss={() => setIsTriggerBannerSuccessful(false)}
          type="success"
        />
        <Toast
          key="event-trigger-error"
          isVisible={isTriggerBannerError}
          text="Event triggered successfully"
          onDismiss={() => setIsTriggerBannerError(false)}
          type="error"
        />
        <ContainerTab>
          <TitleContainer>
            <AppName>Webhooks</AppName>
            <CreateButtonList id="open-create-app-button-js" onClick={handleClick} type="primary" size="small">
              Create new webhook
            </CreateButtonList>
            <RefreshTableButton id="refresh-table-button-js" onClick={refresh} type="primary" size="small">
              <RefreshIcon id="refresh-button-js" />
            </RefreshTableButton>
          </TitleContainer>
          <HeaderRow>
            <OrgRowContent>Name</OrgRowContent>
            <OrgRowContent>Server URL</OrgRowContent>
            <OrgRowContentLarge>Status</OrgRowContentLarge>
            <OrgRowContent></OrgRowContent>
          </HeaderRow>
          {infoTable}
          <SidePanel
            key="edit-webhook-side-panel"
            isOpen={!!currentEditApplication}
            title="Edit Webhook"
            showBackButton={true}
            onClose={() => {
              closeEditWebhookPanel()
            }}
          >
            {currentEditApplication && currentSubscription && (
              <EditWebhookForm
                application={currentEditApplication!}
                showSuccessfulEdit={showSuccessfulCreation}
                closeEditWebhookPanel={closeEditWebhookPanel}
                subscription={currentSubscription}
                editSuccess={editSuccessBanner}
                kenResources={getUnsubscribedResources()}
              />
            )}
          </SidePanel>
        </ContainerTab>
      </>
    )
  }

  return (
    <TabsContainer>
      <>
        <AppName>Application has no webhooks!</AppName>
        <CreateButton id="open-create-app-button-js" onClick={handleClick} type="primary" size="large">
          Create new webhook
        </CreateButton>
      </>
    </TabsContainer>
  )
}

export default ManageWebhooks
