import styled from 'styled-components'
import { color } from '../../utils/styles'

export const CommonlyUsedAppsHeading = styled.div`
  margin-top: 35px;
  font-size: 14px;
`

export const CommonlyUsedAppsText = styled.div`
  color: ${color.lightBlue};
  font-size: 14px;
  margin-bottom: 26px;
`
