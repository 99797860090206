import React, { FC } from 'react'
import { EquipmentStatusApiApiV2 } from '../../../shared/strings/KONEAPIsTextContent'
import Paths from '../../../Dashboard/Paths'
import KONEAPIPage from '../KONEAPIPage'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

const apis_v2 = [
  Paths.AuthenticationAPIV2,
  Paths.EquipmentStatus2RestApiPageDocs,
  Paths.WebhookManagementAPIDocsForKoneAPI,
]

const EquipmentStatusAPIPage: FC = () => {
  return (
    <>
      <KONEAPIPage title={Paths.EquipmentStatusAPI2Product.name} apis={apis_v2}>
        <div dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApiApiV2.body) }} />
        <div dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApiApiV2.features) }} />
      </KONEAPIPage>
    </>
  )
}

export default EquipmentStatusAPIPage
