import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'
import Button from '../../../shared/components/Button'

export const ContentWrapper = styled.div`
  margin: auto;
  width: 362px;
`

export const TextContainer = styled.div`
  text-align: center;
`

export const ErrorTextHeader = styled.p`
  text-align: center;
  font: normal normal normal 28px/42px KONE Information;
  color: ${color.gray20};
`

export const ErrorText = styled.p`
  text-align: center;
  font: normal normal normal 18px/26px KONE Information;
  color: ${color.gray20};
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 36px;
  justify-content: center;
`

export const CreateButton = styled(Button)`
  width: 208px;
`

export const CenterLoader = styled.div`
  display: flex;
  justify-content: center;
`
