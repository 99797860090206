import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'
import { Space, Input } from 'antd'
import { ReactComponent as CopyIcon } from '../../../assets/icons-copy.svg'
import { ReactComponent as Lock } from '../../../assets/icons-locked.svg'

export const LockIcon = styled(Lock)`
  cursor: default;
`

export const TileInfoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  position: relative;
`

export const TileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const CopyIconStyle = styled(CopyIcon)`
  cursor: pointer;
`

export const ClientForm = styled(Space)`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`

export const ClientInput = styled(Input)`
  width: 100%;
  cursor: default;
`

export const ClientInputTop = styled.div`
  width: 344px;

  .ant-input {
    cursor: default;
    color: ${color.gray75};
    caret-color: transparent;
  }

  .ant-input-affix-wrapper {
    cursor: default;
  }

  .ant-input-affix-wrapper {
    cursor: default;
  }
`

export const IconContainer = styled.div`
  margin-left: 6px;
  height: 22px;

  svg {
    width: 22px;
  }

  &:hover {
    svg .a {
      fill: ${color.blueTint20};
    }
  }
`

export const EditContainer = styled.div`
  display: flex;
  cursor: pointer;

  &:hover {
    span {
      color: ${color.blueTint20};
    }

    svg .a {
      fill: ${color.blueTint20};
    }
  }
`

export const EditButton = styled.span`
  font-size: 14px;
  padding-left: 6px;
  padding-right: 0;
  text-transform: uppercase;
  color: ${color.blueBase};
`

export const Name = styled.p`
  margin-top: 25px;
  margin-bottom: 8px;
  font: normal normal normal 25px/30px KONE Information;
`

export const Description = styled.p`
  margin-top: 0;
  margin-bottom: 20px;
  font: normal normal normal 18px/24px KONE Information;
`

export const Line = styled.hr`
  margin-top: 30px;
  margin-bottom: 32px;
  border: 1px solid ${color.gray70};
  width: 100%;
  opacity: 1;
`

export const NameContainer = styled.div`
  width: 100%;
  word-wrap: break-word;
`

export const EnvText = styled.span<{ $sandbox: boolean }>`
  color: ${color.gray10};
  background-color: ${(props) => (props.$sandbox ? color.gray80 : color.green)};
  border-radius: 20px;
  padding: 4px 15px;
  text-transform: uppercase;
  font: normal normal normal 14px KONE Information;
`

export const TileForm = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const FormLabel = styled.p`
  margin-bottom: 8px;
  display: block;
  color: ${color.gray20};
  height: 20px;
  text-align: left;
`

export const SecretInput = styled.div`
  width: 344px;

  .ant-input {
    cursor: default;
    color: ${color.gray75};
  }

  .ant-input-affix-wrapper {
    cursor: default;
  }
`

export const StyledTooltip = styled.div`
  .ant-tooltip-inner {
    color: ${color.black};
    background-color: ${color.white};
    width: 55px;
    height: 16px;
    text-align: right;
    font: normal normal normal 14px/16px KONE Information;
    margin-top: -6px;
    margin-right: 8px;
    min-width: 28px;
    min-height: 28px;
  }
  .ant-tooltip-arrow {
    margin-top: -2px;
  }
  .ant-tooltip-arrow-content {
    background-color: ${color.white};
  }
`
