import React, { FC } from 'react'
import { Container } from './Styles'

type TileProps = {
  backgroundColor: string
  children: React.ReactNode
}

const Tile: FC<TileProps> = ({ backgroundColor, children }) => {
  return <Container backgroundColor={backgroundColor}>{children}</Container>
}

export default Tile
