import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { ApplicationsAPI } from '../../../../shared/api/applications'
import { Organization } from '../../../../shared/models/organization'
import { NumberValue, NumberValueHeader } from '../Styles'

type Props = {
  organization: Organization
}

const ApplicationCount: FC<Props> = ({ organization }) => {
  const { isLoading, error, data } = useQuery([ApplicationsAPI.queryNames.GET_ALL, organization?.externalId], () =>
    ApplicationsAPI.getAll(organization?.externalId)
  )
  if (error) {
    return <p>Error</p>
  }

  return (
    <>
      <NumberValue id="org-application-count">{isLoading ? '–' : data.length}</NumberValue>
      <NumberValueHeader>{data && data.length > 1 ? 'Applications' : 'Application'}</NumberValueHeader>
    </>
  )
}

export default ApplicationCount
