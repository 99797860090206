import { FC } from 'react'
import styled from 'styled-components'
import { Switch as AntSwitch } from 'antd'
import { SwitchProps } from 'antd/lib/switch'
import { color } from '../../utils/styles'

export const AntSwitchContainer = styled.div`
  .ant-switch.ant-switch-checked {
    background-color: ${color.white};
    .ant-switch-handle::before {
      background-color: ${color.blueBase};
    }
  }
  .ant-switch {
    .ant-switch-handle::before {
      background-color: ${color.white};
    }
  }
`

type SwitchPropsWithId = SwitchProps & { id: string }

export const StyledSwitch: FC<SwitchPropsWithId> = styled(AntSwitch)``
