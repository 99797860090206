import React, { FC } from 'react'
import { H4, Tile } from '../../../shared/components'
import { color } from '../../../shared/utils/styles'
import { CreateButton, PlusIconContainer } from './Styles'
import { TileCreateStrings } from '../../../shared/strings/ApplicationsContent'
import { ReactComponent as PlusIcon } from '../../../assets/plus-icon.svg'

type Props = {
  isFirstApplication: boolean
  onClickCreate: () => void
}

const ApplicationsTileCreate: FC<Props> = ({ isFirstApplication, onClickCreate: onClickCreateParentHandler }) => {
  const onClickCreate = () => {
    onClickCreateParentHandler()
  }

  return (
    <Tile backgroundColor={color.gray85}>
      <PlusIconContainer>
        <PlusIcon />
      </PlusIconContainer>
      <H4 id="create-application-tile-text-js">
        {isFirstApplication ? TileCreateStrings.createFirstApplication : TileCreateStrings.createNewApplication}
      </H4>
      <CreateButton id="open-create-app-button-js" onClick={onClickCreate} type="primary" size="large">
        {TileCreateStrings.createApplication}
      </CreateButton>
    </Tile>
  )
}

export default ApplicationsTileCreate
