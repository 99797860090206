import { TitleWrapperProps } from '../components/TitleWrapper'

export const TermsAndConditionsPage = {
  title: 'Terms And Conditions',
}

//TODO: proper meta tags
export const SEOProps: TitleWrapperProps = {
  title: TermsAndConditionsPage.title,
  metaTags: [
    {
      name: '',
      content: '',
    },
  ],
}
