import React, { FC, useState } from 'react'
import { CreateFormStrings } from '../../shared/strings/PartnersContent'
import Modal from '../../shared/components/ModalPopUp'
import { ErrorMessage, H2, H4, Toast } from '../../shared/components'
import {
  ButtonContainer,
  Container,
  FormLabel,
  InputColumn,
  InputName,
  InputsContainer,
  LowerSection,
  ModalButton,
  NewContainer,
} from './Styles'
import { Button, Col, Input, Row } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { InputDescription } from '../../Dashboard/Feedback/Styles'
import {
  PARTNER_NAME_MAX_LENGTH,
  PARTNER_NAME_MIN_LENGTH,
  EMAIL_REG_EXP,
  PHONE_NUMBER_REGEX,
} from '../../shared/constants/auth'
import { EmailData } from '../../shared/models/auth'
import { SendEmailAPI } from '../../shared/api/contact'
import { useHistory } from 'react-router-dom'
import { FeedbackInformation } from '../../shared/strings/FeedbackContent'
import { CheckboxLabel, FormCheckbox, FormCheckboxContainer } from '../../App/Header/AuthForms/RegisterForm/Styles'
import { sendAnalyticsEvent } from '../../shared/adobe-analytics/send-analytics-event'
import { AnalyticsTriggerEvent } from '../../shared/adobe-analytics'

interface Acknowledgementprop {
  isContactSupportOpen: boolean
}

const SupportSuccessView: FC<Acknowledgementprop> = (props) => {
  const { isContactSupportOpen } = props
  const history = useHistory()
  const onClickBack = () => {
    history.push(`/integrated-solutions`)
  }
  return (
    <>
      <NewContainer>
        <H2 id="thank-you-for-your-message-js">{CreateFormStrings.title}</H2>
        {isContactSupportOpen ? (
          <>
            {' '}
            <H4>{CreateFormStrings.message}</H4>
          </>
        ) : (
          <>
            <H4>{CreateFormStrings.messageToPartner}</H4>
          </>
        )}
        <ModalButton onClick={() => onClickBack()}>{CreateFormStrings.back}</ModalButton>
      </NewContainer>
    </>
  )
}
interface EmailProps {
  isContactSupportOpen: boolean
  isContactPartnerOpen: boolean
  partnerId: string
  partnerName: string
  closeEmailPopUp: () => void
}

const SendEmail: FC<EmailProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isShowSuccessView, setIsShowSuccessView] = useState(false)
  const [isSendMeACopy, setIsSendMeACopy] = useState(false)
  const [isConsentClicked, setConsentClicked] = useState(true)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | ''>('')
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm()
  const { isContactSupportOpen, isContactPartnerOpen, closeEmailPopUp } = props

  async function onSubmit(data: EmailData) {
    setIsLoading(true)
    const name = data.name
    const email = data.email
    const phone = data.phone
    const description = data.description
    const sendMeACopy = isSendMeACopy
    const isContactPartner = isContactPartnerOpen
    const partnerId = props.partnerId
    try {
      await SendEmailAPI.sendEmail({
        name,
        email,
        phone,
        description,
        sendMeACopy,
        partnerId,
        isContactPartner,
      })
      setIsLoading(false)
      setIsShowSuccessView(true)
      sendAnalyticsEvent(AnalyticsTriggerEvent.CONTACT_TO_PARTNER, {
        contactToPartner: {
          partnerName: props.partnerName,
          userDetails: isConsentClicked ? `${name},${email}` : 'Anonymous',
        },
      })
    } catch (e: any) {
      if (e?.response?.status === 400) {
        setErrorMessage(e?.response?.data?.message)
        setError(true)
      }
      setIsLoading(false)
    }
  }

  const handleCheckboxClick = () => {
    setIsSendMeACopy(!isSendMeACopy)
  }

  const handleConsentClick = () => {
    setConsentClicked(!isConsentClicked)
  }

  const getContactContent = () => {
    return (
      <>
        <InputsContainer>
          <Row gutter={24}>
            <Col span={8}>
              <InputColumn>
                <FormLabel htmlFor="sender-name-input-js" id="sender-name-label-input-label-js">
                  {CreateFormStrings.name}*
                </FormLabel>
                <Controller
                  render={({ field }) => <InputName id="sender-name-input-js" {...field} disabled={false} />}
                  name="name"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: {
                      value: PARTNER_NAME_MAX_LENGTH,
                      message: CreateFormStrings.partnerNameLengthError,
                    },
                    minLength: {
                      value: PARTNER_NAME_MIN_LENGTH,
                      message: CreateFormStrings.partnerNameLengthError,
                    },
                  }}
                />
                {errors.name && (
                  <ErrorMessage>{errors.name.message || CreateFormStrings.nameRequiredError}</ErrorMessage>
                )}
              </InputColumn>
            </Col>
            <Col span={8}>
              <InputColumn>
                <FormLabel htmlFor="email-input-js" id="email-input-label-js">
                  {CreateFormStrings.email}*
                </FormLabel>
                <Controller
                  render={({ field }) => <Input id="email-input-js" {...field} />}
                  name="email"
                  control={control}
                  rules={{
                    pattern: {
                      value: EMAIL_REG_EXP,
                      message: FeedbackInformation.invalidEmailAddressError,
                    },
                    required: true,
                  }}
                />
                {errors.email && (
                  <ErrorMessage id="email-input-error-js">
                    {errors.email.message || CreateFormStrings.emailRequiredError}
                  </ErrorMessage>
                )}
              </InputColumn>
            </Col>
            <Col span={8}>
              <InputColumn>
                <FormLabel htmlFor="phone-number-input-js" id="phone-number-input-label-js">
                  {CreateFormStrings.phoneNumber}
                </FormLabel>
                <Controller
                  render={({ field }) => <InputName id="phone-number-input-js" {...field} disabled={false} />}
                  name="phone"
                  control={control}
                  rules={{
                    pattern: {
                      value: PHONE_NUMBER_REGEX,
                      message: CreateFormStrings.invalidPhoneNumber,
                    },
                    maxLength: {
                      value: 10,
                      message: CreateFormStrings.phoneNumberMax,
                    },
                    minLength: {
                      value: PARTNER_NAME_MIN_LENGTH,
                      message: CreateFormStrings.phoneNumberMin,
                    },
                  }}
                />
                {errors.phone?.message && (
                  <ErrorMessage id="phone-number-error-input-js">
                    {errors.phone.message || CreateFormStrings.invalidPhoneNumber}
                  </ErrorMessage>
                )}
              </InputColumn>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Row gutter={24}>
                <Col span={24}>
                  <InputColumn>
                    <FormLabel htmlFor="description-input-js">{CreateFormStrings.description}*</FormLabel>
                    <Controller
                      render={({ field }) => (
                        <InputDescription rows={6} id="description-input-js" {...field} disabled={false} />
                      )}
                      name="description"
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />
                    {errors.description && (
                      <ErrorMessage>
                        {errors.description.message || CreateFormStrings.descriptionRequiredError}
                      </ErrorMessage>
                    )}
                  </InputColumn>
                </Col>
              </Row>
            </Col>
          </Row>
          {isContactPartnerOpen && (
            <>
              <FormCheckboxContainer style={{ marginTop: '20px' }}>
                {
                  <FormCheckbox
                    id="send-me-a-copy-checkbox-js"
                    readOnly
                    onClick={handleCheckboxClick}
                    type="checkbox"
                    defaultChecked={false}
                  />
                }
                <CheckboxLabel htmlFor="send-me-a-copy-checkbox-js">
                  {CreateFormStrings.sendMeACopy}&nbsp;
                </CheckboxLabel>
              </FormCheckboxContainer>
              <FormCheckboxContainer>
                {
                  <FormCheckbox
                    id="analytics-consent-checkbox-js"
                    readOnly
                    onClick={handleConsentClick}
                    type="checkbox"
                    defaultChecked={true}
                  />
                }
                <CheckboxLabel htmlFor="consent-input-js" style={{ marginTop: '15px' }}>
                  {CreateFormStrings.consent}
                  <a href={CreateFormStrings.privacyStatementLink} target="_blank" rel="noopener noreferrer">
                    {CreateFormStrings.privacyStatement}
                  </a>
                  {' for more details.'}
                </CheckboxLabel>
              </FormCheckboxContainer>
            </>
          )}
        </InputsContainer>
      </>
    )
  }

  const onClosePopUps = () => {
    if (isShowSuccessView) {
      setIsShowSuccessView(false)
    }
    closeEmailPopUp()
    reset()
  }

  return (
    <>
      <Toast
        isVisible={error}
        text={errorMessage}
        textId="max-email-js"
        onDismiss={() => setError(false)}
        type="error"
        allowWithTime={true}
      />
      <Modal
        key="edit-apis"
        isOpen={isContactSupportOpen || isContactPartnerOpen}
        title={isContactPartnerOpen ? CreateFormStrings.emailPartnerPopUpTitle : CreateFormStrings.emailToKoneSupport}
        onClose={onClosePopUps}
        width={'800px'}
        height={'300px'}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {!isShowSuccessView && (
            <>
              <Container>
                <LowerSection>{getContactContent()}</LowerSection>
              </Container>
              <ButtonContainer>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  SEND
                </Button>
              </ButtonContainer>
            </>
          )}
          {isShowSuccessView && <SupportSuccessView isContactSupportOpen={isContactSupportOpen} />}
        </form>
      </Modal>
    </>
  )
}

export default SendEmail
