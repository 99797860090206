import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactCodeInput from 'react-code-input'
import { AuthFormLabel, ErrorMessage, Toast } from '../../../../shared/components'
import { OTP_LENGTH, OTP_REG, REQUIRED_FIELD } from '../../../../shared/constants/auth'
import { useAuth } from '../../../../shared/contexts/authContext'
import { ConfirmationData, LoginData } from '../../../../shared/models/auth'
import { MfaType } from '../../../../shared/models/mfa'
import { MFALoginStrings, MFAStrings } from '../../../../shared/strings/MFAContent'
import { getMfaExceptionError } from '../../../../shared/utils/mfa'
import {
  MFAContainer,
  OpenAuthAppText,
  StyledForm,
  TwoFactorAuthText,
  VerificationCodeContainer,
  VerifyButton,
  props,
} from './Styles'

type Props = {
  loginData: LoginData
}

const MFAAuthForm: FC<Props> = ({ loginData }) => {
  const { setIsMfaNeeded, confirmSignIn } = useAuth()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifyTotpErrorVisible, setIsVerifyTotpErrorVisible] = useState(false)
  const [totpError, setTotpError] = useState<Error | undefined>()

  async function onSubmit(data: ConfirmationData) {
    setIsLoading(true)
    try {
      await confirmSignIn(loginData.email, loginData.password, data.confirmationCode, MfaType.TOTP)
      setIsMfaNeeded(false)
    } catch (e) {
      console.error(e)
      const err = getMfaExceptionError(e.code)
      setTotpError(err ?? e.message)
      setIsLoading(false)
      setIsVerifyTotpErrorVisible(true)
    }
  }

  return (
    <MFAContainer>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Toast
          key="submit-error"
          isVisible={isVerifyTotpErrorVisible}
          text={totpError}
          onDismiss={() => setIsVerifyTotpErrorVisible(false)}
          type="error"
        />
        <TwoFactorAuthText> {MFALoginStrings.twoFactorText}</TwoFactorAuthText>
        <VerificationCodeContainer>
          <AuthFormLabel htmlFor="confirmation-code-input-js">{MFALoginStrings.enter6digitCode}</AuthFormLabel>
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <ReactCodeInput
                name={name}
                value={value}
                onChange={onChange}
                inputMode="numeric"
                type="number"
                fields={6}
                inputStyle={props}
              />
            )}
            name="confirmationCode"
            control={control}
            defaultValue=""
            rules={{
              minLength: { value: OTP_LENGTH, message: MFAStrings.input6digitFieldError },
              maxLength: { value: OTP_LENGTH, message: MFAStrings.input6digitFieldError },
              pattern: { value: OTP_REG, message: MFAStrings.inputFieldNumbersError },
              required: true,
            }}
          />
          {errors.confirmationCode && (
            <ErrorMessage id="confirmation-code-input-error-js">
              {errors.confirmationCode.message || REQUIRED_FIELD}
            </ErrorMessage>
          )}
        </VerificationCodeContainer>
        <OpenAuthAppText size="small"> {MFALoginStrings.openAuthAppText} </OpenAuthAppText>
        <VerifyButton id="verify-button-js" loading={isLoading} type="primary" size="large" htmlType="submit">
          {MFALoginStrings.verifyButtonText}
        </VerifyButton>
      </StyledForm>
    </MFAContainer>
  )
}

export default MFAAuthForm
