import React, { FC } from 'react'
import { NavHashLink } from 'react-router-hash-link'
import { H4 } from '../../../../shared/components/Typography'
import { LeftCol, RightCol, SectionRow, TableOfContentsStyles } from '../../Styles'
import { AsyncApiProps } from '../index'
export interface Props {
  asyncApiProps: AsyncApiProps
}

const { Items, Item, Name } = TableOfContentsStyles

export const TableOfContents: FC<Props> = ({ asyncApiProps }) => (
  <SectionRow>
    <LeftCol span="12">
      <H4>Table of contents</H4>
      <Items>
        <Item>
          <Name>
            <NavHashLink smooth to="#authentication">
              Authentication
            </NavHashLink>
          </Name>
        </Item>
        <Item>
          <Name>
            <NavHashLink smooth to="#usage-example">
              Usage example
            </NavHashLink>
          </Name>
        </Item>
        <Item>
          <Name>
            <NavHashLink smooth to="#make-an-elevator-call">
              Make an elevator call
            </NavHashLink>
          </Name>
        </Item>
        {asyncApiProps.callType === 'robot' && (
          <>
            <Item>
              <Name>
                <NavHashLink smooth to="#hold-car-door-open">
                  Hold car door open
                </NavHashLink>
              </Name>
            </Item>
          </>
        )}
        <Item>
          <Name>
            <NavHashLink smooth to="#cancel-an-elevator-call">
              Cancel an elevator call
            </NavHashLink>
          </Name>
        </Item>
        {/* <Item>
          <Name>
            <NavHashLink smooth to="#create-a-session">
              Create a session
            </NavHashLink>
          </Name>
        </Item>
        <Item>
          <Name>
            <NavHashLink smooth to="#resume-a-session">
              Resume a session
            </NavHashLink>
          </Name>
        </Item> */}
        <Item>
          <Name>
            <NavHashLink smooth to="#monitoring-commands">
              Site monitoring
            </NavHashLink>
          </Name>
        </Item>
        <Item>
          <Name>
            <NavHashLink smooth to="#common-commands">
              Common Commands
            </NavHashLink>
          </Name>
        </Item>
        <Item>
          <Name>
            <NavHashLink smooth to="#monitoring-events-js">
              Monitoring Events
            </NavHashLink>
          </Name>
        </Item>
      </Items>
    </LeftCol>
    <RightCol span="12"></RightCol>
  </SectionRow>
)
