import React, { FC } from 'react'

import { SolutionCreationSvg, ImageContainer, ButtonContainer, GetStartedButton } from './Styles'

type Props = {
  onClick: () => void
}

const SolutionCreationLanding: FC<Props> = ({ onClick }) => {
  return (
    <>
      <ImageContainer>
        <SolutionCreationSvg />
      </ImageContainer>
      <ButtonContainer>
        <GetStartedButton type="primary" onClick={onClick}>
          Get started
        </GetStartedButton>
      </ButtonContainer>
    </>
  )
}

export default SolutionCreationLanding
