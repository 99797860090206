import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'
import { Steps } from 'antd'
import { ReactComponent as ErrorIcon } from '../../../assets/warning.svg'

const { Step } = Steps

export const SingleStep = styled(Step)`
  margin-top: 20px;
`

export const WizardContainer = styled.div`
  width: calc(100% + 98px);
  background-color: ${color.gray85};
  margin: -50px 0 48px -50px;
  padding: 0 44px;
  height: 96px;

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: ${color.gray15};
    font: normal normal normal 16px/22px KONE Information;
    margin-bottom: 4px;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: ${color.gray30};
    font: normal normal normal 16px/22px KONE Information;
    margin-bottom: 4px;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: ${color.gray30};
    font: normal normal normal 16px/22px KONE Information;
    margin-bottom: 4px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: ${color.gray15};
    font: normal normal normal 12px/16px KONE Information;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: ${color.gray30};
    font: normal normal normal 12px/16px KONE Information;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: ${color.gray30};
    font: normal normal normal 12px/16px KONE Information;
  }

  .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
    font: normal normal normal 16px/22px KONE Information;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: transparent;
    border: 1px solid ${color.gray70};
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${color.gray30};
    font: normal normal normal 16px/22px KONE Information;
  }

  .ant-steps-item-icon .ant-steps-icon {
    top: 0;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: transparent;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    border: 1px solid ${color.gray70};
    width: 100px;
    margin-right: 15px;
    margin-left: 22px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    border: 1px solid ${color.gray70};
    width: 100px;
    margin-right: 15px;
    margin-left: 22px;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    border: 1px solid ${color.gray70};
    width: 100px;
    margin-right: 15px;
    margin-left: 22px;
  }

  .ant-steps-item-error .ant-steps-item-icon {
    background-color: transparent;
  }

  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    border: 1px solid ${color.gray70};
    width: 100px;
    margin-right: 15px;
    margin-left: 22px;
  }

  .ant-steps-small .ant-steps-item-title {
    font: normal normal normal 16px/22px KONE Information;
  }

  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    font: normal normal normal 12px/16px KONE Information;
  }
`

export const ErrorIconStyled = styled(ErrorIcon)`
  text-align: center;
`

export const ErrorContainer = styled.div`
  border: 1px solid #ed555a;
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
`
