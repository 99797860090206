import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'
import Button from '../../../shared/components/Button'
import { Checkbox, Input } from 'antd'

export const MainContainer = styled.div`
  display: flex;
  justify-content: left;
`

export const AppName = styled.p`
  color: ${color.gray20};
  font: normal normal normal 28px/42px KONE Information;
  margin-bottom: 25px;
`

export const ApiName = styled.p`
  color: ${color.gray20};
  font: normal normal normal 24px/30px KONE Information;
  margin-bottom: 25px;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

export const InputChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
`

export const ChipContainer = styled.div`
  flex-direction: column;
  width: 100%;
  display: 'block';
  padding: 20px;
  border: 1.5px solid ${color.blueBase};
  background-color: ${color.white};
`

export const ResourceIdContainer = styled(Button)`
  border-radius: 10px;
  margin: 6px;
`

export const WarningLabel = styled.label`
  margin-bottom: 12px;
  font-size: 13px;
  color: ${color.gray20};
`

export const FormElement = styled.div`
  margin-bottom: 3em;
  width: 70%;
`

export const FormLabel = styled.label`
  margin-bottom: 8px;
  display: block;
  color: ${color.gray20};
`

export const ButtonsContainerContact = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
`

export const InputName = styled(Input)`
  color: ${color.gray75};
  width: 100%;
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 34px;
  justify-content: center;
`

export const CreateButton = styled(Button)`
  width: 208px;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  opacity: 0.4;
  width: 100%;
`

export const FormCheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const FormCheckbox = styled.input`
  margin-left: 4px;
  margin-right: 8px;
`

export const CheckboxLabel = styled.label`
  color: ${color.gray20};
  line-height: 1;
`

export const SelectAllResource = styled(Checkbox)`
  color: white;
  margin-left: 25px;
`
