import { Tooltip } from 'antd'
import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Paths from '../../Paths'
import Toast from '../../../shared/components/Toast'
import { ReactComponent as RightArrow } from '../../../assets/icons-right-arrow.svg'
import { TOOLTIP_VISIBILITY_MLS } from '../../../shared/constants/common'
import {
  CenterContainer,
  CopyIconStyle,
  DocumentationLabel,
  DocumentationLinksContainer,
  DocumentationRow,
  DocumentationWord,
  FormLabel,
  FormWrapper,
  HeadersDocumentation,
  IconContainer,
  InputContainer,
  InputName,
  Line,
  LinkButton,
  OkIcon,
  StyledTooltip,
  SuccessContainer,
  SuccessMessage,
  WiderCancelButton,
} from './Styles'
import { ClientInfoStrings, SharedApplicationStrings } from '../../../shared/strings/ApplicationsContent'

type Props = {
  clientId?: string
  clientSecret?: string
  onCancelCreate: () => void
}

const ClientInfo: FC<Props> = ({ clientId, clientSecret, onCancelCreate }) => {
  const history = useHistory()
  const [isClientSecretInfoVisible, setIsClientSecretInfoVisible] = useState<boolean>(true)
  const [isCopyTooltipVisible, setIsCopyTooltipVisible] = useState(false)
  const [isCopySecretTooltipVisible, setIsCopySecretTooltipVisible] = useState(false)

  const handleOnCopyClick = (copiedItem: string) => {
    isSecretOrClientCopied(copiedItem)
    displayCorrectTooltip(copiedItem)
    delayTooltipVisibility(copiedItem)
  }

  const isSecretOrClientCopied = (copiedItem: string) => {
    copiedItem === 'client'
      ? navigator.clipboard.writeText(clientId ? clientId : '')
      : clientSecret
      ? navigator.clipboard.writeText(clientSecret)
      : navigator.clipboard.writeText('')
  }

  const displayCorrectTooltip = (copiedItem: string) => {
    copiedItem === 'client' ? setIsCopyTooltipVisible(true) : setIsCopySecretTooltipVisible(true)
  }

  const delayTooltipVisibility = (copiedItem: string) => {
    setTimeout(() => {
      copiedItem === 'client' ? setIsCopyTooltipVisible(false) : setIsCopySecretTooltipVisible(false)
    }, TOOLTIP_VISIBILITY_MLS)
  }

  const openAPIDocs = (path: string) => {
    history.push(path)
  }

  return (
    <FormWrapper>
      <Toast
        isVisible={isClientSecretInfoVisible}
        text={ClientInfoStrings.clientSecretInfo}
        onDismiss={() => setIsClientSecretInfoVisible(false)}
        type="warning"
      />
      <SuccessContainer>
        <OkIcon />
        <SuccessMessage>{ClientInfoStrings.appCreatedSuccessMessage}</SuccessMessage>
      </SuccessContainer>
      <form>
        <InputContainer>
          <FormLabel>{ClientInfoStrings.clientId}</FormLabel>
          <CenterContainer>
            <InputName id="client-id-js" type="text" value={clientId} readOnly></InputName>{' '}
            <IconContainer>
              <Tooltip
                placement="right"
                title={SharedApplicationStrings.copied}
                visible={isCopyTooltipVisible}
                getPopupContainer={(trigger) => {
                  return trigger
                }}
              >
                <StyledTooltip>
                  <CopyIconStyle onClick={() => handleOnCopyClick('client')} />
                </StyledTooltip>
              </Tooltip>
            </IconContainer>
          </CenterContainer>
          <FormLabel>{ClientInfoStrings.clientSecret}</FormLabel>
          <CenterContainer>
            <br></br>
            <InputName id="client-secret-js" type="text" value={clientSecret} readOnly></InputName>
            <IconContainer>
              <Tooltip
                placement="right"
                title={SharedApplicationStrings.copied}
                visible={isCopySecretTooltipVisible}
                getPopupContainer={(trigger) => {
                  return trigger
                }}
              >
                <StyledTooltip>
                  <CopyIconStyle onClick={() => handleOnCopyClick('secret')} />
                </StyledTooltip>
              </Tooltip>
            </IconContainer>
          </CenterContainer>
          <HeadersDocumentation>{ClientInfoStrings.availableApis}</HeadersDocumentation>
          <Line />
          <DocumentationLinksContainer>
            <DocumentationRow>
              <DocumentationLabel>{Paths.ElevatorCallAPI.name}</DocumentationLabel>
              <LinkButton
                id="open-elevator-call-api-button-js"
                type="link"
                onClick={() => openAPIDocs(Paths.ElevatorCallAPI.path)}
              >
                <DocumentationWord>{ClientInfoStrings.documentation}</DocumentationWord>
                <RightArrow />
              </LinkButton>
            </DocumentationRow>
            <DocumentationRow>
              <DocumentationLabel>{Paths.ServiceRobotAPI.name}</DocumentationLabel>
              <LinkButton
                id="open-service-robot-api-button-js"
                type="link"
                onClick={() => openAPIDocs(Paths.ServiceRobotAPI.path)}
              >
                <DocumentationWord>{ClientInfoStrings.documentation}</DocumentationWord>
                <RightArrow />
              </LinkButton>
            </DocumentationRow>
            <DocumentationRow>
              <DocumentationLabel>{Paths.EquipmentStatusAPIProduct.name}</DocumentationLabel>
              <LinkButton
                id="open-equipment-status-api-button-js"
                type="link"
                onClick={() => openAPIDocs(Paths.EquipmentStatusAPIProduct.path)}
              >
                <DocumentationWord>{ClientInfoStrings.documentation}</DocumentationWord>
                <RightArrow />
              </LinkButton>
            </DocumentationRow>
            <DocumentationRow>
              <DocumentationLabel>{Paths.ServiceInfoAPIProduct.name}</DocumentationLabel>
              <LinkButton
                id="open-service-info-api-button-js"
                type="link"
                onClick={() => openAPIDocs(Paths.ServiceInfoAPIProduct.path)}
              >
                <DocumentationWord>{ClientInfoStrings.documentation}</DocumentationWord>
                <RightArrow />
              </LinkButton>
            </DocumentationRow>
          </DocumentationLinksContainer>
        </InputContainer>
        <WiderCancelButton id="cancel-button-js" onClick={onCancelCreate} ghost disabled={false}>
          {ClientInfoStrings.backToApplications}
        </WiderCancelButton>
      </form>
    </FormWrapper>
  )
}

export default ClientInfo
