import styled from 'styled-components'
import { Button } from '../../../shared/components'
import plusIcon from '../../../assets/plus-icon.svg'

export const PlusIcon = styled.div`
  width: 172px;
  height: 172px;
  background-image: url(${plusIcon});
  background-repeat: no-repeat;
`

export const CreateButton = styled(Button)`
  width: 234px;
`
