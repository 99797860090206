import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogApril = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        April 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        April release notes summarizes latest changes for Service Info API.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              <q>workCenter</q> is now available in{' '}
              <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}>Service Info Webhook API</Link>.
            </li>
            <li>
              <q>serviceOrderTypeDesc</q> which previously included both the service order type code and its
              description, now only contains its description in{' '}
              <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrderById'}> Service Info Details API</Link>. The
              service order type code is removed from the serviceOrderTypeDesc data.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
