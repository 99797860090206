import React, { FC, useState, useRef } from 'react'
import { KeyNames } from '../../utils/keyNames'
import { Container, Input, Tag, TagRemove, TagText } from './Styles'
import { GeneralFormStrings } from '../../strings/GeneralFormContent'

const INPUT_FIELD_MIN_SIZE = 15
const TOKEN_SEPARATOR_REGEX = /,|;|\n/

type Props = {
  placeholder?: string
  values: string[]
  onInputChange: () => void
  onValuesChange: (emails: string[]) => void
  validate?: (value: string) => string | undefined
  onValidationError: (error: string) => void
  disabled?: boolean
}

const MultiInput: FC<Props> = ({
  placeholder,
  values,
  onInputChange: onInputChangeParent,
  onValuesChange,
  validate,
  onValidationError,
  disabled = false,
}) => {
  const [currentInputValue, setCurrentInputValue] = useState('')
  const inputFieldRef = useRef<HTMLInputElement>(null)

  const removeValue = (index: number) => {
    const updatedValues = [...values.slice(0, index), ...values.slice(index + 1)]
    onValuesChange(updatedValues)
  }

  const createTag = (value: string, index: number) => {
    return (
      <Tag key={index} className="multi-input-tag-js">
        <TagText>{value}</TagText>
        <TagRemove onClick={() => removeValue(index)} disabled={disabled} />
      </Tag>
    )
  }

  const parseTextAndUpdateValues = (text: string) => {
    const tokens = text
      .split(TOKEN_SEPARATOR_REGEX)
      .map((token) => token.trim())
      .filter((token) => token)

    if (!tokens || !tokens.length) {
      return onValidationError(GeneralFormStrings.valueCannotBeEmpty)
    }

    if (validate) {
      let validationError
      tokens.every((token) => {
        validationError = validate(token)
        return !validationError
      })

      if (validationError) {
        setCurrentInputValue(text)
        return onValidationError(validationError)
      }
    }

    const updatedValues = [...values, ...tokens]
    setCurrentInputValue('')
    onValuesChange(updatedValues)
  }

  const onInputChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setCurrentInputValue(e.currentTarget.value)
    onInputChangeParent()
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyNames.TAB || e.key === KeyNames.ENTER) {
      e.preventDefault()
    }
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyNames.TAB || e.key === KeyNames.ENTER) {
      parseTextAndUpdateValues(e.currentTarget.value)
    }
  }

  const onContainerClick = () => {
    if (inputFieldRef && inputFieldRef.current) {
      inputFieldRef.current.focus()
    }
  }

  const getInputSize = () => {
    return currentInputValue.length < INPUT_FIELD_MIN_SIZE ? INPUT_FIELD_MIN_SIZE : currentInputValue.length
  }

  const onPaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    onInputChangeParent()
    const text = e.clipboardData.getData('Text')
    parseTextAndUpdateValues(text)
  }

  return (
    <Container onClick={onContainerClick} disabled={disabled}>
      {values.map((value: string, index: number) => createTag(value, index))}
      <Input
        id="multi-input-field-js"
        size={getInputSize()}
        placeholder={placeholder}
        value={currentInputValue}
        onChange={onInputChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        ref={inputFieldRef}
        disabled={disabled}
      />
    </Container>
  )
}

export default MultiInput
