import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { H2, P } from '../../../shared/components/Typography'
import Paths from '../../Paths'

const EventsDocumentation: FC = () => {
  return (
    <Row>
      <Col md={24} lg={24} xl={16}>
        <H2>Events</H2>
        <P size="small">
          Events provide a capability to be notified when something interesting happens with your equipment. Events are
          delivered using the webhook mechanism.
        </P>
        <P size="small">
          Events are categorized by type. When you subscribe to receive events, you must select which types of events
          you are interested in and to which endpoint we should deliver them. Note that you don&apos;t need to specify
          the equipment you are interested in. You will receive events from all equipment available to you, and you will
          automatically receive events about new equipment added to your account.
        </P>
        <P size="small">
          Events are part of APIs. You can find the event types associated with an API from the API page, for example,{' '}
          <Link to={Paths.EquipmentStatusAPIProduct.path}>Real-time Equipment API</Link>. You can only receive events
          that are associated with an API that has been enabled in your account.
        </P>
        <P size="small">
          To receive the events, you must first create the application to receive the webhook calls or add the required
          endpoint to your existing application. The endpoint you must implement is documented at Webhook Callback API.
          The webhooks are considered backend-to-backend integration mechanisms, and the endpoint you create must be
          accessible from the public internet using the specified address. Make sure to implement also the
          authentication part of the <Link to={Paths.WebhookCallbackAPIDocs.path}>Webhook Callback API</Link> to guard
          against malicious 3rd parties trying to send fake messages to your endpoint. We also recommended that you
          create the endpoint in a way that enables you to monitor the calls that it receives, for example, by logging
          them.
        </P>
        <P size="small">
          After you have implemented the endpoint, you can test it out in the{' '}
          <Link to={Paths.SandboxApplication.path}>sandbox</Link>. You can create the webhook subscription either from
          the Dashboard under the selected application or by using the{' '}
          <Link to={Paths.WebhookManagementAPIDocs.path}>Webhook Management API</Link>. The creation of a webhook
          subscription triggers the test event to your endpoint. You can use the Portal or the API to trigger sending{' '}
          <Link to={`${Paths.WebhookManagementAPIDocs.path}#triggerTestEvent`}>test events</Link> if you need to test
          things in your endpoint implementation. When you are happy with your endpoint&apos;s technical implementation,
          you can subscribe to the actual events in the sandbox and start creating your application based on those.
        </P>
      </Col>
    </Row>
  )
}

export default EventsDocumentation
