import { AnalyticsTriggerEvent, AnalyticsEventDetails } from './index'
import { analyticEnvironment } from './environment-ids-config'

interface AnalyticsDataLayer {
  environment: string
}

declare global {
  interface Window {
    _satellite: any
    adobeDataLayer: Array<AnalyticsDataLayer>
  }
}

export const sendAnalyticsEvent = (event: AnalyticsTriggerEvent, eventDetails: AnalyticsEventDetails): void => {
  const { solutionsSearched, solutionsVisited, pageViewed, contactToPartner } = eventDetails

  window.adobeDataLayer = window.adobeDataLayer || []
  const dataLayerObject = {
    event,
    pageViewed,
    solutionsSearched,
    solutionsVisited,
    contactToPartner,
    environment: analyticEnvironment(),
  }
  window.adobeDataLayer.push(dataLayerObject)
}

export const setAnalyticsMonitorLogs = () => {
  window._satellite = window._satellite || {}
  window._satellite._monitors = window._satellite._monitors || []
  if (window._satellite._monitors.length === 0) {
    window._satellite._monitors.push({
      ruleTriggered: (event: any) => console.debug('rule triggered', event.rule),
      ruleCompleted: (event: any) => console.debug('rule completed', event.rule),
      ruleConditionFailed: (event: any) => console.debug('rule condition failed', event.rule),
    })
  }
}
