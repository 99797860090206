import { API } from './API'
import { CustomerApp } from '../models/application'

const APPLICATIONS_PATH = '/customer/clients'

const queryNames = {
  GET_ALL: 'applications-get-all',
}

const create = async (data: CustomerApp): Promise<any> => {
  const parameters = {
    body: data,
  }
  return await API.post(APPLICATIONS_PATH, parameters)
}

const getAll = async (orgId?: string): Promise<any> => {
  if (orgId) {
    const parameters = {
      headers: { 'x-org': orgId },
    }
    return await API.get(APPLICATIONS_PATH, parameters)
  }
  return await API.get(APPLICATIONS_PATH)
}

const generateSecret = async (clientId: string): Promise<any> => {
  return await API.post(`${APPLICATIONS_PATH}/${clientId}/secret`)
}

const remove = async (clientId: string): Promise<any> => {
  return await API.remove(`${APPLICATIONS_PATH}/${clientId}`)
}

export const ApplicationsAPI = {
  queryNames,
  create,
  getAll,
  remove,
  generateSecret,
}
