export const DashboardPageStrings = {
  title: 'Dashboard',
  applications: 'Applications',
  developWithAPIsTitle: 'Develop with KONE APIs',
  developWithAPIsText:
    'KONE API Services allow you to innovate, connect and create smart building solutions. Each KONE API product has been designed and packaged for specific use and ' +
    'consist of more than one API. Check out our offering below to discover what you can do with KONE APIs and how they work.',
  productsTitle: 'KONE API products',
  loadingApplications: 'Loading applications list...',
  getApplicationsError: 'Cannot get applications list',
  mfaAddExtraSecurityText: 'Add an extra security layer to your account by ',
  mfaSetupTwoFactorText: 'setting up the two-factor authentication',
}

export const APIProductCardDescription = {
  elevatorCallAPI: 'Implement elevator call-giving features in the applications of your choice',
  serviceRobotAPI: 'Connect robots with elevators and let them call and operate elevators autonomously',
  equipmentStatusAPI: 'Integrate equipment and KONE maintenance service information into building management systems',
  serviceInfoAPI:
    'Receive and inspect KONE maintenance service information and integrate it into building management systems',
}
