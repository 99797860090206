import React, { FC, useState } from 'react'
import { Switch, Toast } from '../../../shared/components'
import { NotificationAPI } from '../../../shared/api/notifications'
import { NotificationStrings } from '../../../shared/strings/NotificationContent'
import { useQueryClient } from 'react-query'
import { ProfileDetails, Section, SubscriptionTypeRowContent, SwitchContainer, NotificationToastText } from './Styles'
import { ContentRow } from '../Styles'

import {
  Subscription,
  SubscriptionList,
  SubscriptionType,
  SubscriptionTypeExt,
} from '../../../shared/models/notification'

type Props = {
  subscription: SubscriptionList[]
  subscriptionTypes: SubscriptionType[]
}
const Notifications: FC<Props> = ({ subscription, subscriptionTypes }) => {
  const queryClient = useQueryClient()
  const [isNotificationToastOpen, setIsNotificationToastOpen] = useState(true)
  const [isUpdateErrorVisible, setIsUpdateErrorVisible] = useState(false)

  if (subscriptionTypes && subscription) {
    subscriptionTypes.forEach((type: SubscriptionTypeExt) => {
      subscription.forEach((sub: SubscriptionList) => {
        if (type.subscriptionId === sub.subscriptionId) {
          type.subscribed = sub.subscribed.toString()
        }
      })
    })
  }
  subscriptionTypes.map((type: SubscriptionTypeExt) => {
    if (!type.subscribed) {
      type.subscribed = true
    } else {
      type.subscribed = JSON.parse(type.subscribed)
    }
  })

  const [subscribed, setSubscribed] = useState(subscriptionTypes)

  const updateSubscription = async (subId: number) => {
    const newSubscription = await subscribed.map((sub: any) => {
      return subId === sub.subscriptionId ? { ...sub, subscribed: !sub.subscribed } : sub
    })

    setSubscribed(newSubscription)

    const updateObj = newSubscription.find((subUpdateObj: Subscription) => subUpdateObj.subscriptionId === subId)
    const errors = []

    try {
      await NotificationAPI.updateSubscription(newSubscription)
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? NotificationStrings.updateError
      errors.push(`${subscriptionTypes}: ${errorMessage}`)
    }

    if (errors.length) {
      setIsUpdateErrorVisible(true)
      setSubscribed(subscriptionTypes)
    }
    queryClient.invalidateQueries(`${NotificationAPI.queryNames.GET_SUBSCRIPTION}`)
  }

  const showNotificationToast = () => {
    return (
      <Toast
        isVisible={isNotificationToastOpen}
        text={[
          <div key="notification">
            <NotificationToastText>{NotificationStrings.manageSubcription}</NotificationToastText>
          </div>,
        ]}
        onDismiss={() => {
          setIsNotificationToastOpen(false)
        }}
        type="info"
        position="absolute"
        top="120px"
        right="188px"
      />
    )
  }

  const loadSubscription = () => {
    return subscribed.map((subscription: any) => (
      <ContentRow key={subscription.subscriptionId}>
        <SubscriptionTypeRowContent title={subscription.subscriptionId}>
          {subscription.subscriptionType}
        </SubscriptionTypeRowContent>
        <SwitchContainer>
          <Switch
            id={subscription.subscriptionId}
            checked={subscription.subscribed}
            onChange={(e) => updateSubscription(subscription.subscriptionId)}
          />
        </SwitchContainer>
      </ContentRow>
    ))
  }
  return (
    <div>
      <ProfileDetails>
        <Toast
          isVisible={isUpdateErrorVisible}
          text={NotificationStrings.updateError}
          textId="add-resources-toast-error-js"
          onDismiss={() => setIsUpdateErrorVisible(false)}
          type="error"
          position="absolute"
          top="180px"
          right="188px"
        />
        {showNotificationToast()}
        <Section>{loadSubscription()}</Section>
      </ProfileDetails>
    </div>
  )
}

export default Notifications
