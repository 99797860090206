import { Input } from 'antd'
import countryLookup from 'country-code-lookup'
import React, { ChangeEvent, FC, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { ReactComponent as ClearIcon } from '../../assets/icon-search-clear.svg'
import { ReactComponent as SearchIcon } from '../../assets/icon-search-find.svg'
import { OrganizationsAPI } from '../../shared/api/organizations'
import { H4, H5, SidePanel } from '../../shared/components'
import Pagination from '../../shared/components/Pagination'
import { TABLE_CONTENT_LENGTH } from '../../shared/constants/common'
import { useAuth } from '../../shared/contexts/authContext'
import { Organization } from '../../shared/models/organization'
import { OrganizationsStrings, TileCreateStrings } from '../../shared/strings/OrganizationsContent'
import CreateForm from './CreateForm'
import EditForm from './EditForm'
import {
  CountHeading,
  CreateButton,
  HeaderRow,
  MessageContainer,
  OrgRowContent,
  OrgRowContentLarge,
  PaginationPlacing,
  RowExpand,
  SearchCreateRow,
} from './Styles'
import OrganizationsTable from './Table'

const Organizations: FC = () => {
  const queryClient = useQueryClient()
  const [page, setPage] = useState(0)
  const [isCreatePanelOpen, setIsCreatePanelOpen] = useState(false)
  const [isEditingNewOrganization, setIsEditingNewOrganization] = useState(false)
  const [currentEditOrganization, setCurrentEditOrganization] = useState<Organization | null>(null)
  const { isLoading, error, data } = useQuery(OrganizationsAPI.queryNames.GET_ALL, () => OrganizationsAPI.getAll())
  const [organizationFilter, setOrganizationFilter] = useState('')
  const { currentUser } = useAuth()

  const onlyCustomers = currentUser?.isKoneAdmin

  const getGreeting = () => {
    const firstName = currentUser?.firstName
    return currentUser?.isKoneAdmin
      ? OrganizationsStrings.organizations
      : `${OrganizationsStrings.welcomeBack}${firstName ? ' ' + firstName : ''}!`
  }

  const editOrganization = (organization: Organization) => {
    setCurrentEditOrganization(organization)
  }

  const deleteOrganization = async (organization: Organization) => {
    const result = await OrganizationsAPI.deleteOrganization(organization)
    setTimeout(() => {
      queryClient.invalidateQueries(OrganizationsAPI.queryNames.GET_ALL)
    }, 2000)
    return result
  }

  const onClickCreate = () => {
    setIsCreatePanelOpen(true)
  }

  const onOrgFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    setOrganizationFilter(event.target.value.toLowerCase())
  }

  const showOrgListTable = () => {
    if (isLoading) {
      return <MessageContainer>{OrganizationsStrings.loadingOrganizationsList}</MessageContainer>
    }
    if (error) {
      return <MessageContainer>{OrganizationsStrings.organizationsListError}</MessageContainer>
    }

    const infoTable = data
      .filter((org: Organization) => {
        return organizationFilter !== undefined
          ? org.name.toLowerCase().includes(organizationFilter) ||
              org.type.toLowerCase().includes(organizationFilter) ||
              org.id.toString().includes(organizationFilter) ||
              org.salesforceAccountId?.toLowerCase().includes(organizationFilter) ||
              countryLookup
                .byIso(org.country ? org.country : 0)
                ?.country.toLowerCase()
                .includes(organizationFilter)
          : true
      })
      .filter((org: Organization) => {
        return onlyCustomers ? org.type != 'personal' : true
      })
      .map((org: Organization) => {
        return (
          <OrganizationsTable
            onClickEdit={editOrganization}
            onClickDelete={deleteOrganization}
            key={org.id}
            organization={org}
          />
        )
      })
    return (
      <>
        <SearchCreateRow>
          <Input
            id="search-organization-input-js"
            style={{ width: 263, height: 40 }}
            placeholder="Search organization"
            onChange={onOrgFilterChange}
            suffix={organizationFilter ? <ClearIcon onClick={() => setOrganizationFilter('')} /> : <SearchIcon />}
            value={organizationFilter}
          />
          <CreateButton id="open-create-org-button-js" onClick={onClickCreate} type="primary" size="large">
            {TileCreateStrings.createOrganization}
          </CreateButton>
        </SearchCreateRow>
        <CountHeading>Showing {infoTable.length} organizations</CountHeading>
        <HeaderRow>
          <RowExpand></RowExpand>
          <OrgRowContent>Salesforce ID</OrgRowContent>
          <OrgRowContentLarge>Name</OrgRowContentLarge>
          <OrgRowContent>Type</OrgRowContent>
          <OrgRowContent>Created By</OrgRowContent>
          <OrgRowContent>Creation Date</OrgRowContent>
          <OrgRowContent></OrgRowContent>
        </HeaderRow>
        {infoTable.slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))}
        <PaginationPlacing>
          <Pagination dataLength={infoTable.length == 0 ? 1 : infoTable.length} page={page} setPage={setPage} />
        </PaginationPlacing>
      </>
    )
  }
  const closeCreateSidePanel = () => {
    setIsCreatePanelOpen(false)
  }

  const closeEditSidePanel = () => {
    setCurrentEditOrganization(null)
    setIsEditingNewOrganization(false)
  }

  const createSuccessful = (organization: Organization) => {
    closeCreateSidePanel()
    setIsEditingNewOrganization(true)
    editOrganization(organization)
  }

  return (
    <div>
      <H4>{getGreeting()}</H4>
      {!currentUser?.isKoneAdmin && <H5>{OrganizationsStrings.yourOrganizations}</H5>}
      {showOrgListTable()}
      <SidePanel
        key="create-organization-side-panel"
        isOpen={isCreatePanelOpen}
        title="Create organization"
        showBackButton={true}
        onClose={closeCreateSidePanel}
      >
        <CreateForm onCancelCreate={closeCreateSidePanel} onCreateSuccessful={createSuccessful} />
      </SidePanel>
      <SidePanel
        key="edit-organization-side-panel"
        isOpen={!!currentEditOrganization}
        title="Edit organization"
        showBackButton={true}
        onClose={closeEditSidePanel}
      >
        {currentEditOrganization && (
          <EditForm
            organization={currentEditOrganization}
            onCloseEdit={closeEditSidePanel}
            isEditingNewOrganization={isEditingNewOrganization}
          />
        )}
      </SidePanel>
    </div>
  )
}

export default Organizations
