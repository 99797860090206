import React, { FC } from 'react'
import { RemoveContainer, RemoveButton } from './Styles'
import Table from '../Table'
import { SharedOrganizationsStrings } from '../../../shared/strings/OrganizationsContent'

type Props = {
  children: React.ReactNode
  onClickRemove?: () => void
  isRemoving?: boolean
}

const ListTableContentRow: FC<Props> = ({ children, onClickRemove, isRemoving }) => {
  return (
    <Table.ContentRow>
      {children}
      {onClickRemove && (
        <RemoveContainer>
          <RemoveButton id="remove-item-button-js" onClick={onClickRemove} ghost loading={isRemoving}>
            {SharedOrganizationsStrings.removeButton}
          </RemoveButton>
        </RemoveContainer>
      )}
    </Table.ContentRow>
  )
}

export const DeletedListTableContentRow: FC<Props> = ({ children, onClickRemove, isRemoving }) => {
  return (
    <Table.ContentRow>
      {children}
      {onClickRemove && (
        <RemoveContainer>
          <RemoveButton id="remove-item-button-js" onClick={onClickRemove} ghost loading={isRemoving}>
            Enable user
          </RemoveButton>
        </RemoveContainer>
      )}
    </Table.ContentRow>
  )
}

export default ListTableContentRow
