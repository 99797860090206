import React, { FC } from 'react'
import { SEOProps } from '../../strings/PrivacyStatementContent'
import { UL, OL, TitleWrapper } from '../../components'
import { Container, H1, H4, P, Line } from '../Styles'

type Props = {
  isModal?: boolean
}

const PrivacyStatementContent: FC<Props> = ({ isModal }) => {
  return (
    <>
      <TitleWrapper title={SEOProps.title} metaTags={SEOProps.metaTags} />
      <Container isModal={isModal}>
        <H1 textAlign="center" isModal={isModal}>
          Privacy Statement for KONE API Portal
        </H1>
        {!isModal && <Line />}
        <P>
          This Privacy Statement provides general information on the processing of certain below specified personal data
          by KONE Corporation and its employees, partners, subsidiaries and affiliates (<strong>“KONE”</strong>) in
          connection with KONE API Portal (the <strong>“Portal”</strong>).
        </P>
        <H4>1. Data controller and contact information</H4>
        <P>
          KONE Corporation
          <br />
          Street Address:
          <br />
          KONE Building
          <br />
          Keilasatama 3, 02150 Espoo, Finland
          <br />
          Telephone: +358 204 751
          <br />
          e-mail: personaldatarequest@kone.com
        </P>
        <H4>2. Personal data</H4>
        <P>Following personal data may be collected and processed in connection with the Portal:</P>
        <UL>
          <li>Name, e-mail address</li>
          <li>Job title, company</li>
          <li>User account ID & password</li>
          <li>
            Log information: log-in and log-out time, timestamp of actions taken within the Portal, technical logs of
            the operations of the portal (e.g. error codes) which may be linked with your user ID
          </li>
          <li>Feedback you provide to KONE</li>
        </UL>
        <P>
          KONE may also combine this personal data with business related information, like information about the
          end-customer to whose benefit you are using the KONE application interfaces (the <strong>“KONE APIs”</strong>)
          made available to you through the Portal or the data flowing through the KONE APIs (the{' '}
          <strong>“API Data”</strong>), as well as information about the integrated solution that uses the KONE APIs and
          API Data and you or your organization offer or manage.
        </P>
        <H4>3. The purposes of the processing</H4>
        <P>Your personal data is collected and processed:</P>
        <OL type="I">
          <li>To manage user rights of the Portal</li>
          <li>To manage operability and security of the Portal</li>
          <li>To enable troubleshooting and incident resolving</li>
          <li>
            To provide KONE material and testing data and methodologies for your use in the Portal (the{' '}
            <strong>“KONE Material”</strong>), and provide you access to KONE APIs and API Data (KONE Material, KONE
            APIs and KONE Data all hereinafter together the <strong>“KONE Property”</strong>)
          </li>
          <li>
            To enable development of the Portal and KONE Property and enhancement of the user experience within the
            Portal
          </li>
          <li>
            To enable communications between you and KONE, including but not limited to necessary communications
            relating to operations of the Portal and KONE API and other business or marketing communications (as allowed
            by applicable laws)
          </li>
          <li>To enable KONE expand and maintain a partner ecosystem</li>
          <li>
            For service and product development purposes (for these purposes KONE strives to use aggregated or otherwise
            anonymized data where possible)
          </li>
          <li>
            To ensure proper use of the Portal, KONE Property and the passwords and/or username granted for you for
            accessing the API Portal (the <strong>“Credentials”</strong>)
          </li>
          <li>
            To ensure compliance with the T&C and potential other agreements that govern your use of the KONE Property
          </li>
          <li>
            Performance of the Terms & Conditions for KONE API Portal (the <strong>“T&C”</strong>)
          </li>
        </OL>
        <P>KONE may further aggregate and anonymize the personal data to use it for business analytics purposes.</P>
        <P>
          Processing is necessary for performance of the T&C and for the purposes of the legitimate interests pursued by
          KONE. The legitimate interests consist of the abovementioned processing purposes i-x.
        </P>
        <P>
          As allowed or required by applicable laws, KONE may as use personal data in legal and administrative
          proceedings or for regulatory compliance, e.g. for fulfilling a request for a resolution or information by
          authorities, in submissions for court or for the establishment, exercise or defense of legal claims.
        </P>
        <P>
          If you do not want to provide personal data listed above or later requests the personal data to be deleted,
          this may result in a situation where you no longer can access or use the Portal or KONE Property or KONE is
          not able to provide you support related to KONE APIs.
        </P>
        <H4>4. Source(s) from which the personal data originate</H4>
        <P>
          The personal data is obtained from you directly when you register to the Portal and generated through your use
          of the Portal.
        </P>
        <H4>5. Transfers of personal data</H4>
        <P>
          The Portal is hosted by external service provider who may have access to the personal data, but only for the
          purposes of providing hosting or other services related to the Portal. From time to time, KONE may use also
          other external parties to e.g. update the Portal or KONE Property or provide IT support or development related
          to the Portal or KONE Property, and for these purposes transfer the personal data to such service providers.
          KONE shall limit the possibility of such external parties to use personal data only to what is necessary for
          the performance of the respective services.
        </P>
        <P>
          Due to the technical and practical requirements, some of the personal data may be processed also outside the
          country of where the Portal user resides (including outside EU/EEA) by other KONE companies or KONE’s
          subcontractors. KONE ensures that there is a legal basis for such transfers, and you can get more information
          about the data transfers by contacting KONE.
        </P>
        <P>
          The transfer of Personal Data between KONE companies or between KONE and a subcontractor for purposes of
          performing the services, does not constitute the selling, renting, releasing, disclosing, disseminating,
          making available, transferring, or otherwise communicating orally, in writing, or by electronic means the
          personal data for monetary or other valuable consideration.
        </P>
        <H4>6. Retention period of the personal data</H4>
        <P>
          The personal data will be stored as long as you have access rights to the Portal, or the data is otherwise
          needed for the abovementioned purposes. The log data may be stored for up to 1 year after the date it was
          generated.
        </P>
        <H4>7. Data subjects’ rights</H4>
        <P>
          You have a right to review the personal data collected on you, right to have incorrect personal data corrected
          and right to request deletion of personal data. You may also have a right to object to processing based on a
          specific individual circumstance and request data portability and right to object marketing. You may use these
          rights by contacting KONE Data Privacy Counsel, Legal Affairs, personaldatarequest@kone.com.
        </P>
        <P>
          In the event you have concerns or remarks regarding legality of Personal Data processing, you may lodge a
          complaint with a competent local data protection supervisory authority or pursue to enforce your legal rights
          in the absence in jurisdictions without such data protection supervisory authority.
        </P>
      </Container>
    </>
  )
}

export default PrivacyStatementContent
