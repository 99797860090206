import React, { ErrorInfo } from 'react'
import ErrorPage from '../pages/ErrorPage'

type Props = {
  props?: any
  withMargin?: boolean
}

type ErrorProps = {
  hasError?: any
}

class ErrorBoundary extends React.Component<Props, ErrorProps> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorProps {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error: ', error, errorInfo)
    if (window.onerror) {
      window.onerror(errorInfo.componentStack, undefined, undefined, undefined, error)
    }
  }

  render(): any {
    if (this.state.hasError) {
      return <ErrorPage withMargin={this.props.withMargin} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
