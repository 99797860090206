import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogMay2 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        May 2024 - 2.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        May 2.0 release notes summarizes latest changes for Equipment Status API and API Portal documentation fix.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Equipment Status API</li>
          <UL>
            <li>
              <q>serviceMode</q> field is now available in{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#availability-event'}>
                Equipment Availability Information Webhook API.
              </Link>
            </li>
            <li>
              <q>activeAlertCount</q> and <q>activeAlerts</q> fields are removed from
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentAvailabilityByIds'}>
                {' '}
                Equipment Availability REST API.
              </Link>{' '}
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Webhook Portal documenatation updated for{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path}>Equipment Status API</Link>.
              <UL>
                <li>The Equipment Info object is updated based on the response from the webhook.</li>
              </UL>
              <UL>
                <li>
                  Updated the API title to maintain consistency in both the table of contents and each API explanation
                  section.
                </li>
              </UL>
              <UL>
                <li>Added a table below each API to showcase which equipment is supported by each API.</li>{' '}
              </UL>
            </li>
            <li>
              The response payload of the Authentication API-{' '}
              <Link to={Paths.AuthenticationAPIV2.path + '#describeToken'}>Describe JWT token</Link> has been updated
              with additional fields.
            </li>
            <li>
              Portal view of Create and Update <Link to={Paths.Dashboard.path}> Webhook subscription</Link> is updated
              according to API Type.{' '}
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
