import { Col, Row } from 'antd'
import React, { FC } from 'react'
import useScrollToHash from '../../../shared/utils/scrollToHash'
import { Authentication } from './sections/Authentication'
import { Introduction } from './sections/Introduction'
import { TableOfContents } from './sections/TableOfContents'
import { UsageExample } from './sections/UsageExample'
import { LiftCall } from './sections/WebsocketRequests'
import { CommonCommands } from './sections/WebSocketCommonRequests'
import { MonitoringRequests } from './sections/WebSocketMonitoringRequests'

export interface AsyncDocumentationProps {
  asyncApiProps: AsyncApiProps
}
export interface AsyncApiProps {
  apiName: string
  scopePrefix: string
  callType: string
  introText: string
}

export const elevatorCallApiV2: AsyncApiProps = {
  apiName: 'Elevator WebSocket API 2',
  scopePrefix: 'callgiving',
  callType: 'normal',
  introText:
    'With Elevator WebSocket API you can implement elevator call-giving features in the applications of your choice. Through Elevator Call API, ' +
    'an application can execute elevator calls and receive real-time data about the call and the assigned elevator.',
}

export const serviceRobotApiV2: AsyncApiProps = {
  apiName: 'Elevator WebSocket API 2',
  scopePrefix: 'robotcall',
  callType: 'robot',
  introText:
    'With Elevator WebSocket API you can implement elevator call-giving features in the applications of your choice. Through Service Robot API, ' +
    'an application can execute elevator calls and receive real-time data about the call and the assigned elevator. ' +
    'Robots can use door hold feature to hold open the door for smooth entry and exit to the elevator car.',
}

const AsyncDocumentationV2: FC<AsyncDocumentationProps> = ({ asyncApiProps }) => {
  useScrollToHash()

  return (
    <Row>
      {/* Everything is wrapped into one column, within that, rows with 2 columns each */}
      <Col span={24}>
        <Introduction asyncApiProps={asyncApiProps} />

        <TableOfContents asyncApiProps={asyncApiProps} />

        <Authentication asyncApiProps={asyncApiProps} />

        <UsageExample asyncApiProps={asyncApiProps} />

        <LiftCall asyncApiProps={asyncApiProps} />

        <CommonCommands />
        <MonitoringRequests />
      </Col>
    </Row>
  )
}

export default AsyncDocumentationV2
