import React, { FC, useEffect, useState } from 'react'
import { useAuth } from '../../../../shared/contexts/authContext'
import { MFAStrings } from '../../../../shared/strings/MFAContent'
import { MfaSetting } from '../../../../shared/models/mfa'
import { Toast, MfaCommonApps } from '../../../../shared/components'
import MFASelection from '../MFASelection'
import {
  AccountCreatedText,
  MFAEnableText,
  MFADisableText,
  MFAQuestionText,
  AddExtraSecurityText,
  OnDisableReducesSecurityText,
  MFAInfoContainer,
  IconContainer,
  LockIcon,
  IconHide,
  IconExpand,
  MoreDetailsTitle,
  StyledLine,
  ButtonContainer,
  NoThanksButton,
  SetupMFAButton,
  StepBox,
  StepsText,
  Step1Svg,
  Step2Svg,
  Step3Svg,
} from './Styles'

type Props = {
  setting: MfaSetting
  callbackFn?: any
}

const MFAInfo: FC<Props> = ({ setting, callbackFn }) => {
  const [isIconExpand, setIsIconExpand] = useState(true)
  const { setIsMfaNeeded, setupTotp, mfaCode, resetMfaCode } = useAuth()
  const [setupTotpError, setIsSetupToptError] = useState<Error | undefined>()
  const [isSetupTotpErrorVisible, setIsSetupToptErrorVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isMfaDisabled, setIsMfaDisabled] = useState(false)

  useEffect(() => {
    setIsMfaDisabled(false)
    resetMfaCode()
  }, [])

  const onNoThanksClick = () => {
    setIsMfaNeeded(false)
  }

  const onMfaSetupClick = async () => {
    setIsLoading(true)
    try {
      await setupTotp()
      setIsMfaDisabled(false)
    } catch (e) {
      console.error(e)
      setIsSetupToptError(e)
      setIsSetupToptErrorVisible(true)
      setIsLoading(false)
    }
  }

  const onMfaDisable = async () => {
    setIsMfaDisabled(true)
  }

  const getHeader = () => {
    switch (setting) {
      case MfaSetting.REGISTRATION:
        return <AccountCreatedText>{MFAStrings.accountCreated}</AccountCreatedText>
      case MfaSetting.PROFILE_ENABLE:
        return <MFAEnableText>{MFAStrings.twoFactorNotEnabled}</MFAEnableText>
      case MfaSetting.PROFILE_DISABLE:
        return <MFADisableText>{MFAStrings.contactAdmin}</MFADisableText>
      default:
        return null
    }
  }

  const getExtraInfoText = () => {
    switch (setting) {
      case MfaSetting.REGISTRATION:
        return (
          <MFAQuestionText>
            {MFAStrings.userQuestionToAddMfa}
            <br></br>
            {MFAStrings.enableThisLaterAlsoFromProfileText}
          </MFAQuestionText>
        )
      case MfaSetting.PROFILE_ENABLE:
        return <AddExtraSecurityText>{MFAStrings.addExtraSecurityText}</AddExtraSecurityText>
      case MfaSetting.PROFILE_DISABLE:
        return null
      default:
        return null
    }
  }

  const getButtons = () => {
    if (setting === MfaSetting.PROFILE_DISABLE) {
      return null
    }

    return (
      <>
        <ButtonContainer>
          {setting === MfaSetting.REGISTRATION && (
            <NoThanksButton ghost={true} onClick={onNoThanksClick} size="large" key="back">
              {MFAStrings.noThanks}
            </NoThanksButton>
          )}
          <SetupMFAButton onClick={onMfaSetupClick} type="primary" size="large" loading={isLoading}>
            {MFAStrings.setupMfa}
          </SetupMFAButton>
        </ButtonContainer>
        <MfaCommonApps />
      </>
    )
  }

  const getMoreDetailsOnHowitWorks = () => {
    if (setting === MfaSetting.PROFILE_DISABLE) {
      return null
    }

    return (
      <>
        {!isIconExpand && (
          <>
            <StyledLine />
            <StepBox>
              <Step1Svg />
              <StepsText size="small">{MFAStrings.howItWorksStep1}</StepsText>
            </StepBox>
            <StepBox>
              <Step2Svg />
              <StepsText size="small">{MFAStrings.howItWorksStep2}</StepsText>
            </StepBox>
            <StepBox>
              <Step3Svg />
              <StepsText size="small">{MFAStrings.howItWorksStep3}</StepsText>
            </StepBox>
            <StyledLine />
          </>
        )}
        <MoreDetailsTitle onClick={() => setIsIconExpand(!isIconExpand)}>
          {isIconExpand ? (
            <>
              {MFAStrings.howItWorks} <IconExpand />
            </>
          ) : (
            <>
              {MFAStrings.hideDetails} <IconHide />
            </>
          )}
        </MoreDetailsTitle>
      </>
    )
  }

  return (
    <MFAInfoContainer>
      {!isMfaDisabled ? (
        <>
          {!mfaCode ? (
            <>
              <Toast
                key="setup-totp-error"
                isVisible={isSetupTotpErrorVisible}
                text={setupTotpError?.message}
                onDismiss={() => setIsSetupToptErrorVisible(false)}
                type="error"
              />
              <IconContainer>
                <LockIcon />
              </IconContainer>
              {getHeader()}
              {getExtraInfoText()}
              {getMoreDetailsOnHowitWorks()}
              {getButtons()}
            </>
          ) : (
            <MFASelection setting={setting} callbackFn={callbackFn} data={mfaCode} />
          )}
        </>
      ) : (
        <MFASelection setting={setting} callbackFn={callbackFn} />
      )}
    </MFAInfoContainer>
  )
}

export default MFAInfo
