import { TierPlanList } from '../models/tierPlan'
import { API } from './API'
import { ORGANIZATIONS_PATH } from './organizations'

const queryNames = {
  GET_BY_ORG_ID: 'tierplans-get-by-orgId-',
}

const getDefaultTierByOrganizationId = async (orgId: number): Promise<TierPlanList> => {
  return await API.get(`${ORGANIZATIONS_PATH}/${orgId}/tiers`)
}

const saveTier = async (orgId: number, productName: string, tier: string): Promise<void> => {
  const parameters = {
    body: { productName, tier },
  }
  return await API.patch(`${ORGANIZATIONS_PATH}/${orgId}/tiers`, parameters)
}

export const TierPlanAPI = {
  queryNames,
  getDefaultTierByOrganizationId,
  saveTier,
}
