import { RestAPI as AmplifyAPI } from '@aws-amplify/api-rest'
import { DOWNLOAD_API_NAME } from './constants'

const getDownloadURL = async (fileName: string): Promise<string> => {
  const encodedFileName = encodeURIComponent(fileName)
  const { downloadUrl } = await AmplifyAPI.get(DOWNLOAD_API_NAME, `/${encodedFileName}`, {})
  return downloadUrl
}

export const DownloadsAPI = {
  getDownloadURL,
}
