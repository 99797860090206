import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { ErrorMessage, Toast } from '../../../shared/components'
import { BuildingId, PREFIX_BUILDING_ID, RefreshResponse } from '../../../shared/models/building'
import { BuildingsStrings } from '../../../shared/strings/BuildingsContent'
import { ApiError } from '../../../shared/models/error'
import { BuildingsAPI } from '../../../shared/api/buildings'
import { ButtonsContainer, CancelButton, CreateButton, FormLabel, InputId, Line } from './Styles'
import { BID_REG, BID_MAX_LENGTH, BID_MIN_LENGTH } from '../../../shared/constants/auth'

type Props = {
  onCancelCreate: () => void
}

type FormData = {
  buildingId: BuildingId
}

const ImportBuildingForm: FC<Props> = ({ onCancelCreate }) => {
  const [isShowCreatedNotification, setIsShowCreatedNotification] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      buildingId: '',
    },
  })
  const { mutateAsync: refreshBuildingConfiguration, isLoading } = useMutation<RefreshResponse, ApiError, BuildingId>(
    BuildingsAPI.refreshBuildingConfiguration
  )
  const queryClient = useQueryClient()
  const [isRefreshErrorVisible, setIsRefreshErrorVisible] = useState(false)
  const [refreshErrorMessage, setRefreshErrorMessage] = useState()

  const hideToastNotifications = () => {
    setIsShowCreatedNotification(false)
    setIsRefreshErrorVisible(false)
  }

  const onSubmit = async (data: FormData) => {
    hideToastNotifications()

    try {
      const buildingId = data.buildingId.startsWith(PREFIX_BUILDING_ID)
        ? data.buildingId
        : `${PREFIX_BUILDING_ID}${data.buildingId}`

      await refreshBuildingConfiguration(buildingId)
      queryClient.invalidateQueries(BuildingsAPI.queryNames.LIST)
      setIsShowCreatedNotification(true)
      reset()
    } catch (err) {
      console.error(err)
      setRefreshErrorMessage(err.response?.data?.message ?? BuildingsStrings.refreshConfigurationError)
      setIsRefreshErrorVisible(true)
    }
  }

  return (
    <div>
      <Toast
        key="import-success"
        isVisible={isShowCreatedNotification && !isLoading}
        text="Building import requested!"
        onDismiss={() => setIsShowCreatedNotification(false)}
        type="success"
      />
      <Toast
        key="import-error"
        isVisible={isRefreshErrorVisible}
        text={refreshErrorMessage}
        onDismiss={() => setIsRefreshErrorVisible(false)}
        type="error"
      />
      <form id="import-building-form-js" onSubmit={handleSubmit(onSubmit)}>
        <FormLabel htmlFor="building-input-js" id="building-input-label-js">
          Building ID
        </FormLabel>
        <Controller
          render={({ field }) => (
            <InputId
              id="building-input-js"
              {...field}
              placeholder="Building ID with or without the 'building:' -prefix"
            />
          )}
          name="buildingId"
          control={control}
          rules={{
            required: true,
            pattern: {
              value: BID_REG,
              message: BuildingsStrings.invalidBuildingId,
            },
            maxLength: { value: BID_MAX_LENGTH, message: BuildingsStrings.buildingIdLengthError },
            minLength: { value: BID_MIN_LENGTH, message: BuildingsStrings.buildingIdLengthError },
          }}
        />
        {errors.buildingId && (
          <ErrorMessage>{errors.buildingId.message || BuildingsStrings.buildingIdRequired}</ErrorMessage>
        )}
        <Line />
        <ButtonsContainer>
          <CancelButton id="cancel-import-building-button-js" onClick={onCancelCreate} ghost disabled={isLoading}>
            Cancel
          </CancelButton>
          <CreateButton
            id="import-building-button-js"
            type="primary"
            htmlType="submit"
            disabled={!isDirty}
            loading={isLoading}
          >
            Import building
          </CreateButton>
        </ButtonsContainer>
      </form>
    </div>
  )
}

export default ImportBuildingForm
