import styled from 'styled-components'
import { color, size } from '../../shared/utils/styles'

export const FooterContainer = styled.div`
  text-align: center;
  position: relative;
  background-color: ${color.gray85};
  justify-content: center;
  align-items: center;

  @media (min-width: ${size.large}) {
    display: flex;
    height: 88px;
  }

  @media print {
    display: none;
  }
`

export const FooterLinkText = styled.div`
  font: normal normal normal 11px/18px KONE Information;
  padding: 10px;

  a {
    color: ${color.white};
  }

  a:hover {
    color: ${color.linkHoverColorBlue};
  }

  @media (min-width: ${size.large}) {
    width: 226px;
    padding: 0px;
    font: normal normal normal 14px/18px KONE Information;
  }
`
