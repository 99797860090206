import React from 'react'
import ReactECharts from 'echarts-for-react'
import { orgTypeLegend, productLegend } from '../../../../../shared/strings/StatisticDashboard'

interface BarChartProps {
  selectedOrgs: string[]
  selectedProducts: string[]
  product: any
}

const BarChart: React.FC<BarChartProps> = ({ selectedOrgs, selectedProducts, product }) => {
  const colors = ['#0071b9', '#6e99b4', '#e3e5e6']

  let xAxisData: string[] = []
  let seriesData: any[] = []

  xAxisData = selectedProducts.map((prod) => productLegend[prod] || prod)

  seriesData = selectedOrgs.map((orgType, index) => ({
    name: orgTypeLegend[orgType] || orgType,
    type: 'bar',
    data: selectedProducts.map((prod) => product?.productsWiseOrgnizations?.[prod]?.[orgType.toLowerCase()] ?? 0),
    itemStyle: {
      color: colors[index % colors.length],
    },
    barGap: '0%',
    barWidth: '15%',
    label: {
      show: true,
      position: 'top',
      color: '#e3e5e6',
      fontSize: 12,
      formatter: (params: { value: number }) => (params.value > 0 ? params.value : ''),
    },
  }))

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '6%',
      right: '1%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      name: 'API Products',
      nameLocation: 'center',
      nameTextStyle: {
        color: '#e3e5e6',
        fontSize: 14,
        padding: 20,
      },
      axisLabel: {
        color: '#e3e5e6',
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: '#e3e5e6',
          width: 2,
        },
      },
      axisTick: {
        alignWithLabel: true,
      },
      categoryGap: '2%',
    },
    yAxis: {
      type: 'value',
      name: 'API Product Count',
      nameTextStyle: {
        color: '#e3e5e6',
        fontSize: 14,
        // padding: 20,
      },
      axisLabel: {
        color: '#e3e5e6',
        formatter: '{value}',
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#e3e5e6',
          width: 2,
        },
      },
      splitLine: {
        show: false,
      },
      minInterval: 1,
      splitNumber: 5,
    },
    series: seriesData,
  }

  return (
    <>
      <ReactECharts option={option} style={{ height: '400px', width: '100%' }} />
    </>
  )
}

export default BarChart
