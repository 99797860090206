import styled from 'styled-components'
import Button from '../../../../shared/components/Button'

export const Description = styled.div`
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 34px;
`

export const StyledForm = styled.form`
  margin-bottom: 20px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
`

export const SendResetLinkButton = styled(Button)`
  height: 40px;
  width: 160px;
`

export const CancelButton = styled(SendResetLinkButton)`
  margin-right: 30px;
`
