import { OpenAPIV3 } from 'openapi-types'
import React, { FC } from 'react'
import RestDocumentation from '../RestDocumentation'

const ServiceInfoAPIDocumentation: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const openAPISpec = require(`./service-info-api.json`) as OpenAPIV3.Document

  return <RestDocumentation apiName="service-info-api" openAPISpec={openAPISpec} />
}

export default ServiceInfoAPIDocumentation
