import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { ErrorMessage } from '../../../../shared/components'
import { CreateFormStrings } from '../../../../shared/strings/PartnersContent'
import { Solution } from '../../Models'
import {
  InputColumnRight,
  RemoveIconSolution,
  SolutionContainerItem,
  SolutionRemoveContainer,
  StyledLine,
  FormLabel,
  InputColumn,
  InputName,
  Editor,
  EditorColumn,
  InputColumnSecond,
  TypeSelector,
} from './Styles'

import { masterData } from '../../../../shared/models/salespage'
const { Option } = TypeSelector
const OneSolution: FC<{
  index: number
  solution: Solution
  onInputChange: (e: any, index: number, name: string) => void
  onSelectChange: (e: any, index: number, name: string) => void
  removeSolution: (index: number) => void
  formErr?: any
  masterData: masterData
}> = (props) => {
  const {
    index: currentSolutionIndex,
    solution,
    onInputChange,
    onSelectChange,
    formErr,
    removeSolution,
    masterData,
  } = props

  const getAPIs = () => {
    return masterData?.api?.map((apiItem: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${apiItem?.value}_${index}`} id={apiItem?.value} value={apiItem?.value}>
          {apiItem?.label}
        </Option>
      )
    })
  }

  const getSegments = () => {
    return masterData?.segments?.map((item: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${item?.value}_${index}`} id={item?.value} value={item?.value}>
          {item?.label}
        </Option>
      )
    })
  }

  const getCountryList = () => {
    return masterData?.country?.map((item: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${item?.value}_${index}`} id={item?.value} value={item?.value}>
          {item?.label}
        </Option>
      )
    })
  }

  const getCategoryList = () => {
    return masterData?.category?.map((item: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${item?.value}_${index}`} id={item?.value} value={item?.value}>
          {item?.label}
        </Option>
      )
    })
  }

  const filterOption = (input: any, option: any) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    }
    return false
  }

  return (
    <>
      <SolutionContainerItem>
        <Row gutter={24}>
          <Col span={24}>
            {currentSolutionIndex > 0 && (
              <SolutionRemoveContainer>
                <RemoveIconSolution onClick={() => removeSolution(currentSolutionIndex)} />
              </SolutionRemoveContainer>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <FormLabel>{CreateFormStrings.solutionName}*</FormLabel>
            <InputName
              id={`solutionName${currentSolutionIndex}`}
              placeholder={'Enter Solution Name'}
              value={solution?.solutionName}
              onChange={(e: any) => onInputChange(e, currentSolutionIndex, 'solutionName')}
            />
            {formErr?.length > 0 && formErr[currentSolutionIndex]?.solutionName?.error && (
              <ErrorMessage>{formErr[currentSolutionIndex]?.solutionName?.message}</ErrorMessage>
            )}
          </Col>
          <Col span={8}>
            <InputColumn>
              <FormLabel>{CreateFormStrings.selectApi}*</FormLabel>
              <TypeSelector
                showSearch
                mode="multiple"
                id="select-apis-selector-js"
                placeholder={CreateFormStrings.selectApi}
                onChange={(e: any) => onSelectChange(e, currentSolutionIndex, 'apis')}
                disabled={false}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                optionFilterProp="children"
                filterOption={filterOption}
                value={solution?.apis}
              >
                {getAPIs()}
              </TypeSelector>
              {formErr?.length > 0 && formErr[currentSolutionIndex]?.apis?.error && (
                <ErrorMessage>{formErr[currentSolutionIndex]?.apis?.message}</ErrorMessage>
              )}
            </InputColumn>
          </Col>
          <Col span={8}>
            <InputColumnRight>
              <FormLabel>{CreateFormStrings.keyCustomerSegments}*</FormLabel>
              <TypeSelector
                showSearch
                mode="multiple"
                id="key_segments-selector-js"
                placeholder={CreateFormStrings.customerSegments}
                onChange={(e: any) => onSelectChange(e, currentSolutionIndex, 'keyCustomerSegments')}
                disabled={false}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                optionFilterProp="children"
                filterOption={filterOption}
                value={solution?.keyCustomerSegments}
              >
                {getSegments()}
              </TypeSelector>
              {formErr?.length > 0 && formErr[currentSolutionIndex]?.keyCustomerSegments?.error && (
                <ErrorMessage>{formErr[currentSolutionIndex]?.keyCustomerSegments?.message}</ErrorMessage>
              )}
            </InputColumnRight>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <InputColumnSecond>
              <FormLabel>{CreateFormStrings.country}*</FormLabel>
              <TypeSelector
                showSearch
                mode="multiple"
                id="country-selector-js"
                placeholder={CreateFormStrings.country}
                onChange={(e: any) => onSelectChange(e, currentSolutionIndex, 'country')}
                disabled={false}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                optionFilterProp="children"
                filterOption={filterOption}
                value={solution?.country}
              >
                {getCountryList()}
              </TypeSelector>
              {formErr?.length > 0 && formErr[currentSolutionIndex]?.country?.error && (
                <ErrorMessage>{formErr[currentSolutionIndex]?.country?.message}</ErrorMessage>
              )}
            </InputColumnSecond>
          </Col>
          <Col span={8}>
            <InputColumnSecond>
              <FormLabel>{CreateFormStrings.selectCategory}*</FormLabel>
              <TypeSelector
                showSearch
                mode="multiple"
                id="category-selector-js"
                placeholder={CreateFormStrings.selectCategory}
                onChange={(e: any) => onSelectChange(e, currentSolutionIndex, 'category')}
                disabled={false}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                optionFilterProp="children"
                filterOption={filterOption}
                value={solution?.category}
              >
                {getCategoryList()}
              </TypeSelector>
              {formErr?.length > 0 && formErr[currentSolutionIndex]?.category?.error && (
                <ErrorMessage>{formErr[currentSolutionIndex]?.category?.message}</ErrorMessage>
              )}
            </InputColumnSecond>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={24}>
          <Col span={23}>
            <EditorColumn>
              <FormLabel htmlFor="app-desc-input-js">{CreateFormStrings.solutionHighlight}*</FormLabel>
              <Editor
                theme="snow"
                value={solution?.solutionHighlights}
                onChange={(e: any) => onSelectChange(e, currentSolutionIndex, 'solutionHighlights')}
              />
              {formErr?.length > 0 && formErr[currentSolutionIndex]?.solutionHighlights?.error && (
                <ErrorMessage>{formErr[currentSolutionIndex]?.solutionHighlights?.message}</ErrorMessage>
              )}
            </EditorColumn>
          </Col>
        </Row>
      </SolutionContainerItem>
      <StyledLine />
    </>
  )
}

export default OneSolution
