import styled from 'styled-components'

export const Container = styled.div<{ backgroundColor: string }>`
  position: relative;
  width: 452px;
  min-height: 344px;
  padding: 28px 36px;
  margin: 0 16px 16px 0;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
  align-items: center;
`
