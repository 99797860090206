import { color } from '../../../../shared/utils/styles'
import styled from 'styled-components'
import { Button, Input, Select } from 'antd'
import ReactQuill from 'react-quill'
import { ReactComponent as RemoveIcon } from '../../../../assets/icons_remove.svg'

export const PartnersSolutionSection = styled.div`
  margin-top: 50px;
  margin-bottom: 60px;
`

export const AddMoreSolutionButton = styled(Button)`
  background-color: ${color.blueBase};
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
}  
`

export const InputName = styled(Input)`
  color: ${color.gray75};
  width: 250px;
  margin-right: 3em;
`
export const EditorColumn = styled.div`
  margin-bottom: 50px;
`
export const FormLabel = styled.label`
  margin-bottom: 3px;
  margin-top: 10px;
  display: block;
  text-aligh: left;
`
export const Editor = styled(ReactQuill)`
  border: 1px solid ${color.gray20};
  height: 100px;
  width: 84%;
  margin-bottom: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .ql-container {
    background-color: white;
    color: black;
    overflow: visible;
    overflow-y: auto;
    max-height: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ql-formats {
    color: white;
  }

  .ql-toolbar {
    background-color: transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 2px;
    border: 0px solid transparent;
  }
`
export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  margin-top: 10px;
  opacity: 0.4;
  width: 100%;
`

export const ButtonContainer = styled.div`
  height: 10px;
`
export const TypeSelector = styled(Select)`
  width: 250px;
  overflow-y: 'scroll';
`
export const FormButton = styled(Button)`
  margin-right: 10px;
  background-color: ${color.blueBase};
`
export const InputColumn = styled.div`
  //width: 100%;
`

export const InputColumnSecond = styled.div``

export const InputColumnRight = styled.div``

export const SolutionContainerItem = styled.div``
export const SolutionRemoveContainer = styled.div`
  display: flex;
  justify-content: end;
`

export const RemoveIconSolution = styled(RemoveIcon)`
  height: 20px;
  cursor: pointer;
  margin-top: 5px;
  margin-right: 20px;
`
