import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'

export const ReleaseNotesLogFebruary = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        February 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        February release notes summarizes latest changes for Experience API, Service Info API, Equipment Status API and
        API Portal documentation fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Experience API</li>
          <UL>
            <li>
              The confirmation response to hold the car door open for a specific amount of time is now available for car
              door hold open <b>feature</b> in the{' '}
              <Link to={Paths.ElevatorWebsocketAPIRobotsV2.path + '#hold-car-door-open'}> Service Robot API.</Link>
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              <q>serviceContractNumber</q> is now available in{' '}
              <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}>Service Info Webhook API</Link>.{' '}
            </li>
            <li>
              {' '}
              For <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}>Service Info Webhook API</Link>{' '}
              <q>salesOrderNumber</q> is now available for YSM6 Fixed Price Repair Order service order type.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Equipment Status API</li>
          <UL>
            <li>
              Equipment information is now available for Ken with a valid contract in{' '}
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentAvailabilityByIds'}>
                {' '}
                Equipment Availability REST API.{' '}
              </Link>{' '}
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Modes and their descriptions have been corrected and updated for{' '}
              <Link to={Paths.EquipmentStatus2RestApiPageDocs.path + '#getEquipmentMovementByIds'}>
                Movement Completed API REST{' '}
              </Link>{' '}
              and{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#movement-event'}>
                Movement Completed Webhook API{' '}
              </Link>
              .{' '}
            </li>
            <li>
              Transfer floor call response payload added in{' '}
              <Link to={Paths.ElevatorWebsocketAPIV2.path + '#make-an-elevator-call'}>Elevator Call API</Link>.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
