import React from 'react'
import { H4, P, UL, OL } from '../../../../shared/components/Typography'

export const ReleaseNotesLogJuly3 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        July 2023 - 3.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        July 3.0 release notes summarizes updates in the solution validation guides on the API Portal along with
        addition of timestamp in 201 response of Experience APIs.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Documentation guides are updated for v2 APIs in the Solution Validation section of the Solution Creation
              Page.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Elevator Call and Service Robot API</li>
          <UL>
            <li>
              We have temporarily reverted{' '}
              <q>Timeout for Elevator Call and Service Robot API has been decreased from 30 seconds to 15 seconds.</q>
            </li>
            <li>Timestamp now added in the 201 acknowledgement response.</li>
          </UL>
          <li style={{ marginTop: '30px' }}>
            {' '}
            No product level bug fixes applicable and no new feature release for Equipment Status 2 and Service Info 2.
            APIs.
          </li>
        </OL>
      </P>
    </>
  )
}
