import styled from 'styled-components'
import Button from '../../../../shared/components/Button'
import { size } from '../../../../shared/utils/styles'

export const StyledForm = styled.form`
  width: 350px;
  text-align: center;

  @media (max-width: ${size.medium}) {
    width: 300px;
  }
`

export const DescriptionContainer = styled.div`
  text-align: center;
  margin-bottom: 34px;
`

export const VerificationCodeContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    &:focus {
      outline: none !important;
    }
  }
  textarea {
    &:focus {
      outline: none !important;
    }
  }

  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${size.medium}) {
    flex-direction: column;
  }
`

export const ResendButton = styled(Button)`
  min-width: 152px;
  height: 40px;

  @media (max-width: ${size.medium}) {
    margin-bottom: 20px;
  }
`

export const VerifyButton = styled(Button)`
  min-width: 152px;
  height: 40px;
`

export const props = {
  width: '28px',
  height: '32px',
  fontSize: '20pt',
  color: 'black',
  borderRadius: '4px',
  border: '1.5px solid #0071B9',
  margin: '4px',
  opacity: '1',
  paddingLeft: '6px',
}
