import React, { FC } from 'react'
import { TABLE_CONTENT_LENGTH } from '../../../shared/constants/common'
import {
  NextActiveArrow,
  PreviousActiveArrow,
  NextPassiveArrow,
  PreviousPassiveArrow,
  LastInactiveArrow,
  FirstInactiveArrow,
  LastActiveArrow,
  FirstActiveArrow,
  TablePagination,
  VerticalLineRight,
  AlignArrows,
  VerticalLineLeft,
  PaginationContainer,
} from './Styles'

type Props = {
  jumpToFirstPage: () => void
  onPrevPage: () => void
  onNextPage: () => void
  jumpToLastPage: () => void
  page: number
  maxPage: number
  data?: any
}

const Pagination: FC<Props> = ({ jumpToFirstPage, onPrevPage, onNextPage, jumpToLastPage, page, maxPage, data }) => {
  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return page === 0 ? (
        <AlignArrows>
          <FirstInactiveArrow />
          <VerticalLineLeft />
          <PreviousPassiveArrow />
        </AlignArrows>
      ) : (
        <AlignArrows>
          <FirstActiveArrow onClick={jumpToFirstPage} />
          <VerticalLineLeft />
          <PreviousActiveArrow onClick={onPrevPage} />
        </AlignArrows>
      )
    }

    if (type === 'next') {
      return page === maxPage - 1 ? (
        <AlignArrows>
          <NextPassiveArrow />
          <VerticalLineRight></VerticalLineRight>
          <LastInactiveArrow />
        </AlignArrows>
      ) : (
        <AlignArrows>
          <NextActiveArrow onClick={onNextPage} />
          <VerticalLineRight></VerticalLineRight>
          <LastActiveArrow onClick={jumpToLastPage} />
        </AlignArrows>
      )
    }
    return originalElement
  }

  return (
    <PaginationContainer>
      <TablePagination
        simple
        defaultCurrent={1}
        total={data.length}
        pageSize={TABLE_CONTENT_LENGTH}
        itemRender={itemRender}
        current={page + 1}
      />
    </PaginationContainer>
  )
}

export default Pagination
