import styled, { css } from 'styled-components'
import { Button } from '../../../../shared/components'
import { Progress, Select } from 'antd'
import { color } from '../../../../shared/utils/styles'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputsContainer = styled.div`
  margin-top: 10px;
`

export const ResourceButtonContainer = styled.div`
  display: flex;
`

export const CancelResourceAddButton = styled(Button)`
  width: 80px;
  margin-top: 15px;
  margin-left: 20px;
`

export const SaveButton = styled(Button)`
  width: 80px;
  padding: 0px;
  margin-top: 15px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 30px;
`

export const SearchButton = styled(Button)`
  width: 140px;
  padding: 0px;
`

export const AddManuallyButton = styled(Button)`
  width: 120px;
  padding: 0px;
`

export const Line = styled.hr`
  margin-top: 34px;
  margin-bottom: 48px;
  border: 1px solid #3d464c;
  opacity: 1;
`

export const LineTop = styled.hr`
  margin-top: 30px;
  margin-bottom: 20px;
  border: 0.5px solid #3d464c;
  opacity: 1;
`

export const TypeSelector = styled(Select)`
  width: 150px;
`

export const MessageContainer = styled.p`
  margin: 0;
  max-width: 100%;
`

export const BidInputContainer = styled.div`
  width: 150px;
`

export const MultiInputContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 200px;
`

export const ProductSelectionBase = styled.div`
  .ant-select-arrow {
    top: 30%;
    right: 18px;
    color: #0071b9;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 34px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    bottom: 2px;
    font-size: 16px;
  }
`

export const ProductExpiryContianer = styled.div``

export const ProductSelectionContainer = styled(ProductSelectionBase)<{ $isMultiInput?: boolean }>`
  margin-left: ${(props) => (props.$isMultiInput ? '0px' : '20px')};
  width: 250px;
`

export const InputRowContainer = styled.div<{ $isMultiInput?: boolean }>`
  display: flex;
  margin-bottom: 10px;
  margin-top: 15px;
  justify-content: ${(props) => (props.$isMultiInput ? 'space-between' : 'unset')};
`

export const SearchDeviceInputCol = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    &:focus {
      outline: none !important;
    }
  }
  textarea {
    &:focus {
      outline: none !important;
    }
  }
  flex-basis: 30%;
  margin-top: 0px;
`

export const SwitchLabel = styled.label`
  font-size: 16px;
  margin-right: 5px;
`

export const SwitchContainer = styled.div`
  display: flex;
  margin-left: 15px;
  align-items: center;
`

export const DatePickerContainer = styled.div`
  margin-left: 10px;
`

export const CancelButton = styled(Button)`
  width: 250px;
`
export const UpdateCounter = styled.div`
  width: 28px;
  margin-left: 4px;
  border: 1px solid ${color.gray40};
  text-align: center;
`

export const UpdateCounterBuilding = styled.div`
  width: 36px;
  border: 1px solid ${color.gray40};
  margin-left: 3px;
`

export const WarningLabel = styled.label`
  margin-bottom: 1px;
  font-size: 13px;
  color: ${color.gray20};
`

export const BackButtonContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`

const rowCommonStyle = css`
  color: ${color.gray10};
  max-width: 1388px;
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
`

export const Row = styled.div`
  ${rowCommonStyle};
  background-color: ${color.gray85};
  min-height: 48px;
  margin-bottom: 2px;
  border-radius: 4px;
  display: flex;
`

export const RowContentBase = styled.div`
  padding-right: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`
export const KenProgressBar = styled(Progress)`
  .ant-progress-text {
    color: white;
  }
`
export const OkButton = styled(Button)`
  margin: 1vh 0vh;
`
