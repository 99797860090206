import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { ApiError } from '../../../shared/models/error'
import { ApplicationsAPI } from '../../../shared/api/applications'
import { ErrorMessage, Toast } from '../../../shared/components'
import { useAuth } from '../../../shared/contexts/authContext'
import { CustomerApp } from '../../../shared/models/application'
import {
  ButtonsContainer,
  ButtonsContainerContact,
  CancelButton,
  ContactButton,
  ContactLink,
  CreateButton,
  ExternalLinkIcon,
  FormElement,
  FormLabel,
  FormWrapper,
  IconContainer,
  InputContainer,
  InputDescription,
  InputName,
  NoProductionText,
  RadioGroup,
  SingleRadioButton,
} from './Styles'
import { CreateFormStrings } from '../../../shared/strings/ApplicationsContent'
import { GeneralFormStrings } from '../../../shared/strings/GeneralFormContent'
import { APP_NAME_MAX_LENGTH, APP_NAME_MIN_LENGTH, APP_NAME_REG } from '../../../shared/constants/auth'

type Props = {
  onCancelCreate: () => void
  onCreateApplicationSuccess: (clientId: string) => void
}

const CreateForm: FC<Props> = ({ onCancelCreate, onCreateApplicationSuccess }) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors: formErrors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
    },
  })
  const { currentOrganization } = useAuth()
  const [isSandbox, setIsSandbox] = useState(true)
  const { mutateAsync: createApplication, isLoading } = useMutation<CustomerApp, ApiError, CustomerApp>(
    ApplicationsAPI.create
  )
  const queryClient = useQueryClient()
  const [isCreateAppErrorVisible, setIsCreateAppErrorVisible] = useState(false)
  const [errorToDisplay, setErrorToDisplay] = useState<string>('')

  const onSubmit = async (data: CustomerApp) => {
    setIsCreateAppErrorVisible(false)

    try {
      const name = data.name
      const description = data.description
      const savedData = await createApplication({ name, description, sandbox: isSandbox })
      queryClient.invalidateQueries([ApplicationsAPI.queryNames.GET_ALL, currentOrganization!.externalId])
      onCreateApplicationSuccess(savedData.clientId!)
      reset()
    } catch (err: any) {
      switch (err.response.data.message) {
        case CreateFormStrings.createApplicationErrorLimitSandboxPersonal:
          setErrorToDisplay(CreateFormStrings.createApplicationErrorLimitSandboxPersonal)
          break
        case 'you can have maximum 10 production application per organization':
          setErrorToDisplay(CreateFormStrings.createApplicationErrorLimitProd)
          break
        case CreateFormStrings.createApplicationErrorLimitSandboxExt:
          setErrorToDisplay(CreateFormStrings.createApplicationErrorLimitSandboxExt)
          break
        default:
          setErrorToDisplay(CreateFormStrings.createApplicationError)
      }
      setIsCreateAppErrorVisible(true)
    }
  }

  const onChange = (e: any) => {
    setIsSandbox(e.target.value)
  }

  if (!isSandbox && currentOrganization!.type === 'personal') {
    return (
      <FormWrapper>
        <RadioGroup onChange={onChange} value={isSandbox}>
          <SingleRadioButton id="sandbox-radio-button-js" value={true}>
            {CreateFormStrings.sandBoxRadioButton}
          </SingleRadioButton>
          <SingleRadioButton id="production-radio-button-js" value={false}>
            {CreateFormStrings.productionRadioButton}
          </SingleRadioButton>
        </RadioGroup>
        <NoProductionText id="no-production-access-message-js">{CreateFormStrings.noProductionText}</NoProductionText>
        <ButtonsContainerContact>
          <ContactLink href="https://www.kone.com/en/contact.aspx" target="_blank" rel="noreferrer">
            <IconContainer>
              <ContactButton>{CreateFormStrings.contactUsButton}</ContactButton>
              <ExternalLinkIcon />
            </IconContainer>
          </ContactLink>
        </ButtonsContainerContact>
      </FormWrapper>
    )
  }

  return (
    <FormWrapper>
      <Toast
        isVisible={isCreateAppErrorVisible}
        text={errorToDisplay}
        onDismiss={() => setIsCreateAppErrorVisible(false)}
        type="error"
      />
      <RadioGroup onChange={onChange} value={isSandbox}>
        <SingleRadioButton id="sandbox-radio-button-js" value={true}>
          {CreateFormStrings.sandBoxRadioButton}
        </SingleRadioButton>
        <SingleRadioButton id="production-radio-button-js" value={false}>
          {CreateFormStrings.productionRadioButton}
        </SingleRadioButton>
      </RadioGroup>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputContainer>
          <FormElement>
            <FormLabel htmlFor="app-name-input-js">{CreateFormStrings.appName}</FormLabel>
            <Controller
              render={({ field }) => <InputName id="app-name-input-js" {...field} />}
              name="name"
              control={control}
              rules={{
                required: true,
                maxLength: { value: APP_NAME_MAX_LENGTH, message: CreateFormStrings.appNameLengthError },
                minLength: { value: APP_NAME_MIN_LENGTH, message: CreateFormStrings.appNameLengthError },
                pattern: { value: APP_NAME_REG, message: CreateFormStrings.appNameWhiteSpaceError },
              }}
            />
            {formErrors.name && (
              <ErrorMessage>{formErrors.name.message || CreateFormStrings.appNameRequiredError}</ErrorMessage>
            )}
          </FormElement>

          <FormElement>
            <FormLabel htmlFor="app-desc-input-js">{CreateFormStrings.appDescription}</FormLabel>
            <Controller
              render={({ field }) => <InputDescription id="app-desc-input-js" {...field} />}
              name="description"
              control={control}
              rules={{
                required: false,
              }}
            />
          </FormElement>
        </InputContainer>
        <ButtonsContainer>
          <CancelButton id="cancel-create-app-button-js" onClick={onCancelCreate} ghost disabled={false}>
            {GeneralFormStrings.cancel}
          </CancelButton>
          <CreateButton
            id="create-app-button-js"
            type="primary"
            htmlType="submit"
            disabled={!isDirty}
            loading={isLoading}
          >
            {CreateFormStrings.createApplication}
          </CreateButton>
        </ButtonsContainer>
      </form>
    </FormWrapper>
  )
}

export default CreateForm
