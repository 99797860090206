import React, { FC } from 'react'
import { StyleLoading } from './Styles'
import { ReactComponent as Loading } from '../../../assets/loading-logo.svg'

const LoadingComponent: FC = () => {
  return (
    <StyleLoading>
      <Loading />
    </StyleLoading>
  )
}

export default LoadingComponent
