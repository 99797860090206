import React from 'react'
import { H4, P, UL, OL } from '../../../../shared/components/Typography'

export const ReleaseNotesLogJune = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        June 2023
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        June release notes summarizes bug fixes and improvements to existing products.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li>Service Info API</li>
          <UL>
            <li>
              Removed customer account Id dependency from Service Info 2 REST and is now accessible without adding it.
            </li>
            <li>Fixed empty workOrderStatusCode in Service Info 2 API response with correct value.</li>
          </UL>

          <li style={{ marginTop: '30px' }}>Equipment Status API 2</li>
          <UL>
            <li>
              Removed customer account Id dependency for Equipment Status 2 REST and is now accessible without adding
              it.
            </li>
            <li>
              customerEquipmentNumber is now available in the equipmentInfo of webhook response for all the event types.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Flags are now added besides the equipment id in the Resource section of Organization Details for the
              customers.
            </li>
            <li>
              {' '}
              Flag color will notify the expiry of activation status for the equipment.
              <ul>
                {' '}
                <li>Red Flag indicates the product associated with the id has expired.</li>
                <li>
                  Orange Flag indicates the product associated with the id is about to expire in or less than 30 days.
                </li>
                <li>
                  Yellow Flag indicates the product associated with the id is about to expire in or less than 60 days.
                </li>
              </ul>
            </li>
            <li>
              Select All checkbox is added while selecting equipments during creating and editing webhook subscription.
            </li>
            <li>
              NOTE: Existing subscriptions for which no equipment were selected (made because all equipment were to be
              used) are now invalid and they will no longer receive webhook traffic. For those applicable, it is
              required to recreate the subscription and use the new <b>Select All</b> button to select and subscribe
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>
            {' '}
            No product level bug fixes applicable and no new feature release for Service Robot and Elevator Call APIs.
          </li>
        </OL>
      </P>
    </>
  )
}
