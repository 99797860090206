import React, { FC } from 'react'
import { Circle, Icon } from './Styles'

type Props = {
  id?: string
  onClick: () => void
  height?: number
  width?: number
}

const BackButton: FC<Props> = ({ id, onClick, ...rest }) => {
  return (
    <Circle id={id} onClick={onClick} {...rest}>
      <Icon />
    </Circle>
  )
}

export default BackButton
