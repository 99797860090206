import { Input, Radio } from 'antd'
import styled from 'styled-components'
import { ReactComponent as ExtLink } from '../../../assets/icons-external-link.svg'
import Button from '../../../shared/components/Button'
import { color } from '../../../shared/utils/styles'

const { TextArea } = Input

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
`

export const FormLabel = styled.label`
  margin-bottom: 8px;
  display: block;
  color: ${color.gray20};
`

export const FormElement = styled.div`
  margin-bottom: 3em;
`

export const InputName = styled(Input)`
  color: ${color.gray75};
  width: 100%;
`

export const InputDescription = styled(TextArea)`
  color: ${color.gray75};
  width: 100%;
`

export const CancelButton = styled(Button)`
  width: 194px;
`

export const CreateButton = styled(Button)`
  width: 208px;
`

export const FormWrapper = styled.div`
  width: 420px;
  margin: 0 auto;
`

export const RadioGroup = styled(Radio.Group)`
  margin-bottom: 40px;
`

export const SingleRadioButton = styled(Radio)`
  margin-right: 38px;
  width: 112px;
  height: 20px;
  color: ${color.gray20};
  font: normal normal normal 16px/24px KONE Information;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const NoProductionText = styled.p`
  text-align: center;
  font: normal normal normal 18px/26px KONE Information;
  color: ${color.gray20};
`

export const ExternalLinkIcon = styled(ExtLink)`
  margin-top: -2px;
`

export const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
  .a {
    fill: ${color.blueBase};
  }
  &:hover {
    p {
      color: ${color.blueTint20};
    }

    .a {
      fill: ${color.blueTint20};
    }
  }
`

export const ContactButton = styled.p`
  border-style: none;
  padding: 0 6px;
  color: ${color.blueBase};
  margin-bottom: 0;
  text-transform: uppercase;
  margin-right: 8px;
`

export const ButtonsContainerContact = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
`

export const ContactLink = styled.a``
