export const GeneralFormStrings = {
  email: 'Email',
  password: 'Password',
  newPassword: 'New Password',
  firstName: 'First name',
  lastName: 'Last name',
  registerButton: 'Register',
  loginButton: 'Log in',
  logout: 'Log out',
  cancel: 'Cancel',
  name: 'Name',
  saveChanges: 'Save changes',
  valueCannotBeEmpty: 'Value cannot be empty',
  readMore: 'Read more',
  loading: 'Loading...',
  account: 'Account',
  cancelAndReturnToDashboard: 'Cancel and return to dashboard',
}
