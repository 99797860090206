import { Invitation } from '../models/invitation'
import { API } from './API'

const PATH_PREFIX = '/customer/me'

const queryNames = {
  GET_ALL: 'invitations-get-all',
}

const getAll = async (): Promise<Invitation[]> => {
  return await API.get(`${PATH_PREFIX}/invitations`)
}

const accept = async (invitationId: string): Promise<void> => {
  await API.post(`${PATH_PREFIX}/invitations/${invitationId}`)
}

const decline = async (invitationId: string): Promise<void> => {
  await API.remove(`${PATH_PREFIX}/invitations/${invitationId}`)
}

export const InvitationsAPI = {
  queryNames,
  getAll,
  accept,
  decline,
}
