import { Col, Row } from 'antd'
import React, { FC, useState } from 'react'
import { DownloadsAPI } from '../../../shared/api/downloads'
import { Button, H2, H3, P, Toast, UL } from '../../../shared/components'

type DownloadFileButtonProps = {
  fileName: string
  buttonName: string
  onDownloadStart: () => void
  onDownloadFailed: () => void
}

const DownloadFileButton: FC<DownloadFileButtonProps> = ({
  fileName,
  buttonName,
  onDownloadStart,
  onDownloadFailed,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const onDownloadClick = async () => {
    setIsLoading(true)
    onDownloadStart()

    try {
      const downloadUrl = await DownloadsAPI.getDownloadURL(fileName)

      const temporaryLink = document.createElement('a')
      temporaryLink.setAttribute('href', downloadUrl)
      temporaryLink.setAttribute('download', fileName)

      temporaryLink.style.display = 'none'
      document.body.appendChild(temporaryLink)

      temporaryLink.click()

      document.body.removeChild(temporaryLink)
    } catch (err) {
      console.error(err)
      onDownloadFailed()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <p>
      <Button type="primary" onClick={onDownloadClick} loading={isLoading}>
        {buttonName}
      </Button>
    </p>
  )
}

const ProductionAccess: FC = () => {
  const [isShowDownloadError, setIsShowDownloadError] = useState(false)

  const onDownloadStart = () => {
    setIsShowDownloadError(false)
  }

  const onDownloadFailed = () => {
    setIsShowDownloadError(true)
  }

  return (
    <Row>
      <Toast
        key="download-error"
        isVisible={isShowDownloadError}
        text="Download file error. Please try again."
        onDismiss={() => setIsShowDownloadError(false)}
        type="error"
      />
      <Col md={24} lg={24} xl={16}>
        <H2>Access to KONE equipment and data through APIs </H2>
        <H3>Prerequisites </H3>
        <P size="small">
          KONE API Services add value to KONE equipment and maintenance service. Production access means that your
          application gets connected to certain KONE elevators or maintenance service data. For this reason, access to
          KONE data or equipment through KONE APIs is available for those applications that are used by{' '}
          <b>KONE maintenance service customers with KONE API Services contract</b>.
        </P>
        <P size="small">
          <a href="https://www.kone.com/en/contact.aspx" rel="noreferrer" target="_blank">
            Local KONE sales
          </a>{' '}
          assist in questions about the available KONE equipment, maintenance service and KONE API Services offering and
          pricing.
        </P>
        <H3>Contact KONE API Support to request production access</H3>
        <P size="small">
          Once you are ready with developing and testing your application in our Sandbox, you can request production
          access. KONE API Support helps you with the activities needed to gain access to the correct KONE equipment or
          data. Access to production is granted to an authorized KONE API organization.
        </P>
        <P size="small">
          You can request the creation of a KONE API organization and access to production via email at{' '}
          <a href="mailto:api-support@kone.com">api-support@kone.com</a>. Include in your email:
          <UL size="small">
            <li>Your organization information (organization name and key contact information)</li>
            <li>
              Information about the KONE customer with maintenance service and KONE API Services contract (name,
              country, other information if known)
            </li>
            <li>
              API(s) that your request concerns. If your request concerns Elevator Call API or Service Robot API,
              include the location of the building(s).
            </li>
            <li>Email addresses of those members that you wish to add to your KONE API organization</li>
          </UL>
        </P>
        <P size="small">
          After checking the validity of your request, KONE API Support creates the organization with requested API
          access and adds the listed members.
        </P>
        <P size="small">You will receive confirmation when your organization is created.</P>
        <H3>Create and test production application</H3>
        <P size="small">
          After receiving the access, you can continue to develop and test your application in the production
          environment.
        </P>
        <P size="small">
          For solutions using Elevator Call API and Service Robot API, verifying the full operation with a proper
          elevator simulator equipment is additionally needed. KONE API support grants the access to elevator simulator.{' '}
        </P>
        <P size="small">
          From below you can download security and validation test documents that guide you through the verification of
          your solution:
        </P>
        <DownloadFileButton
          fileName="KONE API Services security and privacy self-assessment form.xlsx"
          buttonName="Download security and privacy self assessment form"
          onDownloadStart={onDownloadStart}
          onDownloadFailed={onDownloadFailed}
        />
        <DownloadFileButton
          fileName="Elevator Call API solution validation test guide.docx"
          buttonName="Download elevator call API solution validation test guide"
          onDownloadStart={onDownloadStart}
          onDownloadFailed={onDownloadFailed}
        />
        <DownloadFileButton
          fileName="Service Robot API solution validation test guide.docx"
          buttonName="Download service robot API solution validation test guide"
          onDownloadStart={onDownloadStart}
          onDownloadFailed={onDownloadFailed}
        />
        <DownloadFileButton
          fileName="Equipment Status API solution validation test guide.docx"
          buttonName="Download equipment status API solution validation test guide"
          onDownloadStart={onDownloadStart}
          onDownloadFailed={onDownloadFailed}
        />
        <DownloadFileButton
          fileName="Service Info API solution validation test guide.docx"
          buttonName="Download service info API solution validation test guide"
          onDownloadStart={onDownloadStart}
          onDownloadFailed={onDownloadFailed}
        />
      </Col>
    </Row>
  )
}

export default ProductionAccess
