import { color, size } from '../../../../shared/utils/styles'
import styled from 'styled-components'
import { ReactComponent as Lock } from '../../../../assets/icons-locked.svg'
import { ReactComponent as ArrowUp } from '../../../../assets/icons-sort-chevron-up.svg'
import { ReactComponent as ArrowDown } from '../../../../assets/icons-sort-chevron-down.svg'
import { ReactComponent as MFAStep1 } from '../../../../assets/icons-mfa-step1.svg'
import { ReactComponent as MFAStep2 } from '../../../../assets/icons-mfa-step2.svg'
import { ReactComponent as MFAStep3 } from '../../../../assets/icons-mfa-step3.svg'
import { Button, H6, H5, P, H4 } from '../../../../shared/components'

export const MFAInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
}
`

export const AccountCreatedText = styled(H4)`
  margin-top: 0;
  margin-bottom: 34px;
`

export const MFADisableText = styled(H5)`
  margin-top: 40px;
  margin-bottom: 0px;
  text-align: center;
`

export const MFAEnableText = styled(H5)`
  margin-top: 40px;
  margin-bottom: 44px;
`

export const IconContainer = styled.div`
  svg .a {
    fill: ${color.gray10};
  }
`

export const LockIcon = styled(Lock)`
  width: 95px;
  height: 100%;
`

export const MFAQuestionText = styled(H6)`
  padding: 0 95px;
  text-align: center;
`

export const AddExtraSecurityText = styled(H6)`
  padding: 0 150px;
  text-align: center;
`

export const OnDisableReducesSecurityText = styled(H6)`
  padding: 0 160px;
  text-align: center;
  margin-bottom: 50px;
`

export const MFADescriptionText = styled(H6)`
  text-align: center;
`

export const StepsText = styled(P)`
  text-align: left;
  padding: 0 146px 0 20px;
`

export const StepBox = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0;
`

export const Step1Svg = styled(MFAStep1)`
  width: 422px;
  height: 96px;
`

export const Step2Svg = styled(MFAStep2)`
  width: 310px;
  height: 110px;
`

export const Step3Svg = styled(MFAStep3)`
  width: 215px;
  height: 96px;
`

export const IconExpand = styled(ArrowDown)`
  cursor: pointer;
  vertical-align: middle;
`

export const IconHide = styled(ArrowUp)`
  cursor: pointer;
  vertical-align: middle;
`

export const MoreDetailsTitle = styled(H6)`
  cursor: pointer;
  text-align: center;
  color: ${color.lightBlue};
  margin-bottom: 42px;
  margin-top: 9px;
  padding-left: 20px;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  opacity: 0.4;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 75%;

  @media (max-width: ${size.medium}) {
    flex-direction: column;
  }
`

export const NoThanksButton = styled(Button)`
  font-size: 16px;
  padding: 0 16px;

  @media (max-width: ${size.medium}) {
    margin-bottom: 10px;
  }
`

export const SetupMFAButton = styled(Button)`
  font-size: 16px;
  padding: 0 32px;
`

export const CommonlyUsedAppsHeading = styled.div`
  margin-top: 35px;
  font-size: 14px;
`

export const CommonlyUsedAppsText = styled.div`
  color: ${color.lightBlue};
  font-size: 14px;
`
