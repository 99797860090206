import React, { FC, useState } from 'react'
import { Steps } from 'antd'
import ClientInfo from '../ClientInfo'
import CreateForm from '../CreateForm'
import GenerateSecret from '../GenerateSecret'
import { SingleStep, WizardContainer, ErrorIconStyled, ErrorContainer } from './Styles'
import { CreateWizardStrings } from '../../../shared/strings/ApplicationsContent'

type Props = {
  onCancelCreate: () => void
}

const CreateWizard: FC<Props> = ({ onCancelCreate }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [clientId, setClientId] = useState<string>()
  const [clientSecret, setClientSecret] = useState<string>()
  const [wizardStatus, setWizardStatus] = useState<any>('process')

  const onCreateApplicationSuccess = (clientId: string) => {
    setCurrentStep(1)
    setClientId(clientId)
  }

  const onSecretGenerationSuccess = (clientSecret: string) => {
    setWizardStatus('process')
    setClientSecret(clientSecret)
    setCurrentStep(2)
  }

  const onError = () => {
    setWizardStatus('error')
  }

  return (
    <>
      <WizardContainer>
        <Steps size="small" current={currentStep} status={wizardStatus}>
          <SingleStep
            title={CreateWizardStrings.createAppStep}
            description={CreateWizardStrings.createAppDescription}
          />
          <SingleStep
            title={CreateWizardStrings.generateSecret}
            description={CreateWizardStrings.generateSecretDescription}
            icon={
              wizardStatus === 'error' ? (
                <ErrorContainer>
                  <ErrorIconStyled />
                </ErrorContainer>
              ) : (
                ''
              )
            }
          />
          <SingleStep
            title={CreateWizardStrings.appCreatedTitle}
            description={CreateWizardStrings.appCreatedDescription}
          />
        </Steps>
      </WizardContainer>
      {currentStep === 0 && (
        <CreateForm onCreateApplicationSuccess={onCreateApplicationSuccess} onCancelCreate={onCancelCreate} />
      )}
      {currentStep === 1 && clientId && (
        <GenerateSecret
          onSecretGenerationSuccess={onSecretGenerationSuccess}
          onError={onError}
          clientId={clientId}
          onCancelCreate={onCancelCreate}
        />
      )}
      {currentStep === 2 && (
        <ClientInfo clientId={clientId} clientSecret={clientSecret} onCancelCreate={onCancelCreate} />
      )}
    </>
  )
}

export default CreateWizard
