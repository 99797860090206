import { EmailData } from '../models/auth'
import axios from 'axios'

const SEND_EMAIL_PATH = '/api/support'

const sendEmail = async (data: EmailData): Promise<any> => {
  const response = await axios(SEND_EMAIL_PATH, {
    method: 'POST',
    data: JSON.stringify(data),
  })

  if (response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new Error()
}

export const SendEmailAPI = {
  sendEmail,
}
