import React, { createContext, ReactNode, useState } from 'react'
import { masterData } from '../../shared/models/salespage'

const defaultData = {
  masterData: {},
}

export const MasterDataContext = createContext<any | null>(defaultData)
const MasterDataProvider: React.FC<ReactNode> = ({ children }) => {
  const [masterData, setMasterData] = useState<masterData>({})

  return <MasterDataContext.Provider value={{ masterData, setMasterData }}>{children}</MasterDataContext.Provider>
}

export default MasterDataProvider
