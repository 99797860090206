import { ReactComponent as Remove } from '../../../assets/icon-remove.svg'
import { color } from '../../../shared/utils/styles'
import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 22px;
  justify-content: center;
  text-align: center;
`

export const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
  &:hover {
    p {
      color: ${color.blueTint20};
    }

    .a {
      fill: ${color.blueTint20};
    }
  }
`

export const RemoveButton = styled.p`
  border-style: none;
  padding: 0 6px;
  color: ${color.blueBase};
  margin-bottom: 0;
  text-transform: uppercase;
`

export const RemoveButtonInactive = styled.p`
  border-style: none;
  padding: 0 6px;
  color: ${color.gray60};
  margin-bottom: 0;
  text-transform: uppercase;
  cursor: default;
`

export const IconContainerInactive = styled.div`
  display: flex;
  color: ${color.gray60};
  cursor: default;
  .a {
    fill: ${color.gray60};
    cursor: default;
  }
  &:hover {
    p {
      color: ${color.gray60};
      cursor: default;
    }

    .a {
      fill: ${color.gray60};
      cursor: default;
    }
  }
`

export const RemoveIcon = styled(Remove)`
  align-self: center;
`
