import { Dropdown } from 'antd'
import React, { FC, useState } from 'react'
import { Organization } from '../../../shared/models/organization'
import Modal from '../../../shared/components/Modal'
import { Button, Toast } from '../../../shared/components'
import {
  DropdownGlobalStyle,
  OrgRowContent,
  OrgRowContentLarge,
  OrgRowContentLastCell,
  Row,
  StyledDropdownMenu,
} from '../Styles'
import RowExtension from './Extension'
import {
  IconExpand,
  IconHide,
  MoreItem,
  ProfileDetails,
  ResourceFlagOrange,
  ResourceFlagRed,
  ResourceFlagYellow,
  RowExpand,
} from './Styles'
import { SharedOrganizationsStrings } from '../../../shared/strings/OrganizationsContent'

type Props = {
  organization: Organization
  onClickEdit: (organization: Organization) => void
  onClickDelete: (organization: Organization) => void
}

const OrganizationsTable: FC<Props> = ({ organization, onClickEdit: onClickEditParentHandler, onClickDelete }) => {
  const [isIconExpand, setIsIconExpand] = useState(true)
  const [isDeleted, setIsDeleted] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string | ''>('')
  const [successMsg, setsuccessMsg] = useState<string | ''>('')

  const [ModalDeleteVisible, setModalDeleteVisible] = useState<boolean | false>(false)

  const getMoreMenuItems = () => {
    return (
      <StyledDropdownMenu>
        <StyledDropdownMenu.Item id="more-menuitem1-js" key="moreMenu1" onClick={onClickEdit}>
          Edit organization details
        </StyledDropdownMenu.Item>
        <StyledDropdownMenu.Item id="more-menuitem2-js" key="moreMenu2" onClick={onHandleDelete}>
          Delete organization
        </StyledDropdownMenu.Item>
        <Modal
          key="remove-delete-modal"
          title="Delete Organization"
          width={672}
          visible={ModalDeleteVisible}
          closable={false}
          footer={[
            <Button
              id="delete-button-js"
              size="large"
              style={{ width: '304px', marginRight: '24px' }}
              key="submit"
              ghost={true}
              onClick={deleteOrganization}
            >
              {`${SharedOrganizationsStrings.deleteButtonText}`}
            </Button>,
            <Button
              id="cancel-remove-resource-button-js"
              size="large"
              style={{ width: '272px' }}
              key="back"
              type="primary"
              onClick={handleCancel}
            >
              {`${SharedOrganizationsStrings.goBackButton}`}
            </Button>,
          ]}
        >
          {`${SharedOrganizationsStrings.deleteOrganization}`}
        </Modal>

        {/* To be enabled once the seperate screen for each edit Item is created
        <StyledDropdownMenu.Item id="more-menuitem2-js" key="moreMenu2" onClick={onClickEdit}>
          Edit members
        </StyledDropdownMenu.Item>
        <StyledDropdownMenu.Item id="more-menuitem3-js" key="moreMenu3" onClick={onClickEdit}>
          Edit resources
        </StyledDropdownMenu.Item>
        <StyledDropdownMenu.Item id="more-menuitem4-js" key="moreMenu4" onClick={onClickEdit}>
          Edit registered APIs
        </StyledDropdownMenu.Item> */}
      </StyledDropdownMenu>
    )
  }

  const handleCancel = () => {
    setModalDeleteVisible(false)
  }

  const deleteOrganization = async () => {
    setModalDeleteVisible(false)
    try {
      const result: any = await onClickDelete(organization)
      if (result == 'Organization deleted successfully') {
        setIsDeleted(true)
        setsuccessMsg(`${result}`)
      } else {
        setIsError(true)
        setErrorMsg(`${result}`)
      }
    } catch (error) {
      setIsError(true)
      setErrorMsg(`Error while deleting organization`)
    }
  }

  const onHandleDelete = async () => {
    setModalDeleteVisible(true)
  }
  const onClickEdit = () => {
    onClickEditParentHandler(organization)
  }

  const getOrgFlag = (organization: Organization) => {
    /**
     * yellow < 60 , > 30
    orange < 30
    red < 0
    * **/
    if (organization?.resourcesInRed) {
      return <ResourceFlagRed />
    }

    if (organization?.resourcesInOrange) {
      return <ResourceFlagOrange />
    }

    if (organization?.resourcesInYellow) {
      return <ResourceFlagYellow />
    }

    return null
  }

  return (
    <ProfileDetails>
      <Toast
        key="delete-success"
        isVisible={isDeleted}
        text={successMsg}
        onDismiss={() => setIsDeleted(false)}
        type="success"
        allowWithTime={true}
      />
      <Toast
        key="delete-error"
        isVisible={isError}
        text={errorMsg}
        onDismiss={() => setIsError(false)}
        type="error"
        allowWithTime={true}
      />
      <Row>
        <RowExpand onClick={() => setIsIconExpand(!isIconExpand)}>
          {isIconExpand ? (
            <>
              <IconExpand />
            </>
          ) : (
            <>
              <IconHide />
            </>
          )}
        </RowExpand>
        <OrgRowContent>{organization.salesforceAccountId}</OrgRowContent>
        <OrgRowContentLarge id="tile-info-organization-name-js">
          {organization.name}
          {getOrgFlag(organization)}
        </OrgRowContentLarge>
        <OrgRowContent>{organization.type}</OrgRowContent>
        <OrgRowContent>{organization.createdBy}</OrgRowContent>
        <OrgRowContent>{new Date(organization.createdAt).toISOString().substring(0, 10)}</OrgRowContent>
        <OrgRowContent>
          <OrgRowContentLastCell>
            <DropdownGlobalStyle />
            <Dropdown overlay={getMoreMenuItems()} placement="bottomRight" trigger={['click']}>
              <MoreItem id="open-edit-org-button-js" />
            </Dropdown>
          </OrgRowContentLastCell>
        </OrgRowContent>
      </Row>
      {!isIconExpand && <RowExtension organization={organization} />}
    </ProfileDetails>
  )
}

export default OrganizationsTable
