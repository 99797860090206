type AwsConfig = {
  userPoolId: string
  userPoolWebClientId: string
  cognito: string
  ADProviderName: string
  environment: string
  featureFlags: string[]
  serviceBreakMessage?: string
}

const configText = window.document.getElementById('conf')?.textContent
const awsConfig: AwsConfig = JSON.parse(configText!)

export default awsConfig
