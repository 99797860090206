import React, { FC } from 'react'
import { H4, Tile } from '../../../shared/components'
import { color } from '../../../shared/utils/styles'
import { CreateButton, PlusIcon } from './Styles'

type Props = {
  onClickCreate: () => void
}

const BuildingImportTile: FC<Props> = ({ onClickCreate: onClickCreateParentHandler }) => {
  const onClickCreate = () => {
    onClickCreateParentHandler()
  }

  return (
    <Tile backgroundColor={color.gray85}>
      <PlusIcon />
      <H4>Import new building</H4>
      <CreateButton id="open-import-building-button-js" onClick={onClickCreate} type="primary" size="large">
        Import building
      </CreateButton>
    </Tile>
  )
}

export default BuildingImportTile
