import { masterData } from '../models/salespage'
import { BackendTokenAPI } from './backend'

export const SALESPAGE_PATH = '/api/admin/salespage/partner'
export const SALESPAGE_ADD_INTERGRATOR_PATH = '/api/admin/salespage/addPartner'
export const SALESPAGE_EDIT_INTERGRATOR_PATH = '/api/admin/salespage/partner'
export const SALESPAGE_PUBLIC_PATH = '/api/salespage/solution'
export const SALESPAGE_MASTER_DATA = '/api/salespage/masterData'

const queryNames = {
  GET_ALL: 'partners-get-all',
  GET_PARTNER_DETIALS: 'partners-get-details',
}

const publicQueryNames = {
  GET_ALL: 'get-all-solutions',
  GET_SOLUTION_DETIALS: 'solution-get-details',
}

const commonQueryName = {
  GET_MASTER_DATA: 'get-master-data',
}

const ADMIN_SCOPE = ['admin/common']

const getAllPartners = async (): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(SALESPAGE_PATH, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    throw new Error(`Failed fetching integrator list: ${response.body ?? response.status}`)
  }
  return (await response.json()) as any
}

// TODO:: replace any with specific data type
const getPartnerDetails = async (id: string): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(`${SALESPAGE_PATH}/${id}`, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    throw new Error(`Failed fetching integrator Details: ${response.body ?? response.status}`)
  }
  return (await response.json()) as any // here replace any
}

const addIntergrator = async (formData: any): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(SALESPAGE_ADD_INTERGRATOR_PATH, {
    method: 'POST',
    headers: authHeader,
    body: JSON.stringify(formData),
  })
  return response
}

const editIntegrator = async (formData: any, partnerId: string): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(`${SALESPAGE_EDIT_INTERGRATOR_PATH}/${partnerId}`, {
    method: 'PATCH',
    headers: authHeader,
    body: JSON.stringify(formData),
  })
  return response
}

export const deletePartner = async (partnerId: string): Promise<any> => {
  const accessToken = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(`${SALESPAGE_EDIT_INTERGRATOR_PATH}/${partnerId}`, {
    method: 'DELETE',
    headers: accessToken,
  })
  return response
}

/**
 *  Public UI APIs.
 *  No Authentication Needed
 **/

const getAllSolutionList = async (queryStr: string, body?: any): Promise<any> => {
  const response = await fetch(`${SALESPAGE_PUBLIC_PATH}?${queryStr}`, {
    method: 'POST',
    body: body ? JSON.stringify(body) : null,
  })
  return response.json()
}

const getSolutionDetails = async (solutionId: string): Promise<any> => {
  const response = await fetch(`${SALESPAGE_PUBLIC_PATH}/${solutionId}`, {
    method: 'GET',
  })
  return response.json()
}

/**
 * Master Data API
 * Common API used for both Public and Admin side
 */
const getMasterData = async (): Promise<masterData> => {
  const response = await fetch(`${SALESPAGE_MASTER_DATA}`, {
    method: 'GET',
  })
  return response.json()
}

// TODO:: SalesPage should be renamed with Salespage (considered as one word in other parts of code)
export const SalesPageAPI = {
  queryNames,
  getAllPartners,
  getPartnerDetails,
  addIntergrator,
  editIntegrator,
  deletePartner,
}

export const SalespagePublicAPI = {
  publicQueryNames,
  getAllSolutionList,
  getSolutionDetails,
}

export const CommonAPI = {
  commonQueryName,
  getMasterData,
}
