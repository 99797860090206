import React, { FC, useState } from 'react'
import { Steps } from 'antd'
import SolutionCreationLanding from '../SolutionCreationLandingDocumentation'
import NewSolutionDevelopment from '../SolutionDevelopmentDocumentation'
import SolutionDeployment from '../SolutionDeploymentDocumentation'
import SolutionValidation from '../SolutionValidation'

import {
  SingleStep,
  WizardContainer,
  ButtonContainer,
  HeadingContainer,
  StepContainer,
  ContentContainer,
  NextActiveArrow,
  PreviousActiveArrow,
  NextPassiveArrow,
  PreviousPassiveArrow,
  ButtonContainer2,
  HeadingText,
  Title,
} from './Styles'

const StepComponent: FC = () => {
  const [currentStep, setCurrentStep] = useState(-1)
  const [wizardStatus, setWizardStatus] = useState<any>('process')

  const LandingAPIDocumentation = () => {
    return <SolutionCreationLanding onClick={() => nextClick()} />
  }
  const FirstAPIDocumentation = () => {
    return <NewSolutionDevelopment />
  }
  const SecondAPIDocumentation = () => {
    return <SolutionValidation />
  }
  const ThirdAPIDocumentation = () => {
    return <SolutionDeployment />
  }

  const previousClick = () => {
    setCurrentStep(currentStep - 1)
  }
  const nextClick = () => {
    setCurrentStep(currentStep + 1)
  }

  const stepClick = (step: number) => {
    setCurrentStep(step)
  }

  return (
    <ContentContainer>
      <HeadingContainer>
        {currentStep === -1 ? <HeadingText>Solution Creation Overview</HeadingText> : ''}
      </HeadingContainer>
      {currentStep !== -1 && (
        <WizardContainer>
          <StepContainer>
            <ButtonContainer>
              {currentStep > -1 ? <PreviousActiveArrow onClick={previousClick} /> : <PreviousPassiveArrow />}
            </ButtonContainer>
            <Steps size="default" current={currentStep} status={wizardStatus}>
              <SingleStep
                onStepClick={stepClick}
                title={<Title>Solution Development</Title>}
                status={currentStep < 0 ? 'wait' : 'process'}
              />
              <SingleStep
                onStepClick={stepClick}
                title={<Title>Solution Validation</Title>}
                status={currentStep < 1 ? 'wait' : 'process'}
              />
              <SingleStep onStepClick={stepClick} title={<Title>Solution Deployment</Title>} />
            </Steps>
            <ButtonContainer2>
              {currentStep < 2 ? <NextActiveArrow onClick={nextClick} /> : <NextPassiveArrow />}
            </ButtonContainer2>
          </StepContainer>
        </WizardContainer>
      )}
      {currentStep === -1 && LandingAPIDocumentation()}
      {currentStep === 0 && FirstAPIDocumentation()}
      {currentStep === 1 && SecondAPIDocumentation()}
      {currentStep === 2 && ThirdAPIDocumentation()}
    </ContentContainer>
  )
}

export default StepComponent
