import { color } from '../../../../shared/utils/styles'
import styled from 'styled-components'
import { ReactComponent as CrossIcon } from '../../../../assets/cross.svg'
import { ReactComponent as PlusArrow } from '../../../../assets/icongraphic.svg'
import Tabs from 'antd/lib/tabs'

import { Button } from '../../../../shared/components'

export const Container = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
`
export const Vl = styled.div`
  border-left: 2px solid ${color.gray80};
  height: 150px;
  padding-left: 20px;
  pagging-right: 20px;
`

export const ButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: end;
`

export const CancelButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 150px;
  margin-top: 2px;
  margin-right: 10px;
  font-weight: 100;
  background-color: transparent;
  color: ${color.white};
  border: 1px solid ${color.white};
  cursor: pointer;

  &hover: {
    background-color: transparent;
    color: ${color.white};
  }
`

export const StyledTabs = styled(Tabs)`
  max-width: 1500px;
  color: ${color.gray20};
  border: 1px solid ${color.gray70};

  .ant-tabs-tab {
    margin-right: 50px;
    margin-left: 50px;
    padding-bottom: 5px;
  }

  .ant-tabs-nav-list {
    width: 100%;
    margin-bottom: 20px;

    margin-right: auto;
    background-color: ${color.gray70};
  }

  .ant-tabs-ink-bar {
    background: ${color.blueBase};
    height: 3px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${color.white} !important;
  }
  .ant-tabs-tab:hover {
    color: ${color.white};
  }
  .ant-tabs-tab-btn:active {
    color: ${color.gray10};
  }

  &.ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }

  &.ant-tabs-nav-operations-hidden {
    display: none;
  }
`

export const SaveButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 150px;
  margin-top: 2px;
  font-weight: 100;
  background-color: ${color.blueBase};
  color: ${color.white};
  border: 0px;
  cursor: pointer;

  &hover: {
    background-color: ${color.blueBase};
    color: ${color.white};
  }
`

export const CloseIcon = styled(CrossIcon)`
  height: 10px;
  margin-right: 6px;
  display: inline-block;
  opacity: 1;
`

export const AddMoreVideo = styled(PlusArrow)`
  margin-top: 5px;
  margin-left: -1px;
`

export const FormButton = styled(Button)`
  margin-right: 14px;
  margin-top: 33px;
  background-color: ${color.blueBase};
`
