import React, { FC, useEffect, useState } from 'react'
import { PartnerStrings } from '../../../../shared/strings/PartnersContent'
import Modal from '../../../../shared/components/ModalPopUp'
import { Solution } from '../../Models'
import { Toast } from '../../../../../src/shared/components'
import {
  AddContactButton,
  ButtonContainer,
  CancelButton,
  Container,
  PartnerP,
  RequiredFieldsText,
  SaveButton,
  StyledLine,
} from './Styles'
import OneSolution from './OneSolution'
import { SalesPageAPI } from '../../../../shared/api/salespage'
import { checkDuplicateSolutions } from '../../../../../src/shared/utils/salespage'

export interface EditSolutionInfoProps {
  isEditSolutionInfoOpen: boolean
  closeEditSolutionInfo: () => void
  solutionInfo?: any
  isCreateErrorVisible: boolean | false
  setIsCreateErrorVisible: (data: boolean) => void
  createIntegratorErrorMessage?: string | ''
  setCreateIntegratorErrorMessage: (msg: string) => void
  invalidateIntegratorData: () => void
  setIsSuccess: (data: boolean) => void
  setSuccessMsg: (data: string) => void
}

const EditSolutionInfo: FC<EditSolutionInfoProps> = (props) => {
  const defaultSolution: Solution = {
    solutionName: '',
    solutionHighlights: '',
    keyCustomerSegments: [],
    apis: [],
    country: [],
    category: [],
    solutionId: null,
  }
  const {
    isEditSolutionInfoOpen,
    closeEditSolutionInfo,
    solutionInfo,
    isCreateErrorVisible,
    setIsCreateErrorVisible,
    createIntegratorErrorMessage,
    setCreateIntegratorErrorMessage,
    invalidateIntegratorData,
  } = props
  const [solutionList, setSolutionList] = useState<Solution[]>([])
  const [deleteSolutionIds, setDeleteSolutionIds] = useState<string[]>([])
  const [isSuccessOnSave, setIsSuccessOnSave] = useState<boolean | false>(false)
  const [successMsgOnSave, setSuccessMsgOnSave] = useState<string | ''>('')
  const [isSuccess, setIsSuccess] = useState<boolean | false>(false)
  const [successMsg, setsuccessMsg] = useState<string | ''>('')
  const defaultError = {
    solutionName: {
      error: false,
      message: 'Please enter solution name',
    },
    apis: {
      error: false,
      message: 'Please select an API',
    },
    keyCustomerSegments: {
      error: false,
      message: 'Please select a key customer segment',
    },
    country: {
      error: false,
      message: 'Please select a country',
    },
    category: {
      error: false,
      message: 'Please select a category',
    },
    solutionHighlights: {
      error: false,
      message: 'Please enter Solution Highlights',
    },
  }
  const [formErr, setFormErr] = useState<any>([{ ...defaultError }])

  useEffect(() => {
    const solutionValues: Solution[] = []
    setTimeout(async () => {
      solutionInfo?.map((solution: any, index: number) => {
        solutionValues?.push({
          solutionName: solution?.solutionName,
          apis: solution?.apis,
          category: solution?.category,
          country: solution?.country,
          keyCustomerSegments: solution?.keyCustomerSegments,
          solutionHighlights: solution?.solutionHighlights,
          solutionId: solution?.solutionId,
        })
      })
      await setSolutionList(solutionValues)
    }, 200)
  }, [])

  const onClickHandle = async () => {
    const isValid = validateSolution()
    if (!isValid) {
      return false
    }

    const payload = {
      solutionInfo: solutionList,
      partnerStep: 2,
      deleteSolutionIds,
    }

    const resopnse = await SalesPageAPI.editIntegrator(payload, solutionInfo[0]?.partnerId)
    if (!resopnse.ok) {
      const newRes = await resopnse.json()
      setIsCreateErrorVisible(true)
      setCreateIntegratorErrorMessage(newRes?.message)
    } else if (resopnse.status === 202) {
      setSuccessMsgOnSave('Integrator updated successfully.')
      setIsSuccessOnSave(true)
      setTimeout(() => {
        closeEditSolutionInfo()
        invalidateIntegratorData()
      }, 2000)
    }
    return true
  }

  useEffect(() => {
    if (solutionList) {
      const hasDuplicateSolution = checkDuplicateSolutions(solutionList, 'solutionName')
      if (hasDuplicateSolution) {
        setsuccessMsg(PartnerStrings.uniqueSolutionName)
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
      }
    }
  }, [solutionList])

  const removeSolution = (index: number, solutionId: string | null) => {
    const tempSolutions = [...solutionList]
    tempSolutions.splice(index, 1)
    setSolutionList(tempSolutions)
    if (solutionId) {
      setDeleteSolutionIds([...deleteSolutionIds, solutionId])
    }
  }

  // TODO: this code should be improvised for better efficiency - discuss with the Devs
  const validateCheck = () => {
    const solutionNames = new Set()

    for (let i = 0; i < solutionList.length; i++) {
      const solution = solutionList[i]

      if (
        solution.solutionName === '' ||
        solution.apis === undefined ||
        solution.apis.length === 0 ||
        solution.keyCustomerSegments === undefined ||
        solution.keyCustomerSegments.length === 0 ||
        solution.country === undefined ||
        solution.country.length === 0 ||
        solution.category === undefined ||
        solution.category.length === 0 ||
        solution.solutionHighlights === '' ||
        solution.solutionHighlights === undefined ||
        solutionNames.has(solution.solutionName)
      ) {
        return false
      }

      solutionNames.add(solution.solutionName)
    }

    return true
  }

  const validateSolution = () => {
    const errorFlags: boolean[] = []
    const tempError: any = [...formErr]

    if (solutionList?.length == 0) {
      setIsCreateErrorVisible(true)
      setCreateIntegratorErrorMessage('Please add atleast one solution.')
      return
    } else {
      setIsCreateErrorVisible(false)
      setCreateIntegratorErrorMessage('')
    }

    solutionList?.map((solution: Solution, index: number) => {
      if (!tempError[index]) {
        tempError[index] = { ...defaultError }
      }
      if (!solution?.solutionName || solution?.solutionName?.length == 0) {
        tempError[index].solutionName.error = true
        errorFlags.push(false)
      } else {
        tempError[index].solutionName.error = false
      }

      if (!solution?.apis || solution?.apis?.length == 0) {
        tempError[index].apis.error = true
        errorFlags.push(false)
      } else {
        tempError[index].apis.error = false
      }

      if (!solution?.keyCustomerSegments || solution?.keyCustomerSegments?.length == 0) {
        tempError[index].keyCustomerSegments.error = true
        errorFlags.push(false)
      } else {
        tempError[index].keyCustomerSegments.error = false
      }

      if (!solution?.country || solution?.country?.length == 0) {
        tempError[index].country.error = true
        errorFlags.push(false)
      } else {
        tempError[index].country.error = false
      }

      if (!solution?.category || solution?.category?.length == 0) {
        tempError[index].category.error = true
        errorFlags.push(false)
      } else {
        tempError[index].category.error = false
      }
      if (!solution?.solutionHighlights || solution?.solutionHighlights?.length == 0) {
        tempError[index].solutionHighlights.error = true
        errorFlags.push(false)
      } else {
        tempError[index].solutionHighlights.error = false
      }
    })
    setFormErr(tempError)
    return !errorFlags.includes(false)
  }

  const onSelectChange = (value: any, index: number, key: string) => {
    const tempSolutions: Solution[] = [...solutionList]
    if (value == '<p><br></p>') {
      value = ''
    }
    tempSolutions[index] = { ...tempSolutions[index], [key]: value }
    checkTouched(index, key, value)
    setSolutionList(tempSolutions)
  }

  const checkTouched = (index: number, key: string, value: string) => {
    if (formErr[index]?.[key].error === true && value && value?.length > 0) {
      const newFormErr = [...formErr]
      if (newFormErr[index]?.[key].error) {
        newFormErr[index][key].error = false
        setFormErr(newFormErr)
      }
    }
  }

  const onInputChange = (e: any, index: number, key: string) => {
    const { value } = e.target

    const tempSolutions: Solution[] = [...solutionList]
    tempSolutions[index] = { ...tempSolutions[index], [key]: value }

    checkTouched(index, key, value)
    setSolutionList(tempSolutions)
  }

  const addNewSolution = () => {
    const isValid = validateSolution()
    if (!isValid) {
      return false
    }
    setSolutionList((preState: any) => [...preState, { ...defaultSolution }])
    setFormErr((preState: any) => [...preState, { ...defaultError }])
    return true
  }

  const showSolutionList = () => {
    return solutionList?.map((item: Solution, index: number) => {
      return (
        <OneSolution
          key={`solution_item_${index}`}
          index={index}
          solution={item}
          onInputChange={onInputChange}
          onSelectChange={onSelectChange}
          formErr={formErr}
          removeSolution={removeSolution}
        />
      )
    })
  }

  return (
    <>
      <Modal
        key="edit-solution-info"
        isOpen={isEditSolutionInfoOpen}
        title={PartnerStrings.editSolutionInfo}
        onClose={closeEditSolutionInfo}
        width={'800px'}
        height={'300px'}
      >
        <Container>
          <PartnerP>{PartnerStrings.solutionInformation}</PartnerP>
          <StyledLine />
          <RequiredFieldsText>{PartnerStrings.requireFieldText}</RequiredFieldsText>
          {solutionList?.length > 0 && showSolutionList()}
        </Container>

        <ButtonContainer>
          <AddContactButton onClick={addNewSolution} disabled={!validateCheck()}>
            {PartnerStrings.addOneMoreSolution}
          </AddContactButton>
          <CancelButton onClick={closeEditSolutionInfo}>{PartnerStrings.cancel}</CancelButton>
          <SaveButton onClick={onClickHandle} disabled={!validateCheck()}>
            {PartnerStrings.saveChanges}
          </SaveButton>
        </ButtonContainer>
      </Modal>
      <Toast isVisible={isSuccess} text={successMsg} onDismiss={() => setIsSuccess(false)} type="error" />
      <Toast
        isVisible={isSuccessOnSave}
        text={successMsgOnSave}
        onDismiss={() => setIsSuccessOnSave(false)}
        type="success"
      />
    </>
  )
}

export default EditSolutionInfo
