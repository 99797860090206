import React, { FC, useEffect, useState } from 'react'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useQuery, useQueryClient } from 'react-query'
import { productInfo } from '../../../../shared/constants/product'
import { ProductsAPI } from '../../../../shared/api/products'
import { Organization } from '../../../../shared/models/organization'
import { Toast } from '../../../../shared/components'
import {
  Container,
  ProductListContainer,
  Title,
  ProductCheckbox,
  ProductDescription,
  ButtonsContainer,
  CancelButton,
  SaveButton,
} from './Styles'
import { EditProductsStrings, SharedOrganizationsStrings } from '../../../../shared/strings/OrganizationsContent'
import { GeneralFormStrings } from '../../../../shared/strings/GeneralFormContent'

type ProductItemProps = {
  name: string
  type: string
  description: string
  selected: boolean
  onSelectionChange: (name: string, selected: boolean) => void
}

const ProductItem: FC<ProductItemProps> = ({ name, type, description, selected, onSelectionChange }) => {
  const [checked, setChecked] = useState(selected)

  useEffect(() => {
    setChecked(selected)
  }, [selected])

  const onChange = (e: CheckboxChangeEvent) => {
    onSelectionChange(name, e.target.checked)
  }

  return (
    <>
      <div>
        <ProductCheckbox id="product-checkbox-js" checked={checked} onChange={onChange}>
          {type}
        </ProductCheckbox>
      </div>
      <ProductDescription>{description}</ProductDescription>
    </>
  )
}

type Props = {
  organization: Organization
  onCancelEdit: () => void
}

const EditProductsForm: FC<Props> = ({ organization, onCancelEdit }) => {
  const queryClient = useQueryClient()
  const [isSaving, setIsSaving] = useState(false)
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [isSuccessVisible, setIsSuccessVisible] = useState(false)
  const [initialSelectedProducts, setInitialSelectedProducts] = useState<string[]>([])
  const [selectedProducts, setSelectedProducts] = useState<string[]>([])

  const getProductsByOrgIdQueryName = `${ProductsAPI.queryNames.GET_BY_ORGANIZATION_ID}${organization.id}`
  const { isLoading: isProductNamesByOrgIdLoading, error: productNamesByOrgIdError } = useQuery(
    getProductsByOrgIdQueryName,
    async () => {
      const products = await ProductsAPI.getProductNamesByOrganizationId(organization.id)
      setInitialSelectedProducts([...products])
      setSelectedProducts([...products])
      return products
    }
  )

  const onSelectionChange = (name: string, selected: boolean) => {
    setIsSuccessVisible(false)
    if (selected) {
      setSelectedProducts([...selectedProducts, name])
    } else {
      setSelectedProducts(selectedProducts.filter((n) => n !== name))
    }
  }

  const showProductItems = () => {
    if (isProductNamesByOrgIdLoading) {
      return SharedOrganizationsStrings.loading
    }
    if (productNamesByOrgIdError) {
      return EditProductsStrings.productNamesByOrgIdError
    }

    return productInfo.map((productInfoItem) => {
      const selected = selectedProducts.includes(productInfoItem.name)

      return (
        <ProductItem
          key={productInfoItem.name}
          name={productInfoItem.name}
          type={productInfoItem.type}
          description={productInfoItem.description}
          selected={selected}
          onSelectionChange={onSelectionChange}
        />
      )
    })
  }

  const hideToastNotifications = () => {
    setIsErrorVisible(false)
    setIsSuccessVisible(false)
  }

  const isSaveButtonDisabled = () => {
    if (isProductNamesByOrgIdLoading) {
      return true
    }

    const noChanges =
      selectedProducts.length === initialSelectedProducts.length &&
      selectedProducts.every((val) => initialSelectedProducts.includes(val))

    return noChanges
  }

  const onSave = async () => {
    hideToastNotifications()
    setIsSaving(true)

    try {
      const productNamesToAdd = selectedProducts.filter((name) => !initialSelectedProducts.includes(name))
      const productNamesToRemove = initialSelectedProducts.filter((name) => !selectedProducts.includes(name))

      const requests = []
      requests.push(
        ...productNamesToAdd.map((name) =>
          ProductsAPI.addProduct({ organizationId: organization.id, productName: name })
        )
      )
      requests.push(
        ...productNamesToRemove.map((name) =>
          ProductsAPI.remove({ organizationId: organization.id, productName: name })
        )
      )

      await Promise.all(requests)

      setInitialSelectedProducts([...selectedProducts])
      queryClient.invalidateQueries(getProductsByOrgIdQueryName)
      setIsSuccessVisible(true)
    } catch (err) {
      setIsErrorVisible(true)
      console.error(err)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Container>
      <Toast
        isVisible={isErrorVisible}
        text={EditProductsStrings.changesSavedError}
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <Toast
        isVisible={isSuccessVisible}
        text={EditProductsStrings.changesSavedSuccess}
        textId="edit-resources-success-js"
        onDismiss={() => setIsSuccessVisible(false)}
        type="success"
      />
      <ProductListContainer>
        <Title>{EditProductsStrings.productsListTitle}</Title>
        {showProductItems()}
      </ProductListContainer>
      <ButtonsContainer>
        <CancelButton id="cancel-edit-apis-button-js" onClick={onCancelEdit} ghost disabled={isSaving}>
          {GeneralFormStrings.cancel}
        </CancelButton>
        <SaveButton
          id="edit-apis-button-js"
          type="primary"
          onClick={onSave}
          disabled={isSaveButtonDisabled()}
          loading={isSaving}
        >
          {GeneralFormStrings.saveChanges}
        </SaveButton>
      </ButtonsContainer>
    </Container>
  )
}

export default EditProductsForm
