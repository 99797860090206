export const DeviceMappingStrings = {
  deviceMapping: 'Device Mappings',
  loadingDeviceList: 'Loading device mapping list...',
  deviceListError: 'Cannot fetch device mapping list',
  buildingId: 'Building ID',
  thingName: 'Thing Name',
  clusterId: 'Cluster ID',
  deviceType: 'Device Type',
  thingType: 'Thing Type',
}
