import React, { FC } from 'react'
import { FooterContainer } from './Styles'

interface Props {
  text: string
}

const Footer: FC<Props> = (props: Props) => <FooterContainer>{props.text}</FooterContainer>

export default Footer
