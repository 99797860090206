import pluralize from 'pluralize'
import React, { FC, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { animated, useSpring } from 'react-spring'
import { BuildingsAPI } from '../../../shared/api/buildings'
import { H4, Tile, Toast } from '../../../shared/components'
import { SimplifiedBuilding } from '../../../shared/models/building'
import { BuildingsStrings } from '../../../shared/strings/BuildingsContent'
import { ReactComponent as RefreshIcon } from '../../../assets/icon-reset.svg'
import { color } from '../../../shared/utils/styles'
import { Line, RefreshButton, RefreshContainer, TileInfoContainer } from './Styles'

type Props = {
  building: SimplifiedBuilding
}

const BuildingTile: FC<Props> = ({ building }) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const { mutateAsync: refreshBuildingConfiguration, isLoading } = useMutation(
    BuildingsAPI.refreshBuildingConfiguration
  )
  const queryClient = useQueryClient()

  const onClickRefresh = async (building: SimplifiedBuilding) => {
    setIsErrorVisible(false)

    try {
      await refreshBuildingConfiguration(building.buildingId)
      queryClient.invalidateQueries(BuildingsAPI.queryNames.LIST)
    } catch (err) {
      console.error(err)
      setIsErrorVisible(err)
    }
  }

  const animationProps = useSpring({
    transform: isLoading ? 'rotateZ(0deg)' : 'rotateZ(360deg)',
    config: { duration: 2000 },
  })

  return (
    <Tile backgroundColor={color.gray90}>
      <Toast
        isVisible={isErrorVisible}
        position="absolute"
        text={BuildingsStrings.refreshConfigurationError}
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <RefreshContainer onClick={() => onClickRefresh(building)}>
        <animated.div style={animationProps}>
          <RefreshIcon />
        </animated.div>
        <RefreshButton id="refresh-building-button-js" type="link">
          {BuildingsStrings.refresh}
        </RefreshButton>
      </RefreshContainer>
      <TileInfoContainer>
        <TileInfoContainer>
          <div>
            <H4>{building.buildingId}</H4>
            <Line />
            <div>
              <H4>
                {building.groupCount ?? 'n/a'} {pluralize('groups', building.groupCount ?? 0)}
              </H4>
              <H4>
                {building.areaCount ?? 'n/a'} {pluralize('areas', building.areaCount ?? 0)}
              </H4>
            </div>
            <Line />
            <div>
              <p>
                {BuildingsStrings.lastUpdated}
                <br />
                {building.timestamp}
              </p>
            </div>
          </div>
        </TileInfoContainer>
      </TileInfoContainer>
    </Tile>
  )
}

export default BuildingTile
