import { createGlobalStyle } from 'styled-components'
import { color } from '../../utils/styles'

export const ModalGlobalStyle = createGlobalStyle`
  .ant-modal-content {
    background-color: ${color.gray90};
  }

  .ant-modal-header {
    background: ${color.gray70};
    border-bottom: unset;
    border-radius: unset;
    padding: 16px 32px;

    .ant-modal-title {
      color: ${color.gray20};
      font-size: 24px;
    }
  }

  .ant-modal-body {
    color: ${color.gray20};
    font-size: 24px;
    text-align: center;
    padding: 24px 56px 0px;
  }

  .ant-modal-footer {
    border-top: unset;
    border-radius: unset;
    padding: 32px 32px;
    text-align: center;
    align-items: center;
  }
`
