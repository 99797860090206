import React from 'react'
import ReactECharts from 'echarts-for-react'
import countryLookup from 'country-code-lookup'
import { productLegend } from '../../../../../shared/strings/StatisticDashboard'
import { orderBy, map } from 'lodash'

interface CountryWiseProductsProps {
  selectedProducts: string[]
  data: any
}

const CountryWiseProducts: React.FC<CountryWiseProductsProps> = ({ selectedProducts, data }) => {
  const colors = ['#0071b9', '#338dc7', '#e3e5e6', '#6e99b4', '#aaafb3']

  let productCount = {}

  // Check if data and data.countryWiseProducts exist
  const xAxisData = data && data.countryWiseProducts ? Object.keys(data.countryWiseProducts) : []

  const totalSum = {
    callgiving: 0,
    robotcall: 0,
    serviceinfo: 0,
    rtm: 0,
  }

  for (let i = 0; i < xAxisData.length; i++) {
    if (!['null', undefined, ''].includes(xAxisData[i])) {
      const d = data.countryWiseProducts[xAxisData[i]]
      const countryCode = countryLookup.byIso(xAxisData[i])?.country
      if (countryCode) {
        productCount[countryCode] = Object.assign(data.countryWiseProducts[xAxisData[i]], {
          id: xAxisData[i],
          countryName: countryCode,
          total: d.callgiving + d.robotcall + d.serviceinfo + d.rtm,
        })
        totalSum.callgiving += d.callgiving
        totalSum.robotcall += d.robotcall
        totalSum.serviceinfo += d.serviceinfo
        totalSum.rtm += d.rtm
      }
    }
  }

  productCount = orderBy(productCount, 'total', 'desc').slice(0, 5)
  console.log('total sum', totalSum)

  // Calculate remaining sums other than top 5
  const remainingSum = {
    countryName: 'Others',
    callgiving: totalSum.callgiving - map(productCount, 'callgiving').reduce((a, b) => a + b, 0),
    robotcall: totalSum.robotcall - map(productCount, 'robotcall').reduce((a, b) => a + b, 0),
    serviceinfo: totalSum.serviceinfo - map(productCount, 'serviceinfo').reduce((a, b) => a + b, 0),
    rtm: totalSum.rtm - map(productCount, 'rtm').reduce((a, b) => a + b, 0),
  }

  console.log('remaining sum', remainingSum)

  // Add the "Others" bar only if the sum of remaining products sum is greater than 0
  const checkRemaningCount = Object.values(remainingSum)
    .slice(0)
    .some((value) => value > 0)

  const seriesData = selectedProducts.map((prod, index) => ({
    name: productLegend[prod] || prod,
    type: 'bar',
    data: [
      ...Object.keys(productCount).map((code) => productCount[code][prod] || 0),
      checkRemaningCount ? remainingSum[prod] || 0 : 0,
    ],
    itemStyle: {
      color: colors[index % colors.length],
    },
    barGap: '0%',
    barWidth: '15%',
    label: {
      show: true,
      position: 'top',
      color: '#e3e5e6',
      fontSize: 12,
      formatter: (params: { value: number }) => (params.value > 0 ? params.value : ''),
    },
  }))

  // Y-axis label count updated
  let maxProductCount = Math.max(totalSum.callgiving, totalSum.robotcall, totalSum.serviceinfo, totalSum.rtm)
  maxProductCount = Math.ceil(maxProductCount / 5) * 5
  const interval = Math.ceil(maxProductCount / 5)

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: selectedProducts.map((prod) => productLegend[prod] || prod),
      textStyle: {
        color: '#e3e5e6',
      },
      selectedMode: false,
    },
    grid: {
      left: '5%',
      right: '1%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: [...map(productCount, 'countryName'), ...(checkRemaningCount ? ['Others'] : [])],
      axisLabel: {
        color: '#e3e5e6',
        interval: 0,
      },
      name: 'Country',
      nameLocation: 'center',
      nameTextStyle: {
        color: '#e3e5e6',
        fontSize: 14,
        padding: 20,
      },
      axisLine: {
        lineStyle: {
          color: '#e3e5e6',
          width: 2,
        },
      },
      axisTick: {
        alignWithLabel: true,
      },
      categoryGap: '2%',
    },
    yAxis: {
      type: 'value',
      name: 'Resource Count',
      nameTextStyle: {
        color: '#e3e5e6',
        fontSize: 14,
      },
      axisLabel: {
        color: '#e3e5e6',
        formatter: '{value}',
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#e3e5e6',
          width: 2,
        },
      },
      splitLine: {
        show: false,
      },
    },
    series: seriesData,
  }

  return (
    <>
      <ReactECharts option={option} style={{ height: '400px', width: '100%' }} />
    </>
  )
}

export default CountryWiseProducts
