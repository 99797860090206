import React, { FC } from 'react'
import { QRCodeImage } from './Styles'
import { default as ReactQRCode } from 'react-qr-code'

type Props = {
  qrcode: string
}

const QRCode: FC<Props> = ({ qrcode }) => {
  return (
    <QRCodeImage>
      <ReactQRCode value={qrcode} size={160} />;
    </QRCodeImage>
  )
}

export default QRCode
