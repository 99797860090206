import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogMarch2 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        March 2024 - 2.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        March 2.0 release notes summarizes latest changes for API Portal documentation changes and bug fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Experience API</li>
          <UL>
            <li>
              The bug related to the use of the same request Id within the same connection Id for{' '}
              <Link to={Paths.ElevatorWebsocketAPIV2.path + '#make-an-elevator-call'}>Elevator Call API</Link> is now
              fixed. Now, API clients will receive a proper error message if they use the same request Id within the
              same connection.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Available Elevator modes and their description added in{' '}
              <Link to={Paths.ElevatorWebsocketAPIV2.path + '#make-an-elevator-call'}>Elevator Call API</Link> inside
              lift-status event.{' '}
            </li>
            <li>
              The feedback form has been updated with additional options to select under the main area, enabling
              customers to provide brief
              <Link to={Paths.Feedback.path}> feedback.</Link>
            </li>
            <li>
              The different sections of the portal documentation have been updated with additional relevant information.
              <UL>
                <li>
                  Kone APIs section has been updated with its functionality for API and more relevant information
                  related to each API has been added.
                </li>
              </UL>
              <UL>
                <li>
                  The Authentication scope is explained within the Developer guide -{' '}
                  <Link to={Paths.MyFirstAPICall.path}> Get started with APIs</Link> section.
                </li>
              </UL>
              <UL>
                <li>Structure of Documentation guide for testing inside Solution Creation updated.</li>
              </UL>
              <UL>
                <li>
                  The order of the<Link to={Paths.Glossary.path}> Glossary</Link> page has been updated to provide users
                  with step-by-step information.{' '}
                </li>
              </UL>
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
