import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogMarch = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        March 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        March release notes summarizes latest changes for API Portal documentation changes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Added possible error values on API portal documentation for Hold car door open request in{' '}
              <Link to={Paths.ElevatorWebsocketAPIRobotsV2.path + '#hold-car-door-open'}> Service Robot API.</Link>
            </li>
            <li>
              The absence of the REST tier feature is explained in the <Link to={Paths.Glossary.path}> Glossary </Link>{' '}
              section.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
