import styled from 'styled-components'
import { Row } from 'antd'
import { Button, H5 } from '../../shared/components'
import { color, size } from '../../shared/utils/styles'
import phoneImage from '../../assets/img-api-cat-experience-front.png'
import flippedPhoneImage from '../../assets/img-api-cat-operational-cropped.png'

export const StartUsingBtn = styled(Button)`
  width: 194px;
  height: 40px;
`

export const CardContainer = styled.div`
  position: relative;
  background-color: ${color.gray85Opacity};
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: '. TextArea' '. ReadMore';
  &#experience-api-card {
    grid-template-areas: '. TextArea' '. ReadMore';
    margin: 0 0 0 -999000px;
    padding: 0 0 0 999000px;

    @media (max-width: ${size.large}) {
      grid-template-columns: 30% 70%;
      grid-template-areas: '. TextArea' '. ReadMore';
      padding-right: 16px;
    }

    @media (max-width: ${size.medium}) {
      grid-template-columns: 100%;
      grid-template-areas: 'TextArea' 'ReadMore';
      padding-right: 8px;
    }
  }
  &#operational-api-card {
    grid-template-areas: 'TextArea .' 'ReadMore .';
    margin: 0 -999000px 0 0;
    padding: 0 999000px 0 0;

    @media (max-width: ${size.large}) {
      grid-template-columns: 70% 30%;
      grid-template-areas: 'TextArea .' 'ReadMore .';
    }

    @media (max-width: ${size.medium}) {
      grid-template-columns: 100%;
      grid-template-areas: 'TextArea' 'ReadMore';
    }
  }
`

export const TitleContainer = styled.div`
  margin: 0;
  padding-top: 70px;

  &#experience-api-title {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: '. Circle' '. TitleArea' '. SubTitleArea';

    @media (max-width: ${size.large}) {
      grid-template-columns: 30% 70%;
      padding-top: 50px;
    }

    @media (max-width: ${size.small}) {
      padding-top: 0px;
    }
  }
  &#operational-api-title {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: 'Circle .' 'TitleArea .' 'SubTitleArea .';
    padding-left: 64px;

    @media (max-width: ${size.large}) {
      padding: 0px;
      margin-left: 36px;
      padding-top: 50px;
    }

    @media (max-width: ${size.small}) {
      padding-top: 0px;
    }
  }
`

export const Icon = styled.div<{ icon: string }>`
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: 80px;
  width: 80px;
  height: 80px;

  @media (max-width: ${size.large}) {
    height: 40px;
    width: 40px;
    background-size: 40px;
  }
`

export const Circle = styled.div`
  height: 150px;
  width: 150px;
  background-color: ${color.blueBase};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  grid-area: Circle;

  @media (max-width: ${size.large}) {
    height: 70px;
    width: 70px;
  }

  @media (max-width: ${size.medium}) {
    display: none;
  }

  @media (max-width: ${size.small}) {
    display: none;
  }
`

export const ReadMoreButtonArea = styled.div`
  grid-area: ReadMore;
  padding-bottom: 32px;
  padding-top: 16px;

  @media (max-width: ${size.large}) {
    padding: 0px;
    margin: 0px 0px 24px 0px;
    text-align: left;
  }

  &#experience-api-button {
    padding-left: 0;

    @media (max-width: ${size.large}) {
      padding-left: 0;
      text-align: left;
    }

    @media (max-width: ${size.medium}) {
      padding: 0;
      text-align: center;
    }
  }

  &#operational-api-button {
    padding-left: 64px;

    @media (max-width: ${size.large}) {
      padding-left: 32px;
      text-align: left;
    }

    @media (max-width: ${size.medium}) {
      padding: 0;
      text-align: center;
    }
  }
`

export const TitleArea = styled.div`
  grid-area: TitleArea;
  margin-top: 10px;

  && h3 {
    margin: 0 0 25px 0;
  }

  @media (max-width: ${size.large}) {
    margin-top: -10px;
    width: 100%;
  }
`

export const TextArea = styled(H5)`
  grid-area: TextArea;
  padding-top: 20px;
  padding-right: 156px;

  &#experience-api-textarea {
    @media (max-width: ${size.large}) {
      padding: 0px;
      margin: 20px 0;
    }

    @media (max-width: ${size.medium}) {
      margin: 20px 36px;
    }
  }

  &#operational-api-textarea {
    grid-area: TextArea;
    padding-top: 20px;
    padding-left: 64px;

    @media (max-width: ${size.large}) {
      padding: 0px;
      margin: 20px 36px;
    }

    @media (max-width: ${size.medium}) {
      margin: 20px 36px;
    }
  }
`

export const ExperienceApisContainer = styled.div`
  padding-top: 95px;
  padding-bottom: 32px;
  background-image: url(${phoneImage});
  background-repeat: no-repeat;
  background-position-y: 100px;
  background-position-x: 5%;
  max-width: 1550px;
  margin: 0 auto;

  @media (max-width: ${size.large}) {
    background-size: contain;
    margin-top: 40px;
    padding: 0;
    background-position: 2% 40%;
  }

  @media (max-width: ${size.medium}) {
    background-size: contain;
    margin-top: 40px;
    padding: 0;
    background-position: -9vw 6vw;
  }

  @media (max-width: ${size.small}) {
    background-size: 69%;
    background-position: -24vw 30px;
    padding-top: 10%;
    margin-top: 0;
  }
`

export const OperationalApisContainer = styled.div`
  padding-bottom: 32px;
  padding-top: 70px;
  background-image: url(${flippedPhoneImage});
  background-repeat: no-repeat;
  background-position-y: 140px;
  background-position-x: 100%;
  max-width: 1550px;
  margin: 0 auto;

  @media (max-width: ${size.large}) {
    background-size: contain;
    padding: 0px;
    background-position: 100% 3vw;
  }

  @media (max-width: ${size.small}) {
    background-size: 69%;
    background-position: 44vw 100px;
    padding-top: 10%;
    margin-top: 0;
  }
`

export const TilesContainer = styled(Row)`
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 1565px;
  align-items: stretch;
  justify-content: center;
  :last-child {
    margin-bottom: 64px;
    @media (max-width: ${size.small}) {
      margin-bottom: 0px;
    }
  }
`
