import styled from 'styled-components'
import Button from '../../../../shared/components/Button'

export const StyledForm = styled.form`
  margin-bottom: 20px;
`

export const ResetPasswordAndLoginButton = styled(Button)`
  height: 40px;
  width: 100%;
`
export const ResetCodeContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    &:focus {
      outline: none !important;
    }
  }
  textarea {
    &:focus {
      outline: none !important;
    }
  }

  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`
export const props = {
  width: '28px',
  height: '32px',
  fontSize: '20pt',
  color: 'black',
  borderRadius: '4px',
  border: '1.5px solid #0071B9',
  margin: '4px',
  opacity: '1',
  paddingLeft: '6px',
}
