import React, { FC, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PartnerStrings } from '../../../../shared/strings/PartnersContent'
import { FormButton, PartnerP, RequiredFieldsText } from '../Styles'
import { StyledLine } from '../../Styles'
import PartnerContactDetails from './PartnerContactDetails'
import { Contact } from '../../Models'
import { PartnerContext } from '../state'
import { SalesPageAPI } from '../../../../shared/api/salespage'
import { Toast } from '../../../../../src/shared/components'
import { checkDuplicateContacts } from '../../../../../src/shared/utils/salespage'

interface ContactInfoProps {
  next: (data: any | null) => void
  isCreateErrorVisible: boolean | false
  setIsCreateErrorVisible: (data: boolean) => void
  createIntegratorErrorMessage?: string | ''
  setCreateIntegratorErrorMessage: (msg: string) => void
}
const ContactInfo: FC<ContactInfoProps> = (props) => {
  const defaultContact: Contact = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
  }
  type ToastType = 'success' | 'error'
  const [contacts, setContacts] = useState<Contact[] | []>([{ ...defaultContact }])
  const { partner, setPartner } = useContext(PartnerContext)
  const [contactDisabled, setContactDisabled] = useState(false)
  const [isSuccess, setIsSuccess] = useState<boolean | false>(false)
  const [successMsg, setsuccessMsg] = useState<string | ''>('')
  const [toastType, setToastType] = useState<ToastType>('success')
  const defaultError = {
    firstName: {
      error: false,
      message: 'Please enter first name',
    },
    lastName: {
      error: false,
      message: 'Please enter last name',
    },
    email: {
      error: false,
      message: 'Please enter a valid email ID',
    },
    phoneNumber: {
      error: false,
      message: 'Please enter a valid phone number with country code',
    },
    jobTitle: {
      error: false,
      message: 'Please enter a job title',
    },
  }
  const [formErr, setFormErr] = useState<any>([{ ...defaultError }])
  const { setIsCreateErrorVisible, setCreateIntegratorErrorMessage } = props
  const { handleSubmit } = useForm({})

  const validateContact = () => {
    const errorFlags: boolean[] = []
    const tempError: any = [...formErr]
    contacts?.map((contact: Contact, index: number) => {
      if (!contact?.firstName || contact?.firstName?.length == 0) {
        tempError[index].firstName.error = true
        errorFlags.push(false)
      } else {
        tempError[index].firstName.error = false
      }
      if (!contact?.lastName || contact?.lastName?.length == 0) {
        tempError[index].lastName.error = true
        errorFlags.push(false)
      } else {
        tempError[index].lastName.error = false
      }

      const emailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (!contact?.email || contact?.email?.length == 0 || !emailRegx?.test(contact?.email)) {
        tempError[index].email.error = true
        errorFlags.push(false)
      } else {
        tempError[index].email.error = false
      }

      const regx = /^([+]?)+\d{8,15}(,[\s\s]?([+]?)+\d{8,15})*$/
      if (!contact?.phoneNumber || contact?.phoneNumber?.length == 0 || !regx?.test(contact?.phoneNumber)) {
        tempError[index].phoneNumber.error = true
        errorFlags.push(false)
      } else {
        tempError[index].phoneNumber.error = false
      }

      if (!contact?.jobTitle || contact?.jobTitle?.length == 0) {
        tempError[index].jobTitle.error = true
        errorFlags.push(false)
      } else {
        tempError[index].jobTitle.error = false
      }
    })
    setFormErr(tempError)
    return !errorFlags.includes(false)
  }

  useEffect(() => {
    if (partner?.contacts) {
      setContacts(partner?.contacts)
    }
  }, [])

  const onSubmit = async (data: any) => {
    console.log('data in step 2: ', data)
  }

  const isDisableContactButton = () => {
    const flagArr: boolean[] = []
    contacts?.map((contact: Contact, index: number) => {
      const isDisable =
        contact?.firstName?.trim()?.length == 0 ||
        contact?.lastName?.trim()?.length == 0 ||
        contact?.email?.trim()?.length == 0 ||
        contact?.jobTitle?.trim()?.length == 0 ||
        contact?.phoneNumber?.trim()?.length == 0
      flagArr.push(isDisable)
    })

    setContactDisabled(flagArr.includes(true))
  }

  const checkTouched = (index: number, key: string, value: string) => {
    if (formErr[index]?.[key].error === true && value && value?.length > 0) {
      const newFormErr = [...formErr]
      if (newFormErr[index]?.[key].error) {
        newFormErr[index][key].error = false
        setFormErr(newFormErr)
      }
    }
  }

  useEffect(() => {
    if (contacts) {
      const hasDuplicateContacts = checkDuplicateContacts(contacts, 'email')
      if (hasDuplicateContacts) {
        setsuccessMsg(PartnerStrings.uniqueEmail)
        setIsSuccess(true)
        setToastType('error')
      } else {
        setIsSuccess(false)
      }
    }
  }, [contacts])

  const removeContact = (index: number) => {
    const tempContacts = [...contacts]
    tempContacts.splice(index, 1)
    setContacts(tempContacts)
  }

  const onInputChange = (e: any, index: number, key: string) => {
    const { value } = e.target

    const tempContacts: Contact[] = [...contacts]
    tempContacts[index] = { ...tempContacts[index], [key]: value }
    checkTouched(index, key, value)
    setContacts(tempContacts)
  }

  const onPhoneNumberChange = (e: any, index: number, key: string) => {
    const { value } = e.target
    const tempContacts: Contact[] = [...contacts]
    tempContacts[index] = { ...tempContacts[index], [key]: value }
    setContacts(tempContacts)
  }

  const onClickHandle = async () => {
    const isValid = validateContact()
    if (!isValid) {
      return false
    }

    setPartner((preState: any) => {
      return { ...preState, contacts: contacts }
    })

    //prepre data to call add api
    const formData = {
      ...partner,
      contacts: contacts.map((contact) => ({
        ...contact,
        phoneNumber: contact.phoneNumber ? contact.phoneNumber.split(',').map((s: string) => s.trim()) : [],
      })),
    }
    const resopnse = await SalesPageAPI.addIntergrator(formData)

    if (!resopnse.ok) {
      const newRes = await resopnse.json()
      setIsCreateErrorVisible(true)
      setCreateIntegratorErrorMessage(newRes?.message)
    } else if (resopnse.status === 201) {
      const newRes = await resopnse.json()
      setPartner((preState: any) => {
        return { ...preState, contacts: contacts, partnerId: newRes?.partnerId }
      })
      props.next(null)
    }
    return true
  }

  useEffect(() => {
    isDisableContactButton()
  }, [contacts])

  const addNewContact = () => {
    setContacts((preState: any) => [...preState, { ...defaultContact }])
    setFormErr((preState: any) => [...preState, { ...defaultError }])
  }

  const getContactContent = () => {
    const contactContent = contacts.map((contact: Contact, index: number) => (
      <PartnerContactDetails
        key={`contact_item1_${index}`}
        index={index}
        contact={contact}
        onInputChange={onInputChange}
        onPhoneNumberChange={onPhoneNumberChange}
        formErr={formErr}
        removeContact={removeContact}
      />
    ))
    return contactContent
  }

  return (
    <>
      <Toast isVisible={isSuccess} text={successMsg} onDismiss={() => setIsSuccess(false)} type={toastType} />
      <PartnerP>{PartnerStrings.contactInformation}</PartnerP>
      <StyledLine />
      <RequiredFieldsText>{`* indicates required fields`}</RequiredFieldsText>
      <form onSubmit={handleSubmit(onSubmit)}>
        {getContactContent()}
        <div className="steps-action">
          <FormButton type="primary" onClick={addNewContact}>
            {'Add'}
          </FormButton>
          <FormButton type="primary" disabled={contactDisabled} onClick={onClickHandle}>
            {PartnerStrings.AddNewMember}
          </FormButton>
        </div>
      </form>
    </>
  )
}

export default ContactInfo
