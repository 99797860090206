import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactCodeInput from 'react-code-input'
import { AuthFormLabel, ErrorMessage, MfaCommonApps, QRCode, Toast } from '../../../../shared/components'
import { OTP_LENGTH, OTP_REG, REQUIRED_FIELD } from '../../../../shared/constants/auth'
import { useAuth } from '../../../../shared/contexts/authContext'
import { ConfirmationData } from '../../../../shared/models/auth'
import { MfaMethod, MfaSetting } from '../../../../shared/models/mfa'
import { MFAStrings } from '../../../../shared/strings/MFAContent'
import { getMfaExceptionError } from '../../../../shared/utils/mfa'
import {
  Disable2FAHeaderText,
  DisableAuthenticationButton,
  EnableLaterAlsoText,
  GoToDashboardButton,
  MfaButton,
  MfaProfileButton,
  MFASelectionContainer,
  OnDisableReturnToProfileButton,
  OnEnableReturnToProfileButton,
  OpenAuthAppText,
  ProfileButtonsContainer,
  QRCodeContainer,
  QrCodeScanInfoText,
  QrCodeText,
  RegistrationButtonsContainer,
  ScanQRCodeText,
  StyledForm,
  StyledLine,
  TwoFactorDisabledHeaderText,
  TwoFactorEnabledText,
  VerificationCodeContainer,
  props,
} from './Styles'

type Props = {
  setting: MfaSetting
  callbackFn?: any
  data?: any
}

const MFASelection: FC<Props> = ({ setting, callbackFn, data }) => {
  const { verifyTotp, setIsMfaNeeded } = useAuth()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifyTotpErrorVisible, setIsVerifyTotpErrorVisible] = useState(false)
  const [verifyTotpError, setVerifyTotpError] = useState<Error | undefined>()
  const [isVerified, setIsVerified] = useState(false)
  const [isMfaDisabled, setIsMfaDisabled] = useState(false)

  async function onSubmit(data: ConfirmationData) {
    setIsLoading(true)
    try {
      await verifyTotp(data.confirmationCode, setting === MfaSetting.PROFILE_DISABLE ? MfaMethod.NOMFA : MfaMethod.TOTP)
      updateVerifyState()
      setIsLoading(false)
      setIsVerifyTotpErrorVisible(false)
    } catch (e) {
      console.error(e)
      const err = getMfaExceptionError(e.code)
      setVerifyTotpError(err ?? e.message)
      setIsLoading(false)
      setIsVerifyTotpErrorVisible(true)
    }
  }

  const updateVerifyState = () => {
    if (setting === MfaSetting.PROFILE_DISABLE) {
      setIsMfaDisabled(true)
    } else {
      setIsVerified(true)
    }
  }

  const onCancel = () => {
    setIsMfaNeeded(false)
  }

  const onContinueToDashboard = () => {
    setIsMfaNeeded(false)
  }

  const returnToProfile = () => {
    callbackFn()
  }

  const get6DigitInput = () => {
    return (
      <VerificationCodeContainer>
        <AuthFormLabel htmlFor="confirmation-code-input-js">{MFAStrings.enter6digitCode}</AuthFormLabel>
        <Controller
          render={({ field: { onChange, value, name } }) => (
            <ReactCodeInput
              name={name}
              value={value}
              onChange={onChange}
              inputMode="numeric"
              type="number"
              fields={6}
              inputStyle={props}
            />
          )}
          name="confirmationCode"
          control={control}
          defaultValue=""
          rules={{
            minLength: { value: OTP_LENGTH, message: MFAStrings.input6digitFieldError },
            maxLength: { value: OTP_LENGTH, message: MFAStrings.input6digitFieldError },
            pattern: { value: OTP_REG, message: MFAStrings.inputFieldNumbersError },
            required: true,
          }}
        />
        {errors.confirmationCode && (
          <ErrorMessage id="mfa-verification-code-input-error-js">
            {errors.confirmationCode.message || REQUIRED_FIELD}
          </ErrorMessage>
        )}
      </VerificationCodeContainer>
    )
  }

  const getMfaSetupContent = () => {
    return (
      <>
        <ScanQRCodeText>{MFAStrings.scanQrCode}</ScanQRCodeText>
        {data && (
          <>
            <QRCodeContainer>
              <QRCode qrcode={data!} />
            </QRCodeContainer>
            <QrCodeText>
              {/* split qrcode secret from the format otpauth://totp/email/?secret=qrcodesecret&issuer=KONE API Portal'*/}
              {MFAStrings.enterSecretKey} <br></br> {data!.split('secret=')[1].split('&')[0]}
            </QrCodeText>
          </>
        )}
        <StyledLine />
        <QrCodeScanInfoText>{MFAStrings.onQrCodeScanUserInfo}</QrCodeScanInfoText>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          {get6DigitInput()}
          {getButtonsContent()}
        </StyledForm>
        <MfaCommonApps />
      </>
    )
  }

  const getButtonsContent = () => {
    return (
      <>
        {setting === MfaSetting.REGISTRATION && (
          <RegistrationButtonsContainer>
            <MfaButton ghost onClick={onCancel}>
              {MFAStrings.cancelAndGotoDashboard}
            </MfaButton>
            <MfaButton id="verify-topt-button-js" loading={isLoading} type="primary" htmlType="submit">
              {MFAStrings.enableAuthentication}
            </MfaButton>
          </RegistrationButtonsContainer>
        )}

        {setting === MfaSetting.PROFILE_ENABLE && (
          <ProfileButtonsContainer>
            <MfaProfileButton ghost onClick={returnToProfile}>
              {MFAStrings.cancel}
            </MfaProfileButton>
            <MfaProfileButton id="verify-topt-button-js" loading={isLoading} type="primary" htmlType="submit">
              {MFAStrings.enableAuthentication}
            </MfaProfileButton>
          </ProfileButtonsContainer>
        )}
      </>
    )
  }

  const getDisableFromProfileContent = () => {
    return (
      <>
        {!isMfaDisabled ? (
          <>
            <Disable2FAHeaderText>{MFAStrings.verifyDisableHeaderText}</Disable2FAHeaderText>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              {get6DigitInput()}
              <OpenAuthAppText size="small"> {MFAStrings.openTwoFactorAuthApp} </OpenAuthAppText>
              <DisableAuthenticationButton
                id="verify-and-disable-mfa-button-js"
                loading={isLoading}
                type="primary"
                htmlType="submit"
              >
                {MFAStrings.verifyAndDisableMfaButton}
              </DisableAuthenticationButton>
            </StyledForm>
          </>
        ) : (
          <>
            <TwoFactorDisabledHeaderText>{MFAStrings.twoFactorIsDisabledText}</TwoFactorDisabledHeaderText>
            <OnDisableReturnToProfileButton
              id="return-to-profile-button-js"
              loading={isLoading}
              type="primary"
              onClick={returnToProfile}
            >
              {MFAStrings.returnToProfilePageButton}
            </OnDisableReturnToProfileButton>
          </>
        )}
      </>
    )
  }

  return (
    <MFASelectionContainer>
      {!isVerified ? (
        <>
          <Toast
            key="verify-totp-error"
            isVisible={isVerifyTotpErrorVisible}
            text={verifyTotpError}
            onDismiss={() => setIsVerifyTotpErrorVisible(false)}
            type="error"
          />
          {setting === MfaSetting.REGISTRATION && (
            <>
              {getMfaSetupContent()}
              <StyledLine />
              <EnableLaterAlsoText> {MFAStrings.enable2FALaterAlsoFromProfileText} </EnableLaterAlsoText>
            </>
          )}
          {setting === MfaSetting.PROFILE_ENABLE && getMfaSetupContent()}
          {setting === MfaSetting.PROFILE_DISABLE && getDisableFromProfileContent()}
        </>
      ) : (
        <>
          {setting === MfaSetting.REGISTRATION && (
            <>
              <TwoFactorEnabledText> {MFAStrings.twoFactorSuccessfullyEnabledText} </TwoFactorEnabledText>
              <GoToDashboardButton
                id="continue-to-dashboard-button-js"
                type="primary"
                size="large"
                onClick={onContinueToDashboard}
              >
                {MFAStrings.goToDashboard}
              </GoToDashboardButton>
            </>
          )}
          {setting === MfaSetting.PROFILE_ENABLE && (
            <>
              <TwoFactorEnabledText> {MFAStrings.twoFactorSuccessfullyEnabledText} </TwoFactorEnabledText>
              <OnEnableReturnToProfileButton
                id="return-to-profile-page-button-js"
                type="primary"
                size="large"
                onClick={returnToProfile}
              >
                {MFAStrings.returnToProfilePageButton}
              </OnEnableReturnToProfileButton>
            </>
          )}
        </>
      )}
    </MFASelectionContainer>
  )
}

export default MFASelection
