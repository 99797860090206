import styled from 'styled-components'
import Button from '../../../shared/components/Button'
import { color } from '../../../shared/utils/styles'
import { Input, Select } from 'antd'

export const FormLabel = styled.label`
  color: ${color.gray20};
  margin-bottom: 8px;
  display: block;
`

export const InputName = styled(Input)`
  width: 50%;
  color: ${color.gray75};
`

const lineStyle = `
  border: 1px solid ${color.gray70};
  opacity: 1;
`

export const Line = styled.hr`
  ${lineStyle}
  margin-top: 26px;
  margin-bottom: 34px;
`
export const TypeSelector = styled(Select)`
  width: 150px;
  margin-bottom: 25px;
  margin-top: 10px;
  overflow-y: 'scroll';
`

export const BottomLine = styled.hr`
  ${lineStyle}
  margin-top: 34px;
  margin-bottom: 48px;
`

export const CloseButton = styled(Button)`
  width: 194px;
`

export const SaveButton = styled(Button)`
  width: 208px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
