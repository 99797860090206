import { MFAErrorStrings } from '../strings/MFAContent'

export const getMfaExceptionError = (code: string): string | null => {
  switch (code) {
    case 'CodeMismatchException': // both cases has same error description
    case 'EnableSoftwareTokenMFAException':
      return MFAErrorStrings.invalidCode
    case 'InternalErrorException':
      return MFAErrorStrings.internalServerError
    case 'InvalidParameterException':
      return MFAErrorStrings.invalidParams
    case 'InvalidUserPoolConfigurationException':
      return MFAErrorStrings.invalidUserPoolConfiguration
    case 'NotAuthorizedException':
      return MFAErrorStrings.userNotAuthorized
    case 'PasswordResetRequiredException':
      return MFAErrorStrings.passwordResetNeeded
    case 'ResourceNotFoundException':
      return MFAErrorStrings.resourceNotFound
    case 'SoftwareTokenMFANotFoundException':
      return MFAErrorStrings.mfaNotEnabled
    case 'TooManyRequestsException':
      return MFAErrorStrings.tooManyAttempts
    case 'UserNotConfirmedException':
      return MFAErrorStrings.userNotConfirmed
    case 'UserNotFoundException':
      return MFAErrorStrings.userNotFound
  }
  return null
}
