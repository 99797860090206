import React, { FC, useState } from 'react'
import { PartnerStrings, TileCreateStrings, DeletePartner } from '../../shared/strings/PartnersContent'
import { Button, Toast } from '../../shared/components'
import dummyPartner from '../../assets/dummyPartner.jpg'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Modal from '../../shared/components/Modal'
import {
  AddIconPartner,
  CreateButton,
  CreateRow,
  DescOnHover,
  DescriptionText,
  HeaderContainer,
  KnowMoreButton,
  NoRecordContainter,
  Para,
  PartnerH5,
  PlusArrowPartner,
  RemoveIconPartner,
  StyledLine,
  TextTile,
  TileImage,
  TilesContainer,
} from './Styles'
import { Partner } from '../../shared/models/partner'
import { useHistory } from 'react-router-dom'
import { SalesPageAPI, deletePartner } from '../../shared/api/salespage'

const SalesPage: FC = () => {
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [hoverList, setHoverList] = useState(new Map<string, boolean | false>())
  const [isSuccess, setIsSuccess] = useState<boolean | false>(false)
  const [successMsg, setsuccessMsg] = useState<string | ''>('')
  const history = useHistory()
  const queryClient = useQueryClient()
  const { isLoading, error, data } = useQuery(SalesPageAPI.queryNames.GET_ALL, () => SalesPageAPI.getAllPartners())

  const { mutateAsync: deleteOwnedPartner } = useMutation(deletePartner)

  const onHandleDelete = async () => {
    const response = await deleteOwnedPartner(deleteId)
    if (response?.ok) {
      setsuccessMsg('Intergrator deleted successfully.')
      setIsSuccess(true)
    }
    setModalDeleteVisible(false)
    invalidateIntegratorList()
  }

  const onClickKnowMore = (id: any) => {
    history.push(`/dashboard/partner-details/${id}`)
  }

  const invalidateIntegratorList = () => {
    queryClient.invalidateQueries({ queryKey: [SalesPageAPI.queryNames.GET_ALL] })
  }

  const handleHover = (e: any, Id: string) => {
    const newEntry = new Map<string, boolean | false>()
    setHoverList(newEntry.set(Id, true))
  }
  const onHideDeleteModal = () => {
    setModalDeleteVisible(false)
  }
  const handleHoverOut = (e: any, Id: string) => {
    const newEntry = new Map<string, boolean | false>()
    setHoverList(newEntry.set(Id, false))
  }
  const onShowDeleteModal = (id: string) => {
    setDeleteId(id)
    setModalDeleteVisible(true)
  }

  const getPartnerTiles = (partners: Array<Partner>) => {
    if (partners?.length === 0) {
      return (
        <>
          <NoRecordContainter>{'No Integrator Found!'}</NoRecordContainter>
        </>
      )
    }
    const partnerTiles = partners?.map((onePartner: Partner) => {
      return (
        <TextTile
          key={`parterTile_${onePartner.partnerId}`}
          onMouseEnter={(e: any) => handleHover(e, `parterTile_${onePartner.partnerId}`)}
          onMouseLeave={(e: any) => handleHoverOut(e, `parterTile_${onePartner.partnerId}`)}
        >
          <>
            {onePartner?.image?.length ? (
              <TileImage src={onePartner?.image?.length ? onePartner?.image : dummyPartner}></TileImage>
            ) : (
              <AddIconPartner />
            )}
            <DescriptionText> {onePartner.partnerName} </DescriptionText>
            {hoverList.get(`parterTile_${onePartner.partnerId}`) && (
              <>
                <DescOnHover>
                  <RemoveIconPartner onClick={() => onShowDeleteModal(onePartner?.partnerId)}></RemoveIconPartner>
                  <Modal
                    key="remove-delete-modal"
                    title="Delete Partner"
                    width={672}
                    visible={modalDeleteVisible}
                    closable={false}
                    footer={[
                      <Button
                        id="remove-delete-button-js"
                        size="large"
                        style={{ width: '304px', marginRight: '24px' }}
                        key="back"
                        ghost={true}
                        onClick={onHideDeleteModal}
                      >
                        {DeletePartner.ConfirmDeletion}
                      </Button>,
                      <Button
                        id="delete-button-js"
                        size="large"
                        style={{ width: '272px' }}
                        key="submit"
                        type="primary"
                        onClick={() => onHandleDelete()}
                      >
                        {DeletePartner.Delete}
                      </Button>,
                    ]}
                  >
                    {DeletePartner.DeletePopupText}
                  </Modal>
                  <Para>{onePartner.companyInfo}</Para>
                  <KnowMoreButton onClick={() => onClickKnowMore(onePartner.partnerId)} type="primary" size="small">
                    {TileCreateStrings.knowMore}
                  </KnowMoreButton>
                </DescOnHover>
              </>
            )}
          </>
        </TextTile>
      )
    })

    return partnerTiles
  }

  const onClickCreate = () => {
    history.push('/dashboard/addPartner')
  }

  return (
    <>
      <Toast
        allowWithTime={true}
        isVisible={isSuccess}
        text={successMsg}
        onDismiss={() => setIsSuccess(false)}
        type="success"
      />
      <HeaderContainer></HeaderContainer>
      <CreateRow>
        <PartnerH5>{PartnerStrings.PartnerCatelouge}</PartnerH5>
        <CreateButton id="open-create-org-button-js" onClick={onClickCreate} type="primary">
          <PlusArrowPartner />
          {TileCreateStrings.addNewMember}
        </CreateButton>
      </CreateRow>
      <StyledLine />
      <TilesContainer>{!isLoading ? getPartnerTiles(data) : 'Loading Integrator data...'}</TilesContainer>
    </>
  )
}

export default SalesPage
