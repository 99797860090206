import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'

export const ReleaseNotesLogDecember2 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        December 2023 - 2.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        December 2.0 release notes summarizes addition of few fields in Equipment Status API webhook response for
        Sandbox Kens.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Equipment Status API</li>
          <UL>
            <li>
              The Equipment Status API webhook response for <b>Sandbox Kens</b> now includes additional fields like:{' '}
              <q>equipmentStatus</q>, <q>equipmentFunctionalLocation</q>, <q>equipmentFunctionalLocationDescription</q>{' '}
              and <q>customerEquipmentLocationId</q>.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
