import styled from 'styled-components'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { color } from '../../../shared/utils/styles'
import { Image } from 'antd'

export const StyledCodeContainer = styled(SyntaxHighlighter)`
  background-color: ${color.gray85} !important;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 14px;
  && ::-webkit-scrollbar {
    width: 12px;
  }
  && ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  && ::-webkit-scrollbar-thumb {
    background: ${color.gray60};
    border-radius: 4px;
  }
  && ::-webkit-scrollbar-track-piece {
    background: ${color.gray90};
    border-radius: 4px;
  }
`

export const GettingStartedImage = styled(Image)`
  width: 80%;
`
