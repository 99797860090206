import { color } from '../../../../shared/utils/styles'
import styled from 'styled-components'
import { Button, Input, Select } from 'antd'
import ReactQuill from 'react-quill'
import TextArea from 'antd/lib/input/TextArea'
import { ReactComponent as RemoveIcon } from '../../../../assets/icons_remove.svg'
export {}
export const Container = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`

export const UpperSection = styled.section``
export const LowerSection = styled.section``
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  margin-top: 10px;
  opacity: 0.4;
  width: 100%;
`

export const FormLabel = styled.label`
  margin-bottom: 3px;
  margin-top: 10px;
  display: block;
  text-aligh: left;
`

export const InputName = styled(Input)`
  color: ${color.gray75};
  width: 100%;
`

export const TextareaDesc = styled(TextArea)`
  color: ${color.gray75};
  width: 84%;
`

export const CancelButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 150px;
  margin-top: 2px;
  margin-right: 10px;
  font-weight: 100;
  background-color: transparent;
  color: ${color.white};
  border: 1px solid ${color.white};
  cursor: pointer;

  &hover: {
    background-color: transparent;
    color: ${color.white};
  }
`

export const SaveButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 150px;
  margin-top: 2px;
  font-weight: 100;
  background-color: ${color.blueBase};
  color: ${color.white};
  border: 0px;
  cursor: pointer;

  &hover: {
    background-color: ${color.blueBase};
    color: ${color.white};
  }
`

export const AddContactButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 150px;
  margin-top: 2px;
  margin-right: 10px;
  font-weight: 100;
  background-color: transparent;
  color: ${color.white};
  border: 1px solid ${color.white};
  cursor: pointer;

  &hover: {
    background-color: transparent;
    color: ${color.white};
  }
`

export const Editor = styled(ReactQuill)`
  border: 1px solid ${color.gray20};
  height: 100px;
  width: 84%;
  margin-bottom: 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .ql-container {
    background-color: white;
    color: black;
    overflow: visible;
    overflow-y: auto;
    max-height: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ql-formats {
    color: white;
  }

  .ql-toolbar {
    background-color: transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`

export const UploadFileSection = styled.section`
  width: 16em;
  margin-right: 2em;
  height: 210px;
  //background-color: blue;
`
export const InputRowContainer = styled.div<{ $isMultiInput?: boolean }>`
  display: flex;
  margin-bottom: 10px;
  margin-top: 15px;
  justify-content: ${(props) => (props.$isMultiInput ? 'space-between' : 'unset')};
`
export const MultiInputContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 200px;
`
export const InputsContainer = styled.div`
  margin-bottom: 32px;
`

export const InputColumn = styled.div`
  //width: 100%;
  margin: 0 auto;
`

export const Underline = styled.div`
  text-decoration: underline;
  text-decoration-color: ${color.gray70};
`
export const RemoveIconContact = styled(RemoveIcon)`
  height: 20px;
  cursor: pointer;
`

export const ContactHeader = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
`

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`
export const LabelContainer = styled.div``
export const TypeSelector = styled(Select)`
  width: 64%;
  //margin-bottom: 35px;
  overflow-y: 'scroll';
`
export const ImageBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 89%;
`
export const PreviewImage = styled.img`
  width: 250px;
  height: 150px;
`
export const ImageName = styled.div`
  text-align: center;
`
export const UploadBtn = styled(Button)`
  width: 60%;
  margin-top: 5px;
`
