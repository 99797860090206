import { Layout } from 'antd'
import styled from 'styled-components'
import { color } from '../shared/utils/styles'

export const ContentWrapper = styled(Layout)<{ $noPadding?: boolean }>`
  // no padding if prop wants so
  padding: ${(props) => (props.$noPadding ? '0px' : '45px 45px')};
  color: ${color.gray20};
  background-color: ${color.gray80};

  && h2:first-of-type {
    margin-top: 0;
  }
`

export const StyledSider = styled(Layout.Sider)`
  flex: 0 0 250px !important;
  width: 250px !important;
  max-width: 250px !important;
  background-color: ${color.gray90};

  @media print {
    display: none;
  }
`
