import styled from 'styled-components'
import { ReactComponent as CaretDown } from '../../../../assets/icon-caret-down.svg'
import { ReactComponent as CheckIcon1 } from '../../../../assets/check.svg'
import { ReactComponent as ResetIcon } from '../../../../assets/reset.svg'
import { ReactComponent as ExpiredIcon1 } from '../../../../assets/reset 1.svg'
import { ReactComponent as RemoveIcon } from '../../../../assets/minus-circle.svg'
import { color } from '../../../../shared/utils/styles'
import { Input, Select } from 'antd'
import { Button } from '../../../../shared/components'

export const Symbol = styled(RemoveIcon)`
  cursor: pointer;
  margin-left: 60px;
  width: 25px;
  height: 25px;
  &:hover {
    filter: brightness(0.7);
  }
`

export const EmailContainer = styled.div`
  width: 350px;
  margin-left: 10px;
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflow text */
  white-space: nowrap; /* Prevent wrapping of text */
  cursor: pointer; /* Change cursor to pointer on hover */

  &:hover {
    overflow: visible; /* Show full content on hover */
    white-space: normal; /* Allow text to wrap */
    text-overflow: initial; /* Remove ellipsis */
    z-index: 1; /* Ensure hovered item appears above other content */
  }
`

export const DeletedEmailContainer = styled.div`
  width: 340px;
  flex-basis: 85%;
  color: #ed555a;
`

export const RowArrow = styled(CaretDown)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const StatusContainer = styled.div`
  width: 210px;
`

export const TitleContainer = styled.div`
  flex-grow: 2; /* Allow this column to grow twice as much as others */
  min-width: 400px; /* Set minimum width to prevent excessive shrinking */
`

export const ProfileContainer = styled.div`
  width: 150px;
  margin-left: 40px;
`

export const RoleContainer = styled.div`
  width: 150px;
`

export const CenteredButton = styled(Button)`
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 150px;
`

export const InvitationStatusContainer = styled.div`
  width: 150px;
  margin-left: 10px;
  padding-left: 30px;
  display: flex;
  justify-content: center;
`

export const ResendIcon = styled(ResetIcon)`
  cursor: pointer;
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
  &:hover {
    filter: brightness(0.7);
  }
`
export const ExpiredIcon = styled(ExpiredIcon1)`
  cursor: pointer;
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
  &:hover {
    filter: brightness(0.7);
  }
`

export const CheckIcon = styled(CheckIcon1)`
  cursor: pointer;
  width: 30px;
  height: 30px;
`

export const InvitationStatusPopup = styled.div`
  position: absolute;
  background: #3d464cf7;
  text-align: center;
  border-radius: 4px;
  padding: 1px 3px 1px 3px;
  z-index: 1;
  white-space: normal;
  width: auto;
  margin-top: -60px; /* Adjust to move popup above the icon */
  margin-left: -10px; /* Center the popup above the icon */
  user-select: none; /* Make text unselectable */
  pointer-events: none; /* Prevent pointer events */
`

export const RemovePopup = styled.div`
  position: absolute;
  background: #3d464cf7;
  text-align: center;
  border-radius: 4px;
  padding: 1px 3px 1px 3px;
  z-index: 1;
  white-space: normal;
  width: auto;
  margin-top: -60px; /* Adjust to move popup above the icon */
  margin-left: 50px; /* Center the popup above the icon */
  user-select: none; /* Make text unselectable */
  pointer-events: none; /* Prevent pointer events */
`

export const ExpandedFormContainer = styled.div`
  background-color: #3d464c;
  padding: 10px 20px; /* Adjust padding */
  border: 1px #d9d9d9;
  border-top: none;
  display: grid;
  grid-template-columns: 1fr; /* Single column layout */
  grid-gap: 20px; /* Gap between rows */
`

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0; /* Remove margin bottom for last row */
  }
`

export const TitleRow = styled.div`
  display: flex;
  flex-direction: column; /* Arrange items vertically */
`

export const FieldRow = styled.div`
  display: flex;
  align-items: left;
  margin-top: -10px;
  column-gap: 20px; /* Add gap between columns */

  & > * {
    flex-grow: 1; /* Make all children occupy equal space */
  }
`

export const Label = styled.div`
  margin-right: 10px;
  color: #e3e5e6;
  width: 30%;
`

export const InputBox = styled(Input)`
  width: 50%;
  color: ${color.gray75};
`

export const TypeSelector = styled(Select)`
  width: 150px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 10px; /* Add margin between buttons */
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputsContainer = styled(Input)`
  width: 50%;
  color: ${color.gray75};
`

export const ResourceButtonContainer = styled.div`
  display: flex;
`

export const CancelResourceAddButton = styled(Button)`
  width: 80px;
  margin-top: 15px;
  margin-left: 20px;
`

export const SaveButton = styled(Button)`
  width: 100px;
  margin-right: 20px;
  margin-top: auto;
`

export const CancelButton = styled.button`
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-color: transparent; /* Set background color to transparent */
  color: #d9d9d9; /* Set text color to the background color of the button */
  border: 1px solid #d9d9d9; /* Add border to create a ghost effect */
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 30px;
`
export const ProductSelectionBase = styled.div`
  .ant-select-arrow {
    top: 30%;
    right: 18px;
    color: #0071b9;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 34px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    bottom: 2px;
    font-size: 12px;
  }
`

export const ProductSelectionAuto = styled(ProductSelectionBase)`
  margin-top: -16px;
  overflow: visible !important;
  position: absolute;
  margin-left: 20px;
  width: 200px;
`
