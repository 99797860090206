import styled from 'styled-components'
import { size } from '../../shared/utils/styles'

export const PageContainer = styled.div`
  width: 920px;
  margin: 0 auto;

  @media (max-width: ${size.large}) {
    width: 90%;
  }
`
