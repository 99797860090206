import styled, { css } from 'styled-components'
import { color as colorList, size } from '../../utils/styles'

type TypographyProps = {
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize'
  color?: string
}

type ParagraphProps = TypographyProps & { size?: 'small' }

const typographyMixin = css<TypographyProps>`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : null)};
  text-transform: ${({ textTransform }) => (textTransform ? textTransform : null)};
  color: ${({ color }) => (color ? color : colorList.gray10)};
`

const koneMixin = css`
  font-family: KONE Information;
`

const arialMixin = css`
  font-family: Arial, Helvetica, Sans-Serif;
`

export const H1 = styled.h1<TypographyProps>`
  ${typographyMixin}
  ${koneMixin}
  font-size: 3.05rem;
  line-height: 1.1;

  @media (max-width: ${size.large}) {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  @media (max-width: ${size.small}) {
    font-size: 2.1rem;
    line-height: 1.2;
  }
`

export const H2 = styled.h2<TypographyProps>`
  ${typographyMixin}
  ${koneMixin}
  font-size: 2.45rem;
  line-height: 1.2;
  margin-top: 2rem;

  @media (max-width: ${size.large}) {
    font-size: 2rem;
    line-height: 1.2;
  }

  @media (max-width: ${size.small}) {
    font-size: 1.8rem;
    line-height: 1.1;
  }
`

export const H3 = styled.h3<TypographyProps>`
  ${typographyMixin}
  ${koneMixin}
  font-size: 1.95rem;
  line-height: 1.25;
  margin-top: 1.7rem;

  @media (max-width: ${size.large}) {
    font-size: 1.8rem;
    line-height: 1.25;
    margin-top: 1rem;
  }

  @media (max-width: ${size.small}) {
    font-size: 1.5rem;
    line-height: 1.25;
  }
`

export const H4 = styled.h4<TypographyProps>`
  ${typographyMixin}
  ${koneMixin}
  font-size: 1.56rem;
  line-height: 1.2;
  margin-top: 1.5rem;

  @media (max-width: ${size.large}) {
    font-size: 1.4rem;
    line-height: 1.25;
  }

  @media (max-width: ${size.small}) {
    font-size: 1.3rem;
    line-height: 1.27;
  }
`

export const H5 = styled.h5<TypographyProps>`
  ${typographyMixin}
  ${koneMixin}
  font-size: 1.35rem;
  line-height: 1.4;
  margin-top: 1.3rem;

  @media (max-width: ${size.large}) {
    font-size: 1.25rem;
    line-height: 1.25;
  }
  @media (max-width: ${size.small}) {
    font-size: 1.12rem;
    line-height: 1.25;
  }
`

export const H6 = styled.h6<TypographyProps>`
  ${typographyMixin}
  ${koneMixin}
  font-size: 1.15rem;
  line-height: 1.3;
  margin-top: 1rem;

  @media (max-width: ${size.large}) {
    font-size: 1.15rem;
    line-height: 1.2;
  }

  @media (max-width: ${size.small}) {
    font-size: 1.05rem;
    line-height: 1.29;
  }
`

const defaultParagraphMixin = css`
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 1.5rem;

  && ul, // Styling for inline HTML items
  li {
    font-size: 1rem;
    margin-top: 10px;

    @media (max-width: ${size.small}) {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }

  @media (max-width: ${size.small}) {
    font-size: 0.9rem;
    line-height: 1.5;
  }
`

const smallParagraphMixin = css`
  font-size: 0.875rem;
  line-height: 1.7;
  margin-top: 1.5rem;
`

export const UL = styled.ul<ParagraphProps>`
  ${typographyMixin}
  ${arialMixin}
  font-size: 1rem;
  margin-top: 0 !important;

  && li {
    line-height: 1.7;
  }

  ${(props) => {
    switch (props.size) {
      case 'small':
        return smallParagraphMixin
      default:
        return defaultParagraphMixin
    }
  }};
`

export const OL = styled.ol<ParagraphProps>`
  ${typographyMixin}
  ${arialMixin}
  font-size: 1rem;

  && li {
    line-height: 1.7;
  }

  ${(props) => {
    switch (props.size) {
      case 'small':
        return smallParagraphMixin
      default:
        return defaultParagraphMixin
    }
  }};
`

export const P = styled.p<ParagraphProps>`
  ${typographyMixin}
  ${arialMixin}

  ${(props) => {
    switch (props.size) {
      case 'small':
        return smallParagraphMixin
      default:
        return defaultParagraphMixin
    }
  }};
`
