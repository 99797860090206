import { color } from '../../../../shared/utils/styles'
import styled from 'styled-components'
import { ReactComponent as PlusArrow } from '../../../../assets/icongraphic.svg'
import { ReactComponent as RemoveIcon } from '../../../../shared/assets/image-delete-white.svg'
import Button from '../../../../shared/components/Button'
import Tabs from 'antd/lib/tabs'

export const Container = styled.div`
  display: flex;
  margin: 20px;
  padding-left: 30px;
  align-items: center;
`

export const InnerContainer = styled.div``

export const FormButton = styled(Button)`
  width: 2%;
  margin-right: 10px;
  margin-top: 36px;
  border: 1px solid #fff;
  background-color: transparent;
`

export const AddMoreVideo = styled(PlusArrow)`
  margin-top: 5px;
  margin-left: -7px;
`

export const RemoveIconPartner = styled(RemoveIcon)`
  position: absolute;
  right: 7px;
  top: 6px;
  cursor: pointer;
`

export const SaveChanges = styled(Button)`
  font-size: 1em;
  height: 40px;
  margin-top: 20px;
  font-weight: 100;
  margin-left: auto;
  width: 15%;
`

export const CancelButton = styled(Button)`
  width: 10%;
  margin-right: 10px;
  margin-top: 36px;
  height: 40px;
  border: 1px solid #fff;
  background-color: transparent;
`

export const TitleLabel = styled.label`
  font-size: 20px;
  color: white;
`

export const Table = styled.div`
  margin-top: 50px;
  th,
  td {
    border-bottom: 1px solid #474242;
    padding: 10px;
    text-align: left;
  }
`

export const PartnerP = styled.p`
  margin-bottom: 1px;
  text-align: left;
`
export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  margin-top: 1px;
  opacity: 0.4;
  width: 100%;
`

export const Vl = styled.div`
  border-left: 2px solid ${color.gray80};
  height: 150px;
  padding-left: 20px;
  pagging-right: 20px;
`

export const StyledTabs = styled(Tabs)`
  max-width: 1000px;
  color: ${color.gray20};
  border: 1px solid ${color.gray70};
  overflow-x: auto;

  .ant-tabs-tab {
    margin-right: 50px;
    margin-left: 50px;
    padding-bottom: 5px;
  }

  .ant-tabs-nav-list {
    width: 100%;
    margin-bottom: 20px;

    margin-right: auto;
    background-color: ${color.gray70};
  }

  .ant-tabs-ink-bar {
    background: ${color.blueBase};
    height: 3px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${color.white} !important;
  }
  .ant-tabs-tab:hover {
    color: ${color.white};
  }
  .ant-tabs-tab-btn:active {
    color: ${color.gray10};
  }

  &.ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }

  &.ant-tabs-nav-operations-hidden {
    display: none;
  }
`

export const TabsContainer = styled.div`
  margin-top: 50px;
  margin-left: 0px;
`
