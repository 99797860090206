import React, { FC } from 'react'
import { HeadingText } from './Styles'
import { Row } from 'antd'
import BuildingTopology from './BuildingTopology'
import TestForSolutionValidation from './TestForSolutionValidation'
import DocumentGuideForTesting from './DocumentGuideForTesting'

const SolutionValidation: FC = () => {
  return (
    <>
      <Row>
        <HeadingText>Solution Validation</HeadingText>
      </Row>
      <BuildingTopology />
      <DocumentGuideForTesting />
      <TestForSolutionValidation />
    </>
  )
}

export default SolutionValidation
