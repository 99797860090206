import { ReactComponent as Reset } from '../../../assets/icon-reset.svg'
import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'
import { Input } from 'antd'
import { ReactComponent as CopyIcon } from '../../../assets/icons-copy.svg'

export const InputName = styled(Input)`
  color: ${color.gray75} !important;
  width: 316px;
  height: 32px;
  margin-bottom: 36px;
  margin-right: 12px;
`

export const IconContainer = styled.div`
  display: flex;
  &:hover {
    p {
      color: ${color.blueTint20};
    }

    .a {
      fill: ${color.blueTint20};
    }
  }
`

export const ResetIcon = styled(Reset)`
  cursor: pointer;
  margin-top: 3px;
  &:hover {
    .a {
      fill: ${color.blueTint20};
    }
  }
`

export const CenterLoader = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 114px;
`

export const CenterContainer = styled.div`
  display: flex;

  .ant-input {
    cursor: default;
    background-color: ${color.white} !important;
  }
`

export const StyledTooltip = styled.div`
  .ant-tooltip-inner {
    color: ${color.black};
    background-color: ${color.white};
    width: 55px;
    height: 16px;
    text-align: right;
    font: normal normal normal 14px/16px KONE Information;
    margin-top: -32px;
    margin-right: 8px;
    min-width: 28px;
    min-height: 28px;
  }
  .ant-tooltip-arrow {
    margin-top: -18px;
  }
  .ant-tooltip-arrow-content {
    background-color: ${color.white};
  }
`

export const StyledTooltipReset = styled.div`
  .ant-tooltip-inner {
    color: ${color.black};
    background-color: ${color.white};
    width: 55px;
    height: 16px;
    text-align: left;
    font: normal normal normal 14px/16px KONE Information;
    margin-top: -32px;
    margin-left: 8px;
    min-width: 28px;
    min-height: 28px;
  }
  .ant-tooltip-arrow {
    margin-top: -18px;
  }
  .ant-tooltip-arrow-content {
    background-color: ${color.white};
  }
`

export const CopyIconStyle = styled(CopyIcon)`
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-top: 3px;
`

export const FormLabelSecret = styled.p`
  margin-top: 0;
  margin-bottom: 8px;
  display: block;
  color: ${color.gray20};
`
