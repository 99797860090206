import styled, { css } from 'styled-components'
import { ReactComponent as ArrowUp } from '../../../assets/icons-sort-chevron-up.svg'
import { ReactComponent as ArrowDown } from '../../../assets/icons-sort-chevron-down.svg'

const arrowStyle = css`
  margin-left: 6px;
  cursor: pointer;
`

export const ArrowSortedAZ = styled(ArrowDown)`
  ${arrowStyle}
`

export const ArrowSortedZA = styled(ArrowUp)`
  ${arrowStyle}
`

export const ProductsText = styled.div`
  position: absolute;
  background: #3d464cf7;
  text-align: center;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  margin-top: -22px;
  margin-left: 50px;
  padding: 0px 5px;
  z-index: 1;
  white-space: normal;
  width: 220px;
`

export const ProductNumber = styled.div`
  margin-right: 4px;
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  background: #0071b9;
  color: white;
  text-align: center;
  box-sizing: content-box;
  white-space: nowrap;
  font-size: 14px;
`

export const ProductsContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
`
