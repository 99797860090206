import { Input, Tabs } from 'antd'
import styled from 'styled-components'
import { P } from '../../../shared/components/Typography'
import { color } from '../../../shared/utils/styles'

export const TabsContainer = styled.div`
  margin-top: -122px;
`

export const StyledTabs = styled(Tabs)`
  width: 100%;
  color: ${color.gray20};

  .ant-tabs-tab {
    margin-right: 50px;
    margin-left: 50px;
    padding-bottom: 5px;
  }

  .ant-tabs-nav-list {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .ant-tabs-ink-bar {
    background: ${color.blueBase};
    height: 3px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${color.white} !important;
  }
  .ant-tabs-tab:hover {
    color: ${color.white};
  }
  .ant-tabs-tab-btn:active {
    color: ${color.gray10};
  }

  &.ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }

  &.ant-tabs-nav-operations-hidden {
    display: none;
  }
`

export const EmailInput = styled(Input)`
  text-transform: lowercase;
`

export const ServiceBreakMessage = styled(P)`
  text-align: center;
`
