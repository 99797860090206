import { ProductInfoStrings } from '../../shared/strings/ProductInfoContent'

type ProductInfo = {
  name: string
  type: string
  description: string
}

const productInfo: ProductInfo[] = [
  {
    name: 'callgiving',
    type: 'Elevator call API',
    description: ProductInfoStrings.elevatorCallApi,
  },
  {
    name: 'robotcall',
    type: 'Service Robot API',
    description: ProductInfoStrings.serviceInfoApi,
  },
  {
    name: 'equipmentstatus',
    type: 'Equipment Status API',
    description: ProductInfoStrings.equipmentStatusApi,
  },
  {
    name: 'serviceinfo',
    type: 'Service Info API',
    description: ProductInfoStrings.serviceInfoApi,
  },
  {
    name: 'rtm',
    type: 'Equipment Status API 2.0',
    description: ProductInfoStrings.rtm,
  },
]

export { productInfo }
