import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'
import { Button, Table } from '../../../shared/components'

export const InvitationList = styled.div`
  color: ${color.gray10};
`

export const OrganizationNameRowContent = styled(Table.Column)`
  flex-basis: 33%;
`

export const ButtonAccept = styled(Button)`
  width: 102px;
  height: 32px;
`

export const ButtonDecline = styled(Button)`
  width: 102px;
  height: 32px;
  margin-right: 10px;
`

export const ButtonsContainer = styled.div`
  margin-left: auto;
`

export const MessageContainer = styled.div`
  margin-top: 1.5em;
`
