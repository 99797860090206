import { TitleWrapperProps } from '../components/TitleWrapper'

export const InspirationPageHeroImage = {
  title: 'Inspiration',
  body: 'Find out how we are improving people flow in cities with APIs',
}

export const SharedStrings = {
  findOutMoreTitle: 'Do you want to find out more?',
  findOutMoreButton: 'Contact us',
}

export const SmarterCities = {
  title: 'Smarter cities with KONE API-powered ecosystem',
  firstTextBlock:
    '<p>KONE provides innovative and sustainable solutions for elevators, escalators and automatic building doors, as well as ' +
    'solutions that can be integrated with smart buildings. We are guided by a vision to create the best people flow experience and ' +
    'our mission is to improve the flow of urban life. At KONE, this is what inspires us to help our customers succeed. Throughout our history, ' +
    'we have always focused on sustainability and what’s important to our customers. This is where technology can help, ' +
    'enabling brilliant solutions to solve the challenges of a fast-moving world.</p>',
  subtitle: 'Combining forces through connected ecosystems',
  secondTextBlock:
    '<p>By sharing our digital resources and intelligence, we can work together to create even better sustainable solutions which can help make cities better places to live. ' +
    'KONE API (Application Programming Interface) Services tap into KONE’s equipment and data, giving insights about people flow, equipment and maintenance activities enabling ' +
    'the creation of more adaptable solutions for different needs. APIs are a convenient way to connect different systems together seamlessly and effortlessly, ' +
    'bringing together digital and physical services.</p><p>We want to invite anyone to try out our APIs and the possibilities they can bring. With an open approach, ' +
    'we allow different companies and users to co-create imaginative solutions. With KONE API Services, our maintenance service customers can connect their elevators, ' +
    'other equipment or data to work with solutions that already exist, or into solutions which are going to be built or bought. With a growing network of companies in ' +
    'our ecosystem, smart solutions can bring value to our customers. Through the power of ecosystems, it is a lot easier to create value for the whole building, ' +
    'make it smarter and even introduce new advanced services which can integrate with smart cities.</p>',
  findOutMoreText:
    '<p>KONE can help future-proof your building and your business, with a wide array of digital solutions which can be used with different equipment. ' +
    'Contact our <a href="https://www.kone.com/en/contact.aspx" rel="noreferrer" target="_blank">local KONE sales teams</a> to hear more about the currently available ' +
    'KONE API Services offering and pricing.</p>',
  contactUsLink: 'https://www.kone.com/en/contact.aspx',
  imageAlt: 'A man is standing in an elevator and using a device that is connected to smart building ecosystem',
}

export const SmarterCitiesSEOProps = {
  metaTags: [
    {
      name: 'description',
      content: 'Build and connect systems together seamlessly and effortlessly with KONE API-powered ecosystem',
    },
  ],
}

export const Ecosystem = {
  title: 'An ecosystem of partners',
  firstTextBlock: `<p>In today’s world nobody can innovate alone – the best innovation happens when working together with others. Striving together to create customer value and 
  product excellence is what helps companies grow and reach leading positions in their industries. KONE is already working with innovative companies in different fields of 
  technology, to form a dynamic ecosystem of partners. Together with KONE, these partners are responding to the growing needs of smart cities and environments by bringing modern 
  smart building solutions to the market.</p>`,
  subtitle: 'Practicalities of partnering with KONE',
  secondTextBlock: `<p>In the current partner collaboration model, the first step is for the organization to develop and test their solution against the KONE APIs. The next step 
  is to identify a customer case together and deliver the joint-solution solution to the customer. The collaboration can remain on this level, or if both, solution provider and 
  KONE are interested, the collaboration can potentially be extended to the next level within KONE Partner Ecosystem. On this next level, as a verified KONE Ecosystem Partner, 
  the collaboration operations are more dynamic and business-oriented. These partners complete a specified onboarding journey, to ensure our two companies’ business objectives 
  and collaboration models are aligned. The aim of this journey is to agree a way to work together, so that we can both promote the joint-solution, explore great opportunities 
  together, and both gain further benefits from the collaboration.</p><p>The common goal with partner collaboration is to create the best possible experience for the different 
  types of user groups moving around modern smart cities, increase the value delivered to customers, and seize the opportunity to support each other to grow and strengthen our 
  offering.</p>`,
  findOutMoreText: `<p>Innovate with us to help our customers build smart and sustainable urban environments and drive sustainable city development. Contact us to learn more 
  about our Ecosystem plans and portfolio. Leave your contact information through the form <a href="https://www.kone.com/en/company/innovations/innovating-with-partners/" 
  rel="noreferrer" target="_blank">at the bottom of this page</a>.</p>`,
  contactUsLink: 'https://www.kone.com/en/company/innovations/innovating-with-partners/',
  imageAlt: "A man and a woman are experiencing modern elevator's capabilities",
  metaTags: [
    {
      name: 'description',
      content: 'Create the best possible experiences for your customers by joining KONE Partner Ecosystem',
    },
  ],
}

export const EcosystemSEOProps = {
  metaTags: [
    {
      name: 'description',
      content: 'Build and connect systems together seamlessly and effortlessly with KONE API-powered ecosystem',
    },
  ],
}

export const InspirationPageTitles = {
  creatingValue: 'Creating value with new innovations',
}

export const SEOProps: TitleWrapperProps = {
  title: 'Inspiring examples of KONE APIs in action',
  metaTags: [
    {
      name: 'description',
      content: 'Find out how we are improving People Flow® in cities with APIs.',
    },
  ],
}
