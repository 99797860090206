import React from 'react'

import { FileUploadContainer, FormField, DragDropText, FileIcon, MaxFileSizeText, SupportedFileText } from './Styles'
import {
  DEFAULT_LABEL,
  DEFAULT_MAX_FILE_SIZE,
  DEFAULT_MAX_FILE_SIZE_LABLE,
  DEFAULT_HEIGHT,
  SUPPORTED_FILE_TYPES,
} from '../../../shared/constants/common'

const FileUpload = ({
  label = DEFAULT_LABEL,
  maxSize = DEFAULT_MAX_FILE_SIZE,
  sizeLable = DEFAULT_MAX_FILE_SIZE_LABLE,
  height = DEFAULT_HEIGHT,
  ...otherProps
}: any) => {
  return (
    <>
      <FileUploadContainer height={height}>
        {/* <InputLabel>{label}</InputLabel> */}
        <FileIcon />
        <DragDropText>{label}</DragDropText>
        <MaxFileSizeText>
          Max file size : {maxSize}
          {` ${sizeLable}`}
        </MaxFileSizeText>
        <SupportedFileText>
          Supported file type :
          <br />
          {SUPPORTED_FILE_TYPES.join(', ')}
        </SupportedFileText>
        <FormField type="file" title="" value="" {...otherProps} />
      </FileUploadContainer>
    </>
  )
}

export default FileUpload
