import React, { FC, useEffect } from 'react'
import { useTransition } from 'react-spring'
import { CloseIcon, ContentContainer, Text, AnimatedDiv, ToastContent, ToastWrapper, IconContainer } from './Styles'
import { ReactComponent as OKIcon } from '../../../assets/icons-green-ok.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons-yellow-warning.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/icons-red-warning.svg'
import { ReactComponent as InfoIcon } from '../../../assets/icons_toaster_ok_circle.svg'

import { ToastType } from './ToastType'

type Props = {
  isVisible: boolean
  onDismiss: () => void
  position?: 'absolute' | 'fixed'
  text?: any
  textId?: string
  type: ToastType
  top?: string
  right?: string
  allowWithTime?: boolean
}

const Toast: FC<Props> = ({
  isVisible,
  onDismiss,
  position = 'fixed',
  text,
  textId,
  type,
  top,
  right,
  allowWithTime = false,
}) => {
  const transitions = useTransition(isVisible, {
    from: { position, opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <ErrorIcon />
      case 'warning':
        return <WarningIcon />
      case 'success':
        return <OKIcon />
      case 'info':
        return <InfoIcon />
    }
  }

  useEffect(() => {
    if (allowWithTime) {
      setTimeout(() => {
        onDismiss()
      }, 8000)
    }
  }, [isVisible])

  return (
    <ToastWrapper>
      {transitions(
        (styles, item) =>
          item && (
            <AnimatedDiv
              style={styles}
              top={position === 'absolute' ? (top ? top : 'unset') : '10px'}
              right={position === 'absolute' ? (right ? right : 'unset') : 'unset'}
            >
              <ToastContent type={type}>
                <ContentContainer>
                  <IconContainer>{getIcon()}</IconContainer>
                  <>
                    <Text id={textId}>{text}</Text>
                  </>
                  <CloseIcon id="close-toast-js" onClick={onDismiss} />
                </ContentContainer>
              </ToastContent>
            </AnimatedDiv>
          )
      )}
    </ToastWrapper>
  )
}

export default Toast
