import styled from 'styled-components'
import { Button } from '../../../shared/components'
import { color } from '../../../shared/utils/styles'

export const TileInfoContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
`

export const Line = styled.hr`
  width: 96px;
  border: 2px solid ${color.gray20};
`

export const RefreshContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const RefreshButton = styled(Button)`
  padding-left: 6px;
  padding-right: 0;
`
