import { API } from './API'
import { ORGANIZATIONS_PATH, PRODUCTS_PATH } from '../api/organizations'
import { ProductList } from '../models/product'

const OWNS_PRODUCT = 'Owns'

const queryNames = {
  GET_PRODUCTS: 'products',
  GET_BY_ORGANIZATION_ID: 'products-get-by-organization-id-',
}

const getProductNames = async (): Promise<string[]> => {
  const products: ProductList = await API.get(`${PRODUCTS_PATH}`)
  const productNames = products.filter((p) => p.name.toLowerCase() !== OWNS_PRODUCT.toLowerCase()).map((p) => p.name)

  return productNames
}

const getProductNamesByOrganizationId = async (organizationId: number): Promise<string[]> => {
  const products: ProductList = await API.get(`${ORGANIZATIONS_PATH}/${organizationId}/products`)
  return products.map((p) => p.name)
}

type AddProductParams = {
  organizationId: number
  productName: string
}

const addProduct = async (params: AddProductParams): Promise<any> => {
  const parameters = {
    body: { name: params.productName },
  }
  return await API.post(`${ORGANIZATIONS_PATH}/${params.organizationId}/products`, parameters)
}

type RemoveProductParams = {
  organizationId: number
  productName: string
}

const remove = async (params: RemoveProductParams): Promise<any> => {
  const parameters = {
    body: { name: params.productName },
  }
  return await API.remove(`${ORGANIZATIONS_PATH}/${params.organizationId}/products`, parameters)
}

export const ProductsAPI = {
  queryNames,
  getProductNames,
  getProductNamesByOrganizationId,
  addProduct,
  remove,
}
