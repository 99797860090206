import styled from 'styled-components'
import { Button, Row } from 'antd'
import { color, size } from '../../shared/utils/styles'
import { ReactComponent as IconGlobe } from '../../../src/assets/ico-globe.svg'
import { ReactComponent as IconContac } from '../../../src/assets/ico-message.svg'
import { ReactComponent as IconDocument } from '../../assets/img-document-icon.svg'

export const HeaderContainer = styled.div`
  text-align: center;
  position: relative;
  background-color: ${color.gray85};
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  padding-top: 1%;

  @media (min-width: ${size.large}) {
    display: flex;
  }

  h1 {
    color: white;
    border-bottom: 3px solid #ffffff;
  }

  p {
    color: white;
  }
`
export const SearchBy = styled.div`
  color: white;
  display: flex;
  flex-flow: row;
  // justify-content: space-between;
  justify-content: center;
  margin-top: 15%;
  padding-bottom: 5%;
`
export const LeftPart = styled.div`
  width: 88%;
`
export const Radio = styled.div`
  color: white;
`
export const SelectSolution = styled.div`
  color: white;
  margin-right: 20px;
`
export const MainInfoContainer = styled.div`
  background-color: ${color.gray85};
`
export const MainContent = styled.div`
  margin-left: 9%;
  margin-right: 9%;
  margin-bottom: 1%;
  padding-top: 4%;
  color: white;
`
export const IntegratedContainer = styled.div`
  color: white;
  background-color: #11161a;
  margin-left: 9%;
  margin-right: 9%;
  height: auto;
  display: flex;
  margin-top: 2%;
`
export const LeftContent = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 2%;
`
export const RightContainer1 = styled.div`
  display: flex;
  font-size: 16px;
  color: white;
  width: 94%;
  align-items: baseline;
  // justify-content: flex-start;
  background-color: ${color.gray80};
  border-radius: 12px;
  margin: 15px;
  padding: 15px;
  height: auto;
  flex-direction: column;
  color: white;
`
export const CompanyUpper = styled.section`
  display: flex;
  justify-content: start;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 25px;
  font-size: 0.8em;
  height: auto;
  width: 100%;
`
export const LogoSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
`
export const ImageBackground = styled.div`
  width: 100%;
  height: 100px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`
export const CompanyLogo = styled.img`
  width: 150px;
  height: 100px;
  font-size: 0.7em;
  padding: 3px 0px;
`
export const CompanyLink = styled.div`
  display: flex;
  align-items: center;
  margin-left: -3px;
`
export const DescSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 27px;
  width: 70%;
`
export const GlobeIcon = styled(IconGlobe)`
  height: 10px;
`
export const SolutionHeaderTitle = styled.div`
  font-size: 0.9em;
  color: ${color.blueBase};
`

export const SolutionContent = styled.div`
  font-size: 0.8em;
`

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContactHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`
export const Lower = styled.div`
  padding: 10px;
  width: 100%;
  margin-left: 88px;
}
`
export const LowerFlex = styled.div`
  display: flex; 
  flex-wrap: wrap;
  align-items: center;
}
`
export const SolutionHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  color: white;
  background-color: ${color.gray85};
  height: auto;
`
export const DesP1 = styled.p`
  font-size: 0.8em;
  margin-bottom: 40px;

  h1 {
    color: white;
  }

  h2 {
    color: white;
  }

  h3 {
    color: white;
  }
`

export const OlList = styled.ol`
  font-size: 0.8em;
  margin-bottom: 40px;
  text-transform: capitalize;

  li {
    margin-left: -30px;
  }
`

export const DesH = styled.p`
  font-size: 1.5em;
`

export const SolutionMaterialReelContainer = styled.div`
  font-size: 0.8em;
  margin-top: 25px;
  width: inherit;
  display: flex;
  flex-flow: column;
  align-items: left;
  justify-content: left;
`

export const SolutionMaterialReelTitle = styled.p`
  font-size: 1.5em;
  margin-left: 60px;
`

export const Bottom = styled.div`
  font-size: 0.8em;
  margin-top: 25px;
  margin-bottom: 25px;
  width: inherit;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`
export const LogoContact = styled.section`
  align-items: center;
`
export const ContactIcon = styled(IconContac)`
  height: 50px;
  margin-right: 10px;
`
export const CompanyLowerSol = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 3%;
  margin-top: 3%;
`
export const HeaderRight = styled.div`
  font-size: 1.5em;
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const TextArea = styled.div`
  font-size: 1.5em;
`
export const CompanyMiddleItem = styled.div`
  width: 90%;
  margin-left: 10px;
  margin-top: 15px;
  display: flex;
  font-size: 0.8em;
  ul {
    list-style-type: none;
    padding: 0px;
  }
`
export const ContactTitle = styled.span`
  color: ${color.blueBase};
  margin-left: 10px;
`

export const ContainerRow = styled(Row)`
  width: 100%;
`

export const DescP = styled.p`
  font-size: 0.9em;
  color: ${color.blueBase};
`
export const CompanyDescP = styled.p`
  margin-left: 25px;
  margin-top: 20px;
  overflow-wrap: break-word;
`

export const LinkHeading = styled.div`
  margin-left: 5px;
  font-size: 1.2em;
`

export const CompanyDesc = styled.p`
  margin-left: 0.2%;
`

export const ContactUsButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
`
export const LoadingContainter = styled.div`
  height: 70vh;
  padding: 40px;
`
export const VideoTitle = styled.div`
  margin-left: auto;
  font-size: 1em;
  text-align: center;
  margin-top: 5px;
`

export const DocumentIcon = styled(IconDocument)`
  margin-top: -1px;
  margin-left: -7px;
  margin-bottom: -5px;
`
