import React, { ChangeEvent, FC, useState } from 'react'
import { useQuery } from 'react-query'
import { H4, SidePanel } from '../../shared/components'
import { SimplifiedBuilding } from '../../shared/models/building'
import { BuildingsAPI } from '../../shared/api/buildings'
import BuildingTile from './BuildingTile'
import ImportBuildingForm from './ImportBuildingForm'
import BuildingImportTile from './ImportTile'
import { BuildingsStrings } from '../../shared/strings/BuildingsContent'
import {
  MessageContainer,
  TilesContainer,
  FilterContainer,
  HeaderRow,
  OrgRowContent,
  OrgRowContentLarge,
  SearchCreateRow,
  CreateButton,
  CountHeading,
  PaginationPlacing,
  SearchInput,
} from './Styles'
import BuildingTable from './Table'
import Pagination from '../../shared/components/Pagination'
import { TABLE_CONTENT_LENGTH } from '../../shared/constants/common'
import { ReactComponent as SearchIcon } from '../../assets/icon-search-find.svg'
import { ReactComponent as ClearIcon } from '../../assets/icon-search-clear.svg'
import { ResourcesAPI } from '../../shared/api/resources'
import { ResourceOrgObject, ResourceOrg } from '../../shared/models/resource'

const Buildings: FC = () => {
  const [isCreatePanelOpen, setIsCreatePanelOpen] = useState(false)
  const { isLoading, error, data } = useQuery(BuildingsAPI.queryNames.LIST, () => BuildingsAPI.listBuildings())
  const getResourceOrg = useQuery(ResourcesAPI.queryNames.GET_BY_ORGANIZATION_ID, () =>
    ResourcesAPI.getOrgNameByResourceTypeAndId('building')
  )

  const [buildingFilter, setBuildingFilter] = useState('')
  const [page, setPage] = useState(0)
  const filResult: ResourceOrg[] = []
  const showContentRows = () => {
    let fil
    getResourceOrg.data.forEach((obj: ResourceOrgObject) => {
      fil = filResult.filter((val) => val.resourceId === obj.resourceId)
      if (fil.length === 0) {
        filResult.push({
          resourceId: obj.resourceId,
          uniqueId: obj.uniqueId,
          type: obj.type,
          organization: obj.name ? [obj.name] : [],
          resource_name: obj.resource_name,
        })
      } else {
        filResult.forEach((ob) => {
          if (ob.resourceId === obj.resourceId && obj.name) {
            ob.organization?.push(obj.name)
          }
        })
      }
    })
  }
  if (getResourceOrg?.data?.length > 0) {
    showContentRows()
  }

  const list = (() => {
    if (isLoading) {
      return <MessageContainer>Loading buildings list...</MessageContainer>
    }
    if (error) {
      return <MessageContainer>Cannot load buildings list</MessageContainer>
    }

    const tiles = data
      ? data.map((building: SimplifiedBuilding) => <BuildingTile key={building.buildingId} building={building} />)
      : []

    return (
      <TilesContainer>
        <BuildingImportTile onClickCreate={() => setIsCreatePanelOpen(true)} />
        {tiles}
      </TilesContainer>
    )
  })()

  const onClickCreate = () => {
    setIsCreatePanelOpen(true)
  }
  const onBuildingFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    setBuildingFilter(event.target.value.toLowerCase())
  }
  const showBuildingList = () => {
    if (isLoading) {
      return <MessageContainer>{BuildingsStrings.loadingbuilingList}</MessageContainer>
    }
    if (error) {
      return <MessageContainer>{BuildingsStrings.buildingListError}</MessageContainer>
    }

    const infoTiles = data
      ? data
          .filter((building: SimplifiedBuilding) => building.buildingId.toLowerCase().includes(buildingFilter))
          .map((building: SimplifiedBuilding) => (
            <BuildingTable key={building.buildingId} building={building} resourceOrg={filResult} />
          ))
      : []

    return (
      <>
        <SearchCreateRow>
          <FilterContainer>
            <SearchInput
              id="search-building-input-js"
              placeholder="Search building"
              onChange={onBuildingFilterChange}
              suffix={buildingFilter ? <ClearIcon onClick={() => setBuildingFilter('')} /> : <SearchIcon />}
              value={buildingFilter}
            />
          </FilterContainer>
          <CreateButton id="open-import-building-button-js" onClick={onClickCreate} type="primary" size="large">
            Import building
          </CreateButton>
        </SearchCreateRow>
        <CountHeading></CountHeading>
        <HeaderRow>
          <OrgRowContentLarge>Building ID</OrgRowContentLarge>
          <OrgRowContent>Name</OrgRowContent>
          <OrgRowContent>Organization</OrgRowContent>
          <OrgRowContent>Group</OrgRowContent>
          <OrgRowContent>Areas</OrgRowContent>
          <OrgRowContent>Updated</OrgRowContent>
          <OrgRowContent></OrgRowContent>
        </HeaderRow>
        {infoTiles.slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))}
        <PaginationPlacing>
          <Pagination dataLength={infoTiles.length == 0 ? 1 : infoTiles.length} page={page} setPage={setPage} />
        </PaginationPlacing>
      </>
    )
  }
  return (
    <div>
      <H4>{BuildingsStrings.buildings}</H4>
      {showBuildingList()}
      <SidePanel
        key="import-building-side-panel"
        isOpen={isCreatePanelOpen}
        title="Import building"
        showBackButton={true}
        onClose={() => setIsCreatePanelOpen(false)}
      >
        <ImportBuildingForm onCancelCreate={() => setIsCreatePanelOpen(false)} />
      </SidePanel>
    </div>
  )
}

export default Buildings
