import React from 'react'
import { H4, P, UL, OL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'
import { HashLink as Link } from 'react-router-hash-link'

export const ReleaseNotesLog = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        December 2022
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        December Release Notes summarizes the new and enhanced features, bug fixes, how to upgrade and about the
        deprecated APIs.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li>Service Info API</li>
          <UL>
            <li>
              Introducing Service Info API 2 using <Link to={Paths.ServiceInfoAPIV2Docs.path}>REST</Link> and{' '}
              <Link to={Paths.ServiceInfoWebhookAPIDocs.path}>Webhooks</Link>.
            </li>
            <li>
              Service Info API 2 for Rest Calls can be accessed with these latest endpoints.
              <ul style={{ fontWeight: 'bold' }}>
                {' '}
                List Service Orders - GET /api/v2/equipment/equipmentId/serviceOrders
              </ul>
              <ul style={{ fontWeight: 'bold' }}>
                {' '}
                Get Service Order details - GET /api/v2/equipment/equipmentId/serviceOrders/workOrderNumber
              </ul>
            </li>
            <li>Webhook subscription for Service Info 2 can be done with Service Order Event Type.</li>
            <li>Authentication API 1.0 is deprecated.</li>
            <li> Service Info API 2 is compatible with Authentication API 2 only.</li>
            <li>Service Info API 1.0 deprecated.</li>
            <li>No product level bug fixes applicable for Service Info APIs.</li>
          </UL>

          <li style={{ marginTop: '30px' }}>Equipment Status API 2</li>
          <UL>
            <li>Service Status is renamed to Equipment Status Information and updated for both REST and Webhooks. </li>
            <li>
              Updated Endpoints/Event Types :
              <ul style={{ fontWeight: 'bold' }}> Rest - POST /api/v2/equipment/search/status</ul>
              <ul style={{ fontWeight: 'bold' }}> Webhooks - Equipment Status Type</ul>
            </li>
            <li>
              See the latest{' '}
              <Link to={Paths.EquipmentStatus2RestApiPageDocs.path + '#getEquipmentEntrapmentByIds'}>
                Equipment Status Information documentation{' '}
              </Link>{' '}
              to learn more about the newly added data fields.
            </li>
            <li>Webhook Payload updates:</li>
            <UL>
              <li> subject field name is replaced with equipmentId. </li>
              <li>
                {' '}
                technicianComment is removed from the payload and is now available in the service order details rest
                payload.
              </li>
            </UL>
            <li>
              Bug fix for solution usage which allowed users to use Authentication API 1.0 with Equipment Status 2.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Description column is now available in the Resource section of Organization Details for the customers.{' '}
            </li>
            <li>
              Improvements to documentation for newly added fields are explained and available on the Portal in API
              Document Section.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>
            {' '}
            No product level bug fixes applicable and no new feature release for Service Robot and Elevator Call APIs.
          </li>
        </OL>
      </P>
    </>
  )
}
