import { FC } from 'react'
import styled from 'styled-components'
import { Switch as AntSwitch } from 'antd'
import { SwitchProps } from 'antd/lib/switch'
import { color } from '../../utils/styles'

export const AntSwitchWebhookContainer = styled.div`
  .ant-switch.ant-switch-checked {
    background-color: ${color.blueBase};
    .ant-switch-handle::before {
      background-color: ${color.white};
    }
  }
  .ant-switch {
    .ant-switch-handle::before {
      background-color: ${color.white};
    }
  }

  margin-left: 6px;
  margin-right: 6px;
`

type SwitchPropsWithId = SwitchProps & { id: string }

export const StyledSwitch: FC<SwitchPropsWithId> = styled(AntSwitch)``
