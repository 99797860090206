import { Form, Input } from 'antd'
import React, { ChangeEvent, FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { AuthFormLabel, AuthFormWrapper, ErrorMessage, SuccessView, Toast } from '../../../shared/components'
import {
  COGNITO_PASSWORD_MIN_LENGTH,
  COGNITO_PASSWORD_PATTERN,
  PASSWORD_DESCRIPTION,
  PASSWORD_REG_EXP,
  REQUIRED_FIELD,
} from '../../../shared/constants/auth'
import { useAuth } from '../../../shared/contexts/authContext'
import { ChangePasswordStrings } from '../../../shared/strings/MyProfileContent'
import Paths from '../../Paths'
import { Button } from './Styles'

type ChangePasswordData = {
  oldPassword: string
  newPassword: string
}

const ChangePasswordForm: FC = () => {
  const { changePassword } = useAuth()
  const history = useHistory()
  const {
    handleSubmit,
    control,
    watch,
    reset,
    trigger,
    formState: { errors, isSubmitted },
  } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] = useState<string | undefined>()
  const [isChangePasswordErrorVisible, setIsChangePasswordErrorVisible] = useState(false)

  const onSubmit = async (data: ChangePasswordData) => {
    setIsLoading(true)
    setIsChangePasswordErrorVisible(false)
    setIsSuccess(false)
    try {
      await changePassword(data.oldPassword, data.newPassword)
      setIsSuccess(true)
      reset()
    } catch (err) {
      let message = ChangePasswordStrings.changePasswordError

      if (err?.code === 'NotAuthorizedException') {
        // Replace the message if NotAuthorizedException that mentions
        // errors in username also.
        message = ChangePasswordStrings.notAuthorizedException
      } else if (err?.code) {
        // If there is Cognito error type use the message if it exists
        message = err?.message || ChangePasswordStrings.changePasswordError
      }

      setChangePasswordErrorMessage(message)
      setIsChangePasswordErrorVisible(true)
    } finally {
      setIsLoading(false)
    }
  }

  const triggerPasswordConfirmationValidation = () => {
    if (isSubmitted) {
      trigger('passwordConfirmation')
    }
  }

  const InputForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Form.Item validateStatus={errors.oldPassword ? 'error' : ''}>
        <AuthFormLabel htmlFor="old-password-input-js">{ChangePasswordStrings.oldPassword}</AuthFormLabel>
        <Controller
          render={({ field }) => <Input.Password id="old-password-input-js" {...field} autoComplete="off" />}
          name="oldPassword"
          control={control}
          defaultValue=""
          rules={{
            minLength: { value: COGNITO_PASSWORD_MIN_LENGTH, message: ChangePasswordStrings.oldPasswordTooShort },
            pattern: { value: COGNITO_PASSWORD_PATTERN, message: ChangePasswordStrings.oldPasswordNotTrimmed },
            required: true,
          }}
        />
        {errors.oldPassword && <ErrorMessage>{errors.oldPassword.message || REQUIRED_FIELD}</ErrorMessage>}
      </Form.Item>
      <Form.Item validateStatus={errors.newPassword ? 'error' : ''}>
        <AuthFormLabel htmlFor="new-password-input-js">{ChangePasswordStrings.newPassword}</AuthFormLabel>
        <Controller
          render={({ field }) => (
            <Input.Password
              id="new-password-input-js"
              {...field}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e)
                triggerPasswordConfirmationValidation()
              }}
              autoComplete="off"
            />
          )}
          name="newPassword"
          control={control}
          defaultValue=""
          rules={{
            pattern: {
              value: PASSWORD_REG_EXP,
              message: PASSWORD_DESCRIPTION,
            },
            required: true,
          }}
        />
        {errors.newPassword && <ErrorMessage>{errors.newPassword.message || REQUIRED_FIELD}</ErrorMessage>}
      </Form.Item>
      <Form.Item validateStatus={errors.passwordConfirmation ? 'error' : ''}>
        <AuthFormLabel htmlFor="confirm-new-password-js">{ChangePasswordStrings.confirmNewPassword}</AuthFormLabel>
        <Controller
          render={({ field }) => <Input.Password id="confirm-new-password-js" {...field} autoComplete="off" />}
          name="passwordConfirmation"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            validate: (value) => value === watch('newPassword') || ChangePasswordStrings.passwordsNotMatchError,
          }}
        />
        {errors.passwordConfirmation && (
          <ErrorMessage>{errors.passwordConfirmation.message || REQUIRED_FIELD}</ErrorMessage>
        )}
      </Form.Item>
      <Button id="update-password-button-js" type="primary" htmlType="submit" loading={isLoading}>
        {ChangePasswordStrings.updatePassword}
      </Button>
    </form>
  )

  return (
    <AuthFormWrapper>
      <Toast
        isVisible={isChangePasswordErrorVisible}
        text={changePasswordErrorMessage}
        onDismiss={() => setIsChangePasswordErrorVisible(false)}
        type="error"
      />
      {isSuccess ? (
        <SuccessView
          successText={ChangePasswordStrings.passwordUpdatedSuccess}
          successTextId="change-password-success-message-js"
          buttonText={ChangePasswordStrings.returnToDashboardButton}
          buttonOnClick={() => history.push(Paths.Dashboard.path)}
        />
      ) : (
        InputForm
      )}
    </AuthFormWrapper>
  )
}

export default ChangePasswordForm
