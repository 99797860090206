import React, { FC, useState, useContext, useEffect } from 'react'
import { Button, Row, Col } from 'antd'
import dummyPartner from '../../assets/dummyPartner.jpg'
import { CommonAPI, SalespagePublicAPI } from '../../shared/api/salespage'
import {
  Bottom,
  CompanyLink,
  CompanyLogo,
  CompanyUpper,
  ContactIcon,
  DesH,
  DesP1,
  DescSection,
  GlobeIcon,
  HeaderRight,
  ImageBackground,
  IntegratedContainer,
  LeftPart,
  LogoContact,
  LogoSection,
  MainContent,
  MainInfoContainer,
  RightContainer1,
  TextArea,
  OlList,
  ContainerRow,
  DescP,
  CompanyDescP,
  ContactUsButton,
  LoadingContainter,
  SolutionMaterialReelContainer,
  SolutionMaterialReelTitle,
  LinkHeading,
  Lower,
  LowerFlex,
  VideoTitle,
  DocumentIcon,
} from './Styles'
// eslint-disable-next-line import/no-unresolved
import { SolutionDetails } from 'src/shared/strings/IntegratedSolution'
// eslint-disable-next-line import/no-unresolved
import { StyledLine, StyledLineBottom, StyledLineDivide } from 'src/Dashboard/Statistics/Styles'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Document, Video } from '../../Dashboard/Salespage/Models'
import SendEmail from '../SendEmail'
import { CreateFormStrings } from '../../shared/strings/PartnersContent'
import ReactPlayer from 'react-player/youtube'
import { MasterDataContext } from '../SolutionsList/state'
import { AnalyticsTriggerEvent } from '../../shared/adobe-analytics'
import { sendAnalyticsEvent } from '../../shared/adobe-analytics/send-analytics-event'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

const SolutionDetailsPage: FC = () => {
  const { solutionId } = useParams<{ solutionId: string }>()
  const decodedValue = atob(solutionId)
  const decodedSolutionId = decodedValue.split('_')[1]
  const decodedSolutionName = decodedValue.split('_')[0]

  const { isLoading, error, data } = useQuery(SalespagePublicAPI.publicQueryNames.GET_SOLUTION_DETIALS, () =>
    SalespagePublicAPI.getSolutionDetails(decodedSolutionId)
  )

  const onClickContactSupport = () => {
    setIsContactSupportOpen(true)
  }

  const closeContactPartner = () => {
    setIsContactPartnerOpen(false)
    setIsContactSupportOpen(false)
  }

  const onClickContactPartner = () => {
    setIsContactPartnerOpen(true)
  }

  const [isContactSupportOpen, setIsContactSupportOpen] = useState(false)
  const [isContactPartnerOpen, setIsContactPartnerOpen] = useState(false)
  const { masterData, setMasterData } = useContext(MasterDataContext)

  const history = useHistory()
  const onClickBack = () => {
    history.push(`/integrated-solutions`)
  }

  // when solution detail page is reloaded
  useEffect(() => {
    if (!masterData?.api) {
      CommonAPI.getMasterData().then((data) => {
        setMasterData(data)
      })
    }
    sendAnalyticsEvent(AnalyticsTriggerEvent.PAGES_VIEWED, {
      pageViewed: 'Solution Detail Page :' + decodedSolutionName,
    })
  }, [])

  const getKeySegmentsContent = (keySegments: string[]) => {
    const selectedSegments: string[] = []
    masterData?.segments?.map((segment: any) => {
      if (keySegments?.indexOf(segment.value) > -1) {
        selectedSegments.push(segment.label)
      }
    })
    return selectedSegments?.map((item: string, index: number) => {
      return <li key={`keySegments_${index}`}>{item}</li>
    })
  }

  const getAPIsContent = (apis: string[]) => {
    const selectedApis: string[] = []
    masterData?.api?.map((api: any) => {
      if (apis?.indexOf(api.value) > -1) {
        selectedApis.push(api.label)
      }
    })
    return selectedApis?.map((item: string, index: number) => {
      return <li key={`apis_${index}`}>{item}</li>
    })
  }

  const getCountryContent = (countries: string[]) => {
    const selectedCountries: string[] = []
    masterData?.country?.map((country: any) => {
      if (countries?.indexOf(country.value) > -1) {
        selectedCountries.push(country.label)
      }
    })
    return selectedCountries?.map((item: string, index: number) => {
      return <li key={`country_${index}`}>{item}</li>
    })
  }

  const getCategoryContent = (categories: string[]) => {
    const selectedCategory: string[] = []
    masterData?.category?.map((category: any) => {
      if (categories?.indexOf(category.value) > -1) {
        selectedCategory.push(category.label)
      }
    })
    return selectedCategory?.map((item: string, index: number) => {
      return <li key={`category_${index}`}>{item}</li>
    })
  }

  const getLinkContent = () => {
    return (
      <>
        <Lower>
          {data?.solutionDetails[0]?.integratorLinks?.videos?.length > 0 && (
            <>
              <LinkHeading>{CreateFormStrings.videos}</LinkHeading>
              <LowerFlex>
                {data?.solutionDetails[0]?.integratorLinks?.videos?.map((data: Video, index: number) => (
                  <Row key={`video${index}`} gutter={2} style={{ marginLeft: '30px', marginTop: '25px' }}>
                    {video(data?.videoUrl, data?.fileName)}
                  </Row>
                ))}
              </LowerFlex>
              <StyledLineDivide />
            </>
          )}
          {data?.solutionDetails[0]?.integratorLinks?.documents?.length > 0 && (
            <>
              {' '}
              <LinkHeading>{CreateFormStrings.documentTitle}</LinkHeading>
              <Row gutter={20}>
                {data?.solutionDetails[0]?.integratorLinks?.documents?.map((data: Document, index: number) => (
                  <Col span={4} key={`document${index}`} style={{ marginLeft: '15px', marginTop: '10px' }}>
                    <DocumentIcon />
                    <a href={data?.documentUrl} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '2px' }}>
                      {data?.fileName}
                    </a>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Lower>
      </>
    )
  }

  const video = (url: string, fileName: string) => {
    return (
      <div>
        <ReactPlayer url={url} width="300px" height="170px" controls />
        <VideoTitle>{fileName}</VideoTitle>
      </div>
    )
  }

  const getContent = () => {
    return (
      <ContainerRow gutter={24}>
        <Col span={9} style={{ marginLeft: '3%', marginTop: '3%', marginBottom: '3%' }}>
          <LeftPart>
            <DesH>{data?.solutionDetails[0]?.solutionName}</DesH>
            <DescP> {SolutionDetails.Solution}</DescP>
            <DesP1 dangerouslySetInnerHTML={{ __html: sanitizer(data?.solutionDetails[0]?.solutionHighlights) }} />
            <DescP>Integrator Solution</DescP>
            <OlList>{getCategoryContent(data?.solutionDetails[0]?.category)}</OlList>
            <DescP>Key Customer Segments</DescP>
            <OlList>{getKeySegmentsContent(data?.solutionDetails[0]?.keyCustomerSegments)}</OlList>
            <DescP>Countries available for distribution</DescP>
            <OlList>{getCountryContent(data?.solutionDetails[0]?.country)}</OlList>
            <DescP>API used</DescP>
            <OlList>{getAPIsContent(data?.solutionDetails[0]?.apis)}</OlList>
          </LeftPart>
        </Col>
        <Col span={15} style={{ marginRight: '-40%', marginTop: '3%', marginBottom: '5%', paddingRight: '52px' }}>
          <RightContainer1>
            <HeaderRight>{'Company info'}</HeaderRight>
            <CompanyUpper>
              <LogoSection>
                <TextArea>{data?.solutionDetails[0]?.partnerName}</TextArea>
                <ImageBackground>
                  <CompanyLogo src={data?.solutionDetails[0]?.image ? data?.solutionDetails[0]?.image : dummyPartner} />
                </ImageBackground>
                <CompanyLink>
                  <GlobeIcon />
                  <a href={data?.solutionDetails[0]?.websiteLink} target="_blank" rel="noopener noreferrer">
                    {data?.solutionDetails[0]?.websiteLink}
                  </a>
                </CompanyLink>
              </LogoSection>
              <DescSection>
                <CompanyDescP>{data?.solutionDetails[0]?.companyInfo}</CompanyDescP>
              </DescSection>
            </CompanyUpper>
            <StyledLine />
            <Bottom>
              <div style={{ fontSize: '2.1em' }}> {SolutionDetails.AnyQueries}</div>
              <div style={{ fontSize: '1.1em' }}> {SolutionDetails.GetInTouch}</div>
              <LogoContact>
                <ContactUsButton type="link" onClick={onClickContactPartner}>
                  <ContactIcon />
                  Connect With Integrator
                </ContactUsButton>
              </LogoContact>
            </Bottom>
          </RightContainer1>
        </Col>
        {(data?.solutionDetails[0]?.integratorLinks?.videos?.length > 0 ||
          data?.solutionDetails[0]?.integratorLinks?.documents?.length > 0) && (
          <>
            <SolutionMaterialReelContainer>
              <SolutionMaterialReelTitle>{CreateFormStrings.solutionMaterialReel}</SolutionMaterialReelTitle>
            </SolutionMaterialReelContainer>
            <StyledLineBottom />
            {getLinkContent()}
          </>
        )}
        <StyledLineBottom />
        <Bottom>
          <LogoContact>
            <ContactUsButton style={{ fontSize: '1.1em' }} type="link" onClick={onClickContactSupport}>
              {CreateFormStrings.emailToKoneSupportLabel}
            </ContactUsButton>
          </LogoContact>
        </Bottom>
      </ContainerRow>
    )
  }

  return (
    <>
      <MainInfoContainer>
        <MainContent>
          <Button style={{ marginRight: '2%' }} onClick={() => onClickBack()} type="primary">
            Back
          </Button>
          {data?.solutionDetails[0]?.partnerName}
          <StyledLine />
        </MainContent>
        <IntegratedContainer>
          {isLoading ? <LoadingContainter>{'Loading Solution details'}</LoadingContainter> : getContent()}
        </IntegratedContainer>
      </MainInfoContainer>
      <SendEmail
        isContactPartnerOpen={isContactPartnerOpen}
        isContactSupportOpen={isContactSupportOpen}
        closeEmailPopUp={closeContactPartner}
        partnerId={data?.solutionDetails[0]?.partnerId}
        partnerName={data?.solutionDetails[0]?.partnerName}
      />
    </>
  )
}
export default SolutionDetailsPage
