import { Col, Row } from 'antd'
import React from 'react'
import useScrollToHash from '../../../shared/utils/scrollToHash'
import { Introduction } from './sections/Introduction'
import { TableOfContents } from './sections/TableOfContents'
import { WebhookEvents } from './sections/WebhookEvents'

const EquipmentStatusAPIWebhookEventsDocumentation = () => {
  useScrollToHash()

  return (
    <Row>
      {/* Everything is wrapped into one column, within that, rows with 2 columns each */}
      <Col span={24}>
        <Introduction />
        <TableOfContents />
        <WebhookEvents />
      </Col>
    </Row>
  )
}

export default EquipmentStatusAPIWebhookEventsDocumentation
