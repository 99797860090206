import styled, { keyframes } from 'styled-components'

const motion = (props: any) => keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 1px;
  border: 4px solid ${(p) => p.color};
  border-radius: 50%;
  animation: ${(p) => motion(p)} 1.2s infinite linear;
  border-color: #338bc3 #338bc359 #338bc359 #338bc359;

  :nth-child(1) {
    animation-delay: -0.65s;
  }
  :nth-child(2) {
    animation-delay: -0.3s;
  }
  :nth-child(3) {
    animation-delay: -0.25s;
  }
`
