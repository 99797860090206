import { Input } from 'antd'
import styled from 'styled-components'
import { ReactComponent as CopyIcon } from '../../../assets/icons-copy.svg'
import { ReactComponent as OkCheckIcon } from '../../../assets/ok-check-icon.svg'
import { color } from '../../../shared/utils/styles'

export const EditWrapper = styled.div`
  width: 350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const AppDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  word-wrap: break-word;
`

export const AppName = styled.p`
  color: ${color.gray20};
  font: normal normal normal 28px/42px KONE Information;
  margin-bottom: 25px;
`

export const AppDescription = styled.p`
  color: ${color.gray20};
  margin-bottom: 36px;
`

export const FormLabel = styled.p`
  margin-top: 32px;
  margin-bottom: 8px;
  display: block;
  color: ${color.gray20};
`

export const CenterContainer = styled.div`
  display: flex;

  .ant-input {
    cursor: default;
  }
`

export const InputName = styled(Input)`
  color: ${color.gray75} !important;
  width: 316px;
  height: 32px;
  margin-bottom: 36px;
  margin-right: 12px;
`

export const CopyIconStyle = styled(CopyIcon)`
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-top: 3px;
`
export const Line = styled.hr`
  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid ${color.gray70};
  width: 100%;
`

export const OkIcon = styled(OkCheckIcon)`
  margin: 10px 0 45px 0;
`

export const SuccessContainer = styled.div`
  text-align: center;
`

export const SuccessMessage = styled.p`
  font-size: 28px;
`

export const StyledTooltip = styled.div`
  .ant-tooltip-inner {
    color: ${color.black};
    background-color: ${color.white};
    width: 55px;
    height: 16px;
    text-align: right;
    font: normal normal normal 14px/16px KONE Information;
    margin-top: -32px;
    margin-right: 8px;
    min-width: 28px;
    min-height: 28px;
  }
  .ant-tooltip-arrow {
    margin-top: -18px;
  }
  .ant-tooltip-arrow-content {
    background-color: ${color.white};
  }
`

export const IconContainer = styled.div`
  display: flex;
  &:hover {
    p {
      color: ${color.blueTint20};
    }

    .a {
      fill: ${color.blueTint20};
    }
  }
`

export const CenterLoader = styled.div`
  display: flex;
  justify-content: center;
`
