import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { FooterContainer, FooterLinkText } from './Styles'
import isInternalURL from '../../shared/utils/isInternalURL'
import { FooterMenuStrings } from '../../shared/strings/FooterContent'

interface Props {
  items: FooterMenuItem[]
}

interface FooterMenuItem {
  name: string
  key: string
  link: string
}

const FooterMenuList: FC<Props> = (props) => {
  function generateMenuItems(items: FooterMenuItem[]) {
    return items.map((item, i) => {
      const menuItemContent = isInternalURL(item.link) ? (
        <Link to={item.link}>{item.name}</Link>
      ) : (
        <a href={item.link} target="_blank" rel="noreferrer noopener">
          {item.name}
        </a>
      )
      return <FooterLinkText key={i}>{menuItemContent}</FooterLinkText>
    })
  }
  return <FooterContainer>{generateMenuItems(props.items)}</FooterContainer>
}

const FooterMenu: FC = () => {
  function getMenuItems() {
    let menuItems: FooterMenuItem[] = []

    menuItems = [
      { key: '0', name: FooterMenuStrings.legalNotice, link: 'https://www.kone.com/en/legal-notice.aspx' },
      { key: '1', name: FooterMenuStrings.privacyStatement, link: '/privacy-statement' },
      { key: '2', name: FooterMenuStrings.termsAndConditions, link: '/terms-and-conditions' },
      { key: '3', name: FooterMenuStrings.contact, link: 'https://www.kone.com/en/contact.aspx' },
    ]
    return menuItems
  }

  return <FooterMenuList items={getMenuItems()} />
}

export default FooterMenu
