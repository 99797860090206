import React, { FC, ChangeEvent, useState, useRef, useEffect } from 'react'
import { H4 } from '../../../../shared/components'
import {
  orgProductDetails,
  ApiProductMappings,
  headers,
  productLegend,
} from '../../../../shared/strings/StatisticDashboard'
import { TitleLink } from '../../../../App/Header/Styles'
import { Checkbox } from 'antd'
import { ReactComponent as LeftArrow } from '../../../../assets/icons-back-arrow.svg'
import {
  Export,
  FilterButton,
  FilterIcon,
  OrgRowCountry,
  OrgRowContentMedium,
  Row,
  HeaderRow,
  FilterCheckbox,
  StyleCard,
  SelectedFilterIcon,
} from './Styles'
import { StatisticsAPI } from '../../../../shared/api/statistics'
import { useQuery } from 'react-query'
import { OrgProductDetails } from '../../../../shared/models/statistics'
import countryLookup from 'country-code-lookup'
import {
  SearchCreateRow,
  FilterContainer,
  SearchInput,
  MessageContainer,
  CountHeading,
  OrgRowContentLarge,
  OrgRowContent,
  PaginationPlacing,
  ProfileDetails,
} from '../../../DeviceMappings/Styles'
import { ReactComponent as SearchIcon } from '../../../../assets/icon-search-find.svg'
import { ReactComponent as ClearIcon } from '../../../../assets/icon-search-clear.svg'
import Pagination from '../../../../shared/components/Pagination'
import { TABLE_CONTENT_LENGTH } from '../../../../shared/constants/common'
import { CSVLink } from 'react-csv'

const { Group: CheckboxGroup } = Checkbox

const OrgProductsDetails: FC = () => {
  const { isLoading, error, data } = useQuery(StatisticsAPI.queryNames.GET_ORG_AND_PRODUCTS_DETAILS, () =>
    StatisticsAPI.getOrgProductDetails()
  )

  const [partnerFilter, setPartnerFilter] = useState('')
  const [page, setPage] = useState(0)
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProducts, setSelectedProducts] = useState<string[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isProductOpen, setIsProductOpen] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement | null>(null)

  // Close menu when clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false)
        setIsProductOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  const handleCountryClick = (): void => {
    setIsOpen(!isOpen)
  }
  const handleProductClick = (): void => {
    setIsProductOpen(!isProductOpen)
  }

  const handleCheckboxChange = (value: string[]) => {
    onProductChange(value)
  }

  const handleCheckboxCountry = (value: string[]) => {
    onCountryChange(value)
  }

  const filResult: OrgProductDetails[] = []

  console.log('selected products', selectedProducts)
  const showContentRows = () => {
    let filteredResObject
    data.data?.forEach((obj: OrgProductDetails) => {
      filteredResObject = filResult.filter((val) => val.partnername === obj.partnername)
      if (filteredResObject.length === 0) {
        filResult.push({
          partnername: obj.partnername,
          country: obj.country,
          productname: obj.productname,
          equipmentscount: obj.equipmentscount,
        })
      }
    })
  }
  if (data) {
    showContentRows()
  }

  const onCountryChange = (value: string[]) => {
    setPage(0)
    setSelectedCountries(value)
  }

  const onProductChange = (value: string[]) => {
    setPage(0)
    setSelectedProducts(value)
  }

  const getProductsFromVersions = (key: string) => {
    return ApiProductMappings.get(key)
  }

  console.log('checking product data from details', data)

  const getCountries = () => {
    if (!data || !data.data) {
      return []
    }

    // List of target product names
    const targetProducts = ['serviceinfo', 'rtm', 'callgiving', 'robotcall']

    // Extract unique countries that match the targeteed API Product names
    const uniqueCountries = data.data.reduce((acc: any, curr: any) => {
      if (targetProducts.includes(curr.productname) && curr.country && !acc.includes(curr.country)) {
        acc.push(curr.country)
      }
      return acc
    }, [])

    return uniqueCountries.map((country: any) => ({
      label: countryLookup.byIso(country)?.country || country,
      value: country,
    }))
  }

  const checkCountry = (country: string | undefined, selCountries: string[]): boolean => {
    if (!country) {
      return false
    }

    return selCountries.some((ct) => {
      return country === ct.toString().toLowerCase()
    })
  }

  const checkProduct = (productname: string | undefined, selproduct: string[]): boolean => {
    if (!productname) {
      return false
    }

    return selproduct.some((pr) => {
      return productname === pr.toString().toLowerCase()
    })
  }

  const onFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    setPartnerFilter(event.target.value.toLowerCase())
  }

  const orgProductContent = (val: OrgProductDetails) => {
    return (
      <ProfileDetails>
        <Row>
          <OrgRowContent>{val.partnername}</OrgRowContent>
          <OrgRowContent> {countryLookup.byIso(val.country ? val.country : 0)?.country}</OrgRowContent>
          <OrgRowContent>{getProductsFromVersions(val.productname)}</OrgRowContent>
          <OrgRowContent>{val.equipmentscount}</OrgRowContent>
        </Row>
      </ProfileDetails>
    )
  }
  const showorgProductList = () => {
    if (isLoading) {
      return <MessageContainer>{orgProductDetails.header}</MessageContainer>
    }
    if (error) {
      return <MessageContainer>{orgProductDetails.loadingList}</MessageContainer>
    }

    const filteredData = data?.data
      .filter((val: OrgProductDetails) => {
        if (val.productname === 'equipmentstatus' || val.productname === 'Owns') {
          return false
        }

        const partnerNameMatch = val.partnername?.toString().toLowerCase().includes(partnerFilter.toLowerCase())
        const countryNameMatch = countryLookup
          .byIso(val.country ? val.country : 0)
          ?.country.toString()
          .toLowerCase()
          .includes(partnerFilter.toLowerCase())
        const productNameMatch =
          val.productname?.toString().toLowerCase().includes(partnerFilter.toLowerCase()) ||
          getProductsFromVersions(val.productname)?.toLowerCase().includes(partnerFilter.toLowerCase())
        const equipmentCountMatch = val.equipmentscount?.toString().toLowerCase().includes(partnerFilter.toLowerCase())

        // Check if any field matches the filter criteria
        return partnerFilter !== undefined
          ? partnerNameMatch || countryNameMatch || productNameMatch || equipmentCountMatch
          : true
      })
      .filter((val: OrgProductDetails) => {
        // Convert selectedCountries to country names using countryLookup.byIso
        const convertedSelectedCountries = selectedCountries.map((countryCode) => {
          const countryName = countryLookup.byIso(countryCode)?.country
          return countryName ? countryName.toString().toLowerCase() : countryCode.toString().toLowerCase()
        })

        // Filter by selected countries
        return convertedSelectedCountries.length > 0
          ? checkCountry(
              countryLookup
                .byIso(val.country ? val.country : 0)
                ?.country.toString()
                .toLowerCase(),
              convertedSelectedCountries
            )
          : true
      })
      .filter((val: OrgProductDetails) => {
        // Filter by selected products
        return selectedProducts.length > 0
          ? checkProduct(val.productname?.toString().toLowerCase(), selectedProducts)
          : true
      })
    console.log('checking values', selectedCountries)

    const infoTable = filteredData.map((val: OrgProductDetails) => orgProductContent(val))

    const csvData = filteredData.map((val: OrgProductDetails) => ({
      partnername: val.partnername,
      country: countryLookup.byIso(val.country ? val.country : 0)?.country,
      productname: getProductsFromVersions(val.productname),
      equipmentscount: val.equipmentscount,
    }))

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLink
            id="go-to-org-product-link-js"
            to="/dashboard/dashboard-statistics"
            style={{ width: 'fit-content' }}
          >
            <LeftArrow />
          </TitleLink>
          <H4>{orgProductDetails.header}</H4>
        </div>
        <SearchCreateRow>
          <FilterContainer>
            <SearchInput
              id="search-partner-input-js"
              placeholder="Search"
              onChange={onFilterChange}
              suffix={partnerFilter ? <ClearIcon onClick={() => setPartnerFilter('')} /> : <SearchIcon />}
              value={partnerFilter}
            />
          </FilterContainer>
          <Export id="export-button-js">
            <CSVLink data={csvData} headers={headers} filename={'org-product-details.csv'}>
              {orgProductDetails.export}
            </CSVLink>
          </Export>
        </SearchCreateRow>
        <CountHeading>Showing {infoTable.length} records</CountHeading>
        <HeaderRow>
          <OrgRowContentMedium>{orgProductDetails.partnername}</OrgRowContentMedium>
          <OrgRowContentMedium>
            {' '}
            <OrgRowCountry>
              <FilterButton onClick={handleCountryClick}>
                {selectedCountries.length > 0 ? <SelectedFilterIcon /> : <FilterIcon />}
                <OrgRowContentMedium>{orgProductDetails.country}</OrgRowContentMedium>
              </FilterButton>

              {isOpen && (
                <StyleCard ref={menuRef}>
                  <CheckboxGroup
                    style={{ width: '60%', display: 'flex', flexDirection: 'column' }}
                    value={selectedCountries}
                    onChange={(value) => {
                      handleCheckboxCountry(value as string[])
                    }}
                  >
                    {getCountries().map((country: any) => (
                      <FilterCheckbox key={country.value} value={country.value}>
                        {country.label}
                      </FilterCheckbox>
                    ))}
                  </CheckboxGroup>
                </StyleCard>
              )}
            </OrgRowCountry>
          </OrgRowContentMedium>
          <OrgRowContentMedium>
            <OrgRowCountry>
              <FilterButton onClick={handleProductClick}>
                {selectedProducts.length > 0 ? <SelectedFilterIcon /> : <FilterIcon />}
                <OrgRowContentMedium>{orgProductDetails.productName}</OrgRowContentMedium>
              </FilterButton>
              {isProductOpen && (
                <StyleCard ref={menuRef}>
                  <CheckboxGroup
                    style={{ width: '60%', display: 'flex', flexDirection: 'column' }}
                    value={selectedProducts}
                    onChange={(value) => {
                      handleCheckboxChange(value as string[])
                    }}
                  >
                    <FilterCheckbox value="callgiving">{productLegend.callgiving}</FilterCheckbox>
                    <FilterCheckbox value="robotcall">{productLegend.robotcall}</FilterCheckbox>
                    <FilterCheckbox value="rtm">{productLegend.rtm}</FilterCheckbox>
                    <FilterCheckbox value="serviceinfo">{productLegend.serviceinfo}</FilterCheckbox>
                  </CheckboxGroup>
                </StyleCard>
              )}
            </OrgRowCountry>
          </OrgRowContentMedium>
          <OrgRowContentMedium>{orgProductDetails.equipmentCount}</OrgRowContentMedium>
        </HeaderRow>
        {infoTable.slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))}
        <PaginationPlacing>
          <Pagination dataLength={infoTable.length == 0 ? 1 : infoTable.length} page={page} setPage={setPage} />
        </PaginationPlacing>
      </>
    )
  }
  return <div>{showorgProductList()}</div>
}
export default OrgProductsDetails
