import React, { FC, useState, useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Organization } from '../../../shared/models/organization'
import { H4, MultiInput, Toast } from '../../../shared/components'
import { Container, Title, InputContainer, Label, ButtonsContainer, InviteButton, MessageContainer } from './Styles'
import MemberList, { MemberListRef } from '../MemberDetails'
import { OrganizationsAPI } from '../../../shared/api/organizations'
import { EMAIL_REG_EXP } from '../../../shared/constants/auth'
import { HeaderContainer } from '../../Organizations/EditForm/ListHeader/Styles'
import { Line } from '../../Organizations/EditForm/Styles'
import { InviteMembersStrings } from '../../../shared/strings/OrganizationsContent'

type Props = {
  organization: Organization
}

const InviteMembersForm: FC<Props> = ({ organization }) => {
  const [emails, setEmails] = useState<string[]>([])
  const [multiInputValidationError, setMultiInputValidationError] = useState('')
  const [inviteErrorMessage, setInviteErrorMessage] = useState<string | undefined>()
  const [isInviteErrorVisible, setIsInviteErrorVisible] = useState(false)
  const [isInviteSuccessVisible, setIsInviteSuccessVisible] = useState(false)
  const { mutateAsync: inviteUsers, isLoading: isInviteLoading } = useMutation(OrganizationsAPI.inviteUsersOwner)
  const queryClient = useQueryClient()
  const memberListRef = useRef<MemberListRef>()

  const onValuesChange = (values: string[]) => {
    setEmails(values)
  }

  const onValidationError = (error: string) => {
    setMultiInputValidationError(error)
  }

  const onInputChange = () => {
    setMultiInputValidationError('')
  }

  const validate = (value: string) => {
    if (!value) {
      return InviteMembersStrings.emailCannotBeEmptyError
    }

    if (!EMAIL_REG_EXP.test(value.toLocaleLowerCase())) {
      return `${value} ${InviteMembersStrings.notValidEmailError}`
    }

    if (emails.includes(value)) {
      return `${value} ${InviteMembersStrings.isAlreadyOnTheListError}`
    }

    return
  }

  const hideToastNotifications = () => {
    setIsInviteErrorVisible(false)
    setIsInviteSuccessVisible(false)
    memberListRef?.current?.hideToastNotifications()
  }

  const onClickInvite = async () => {
    hideToastNotifications()

    try {
      await inviteUsers({
        orgId: organization.id,
        emails,
      })
      setEmails([])
      queryClient.invalidateQueries([OrganizationsAPI.queryNames.GET_ORG_MEMBERS_AND_INVITED, organization.id])
      setIsInviteSuccessVisible(true)
    } catch (err: any) {
      if (err.response?.status === 504) {
        setInviteErrorMessage(InviteMembersStrings.invitationTimeoutError)
      } else {
        setInviteErrorMessage(err.response?.data?.message ?? InviteMembersStrings.invitationFailedError)
      }
      setIsInviteErrorVisible(true)
    }
  }

  return (
    <Container>
      <Toast
        key="invite-members-success"
        isVisible={isInviteSuccessVisible}
        text={InviteMembersStrings.invitationSentSuccess}
        onDismiss={() => setIsInviteSuccessVisible(false)}
        type="success"
      />
      <Toast
        key="invite-members-error"
        isVisible={isInviteErrorVisible}
        text={inviteErrorMessage}
        onDismiss={() => setIsInviteErrorVisible(false)}
        type="error"
      />
      <Title>{InviteMembersStrings.title}</Title>
      <InputContainer>
        <Label>{InviteMembersStrings.viaEmail}</Label>
        <MultiInput
          values={emails}
          validate={validate}
          onValuesChange={onValuesChange}
          onValidationError={onValidationError}
          onInputChange={onInputChange}
        />
        {multiInputValidationError && <MessageContainer>{multiInputValidationError}</MessageContainer>}
      </InputContainer>
      <ButtonsContainer>
        <InviteButton
          id="invite-members-button-js"
          type="primary"
          htmlType="submit"
          onClick={onClickInvite}
          disabled={!emails.length}
          loading={isInviteLoading}
        >
          {InviteMembersStrings.inviteMembersButton}
        </InviteButton>
      </ButtonsContainer>
      <Line />
      <HeaderContainer>
        <H4>{InviteMembersStrings.members}</H4>
      </HeaderContainer>
      <MemberList
        ref={memberListRef}
        organizationId={organization.id}
        showListHeader={false}
        onStartAction={hideToastNotifications}
      />
    </Container>
  )
}

export default InviteMembersForm
