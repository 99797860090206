import Markdown from 'markdown-to-jsx'
import { OpenAPIV3 } from 'openapi-types'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { H6, P, UL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'
import {
  getRequiredScopes,
  getSecuritySchemaForName,
  requiresBasicAuthentication,
  requiresBearerToken,
  solveSecuritySchemaReference,
} from '../open-api-utils'
import { Endpoint } from '../types'

type RequestProps = {
  endpoint: Endpoint
  document: OpenAPIV3.Document
}

type SecuritySchemesWithDescription = OpenAPIV3.ApiKeySecurityScheme | OpenAPIV3.HttpSecurityScheme

const Authentication: FC<RequestProps> = ({ endpoint, document }: RequestProps) => {
  if (requiresBearerToken(document, endpoint)) {
    const scopes = getRequiredScopes(document, endpoint)

    return endpoint.pattern.includes('/api/v2') || endpoint.pattern.includes('api/v1/application/self/webhooks') ? (
      <>
        <H6>Authentication</H6>
        <P size="small">
          <strong>HTTP Bearer authentication</strong> using the token from{' '}
          <Link to={Paths.AuthenticationAPIV2Token.path}>{Paths.AuthenticationAPIV2Token.name}</Link>
        </P>
        <P size="small">Required token scopes:</P>
        <UL size="small">
          {scopes.map((scope) => (
            <li key={`scope-${scope}`}>{scope}</li>
          ))}
        </UL>
      </>
    ) : (
      <>
        <H6>Authentication</H6>
        <P size="small">
          <strong>HTTP Bearer authentication</strong> using the token from{' '}
          <Link to={Paths.AuthenticationAPI.path}>{Paths.AuthenticationAPI.name}</Link>
        </P>
        <P size="small">Required token scopes:</P>
        <UL size="small">
          {scopes.map((scope) => (
            <li key={`scope-${scope}`}>{scope}</li>
          ))}
        </UL>
      </>
    )
  } else if (requiresBasicAuthentication(document, endpoint)) {
    return (
      <>
        <H6>Authentication</H6>
        <P size="small">
          <strong>HTTP Basic authentication</strong> using client Id and client secret.
        </P>
      </>
    )
  } else {
    const endpointSecurity = (endpoint.security ?? []).find(() => true)

    if (!endpointSecurity) {
      return null
    }

    const securitySchemeName = Object.keys(endpointSecurity).find(() => true)

    if (!securitySchemeName) {
      throw new Error(`Security scheme name not defined in object ${endpointSecurity}.`)
    }

    const securitySchemaOrRef = getSecuritySchemaForName(securitySchemeName, document)

    if (!securitySchemaOrRef) {
      throw new Error(`Security defined with type ${securitySchemeName} but not documented.`)
    }

    const securitySchema = solveSecuritySchemaReference(securitySchemaOrRef[1], document)

    if (!(securitySchema as any).description) {
      throw new Error(`No description found for security schema ${securitySchemeName}.`)
    }

    const securitySchemaWithDescription = securitySchema as SecuritySchemesWithDescription

    return (
      <>
        <H6>Authentication</H6>
        {securitySchemaWithDescription.description && <Markdown>{securitySchemaWithDescription.description}</Markdown>}
      </>
    )
  }
}

export default Authentication
