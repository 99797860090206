import { FC } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Checkbox, Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { color, size } from '../../../../shared/utils/styles'
import { Button, ErrorMessage } from '../../../../shared/components'

export const RegisterForm = styled.form`
  padding-bottom: 65px;
`

export const DescriptionContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

export const RegisterButton = styled(Button)`
  margin-top: 24px;
  height: 40px;
  width: 100%;
`

export const OpenModalLink = styled.span`
  color: ${color.lightBlue};
  cursor: pointer;

  &:hover {
    color: ${color.blueBase};
  }
`

export const FormCheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const FormCheckbox = styled.input`
  margin-left: 4px;
  margin-right: 8px;
`

export const CheckboxLabel = styled.label`
  color: ${color.gray20};
  line-height: 1;
`

export const ModalCheckbox = styled(Checkbox)`
  float: left;

  @media (max-width: ${size.small}) {
    text-align: left;
    margin: 5px 8px;
  }
`

export const StyledModal: FC<ModalProps> = styled(Modal)`
  max-height: 700px;
  height: 700px;
`

export const MobileModalGlobalStyle = createGlobalStyle`
  @media (max-width: ${size.small}) {
    .ant-modal {
      top: 10px;
    }
  }
`

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${size.small}) {
    align-items: normal;
    flex-direction: column;
    padding-bottom: 15px;
  }
`

export const CheckboxErrorMessage = styled(ErrorMessage)`
  margin-bottom: 10px;
`

export const PrivacyStatementInfo = styled.p`
  font-size: 12px;
  line-height: 15px;
  margin-top: 20px;
  color: ${color.gray10};
  text-align: center;
`
