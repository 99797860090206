import React, { FC } from 'react'
import { CenteredButton } from './Styles'
import { Modal } from '../../../../shared/components'

const UpdateModal: FC<{ visible: boolean; response: any; onClose: () => void }> = ({ visible, response, onClose }) => (
  <Modal
    title="Please note!"
    width={672}
    visible={visible}
    onOk={onClose}
    onCancel={onClose}
    closable={false}
    footer={[
      <CenteredButton key="ok" size="large" type="primary" onClick={onClose}>
        OK
      </CenteredButton>,
    ]}
  >
    {response ? (
      <div>
        <p>{response}</p>
      </div>
    ) : (
      <p>Loading...</p>
    )}
  </Modal>
)

export { UpdateModal }
