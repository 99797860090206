import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogJanuary = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        January 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        January release notes summarizes API Portal documentation fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              <q>earliestStartDate</q> field is added in
              <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}> Service Info Webhook API</Link>.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
