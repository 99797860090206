export const BuildingsStrings = {
  buildings: 'Buildings',
  refresh: 'Refresh',
  lastUpdated: 'Last updated:',
  refreshConfigurationError: 'Refresh configuration error',
  buildingIdLengthError: 'Building id must be between 10 to 55 characters',
  invalidBuildingId: 'Whitespace is not allowed in the building id',
  buildingIdRequired: 'Building id is required.',
  loadingbuilingList: 'Loading building list...',
  buildingListError: 'Cannot fetch building list',
  note: 'Please note !',
}
