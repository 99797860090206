export const ErrorStrings = {
  verificationError: 'Failed to send verification code!',
  userDoesNotFoundError: 'The user does not exist',
  codeMismatchError: 'Invalid verification code provided, please try again',
  codeExpiredError: 'Verification code is expired, please try again',
  limitExceededError: 'Attempt limit exceeded, please try again later',
  invalidEmailError: 'Email address is invalid',
  invalidURL: 'Invalid URL Format',
  failedToResetPasswordError: 'Failed to reset password, please try again',
  invalidResetCodeError: 'Invalid reset code, please try again',
  loginError: 'Something went wrong with the login',
  passwordsDoNotMatchError: 'The passwords do not match',
}

export const ConfirmationFormStrings = {
  activationCodeSent: 'Activation code was sent to',
  enterActivationCode: 'Enter activation code',
  resendCode: 'Resend code',
  verify: 'Verify and log in',
}

export const ForgotPasswordStrings = {
  sendResetCode: 'Send reset code',
}

export const LoginFormStrings = {
  forgotPassword: 'Forgot your password?',
  koneAdminButton: 'KONE Admins',
}

export const NewPasswordStrings = {
  newPassword: 'New password',
  confirmNewPassword: 'Confirm new password',
  resetCode: 'Reset code',
  resetPassword: 'Reset Password',
  verify: 'Verify and log in',
  passwordResetSuccess: 'Password has been reset',
  successViewLoginText: 'Log in and go to dashboard',
}

export const RegisterFormStrings = {
  fillAllFieldsCheckEmail: 'Please fill all fields and check your email for activation code',
  confirmPassword: 'Confirm password',
  understandAndAgree: 'I understand and agree to the',
  termsConditions: 'terms & conditions',
  understandAndAgreeTermsConditions: 'I understand & agree to the terms and conditions',
  acceptAndContinueButton: 'Accept & continue',
  understandPrivacyStatement: 'I understand & agree to the privacy statement',
  acceptAndContinue: 'Accept & continue',
  close: 'Close',
}

export const ServiceBreakStrings = {
  loginDisabled: 'KONE API Portal is under maintenance. Log in is disabled during this period.',
  registrationDisabled: 'KONE API Portal is under maintenance. Registration is disabled during this period.',
}
