import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { H2, H3, H4, P, UL } from '../../../shared/components/Typography'
import Paths from '../../Paths'
import { ListTableHeaderRow, TableContentRow, TypeContainer } from './Styles'

const IntroductionAPIDocumentation: FC = () => {
  return (
    <Row>
      <Col md={24} lg={24} xl={16}>
        <H2>APIs overview</H2>
        <P size="small">
          KONE provides products that are either RESTful APIs or WebSocket APIs. The most common data format is JSON.{' '}
          Our authentication APIs are REST APIs which conform to the OpenID Connect standard.
        </P>
        <P size="small">
          This pages goes through the HTTP responses and error codes as well as gives guidance on authentication scopes,
          which are essential to successful responses.
        </P>
        <H3 id="scopes">Authentication scopes</H3>
        <P size="small">
          Client application’s access scope should always be limited to what is required for it to function. The scope
          consist of API product identifier and resource identifier, separated by a slash character. The format to
          utilize is {'{product}/{resource}'}, where product is the API product identifier and resource is the
          hierarchical resource identifier. It is also possible to use wildcard &apos;*&apos; in place of a specific
          resource and the returned scopes will include all allowed resources the application can access.
        </P>
        <P size="small">
          The following scopes should be included in the body of the token generation request{' '}
          <Link to={`${Paths.AuthenticationAPI.path}#getAccessToken`}>POST /api/v1/oauth2/token</Link> in order to
          retrieve an access token with the correct authentication scopes:
        </P>
        <UL size="small">
          <li>
            callgiving/{'{buildingId}'}, scope to be used to gain access to the Elevator Call API and to a specific
            building.
          </li>
          <li>
            robotcall/{'{buildingId}'}, scope to be used to gain access to the Service Robot API and to a specific
            building.
          </li>
          <li>
            equipmentstatus/ken:{'{ken}'}, scope to be used to gain access to the Equipment Status API and to a specific
            KONE equipment.
          </li>
          <li>
            serviceinfo/ken:{'{ken}'}, scope to be used to gain access to the Service Info API and to a specific KONE
            equipment.
          </li>
          <li>
            application/inventory, allows the user to retrieve the list of resources accessible by the application. This
            specific scope can also be obtained by calling the token generation endpoint without a scope.
          </li>
        </UL>
        <P size="small">
          For V2 APIs, group scope is needed for Elevator call API and Service robot API whereas V1 supports only
          building scope. <br />
          <Link to={`${Paths.AuthenticationAPI.path}#getAccessToken`}>POST /api/v2/oauth2/token</Link> in order to
          retrieve an access token with the correct authentication scopes: <br /> callgiving/
          {`group:{buildingId}:{groupId}`}. e.g callgiving/group:9990000951:1
        </P>
        <P size="small">
          To receive the correct Elevator Call authentication scope for building:9990000951, you need to generate the
          token with the following scope in the body of the request:
        </P>
        <UL size="small">
          <li>
            callgiving/building:9990000951, This specific case provides access to the topology of building 9990000951
            and to open a WebSocket to make &apos;normal&apos; type elevator calls. It’s possible to also monitor the
            state of the made call, allocated lift deck, allocated lift deck&apos;s doors and information about the
            estimated arrival.
          </li>
        </UL>
        <P size="small">
          To receive a token with multiple scopes and thus access multiple APIs or buildings you should make a token
          request with multiple space separated scopes. Or alternatively you can use the wildcard resource scope.
          Examples:
        </P>
        <UL size="small">
          <li>callgiving/building:9990000951 callgiving/building:9990000952 robotcall/building:9990000951</li>
          <li>callgiving/*</li>
        </UL>
        <H3>Successful responses</H3>
        <P size="small">
          REST APIs follow the standard HTTP responses. Elevator Call API and Service Robot API are WebSocket APIs, but
          they do provide HTTP acknowledgement responses, as shown below.
        </P>
        <ListTableHeaderRow>
          <TypeContainer>Response</TypeContainer>
          <TypeContainer>Description</TypeContainer>
        </ListTableHeaderRow>
        <TableContentRow>
          <TypeContainer>200 - OK</TypeContainer>
          <TypeContainer>Successful request</TypeContainer>
        </TableContentRow>
        <TableContentRow>
          <TypeContainer>201 - CREATED</TypeContainer>
          <TypeContainer>Successfully created</TypeContainer>
        </TableContentRow>
        <H3>Error codes</H3>
        <P size="small">
          The error codes of KONE APIs follow the standard HTTP codes. The most common responses are the following:
        </P>
        <ListTableHeaderRow>
          <TypeContainer>Error</TypeContainer>
          <TypeContainer>Description</TypeContainer>
        </ListTableHeaderRow>
        <TableContentRow>
          <TypeContainer>400 - BAD REQUEST</TypeContainer>
          <TypeContainer>Input values are missing or wrongly formatted</TypeContainer>
        </TableContentRow>
        <TableContentRow>
          <TypeContainer>401 - UNAUTHORIZED</TypeContainer>
          <TypeContainer>Authorization header is missing or has credentials that are not valid</TypeContainer>
        </TableContentRow>
        <TableContentRow>
          <TypeContainer>403 - FORBIDDEN</TypeContainer>
          <TypeContainer>Requested scopes are not allowed with the given credentials</TypeContainer>
        </TableContentRow>
        <TableContentRow>
          <TypeContainer>404 - NOT FOUND</TypeContainer>
          <TypeContainer>The server can not find the requested resource</TypeContainer>
        </TableContentRow>
        <TableContentRow>
          <TypeContainer>405 - METHOD NOT ALLOWED</TypeContainer>
          <TypeContainer>Wrong HTTP method</TypeContainer>
        </TableContentRow>
        <TableContentRow>
          <TypeContainer>409 - CONFLICT</TypeContainer>
          <TypeContainer>Request conflicts with the current state of the server</TypeContainer>
        </TableContentRow>
        <TableContentRow>
          <TypeContainer>415 - UNSUPPORTED MEDIA TYPE</TypeContainer>
          <TypeContainer>Content type is wrong or missing</TypeContainer>
        </TableContentRow>
        <TableContentRow>
          <TypeContainer>500 - INTERNAL SERVER ERROR</TypeContainer>
          <TypeContainer>Server side error</TypeContainer>
        </TableContentRow>
        <TableContentRow>
          <TypeContainer>504 - GATEWAY TIMEOUT</TypeContainer>
          <TypeContainer>Server side was too slow to respond</TypeContainer>
        </TableContentRow>
        <H3>API versioning</H3>

        <P size="small">
          We use versioning with all KONE APIs to identify API versions. With most KONE APIs, the version that should be
          used is indicated in the path segment as <strong>v1</strong> is in this example{' '}
          <Link to={`${Paths.AuthenticationAPI.path}#getAccessToken`}>POST /api/v1/oauth2/token</Link> With Elevator
          WebSocket API, the version is part of the first path segment like this:{' '}
          <Link to={`${Paths.ElevatorWebsocketAPI.path}#usage-example`}>/stream-v1</Link>
        </P>

        <P size="small">
          The version identifier indicates the major version of the API. The major versions of the APIs may evolve with
          changes that are backwards compatible.
        </P>

        <P size="small">
          If there are changes that are not backwards compatible, this results in a new major version of the API. For
          example, if the major version v1 requires a change that is not backwards compatible, we would publish a new
          major version, <strong>v2</strong>. Both versions will work simultaneously, until the scheduled deprecation of
          the previous version <strong>v1</strong>.
        </P>

        <P size="small">See next section for more information on backwards compatibility.</P>

        <H3>Backwards compatibility</H3>
        <P size="small">
          The list below contains the changes that either don’t break or break the backwards compatibility. Make sure
          your application continues to work after non-breaking changes.
        </P>
        <H4>Non-breaking changes</H4>
        <UL size="small">
          <li>Addition of a new REST API endpoint</li>
          <li>Addition of a new WebSocket request message type</li>
          <li>Addition of a new incoming WebSocket event message type</li>
          <li>Addition of a new field in a REST API response</li>
          <li>Addition of a new field in an incoming WebSocket event</li>
          <li>Addition of a new non-required field or query parameter in a REST API request</li>
          <li>Addition of a new non-required field in a WebSocket request</li>
          <li>Addition of a new enum value</li>
        </UL>
        <H4>Breaking changes</H4>
        <UL size="small">
          <li>
            Removal or renaming of an existing REST API endpoint, WebSocket message, field, query parameter, or enum
            value
          </li>
          <li>Changing an optional field to required</li>
          <li>Changing the type of a field</li>
          <li>Changing the visible behavior or the semantics of the API</li>
        </UL>
      </Col>
    </Row>
  )
}

export default IntroductionAPIDocumentation
