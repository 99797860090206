import React, { ChangeEvent, useState } from 'react'
import { H4 } from '../../shared/components'
import { ReactComponent as SearchIcon } from '../../assets/icon-search-find.svg'
import { ReactComponent as ClearIcon } from '../../assets/icon-search-clear.svg'
import Pagination from '../../shared/components/Pagination'
import { TABLE_CONTENT_LENGTH } from '../../shared/constants/common'
import {
  SearchCreateRow,
  FilterContainer,
  SearchInput,
  MessageContainer,
  CountHeading,
  HeaderRow,
  OrgRowContentLarge,
  OrgRowContent,
  PaginationPlacing,
  Row,
  ProfileDetails,
  OrgRowContentMedium,
} from './Styles'
import { useQuery } from 'react-query'
import { BuildingsAPI } from '../../shared/api/buildings'
import { DeviceMapping } from '../../shared/models/building'
import { DeviceMappingStrings } from '../../shared/strings/DeviceMappingContent'

const DeviceMappings = () => {
  const { isLoading, error, data } = useQuery(BuildingsAPI.queryNames.GET_DEVICE_MAPPING_LIST, () =>
    BuildingsAPI.getDeviceMappings()
  )
  const [deviceFilter, setDeviceFilter] = useState('')
  const [page, setPage] = useState(0)
  const filResult: DeviceMapping[] = []

  const showContentRows = () => {
    let filteredResObject
    data?.forEach((obj: DeviceMapping) => {
      filteredResObject = filResult.filter((val) => val.buildingId === obj.buildingId)
      if (filteredResObject.length === 0) {
        filResult.push({
          buildingId: obj.buildingId,
          thingName: obj.thingName,
          thingType: obj.thingType,
          clusterId: obj.clusterId,
          timestamp: obj.timestamp,
          deviceType: obj.deviceType,
          groupId: obj.groupId,
          businessAssetNumbers: obj.businessAssetNumbers,
        })
      }
    })
  }
  if (data) {
    showContentRows()
  }

  const onFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    setDeviceFilter(event.target.value.toLowerCase())
  }

  const showDeviceMappingContent = (val: DeviceMapping) => {
    return (
      <ProfileDetails>
        <Row>
          <OrgRowContentMedium>{val.buildingId}</OrgRowContentMedium>
          <OrgRowContent>{val.clusterId}</OrgRowContent>
          <OrgRowContentLarge>{val.thingName}</OrgRowContentLarge>
          <OrgRowContent>{val.thingType}</OrgRowContent>
        </Row>
      </ProfileDetails>
    )
  }
  const showDeviceMappingList = () => {
    if (isLoading) {
      return <MessageContainer>{DeviceMappingStrings.loadingDeviceList}</MessageContainer>
    }
    if (error) {
      return <MessageContainer>{DeviceMappingStrings.deviceListError}</MessageContainer>
    }
    const infoTable = filResult
      .filter((val: any) => {
        return deviceFilter !== undefined
          ? val.buildingId.toString().toLowerCase().includes(deviceFilter) ||
              val.clusterId.toString().toLowerCase().includes(deviceFilter) ||
              val.thingName.toString().toLowerCase().includes(deviceFilter) ||
              val.thingType.toString().toLowerCase().includes(deviceFilter)
          : true
      })
      .map((val: any) => {
        return showDeviceMappingContent(val)
      })
    return (
      <>
        <SearchCreateRow>
          <FilterContainer>
            <SearchInput
              id="search-thing-input-js"
              placeholder="Search building/thing"
              onChange={onFilterChange}
              suffix={deviceFilter ? <ClearIcon onClick={() => setDeviceFilter('')} /> : <SearchIcon />}
              value={deviceFilter}
            />
          </FilterContainer>
        </SearchCreateRow>
        <CountHeading></CountHeading>
        <HeaderRow>
          <OrgRowContentMedium>{DeviceMappingStrings.buildingId}</OrgRowContentMedium>
          <OrgRowContent>{DeviceMappingStrings.clusterId}</OrgRowContent>
          <OrgRowContentLarge>{DeviceMappingStrings.thingName}</OrgRowContentLarge>
          <OrgRowContent>{DeviceMappingStrings.thingType}</OrgRowContent>
        </HeaderRow>
        {infoTable.slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))}
        <PaginationPlacing>
          <Pagination dataLength={infoTable.length == 0 ? 1 : infoTable.length} page={page} setPage={setPage} />
        </PaginationPlacing>
      </>
    )
  }

  return (
    <div>
      {/* {console.log('DF: ' + deviceFilter)} */}
      <H4>{DeviceMappingStrings.deviceMapping}</H4>
      {showDeviceMappingList()}
    </div>
  )
}

export default DeviceMappings
