import React, { FC, useState } from 'react'
import { useAuth } from '../../../shared/contexts/authContext'
import { TABLE_CONTENT_LENGTH } from '../../../shared/constants/common'
import { ArrowSortedAZ, ArrowSortedZA, ProductsContainer, ProductsText, ProductNumber } from './Styles'
import { NotLoadedTableText, HeaderRow, ContentRow, Column, ColumnWithIcon, ColumnSmall, ColumnLarge } from '../Styles'
import { UserOrganizationAPI } from '../../../shared/api/organizationDetails'
import { useQuery } from 'react-query'
import Pagination from '../Pagination'
import { sortedResources, sortedResourcesZA } from '../../../shared/utils/sorting'
import { SharedOrganizationsStrings } from '../../../shared/strings/OrganizationsContent'
import { ApiProductMappings } from '../../../shared/models/product'
import { GetResourceExpiryFlag } from '../../../shared/components/ContractExpiry'

type Props = {
  onDataListSuccess: (dataLength: number) => void
}

type ResourceProps = {
  key: number
  uniqueId: string
  type: string
  productsWithVersion: string[]
  description: string
  expiredDate?: string[]
}

const ResourceItem: FC<ResourceProps> = ({ key, uniqueId, type, productsWithVersion, description, ...rest }) => {
  const [isShowProducts, setIsShowProducts] = useState(false)

  const getProductsView = (products: string[]) => {
    return (
      <ProductsContainer onMouseEnter={() => setIsShowProducts(true)} onMouseLeave={() => setIsShowProducts(false)}>
        {
          <>
            {isShowProducts && <ProductsText> {getProductMappingsAsList(products)} </ProductsText>}
            <ProductNumber>{products.length}</ProductNumber>
            {getProductMappings(products)}
          </>
        }
      </ProductsContainer>
    )
  }

  const getProductMappings = (productsWithVersions: string[]): string => {
    const productNames = productsWithVersions.map((productsWithVersion): any => {
      // removing the v1 tag from v1 products as api returns versions with all products
      if (productsWithVersion.includes('v1')) {
        productsWithVersion = productsWithVersion.split('-')[0]
      }
      if (ApiProductMappings.has(productsWithVersion)) {
        return ApiProductMappings.get(productsWithVersion)
      }
    })
    return productNames.toString().replaceAll(',', ', ')
  }

  const getProductMappingsAsList = (productsWithVersions: string[]) => {
    const productNames = productsWithVersions.map((productsWithVersion): any => {
      // removing the v1 tag from v1 products as api returns versions with all products
      if (productsWithVersion.includes('v1')) {
        productsWithVersion = productsWithVersion.split('-')[0]
      }
      if (ApiProductMappings.has(productsWithVersion)) {
        return ApiProductMappings.get(productsWithVersion)
      }
    })
    return (
      <ul style={{ marginTop: '5px' }}>
        {productNames.map((product) => {
          return (
            <li style={{ listStyleType: 'number', textAlign: 'left' }} key={product}>
              {product}
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <ContentRow id="res-details-js" key={key}>
      <Column id="resource-id-js">
        {uniqueId}
        {rest?.expiredDate && rest?.expiredDate?.length > 0 && (
          <GetResourceExpiryFlag expiredDate={rest?.expiredDate} />
        )}
      </Column>
      <ColumnSmall id="resource-type-js">{type}</ColumnSmall>
      <ColumnLarge id="resource-type-js">{getProductsView(productsWithVersion)}</ColumnLarge>
      <ColumnLarge style={{ whiteSpace: 'break-spaces' }} id="resource-description">
        {description}
      </ColumnLarge>
    </ContentRow>
  )
}

const ResourceList: FC<Props> = ({ onDataListSuccess }) => {
  const { currentOrganization } = useAuth()
  const [isArrowSortedAZVisible, setIsArrowSortedAZVisible] = useState(true)
  const resourceData = useQuery(
    [UserOrganizationAPI.queryNamesResources.GET_ALL, currentOrganization?.externalId],
    () => UserOrganizationAPI.getResources()
  )

  const [page, setPage] = useState(0)

  if (resourceData.error) {
    return <NotLoadedTableText>{`${resourceData.error}`}</NotLoadedTableText>
  }

  if (resourceData.isLoading) {
    return <NotLoadedTableText>{SharedOrganizationsStrings.loading}</NotLoadedTableText>
  }

  if (!resourceData.data || resourceData.data.length === 0) {
    onDataListSuccess(0)
    return <NotLoadedTableText>{SharedOrganizationsStrings.noResourcesFound}</NotLoadedTableText>
  }

  const handleClick = () => {
    isArrowSortedAZVisible ? setIsArrowSortedAZVisible(false) : setIsArrowSortedAZVisible(true)
  }

  const maxPage = Math.ceil(resourceData.data.length / TABLE_CONTENT_LENGTH)
  onDataListSuccess(resourceData.data.length)
  const onNextPage = () => {
    if (page !== maxPage - 1) {
      setPage((page + 1) % maxPage)
    }
  }

  const onPrevPage = () => {
    if (page !== 0) {
      setPage((page - 1) % maxPage)
    }
  }

  const jumpToLastPage = () => {
    setPage(maxPage - 1)
  }

  const jumpToFirstPage = () => {
    setPage(0)
  }

  return (
    <>
      <HeaderRow>
        <ColumnWithIcon>
          {SharedOrganizationsStrings.id}
          {isArrowSortedAZVisible ? <ArrowSortedAZ onClick={handleClick} /> : <ArrowSortedZA onClick={handleClick} />}
        </ColumnWithIcon>
        <ColumnSmall>{SharedOrganizationsStrings.type}</ColumnSmall>
        <ColumnLarge>{SharedOrganizationsStrings.products}</ColumnLarge>
        <ColumnLarge>{SharedOrganizationsStrings.description}</ColumnLarge>
      </HeaderRow>
      {resourceData.data && isArrowSortedAZVisible
        ? sortedResources(resourceData.data)
            .slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))
            .map((e: any, key: number) => (
              <ResourceItem
                key={key}
                type={e.resourceId.type}
                uniqueId={e.resourceId.uniqueId}
                productsWithVersion={e.version}
                description={e.resourceInfo.description}
                expiredDate={e.expiredDate}
              />
            ))
        : sortedResourcesZA(resourceData.data)
            .slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))
            .map((e: any, key: number) => (
              <ResourceItem
                key={key}
                type={e.resourceId.type}
                uniqueId={e.resourceId.uniqueId}
                productsWithVersion={e.version}
                description={e.resourceInfo.description}
              />
            ))}
      <Pagination
        jumpToFirstPage={jumpToFirstPage}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        jumpToLastPage={jumpToLastPage}
        page={page}
        maxPage={maxPage}
        data={resourceData.data}
      />
    </>
  )
}

export default ResourceList
