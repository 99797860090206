import { Layout as AntLayout } from 'antd'
import styled, { createGlobalStyle, css } from 'styled-components'
import { H5 } from '../shared/components'
import { color } from '../shared/utils/styles'

export const GlobalStyle = createGlobalStyle`
  .ant-btn > .ant-btn-loading-icon .anticon {
    margin-right: 8px;
    padding-right: 0px;
  }
`

export const DarkLayoutContainer = styled.div`
  background-color: ${color.gray90};
  color: ${color.gray20};
  min-height: 280px;
  padding-bottom: 30px;
  overflow-x: hidden;
  overflow-y: hidden;
`

const bannerStyle = css`
  text-align: center;
  padding: 1em;
  z-index: 1000;
`

export const InternalOrgBanner = styled.div`
  background-color: ${color.blueTint20};
  ${bannerStyle};
`

export const ServiceBreakBanner = styled.div`
  background-color: ${color.magenta};
  ${bannerStyle};
`

export const BannerText = styled(H5)`
  margin: 0;
`

export const Layout = styled(AntLayout)`
  position: relative;
`
