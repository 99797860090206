import styled from 'styled-components'
import { Select } from 'antd'

export const TierSelect = styled(Select)`
  width: 105px;
`

export const ContentContainer = styled.div`
  width: 420px;
  margin: 0 auto;
`

export const ButtonsContainer = styled.div`
  padding-top 30px;
  display: flex;
  justify-content: space-between;
`
