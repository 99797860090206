export type ProductList = Product[]

export type Product = {
  name: string
}

// how will this affect v2 of service info... check later
export const ApiProductMappings: Map<string, string> = new Map([
  ['callgiving', 'Elevator Call API'],
  ['callgiving-v2', 'Elevator Call API'],
  ['buildingResources', 'Building Resources'],
  ['productionApplications', 'Production Applications'],
  ['sandboxApplications', 'Sandbox Applications'],
  ['robotcall', 'Service Robot API'],
  ['robotcall-v2', 'Service Robot API'],
  ['rtm-v2', 'Equipment Status 2.0'],
  ['serviceinfo', 'Service Info API'],
  ['serviceinfo-v2', 'Service Info API 2.0'],
  ['equipmentstatus', 'Equipment Status API'],
])
