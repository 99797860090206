import { API } from './API'

export const USERS_PATH = '/admin/statistics'
export const ORGANIZATIONS_PATH = '/admin/organizations'
export const CUSTOMER_PATH = '/customer/me'

const queryNames = {
  GET_USERS: 'users',
  GET_USER_APPS: 'users-app-count',
  ENABLE_USER: 'enableUser',
}

const getUsers = async (): Promise<any> => {
  return await API.get(`${USERS_PATH}/users`)
}
const getUsersAppCount = async (): Promise<any> => {
  return await API.get(`${USERS_PATH}/users-app-count`)
}

const enableUser = async (username: string, orgIds: string[] | undefined): Promise<any> => {
  console.log(orgIds)
  const parameters = {
    body: {
      username: username,
      orgIds: orgIds,
    },
  }
  return await API.post(`${ORGANIZATIONS_PATH}/enableUser`, parameters)
}

const enableUserOwner = async (username: string, orgIds: string[] | undefined): Promise<any> => {
  const parameters = {
    body: {
      username: username,
      orgIds: orgIds,
    },
  }
  return await API.post(`${CUSTOMER_PATH}/enableUser`, parameters)
}

export const UsersAPI = {
  queryNames,
  getUsers,
  getUsersAppCount,
  enableUser,
  enableUserOwner,
}
