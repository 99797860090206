import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { ExternalLinkContainer, InternalLinkContainer, Title, ButtonContainer, ArrowIcon } from './Styles'
import isInternalURL from '../../utils/isInternalURL'
import ReadMoreButton from '../ReadMoreButton'

type Props = {
  title: string
  image: string
  targetPath: string
}

const GetInspiredCardContent: FC<Props> = ({ title, image, targetPath }) => {
  const history = useHistory()
  const isInternalPath = isInternalURL(targetPath)

  const navigateTo = () => {
    if (isInternalPath) {
      history.push(targetPath)
    } else {
      window.open(targetPath)
    }
  }

  const content = (
    <>
      <Title className="get-inspired-card-title">{title}</Title>
      <ButtonContainer className="get-inspired-card-button">
        <ReadMoreButton onClick={navigateTo} />
      </ButtonContainer>
      <ArrowIcon />
    </>
  )

  return (
    <>
      {isInternalPath ? (
        <InternalLinkContainer to={targetPath} image={image}>
          {content}
        </InternalLinkContainer>
      ) : (
        <ExternalLinkContainer href={targetPath} image={image} target="_blank" rel="noreferrer">
          {content}
        </ExternalLinkContainer>
      )}
    </>
  )
}

export default GetInspiredCardContent
