import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'

export const ReleaseNotesLogDecember23 = () => {
  return (
    <>
      <H4 id="service-info-change" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        December 2023
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        December release notes summarizes Service Info Webhook bug fix.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              Bug related to the <q>orderStatus</q> field not being present in{' '}
              <Link to={Paths.ServiceInfoWebhookAPIDocs.path}>Service Info API webhook </Link>
              for <b>unplanned service orders</b> has been fixed and it is now available with the correct data in the
              payload.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
