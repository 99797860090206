import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { H6, P } from '../Typography'
import { color } from '../../../shared/utils/styles'

export const APILink = styled(Link)`
  flex: 1;
  position: relative;
  padding: 2em 1.5em;
  margin-right: 2em;
  margin-bottom: 2em;
  color: ${color.gray10};
  background-color: ${color.black};
  text-align: center;
  transition: transform 300ms ease-in, box-shadow 300ms ease-in;

  &:hover {
    color: ${color.gray10};
    box-shadow: rgba(0, 0, 0, 0.8) 5px 5px 15px;
    transform: scale(1.05);
  }
`

export const Name = styled(H6)`
  margin: 0;
`

export const Description = styled(P)`
  margin-top: 1em;
`

export const NavigateTo = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 0.5em;

  svg .a {
    fill: ${color.gray10};
  }
`
