import styled from 'styled-components'
import { Button as SharedButton } from '../../../shared/components'
import { ReactComponent as OkCheckIcon } from '../../../assets/ok-check-icon.svg'

export const SuccessContainer = styled.div`
  text-align: center;
`

export const OkIcon = styled(OkCheckIcon)`
  margin: 10px 0 45px 0;
`

export const SuccessMessage = styled.p`
  font-size: 28px;
`

export const Button = styled(SharedButton)`
  margin-top: 20px;
  width: 100%;
  height: 40px;
  font-size: 18px;
`
