import styled from 'styled-components'
import { size } from '../../utils/styles'

const TilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1600px;
  align-items: stretch;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 15px;

  @media (min-width: ${size.large}) {
    margin: 86px auto;
    padding: 0 16px;
  }
`

export default TilesContainer
