export const AuthStrings = {
  noCurrentUserAmplifyError: 'No current user',
  requiredField: 'This field is required',
  passwordDescription:
    'Password must contain at least 12 characters, at least one uppercase letter, one lowercase letter and one number.',
  koneAdmin: 'kone-admin',
  forgotPassword: 'Enter your registered email address and we will send you a code to reset your password',
  checkYourEmail: 'Reset code was sent to',
  errorInPasswordReset: "If you don't receive the code please contact api-support@kone.com.",
  cannotGetCurrentUser: 'Get current user error',
  cannotInitializeAuthenticatedUserSessionError:
    "Cannot initialize authenticated user session. Please refresh the page and if it doesn't help then try to clean the browser cache.",
  cannotRefreshToken: 'Cannot refresh token',
  authProviderError: `useAuth must be used within AuthProvider`,
}
