import React, { FC } from 'react'
import { NavHashLink } from 'react-router-hash-link'
import { H4 } from '../../../../shared/components/Typography'
import { LeftCol, RightCol, SectionRow, TableOfContentsStyles } from '../../Styles'
import awsConfig from '../../../../shared/utils/awsConfig'
import {
  escalatorMovementDisableEnvs,
  movementStartedDisableEnvs,
  elvatorDoorButtonDisableEnvs,
} from '../../../../shared/constants/common'

const { Items, Item, Name } = TableOfContentsStyles

export const TableOfContents: FC = () => (
  <SectionRow>
    <LeftCol span="12">
      <H4>Table of contents</H4>
      <Items>
        <Item>
          <Name>
            <NavHashLink smooth to="#availability-event">
              Equipment availability information
            </NavHashLink>
          </Name>
        </Item>
        <Item>
          <Name>
            <NavHashLink smooth to="#service-status-event">
              Equipment status information
            </NavHashLink>
          </Name>
        </Item>
        <Item>
          <Name>
            <NavHashLink smooth to="#movement-event">
              Equipment movement information
            </NavHashLink>
          </Name>
        </Item>
        {!movementStartedDisableEnvs.includes(awsConfig?.environment) && (
          <Item>
            <Name>
              <NavHashLink smooth to="#movement-started">
                Movement started
              </NavHashLink>
            </Name>
          </Item>
        )}
        {!escalatorMovementDisableEnvs.includes(awsConfig?.environment) && (
          <Item>
            <Name>
              <NavHashLink smooth to="#escalator-movement">
                Escalator movement information
              </NavHashLink>
            </Name>
          </Item>
        )}
        {!elvatorDoorButtonDisableEnvs.includes(awsConfig?.environment) && (
          <Item>
            <Name>
              <NavHashLink smooth to="#elevator-doorevent">
                Elevator door event
              </NavHashLink>
            </Name>
          </Item>
        )}
        {!elvatorDoorButtonDisableEnvs.includes(awsConfig?.environment) && (
          <Item>
            <Name>
              <NavHashLink smooth to="#elevator-buttonevent">
                Elevator button event
              </NavHashLink>
            </Name>
          </Item>
        )}
      </Items>
    </LeftCol>
    <RightCol span="12"></RightCol>
  </SectionRow>
)
