import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogFebruary2 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        February 2.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        February 2.0 release notes summarizes latest changes for Equipment Status API and API Portal documentation
        fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Equipment Status API</li>
          <UL>
            <li>
              For an empty response in the{' '}
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentAvailabilityByIds'}>
                {' '}
                Equipment Availability REST API
              </Link>
              , we present a 204 status code, with the reason mentioned in the documentation.{' '}
            </li>
            <li>
              <q>equipmentNumber</q> field, which comes inside the data, is removed from the response equipment status
              webhook event. It is not part of the standard response and is being sent in the response erroneously.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              <Link to={Paths.ElevatorWebsocketAPIV2.path + '#make-an-elevator-call'}>Elevator Call API</Link>{' '}
              documentation is updated with some request payload changes, including the addition of optional fields in
              the request payload.
              <UL>
                <li>A new response example for car call is added as it does not produce a session id.</li>
              </UL>
              <UL>
                <li>The reasons behind lift call cancellations are updated.</li>
              </UL>
              <UL>
                <li>The acknowledgment response updated for the time field as it comes inside the data.</li>
              </UL>
              <UL>
                <li>
                  Event payload examples for ping, lift status, next stop eta, and lift position are updated with new
                  fields.
                </li>
              </UL>
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
