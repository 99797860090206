import { TitleWrapperProps } from '../components/TitleWrapper'

export const QuickStartHeroImage = {
  title: 'Get started',
  body: 'Register now and get access to our APIs and test environment',
}

export const QuickStartTopPage = {
  title: 'First things first',
  body: `To get things started, follow these steps. And some other instructive text to guide the user how ever is necessary. Once you have logged in, 
  you will gain access to our API documentation and plans.`,
}
export const Step1 = {
  title: 'Register to KONE API Portal',
  body: `Sign up for a KONE API Portal account to explore the technical details of KONE APIs and try them out in our Sandbox. 
  You can get to the registration form by clicking on the Register button in the top right corner.`,
}
export const Step2 = {
  title: 'Activate your account',
  body: 'You will receive an activation link via email. Follow the instructions to activate our account.',
}
export const Step3 = {
  title: 'Sign into your account ',
  body: 'Take advantage of our API documentation, developer guides and virtual building to create and test your smart solutions.',
}
export const Step4 = {
  title: 'Access to KONE equipment and data through APIs',
  body: `<p>KONE API Services add value to KONE equipment and maintenance service.</p><p>Access to KONE data or equipment through KONE APIs is available for KONE maintenance 
  service customers with KONE API Services contract. </><p>Interested in becoming KONE customer and enjoying the benefits of KONE APIs? Contact our ${'local KONE sales'.link(
    'https://www.kone.com/en/contact.aspx'
  )} to hear more about the available KONE API Services offering and pricing.`,
}
export const SEOProps: TitleWrapperProps = {
  title: 'Start using KONE APIs – register now and get access',
  metaTags: [
    {
      name: 'description',
      content: 'Sign up for a KONE API Portal account and explore the technical details of KONE APIs.',
    },
  ],
}
