import React, { FC } from 'react'
import { OpenAPIV3 } from 'openapi-types'
import RestDocumentation from '../RestDocumentation'
const EquipmentStatusApi2RestDocumentation: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const openAPISpec = require(`./equipment-status-2-rest-api-combined.json`) as OpenAPIV3.Document
  return <RestDocumentation apiName="equipment-status-2-rest-api-combined" openAPISpec={openAPISpec} />
}

export default EquipmentStatusApi2RestDocumentation
