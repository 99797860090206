import React, { FC, useState, useEffect, useRef } from 'react'
import { Select } from 'antd'
import { DownSquareFilled, UpSquareFilled } from '@ant-design/icons'
import { SelectContainer } from './Styles'
// const { Option } = Select
type Props = {
  defaultProducts: string[]
  resourceType: string
  onChange: (options: string[]) => void
  isClear?: boolean
  disabled?: boolean
}

function useOutsideAlerter(ref: any, callback: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (event.target.localName === 'svg' || event.target.className === 'ant-select-item-option-content') {
        console.log(event.target.className)
        return
      }
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const ProductOptions: FC<Props> = ({ defaultProducts, resourceType, onChange, isClear, disabled }) => {
  const [isProductSelect, setProductSelect] = useState(false)
  const [options, setOptions] = useState<string[]>()

  useEffect(() => {
    if (isClear) {
      setOptions([])
    }
  }, [isClear])

  const onProductsChange = (options: any) => {
    setOptions(options)
    onChange(options)
  }

  const onOutsideClick = () => {
    setProductSelect(false)
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, onOutsideClick)

  return (
    <div ref={wrapperRef}>
      <SelectContainer>
        <Select
          mode="tags"
          size="large"
          suffixIcon={
            <>
              {!isProductSelect && (
                <DownSquareFilled onClick={() => setProductSelect(!isProductSelect)} style={{ fontSize: '24px' }} />
              )}
              {isProductSelect && (
                <UpSquareFilled onClick={() => setProductSelect(!isProductSelect)} style={{ fontSize: '24px' }} />
              )}
            </>
          }
          showArrow={true}
          style={{ width: '200px' }}
          placeholder="Select API Product"
          defaultValue={defaultProducts}
          maxTagCount="responsive"
          onChange={onProductsChange}
          open={isProductSelect}
          value={options}
          getPopupContainer={(trigger) => trigger.parentNode}
          disabled={disabled}
        >
          {resourceType === 'building' ? (
            <>
              <Select.Option id="elevator-call-api-option-js" value="callgiving">
                Elevator Call API
              </Select.Option>
              <Select.Option id="service-robot-api-option-js" value="robotcall">
                Service Robot API
              </Select.Option>
            </>
          ) : (
            <>
              {resourceType === 'group' ? (
                <>
                  <Select.Option id="elevator-call-api-option-js" value="callgiving-v2">
                    Elevator Call API
                  </Select.Option>
                  <Select.Option id="service-robot-api-option-js" value="robotcall-v2">
                    Service Robot API
                  </Select.Option>
                </>
              ) : (
                <>
                  <Select.Option id="service-info-api-option-js" value="serviceinfo">
                    Service Info API
                  </Select.Option>
                  <Select.Option id="service-info-api-option-js" value="serviceinfo-v2">
                    Service Info API 2.0
                  </Select.Option>
                  <Select.Option id="equipment-status-api-option-js" value="equipmentstatus">
                    Equipment Status API
                  </Select.Option>
                  <Select.Option id="rtm-api-option-js" value="rtm-v2">
                    Equipment Status API 2.0
                  </Select.Option>
                </>
              )}
            </>
          )}
        </Select>
      </SelectContainer>
    </div>
  )
}

export default React.memo(ProductOptions)
