import { Organization } from '../models/organization'

export const readCurrentOrganization = (): Organization | null => {
  const currentOrganizationJSON = localStorage.getItem('currentOrganization')
  const organization = currentOrganizationJSON ? (JSON.parse(currentOrganizationJSON) as Organization) : null
  return organization
}

export const writeCurrentOrganization = (organization: Organization): void => {
  localStorage.setItem('currentOrganization', JSON.stringify(organization))
}

export const removeCurrentOrganization = (): void => {
  localStorage.removeItem('currentOrganization')
}
