import React, { FC, useState, useEffect } from 'react'
import { StyledLine, Stats } from '../Styles'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../Paths'
import StatHeader from '../StatHeader'
import { useLocation } from 'react-router-dom'

const StatDashboard: FC = () => {
  const showStatistics = () => {
    const location = useLocation()
    const [activeLink, setActiveLink] = useState('')

    useEffect(() => {
      // Set the active link based on the current URL path
      if (location.pathname === Paths.Statistics.path) {
        setActiveLink('Statistics')
      } else if (location.pathname === Paths.StatDashboard.path) {
        setActiveLink('Dashboard')
      }
    }, [location.pathname])
    return (
      <>
        <>
          <Stats>
            <Link
              style={{
                color: 'white',
                marginRight: '20px',
                borderBottom: activeLink === 'Statistics' ? '2px solid #0071b9' : 'none',
              }}
              to={Paths.Statistics.path}
            >
              Statistics
            </Link>
            <Link
              style={{ color: 'white', borderBottom: activeLink === 'Dashboard' ? '2px solid #0071b9' : 'none' }}
              to={Paths.StatDashboard.path}
            >
              Dashboard
            </Link>
          </Stats>
          <StatHeader />
          <StyledLine />
        </>
      </>
    )
  }

  return <>{showStatistics()}</>
}

export default StatDashboard
