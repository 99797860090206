import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'

const events = '#service-status-event'
export const ReleaseNotesLogDecember3 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        December 2023 - 3.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        December 3.0 release notes summarizes latest changes for Service Info and Equipment Status API.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              <q>createdDateTime</q> is now enabled for
              <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrdersByEquipmentId'}>
                {' '}
                Service Info List API
              </Link>
              ,<Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrderById'}> Service Info Details API</Link> and
              <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}> Service Info Webhook API</Link>.
            </li>
            <li>
              <q>createdDateTime</q> is an optional field for Service Info APIs and is available for planned service
              order.
            </li>
            <li>
              Data for <q>createdDateTime</q> field in REST API is available for service orders generated from March
              2023 onward.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Equipment Status API</li>
          <UL>
            <li>
              <q>entrapment</q> is converted to an optional field inside the{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + events}>
                Equipment Status Information Webhook payload
              </Link>
              .
            </li>
            <li>
              Internal changes for{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + events}>
                Equipment Status Information Webhook API
              </Link>{' '}
              has been done to improve the performance.
            </li>
            <li>
              Enum values for <q>equipmentStatus</q> field in the{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + events}>
                Equipment Status Information Webhook payload
              </Link>{' '}
              has been updated in the documentation.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
