import React, { FC } from 'react'
import { ReadMoreBtn } from './Styles'
import { GeneralFormStrings } from '../../strings/GeneralFormContent'

type Props = {
  onClick?: () => void
}

const ReadMoreButton: FC<Props> = () => {
  return <ReadMoreBtn ghost>{GeneralFormStrings.readMore}</ReadMoreBtn>
}

export default ReadMoreButton
