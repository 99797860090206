import { RestAPI as AmplifyAPI } from '@aws-amplify/api-rest'
import { REST_API_NAME } from './constants'

type RequestParameters = {
  headers?: { [key: string]: string }
}

type GetParameters = RequestParameters & {
  queryStringParameters?: { [key: string]: string }
}

type WithBodyParams = RequestParameters & {
  body: { [key: string]: any }
}

const get = (path: string, params?: GetParameters): Promise<any> => {
  return AmplifyAPI.get(REST_API_NAME, path, params)
}

const post = (path: string, params?: WithBodyParams): Promise<any> => {
  return AmplifyAPI.post(REST_API_NAME, path, params)
}

const remove = (path: string, params?: WithBodyParams): Promise<any> => {
  return AmplifyAPI.del(REST_API_NAME, path, params)
}

const patch = (path: string, params?: WithBodyParams): Promise<any> => {
  return AmplifyAPI.patch(REST_API_NAME, path, params)
}

export const API = {
  get,
  post,
  remove,
  patch,
}
