import styled from 'styled-components'

export const Text3 = styled.div`
  font: normal normal normal 18px/26px KONE Information;
`

export const Text4 = styled.div`
  font: normal normal normal 24px/36px KONE Information;
`

export const Text5 = styled.div`
  font: normal normal normal 36px/48px KONE Information;
`
