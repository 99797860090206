import React, { FC } from 'react'
import {
  ButtonsContainer,
  RemoveButton,
  RemoveIcon,
  IconContainer,
  RemoveButtonInactive,
  IconContainerInactive,
} from './Styles'
import { EditAppDeleteStrings } from '../../../shared/strings/ApplicationsContent'

type Props = {
  handleDelete: () => void
  isSecretLoadingTimerFinished: boolean
  loadingSecret: boolean
}

const EditAppDelete: FC<Props> = ({ handleDelete, isSecretLoadingTimerFinished, loadingSecret }) => {
  if (!isSecretLoadingTimerFinished || loadingSecret) {
    return (
      <ButtonsContainer>
        <IconContainerInactive>
          <RemoveButtonInactive>{EditAppDeleteStrings.removeApp}</RemoveButtonInactive>
          <RemoveIcon />
        </IconContainerInactive>
      </ButtonsContainer>
    )
  }
  return (
    <ButtonsContainer>
      <IconContainer id="remove-app-button-js" onClick={handleDelete}>
        <RemoveButton>{EditAppDeleteStrings.removeApp}</RemoveButton>
        <RemoveIcon />
      </IconContainer>
    </ButtonsContainer>
  )
}

export default EditAppDelete
