import React, { FC } from 'react'
import { EquipmentStatusApi } from '../../../shared/strings/KONEAPIsTextContent'
import Paths from '../../../Dashboard/Paths'
import KONEAPIPage from '../KONEAPIPage'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

const apis = [Paths.AuthenticationAPI, Paths.EquipmentStatus2RestApiPageDocs, Paths.WebhookManagementAPIDocs]

const EquipmentStatusAPI2Page: FC = () => {
  return (
    <KONEAPIPage title={Paths.EquipmentStatusAPI2Product.name} apis={apis}>
      <div dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApi.body) }} />
      <div dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApi.features) }} />
      <div dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApi.body_version_two) }} />
      <div dangerouslySetInnerHTML={{ __html: sanitizer(EquipmentStatusApi.features_version_two) }} />
    </KONEAPIPage>
  )
}

export default EquipmentStatusAPI2Page
