export const EquipmentStrings = {
  equipments: 'Equipment',
  loadingEquipmentList: 'Loading Equipment list...',
  equipmentListError: 'Cannot fetch Equipment list',
  kenNumber: 'KEN number',
  name: 'Name',
  organizations: 'Organizations',
  added: 'Added',
}

export const TableContent = {
  supporteEquipment: 'Supported Equipment',
  kone: 'Kone',
  nonKone: 'Non-Kone',
  elevator: 'Elevator',
}

export const WebhookStrings = {
  kenExample: 'ken:100554477',
  serialNumber: 'Equipment serial number',
  equipmentDescription:
    'Unique id provided by customer as an internal identification of the equipment within their system',
  equipmentType: 'Equipment type',
  equipmentTypeExamples: [
    'Building',
    'Autowalk',
    'Building Door',
    'Dumbwaiter',
    'Elevator',
    'Escalator',
    'Stairlift',
    'Facadator',
    'Marine Elevator',
    'Marine Escalator',
    'Marine other eq.',
    'Other',
  ],
  directionDesc: ['0: stopped', '1: up', '2: down'],
  equipmentNumberDesc: 'Unique identifer number of an equipment',
  countryCode: 'Code of the country',
  cityName: 'Name of the city',
  countryName: 'Country name',
  postalCode: 'Postal code',
  equipmentDesc: 'Description of the equipment',
  streetName: 'Street name',
  houseNumber: 'House number',
  district: 'District',
  region: 'Region',
}

export const ServiceModes = {
  modes: [
    'Normal',
    'ServiceDriveMaintenance',
    'TestDrive',
    'PitInspection',
    'SafeWellAccess',
    'Setup',
    'CarTest',
    'ScdSetup',
    'BarAdjust',
    'Synchronization',
    'FireFighter',
    'EmergencyPower',
    'Attendant',
    'Freight',
    'Parked',
    'DopCs',
    'OutOfService',
    'OutOfServiceCar',
    'OutOfServiceLdg',
    'OutOfServiceOpt',
    'PriorityCall',
    'PriorityCallPenthouse',
    'PriorityCallPriorityLanding',
    'PriorityCallCarLightFailed',
    'PriorityCallVip',
    'PriorityCallHospitalEmergency',
    'PriorityCallHospitalBed',
    'PriorityCallPriorityLandingHigh',
    'PriorityCallPriorityLandingLow',
    'PriorityCallHospitalInCar',
    'ResE',
    'Fpo',
    'H4',
    'PrlH*HelMes',
    'EarthQuake',
    'FastRecall',
    'LiftClosed',
    'EtslFailure',
    'NtsFailure',
    'Evacuation',
    'OssPending',
    'Invasion',
    'Aes',
    'Gateway',
    'Fid',
    'Rdf',
    'Pendant',
    'NoSetup',
    'Idle',
    'Ebd',
    'Wsc',
    'WscMa',
    'ReserveDispatch',
    'SpecialMode',
    'ExtendedDoorOpenTime',
    'AutomaticallyGuidedVehicle',
    'HighWindOperation',
    'StormOperation',
    'ReducedSpeed',
    'CarCleaningCap',
    'CarCleaningCam',
    'MainsFailure',
    'NoOperation',
    'Automatic',
    'Inspection',
    'Continuous',
    'TwoDirection',
    'EnergySaving',
    'Start',
    'Construct',
    'Mrt',
    'Other',
  ],
  modeDescription: [
    {
      value: `Normal`,
      description: `Elevator in normal mode`,
    },
    {
      value: `ServiceDriveMaintenance`,
      description: `Elevator in service drive mode and operated by maintenance engineer`,
    },
    {
      value: `TestDrive`,
      description: `Elevator in test drive mode`,
    },
    {
      value: `PitInspection`,
      description: `Elevator pit undergoing inspection and elevator out of service`,
    },
    {
      value: `SafeWellAccess`,
      description: `Elevator top / bottom well undergoing inspection`,
    },
    {
      value: `Setup`,
      description: `Elevator setup ongoing`,
    },
    {
      value: `CarTest`,
      description: `Elevator car test ongoing`,
    },
    {
      value: `ScdSetup`,
      description: `Elevator Static converter setup ongoing`,
    },
    {
      value: `BarAdjust`,
      description: `Elevator floor adjustment ongoing`,
    },
    {
      value: `Synchronization`,
      description: `Elevator synchronization ongoing`,
    },
    {
      value: `FireFighter`,
      description: `Elevator in fire mode, access only by fire fighters`,
    },
    {
      value: `EmergencyPower`,
      description: `Elevator currently using emergency power`,
    },
    {
      value: `Attendant`,
      description: `Elevator is being operated by an attendant inside the car`,
    },
    {
      value: `Freight`,
      description: `Elevator reserved for freight`,
    },
    {
      value: `Parked`,
      description: `Elevator at auto parking floor`,
    },
    {
      value: `DopCs`,
      description: `Elevator in test drive and door opening disabled`,
    },
    {
      value: `OutOfService`,
      description: `Elevator is non-functional and was set by a Destination Operating Panel`,
    },
    {
      value: `OutOfServiceCar`,
      description: `Elevator is non-functional and was set by a Car Operating Panel`,
    },
    {
      value: `OutOfServiceLdg`,
      description: `Elevator is non-functional and was set by a Landing Call Station`,
    },
    {
      value: `OutOfServiceOpt`,
      description: `Elevator is non-functional and was set by a Digital Input/Output panel`,
    },
    {
      value: `PriorityCall`,
      description: `Elevator only accept priority call`,
    },
    {
      value: `PriorityCallPenthouse`,
      description: `Elevator only accept priority call to specified penthouse floor`,
    },
    {
      value: `PriorityCallPriorityLanding`,
      description: `Elevator only accept priority call from a Landing Call Station`,
    },
    {
      value: `PriorityCallCarLightFailed`,
      description: `Elevator priority call indicator non functional in car`,
    },
    {
      value: `PriorityCallVip`,
      description: `Elevator only accept priority call for defined VIP`,
    },
    {
      value: `PriorityCallHospitalEmergency`,
      description: `Elevator only accept priority call for defined Hospital emergency`,
    },
    {
      value: `PriorityCallHospitalBed`,
      description: `Elevator only accept priority call for Hospital bed transportation`,
    },
    {
      value: `PriorityCallPriorityLandingHigh`,
      description: `Elevator only accept priority call from a Landing Call Station with higher priority`,
    },
    {
      value: `PriorityCallPriorityLandingLow`,
      description: `Elevator only accept priority call from a Landing Call Station with lower priority`,
    },
    {
      value: `PriorityCallHospitalInCar`,
      description: `Elevator only accept priority call for defined Hospital emergency activated in elevator car`,
    },
    {
      value: `ResE`,
      description: `Elevator running at reduced speed`,
    },
    {
      value: `Fpo`,
      description: `Elevator removed from group operation`,
    },
    {
      value: `H4`,
      description: `Elevator floor adjustment H4 mode`,
    },
    {
      value: `PrlH*HelMes`,
      description: `Elevator only accept priority call for defined Hospital emergency activated at elevator landing`,
    },
    {
      value: `EarthQuake`,
      description: `Elevator in earthquake mode and accepting no calls`,
    },
    {
      value: `FastRecall`,
      description: `Elevator recalled to specified floor and accepting no calls`,
    },
    {
      value: `LiftClosed`,
      description: `Elevator non functional and closed for maintenance`,
    },
    {
      value: `EtslFailure`,
      description: `Elevator emergency terminal speed limiting failed`,
    },
    {
      value: `NtsFailure`,
      description: `Elevator normal terminal stopping failed`,
    },
    {
      value: `Evacuation`,
      description: `Elevator being used only for non-fire evacuation`,
    },
    {
      value: `OssPending`,
      description: `Elevator waiting to enter non functional mode`,
    },
    {
      value: `Invasion`,
      description: `Elevator immobilized on an invasion floor`,
    },
    {
      value: `Aes`,
      description: `Elevator in an automatic serving mode`,
    },
    {
      value: `Gateway`,
      description: `Elevator IoT gateway failure`,
    },
    {
      value: `Fid`,
      description: `Elevator is non functional due to detected fire`,
    },
    {
      value: `Rdf`,
      description: `Elevator controlled with a recall system and not accepting any calls`,
    },
    {
      value: `Pendant`,
      description: `Elevator in pendant mode for limited testing`,
    },
    {
      value: `NoSetup`,
      description: `Elevator has no or incomplete setup`,
    },
    {
      value: `Idle`,
      description: `Elevator in idle mode`,
    },
    {
      value: `Ebd`,
      description: `Elevator controlled by emergency battery`,
    },
    {
      value: `Wsc`,
      description: `Elevator non functional due to water detected at the base or top`,
    },
    {
      value: `WscMa`,
      description: `Elevator non functional due to water detected at the machine room`,
    },
    {
      value: `ReserveDispatch`,
      description: `Elevator dispatched due to abnormal condition`,
    },
    {
      value: `SpecialMode`,
      description: `Elevator in predefined special mode`,
    },
    {
      value: `ExtendedDoorOpenTime`,
      description: `Elevator door opening extended`,
    },
    {
      value: `AutomaticallyGuidedVehicle`,
      description: `Elevator switched to AGV mode and not accepting other calls`,
    },
    {
      value: `HighWindOperation`,
      description: `Elevator mode changed due to high wind`,
    },
    {
      value: `StormOperation`,
      description: `Elevator mode changed due to storm`,
    },
    {
      value: `ReducedSpeed`,
      description: `Elevator running at reduced speed`,
    },
    {
      value: `CarCleaningCap`,
      description: `Elevator switched to cleaning priority and not accepting other calls`,
    },
    {
      value: `CarCleaningCam`,
      description: `Elevator switched to cleaning mode and not accepting other calls`,
    },
    {
      value: `MainsFailure`,
      description: `Elevator power lost`,
    },
    {
      value: `NoOperation`,
      description: `Elevator non functional, reason unspecified`,
    },
    {
      value: `Automatic`,
      description: `Elevator in an automatic serving mode`,
    },
    {
      value: `Inspection`,
      description: `Elevator in inspection drive mode and undergoing inspection`,
    },
    {
      value: `Continuous`,
      description: `Elevator in continuous serving mode`,
    },
    {
      value: `TwoDirection`,
      description: `Elevator serving mode for intensive two-peak`,
    },
    {
      value: `EnergySaving`,
      description: `Elevator in energy saving mode`,
    },
    {
      value: `Start`,
      description: `Elevator start usage pending`,
    },
    {
      value: `Construct`,
      description: `Elevator in construction time usage mode`,
    },
    {
      value: `Mrt`,
      description: `Elevator machine room temperature detection activated`,
    },
    {
      value: `Other`,
      description: `Elevator in other unspecified state`,
    },
  ],
}
