const isInternalURL = (to: string): boolean => {
  try {
    const url = new URL(to, window.location.origin)
    return url.hostname === window.location.hostname
  } catch {
    return false
  }
}

export default isInternalURL
