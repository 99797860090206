import styled from 'styled-components'
import { ReactComponent as CaretDown } from '../../../assets/icon-caret-down.svg'
import { ReactComponent as CaretUp } from '../../../assets/icon-caret-up.svg'

export const RowExpand = styled.div`
  padding-right:12px;
  margin-top: -41px;
  margin-left: 150px;
}
`
export const IconExpand = styled(CaretDown)`
  cursor: pointer;
  vertical-align: middle;
`

export const IconHide = styled(CaretUp)`
  cursor: pointer;
  vertical-align: middle;
`

export const Row = styled.div`
  margin-top: 50px;
`
