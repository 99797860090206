import styled, { createGlobalStyle } from 'styled-components'
import { Select } from 'antd'
import { color } from '../../shared/utils/styles'

export const OrganizationSelectContainer = styled.div`
  padding: 0 10px 10px 10px;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid ${color.gray70};
    border-radius: 4px;
    color: ${color.gray20};
    background-color: ${color.gray90};
  }
`

export const OrganizationSelect = styled(Select)`
  width: 100%;
`

/* Antd select dropdown div is created outside of the parent div element.
  It means that the only way to override its styles is to define global styles.
  Potentially it can override the styles of other antd select components.
  It's not a problem at the moment since we don't have any other selects
  for organization user but something to keep in mind in the future. */
export const SelectDropdownStyle = createGlobalStyle`
  .ant-select-dropdown {
    font: normal normal normal 16px/20px KONE Information;
    color: ${color.gray15};
    background-color: ${color.gray90};
    border: 1px solid ${color.gray70};
    border-radius: 4px;
  }

  .ant-select-item-group {
    color: ${color.gray30};
  }

  .ant-select-item-option {
    color: ${color.gray15};
  }

  .ant-select-item-option-content {
    position: relative;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: ${color.gray15};
    background-color: ${color.gray90};
    font-weight: normal;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: ${color.gray15};
    background-color: ${color.gray80};
  }
`

export const StyledTooltip = styled.div`
  .ant-tooltip-inner {
    color: ${color.black};
    background-color: ${color.white};
    width: 155px;
    height: 16px;
    text-align: center;
    font: normal normal normal 14px/16px KONE Information;
    min-height: 28px;
  }

  .ant-tooltip-arrow-content {
    background-color: ${color.white};
  }
`

export const OrganizationLabel = styled.span`
  color: ${color.gray20};
  font-size: 12px;
`
