import countries from 'country-code-lookup'

export const validateAddressLine1 = (locationDetails: any): string => {
  const addressLine1 =
    locationDetails.map &&
    typeof locationDetails.map == 'object' &&
    Object.keys(locationDetails.map).length > 0 &&
    locationDetails.map.name &&
    typeof locationDetails.map.name == 'object' &&
    Object.keys(locationDetails.map.name).length > 0 &&
    locationDetails.map.name.myArrayList &&
    Array.isArray(locationDetails.map.name.myArrayList) &&
    locationDetails.map.name.myArrayList[0] &&
    typeof locationDetails.map.name.myArrayList[0] != 'object'
      ? locationDetails.map.name.myArrayList[0]
      : ''
  return addressLine1
}

export const validateAddressLine2 = (locationDetails: any): string => {
  let addressLine2Array = []

  if (locationDetails.map && typeof locationDetails.map == 'object' && Object.keys(locationDetails.map).length > 0) {
    locationDetails.map.houseNumber && typeof locationDetails.map.houseNumber != 'object'
      ? addressLine2Array.push(locationDetails.map.houseNumber)
      : addressLine2Array.push('')
    locationDetails.map.street && typeof locationDetails.map.street != 'object'
      ? addressLine2Array.push(locationDetails.map.street)
      : addressLine2Array.push('')
    locationDetails.map.region && typeof locationDetails.map.region != 'object'
      ? addressLine2Array.push(locationDetails.map.region)
      : addressLine2Array.push('')
  } else {
    addressLine2Array = ['', '', '']
  }

  const addressLine2 = addressLine2Array.filter(Boolean).join(',')
  return addressLine2
}

export const validateCountryCode = (locationDetails: any): string => {
  const country =
    locationDetails.map &&
    typeof locationDetails.map == 'object' &&
    Object.keys(locationDetails.map).length > 0 &&
    locationDetails.map.countryCode &&
    typeof locationDetails.map.countryCode != 'object'
      ? locationDetails.map.countryCode
      : ''

  const countryCode = country && countries.byIso(country)?.isoNo ? countries.byIso(country)!.isoNo : ''
  return countryCode
}

export const validateCity = (locationDetails: any): string => {
  const city =
    locationDetails.map &&
    typeof locationDetails.map == 'object' &&
    Object.keys(locationDetails.map).length > 0 &&
    locationDetails.map.city &&
    typeof locationDetails.map.city == 'object' &&
    Object.keys(locationDetails.map.city).length > 0 &&
    locationDetails.map.city.myArrayList &&
    Array.isArray(locationDetails.map.city.myArrayList) &&
    locationDetails.map.name.myArrayList[0] &&
    typeof locationDetails.map.city.myArrayList[0] != 'object'
      ? locationDetails.map.city.myArrayList[0]
      : ''
  return city
}

export const validateZipCode = (locationDetails: any): string => {
  const zipCode =
    locationDetails.map &&
    typeof locationDetails.map == 'object' &&
    Object.keys(locationDetails.map).length > 0 &&
    locationDetails.map.postalCode &&
    typeof locationDetails.map.postalCode != 'object'
      ? locationDetails.map.postalCode
      : ''
  return zipCode
}
