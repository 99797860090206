import styled from 'styled-components'
import { Button } from '../../../shared/components'
import { color } from '../../../shared/utils/styles'

export const PlusIconContainer = styled.div`
  margin-top: 12px;

  > svg {
    width: 136px;
    height: 136px;

    .a {
      fill: ${color.gray80};
    }
  }
`

export const CreateButton = styled(Button)`
  margin-top: 10px;
  width: 234px;
`
