import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

export type TitleWrapperProps = {
  title?: string
  metaTags?: MetaTag[]
}

export type MetaTag = {
  name: string
  content: string
}

const TitleWrapper: FC<TitleWrapperProps> = (props: TitleWrapperProps) => {
  return <Helmet title={props.title} meta={props.metaTags} />
}

export default TitleWrapper
