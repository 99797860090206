import styled from 'styled-components'
import { Checkbox } from 'antd'
import { color } from '../../../shared/utils/styles'
import { H4, H5, Button, Table } from '../../../shared/components'

export const ProfileDetails = styled.div`
  color: ${color.gray10};
`

export const Section = styled.div`
  margin-bottom: 54px;
`

export const UserNameRowContent = styled(Table.Column)`
  flex-basis: 33%;
`

export const RowButton = styled(Button)`
  min-width: 88px;
  margin-left: auto;
`

export const ModalBodyContainer = styled.div`
  margin-top: 0.75em;
  margin-bottom: 0.5em;
`

export const ModalBodyTitle = styled(H4)`
  margin-top: 0;
  margin-bottom: 1em;
`

export const ModalBodyText = styled(H5)`
  margin: 0;
`

export const DeleteAccountCheckbox = styled(Checkbox)`
  margin-top: 1.25em;
`

export const CheckboxLabel = styled.label`
  color: ${color.gray10};
`

export const ModalFooterButtons = styled.div`
  display: flex;
  justify-content: space-around;
`

export const ModalButton = styled(Button)`
  padding-left: 4em;
  padding-right: 4em;
`
