import React, { FC } from 'react'
import { InspirationPageTitles, InspirationPageHeroImage, SEOProps } from '../../shared/strings/InspirationPageContent'
import { HeroImage, H2, Line, TitleWrapper } from '../../shared/components'
import { DarkLayoutContainer } from '../../App/Styles'
import inspirationHeroImage from '../../assets/bg-img-inspiration.png'
import inspirationHeroImageLarge from '../../assets/bg-img-inspiration@2x.png'
import InspirationCards from './InspirationCards'

const images = {
  small: inspirationHeroImage,
  large: inspirationHeroImageLarge,
}

const InspirationPage: FC = () => {
  return (
    <>
      <TitleWrapper title={SEOProps.title} metaTags={SEOProps.metaTags} />
      <HeroImage
        title={InspirationPageHeroImage.title}
        body={InspirationPageHeroImage.body}
        image={images}
        button={false}
        ingressWidth={'700px'}
      />
      <DarkLayoutContainer>
        <H2 textAlign={'center'}>{InspirationPageTitles.creatingValue}</H2>
        <Line />
        <InspirationCards />
      </DarkLayoutContainer>
    </>
  )
}

export default InspirationPage
