import React from 'react'
import { Select } from 'antd'
import { Type } from '../models/salespage'
import { Solution, Contact } from '../../Dashboard/Salespage/Models'
const { Option } = Select
import { CreateFormStrings } from '../strings/PartnersContent'

export const getLanguages = (languageList: Type[]) => {
  return languageList?.map((languagesItem: { value: string; label: string }, index: number) => {
    return (
      <Option key={`${languagesItem?.value}_${index}`} id={languagesItem?.value} value={languagesItem?.value}>
        {languagesItem?.label}
      </Option>
    )
  })
}

export const getCountryList = (countryList: Type[]) => {
  return countryList?.map((item: { value: string; label: string }, index: number) => {
    return (
      <Option key={`${item?.value}_${index}`} id={item?.value} value={item?.value}>
        {item?.label}
      </Option>
    )
  })
}

export const filterByValue = (array: any, str: string) => {
  return array.filter((o: any) => Object.keys(o).some((k: any) => o[k].toLowerCase().includes(str.toLowerCase())))
}

export const validateWebsiteLink = (value: string) => {
  if (!value.startsWith('https://')) {
    return CreateFormStrings.websiteValidation
  }
  return true
}

export const checkDuplicateSolutions = (solutionList: Solution[], key: string) => {
  const res = {}
  let count = false
  solutionList.forEach(function (solution: Solution) {
    res[solution[key]] = (res[solution[key]] || 0) + 1
    if (res[solution[key]] > 1) {
      count = true
      return
    }
  })
  return count
}

export const checkDuplicateContacts = (contactDetails: Contact[], key: string) => {
  const res = {}
  let count = false
  contactDetails.forEach(function (contact: Contact) {
    res[contact[key]] = (res[contact[key]] || 0) + 1
    if (res[contact[key]] > 1) {
      count = true
      return
    }
  })
  return count
}
