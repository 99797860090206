import { color } from '../../../../shared/utils/styles'
import styled from 'styled-components'
import { Button, Input } from 'antd'
import { ReactComponent as RemoveIcon } from '../../../../assets/icons_remove.svg'

export {}

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`

export const FormLabel = styled.label`
  margin-bottom: 3px;
  margin-top: 10px;
  display: block;
  text-aligh: left;
`

export const InputName = styled(Input)`
  color: ${color.gray75};
`

export const InputsContainer = styled.div`
  margin-bottom: 3px;
`

export const ContactHeader = styled.div`
  display: flex;
  justify-content: end;
`

export const Underline = styled.div`
  text-decoration: underline;
  text-decoration-color: ${color.gray70};
`
export const RemoveIconContact = styled(RemoveIcon)`
  height: 20px;
  cursor: pointer;
`
export const InputColumn = styled.div`
  //width: 100%;
  margin: 0 auto;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  margin-top: 10px;
  opacity: 0.4;
  width: 100%;
`

export const AddContactButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 150px;
  margin-top: 2px;
  margin-right: 10px;
  font-weight: 100;
  background-color: transparent;
  color: ${color.white};
  border: 1px solid ${color.white};
  cursor: pointer;

  &hover: {
    background-color: transparent;
    color: ${color.white};
  }
`

export const CancelButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 150px;
  margin-top: 2px;
  margin-right: 10px;
  font-weight: 100;
  background-color: transparent;
  color: ${color.white};
  border: 1px solid ${color.white};
  cursor: pointer;

  &hover: {
    background-color: transparent;
    color: ${color.white};
  }
`
export const SaveButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 150px;
  margin-top: 2px;
  font-weight: 100;
  background-color: ${color.blueBase};
  color: ${color.white};
  border: 0px;
  cursor: pointer;

  &hover: {
    background-color: ${color.blueBase};
    color: ${color.white};
  }
`

export const PartnerP = styled.p`
  margin-bottom: 4px;
  text-align: left;
  margin-top: 14px;
`

export const RequiredFieldsText = styled.div`
  font-size: 11px;
  margin-top: 4px;
`
