import { Tooltip } from 'antd'
import React, { FC, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Button, Toast, LoadingAnimated, Modal } from '../../../shared/components'
import { ApplicationsAPI } from '../../../shared/api/applications'
import { LOADER_VISIBILITY_MLS, TOOLTIP_VISIBILITY_MLS } from '../../../shared/constants/common'
import { useAuth } from '../../../shared/contexts/authContext'
import { CustomerApp } from '../../../shared/models/application'
import EditAppDelete from '../EditAppDelete'
import EditAppSecret from '../EditAppSecret'
import {
  AppDescription,
  AppDetailsContainer,
  AppName,
  CenterContainer,
  CenterLoader,
  CopyIconStyle,
  EditWrapper,
  FormLabel,
  IconContainer,
  InputName,
  Line,
  OkIcon,
  StyledTooltip,
  SuccessContainer,
  SuccessMessage,
} from './Styles'
import {
  EditAppStrings,
  SharedApplicationStrings,
  GenerateSecretStrings,
} from '../../../shared/strings/ApplicationsContent'

type Props = {
  application: CustomerApp
}

const EditApp: FC<Props> = ({ application }) => {
  const { currentOrganization } = useAuth()
  const [isClientSecretInfoVisible, setIsClientSecretInfoVisible] = useState(false)
  const [isTooltipVisible, setIsTooltipVisible] = useState(true)
  const [isCopyTooltipVisible, setIsCopyTooltipVisible] = useState(false)
  const [isCopySecretTooltipVisible, setIsCopySecretTooltipVisible] = useState(false)
  const [isLoadingTimerFinished, setIsLoadingTimerFinished] = useState(false)
  const [isSecretLoadingTimerFinished, setIsSecretLoadingTimerFinished] = useState(true)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
  const [modalRegenerateVisible, setModalRegenerateVisible] = useState(false)
  const [isDeleteErrorVisible, setIsDeleteErrorVisible] = useState(false)
  const [isRegenerateErrorVisible, setIsRegenerateErrorVisible] = useState(false)

  const queryClient = useQueryClient()

  const {
    mutateAsync: regenerateSecret,
    isLoading: loadingSecret,
    data: secretGenerated,
  } = useMutation(ApplicationsAPI.generateSecret)

  const {
    mutateAsync: deleteApplication,
    isLoading: isRemoving,
    isSuccess: appRemoved,
  } = useMutation(ApplicationsAPI.remove)

  const startLoaderTimer = () => {
    setTimeout(() => {
      setIsLoadingTimerFinished(true)
    }, LOADER_VISIBILITY_MLS)
  }

  const startSecretTimer = () => {
    setIsSecretLoadingTimerFinished(false)
    setTimeout(() => {
      setIsSecretLoadingTimerFinished(true)
    }, LOADER_VISIBILITY_MLS)
  }

  const resetTooltipVisibility = () => {
    setTimeout(() => {
      setIsTooltipVisible(false)
    }, TOOLTIP_VISIBILITY_MLS)
  }

  const hideToastNotifications = () => {
    setIsDeleteErrorVisible(false)
    setIsRegenerateErrorVisible(false)
    setIsClientSecretInfoVisible(false)
  }

  const onShowRegenerateModal = () => {
    setModalRegenerateVisible(true)
  }

  const onHideRegenerateModal = () => {
    setModalRegenerateVisible(false)
  }

  const onHandleSecretGeneration = async () => {
    hideToastNotifications()

    try {
      setModalRegenerateVisible(false)
      startSecretTimer()
      await regenerateSecret(application.clientId!)
      setIsClientSecretInfoVisible(true)
    } catch (e) {
      console.error(e)
      setIsRegenerateErrorVisible(true)
    }
  }

  const onShowDeleteModal = () => {
    setModalDeleteVisible(true)
  }

  const onHideDeleteModal = () => {
    setModalDeleteVisible(false)
  }

  const onHandleDelete = async () => {
    hideToastNotifications()

    try {
      setModalDeleteVisible(false)
      startLoaderTimer()
      await deleteApplication(application.clientId!)
      queryClient.invalidateQueries([ApplicationsAPI.queryNames.GET_ALL, currentOrganization!.externalId])
    } catch (e) {
      console.error(e)
      setIsDeleteErrorVisible(true)
    }
  }

  const handleOnCopyClick = (copiedItem: string) => {
    isSecretOrClientCopied(copiedItem)
    displayCorrectTooltip(copiedItem)
    delayTooltipVisibility(copiedItem)
  }

  const isSecretOrClientCopied = (copiedItem: string) => {
    copiedItem === 'client'
      ? navigator.clipboard.writeText(application.clientId ? application.clientId : '')
      : navigator.clipboard.writeText(secretGenerated.clientSecret ? secretGenerated.clientSecret : '')
  }

  const displayCorrectTooltip = (copiedItem: string) => {
    copiedItem === 'client' ? setIsCopyTooltipVisible(true) : setIsCopySecretTooltipVisible(true)
  }

  const delayTooltipVisibility = (copiedItem: string) => {
    setTimeout(() => {
      copiedItem === 'client' ? setIsCopyTooltipVisible(false) : setIsCopySecretTooltipVisible(false)
    }, TOOLTIP_VISIBILITY_MLS)
  }

  if (appRemoved || isRemoving) {
    return !isLoadingTimerFinished || isRemoving ? (
      <CenterLoader>
        <LoadingAnimated />
      </CenterLoader>
    ) : (
      <EditWrapper>
        <SuccessContainer>
          <OkIcon />
          <SuccessMessage>{EditAppStrings.appRemovedSuccess}</SuccessMessage>
        </SuccessContainer>
      </EditWrapper>
    )
  }

  return (
    <EditWrapper>
      <Toast
        key="client-secret-info"
        isVisible={!loadingSecret && isClientSecretInfoVisible && isSecretLoadingTimerFinished}
        text={secretGenerated ? EditAppStrings.resetSecretSuccess : EditAppStrings.resetSecretError}
        textId="client-secret-info-message-js"
        onDismiss={() => setIsClientSecretInfoVisible(false)}
        type="warning"
      />
      <Toast
        key="regeneration-error"
        isVisible={isRegenerateErrorVisible}
        text={GenerateSecretStrings.clientSecretError}
        textId="secret-regeneration-error-message-js"
        onDismiss={() => setIsRegenerateErrorVisible(false)}
        type="error"
      />
      <Toast
        key="delete-error"
        isVisible={isDeleteErrorVisible}
        text={EditAppStrings.removeAppError}
        textId="delete-app-error-message-js"
        onDismiss={() => setIsDeleteErrorVisible(false)}
        type="error"
      />
      {!appRemoved && (
        <>
          <AppDetailsContainer>
            <AppName>{application.name}</AppName>
            <AppDescription>{application.description}</AppDescription>
          </AppDetailsContainer>
          <Line />
          <form>
            <div>
              <FormLabel>{EditAppStrings.clientId}</FormLabel>
              <CenterContainer>
                <InputName className="inputForm" value={application.clientId} readOnly></InputName>
                <IconContainer>
                  <Tooltip
                    placement="right"
                    title={SharedApplicationStrings.copied}
                    visible={isCopyTooltipVisible}
                    getPopupContainer={(trigger) => {
                      return trigger
                    }}
                  >
                    <StyledTooltip>
                      <CopyIconStyle onClick={() => handleOnCopyClick('client')} />
                    </StyledTooltip>
                  </Tooltip>
                </IconContainer>
              </CenterContainer>
              <EditAppSecret
                handleSecretGeneration={onShowRegenerateModal}
                loadingSecret={loadingSecret}
                isSecretLoadingTimerFinished={isSecretLoadingTimerFinished}
                secretGenerated={secretGenerated}
                isTooltipVisible={isTooltipVisible}
                handleOnCopyClick={handleOnCopyClick}
                isCopySecretTooltipVisible={isCopySecretTooltipVisible}
                resetTooltipVisibility={resetTooltipVisibility}
              />
              <Line />
            </div>
            <EditAppDelete
              handleDelete={onShowDeleteModal}
              loadingSecret={loadingSecret}
              isSecretLoadingTimerFinished={isSecretLoadingTimerFinished}
            />
          </form>
        </>
      )}
      <Modal
        key="remove-app-modal"
        title={EditAppStrings.modalTitle}
        width={672}
        visible={modalDeleteVisible}
        onOk={onHandleDelete}
        onCancel={onHideDeleteModal}
        closable={false}
        footer={[
          <Button
            id="remove-app-button-js"
            size="large"
            style={{ width: '304px', marginRight: '24px' }}
            key="submit"
            ghost={true}
            onClick={onHandleDelete}
          >
            {EditAppStrings.removeAppButton}
          </Button>,
          <Button
            id="cancel-remove-app-button-js"
            size="large"
            style={{ width: '272px' }}
            key="back"
            type="primary"
            onClick={onHideDeleteModal}
          >
            {EditAppStrings.cancelRemoveButton}
          </Button>,
        ]}
      >
        {`${EditAppStrings.confirmDelete} ${application.name}`}
      </Modal>
      <Modal
        key="regenerate-secret-modal"
        title={EditAppStrings.modalTitle}
        width={672}
        visible={modalRegenerateVisible}
        onOk={onHandleSecretGeneration}
        onCancel={onHideRegenerateModal}
        closable={false}
        footer={[
          <Button
            id="regenerate-secret-button-js"
            size="large"
            style={{ width: '304px', marginRight: '24px' }}
            key="submit"
            ghost={true}
            onClick={onHandleSecretGeneration}
          >
            {EditAppStrings.regenerateButton}
          </Button>,
          <Button size="large" style={{ width: '272px' }} key="back" type="primary" onClick={onHideRegenerateModal}>
            {"No, let's go back"}
          </Button>,
        ]}
      >
        {EditAppStrings.confirmRegenerate}
      </Modal>
    </EditWrapper>
  )
}

export default EditApp
