import { cloneDeep } from 'lodash'
import { OpenAPIV3 } from 'openapi-types'
import MarkdownLink from './components/MarkdownLink'
import { Endpoint } from './types'

function isReferenceObject(object: unknown): object is OpenAPIV3.ReferenceObject {
  return object && (object as any).$ref
}

export function isArraySchema(schema: OpenAPIV3.SchemaObject): schema is OpenAPIV3.ArraySchemaObject {
  return schema.type === 'array'
}

export function isObjectSchema(schema: OpenAPIV3.SchemaObject): schema is OpenAPIV3.NonArraySchemaObject {
  return schema.type === 'object'
}

export function needsSubSchema(schema: OpenAPIV3.SchemaObject): schema is OpenAPIV3.ArraySchemaObject {
  return isArraySchema(schema) || isObjectSchema(schema) || isReferenceObject(schema)
}

export function solveSubSchema(
  object: OpenAPIV3.SchemaObject | OpenAPIV3.ReferenceObject,
  document: OpenAPIV3.Document
): OpenAPIV3.SchemaObject {
  if (isReferenceObject(object)) {
    return solveSchemaReference(object, document)
  } else if (isArraySchema(object)) {
    return solveSchemaReference(object.items, document)
  } else if (isObjectSchema(object)) {
    return object
  } else {
    throw new Error(`Unsupported sub schema type ${object}`)
  }
}

export function getSubSchemaName(object: OpenAPIV3.SchemaObject | OpenAPIV3.ReferenceObject): string | undefined {
  if (isReferenceObject(object)) {
    return getSchemaName(object)
  } else if (isArraySchema(object)) {
    return getSchemaName(object.items)
  }

  return
}

function getSimpleNameFromReferenceObject(object: OpenAPIV3.ReferenceObject): string {
  return object.$ref.substring(object.$ref.lastIndexOf('/') + 1)
}

function solveReference<T>(
  object: T | OpenAPIV3.ReferenceObject,
  document: OpenAPIV3.Document,
  elementListFn: (document: OpenAPIV3.Document) => { [key: string]: T | OpenAPIV3.ReferenceObject }
): T {
  if (!isReferenceObject(object)) {
    return object
  }

  const elements = elementListFn(document)

  const elementName = getSimpleNameFromReferenceObject(object)

  if (elements[elementName]) {
    return solveReference(elements[elementName], document, elementListFn)
  } else {
    throw new Error(`Undefined reference to ${object.$ref}`)
  }
}

export function solveSchemaReference(
  object: OpenAPIV3.SchemaObject | OpenAPIV3.ReferenceObject,
  document: OpenAPIV3.Document
): OpenAPIV3.SchemaObject {
  return solveReference<OpenAPIV3.SchemaObject>(object, document, (document) => document.components?.schemas ?? {})
}

export function solveRequestBodyReference(
  object: OpenAPIV3.RequestBodyObject | OpenAPIV3.ReferenceObject,
  document: OpenAPIV3.Document
): OpenAPIV3.RequestBodyObject {
  return solveReference<OpenAPIV3.RequestBodyObject>(
    object,
    document,
    (document) => document.components?.requestBodies ?? {}
  )
}

export function solveResponseReference(
  object: OpenAPIV3.ResponseObject | OpenAPIV3.ReferenceObject,
  document: OpenAPIV3.Document
): OpenAPIV3.ResponseObject {
  return solveReference<OpenAPIV3.ResponseObject>(object, document, (document) => document.components?.responses ?? {})
}

export function solveParametersReference(
  object: OpenAPIV3.ParameterObject | OpenAPIV3.ReferenceObject,
  document: OpenAPIV3.Document
): OpenAPIV3.ParameterObject {
  return solveReference<OpenAPIV3.ParameterObject>(
    object,
    document,
    (document) => document.components?.parameters ?? {}
  )
}

export function solveSecuritySchemaReference(
  object: OpenAPIV3.SecuritySchemeObject | OpenAPIV3.ReferenceObject,
  document: OpenAPIV3.Document
): OpenAPIV3.SecuritySchemeObject {
  return solveReference<OpenAPIV3.SecuritySchemeObject>(
    object,
    document,
    (document) => document.components?.securitySchemes ?? {}
  )
}

export function isRequiredPropertyInSchema(name: string, schema: OpenAPIV3.SchemaObject): boolean {
  if (!schema.required) {
    return false
  }

  return schema.required.includes(name)
}

export function getSchemaName(object: OpenAPIV3.SchemaObject | OpenAPIV3.ReferenceObject): string | undefined {
  if (isReferenceObject(object)) {
    return getSimpleNameFromReferenceObject(object)
  }

  return
}

/**
 * @param originalPath /some/path/to/{name}
 * @param parameters { name: "something" }
 * @returns /some/path/to/something
 */
export function replacePathParameters(originalPath: string, parameters: OpenAPIV3.ParameterObject[]): string {
  return parameters
    .filter((parameter) => parameter.in === 'path')
    .reduce((path, parameter) => path.replace(`{${parameter.name}}`, parameter.example), originalPath)
}

export function formatExampleRequestRawHttp(
  document: OpenAPIV3.Document,
  endpoint: Endpoint,
  parameters: OpenAPIV3.ParameterObject[],
  mediaType?: string,
  example?: unknown
): string {
  let requestExample = `${endpoint.method} ${replacePathParameters(endpoint.pattern, parameters)} HTTP/1.1\nHost: ${
    window.location.host
  }`

  let customRequestExample = `${endpoint.method} ${replacePathParameters(
    endpoint.pattern,
    parameters
  )} HTTP/1.1\nHost: example.com`

  if (requiresBearerToken(document, endpoint)) {
    requestExample += '\nAuthorization: Bearer ACCESS_TOKEN'
  } else if (requiresBasicAuthentication(document, endpoint)) {
    requestExample += '\nAuthorization: Basic QWxhZGRpbjpvcGVuIHNlc2FtZQ=='
  } else if (requiresApiKeyAuthentication(document, endpoint)) {
    // TODO: This should read the 'in' and 'name' parameters from the schema if there are more uses in the future.
    customRequestExample +=
      '\nAuthorization: KONE-Signature t=1617861763,v1=44783a52b71ab4bd2b38f7cb876551f5ee56ce5204489d23b3ce8ca572ea84fd'
    customRequestExample += formatExampleBodyRawHttp(mediaType, example)
    return customRequestExample
  }

  requestExample += formatExampleBodyRawHttp(mediaType, example)
  return requestExample
}

export function formatExampleRequestCurl(
  document: OpenAPIV3.Document,
  endpoint: Endpoint,
  parameters: OpenAPIV3.ParameterObject[],
  mediaType?: string,
  example?: unknown
): string {
  // Base
  let requestExample = 'curl \\\n'

  if (requiresBearerToken(document, endpoint)) {
    requestExample += '  --header "Authorization: Bearer ACCESS_TOKEN" \\\n'
    requestExample += `  --request ${endpoint.method} ${window.location.protocol}//${
      window.location.host
    }${replacePathParameters(endpoint.pattern, parameters)} \\\n`
  } else if (requiresBasicAuthentication(document, endpoint)) {
    requestExample += '  --user CLIENT_ID:CLIENT_SECRET \\\n'
    requestExample += `  --request ${endpoint.method} ${window.location.protocol}//${
      window.location.host
    }${replacePathParameters(endpoint.pattern, parameters)} \\\n`
  } else if (requiresApiKeyAuthentication(document, endpoint)) {
    // TODO: This should read the 'in' and 'name' parameters from the schema if there are more uses in the future.
    requestExample += `  --request ${endpoint.method} example.com${replacePathParameters(
      endpoint.pattern,
      parameters
    )} \\\n`
    requestExample +=
      '  --header "Authorization: KONE-Signature t=1617861763,v1=44783a52b71ab4bd2b38f7cb876551f5ee56ce5204489d23b3ce8ca572ea84fd" \\\n'
  }

  requestExample += formatExampleBodyCurl(mediaType, example)

  // Remove leading ' \\\n'
  return requestExample.substring(0, requestExample.length - 3)
}

function formatExampleBodyRawHttp(mediaType?: string, example?: unknown): string {
  let exampleText = ''
  if (example) {
    exampleText += `\nContent-Type: ${mediaType} \n\n`

    if (mediaType === 'application/x-www-form-urlencoded') {
      const exampleData = example as { [key: string]: string }

      exampleText += Object.entries(exampleData)
        .map(([key, value]) => `${encodeURI(key)}=${encodeURI(value)}`)
        .join('&')
    } else if (mediaType === 'application/json') {
      exampleText += JSON.stringify(example, null, 2)
    } else {
      throw new Error(`Unknown media type ${mediaType}`)
    }
    exampleText = exampleText.replaceAll('%SERVER_HOST%', window.location.hostname)
  }
  return exampleText
}

function formatExampleBodyCurl(mediaType?: string, example?: unknown): string {
  let exampleText = ''

  if (example) {
    exampleText += `  --header "Content-Type: ${mediaType}" \\\n`

    if (mediaType === 'application/x-www-form-urlencoded') {
      const exampleData = example as { [key: string]: string }

      exampleText += Object.entries(exampleData)
        .map(([key, value]) => `  --data-urlencode "${key}=${value}" \\\n`)
        .join('')
    } else if (mediaType === 'application/json') {
      exampleText += `  --data '${JSON.stringify(example, null, 0)}' \\\n`
    } else {
      throw new Error(`Unknown media type ${mediaType}`)
    }
    exampleText = exampleText.replaceAll('%SERVER_HOST%', window.location.hostname)
  }
  return exampleText
}

export function formatExampleResponse(statusCode: string, mediaType?: string, example?: unknown): string {
  let responseExample = `HTTP/1.1 ${statusCode} ${statusCodeToDescription(statusCode)}`
  responseExample += formatExampleBodyRawHttp(mediaType, example)
  return responseExample
}

export function requiresBearerToken(document: OpenAPIV3.Document, endpoint: Endpoint): boolean {
  const openIdSecurityName = getOpenIdConnectName(document)

  return openIdSecurityName ? requiresSecurityWithName(openIdSecurityName, endpoint) : false
}

export function requiresBasicAuthentication(document: OpenAPIV3.Document, endpoint: Endpoint): boolean {
  const basicAuthSecurityName = getBasicAuthName(document)

  return basicAuthSecurityName ? requiresSecurityWithName(basicAuthSecurityName, endpoint) : false
}

export function requiresApiKeyAuthentication(document: OpenAPIV3.Document, endpoint: Endpoint): boolean {
  const apiKeySecurityName = getApikeyName(document)

  return apiKeySecurityName ? requiresSecurityWithName(apiKeySecurityName, endpoint) : false
}

export function getRequiredScopes(document: OpenAPIV3.Document, endpoint: Endpoint): string[] {
  const openIdSecurityName = getOpenIdConnectName(document)

  return (endpoint.security ?? []).flatMap((security) => (openIdSecurityName ? security[openIdSecurityName] ?? [] : []))
}

function requiresSecurityWithName(name: string, endpoint: Endpoint): boolean {
  return (
    (endpoint.security ?? []).filter((security) => name && security[name] !== undefined).find(() => true) !== undefined
  )
}

function getSecuritySchemaForType(
  type: string,
  document: OpenAPIV3.Document
): [string, OpenAPIV3.SecuritySchemeObject | OpenAPIV3.ReferenceObject] | undefined {
  return Object.entries(document.components?.securitySchemes ?? {})
    .filter(
      ([_, value]) =>
        (value as OpenAPIV3.SecuritySchemeObject).type && (value as OpenAPIV3.SecuritySchemeObject).type === type
    )
    .find(() => true)
}

export function getSecuritySchemaForName(
  name: string,
  document: OpenAPIV3.Document
): [string, OpenAPIV3.SecuritySchemeObject | OpenAPIV3.ReferenceObject] | undefined {
  return Object.entries(document.components?.securitySchemes ?? {})
    .filter(([schemeName, _]) => schemeName === name)
    .find(() => true)
}

function getOpenIdConnectName(document: OpenAPIV3.Document): string | undefined {
  const nameAndSchema = getSecuritySchemaForType('openIdConnect', document)

  if (!nameAndSchema) {
    return undefined
  }

  const [name] = nameAndSchema

  return name
}

function getApikeyName(document: OpenAPIV3.Document): string | undefined {
  const nameAndSchema = getSecuritySchemaForType('apiKey', document)

  if (!nameAndSchema) {
    return undefined
  }

  const [name] = nameAndSchema

  return name
}

function getBasicAuthName(document: OpenAPIV3.Document): string | undefined {
  const nameAndSchema = getSecuritySchemaForType('http', document)

  if (!nameAndSchema) {
    return undefined
  }

  const [name, schema] = nameAndSchema

  const httpSchema = schema as OpenAPIV3.HttpSecurityScheme

  return httpSchema.scheme === 'basic' ? name : undefined
}

export function statusCodeToDescription(statusCode: string): string {
  if (statusCode === '200') {
    return 'OK'
  } else if (statusCode === '201') {
    return 'Created'
  } else if (statusCode === '202') {
    return 'Accepted'
  } else if (statusCode === '204') {
    return 'No Content'
  } else if (statusCode === '400') {
    return 'Bad Request'
  } else if (statusCode === '401') {
    return 'Unauthorized'
  } else if (statusCode === '403') {
    return 'Forbidden'
  } else if (statusCode === '404') {
    return 'Not Found'
  } else if (statusCode === '405') {
    return 'Method Not Allowed'
  } else if (statusCode === '415') {
    return 'Unsupported Media Type'
  } else if (statusCode === '500') {
    return 'Internal Server Error'
  } else {
    throw new Error(`Unknown status code ${statusCode}`)
  }
}

export function formatType(property: OpenAPIV3.SchemaObject): string {
  if (property.format) {
    return `${property.type ?? ''} (${property.format})`
  } else {
    return property.type ?? ''
  }
}

type EnumDescription = {
  value: string
  description: string
}

export function parseDescriptionAndEnumDescriptions(
  property: OpenAPIV3.SchemaObject
): [string | undefined, EnumDescription[] | undefined] {
  const needle = 'Enum descriptions:\n'
  const keyValueSeparator = ' - '
  const originalDescription = property.description

  if (!originalDescription) {
    return [undefined, undefined]
  }

  const enumDescriptionIndex = originalDescription.indexOf(needle)

  if (enumDescriptionIndex < 0) {
    return [originalDescription, undefined]
  }

  const description = originalDescription.substring(0, enumDescriptionIndex).trim()
  const rawEnumDescriptions = originalDescription.substring(enumDescriptionIndex + needle.length)

  const enumDescriptions: EnumDescription[] = rawEnumDescriptions
    .split('\n')
    .filter((line) => line.indexOf(keyValueSeparator) > 0)
    .map((line) => line.split(keyValueSeparator))
    .map(([key, value]) => [/^\* `.+`/.test(key) ? key.substring(3, key.length - 1) : key, value])
    .map(([key, value]) => ({ value: key, description: value }))

  return [description, enumDescriptions]
}

export function makeLocalLinksRelative(link: string): string {
  return link.replaceAll('https://%SERVER_HOST%/api-portal', '')
}

export const markdownOptions = { overrides: { a: { component: MarkdownLink } } }

export function transformAPIResponseToEvent(
  apiResponse: OpenAPIV3.NonArraySchemaObject
): OpenAPIV3.NonArraySchemaObject {
  const copy = cloneDeep(apiResponse)

  delete copy.properties!.equipmentId
  delete copy.properties!.lastUpdate

  delete copy.example!.equipmentId
  delete copy.example!.lastUpdate

  return copy
}
