import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogJuly24 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        July 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        July release notes summarizes latest changes for Elevator Call API.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Elevator Call API</li>
          <UL>
            <li>
              A new payload, <b>activate_call_states</b> has been added to the{' '}
              <Link to={Paths.ElevatorWebsocketAPIV2.path + '#make-an-elevator-call'}>Elevator Call API</Link>. This
              allows for the monitoring of call states within a single request.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
