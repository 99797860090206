import React from 'react'

export const sandboxSelfTest = {
  content: [
    {
      component: '',
      description:
        'During and after solution development, testing comes as natural step. This testing can be made with the sandbox virtual building following the applicable validation ' +
        'guide above.',
    },
    {
      component: '',
      description:
        'It is recommended to download a copy as this will be used and updated through out the whole solution validation process. ' +
        'After completing all the test cases in the validation guide with the virtual building, the next testing round will be with preproduction elevators',
    },
    {
      component: '',
      description:
        'The virtual building might possess some limitations and because of this, verifying the full operation of a solution is needed with preproduction elevators.',
    },
    {
      component: '',
      description:
        'Access to the preproduction elevator can be requested: Contact us at api-support@kone.com and it’s available only for applications created in production environment. ' +
        'While requesting access to preproduction, do include the completed report made with the sandbox self-test.',
    },
    {
      component: '',
      description:
        'After receiving the access, you can continue to develop and test your application in the preproduction environment.',
    },
  ],
}

export const preProductionSelfTest = {
  content: [
    {
      component: '',
      description:
        'While still making final development adjustment, it is then next testing phase to use the solution to interact with physical equipment / buildings . ' +
        'This testing can be made with the preproduction equipment / building continuing with the applicable validation guide used during sandbox testing.',
    },
    {
      component: '',
      description:
        'After completing all the test cases in the validation guide with the preproduction equipment / building, ' +
        'the next testing round will be with KONE specialist for guided preproduction equipment / building testing.',
    },
    {
      component: '',
      description:
        'After receiving the access, you can continue to develop and test your application in the preproduction environment.',
    },
  ],
}

export const preProductionGuideTest = {
  content: [
    {
      component: '',
      description:
        'As solution development and testing reaches completion, there are some test cases in the validation guide test plan that ' +
        'cant be self tested especially those of error use cases.',
    },
    {
      component: '',
      description: 'For these test cases, KONE specialist assistance will be provided.',
    },
    {
      component: '',
      description:
        'To proceed to completing the solution validation testing, send request: Contact us at api-support@kone.com.',
    },
    {
      component: '',
      description:
        'Including the completed cybersecurity self-test and the preproduction self-test report from the relevant validation test guide in the request for a guided test.',
    },
    {
      component: '',
      description:
        'From above you can download security documents that guide you through the verification of your solution.',
    },
  ],
}

export const selfPilotTest = {
  content: [
    {
      component: '',
      description:
        'The last stage of solution validation is a pilot test. Do note that this is optional step and only conducted if there is a pilot site available for usage.',
    },
    {
      component: '',
      description:
        'This test is with an actual building having KONE elevators fulfilling all requirements of such testing is required to send the request for pilot testing Contact us and ' +
        'include the contact information of KONE personnel that will be coordinating the pilot testing.',
    },
    {
      component: '',
      description:
        'Signed copy of API Consumer agreement will need to be processed and returned. Template will be provided on request: Contact us at api-support@kone.com',
    },
  ],
}
