/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export type GroupDevice = {
  ken_ids: string[]
  group_id: string
  device_id: string
  type_id: string
  address: Record<any, any>
}

export const getDevices = (rawData: any): any => {
  const groups: GroupDevice[] = []
  try {
    const data = JSON.parse(JSON.stringify(rawData))
    if (data.map.hits) {
      if (data.map.hits.map.hits.myArrayList) {
        data.map.hits.map.hits.myArrayList.forEach((data: any) => {
          if (data.map._source.map.equipment.map.devices.myArrayList) {
            data.map._source.map.equipment.map.devices.myArrayList.forEach((device: any) => {
              if (device.map.typeId === 'kcegc') {
                const groupData = groups.find(
                  (group) => group.group_id === device.map.inventory.map.sw.map.ids.map.group
                )
                if (groupData) {
                  const kens = groupData.ken_ids.concat(device.map.equipment.myArrayList)
                  const kenIds = kens.filter((item, pos) => kens.indexOf(item) === pos)
                  groupData.ken_ids = kenIds
                  groupData.address = data.map._source.map.equipment.map.address
                } else {
                  const group: GroupDevice = {
                    group_id: device.map.inventory.map.sw.map.ids.map.group,
                    device_id: device.map.inventory.map.id,
                    type_id: device.map.typeId,
                    ken_ids: device.map.equipment.myArrayList,
                    address: data.map._source.map.equipment.map.address,
                  }
                  groups.push(group)
                }
              }
            })
          }
        })
      }
      if (groups.length) {
        return groups
      } else {
        return data.map.hits.map.hits.myArrayList.length
      }
    }
  } catch (error) {
    return null
  }
}
