import React, { FC } from 'react'
import { PageContainer } from './Styles'
import { DarkLayoutContainer } from '../../App/Styles'
import { Feature, isFeatureEnabled } from '../../shared/utils/featureFlags'
import PrivacyStatementContent from '../../shared/content/PrivacyStatementContent'
import PrivacyStatementContentChina from '../../shared/content/PrivacyStatementContentChina'

const PrivacyStatement: FC = () => {
  return (
    <DarkLayoutContainer>
      <PageContainer>
        {isFeatureEnabled(Feature.IsChina) ? <PrivacyStatementContentChina /> : <PrivacyStatementContent />}
      </PageContainer>
    </DarkLayoutContainer>
  )
}

export default PrivacyStatement
