import React, { FC } from 'react'
import { H4 } from '../../../../shared/components'
import { HeaderContainer, AddButton } from './Styles'

type Props = {
  title: string
  addButtonId: string
  addButtonText: string
  disableAddButton: boolean
  onClickAdd?: () => void
}

const ListHeader: FC<Props> = ({ title, addButtonId, addButtonText, disableAddButton, onClickAdd }) => {
  return (
    <HeaderContainer>
      <H4>{title}</H4>
      <AddButton id={addButtonId} onClick={onClickAdd} type="primary" disabled={disableAddButton}>
        {addButtonText}
      </AddButton>
    </HeaderContainer>
  )
}

export default ListHeader
