import styled from 'styled-components'
import { color } from '../../utils/styles'

export const QRCodeImage = styled.div`
  background: ${color.white};
  border: 4px solid ${color.white};
  border-radius: 4px;
  padding-top: 4px;
  padding-left: 3px;
`
