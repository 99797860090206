import styled from 'styled-components'
import Button from '../../../../shared/components/Button'
import { P, H5 } from '../../../../shared/components/Typography'

export const MFAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledForm = styled.form`
  text-align: center;
`

export const TwoFactorAuthText = styled(H5)`
  margin-top: 0;
`

export const OpenAuthAppText = styled(P)`
  margin-bottom: 30px;
  padding: 0 50px;
`

export const VerificationCodeContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    &:focus {
      outline: none !important;
    }
  }
  textarea {
    &:focus {
      outline: none !important;
    }
  }

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 42px;
`

export const VerifyButton = styled(Button)`
  width: 170px;
`

export const props = {
  width: '28px',
  height: '32px',
  fontSize: '20pt',
  color: 'black',
  borderRadius: '4px',
  border: '1.5px solid #0071B9',
  margin: '4px',
  opacity: '1',
  paddingLeft: '6px',
}
