import styled from 'styled-components'
import { color, size } from '../../utils/styles'
import { ReactComponent as CrossIcon } from '../../../assets/icons_close_small.svg'
export const Overlay = styled.div<{ show: boolean; transparent?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ transparent }) => (transparent ? 'transparent' : 'rgba(0, 0, 0, 0.85)')};
  z-index: 2;
`

export const PanelContainer = styled.div<{ width: string; height: string | '50vh' }>`
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  margin: auto;
  height: ${({ height }) => height};
  z-index: 100;
  
  width: ${({ width }) => width};
}

  @media (max-width: ${size.medium}) {
    width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  background-color: ${color.gray80};
  @media (max-width: ${size.medium}) {
    height: 160px;
  }
`

export const BackButtonContainer = styled.div`
  position: absolute;
  margin-left: 48px;

  @media (max-width: ${size.medium}) {
    margin-left: 16px;
  }
`

export const Title = styled.div`
  color: ${color.gray20};
  font-size: 23px;
  text-align: center;
  margin-left: 15px;
  width: 100%;

  @media (max-width: ${size.medium}) {
    font-size: 32px;
    margin-left: 74px;
    margin-right: 58px;
    line-height: 1.2;
  }
`

export const Content = styled.div`
  padding: 20px;
  font-size: 16px;
  color: ${color.gray20};
  background-color: ${color.gray90};
  height: auto;
  max-height: 500px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${size.medium}) {
    padding: 50px 10px;
  }
`
export const CloseIcon = styled(CrossIcon)`
  height: 18px;
  margin-right: 6px;
  display: inline-block;
`
