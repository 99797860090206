import styled, { css } from 'styled-components'
import { color } from '../../shared/utils/styles'
import { Table } from '../../shared/components'

const tableWidthMixin = css`
  width: 642px;
`

export const HeaderRow = styled(Table.HeaderRow)`
  ${tableWidthMixin}
`

export const ContentRow = styled(Table.ContentRow)`
  ${tableWidthMixin}
`

export const Column = styled(Table.Column)`
  ${tableWidthMixin}
  flex: 1;
`

export const ColumnSmall = styled(Table.Column)`
  width: 400px;
  flex: 0.5;
`

export const ColumnLarge = styled(Table.Column)`
  flex: 2;
`

export const ColumnWithIcon = styled(Table.Column)`
  ${tableWidthMixin}
  flex: 1;
  display: flex;
  align-items: center;
`

export const NotLoadedTableText = styled.p`
  color: ${color.gray20};
`
