import styled from 'styled-components'
import { color } from '../../utils/styles'

const NotificationIcon = styled.i`
  position: absolute;
  top: 1px;
  right: 1px;
  height: 9px;
  width: 9px;
  vertical-align: text-top;
  background-color: ${color.gray03};
  border: solid 1px ${color.gray80};
  border-color: ${color.gray80};
  border-radius: 50%;
`

export default NotificationIcon
