import React, { FC } from 'react'
import { SuccessContainer, OkIcon, SuccessMessage, Button } from './Styles'

type Props = {
  successText: string
  successTextId?: string
  buttonText: string
  buttonOnClick: () => void
  isLoading?: boolean
}

const SuccessView: FC<Props> = ({ successText, successTextId, buttonText, buttonOnClick, isLoading = false }) => {
  return (
    <SuccessContainer>
      <OkIcon />
      <SuccessMessage id={successTextId}>{successText}</SuccessMessage>
      <Button ghost={true} onClick={buttonOnClick} loading={isLoading}>
        {buttonText}
      </Button>
    </SuccessContainer>
  )
}

export default SuccessView
