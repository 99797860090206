import { useEffect, useState } from 'react'

export default (): boolean | undefined => {
  if (typeof window !== 'object') {
    return
  }
  if (!window.matchMedia) {
    return
  }

  const [matches, setMatches] = useState(window.matchMedia('print').matches)

  useEffect(() => {
    const media = window.matchMedia('print')
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    media.addEventListener ? media.addEventListener('change', listener) : media.addListener(listener)
    return () =>
      media.removeEventListener ? media.removeEventListener('change', listener) : media.removeListener(listener)
  }, [matches])

  return matches
}
