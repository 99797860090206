import React, { FC, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { Button, H5, LoadingAnimated, Modal, Table } from '../../../shared/components'
import { Invitation } from '../../../shared/models/invitation'
import { useAuth } from '../../../shared/contexts/authContext'
import { InvitationsAPI } from '../../../shared/api/invitations'
import {
  InvitationList,
  ButtonAccept,
  ButtonsContainer,
  ButtonDecline,
  OrganizationNameRowContent,
  MessageContainer,
} from './Styles'
import { HeaderRow, ContentRow } from '../Styles'
import { InvitationsStrings } from '../../../shared/strings/InvitationsContent'

const { Column } = Table

type Props = {
  setIsErrorVisible: (isVisible: boolean) => void
  setErrorDetail: (errorDetail: string) => void
  setIsSuccessVisible: (isVisible: boolean) => void
  setSuccessMessage: (message: string) => void
}

const Invitations: FC<Props> = ({ setIsErrorVisible, setErrorDetail, setIsSuccessVisible, setSuccessMessage }) => {
  const {
    isLoading: areInvitationsLoading,
    error: loadInvitationsError,
    data: invitations,
  } = useQuery(InvitationsAPI.queryNames.GET_ALL, () => InvitationsAPI.getAll())
  const { mutateAsync: acceptInvitation } = useMutation(InvitationsAPI.accept)
  const { mutateAsync: declineInvitation } = useMutation(InvitationsAPI.decline)
  const {
    loadInvitations,
    forceRefreshToken,
    handleCustomerOrganizations,
    addToNewOrganizationIdList,
    setIsShowNewOrganizationTooltip,
  } = useAuth()

  const [modalVisible, setModalVisible] = useState(false)

  const [acceptingInvitationId, setAcceptingInvitationId] = useState('')
  const [acceptedInvitations, setAcceptedInvitations] = useState([] as string[])
  const [decliningInvitationId, setDecliningInvitationId] = useState('')
  const [declinedInvitations, setDeclinedInvitations] = useState([] as string[])

  const hideToastNotifications = () => {
    setIsSuccessVisible(false)
    setIsErrorVisible(false)
  }

  const showModal = (invitationId: string) => {
    hideToastNotifications()
    setDecliningInvitationId(invitationId)
    setModalVisible(true)
  }

  const onClickAccept = async (i: Invitation) => {
    hideToastNotifications()
    setAcceptingInvitationId(i.invitationId)
    try {
      await acceptInvitation(i.invitationId)
      await forceRefreshToken()
      await handleCustomerOrganizations()
      addToNewOrganizationIdList(i.organizationId)
      setIsShowNewOrganizationTooltip(true)
      await loadInvitations()

      setAcceptedInvitations((acceptedInvitations) => [...acceptedInvitations, i.invitationId])
    } catch (err) {
      if (err.response) {
        let errorMessage = InvitationsStrings.failedToAcceptInvitation
        if (err.response?.data?.message) {
          errorMessage += `: ${err.response?.data?.message}`
        }
        setErrorDetail(errorMessage)
      } else {
        setErrorDetail(InvitationsStrings.internalServerError)
      }
      setIsErrorVisible(true)
    } finally {
      setAcceptingInvitationId('')
    }
  }

  const handleAgreeToDecline = async (invitationId: string) => {
    setModalVisible(false)
    try {
      await declineInvitation(invitationId)
      await loadInvitations()
      setDeclinedInvitations((declinedInvitations) => [...declinedInvitations, invitationId])
      setSuccessMessage(InvitationsStrings.declinedInvitationSuccess)
      setIsSuccessVisible(true)
    } catch (err) {
      if (err.response) {
        let errorMessage = InvitationsStrings.failedToDeclineInvitation
        if (err.response?.data?.message) {
          errorMessage += `: ${err.response?.data?.message}`
        }
        setErrorDetail(errorMessage)
      } else {
        setErrorDetail(InvitationsStrings.internalServerError)
      }
      setIsErrorVisible(true)
    } finally {
      setDecliningInvitationId('')
    }
  }

  const handleCancelDecline = () => {
    setDecliningInvitationId('')
    setModalVisible(false)
  }

  const isExpired = (expiredAt: number) => Date.now() > expiredAt

  const showInvitationsList = () => {
    if (areInvitationsLoading) {
      return <LoadingAnimated />
    }
    if (loadInvitationsError) {
      return <MessageContainer>{InvitationsStrings.failedToFetchInvitationsList}</MessageContainer>
    }
    if (!invitations || invitations.length === 0) {
      return <H5>{InvitationsStrings.noOpenInvitationsMessage}</H5>
    }
    return (
      <>
        <HeaderRow>
          <OrganizationNameRowContent>{InvitationsStrings.organization}</OrganizationNameRowContent>
          {InvitationsStrings.expires}
        </HeaderRow>
        {invitations.map((i: Invitation, k: number) => (
          <ContentRow key={k}>
            <OrganizationNameRowContent id="invitation-organization-name-js" title={i.organizationName}>
              {i.organizationName}
            </OrganizationNameRowContent>
            <Column>
              {new Date(i.expiredAt).toLocaleDateString('fi', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </Column>
            {!isExpired(i.expiredAt) && (
              <ButtonsContainer>
                <ButtonDecline
                  id="decline-invitation-button-js"
                  ghost={true}
                  size="small"
                  key={`decline-${i.invitationId}`}
                  loading={i.invitationId === decliningInvitationId}
                  disabled={[...declinedInvitations, ...acceptedInvitations, acceptingInvitationId].includes(
                    i.invitationId
                  )}
                  onClick={() => showModal(i.invitationId)}
                >
                  {declinedInvitations.includes(i.invitationId)
                    ? InvitationsStrings.declined
                    : InvitationsStrings.decline}
                </ButtonDecline>
                <ButtonAccept
                  id="accept-invitation-button-js"
                  type="primary"
                  size="small"
                  key={`accept-${i.invitationId}`}
                  loading={i.invitationId === acceptingInvitationId}
                  disabled={[...acceptedInvitations, ...declinedInvitations, decliningInvitationId].includes(
                    i.invitationId
                  )}
                  onClick={() => onClickAccept(i)}
                >
                  {acceptedInvitations.includes(i.invitationId)
                    ? InvitationsStrings.accepted
                    : InvitationsStrings.accept}
                </ButtonAccept>
              </ButtonsContainer>
            )}
          </ContentRow>
        ))}
        <Modal
          title={InvitationsStrings.modalTitle}
          width={672}
          visible={modalVisible}
          closable={false}
          footer={[
            <Button
              id="modal-decline-invitation-js"
              size="large"
              style={{ width: '304px', marginRight: '24px' }}
              key="submit"
              ghost={true}
              onClick={() => handleAgreeToDecline(decliningInvitationId)}
            >
              {InvitationsStrings.modalButtonDecline}
            </Button>,
            <Button
              id="modal-goback-invitation-js"
              size="large"
              style={{ width: '272px' }}
              key="back"
              type="primary"
              onClick={handleCancelDecline}
            >
              {InvitationsStrings.modalButtonGoBack}
            </Button>,
          ]}
        >
          {InvitationsStrings.modalConfirmText}
        </Modal>
      </>
    )
  }

  return <InvitationList>{showInvitationsList()}</InvitationList>
}

export default Invitations
