import styled from 'styled-components'
import { color, size } from '../../../shared/utils/styles'

const AuthFormWrapper = styled.div`
  width: 350px;
  margin: 0 auto;

  .ant-form-item {
    margin-bottom: 30px;

    @media (max-width: ${size.medium}) {
      margin-bottom: 15px;
    }
  }

  .ant-form-item-control-input-content label {
    padding-bottom: 5px;
    display: inline-block;
  }

  .form-label {
    color: ${color.gray20};
  }

  @media (max-width: ${size.medium}) {
    width: 300px;
  }
`

export default AuthFormWrapper
