import styled from 'styled-components'
import { color } from '../../utils/styles'

const Badge = styled.span`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: ${color.blueBase};
  background-color: ${color.white};
  font-size: 12px;
  padding: 0 0.75em;
  border-radius: 12px;
  text-transform: uppercase;
`

export default Badge
