import React, { FC, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  ExperienceApisContainer,
  OperationalApisContainer,
  CardContainer,
  TitleContainer,
  Circle,
  TitleArea,
  TextArea,
  Icon,
  ReadMoreButtonArea,
  StartUsingBtn,
  TilesContainer,
} from './Styles'
import { DarkLayoutContainer } from '../../App/Styles'
import { H2, H3, CardContent, HeroImage } from '../../shared/components'
import koneapisHeroImage from '../../assets/bg-img-apis.jpg'
import koneapisHeroImageLarge from '../../assets/bg-img-apis@2x.jpg'
import {
  KONEAPIsHeroImage,
  ExperienceApis,
  OperationalApis,
  ElevatorCallApi,
  ServiceInfoApi,
  EquipmentStatusApi,
  ServiceRobotApi,
  SEOProps,
  KONEApisSharedStrings,
} from '../../shared/strings/KONEAPIsTextContent'
import arrowIcon from '../../assets/icons-maintenance-data-category.svg'
import maintenanceIcon from '../../assets/maintenance-data-category-icon.svg'
import TitleWrapper from '../../shared/components/TitleWrapper'

const KONEAPIs: FC = () => {
  const history = useHistory()
  const location = useLocation()
  function onStartUsingClick() {
    history.replace({ pathname: location.pathname, search: '?auth=register' })
  }
  useEffect(() => {
    const id = location.hash
    const scrollElement = document.getElementById(id)
    if (scrollElement !== null) {
      //Scrolls to top of the element
      const y = scrollElement.getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  })

  const images = {
    small: koneapisHeroImage,
    large: koneapisHeroImageLarge,
  }
  return (
    <>
      <TitleWrapper title={SEOProps.title} metaTags={SEOProps.metaTags} />
      <HeroImage
        title={KONEAPIsHeroImage.title}
        body={KONEAPIsHeroImage.body}
        image={images}
        ingressWidth={'70%'}
        button={false}
      />
      <DarkLayoutContainer>
        <ExperienceApisContainer id="#experience-apis">
          <TitleContainer id="experience-api-title">
            <Circle>
              <Icon icon={arrowIcon} />
            </Circle>
            <TitleArea>
              <H2 textTransform="uppercase">{ExperienceApis.title}</H2>
              <H3>{ExperienceApis.subtitle}</H3>
            </TitleArea>
          </TitleContainer>
          <CardContainer id="experience-api-card">
            <TextArea id="experience-api-textarea">{ExperienceApis.body}</TextArea>
            <ReadMoreButtonArea id="experience-api-button">
              <StartUsingBtn onClick={onStartUsingClick} ghost>
                {KONEApisSharedStrings.createYourSolution}
              </StartUsingBtn>
            </ReadMoreButtonArea>
          </CardContainer>
        </ExperienceApisContainer>
        <TilesContainer>
          <CardContent
            title={ElevatorCallApi.title}
            body={ElevatorCallApi.subtitle}
            iconDimension="68px"
            button={false}
            height={'256px'}
            targetPath="/elevator-call-api"
            id="koneapi-elevator-call"
          />
          <CardContent
            title={ServiceRobotApi.title}
            body={ServiceRobotApi.subtitle}
            iconDimension="68px"
            button={false}
            height={'256px'}
            targetPath="/service-robot-api"
            id="koneapi-service-robot"
          />
        </TilesContainer>
        <OperationalApisContainer id="#operational-apis">
          <TitleContainer id="operational-api-title">
            <Circle>
              <Icon icon={maintenanceIcon} />
            </Circle>
            <TitleArea>
              <H2 textTransform="uppercase">{OperationalApis.title}</H2>
              <H3>{OperationalApis.subtitle}</H3>
            </TitleArea>
          </TitleContainer>
          <CardContainer id="operational-api-card">
            <TextArea id="operational-api-textarea">{OperationalApis.body}</TextArea>
            <ReadMoreButtonArea id="operational-api-button">
              <StartUsingBtn onClick={onStartUsingClick} ghost>
                {KONEApisSharedStrings.createYourSolution}
              </StartUsingBtn>
            </ReadMoreButtonArea>
          </CardContainer>
        </OperationalApisContainer>
        <TilesContainer>
          <CardContent
            title={EquipmentStatusApi.title}
            body={EquipmentStatusApi.subtitle}
            iconDimension="68px"
            button={false}
            height={'256px'}
            targetPath="/equipment-status-api"
            id="koneapi-equipment-status"
          />
          <CardContent
            title={ServiceInfoApi.title}
            body={ServiceInfoApi.subtitle}
            iconDimension="68px"
            button={false}
            height={'256px'}
            targetPath="/service-info-api"
            id="koneapi-service-info"
          />
        </TilesContainer>
      </DarkLayoutContainer>
    </>
  )
}

export default KONEAPIs
