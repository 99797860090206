import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'

const AuthFormLabel = styled.label`
  color: ${color.gray20};
  padding-bottom: 5px;
  display: inline-block;
`

export default AuthFormLabel
