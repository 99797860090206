import styled from 'styled-components'
import { H3, H2 } from '../../../../shared/components'
import { color } from '../../../../shared/utils/styles'
import { Col } from 'antd'
import { ReactComponent as SolutionDevelopment1 } from '../../../../shared/assets/img-solution-development-1.svg'
import { ReactComponent as SolutionDevelopment2 } from '../../../../shared/assets/img-solution-development-2.svg'
import { ReactComponent as SolutionDevelopment3 } from '../../../../shared/assets/img-solution-development-3.svg'
import { ReactComponent as SolutionDevelopment4 } from '../../../../shared/assets/img-solution-development-4.svg'
import { ReactComponent as SolutionLine } from '../../../../shared/assets/img-solution-line.svg'
import { ReactComponent as SolutionLine2 } from '../../../../shared/assets/img-solution-line-2.svg'
import { ReactComponent as SolutionLine3 } from '../../../../shared/assets/img-solution-line-3.svg'
import { ReactComponent as UseCase } from '../../../../shared/assets/img-glossary-use-case.svg'

export const LeftCol = styled(Col)`
  margin-bottom: 0px;
`
export const ImgCol = styled(Col)`
  margin: 150px 0px;
  padding: 0px;
`
export const InnerImgCol = styled(Col)`
  margin: 0px;
  padding: 0px;
`
export const RightCol = styled(Col)`
  background-color: #0c0d0d;
  padding: 0 50px 0 0;
`

export const LeftColFirst = styled(Col)`
  padding: 15px 30px 0 30px;
`

export const RightColFirst = styled(Col)`
  padding: 2em 20px 0 0px;
`
export const RightColSecond = styled(Col)`
  padding: 0px 20px 0 0px;
`

export const LeftColLast = styled(Col)`
  padding: 0 85px 50px 85px;
`

export const NewSectionSubTitle = styled(H3)`
  margin-top: 150px;
  margin-bottom: 14px;
`

export const SolutionDevelopment1Svg = styled(SolutionDevelopment1)`
  height: 80%;
  width: 100%;
`

export const SolutionDevelopment2Svg = styled(SolutionDevelopment2)`
  height: 80%;
  width: 100%;
`
export const SolutionDevelopment3Svg = styled(SolutionDevelopment3)`
  height: 80%;
  width: 100%;
`
export const SolutionDevelopment4Svg = styled(SolutionDevelopment4)``
export const SolutionLineSvg = styled(SolutionLine)`
  width: 40%;
  padding: 0px 0px 0px 105px;
`
export const SolutionLine1Svg = styled(SolutionLine)`
  margin-left: 140px;
`
export const SolutionLine2Svg = styled(SolutionLine2)`
  margin-left: 140px;
`
export const SolutionLine3Svg = styled(SolutionLine3)`
  margin-left: 145px;
`
export const UseCaseSvg = styled(UseCase)`
  margin-top: 160px;
`

export const CardContainer = styled.div`
  background: var(--gray-85-1c2226) 0% 0% no-repeat padding-box;
  background: #1c2226 0% 0% no-repeat padding-box;
  margin-top: 2%;
  border-radius: 2% / 4%;
`

export const HeadingText = styled(H3)`
  margin-top: 0px;
  margin-left: 1%;
  border-bottom-style: solid;
  border-bottom-color: ${color.white};
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
  flex-direction: column;
`
export const SvgBottomText = styled(H2)`
  color: ${color.blueBase};
`

export const ContentHeading = styled(H3)`
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: ${color.blueBase};
`

export const ContentSection = styled.div`
  overflow: hidden;
  height: 300px;
  text-align: left;
  margin-bottom: 5%;
  &:hover {
    overflow: auto;
  }
  ::-webkit-scrollbar {
    width: 0.4em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
`

export const ApiOverviewContentSection = styled.div`
  overflow: hidden;
  height: 300px;
  text-align: left;
`

export const ContentInterSection = styled.div`
  width: 80%;
  padding-right: 10%;
`
