import React, { useCallback, useEffect, useMemo, useState, useContext, createContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import WebFont from 'webfontloader'
import { CurrentUser } from '../models/auth'
import { Organization } from '../models/organization'
import { Invitation } from '../models/invitation'
import { MfaMethod, MfaType } from '../models/mfa'
import { OrganizationsAPI } from '../api/organizations'
import { NotificationAPI } from '../api/notifications'
import { InvitationsAPI } from '../api/invitations'
import { sortOrganizationsForSelectControl } from '../utils/sorting'
import { readCurrentOrganization, writeCurrentOrganization, removeCurrentOrganization } from '../utils/storageUtils'
import ErrorPage from '../../shared/pages/ErrorPage'
import { NO_CURRENT_USER_AMPLIFY_ERROR, KONE_ADMIN_GROUP } from '../constants/auth'
import config from '../utils/awsConfig'
import { AuthStrings } from '../strings/AuthContent'
import { GeneralFormStrings } from '../strings/GeneralFormContent'

type ContextProps = {
  currentUser: CurrentUser | null
  isAuthenticated: () => boolean
  isMfaNeeded: () => boolean
  isConsent: () => boolean
  isVersion: () => string
  setIsMfaNeeded: (isMfaNeeded: boolean) => void
  isMfaEnabledForUser: () => boolean
  mfaCode: string | null
  resetMfaCode: () => void
  setupTotp: () => Promise<void>
  verifyTotp: (topt: string, mfaMethod: MfaMethod) => Promise<void>
  isAuthPanelOpen: boolean
  closeAuthPanel: () => void
  signInAndCheckMfa: (email: string, password: string) => Promise<MfaMethod>
  signIn: (email: string, password: string) => Promise<void>
  signInWithNewPassword: (email: string, password: string, newPassword: string) => Promise<void>
  confirmSignIn: (email: string, password: string, code: string, mfaType: MfaType) => Promise<void>
  signOut: () => Promise<void>
  signUp: (email: string, password: string, firstName: string, lastName: string) => Promise<void>
  confirmSignUp: (email: string, confirmationCode: string) => Promise<void>
  resendSignUp: (email: string) => Promise<void>
  openADSignInPage: () => Promise<void>
  currentOrganization: Organization | null
  customerOrganizations: Organization[] | null
  handleCustomerOrganizations: () => Promise<void>
  changeCurrentOrganization: (organization: Organization) => void
  newOrganizationIdList: number[]
  addToNewOrganizationIdList: (organizationId: number) => void
  removeFromNewOrganizationIdList: (organizationId: number) => void
  isShowNewOrganizationTooltip: boolean
  setIsShowNewOrganizationTooltip: (show: boolean) => void
  forceRefreshToken: () => Promise<void>
  forgotPassword: (email: string) => Promise<void>
  forgotPasswordSubmit: (email: string, verificationCode: string, newPassword: string) => Promise<void>
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>
  loadInvitations: () => Promise<void>
  invitations: Invitation[] | null
  hasNewInvitation: () => boolean
  getConsent: (consent: boolean, version: string) => Promise<void>
  setConsent: (consent: boolean, version: string) => Promise<void>
}

const AuthContext = createContext<Partial<ContextProps>>({})
const LOAD_FONTS_TIMEOUT = 2000

function AuthProvider(props: { children: JSX.Element }): JSX.Element {
  const history = useHistory()
  const location = useLocation()

  const [isInitializing, setIsInitializing] = useState(true)
  const [isLoadingFonts, setIsLoadingFonts] = useState(true)
  const [initializationError, setInitializationError] = useState(false)
  const [isAuthPanelOpen, setIsAuthPanelOpen] = useState(false)

  const [currentUser, setCurrentUser] = useState<CurrentUser | null>()
  const [mfaCode, setMfaCode] = useState<string | null>()
  const [isMfa, setIsMfa] = useState(false)
  const [isMfaEnabled, setIsMfaEnabled] = useState(false)

  const [currentOrganization, setCurrentOrganization] = useState<Organization | null>()
  const [customerOrganizations, setCustomerOrganizations] = useState<Organization[] | null>()
  const [isShowNewOrganizationTooltip, setIsShowNewOrganizationTooltip] = useState(false)
  const [newOrganizationIdList, setNewOrganizationIdsList] = useState<number[]>([])

  const [invitations, setInvitations] = useState<Invitation[] | null>()
  const [isCon, setIsCon] = useState(false)
  const [isVar, setIsVar] = useState<string>('v1')
  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    const finishFontsLoading = () => {
      setIsLoadingFonts(false)
    }

    WebFont.load({
      custom: {
        families: ['KONE Information'],
      },
      timeout: LOAD_FONTS_TIMEOUT,
      active: finishFontsLoading,
      inactive: finishFontsLoading,
    })
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const auth = queryParams.get('auth')

    /* if user is authenticated and "auth" query parameter is presented - it means
      we need to redirect the user to the "/dashboard" or to the URL from the "redirect" query param */

    if (currentUser && !!auth && !isMfa) {
      const redirect = queryParams.get('redirect')
      history.push(redirect ? redirect : '/dashboard')
    }

    setIsAuthPanelOpen(!!auth)
  }, [location, currentUser, isMfa])

  const cleanCurrentOrganization = () => {
    removeCurrentOrganization()
    setCurrentOrganization(null)
  }

  const prepareUserData = async () => {
    const user = await extractUserFromToken()
    try {
      if (!user.isKoneAdmin) {
        // load invitations without async to prevent initialization block
        loadInvitations()
        /* set customer organizations and current organization before setting
       current user to make them immediately available on the dashboard pages */
        await handleCustomerOrganizations()
      }
      setCurrentUser(user)
      const authUser = await Auth.currentAuthenticatedUser()
      if (authUser.preferredMFA === MfaType.TOTP) {
        setIsMfaEnabled(true)
      }
    } catch (e) {
      setInitializationError(true)
    }
  }

  const cleanUserData = () => {
    cleanCurrentOrganization()
    setCurrentUser(null)
    setInvitations(null)
    setIsShowNewOrganizationTooltip(false)
    setNewOrganizationIdsList([])
    setMfaCode(null)
    setIsMfaEnabled(false)
    setIsMfa(false)
    setIsCon(false)
    setIsVar('')
  }

  const resetMfaCode = async () => {
    setMfaCode(null)
  }

  const loadInvitations = useCallback(async () => {
    try {
      const invitations = await InvitationsAPI.getAll()
      setInvitations(invitations)
    } catch (err) {
      console.error(err)
      setInvitations(null)
    }
  }, [])

  const hasNewInvitation = useCallback(() => {
    return !!(invitations && invitations.filter((i: Invitation) => i.expiredAt > Date.now()).length > 0)
  }, [invitations])

  const onLoad = async () => {
    try {
      await Auth.currentSession()
      await prepareUserData()
    } catch (e) {
      if (e !== NO_CURRENT_USER_AMPLIFY_ERROR) {
        setInitializationError(true)
        console.error(e?.message || AuthStrings.cannotGetCurrentUser)
        console.info(AuthStrings.cannotInitializeAuthenticatedUserSessionError)
      }
    } finally {
      setIsInitializing(false)
    }
  }

  const closeAuthPanel = () => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('auth')
    history.replace({
      search: queryParams.toString(),
    })
    setIsMfa(false)
  }

  const changeCurrentOrganization = (organization: Organization) => {
    writeCurrentOrganization(organization)
    setCurrentOrganization(organization)
  }

  const handleCustomerOrganizations = async () => {
    const organizations = await OrganizationsAPI.getCustomerOrganizations()
    const sortedOrganizations = sortOrganizationsForSelectControl(organizations)
    setCustomerOrganizations(sortedOrganizations)

    const organization = readCurrentOrganization()

    if (organization) {
      setCurrentOrganization(organization)
    } else if (sortedOrganizations[0]) {
      writeCurrentOrganization(sortedOrganizations[0])
      setCurrentOrganization(sortedOrganizations[0])
    }
  }

  const addToNewOrganizationIdList = (organizationId: number) => {
    setNewOrganizationIdsList((currentList) => [...currentList, organizationId])
  }

  const removeFromNewOrganizationIdList = (organizationId: number) => {
    if (!newOrganizationIdList.includes(organizationId)) {
      return
    }

    setNewOrganizationIdsList((currentList) => currentList.filter((id) => id !== organizationId))
  }

  const extractUserFromToken = useCallback(async () => {
    const { userPoolId } = config
    const currentAuthSession = await Auth.currentSession()
    const idTokenPayload = currentAuthSession.getIdToken().payload
    const groups = (idTokenPayload['cognito:groups'] || []) as [string]
    const user = {
      userName: idTokenPayload.sub as string,
      firstName: idTokenPayload.given_name as string,
      lastName: idTokenPayload.family_name as string,
      email: idTokenPayload.email as string,
      isKoneAdmin: !!groups.find((group) => group.startsWith(userPoolId) || group === KONE_ADMIN_GROUP),
      isKoneOwner: !!groups.find((group) => group.endsWith('owner')),
      organizations: groups,
    }
    return user
  }, [])

  const isAuthenticated = useCallback(() => {
    return !!currentUser
  }, [currentUser])

  const isMfaNeeded = useCallback(() => {
    return isMfa
  }, [isMfa])

  const isConsent = useCallback(() => {
    return isCon
  }, [isCon])

  const isVersion = useCallback(() => {
    return isVar
  }, [isVar])

  const setIsMfaNeeded = useCallback((isMfaNeeded: boolean) => {
    setIsMfa(isMfaNeeded)
  }, [])

  const isMfaEnabledForUser = useCallback(() => {
    return isMfaEnabled
  }, [isMfaEnabled])

  const setupTotp = useCallback(async () => {
    const user = await Auth.currentAuthenticatedUser()
    const data = await Auth.setupTOTP(user)
    setMfaCode(`otpauth://totp/${user?.attributes.email}?secret=${data}&issuer= KONE API Portal`)
  }, [])

  const verifyTotp = useCallback(async (confirmationCode: string, mfa: MfaMethod) => {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.verifyTotpToken(user, confirmationCode)
    await Auth.setPreferredMFA(user, mfa)
    mfa === MfaMethod.TOTP ? setIsMfaEnabled(true) : setIsMfaEnabled(false)
  }, [])

  const signInAndCheckMfa = useCallback(async (email: string, password: string) => {
    cleanCurrentOrganization()
    const user = await Auth.signIn(email.toLowerCase(), password)
    if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      return MfaMethod.TOTP
    } else {
      await prepareUserData()
      return MfaMethod.NOMFA
    }
  }, [])

  const signIn = useCallback(async (email: string, password: string) => {
    cleanCurrentOrganization()
    const user = await Auth.signIn(email.toLowerCase(), password)
    if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      setIsMfa(true)
    } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return user
    } else {
      await prepareUserData()
    }
  }, [])

  const signInWithNewPassword = useCallback(async (email: string, password: string, newPassword: string) => {
    const user = await Auth.signIn(email.toLowerCase(), password)
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      await Auth.completeNewPassword(user, newPassword)
    }
    await prepareUserData()
  }, [])

  const confirmSignIn = useCallback(async (email: string, password: string, code: string, mfaType: MfaType) => {
    const user = await Auth.signIn(email.toLowerCase(), password)
    await Auth.confirmSignIn(user, code, mfaType)
    await prepareUserData()
    setIsMfaEnabled(true)
  }, [])

  const signOut = useCallback(async () => {
    // Clean local storage data. The memory state will be erased when location.href is changed.
    await Auth.signOut()
    history.push('/')
    cleanUserData()
    history.go(0)
  }, [])

  const signUp = useCallback(async (email: string, password: string, firstName: string, lastName: string) => {
    await Auth.signUp({
      username: email.toLowerCase(),
      password: password,
      attributes: {
        email: email.toLowerCase(),
        given_name: firstName,
        family_name: lastName,
      },
    })
  }, [])

  const setConsent = useCallback(async (consent: boolean, version: string) => {
    await NotificationAPI.createSubscription({
      consent: consent,
      version: version,
    })
  }, [])

  const getConsent = async (consent: boolean, version: string) => {
    setIsCon(consent)
    setIsVar(version)
  }

  const confirmSignUp = useCallback(async (email: string, confirmationCode: string) => {
    await Auth.confirmSignUp(email.toLowerCase(), confirmationCode)
    setIsMfa(true)
  }, [])

  const resendSignUp = useCallback(async (email: string) => {
    await Auth.resendSignUp(email.toLowerCase())
  }, [])

  const openADSignInPage = useCallback(async () => {
    Auth.federatedSignIn({ customProvider: config.ADProviderName })
  }, [])

  const forceRefreshToken = useCallback(async () => {
    const authenticatedUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()
    return new Promise<void>((resolve, reject) => {
      authenticatedUser.refreshSession(currentSession.getRefreshToken(), (err: any, session: any) => {
        if (err) {
          console.warn(AuthStrings.cannotRefreshToken)
          reject(err)
        } else {
          resolve(session)
        }
      })
    })
  }, [])

  const forgotPassword = useCallback(async (email: string) => {
    await Auth.forgotPassword(email.toLowerCase())
  }, [])

  const forgotPasswordSubmit = useCallback(async (email: string, verificationCode: string, newPassword: string) => {
    await Auth.forgotPasswordSubmit(email.toLowerCase(), verificationCode, newPassword)
  }, [])

  const changePassword = useCallback(async (oldPassword: string, newPassword: string) => {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, oldPassword, newPassword)
  }, [])

  const value = useMemo(
    () => ({
      isAuthenticated,
      isMfaNeeded,
      isConsent,
      isVersion,
      setIsMfaNeeded,
      isMfaEnabledForUser,
      isAuthPanelOpen,
      closeAuthPanel,
      currentUser,
      mfaCode,
      resetMfaCode,
      signInAndCheckMfa,
      signIn,
      signInWithNewPassword,
      confirmSignIn,
      signOut,
      signUp,
      confirmSignUp,
      setupTotp,
      verifyTotp,
      resendSignUp,
      openADSignInPage,
      customerOrganizations,
      handleCustomerOrganizations,
      currentOrganization,
      changeCurrentOrganization,
      addToNewOrganizationIdList,
      removeFromNewOrganizationIdList,
      newOrganizationIdList,
      isShowNewOrganizationTooltip,
      setIsShowNewOrganizationTooltip,
      forceRefreshToken,
      forgotPassword,
      forgotPasswordSubmit,
      changePassword,
      loadInvitations,
      invitations,
      hasNewInvitation,
      getConsent,
      setConsent,
    }),
    [
      isAuthenticated,
      isMfaNeeded,
      isConsent,
      isVersion,
      setIsMfaNeeded,
      isMfaEnabledForUser,
      isAuthPanelOpen,
      closeAuthPanel,
      currentUser,
      mfaCode,
      resetMfaCode,
      signInAndCheckMfa,
      signIn,
      signInWithNewPassword,
      confirmSignIn,
      signOut,
      signUp,
      confirmSignUp,
      setupTotp,
      verifyTotp,
      resendSignUp,
      openADSignInPage,
      customerOrganizations,
      handleCustomerOrganizations,
      currentOrganization,
      changeCurrentOrganization,
      addToNewOrganizationIdList,
      removeFromNewOrganizationIdList,
      newOrganizationIdList,
      forceRefreshToken,
      forgotPassword,
      forgotPasswordSubmit,
      changePassword,
      loadInvitations,
      invitations,
      hasNewInvitation,
      getConsent,
      setConsent,
    ]
  )

  if (isInitializing || isLoadingFonts) {
    // TODO: show proper spinner
    return <div>{GeneralFormStrings.loading}</div>
  }

  if (initializationError) {
    return <ErrorPage withMargin={true} />
  }

  return <AuthContext.Provider value={value} {...props} />
}

function useAuth(): ContextProps {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(AuthStrings.authProviderError)
  }
  return context as ContextProps
}

export { AuthProvider, AuthContext, useAuth }
