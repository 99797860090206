import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { H3 } from '../Typography'
import { HeroImageContainer, HeroDescriptionContainer, HeroTextButton, HeroTitle } from './Styles'
import { QuickStartHeroImage } from '../../strings/QuickStartTextContent'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

export type HeroImageProps = {
  small: string
  large: string
}

type ContainerProps = {
  title: string
  body: string
  image: HeroImageProps
  button: boolean
  ingressWidth: string
}

const HeroImage: FC<ContainerProps> = ({ title, body, image, button }) => (
  <HeroImageContainer image={image}>
    <HeroTitle dangerouslySetInnerHTML={{ __html: sanitizer(title) }} />
    <HeroDescriptionContainer width={'60%'}>
      <H3>{body}</H3>
      {button && (
        <Link to="/getting-started">
          <HeroTextButton ghost>{QuickStartHeroImage.title}</HeroTextButton>
        </Link>
      )}
    </HeroDescriptionContainer>
  </HeroImageContainer>
)

export default HeroImage
