import styled from 'styled-components'
import Button from '../Button'
import { color, size } from '../../utils/styles'
import { HeroImageProps } from './index'
import { H1 } from '../Typography'

export const HeroImageContainer = styled.div<{ image: HeroImageProps }>`
  background-image: url(${(props) => props.image.large});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: ${color.gray10};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 165px;
  padding-bottom: 110px;
  height: 528px;
  margin-bottom: 16px;

  @media (max-width: ${size.large}) {
    background-image: url(${(props) => props.image.small});
    padding-top: 165px;
    padding-bottom: 110px;
    height: 528px;
    background-position: center;
  }

  @media (max-width: ${size.medium}) {
    height: 460px;
    padding-top: 100px;
    padding-bottom: 110px;
    background-position: center;
  }

  @media (max-width: ${size.small}) {
    height: 420px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-position: center;
  }
`

export const HeroDescriptionContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 80%;

  @media (min-width: ${size.large}) {
    max-width: 50%;
    height: 100%;
  }
`

export const HeroTextButton = styled(Button)`
  margin-top: 24px;
  width: 165px;
  height: 40px;
  justify-content: center;
`
export const HeroTitle = styled(H1)`
  font-size: 3.75rem;
  line-height: 1.1;
  padding: 0 0.5rem;
  margin-bottom: 0;

  && sup {
    font-size: 50%;
    vertical-align: 10px;
  }

  @media (max-width: ${size.large}) {
    font-size: 3rem;
  }

  @media (max-width: ${size.small}) {
    font-size: 2.5rem;
  }
`
