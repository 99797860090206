import React, { FC } from 'react'
import { HeroImage, H2, CardContent, TilesContainer, Line } from '../../shared/components'
import {
  LandingPageHeroImage,
  LandingPageTitles,
  ExperienceAPIs,
  OperationalAPIs,
  SEOProps,
} from '../../shared/strings/MainPageTextContent'
import { DarkLayoutContainer } from '../../App/Styles'
import elevatorCallIcon from '../../assets/elevator-call-category-icon.svg'
import maintenanceDataIcon from '../../assets/maintenance-data-category-icon.svg'
import frontPageHeroImage from '../../assets/bg-image-landing.jpg'
import frontPageHeroImageLarge from '../../assets/bg-image-landing@2x.jpg'
import experienceApiImage from '../../assets/img-api-cat-experience-front.png'
import operationalApiImage from '../../assets/img-api-cat-operational-front.png'
import TitleWrapper from '../../shared/components/TitleWrapper'
import InspirationCards from '../Inspiration/InspirationCards'

const images = {
  small: frontPageHeroImage,
  large: frontPageHeroImageLarge,
}

const FrontPage: FC = () => {
  return (
    <>
      <TitleWrapper title={SEOProps.title} metaTags={SEOProps.metaTags} />
      <HeroImage
        title={LandingPageHeroImage.title}
        body={LandingPageHeroImage.body}
        image={images}
        button={true}
        ingressWidth={'50%'}
      />
      <DarkLayoutContainer>
        <H2 textAlign={'center'}>{LandingPageTitles.exploreKoneApis}</H2>
        <Line />
        <TilesContainer>
          <CardContent
            title={ExperienceAPIs.title}
            body={ExperienceAPIs.body}
            icon={elevatorCallIcon}
            iconDimension="48px"
            image={experienceApiImage}
            height={'256px'}
            targetPath="/kone-apis"
            id="experience-apis"
          />
          <CardContent
            title={OperationalAPIs.title}
            body={OperationalAPIs.body}
            icon={maintenanceDataIcon}
            iconDimension="48px"
            image={operationalApiImage}
            height={'256px'}
            targetPath="/kone-apis"
            id="operational-apis"
          />
        </TilesContainer>
        <H2 textAlign={'center'}>{LandingPageTitles.getInspired}</H2>
        <Line />
        <InspirationCards />
      </DarkLayoutContainer>
    </>
  )
}

export default FrontPage
