import React, { FC, useEffect, useState, useContext } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Radio, RadioChangeEvent, Col, Row, Input } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Solution } from '../../shared/strings/IntegratedSolution'
import { SolutionList } from '../../shared/models/partner'
import dummyPartner from '../../assets/dummyPartner.jpg'
import { TileCreateStrings } from '../../shared/strings/PartnersContent'
import { CommonAPI, SalespagePublicAPI } from '../../shared/api/salespage'
import { AddIconPartner, KnowMoreButton, TilesContainer } from '../../Dashboard/Salespage/Styles'
import {
  HeaderContainer,
  Refine,
  SearchBy,
  SelectSolution,
  StyleCard,
  Selector,
  TextTileSol,
  FilterCheckbox,
  StyledLine,
  StyledLineBottom,
  FilterSearch,
  StyledLineTop,
  TileImage,
  LoadingPartnerData,
  Para,
  DescriptionText,
  DescOnHover,
  NoRecordContainter,
  ChipContainer,
  ChipSpace,
  Tag,
  TagRemove,
  gold,
  silver,
  Tier,
} from './Styles'
import { filterByValue } from '../../shared/utils/salespage'
import { Type } from '../../shared/models/salespage'
import { MasterDataContext } from './state'
import { sendAnalyticsEvent } from '../../shared/adobe-analytics/send-analytics-event'
import { AnalyticsTriggerEvent } from '../../shared/adobe-analytics'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

type FilteredSolutions = {
  country?: string[]
  category?: string[]
  api?: string[]
  tier?: string[]
}

const IntergratedSolutions: FC = () => {
  const [searchStr, setSearchStr] = useState<string | ''>('')
  const [searchType, setSearchType] = useState<number | 1>(1)
  const [filterStr, setFilterStr] = useState<string | ''>('')
  const [hoverList, setHoverList] = useState(new Map<string, boolean | false>())
  const [countries, setCountries] = useState<Type[]>([])
  const [categories, setCategories] = useState<Type[]>([])
  const [tier, setTier] = useState<Type[]>([])
  const [apis, setApis] = useState<Type[]>([])
  const [countriesSelected, setCountriesSelected] = useState<string[]>([])
  const [apisSelected, setApisSelected] = useState<string[]>([])
  const [categorySelected, setCategorySelected] = useState<string[]>([])
  const [tierSelected, setTierSelected] = useState<string[]>([])
  const { Search } = Input
  const [body, setBody] = useState<FilteredSolutions | null>(null)
  const history = useHistory()
  const { isLoading, error, data } = useQuery([SalespagePublicAPI.publicQueryNames.GET_ALL, filterStr, body], () =>
    SalespagePublicAPI.getAllSolutionList(filterStr, body)
  )
  const { masterData, setMasterData } = useContext(MasterDataContext)

  //Backup the master list for the filter search
  const [countryList, setCountryList] = useState<Type[]>([])
  const [categoryList, setCategoryList] = useState<Type[]>([])
  const [tierList, setTierList] = useState<Type[]>([])
  const [apiList, setApiList] = useState<Type[]>([])

  useEffect(() => {
    if (!masterData?.api) {
      CommonAPI.getMasterData().then((data) => {
        setMasterData(data)
      })
      // adding page visit analytics here as master apis are only called when the page loads
      sendAnalyticsEvent(AnalyticsTriggerEvent.PAGES_VIEWED, {
        pageViewed: 'Solution Catalogue Page',
      })
    } else {
      setMasterData(masterData)
    }
  }, [])

  useEffect(() => {
    if (masterData?.api) {
      setCountries(masterData?.country)
      setCountryList(masterData?.country)
      setCategories(masterData?.category)
      setCategoryList(masterData?.category)
      setTier(masterData?.tier)
      setTierList(masterData?.tier)
      setApis(masterData?.api)
      setApiList(masterData?.api)
    }
  }, [masterData?.api])

  useEffect(() => {
    const filterObj: any = {
      filterType: searchType,
      searchStr,
    }
    const newFilter: any = new URLSearchParams(filterObj)
    setFilterStr(`${newFilter}`)
    if (searchStr) {
      sendAnalyticsEvent(AnalyticsTriggerEvent.SOLUTIONS_SEARCHED, {
        solutionsSearched: {
          searchedSolution: searchStr,
          searchType: searchType === 1 ? 'Searched by Integrator Name' : 'Searched by Solution Name',
        },
      })
    }
  }, [searchStr, searchType])

  const getCountryLabel = (code: string) => {
    let label = ''
    masterData?.country?.map((item: Type) => {
      if (item?.value == code) {
        label = item?.label
      }
    })

    return label
  }

  const getCategoryLabel = (code: string) => {
    let label = ''
    categoryList?.map((item: Type) => {
      if (item?.value == code) {
        label = item?.label
      }
    })

    return label
  }
  const getTierLabel = (code: string) => {
    let label = ''
    tierList?.map((item: Type) => {
      if (item?.value == code) {
        label = item?.label
      }
    })
    return label
  }

  const getApiLabel = (code: string) => {
    let label = ''
    apiList?.map((item: Type) => {
      if (item?.value == code) {
        label = item?.label
      }
    })

    return label
  }
  useEffect(() => {
    setBody({
      category: categorySelected,
      api: apisSelected,
      country: countriesSelected,
      tier: tierSelected,
    })
  }, [countriesSelected, apisSelected, categorySelected, tierSelected])

  const onCountryChange = (e: CheckboxChangeEvent, item?: any) => {
    // this should go in array
    const { checked } = e.target
    if (checked) {
      const tempCountry = new Set([...countriesSelected, item.value])
      setCountriesSelected([...tempCountry])
    } else {
      const tempCountry = [...countriesSelected]
      tempCountry?.splice(tempCountry.indexOf(item.value), 1)
      setCountriesSelected(tempCountry)
    }
  }

  const onCloseCountryChip = (code: string) => {
    const tempCountry = [...countriesSelected]
    tempCountry?.splice(tempCountry.indexOf(code), 1)
    setCountriesSelected(tempCountry)
  }

  const onCloseCategoryChip = (code: string) => {
    const tempCategory = [...categorySelected]
    tempCategory?.splice(tempCategory.indexOf(code), 1)
    setCategorySelected(tempCategory)
  }
  const onCloseTierChip = (code: string) => {
    const tempTier = [...tierSelected]
    tempTier?.splice(tempTier.indexOf(code), 1)
    setTierSelected(tempTier)
  }

  const onCloseApiChip = (code: string) => {
    const tempApis = [...apisSelected]
    tempApis?.splice(tempApis.indexOf(code), 1)
    setApisSelected(tempApis)
  }

  const onCategoryChange = (e: CheckboxChangeEvent, item?: any) => {
    const { checked } = e.target
    if (checked) {
      const tempCategory = new Set([...categorySelected, item.value])
      setCategorySelected([...tempCategory])
    } else {
      const tempCategory = [...categorySelected]
      tempCategory?.splice(tempCategory.indexOf(item.value), 1)
      setCategorySelected(tempCategory)
    }
  }

  const onApisChange = (e: CheckboxChangeEvent, item?: any) => {
    const { checked } = e.target
    if (checked) {
      const tempApis = new Set([...apisSelected, item.value])
      setApisSelected([...tempApis])
    } else {
      const tempApis = [...apisSelected]
      tempApis?.splice(tempApis.indexOf(item.value), 1)
      setApisSelected(tempApis)
    }
  }
  const onTierChange = (e: CheckboxChangeEvent, item?: any) => {
    const { checked } = e.target
    if (checked) {
      const tempTier = new Set([...tierSelected, item.value])
      setTierSelected([...tempTier])
    } else {
      const tempTier = [...tierSelected]
      tempTier?.splice(tempTier.indexOf(item.value), 1)
      setTierSelected(tempTier)
    }
  }

  const onSearch = (value: string) => {
    setSearchStr(value)
  }
  const handleSearchType = (e: RadioChangeEvent) => {
    const { value } = e.target
    setSearchType(value)
  }

  const handleHover = (e: any, Id: string) => {
    const newEntry = new Map<string, boolean | false>()
    setHoverList(newEntry.set(Id, true))
  }

  const handleHoverOut = (e: any, Id: string) => {
    const newEntry = new Map<string, boolean | false>()
    setHoverList(newEntry.set(Id, false))
  }

  const onClickKnowMore = (id: string, solutionsName?: string) => {
    if (solutionsName) {
      const encryptedNameandId = btoa(`${solutionsName}_${id}`)
      history.push(`/solution-details/${encryptedNameandId}`)
      sendAnalyticsEvent(AnalyticsTriggerEvent.SOLUTIONS_VISITED, {
        solutionsVisited: solutionsName,
      })
    }
  }

  const getSolutionTiles = (solutions: Array<SolutionList>) => {
    if (solutions?.length === 0) {
      return (
        <>
          <NoRecordContainter>{'No Solution Found!'}</NoRecordContainter>
        </>
      )
    }
    const tierImages: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
      'tier 1': gold,
      'tier 2': silver,
    }

    return solutions?.map((solution: SolutionList) => {
      const TierImage = tierImages[solution.tier]

      return (
        <TextTileSol
          key={`solutionTile_${solution?.solutionId}`}
          onMouseEnter={(e: any) => handleHover(e, `solutionTile_${solution?.solutionId}`)}
          onMouseLeave={(e: any) => handleHoverOut(e, `solutionTile_${solution?.solutionId}`)}
        >
          <>
            <Tier>{TierImage ? <TierImage /> : <p>No tier image available</p>}</Tier>
            {solution?.logo?.length ? (
              <TileImage src={`${solution?.logo?.length ? solution?.logo : dummyPartner}`}></TileImage>
            ) : (
              <AddIconPartner />
            )}
            <DescriptionText> {solution?.solutionName} </DescriptionText>
            {hoverList.get(`solutionTile_${solution?.solutionId}`) && (
              <>
                <DescOnHover>
                  <Para dangerouslySetInnerHTML={{ __html: sanitizer(solution?.solutionHighlights) }} />
                  <KnowMoreButton
                    onClick={() => onClickKnowMore(solution?.solutionId, solution?.solutionName)}
                    type="primary"
                    size="small"
                  >
                    {TileCreateStrings.knowMore}
                  </KnowMoreButton>
                </DescOnHover>
              </>
            )}
          </>
        </TextTileSol>
      )
    })
  }

  const getCountryList = (countries: Type[]) => {
    return countries?.map((item: { value: string; label: string }, index: number) => {
      return (
        <li key={`${item?.value}_${index}`}>
          <FilterCheckbox
            checked={countriesSelected?.includes(item?.value)}
            onChange={(e: any) => onCountryChange(e, item)}
          >
            {item?.label}
          </FilterCheckbox>
        </li>
      )
    })
  }

  const getCategoryList = () => {
    return categories?.map((item: { value: string; label: string }, index: number) => {
      return (
        <li key={`${item?.value}_${index}`}>
          <FilterCheckbox
            checked={categorySelected?.includes(item?.value)}
            onChange={(e: any) => onCategoryChange(e, item)}
          >
            {item?.label}
          </FilterCheckbox>
        </li>
      )
    })
  }
  const getTierList = () => {
    return tier?.map((item: { value: string; label: string }, index: number) => {
      return (
        <li key={`${item?.value}_${index}`}>
          <FilterCheckbox checked={tierSelected?.includes(item?.value)} onChange={(e: any) => onTierChange(e, item)}>
            {item?.label}
          </FilterCheckbox>
        </li>
      )
    })
  }

  const getApis = () => {
    return apis?.map((item: { value: string; label: string }, index: number) => {
      return (
        <li key={`${item?.value}_${index}`}>
          <FilterCheckbox checked={apisSelected?.includes(item?.value)} onChange={(e: any) => onApisChange(e, item)}>
            {item?.label}
          </FilterCheckbox>
        </li>
      )
    })
  }

  const onSearchCountry = (e: any) => {
    const { value } = e?.target
    setCountries(filterByValue(countryList, value))
  }

  const onSearchCategory = (e: any) => {
    const { value } = e?.target
    setCategories(filterByValue(categoryList, value))
  }
  const onSearchTier = (e: any) => {
    const { value } = e?.target
    setTier(filterByValue(tierList, value))
  }

  const onSearchApi = (e: any) => {
    const { value } = e?.target
    setApis(filterByValue(apiList, value))
  }
  // Todo: Rename to Camel case
  return (
    <>
      <HeaderContainer>
        <div>
          <h1 style={{ fontSize: '38px' }}>SOLUTIONS CATALOGUE</h1>
          <StyledLineTop />
          <div>
            <SearchBy>
              <SelectSolution>{Solution.SelectSol}</SelectSolution>
              <Radio.Group name="radiogroup" defaultValue={1}>
                <Radio onChange={handleSearchType} value={1} style={{ color: 'white' }}>
                  {Solution.IntegratorName}
                </Radio>
                <Radio onChange={handleSearchType} value={2} style={{ color: 'white' }}>
                  {Solution.SolutionName}
                </Radio>
              </Radio.Group>
            </SearchBy>
            <Search
              placeholder={searchType === 1 ? 'Search Integrator Name' : 'Search Solution Name'}
              onSearch={onSearch}
              style={{ width: 300 }}
            />
          </div>
        </div>
      </HeaderContainer>
      <Row>
        <Col flex="0 1 300px" style={{ borderRight: '2px solid #1C2226' }}>
          <Refine style={{ color: 'white' }}>
            <div
              style={{ display: 'flex', flexFlow: 'column', color: 'white', marginLeft: '60%', width: 'fit-content' }}
            >
              {Solution.Refine}
              <StyledLine />
              <div style={{ marginBottom: '15%', marginTop: '8%' }}>
                {Solution.Con}
                <FilterSearch onChange={onSearchCountry} placeholder="Search by Country" style={{ width: 230 }} />
                <StyleCard>
                  <Selector>
                    <ol style={{ listStyle: 'none' }}>{getCountryList(countries)}</ol>
                  </Selector>
                </StyleCard>
              </div>
              <StyledLine />
              <div style={{ marginBottom: '15%', marginTop: '8%' }}>
                {Solution.Category}
                <FilterSearch onChange={onSearchCategory} placeholder="Search by Category" style={{ width: 230 }} />
                <StyleCard>
                  <Selector>
                    <ol style={{ listStyle: 'none' }}>{getCategoryList()}</ol>
                  </Selector>
                </StyleCard>
              </div>
              <StyledLine />
              <div style={{ marginBottom: '15%', marginTop: '8%' }}>
                {Solution.Tier}
                <FilterSearch onChange={onSearchTier} placeholder="Search by Tier" style={{ width: 230 }} />
                <StyleCard>
                  <ol style={{ listStyle: 'none' }}>{getTierList()}</ol>
                </StyleCard>
              </div>
              <StyledLine />
              <div style={{ marginBottom: '15%', marginTop: '8%' }}>
                {Solution.Api}
                <FilterSearch onChange={onSearchApi} placeholder="Search by API" style={{ width: 230 }} />
                <StyleCard>
                  <ol style={{ listStyle: 'none' }}>{getApis()}</ol>
                </StyleCard>
              </div>
              <StyledLine />
            </div>
          </Refine>
        </Col>
        <Col flex="1 1 200px">
          <h1 style={{ color: 'white', marginTop: '4%', marginLeft: '6%' }}>
            {Solution.Catalog} <StyledLineBottom />
          </h1>
          <StyledLine />
          <ChipContainer>
            <ChipSpace size={[0, 8]} wrap>
              {countriesSelected?.map((country: any, index: number) => {
                return (
                  <Tag key={`country_key_${index}`} className="countries">
                    {getCountryLabel(country)}
                    <TagRemove onClick={() => onCloseCountryChip(country)} />
                  </Tag>
                )
              })}
              {apisSelected?.map((api: any, index: number) => {
                return (
                  <Tag key={`apis_key_${index}`}>
                    {getApiLabel(api)}
                    <TagRemove onClick={() => onCloseApiChip(api)} />
                  </Tag>
                )
              })}
              {categorySelected?.map((category: any, index: number) => {
                return (
                  <Tag key={`category_key_${index}`}>
                    {getCategoryLabel(category)}
                    <TagRemove onClick={() => onCloseCategoryChip(category)} />
                  </Tag>
                )
              })}
              {tierSelected?.map((tier: any, index: number) => {
                return (
                  <Tag key={`tier_key_${index}`}>
                    {getTierLabel(tier)}
                    <TagRemove onClick={() => onCloseTierChip(tier)} />
                  </Tag>
                )
              })}
            </ChipSpace>
          </ChipContainer>
          <TilesContainer>
            {!isLoading ? getSolutionTiles(data) : <LoadingPartnerData>{'Loading Solutions'}</LoadingPartnerData>}
          </TilesContainer>
        </Col>
      </Row>
    </>
  )
}

export default IntergratedSolutions
