import { Row } from 'antd'
import { OpenAPIV3 } from 'openapi-types'
import React, { FC } from 'react'
import { H4, P } from '../../../../shared/components/Typography'
import { LeftColFirst, LeftColTight, RightColEmpty } from '../../Styles'
import { Endpoint } from '../types'
import Request from './Request'
import Response from './Response'
import { EndpointTitle, HTTPVerb } from './Styles'

type RestEndpointProps = {
  endpoint: Endpoint
  document: OpenAPIV3.Document
}

const RESTEndpoint: FC<RestEndpointProps> = ({ endpoint, document }: RestEndpointProps) => {
  const id = endpoint.operationId

  return (
    <>
      <Row>
        <LeftColFirst span="12">
          <EndpointTitle id={id}>
            <HTTPVerb>{endpoint.method}</HTTPVerb>
            {endpoint.pattern}
          </EndpointTitle>

          <H4>{endpoint.summary}</H4>

          <P size="small">{endpoint.description}</P>
        </LeftColFirst>
        <RightColEmpty span="12"></RightColEmpty>
      </Row>

      <Request document={document} endpoint={endpoint} />

      <Row>
        <LeftColTight span="12">
          <H4>Responses</H4>
        </LeftColTight>
        <RightColEmpty span="12"></RightColEmpty>
      </Row>

      {endpoint.responses &&
        Object.entries(endpoint.responses).map(([code, responseOrReference]) => (
          <Response
            key={`${id}-${code}`}
            document={document}
            statusCode={code}
            responseOrReference={responseOrReference}
          />
        ))}
    </>
  )
}

export default RESTEndpoint
