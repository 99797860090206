import { RadioChangeEvent } from 'antd/lib/radio'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Paths from '../../../Paths'
import { Button, H2, P } from '../../../../shared/components'
import { LeftCol, RadioButton, RadioGroup, RightColTightAndWide, SectionRow, StickyToggle } from '../../Styles'
import { AsyncApiProps } from '../index'
export interface IntroductionProps {
  asyncApiProps: AsyncApiProps
}

const stickyToggleContainerId = 'stickyToggleContainer'

type StickyToggleState = {
  isFixed: boolean
  width?: number
}

export const Introduction: FC<IntroductionProps> = ({ asyncApiProps }) => {
  const history = useHistory()

  function selectCallType(e: RadioChangeEvent) {
    const path = e.target.value === 'normal' ? Paths.ElevatorWebsocketAPI : Paths.ElevatorWebsocketAPIRobots

    history.push(path.path)
  }

  const [stickyToggleState, setStickyToggleState] = useState<StickyToggleState>({
    isFixed: false,
  })

  const unfixStickyToggle = () => {
    setStickyToggleState({ isFixed: false })
  }

  const fixStickyToggle = (width: number) => {
    setStickyToggleState({ isFixed: true, width: width })
  }

  const onIntersect = (entries: IntersectionObserverEntry[]) => {
    if (entries.length > 0) {
      const entry = entries[0]

      const boundingRect = entry.boundingClientRect
      const containerIsVisible = entry.isIntersecting

      if (containerIsVisible) {
        unfixStickyToggle()
      } else {
        fixStickyToggle(boundingRect.width)
      }
    }
  }

  useEffect(() => {
    const stickyToggleContainer = window.document.getElementById(stickyToggleContainerId)

    if (!stickyToggleContainer) {
      console.error('Did not find sticky toggle container.')
      return
    }

    const observer = new IntersectionObserver(onIntersect, {
      rootMargin: '0px',
      threshold: 1.0,
    })
    observer.observe(stickyToggleContainer)

    return () => {
      // Unfix on unmount to prevent the fixed element from "showing through"
      unfixStickyToggle()
      observer.unobserve(stickyToggleContainer)
    }
  }, [])

  return (
    <SectionRow>
      <LeftCol span="12">
        <H2>{asyncApiProps.apiName}</H2>

        <P size="small">
          <a href="/api/v1/spec/elevator-websocket-api.yaml" download>
            <Button type="primary">Download AsyncAPI 2.0 specification</Button>
          </a>
        </P>

        <P size="small">{asyncApiProps.introText}</P>

        <P size="small">
          Start exploring the capabilities of Elevator WebSocket API by checking the fully functional example project in{' '}
          <a href="https://github.com/konecorp/kone-api-examples" target="_blank" rel="noreferrer">
            GitHub
          </a>
          .
        </P>
      </LeftCol>
      <RightColTightAndWide id={stickyToggleContainerId} span="12">
        <StickyToggle isFixed={stickyToggleState.isFixed} width={stickyToggleState.width}>
          Using API for{' '}
          <RadioGroup value={asyncApiProps.callType} onChange={selectCallType} buttonStyle="solid">
            <RadioButton value="normal">Moving persons</RadioButton>
            <RadioButton value="robot">Moving robots</RadioButton>
          </RadioGroup>
        </StickyToggle>
      </RightColTightAndWide>
    </SectionRow>
  )
}
