import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { APILinkCard, H2, H5, P } from '../../../shared/components'
import { APIList } from './Styles'

type APIProps = {
  name: string
  path: string
}

type KONEAPIPageProps = {
  title: string
  apis: APIProps[]
  events?: APIProps[]
}

const KONEAPIPage: FC<KONEAPIPageProps> = ({ title, apis, events = [], children }) => {
  return (
    <>
      <Row>
        <Col md={24} lg={24} xl={16}>
          <H2>{title}</H2>

          <P size="small">{children}</P>

          {apis.length > 0 && (
            <>
              <H5>Composed with:</H5>

              <APIList>
                {apis.map((api) => (
                  <APILinkCard key={`api-${api.path}`} name={api.name} path={api.path} />
                ))}
              </APIList>
            </>
          )}

          {events.length > 0 && (
            <>
              <H5>Provides events:</H5>

              <APIList>
                {events.map((event) => (
                  <APILinkCard key={`api-${event.path}`} name={event.name} path={event.path} />
                ))}
              </APIList>
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

export default KONEAPIPage
