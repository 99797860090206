import styled from 'styled-components'
import { color, size } from '../../shared/utils/styles'
import { Layout } from 'antd'

export const FooterContainer = styled(Layout.Footer)`
  text-align: center;
  color: ${color.white};

  &.ant-layout-footer {
    padding: 22px 15px;
    font: normal normal normal 11px/18px KONE Information;
  }
  @media (min-width: ${size.large}) {
    &.ant-layout-footer {
      padding: 24px 15px;
      font: normal normal normal 14px/18px KONE Information;
    }
  }
`
