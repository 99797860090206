import { API } from './API'
import { CreateOrganizationData, Organization } from '../models/organization'
import { CreateInvitationData } from '../models/invitation'

export const ORGANIZATIONS_PATH = '/admin/organizations'
export const RESOURCES_PATH = '/admin/resources'
export const PRODUCTS_PATH = '/admin/products'
export const CUSTOMER_ORGANIZATIONS_PATH = '/customer/me/organizations'
export const CUSTOMER_PATH = '/customer/me'

const queryNames = {
  GET_ALL: 'organizations-get-all',
  GET_ORG_MEMBERS_AND_INVITED: 'organizations-get-members',
  GET_INVITED_USERS: 'organizations-get-invited-users',
  GET_MEMBERS_ONLY: 'organization-get-members',
}

const create = async (data: CreateOrganizationData): Promise<Organization> => {
  const parameters = {
    body: {
      name: data.name,
      type: data.type,
      salesforceAccountId: data.salesforceAccountId ? data.salesforceAccountId : undefined,
      country: data.country ? data.country : undefined,
    },
  }
  return await API.post(ORGANIZATIONS_PATH, parameters)
}

const update = async (data: CreateOrganizationData & { id: number }): Promise<Organization> => {
  const parameters = {
    body: {
      name: data.name,
      salesforceAccountId: data.salesforceAccountId ? data.salesforceAccountId : undefined,
      country: data.country,
      distributionListEmailAddress: data.distributionListEmailAddress ? data.distributionListEmailAddress : undefined,
    },
  }
  return await API.patch(`${ORGANIZATIONS_PATH}/${data.id}`, parameters)
}

const getAll = async (): Promise<any> => {
  const organizations = await API.get(ORGANIZATIONS_PATH)
  // organizations.sort((org1: Organization, org2: Organization) => org1.name.localeCompare(org2.name))
  return organizations
}

const grantResourceToOrg = async (data: { kenId: string; kenType: string; orgCognitoGroup: string }): Promise<any> => {
  const parameters = {
    body: { id: data.kenId, type: data.kenType, tags: [data.orgCognitoGroup] },
  }
  return await API.post(RESOURCES_PATH, parameters)
}

const inviteUsers = async (data: CreateInvitationData): Promise<any> => {
  const parameters = {
    body: data.emails.map((e) => ({ email: e })),
  }
  return await API.post(`${ORGANIZATIONS_PATH}/${data.orgId}/invitations`, parameters)
}

const inviteUsersOwner = async (data: CreateInvitationData): Promise<any> => {
  const parameters = {
    body: data.emails.map((e) => ({ email: e })),
  }
  return await API.post(`${CUSTOMER_PATH}/${data.orgId}/invitations`, parameters)
}

const listMembersAndInvitedUsers = async (orgId: number): Promise<any> => {
  const members = await API.get(`${ORGANIZATIONS_PATH}/${orgId}/users`)
  const users = await API.get(`${ORGANIZATIONS_PATH}/${orgId}/invitations`)
  users.sort((u1: any, u2: any) => u1.status < u2.status)
  return [...members, ...users]
}
const listMembersOnly = async (orgId: number): Promise<any> => {
  const members = await API.get(`${ORGANIZATIONS_PATH}/${orgId}/users`)
  // users.sort((u1: any, u2: any) => u1.status < u2.status)
  return members
}

const listMembersAndInvitedUsersOwner = async (orgId: number): Promise<any> => {
  const members = await API.get(`${CUSTOMER_PATH}/${orgId}/users`)
  const users = await API.get(`${CUSTOMER_PATH}/${orgId}/invitations`)
  users.sort((u1: any, u2: any) => u1.status < u2.status)
  return [...members, ...users]
}

const getCustomerOrganizations = async (): Promise<Organization[]> => {
  return await API.get(CUSTOMER_ORGANIZATIONS_PATH)
}

type RemoveMemberParams = {
  organizationId: number
  data: { userName: string }
}

const deleteUserFromOrganization = async (params: RemoveMemberParams): Promise<any> => {
  const parameters = {
    body: params.data,
  }
  return await API.remove(`${ORGANIZATIONS_PATH}/${params.organizationId}/users`, parameters)
}

const deleteUserFromOrganizationOwner = async (params: RemoveMemberParams): Promise<any> => {
  const parameters = {
    body: params.data,
  }
  return await API.remove(`${CUSTOMER_PATH}/${params.organizationId}/users`, parameters)
}

const updateMemberDetails = async ({
  organizationId,
  data,
}: {
  organizationId: number
  data: { email: string; firstName?: string; lastName?: string; role: string }
}): Promise<any> => {
  const parameters = {
    body: data,
  }
  return await API.post(`${ORGANIZATIONS_PATH}/${organizationId}/update`, parameters)
}

const updateMemberDetailsOwner = async ({
  organizationId,
  data,
}: {
  organizationId: number
  data: { email: string; firstName?: string; lastName?: string; role: string }
}): Promise<any> => {
  const parameters = {
    body: data,
  }
  return await API.post(`${CUSTOMER_PATH}/${organizationId}/update`, parameters)
}

const deleteOrganization = async (params: Organization): Promise<any> => {
  return await API.remove(`${ORGANIZATIONS_PATH}/${params.id}/delete`)
}

type RemoveInviteParams = {
  organizationId: number
  invitationId: number
}

const deleteInvite = async (params: RemoveInviteParams): Promise<any> => {
  return await API.remove(`${ORGANIZATIONS_PATH}/${params.organizationId}/invitations/${params.invitationId}`)
}

const deleteInviteOwner = async (params: RemoveInviteParams): Promise<any> => {
  return await API.remove(`${CUSTOMER_PATH}/${params.organizationId}/invitations/${params.invitationId}`)
}

const deleteAccount = async (): Promise<any> => {
  return await API.remove(`${CUSTOMER_PATH}`)
}

export const OrganizationsAPI = {
  queryNames,
  create,
  update,
  getAll,
  grantResourceToOrg,
  inviteUsers,
  listMembersAndInvitedUsers,
  listMembersOnly,
  getCustomerOrganizations,
  deleteUserFromOrganization,
  deleteInvite,
  deleteAccount,
  deleteOrganization,
  updateMemberDetails,
  updateMemberDetailsOwner,
  listMembersAndInvitedUsersOwner,
  inviteUsersOwner,
  deleteInviteOwner,
  deleteUserFromOrganizationOwner,
}
