import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogOct24 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        October 2024 - 2.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        October 2.0 release notes summarizes latest changes for Service Info API.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              The prerequisite to create a webhook subscription for the{' '}
              <Link to={Paths.ServiceInfoAPIV2Docs.path}> Service Info REST APIs</Link> has been reverted.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
