import React, { FC, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { ApplicationsAPI } from '../../../shared/api/applications'
import {
  ButtonsContainer,
  CreateButton,
  ContentWrapper,
  TextContainer,
  ErrorTextHeader,
  ErrorText,
  CenterLoader,
} from './Styles'
import LoadingComponent from '../../../shared/components/LoadingAnimated'
import { LOADER_VISIBILITY_MLS } from '../../../shared/models/loadingComponent'
import { GenerateSecretStrings } from '../../../shared/strings/ApplicationsContent'

type Props = {
  onCancelCreate: () => void
  onSecretGenerationSuccess: (clientSecret: string) => void
  clientId: string
  onError: () => void
}

const GenerateSecret: FC<Props> = ({ onSecretGenerationSuccess, onError, clientId }) => {
  const [isCreationSuccessful, setIsCreationSuccessful] = useState(false)
  const [isLoadingTimerFinished, setIsLoadingTimerFinished] = useState(false)
  const [createSecretError, setCreateSecretError] = useState()

  const {
    mutateAsync: createSecret,
    isLoading: loadingSecret,
    data: secretData,
  } = useMutation(ApplicationsAPI.generateSecret)

  useEffect(() => {
    startTimer()
    generateSecret()
  }, [])

  const tryAgain = async () => {
    setIsCreationSuccessful(false)
    setIsLoadingTimerFinished(false)
    startTimer()
    await generateSecret()
  }

  const generateSecret = async () => {
    try {
      await createSecret(clientId)
      setIsCreationSuccessful(true)
    } catch (err) {
      console.error(err)
      onError()
      setCreateSecretError(err)
    }
  }

  const startTimer = () => {
    setTimeout(() => {
      setIsLoadingTimerFinished(true)
    }, LOADER_VISIBILITY_MLS)
  }

  if (secretData && isCreationSuccessful && isLoadingTimerFinished) {
    onSecretGenerationSuccess(secretData.clientSecret)
  }

  const showLoader = () => {
    return (
      <CenterLoader>
        <LoadingComponent />
      </CenterLoader>
    )
  }

  const showError = () => {
    if (!createSecretError) {
      return null
    }

    return (
      <>
        <TextContainer>
          <ErrorTextHeader>{GenerateSecretStrings.somethingWentWrongError}</ErrorTextHeader>
          <ErrorText>{GenerateSecretStrings.clientSecretError}</ErrorText>
        </TextContainer>
        <ButtonsContainer>
          <CreateButton id="create-button-js" type="primary" htmlType="submit" onClick={tryAgain}>
            {GenerateSecretStrings.tryAgainMessage}
          </CreateButton>
        </ButtonsContainer>
      </>
    )
  }

  return <ContentWrapper>{loadingSecret || !isLoadingTimerFinished ? showLoader() : showError()}</ContentWrapper>
}

export default GenerateSecret
