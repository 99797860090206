export enum AnalyticsTriggerEvent {
  SOLUTIONS_SEARCHED = 'solutions_searched',
  PAGES_VIEWED = 'pages_viewed',
  SOLUTIONS_VISITED = 'solutions_visited',
  CONTACT_TO_PARTNER = 'contact_to_partner',
}

export interface AnalyticsSolutionsSearched {
  searchedSolution: string
  searchType: string
}

export interface AnalyticsContactToPartner {
  partnerName: string
  userDetails: string
}

export interface AnalyticsEventDetails {
  pageViewed?: string
  solutionsVisited?: string
  solutionsSearched?: AnalyticsSolutionsSearched
  contactToPartner?: AnalyticsContactToPartner
}
