import { BuildingId, BuildingList, DeviceMappingList, RefreshResponse, DeviceMapping } from '../models/building'
import { BackendTokenAPI } from './backend'

const queryNames = {
  LIST: 'buildings-list',
  GET_DEVICE_MAPPING_LIST: 'device-mapping-list',
  GET_BUILDING_NAME: 'building-name',
  UPDATE_BUILDING_NAME: 'update-building-name',
  GET_SOME_BUILDING_NAMES: 'get-some-building-names',
}

const PATH_BUILDINGS_LIST = '/api/v1/buildings'
const PATH_DEVICE_MAPPING_LIST = '/api/v1/buildings/building-mappings'
const getPathBuilding = (buildingId: BuildingId) => `/api/v1/buildings/${encodeURIComponent(buildingId)}`

const ADMIN_SCOPE = ['admin/topology']

const listBuildings = async (): Promise<BuildingList> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(PATH_BUILDINGS_LIST, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    throw new Error(`Failed fetching building list: ${response.body ?? response.status}`)
  }
  return (await response.json()) as BuildingList
}

const getDeviceMappings = async (): Promise<DeviceMappingList> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(PATH_DEVICE_MAPPING_LIST, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    throw new Error(`Failed fetching building mappings: ${response.body ?? response.status}`)
  }
  return (await response.json()) as DeviceMappingList
}

const getDeviceMappingByKen = async (kenId: string): Promise<DeviceMapping> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(`${PATH_DEVICE_MAPPING_LIST}?kenId=ken${kenId}`, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    if (response.status === 400) {
      throw new Error(`Failed fetching building mappings, KenId not found`)
    }
    if (response.status === 502) {
      throw new Error(`Failed fetching building mappings, Internal server error`)
    }
  }
  return (await response.json()) as DeviceMapping
}

const getDeviceMappingByBuildingId = async (buildingId: string): Promise<DeviceMapping> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(`${PATH_DEVICE_MAPPING_LIST}?buildingId=${buildingId}`, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    if (response.status === 400) {
      throw new Error(`Failed fetching building mappings, Device not found`)
    }
    if (response.status === 502) {
      throw new Error(`Failed fetching building mappings, Internal server error`)
    }
  }

  return (await response.json()) as DeviceMapping
}

const updateGroupDeviceIdMapping = async (deviceMapping: DeviceMapping): Promise<void> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const putResponse = await fetch(PATH_DEVICE_MAPPING_LIST, {
    method: 'PUT',
    headers: authHeader,
    body: JSON.stringify(deviceMapping),
  })
  console.log(putResponse)
  if (putResponse.status !== 204) {
    if (putResponse.status === 500) {
      throw new Error(`Failed fetching building mappings, Internal server error`)
    }
  }
}

const refreshBuildingConfiguration = async (buildingId: BuildingId): Promise<RefreshResponse> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(getPathBuilding(buildingId), {
    method: 'POST',
    headers: authHeader,
    body: '{}',
  })
  if (!response.ok) {
    throw new Error(`Failed refreshing building ${buildingId}: ${response.body ?? response.status}`)
  }
  return (await response.json()) as RefreshResponse
}

export const BuildingsAPI = {
  queryNames,
  listBuildings,
  refreshBuildingConfiguration,
  updateGroupDeviceIdMapping,
  getDeviceMappings,
  getDeviceMappingByKen,
  getDeviceMappingByBuildingId,
}
