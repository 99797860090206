import styled from 'styled-components'
import Button from '../../../../shared/components/Button'
import { color, size } from '../../../../shared/utils/styles'
import { P, H6, H5 } from '../../../../shared/components/Typography'

export const MFASelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledForm = styled.form`
  text-align: center;
`

export const DescriptionContainer = styled.div`
  text-align: center;
  margin-bottom: 44px;
`

export const ScanQRCodeText = styled(H6)`
  text-align: center;
  margin-top: 0;
`

export const Disable2FAHeaderText = styled(H6)`
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
`

export const QrCodeText = styled(H6)`
  text-align: center;
  margin-bottom: 26px;
`

export const QrCodeScanInfoText = styled(H6)`
  text-align: center;
  margin-bottom: 24px;
  padding: 0 160px;
  margin-top: 20px;
`

export const QRCodeContainer = styled.div`
  text-align: center;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  opacity: 0.4;
  width: 100%;
  margin-top: 34px;
`

export const VerificationCodeContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    &:focus {
      outline: none !important;
    }
  }
  textarea {
    &:focus {
      outline: none !important;
    }
  }

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 22px;
`
export const RegistrationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 110px;
  margin-bottom: 10px;

  @media (max-width: ${size.medium}) {
    flex-direction: column;
  }
`

export const MfaButton = styled(Button)`
  width: 220px;
  margin: 20px;

  @media (max-width: ${size.medium}) {
    margin-bottom: 10px;
  }
`

export const MfaProfileButton = styled(Button)`
  width: 206px;
  height: 40px;
  margin: 20px;

  @media (max-width: ${size.medium}) {
    margin-bottom: 10px;
  }
`

export const EnableLaterAlsoText = styled(P)`
  text-align: center;
  padding: 0 125px;
  margin-top: 20px;
`

export const TwoFactorEnabledText = styled(H5)`
  text-align: center;
  margin-top: 0;
`

export const GoToDashboardButton = styled(Button)`
  margin-top: 30px;
  padding: 0 32px;
`

export const OpenAuthAppText = styled(P)`
  margin-bottom: 35px;
  margin-top: 0;
  padding: 0 230px;
`

export const DisableAuthenticationButton = styled(Button)`
  padding: 0 10px;
`

export const TwoFactorDisabledHeaderText = styled(H6)`
  text-align: center;
  margin-top: 0;
  margin-bottom: 59px;
`

export const OnDisableReturnToProfileButton = styled(Button)`
  padding: 0 10px;
`

export const OnEnableReturnToProfileButton = styled(Button)`
  margin-top: 40px;
  padding: 0 10px;
`

export const ProfileButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 120px;
  margin-bottom: 10px;

  @media (max-width: ${size.medium}) {
    flex-direction: column;
  }
`
export const props = {
  width: '28px',
  height: '32px',
  fontSize: '20pt',
  color: 'black',
  borderRadius: '4px',
  border: '1.5px solid #0071B9',
  margin: '4px',
  opacity: '1',
  paddingLeft: '6px',
}
