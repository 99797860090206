import React, { FC } from 'react'
import { TABLE_CONTENT_LENGTH } from '../../../shared/constants/common'
import {
  NextActiveArrow,
  PreviousActiveArrow,
  NextPassiveArrow,
  PreviousPassiveArrow,
  LastInactiveArrow,
  FirstInactiveArrow,
  LastActiveArrow,
  FirstActiveArrow,
  TablePagination,
  VerticalLineRight,
  AlignArrows,
  VerticalLineLeft,
  PaginationContainer,
} from './Styles'

type Props = {
  dataLength: number
  page: number
  setPage: (page: number) => void
}

const Pagination: FC<Props> = ({ dataLength, page, setPage }) => {
  const maxPage = Math.ceil(dataLength / TABLE_CONTENT_LENGTH)
  const onNextPage = () => {
    if (page !== maxPage - 1) {
      setPage((page + 1) % maxPage)
    }
  }
  const onPrevPage = () => {
    if (page !== 0) {
      setPage((page - 1) % maxPage)
    }
  }
  const jumpToLastPage = () => {
    setPage(maxPage - 1)
  }
  const jumpToFirstPage = () => {
    setPage(0)
  }

  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return page === 0 ? (
        <AlignArrows>
          <FirstInactiveArrow id="first-inactive-arrow" />
          <VerticalLineLeft />
          <PreviousPassiveArrow id="previous-passive-arrow" />
        </AlignArrows>
      ) : (
        <AlignArrows>
          <FirstActiveArrow id="first-active-arrow" onClick={jumpToFirstPage} />
          <VerticalLineLeft />
          <PreviousActiveArrow id="previous-active-arrow" onClick={onPrevPage} />
        </AlignArrows>
      )
    }

    if (type === 'next') {
      return page === maxPage - 1 ? (
        <AlignArrows>
          <NextPassiveArrow id="next-passive-arrow" />
          <VerticalLineRight></VerticalLineRight>
          <LastInactiveArrow id="last-inactive-arrow" />
        </AlignArrows>
      ) : (
        <AlignArrows>
          <NextActiveArrow id="next-active-arrow" onClick={onNextPage} />
          <VerticalLineRight></VerticalLineRight>
          <LastActiveArrow id="last-active-arrow" onClick={jumpToLastPage} />
        </AlignArrows>
      )
    }
    return originalElement
  }

  return (
    <PaginationContainer>
      <TablePagination
        simple
        defaultCurrent={1}
        total={dataLength}
        pageSize={TABLE_CONTENT_LENGTH}
        itemRender={itemRender}
        current={page + 1}
      />
    </PaginationContainer>
  )
}

export default Pagination
