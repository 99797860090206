const initReportingObserver = (): void => {
  if (!('ReportingObserver' in window)) {
    return
  }

  const observer = new ReportingObserver(
    (reports: any) => {
      for (const report of reports) {
        const event = `Using a deprecated API which will be removed on ${report.body.anticipatedRemoval}. Info: ${report.body.message} Deprecation id: ${report.body.id}`

        if (window.onerror) {
          window.onerror(event, report.body.sourceFile, report.body.lineNumber, report.body.columnNumber)
        }
      }
    },
    { types: ['deprecation'], buffered: true }
  )

  observer.observe()
}

export default initReportingObserver
