/* eslint-disable import/named */
import React, { FC } from 'react'
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom'
import { useAuth } from '../../contexts/authContext'

const ProtectedRoute: FC<RouteProps> = ({ children, ...rest }) => {
  const { pathname, search } = useLocation()
  const { isAuthenticated } = useAuth()

  // If user is not authenticated it redirects back to current path after the user logs in.
  return (
    <Route {...rest}>
      {isAuthenticated() ? children : <Redirect to={`/?auth=login&redirect=${pathname}${search}`} />}
    </Route>
  )
}

export default ProtectedRoute
