import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { H3, H4, P } from '../../../../shared/components/Typography'
import { PayloadProperty } from '../../components/PayloadProperty'
import { CodeBlock, LeftCol, Paragraph, RightCol, SectionRow, StyledEventName } from '../../Styles'

export const LiftCallState: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H3>Incoming WebSocket Events</H3>

        <H4 id="updates-for-a-lift-call">Updates for a lift-call</H4>

        <Row>
          <Col span={5}>
            <StyledEventName>lift-call-state</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">This event is sent when the state of the client elevator call is updated</P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="type"
              type="string"
              required={true}
              description={``}
              stringEnums={['lift-call-state']}
            />

            <PayloadProperty
              name="timestamp"
              type="string"
              required={true}
              description={`ISO formatted timestamp of the message`}
            />

            <PayloadProperty
              name="passengerGuidance"
              type="string"
              description={`Gives passenger insight into the possible actions that can be taken. Possible actions depend on the state of the elevator call. 'passengerGuidance' 
              values should be treated as enums about the state of the call, and not as strings to present to human passengers.`}
              stringEnums={['wait_for_car', 'enter_car', 'stay_in_car', 'exit_car', 'call_cancelled']}
              stringEnumDescriptions={[
                {
                  value: 'wait_for_car',
                  description: `A lift car has been assigned to serve the lift call, but the car hasn't arrived to the floor. The passenger should wait for the lift to arrive.`,
                },
                {
                  value: 'enter_car',
                  description: `Lift car is either arriving or has already arrived to the source floor. The passenger can prepare to enter the lift car. 
                  The passenger can enter the lift car once the lift car doors open.`,
                },
                {
                  value: 'stay_in_car',
                  description: `Lift car doors are closing or already closed and the lift is starting to move or is already moving. Passenger should stay in the lift car.`,
                },
                {
                  value: 'exit_car',
                  description: `Lift is either arriving or has already arrived to the destination floor and the door is soon about to open, is opening or is already open. 
                  Passenger should prepare to exit the lift car. The passenger can exit the lift car when the lift car doors open.`,
                },
                {
                  value: 'call_cancelled',
                  description: `For one reason or another the system hasn't been able to serve the passenger's request and the call has been cancelled. 
                  In this case the cancelReason property provides more information about the reason of the cancellation.`,
                },
              ]}
            />

            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Matches the requestId specified in the request`}
            />

            <PayloadProperty
              name="callStatus"
              type="string"
              required={true}
              description={`Reflects the state of the elevator call. The state will be updated as the call progresses.`}
              stringEnums={['created', 'approved', 'served', 'cancelled', 'timeout']}
              stringEnumDescriptions={[
                {
                  value: 'created',
                  description: `Lift call has been created in the system, but a lift car hasn't yet been assigned to serve the passenger`,
                },
                {
                  value: 'approved',
                  description: `A lift car has been assigned to serve the Lift call and the passenger will soon be or is being served by the lift. 
                  The passengerGuidance property provides additional infromation about the state of the call.`,
                },
                {
                  value: 'served',
                  description: `In the case of destination call this means that the lift car has arrived to the destination of the call and passenger's 
                  call request has been served. In the case of landing call this means that the lift has arrived to the floor that the landing call was made from and 
                  the passenger's call request has been served.`,
                },
                {
                  value: 'cancelled',
                  description: `For one reason or another, the system isn't able to serve the passenger and the call has been cancelled. In this case passengerGuidance has 
                  a value of call_cancelled. Additionally the cancelReason property provides more information about the reason of the cancellation. 
                  'cancelReason' values should be treated as enums rather than strings to present to human passengers.`,
                },
                {
                  value: 'timeout',
                  description: `For one reason or another, the system isn't able to serve the passenger and the call times out. This can happen in 2 scenarios,
                  First scenario can be the call is created but the site(building elevators) has not responded to the call command within 15 seconds, resulting in timeout. 
                  The value for cancelReason in this case will be 'timeout_in_created'. Second Scenario is the elevator call is approved by the site initially and 
                  for some reason call updates or event information is not flowing from site. If there are no updates for 210 seconds the call will result in timeout 
                  with cancleReason 'timeout_in_approved'. 'cancelReason' values should be treated as enums rather than strings to present to human passengers.`,
                },
              ]}
            />

            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`The type of the call defines if the user is going to be a robot or a normal user`}
              stringEnums={['normal', 'robot']}
              stringEnumDescriptions={[
                {
                  value: 'normal',
                  description: `Normal call type.`,
                },
                {
                  value: 'robot',
                  description: `The user is a robot and thus the door will take longer to open and close`,
                },
              ]}
            />

            <PayloadProperty
              name="callAction"
              type="string"
              required={true}
              description={`Defines which kind of call the client is making`}
              stringEnums={['destination']}
              stringEnumDescriptions={[
                {
                  value: 'destination',
                  description: `A destination call, which sets both the sourceId and destinationId`,
                },
              ]}
            />

            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
            />

            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the lift group `}
              format={`group:BUILDING_ID:GROUP_ID`}
            />

            <PayloadProperty
              name="liftId"
              type="string"
              description={`Unique identifier for the lift`}
              format={`lift:BUILDING_ID:GROUP_ID:LIFT_ID`}
            />

            <PayloadProperty
              name="deckId"
              type="string"
              description={`Unique identifier for the deck`}
              format={`deck:BUILDING_ID:GROUP_ID:LIFT_ID:DECK_ID`}
            />

            <PayloadProperty
              name="sourceDoorId"
              type="string"
              description={`Unique identifier for the door which will open when the elevator reaches the sourceId`}
              format={`door:{buildingId}:{groupId}:{liftId}:{deckId}:{doorId}`}
            />

            <PayloadProperty
              name="destinationDoorId"
              type="string"
              description={`Unique identifier for the door which will open when the elevator reaches the destinationId`}
              format={`door:{buildingId}:{groupId}:{liftId}:{deckId}:{doorId}`}
            />

            <PayloadProperty
              name="sourceId"
              type="string"
              description={`The starting area where the elevator takes the user on board `}
              format={`area:{buildingId}:{areaId}`}
            />

            <PayloadProperty
              name="destinationId"
              type="string"
              description={`The area id of destination`}
              format={`area:{buildingId}:{areaId}`}
            />

            <PayloadProperty
              name="cancelReason"
              type="string"
              required={false}
              description={`Reason code for call cancellation`}
              stringEnums={[
                'area_does_not_exist',
                'area_is_locked',
                'please_wait',
                'cannot_serve_now',
                'terminal_is_not_in_use',
                'invalid_side',
                'no_lifts_available',
                'access_denied',
                'timeout_in_created',
                'timeout_in_approved',
                'unknown',
              ]}
              stringEnumDescriptions={[
                {
                  value: 'area_does_not_exist',
                  description: `An area that isn't available in the building was sent as either sourceAreaId or destinationAreaId when the destination call was made`,
                },
                {
                  value: 'area_is_locked',
                  description: `An area that was sent when the lift call was made exists, but is locked by the access control system in the building. Such locks can be 
                  e.g. permanent, time based, etc.`,
                },
                {
                  value: 'please_wait',
                  description: `System is currently busy and a lift car can't be assigned for the call`,
                },
                {
                  value: 'cannot_serve_now',
                  description: `In a case of a landing call an identical landing call already exists in the system and therefore the second one is being cancelled`,
                },
                {
                  value: 'terminal_is_not_in_use',
                  description: `Terminal that was specified when the lift call was made is not in use`,
                },
                {
                  value: 'invalid_side',
                  description: `For one reason or another the system configuration is invalid`,
                },
                {
                  value: 'no_lifts_available',
                  description: `All the lifts are currently under maintenance and no lift car can be assigned to serve the call`,
                },
                {
                  value: 'access_denied',
                  description: `The access to the building was denied by the access control system`,
                },
                {
                  value: 'timeout_in_created',
                  description: `The call timed out before being approved`,
                },
                {
                  value: 'timeout_in_approved',
                  description: `The call timed out after being approved but before before being served`,
                },
                {
                  value: 'unknown',
                  description: `The system hasn't given a specific reason for the cancellation`,
                },
              ]}
            />

            {/* Only for landing calls */}
            {/* <PayloadProperty
              name="direction"
              type="string"
              description={`The direction of the elevator.`}
              stringEnums={['up', 'down']}
              stringEnumDescriptions={[
                {
                  value: 'up',
                  description: `up, represents the movement of the lift towards the top of the shaft`,
                },
                {
                  value: 'down',
                  description: `down, respresents the movement of the lift towards the bottom of the shaft`,
                },
              ]}
            /> */}
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "lift-call-state",
  "timestamp": "2021-01-15T05:55:41.424Z",
  "requestId": "01841d1c-f4ba-4f9c-a348-6f679bfae86e",
  "callStatus": "approved",
  "passengerGuidance": "wait_for_car",
  "callType": "normal",
  "callAction": "destination",
  "buildingId": "building:99900009301",
  "groupId": "group:99900009301:1",
  "liftId": "lift:99900009301:1:1",
  "deckId": "deck:99900009301:1:1:0",
  "sourceDoorId": "door:99900009301:1:1:0:1",
  "destinationDoorId": "door:99900009301:1:1:0:1",
  "sourceId": "area:99900009301:15100",
  "destinationId": "area:99900009301:2000"
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}

export const LiftDoorState: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <Row>
          <Col span={5}>
            <StyledEventName>lift-door-state</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Elevator door state update. There are four possible states, which inform if the door is either closing,
              opening, closed or open.
            </P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty name="type" type="string" required={true} stringEnums={['lift-door-state']} />

            <PayloadProperty
              name="timestamp"
              type="string"
              required={true}
              description={`ISO formatted timestamp of the message`}
            />

            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={` Unique identifier for the building`}
              format={`building:{buildingId}`}
            />

            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the lift group `}
              format={`group:BUILDING_ID:GROUP_ID`}
            />

            <PayloadProperty
              name="liftId"
              type="string"
              required={true}
              description={`Unique identifier for the lift`}
              format={`lift:BUILDING_ID:GROUP_ID:LIFT_ID`}
            />

            <PayloadProperty
              name="deckId"
              type="string"
              required={true}
              description={`Unique identifier for the deck`}
              format={`deck:BUILDING_ID:GROUP_ID:LIFT_ID:DECK_ID`}
            />

            <PayloadProperty
              name="doorId"
              type="string"
              required={true}
              description={`Unique identifier for the door`}
              format={`deck:{buildingId}:{groupId}:{liftId}:{deckId}:{doorId}`}
            />

            <PayloadProperty
              name="state"
              type="string"
              required={true}
              description={`Door state`}
              stringEnums={['closing', 'closed', 'opening', 'open']}
              stringEnumDescriptions={[
                {
                  value: 'closing',
                  description: `When the door is closing`,
                },
                {
                  value: 'closed',
                  description: `The door has already been closed`,
                },
                {
                  value: 'opening',
                  description: `When the door is opening`,
                },
                {
                  value: 'open',
                  description: `When the door is open`,
                },
              ]}
            />

            <PayloadProperty
              name="currentAreaId"
              type="string"
              required={true}
              description={`The area id where the elevator is at a specific moment in time`}
              format={`area:{buildingId}:{areaId}`}
            />
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="typescript">
          {`{
  type: 'lift-door-state',
  timestamp: '2021-01-21T05:56:22.519Z',
  buildingId: 'building:99900009301',
  groupId: 'group:99900009301:1',
  liftId: 'lift:99900009301:1:1',
  deckId: 'deck:99900009301:1:1:0',
  doorId: 'door:99900009301:1:1:0:1',
  state: 'closed',
  currentAreaId: 'area:99900009301:7000'
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}

export const DeckState: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <Row>
          <Col span={5}>
            <StyledEventName>deck-state</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Elevator deck state update. Gives information about its movement state (moving, standing or none), informs
              if the movement is towards the top or the bottom of the shaft (up or down) and gives information about the
              load state of the lift (normal, full, overload).
            </P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty name="type" type="string" required={true} stringEnums={['deck-state']} />

            <PayloadProperty
              name="timestamp"
              type="string"
              required={true}
              description={`ISO formatted timestamp of the message`}
            />

            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:{buildingId}`}
            />

            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the lift group `}
              format={`group:BUILDING_ID:GROUP_ID`}
            />

            <PayloadProperty
              name="liftId"
              type="string"
              required={true}
              description={`Unique identifier for the lift`}
              format={`lift:BUILDING_ID:GROUP_ID:LIFT_ID`}
            />

            <PayloadProperty
              name="deckId"
              type="string"
              required={true}
              description={`Unique identifier for the deck`}
              format={`deck:BUILDING_ID:GROUP_ID:LIFT_ID:DECK_ID`}
            />

            <PayloadProperty
              name="movingState"
              type="string"
              required={true}
              description={`The state of the lift in a specific moment in time`}
              stringEnums={['standing', 'moving', 'none']}
            />

            <PayloadProperty
              name="movingDirection"
              type="string"
              required={true}
              description={`The direction of the elevator`}
              stringEnums={['up', 'down']}
            />

            <PayloadProperty
              name="loadState"
              type="string"
              required={true}
              description={`loadState defines the amount of weight that the elevator is currently handling`}
              stringEnums={['normal', 'full', 'overload']}
            />

            <PayloadProperty
              name="loadPercentage"
              type="number"
              required={true}
              description={`The load expressed as a percentage`}
            />

            <PayloadProperty
              name="currentLevelMillimeters"
              type="number"
              required={true}
              description={`The vertical position of the elevator specified in millimeters`}
            />
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "deck-state",
  "timestamp": "2021-01-21T05:56:22.519Z",
  "buildingId": "building:99900009301",
  "groupId": "group:99900009301:1",
  "liftId": "lift:99900009301:1:1",
  "deckId": "deck:99900009301:1:1:0",
  "movingState": "moving",
  "movingDirection": "up",
  "loadState": "normal",
  "loadPercentage": 0,
  "currentLevelMillimeters": 21000
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}

export const OkEvent: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <Row>
          <Col span={5}>
            <StyledEventName>ok</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Success response for a request identified with the <strong>requestId</strong>. Asynchronous operation is
              accepted and proceeds in the background.
            </P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty name="type" type="string" required={true} description={``} stringEnums={['ok']} />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Matches the requestId specified in the request`}
            />
            <PayloadProperty
              name="connectionId"
              type="string"
              required={true}
              description={`Identifier for a connection from which request was made. Same for all of the requests made within the connection.`}
            />
            <PayloadProperty
              name="statusCode"
              type="number"
              required={true}
              description={`HTTP status code, 200 or 201 for ok type`}
            />
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "ok",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "statusCode": 201
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}

export const ErrorEvent: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <Row>
          <Col span={5}>
            <StyledEventName>error</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">Error response if something went wrong with the request</P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty name="type" type="string" required={true} description={``} stringEnums={['error']} />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Matches the requestId specified in the request`}
            />
            <PayloadProperty
              name="connectionId"
              type="string"
              required={true}
              description={`Identifier for a connection from which request was made. Same for all of the requests made within the connection.`}
            />
            <PayloadProperty
              name="statusCode"
              type="number"
              required={true}
              description={`HTTP status code. (400, 401, 403, 404, 409, 500)`}
            />
            <PayloadProperty name="message" type="string" required={true} description={`Error message`} />
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "requestId": "3ed49fce-a55f-4675-9b79-849a1cab6ec6",
  "connectionId": "amAcVfcBjoECFxg=",
  "statusCode": 409,
  "message": "requestId must by unique within connection"
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}
