import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogMay3 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        May 2024 - 3.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        May 3.0 release notes summarizes latest changes for API Portal documentation fix.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              <q>workOrderPlannedStartDate</q> field added in
              <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}> Service Info Webhook API </Link>
              documentation.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
