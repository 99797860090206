import React, { FC } from 'react'
import { HashLink as Link } from 'react-router-hash-link'

const MarkdownLink: FC<{ href: string }> = ({ href, children }) => {
  if (href.startsWith('http')) {
    return <a href={href}>{children}</a>
  } else {
    return <Link to={href}>{children}</Link>
  }
}

export default MarkdownLink
