import React from 'react'
import { H4, P, UL, OL } from '../../../../shared/components/Typography'
import { Link } from 'react-router-dom'
import Paths from '../../../Paths'

export const ReleaseNotesLogOctober2 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        October 2023 - 2.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        October 2.0 release notes summarizes updates for Service Info REST APIs and related API Portal documentation
        changes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              <q>salesOrderNumber</q> is now available in{' '}
              <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrderById'}>Service Order Details API</Link>.
            </li>
            <li>
              <q>customerPurchaseOrderNumber</q> field will also be now available in{' '}
              <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrdersByEquipmentId'}>
                Service Order List API
              </Link>
              .
            </li>
            <li>
              The customerPurchaseOrderNumber field in the Service Order Details API that was receiving a null value has
              been fixed and is now receiving the correct value.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
