import { Row, Tabs } from 'antd'
import { OpenAPIV3 } from 'openapi-types'
import React, { FC } from 'react'
import { H4, H6, P } from '../../../../shared/components/Typography'
import { PayloadProperty } from '../../components/PayloadProperty'
import { TabbedCodeContainer } from '../../components/TabbedCodeContainer'
import { CodeBlock, LeftColTight, RightColTight } from '../../Styles'
import {
  formatExampleRequestCurl,
  formatExampleRequestRawHttp,
  solveParametersReference,
  solveRequestBodyReference,
} from '../open-api-utils'
import { Endpoint } from '../types'
import Authentication from './Authentication'
import Schema from './Schema'

const TabPane = Tabs.TabPane

type RequestProps = {
  endpoint: Endpoint
  document: OpenAPIV3.Document
}

const Request: FC<RequestProps> = ({ endpoint, document }: RequestProps) => {
  const id = endpoint.operationId
  const requestBody = endpoint.requestBody && solveRequestBodyReference(endpoint.requestBody, document)
  const requestParameters = (endpoint.parameters ?? []).map((parameterOrRef) =>
    solveParametersReference(parameterOrRef, document)
  )

  return (
    <>
      <Row>
        <LeftColTight span="12">
          <H4>Request</H4>

          {requestParameters.length > 0 && (
            <>
              <H6>Request parameters</H6>

              {requestParameters.map((parameter) => (
                <PayloadProperty
                  key={`parameter-${parameter.name}`}
                  name={parameter.name}
                  type={parameter.in}
                  required={parameter.required}
                  description={parameter.description}
                />
              ))}
            </>
          )}

          <Authentication endpoint={endpoint} document={document} />
        </LeftColTight>
        <RightColTight span="12">
          {!requestBody && (
            <>
              <P size="small">Request example</P>
              <TabbedCodeContainer>
                <TabPane tab="HTTP" key="1">
                  <CodeBlock language="http">
                    {formatExampleRequestRawHttp(document, endpoint, requestParameters)}
                  </CodeBlock>
                </TabPane>
                <TabPane tab="cURL" key="2">
                  <CodeBlock language="bash">
                    {formatExampleRequestCurl(document, endpoint, requestParameters)}
                  </CodeBlock>
                </TabPane>
              </TabbedCodeContainer>
            </>
          )}
        </RightColTight>
      </Row>

      {requestBody &&
        Object.entries(requestBody.content).map(([mediaType, content]) => {
          return (
            <Row key={`request-${id}-${mediaType}`}>
              <LeftColTight span="12">
                <H6>Request body</H6>
                {requestBody.required ? <strong>Required</strong> : ''} <em>{mediaType}</em>{' '}
                {content.schema && <Schema schemaOrRef={content.schema} document={document} />}
              </LeftColTight>
              <RightColTight span="12">
                <P size="small">Request example</P>
                <TabbedCodeContainer>
                  <TabPane tab="HTTP" key="1">
                    <CodeBlock language="http">
                      {formatExampleRequestRawHttp(document, endpoint, requestParameters, mediaType, content.example)}
                    </CodeBlock>
                  </TabPane>
                  <TabPane tab="cURL" key="2">
                    <CodeBlock language="bash">
                      {formatExampleRequestCurl(document, endpoint, requestParameters, mediaType, content.example)}
                    </CodeBlock>
                  </TabPane>
                </TabbedCodeContainer>
              </RightColTight>
            </Row>
          )
        })}
    </>
  )
}

export default Request
