import React, { FC } from 'react'
import { Col, Row } from 'antd'
import { H2, H3, P } from '../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Dashboard/Paths'

const Sandbox: FC = () => {
  return (
    <Row>
      <Col md={24} lg={24} xl={16}>
        <H2>Develop in sandbox</H2>
        <P size="small">
          Sandbox provides a safe way to test your solution before connecting it to KONE equipment or data.
        </P>
        <P size="small">
          Our sandbox is available for every registered KONE API portal user. Create a new application of type sandbox
          and you are ready to start integrating your solution with our APIs. You can find out what resources (buildings
          and equipment) sandbox can access by calling API{' '}
          <Link to={`${Paths.AuthenticationAPIV2.path}#listResources`}>
            <code>/api/v2/application/self/resources</code>
          </Link>{' '}
          with your sandbox application credentials. With the returned IDs you can then make next requests.
        </P>
        <H3>Sandbox for Elevator Call API and Service Robot API</H3>
        <P size="small">
          Sandbox for Elevator Call APIs and Service Robot API is a <b>virtual building environment</b>. Virtual
          building simulates how elevators operate in a real building with one or more elevators and multiple areas. You
          can make elevator calls to travel from one area to another while monitoring the elevator and door movement
          just like you would with real equipment.
        </P>
        <P size="small">
          The available virtual buildings represent buildings of 20 floors and 3 elevators. 2 floors are located
          underground in the parking area. All elevators have doors on two sides.
        </P>
        <P size="small">
          The virtual building processes Service Robot API requests the same way as a normal elevator call. For example,
          there is no difference in door closing times in the virtual building. Because of this, verifying the full
          operation of a robot solution is needed with a proper elevator simulator equipment. Access to the simulator
          can be requested separately and it’s available only for applications created in production environment.{' '}
        </P>
        <H3>Sandbox for Equipment Status API and Service Info API</H3>
        <P size="small">
          Equipment Status API and Service Info API return static data responses. The example data mimics real
          responses. e.g ken:211111112
        </P>
      </Col>
    </Row>
  )
}

export default Sandbox
