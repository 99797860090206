import { Col, Collapse, Row } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'
import { ReactComponent as IconExpand } from '../../../assets/icons-sort-chevron-down.svg'
import { ReactComponent as IconHide } from '../../../assets/icons-sort-chevron-up.svg'
import { P } from '../../../shared/components/Typography'
import { ComponentsStrings } from '../../../shared/strings/ApiDocumentationContent'
import useIsPrint from '../../../shared/utils/isPrint'
import { color } from '../../../shared/utils/styles'

const { Panel } = Collapse

const StyledDescription = styled(P)`
  margin: 0;
  font-size: 0.875rem;
`

const StyledName = styled(P)`
  color: ${color.gray10};
  text-align: end;
  margin: 0 1vw 6px 0;
  overflow-wrap: anywhere;
  hyphens: manual;
  hyphenate-character: '\u21B5';
`

const StyledRequired = styled(P)`
  letter-spacing: 0px;
  color: ${color.apiRed};
  text-align: end;
  margin: 0 1vw 6px 0;
`

const StyledType = styled(P)`
  letter-spacing: 0px;
  color: ${color.apiOrange};
  margin: 0 0 6px 0;
`

const StyledEnumContainer = styled(P)`
  letter-spacing: 0px;
  color: ${color.gray20};
  margin: 10px 0 0 0;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 0;
`

const StyledEnumTitle = styled.span`
  margin: 0 10px 0 0;
`

const StyledEnumItem = styled.span`
  color: ${color.apiGreen};
  margin: 0 10px 0 0;
`

const StyledFormatTitle = styled(P)`
  margin: 0px 10px 0 0;
`

const StyledFormatText = styled(P)`
  color: ${color.apiGreen};
  margin: 0;
`

export const StyledLine = styled.hr`
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid ${color.gray70};
  opacity: 0.4;
  width: 100%;
`

const StyledCollapse = styled(Collapse)`
  background: none;
  border: none;

  .ant-collapse-header {
    color: ${color.blueTint20} !important;
    padding-left: 24px !important;

    .ant-collapse-arrow {
      left: 0px !important;
      top: 11px !important;
    }
  }

  .ant-collapse-content {
    background: none;
    border: none;
  }

  .ant-collapse-content-box {
    padding: 0px;
    color: ${color.gray20};
  }

  .ant-collapse-item {
    border: none;
  }
`

const StyledPanel = styled(Panel)`
  padding-left: 0px !important;
  && .ant-collapse-header svg {
    padding: 0 !important;
    margin: 6px 10px 0 -5px;
  }
`

const StyledStringEnumDescriptionRow = styled(Row)`
  margin-bottom: 16px;
`

const ExpandedDescription = styled(P)`
  margin: 0;
  padding-left: 10px;
`

const StyledStringEnumDescriptionValue = styled(Col)`
  overflow-wrap: break-word;
  && p {
    color: #64a340;
  }
`

interface StringEnumDescription {
  value: string
  description: string
}

interface PayloadPropertyProps {
  name: string
  type: string
  required?: boolean
  description?: any
  stringEnums?: string[]
  stringEnumDescriptions?: StringEnumDescription[]
  format?: string
  example?: string
  comment?: string
  attach?: any
}

function hyphenateCamelAndSnakeCase(text: string): string {
  let output = ''
  let previousWasUpperCase = true
  for (const char of text) {
    const isUpperCase = ('A' <= char && char <= 'Z') || char === '_'
    if (isUpperCase && !previousWasUpperCase) {
      output += '\u00AD' // shy-hyphen character that is only visible when line wrap occurs at this position
    }
    previousWasUpperCase = isUpperCase
    output += char
  }
  return output
}

export const PayloadProperty: FC<PayloadPropertyProps> = ({
  name,
  type,
  required,
  description,
  stringEnums,
  stringEnumDescriptions,
  format,
  example,
  comment,
  attach,
}) => {
  const isPrint = useIsPrint()

  const hyphenatedName = hyphenateCamelAndSnakeCase(name)
  return (
    <Row>
      <Col span={6}>
        <StyledName size="small">{hyphenatedName}</StyledName>
        {required && <StyledRequired size="small">{ComponentsStrings.payloadProperty.required}</StyledRequired>}
      </Col>
      <Col span={18}>
        <StyledType size="small">{type}</StyledType>

        <StyledDescription size="small">{description}</StyledDescription>

        {format && (
          <StyledEnumContainer>
            <StyledFormatTitle size="small">{ComponentsStrings.payloadProperty.format}</StyledFormatTitle>
            <StyledFormatText size="small">{format}</StyledFormatText>
          </StyledEnumContainer>
        )}
        {example && (
          <StyledEnumContainer>
            <StyledFormatTitle size="small">{ComponentsStrings.payloadProperty.example}</StyledFormatTitle>
            <StyledFormatText size="small">{example}</StyledFormatText>
          </StyledEnumContainer>
        )}
        <StyledDescription size="small">{attach}</StyledDescription>
        {comment && (
          <StyledEnumContainer>
            <StyledFormatTitle size="small">{ComponentsStrings.payloadProperty.comment}</StyledFormatTitle>
            <StyledFormatText size="small">{comment}</StyledFormatText>
          </StyledEnumContainer>
        )}

        {stringEnums && (
          <StyledEnumContainer size="small">
            <StyledEnumTitle>{ComponentsStrings.payloadProperty.enum}</StyledEnumTitle>
            {stringEnums?.map((enumString, index) => (
              <StyledEnumItem key={`${enumString}-${index}`}>&quot;{enumString}&quot;</StyledEnumItem>
            ))}
          </StyledEnumContainer>
        )}

        {stringEnumDescriptions && (
          <StyledCollapse
            defaultActiveKey={isPrint ? '1' : ''}
            expandIcon={({ isActive }) => (isActive ? <IconHide /> : <IconExpand />)}
          >
            <StyledPanel header="Possible values and their meanings" key={'1'}>
              {stringEnumDescriptions?.map((descriptionObject, index) => (
                <StyledStringEnumDescriptionRow key={`collapsible-${descriptionObject.value}-${index}`}>
                  <StyledStringEnumDescriptionValue span="5">
                    <StyledDescription size="small">{descriptionObject.value}</StyledDescription>
                  </StyledStringEnumDescriptionValue>
                  <Col span="19">
                    <ExpandedDescription size="small">{descriptionObject.description}</ExpandedDescription>
                  </Col>
                </StyledStringEnumDescriptionRow>
              ))}
            </StyledPanel>
          </StyledCollapse>
        )}
      </Col>
      <StyledLine />
    </Row>
  )
}
