import styled from 'styled-components'
import { color } from '../../../../shared/utils/styles'
import Button from '../../../../shared/components/Button'
import { ReactComponent as OkCheckIcon } from '../../../../assets/ok-check-icon.svg'

import { Input } from 'antd'

export const MainContainer = styled.div`
  display: flex;
  justify-content: left;
`

export const AppName = styled.p`
  color: ${color.gray20};
  font: normal normal normal 28px/42px KONE Information;
  margin-bottom: 25px;
`

export const ApiName = styled.p`
  color: ${color.gray20};
  font: normal normal normal 24px/30px KONE Information;
  margin-bottom: 25px;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`
export const DisabledChipContainer = styled.div`
  flex-direction: column;
  pointer-events: none;
  width: 100%;
  padding: 20px;
  border: 0.5px solid ${color.gray40};
  border-radius: 6px;
  background-color: ${color.gray70};
`

export const ChipContainer = styled.div`
  flex-direction: column;
  width: 100%;
  display: 'block';
  padding: 20px;
  border: 1.5px solid ${color.blueBase};
  background-color: ${color.white};
`

export const FormElement = styled.div`
  margin-bottom: 3em;
  width: 80%;
`
export const FormURLElement = styled.div`
  margin-bottom: 3em;
  pointer-events: none;
  width: 80%;
`
export const FormSecretElement = styled.div`
  margin-bottom: 3em;
  width: 70%;
  pointer-events: none;
`

export const ClientInput = styled(Input)`
  cursor: default;
  width: 75%;
`

export const FormLabel = styled.label`
  margin-bottom: 8px;
  display: block;
  color: ${color.gray20};
`

export const ButtonsContainerContact = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
`

export const InputName = styled(Input)`
  color: ${color.gray75};
  width: 100%;
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 34px;
  margin-bottom: 34px;
  justify-content: center;
`

export const SaveButton = styled(Button)`
  width: 208px;
  padding: 3px;
`

export const SwitchContainer = styled.div`
  display: flex;
  margin-top: 10px;
`

export const SwitchLabel = styled.label`
  margin-bottom: 8px;
  display: block;
  color: ${color.gray20};
`

export const SwitchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 65px;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  opacity: 0.4;
  width: 100%;
`

export const FormCheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const FormCheckbox = styled.input`
  margin-left: 4px;
  margin-right: 8px;
`

export const CheckboxLabel = styled.label`
  color: ${color.gray20};
  line-height: 1;
`

export const DeleteContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: 20px;
  padding: 5px;

  &:hover {
    span {
      color: ${color.blueTint20};
    }

    svg .a {
      fill: ${color.blueTint20};
    }
  }
`
export const DeleteButton = styled.span`
  font-size: 16px;
  padding-right: 5px;
  text-transform: uppercase;
  color: ${color.blueBase};
`

export const CenterLoader = styled.div`
  display: flex;
  justify-content: center;
`

export const EditWrapper = styled.div`
  width: 350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const SuccessContainer = styled.div`
  text-align: center;
`

export const OkIcon = styled(OkCheckIcon)`
  margin: 10px 0 45px 0;
`

export const SuccessMessage = styled.p`
  font-size: 28px;
`
