import styled from 'styled-components'
import { ReactComponent as OrangeFlag } from '../../../assets/orange-flag.svg'
import { ReactComponent as YellowFlag } from '../../../assets/yellow-flag.svg'
import { ReactComponent as RedFlag } from '../../../assets/red-flag.svg'

export const ResourceFlagOrange = styled(OrangeFlag)`
  margin-left: 5px;
`
export const ResourceFlagYellow = styled(YellowFlag)`
  margin-left: 5px;
`
export const ResourceFlagRed = styled(RedFlag)`
  margin-left: 5px;
`
