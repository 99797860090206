import React, { FC, MouseEvent, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { useForm, Controller } from 'react-hook-form'
import { useAuth } from '../../../../shared/contexts/authContext'
import { LoginData, ConfirmationData } from '../../../../shared/models/auth'
import { AuthFormLabel, Text3, ErrorMessage, Toast } from '../../../../shared/components'
import { REQUIRED_FIELD } from '../../../../shared/constants/auth'

import {
  StyledForm,
  DescriptionContainer,
  VerificationCodeContainer,
  ButtonsContainer,
  ResendButton,
  VerifyButton,
  props,
} from './Styles'
import { ConfirmationFormStrings } from '../../../../shared/strings/AuthFormContent'

type Props = {
  loginData: LoginData
}

const AuthFormsConfirmationForm: FC<Props> = ({ loginData }) => {
  const { signIn, confirmSignUp, resendSignUp, setConsent, isConsent, isVersion } = useAuth()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isCodeSending, setIsCodeSending] = useState(false)
  const [resendCodeError, setResendCodeError] = useState<Error | undefined>()
  const [isResendCodeErrorVisible, setIsResendCodeErrorVisible] = useState(false)
  const [submitError, setSubmitError] = useState<Error | undefined>()
  const [isSubmitErrorVisible, setIsSubmitErrorVisible] = useState(false)

  const hideToastNotifications = () => {
    setIsResendCodeErrorVisible(false)
    setIsResendCodeErrorVisible(false)
  }

  async function onSubmit(data: ConfirmationData) {
    hideToastNotifications()
    setIsLoading(true)

    try {
      await confirmSignUp(loginData.email, data.confirmationCode)
      await signIn(loginData.email, loginData.password)
      await setConsent(isConsent(), isVersion())
    } catch (e) {
      console.error(e)
      setSubmitError(e)
      setIsSubmitErrorVisible(true)
      setIsLoading(false)
    }
  }

  async function onResendCode(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    hideToastNotifications()
    setIsCodeSending(true)

    try {
      await resendSignUp(loginData.email)
    } catch (e) {
      console.error(e)
      setResendCodeError(e)
      setIsResendCodeErrorVisible(true)
    } finally {
      setIsCodeSending(false)
    }
  }
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Toast
        key="submit-error"
        isVisible={isSubmitErrorVisible}
        text={submitError?.message}
        onDismiss={() => setIsSubmitErrorVisible(false)}
        type="error"
      />
      <Toast
        key="resend-code-error"
        isVisible={isResendCodeErrorVisible}
        text={resendCodeError?.message}
        onDismiss={() => setIsResendCodeErrorVisible(false)}
        type="error"
      />
      <DescriptionContainer>
        <Text3>{ConfirmationFormStrings.activationCodeSent}</Text3>
        <Text3>{loginData.email}</Text3>
      </DescriptionContainer>
      <VerificationCodeContainer>
        <AuthFormLabel htmlFor="confirmation-code-input-js">
          {ConfirmationFormStrings.enterActivationCode}
        </AuthFormLabel>
        <Controller
          render={({ field: { onChange, value, name } }) => (
            <ReactCodeInput
              name={name}
              value={value}
              onChange={onChange}
              inputMode="numeric"
              type="number"
              fields={6}
              inputStyle={props}
            />
          )}
          name="confirmationCode"
          control={control}
          defaultValue=""
          rules={{ required: true }}
        />
        {errors.confirmationCode && <ErrorMessage id="confirmation-code-input-error-js">{REQUIRED_FIELD}</ErrorMessage>}
      </VerificationCodeContainer>
      <ButtonsContainer>
        <ResendButton id="resend-button-js" loading={isCodeSending} ghost onClick={onResendCode}>
          {ConfirmationFormStrings.resendCode}
        </ResendButton>
        <VerifyButton id="confirm-button-js" loading={isLoading} type="primary" htmlType="submit">
          {ConfirmationFormStrings.verify}
        </VerifyButton>
      </ButtonsContainer>
    </StyledForm>
  )
}

export default AuthFormsConfirmationForm
