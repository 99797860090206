import styled from 'styled-components'
import { size } from '../../utils/styles'

const SectionTitle = styled.div`
  text-align: center;
  font: normal normal normal 24px/30px KONE Information;
  margin-top: 46px;

  @media (min-width: ${size.large}) {
    margin-top: 74px;
    margin-bottom: 14px;
    font: normal normal normal 48px/72px KONE Information;
  }
`

export default SectionTitle
