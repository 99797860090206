import styled from 'styled-components'
import { Checkbox } from 'antd'
import Button from '../../../../shared/components/Button'
import { color } from '../../../../shared/utils/styles'

export const Container = styled.div`
  width: 420px;
  margin: 0 auto;
`

export const ProductListContainer = styled.div`
  width: 350px;
  margin: 0 auto;
`

export const Title = styled.div`
  font: normal normal normal 18px/24px KONE Information;
  margin-bottom: 12px;
`

export const ProductCheckbox = styled(Checkbox)`
  font: normal normal normal 16px/18px KONE Information;
  color: ${color.gray20};
  margin-bottom: 4px;
`

export const ProductDescription = styled.div`
  margin-bottom: 22px;
  margin-left: 25px;
  font: normal normal normal 14px/18px KONE Information;
`

export const ButtonsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
`

export const CancelButton = styled(Button)`
  min-width: 144px;
  height: 40px;
`

export const SaveButton = styled(Button)`
  min-width: 195px;
  height: 40px;
`
