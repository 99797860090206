import { fromPairs } from 'lodash'
import awsConfig from './awsConfig'

export enum Feature {
  ShowEnvironmentName = 'ShowEnvironmentName',
  IsChina = 'IsChina',
  ServiceBreak = 'ServiceBreak',
  ShowServiceBreakBanner = 'ShowServiceBreakBanner',
  EquipmentStsApi2Full = 'EquipmentStsApi2Full',
}

let enabledFeatures: Feature[] = Object.keys(Feature)
  .filter((feature) => (awsConfig?.featureFlags ?? []).includes(feature))
  .map((feature) => Feature[feature])

export function isFeatureEnabled(feature: Feature): boolean {
  return enabledFeatures.includes(feature)
}

;(window as any).listFeatures = function () {
  return fromPairs(Object.keys(Feature).map((feature) => [feature, isFeatureEnabled(Feature[feature])]))
}
;(window as any).disableFeature = function (feature: Feature) {
  const copy = enabledFeatures.slice()
  const idx = copy.indexOf(feature)

  if (idx >= 0) {
    copy.splice(idx, 1)
  }

  enabledFeatures = copy
}
;(window as any).enableFeature = function (feature: Feature) {
  const copy = enabledFeatures.slice()
  const idx = copy.indexOf(feature)

  if (idx < 0) {
    copy.push(feature)
  }

  enabledFeatures = copy
}
