import { TitleWrapperProps } from '../components/TitleWrapper'

export const LandingPageHeroImage = {
  title: 'Improve People Flow<sup>®</sup> with KONE APIs',
  body: 'Welcome to the KONE API Portal where you can innovate and create smart building solutions',
}
export const LandingPageTitles = {
  exploreKoneApis: 'Explore KONE APIs',
  getInspired: 'Inspiration',
}
export const ExperienceAPIs = {
  title: 'Experience APIs',
  body: 'Enhance your building’s people flow experience by implementing elevator call-giving features in your application',
}
export const OperationalAPIs = {
  title: 'Operational APIs',
  body: 'Ensure cost-efficiency and operational excellence by integrating service operations data into your building management system',
}
export const GetInspiredCard1 = {
  title: 'Connected elevators boost accessibility for people who are blind or partially sighted',
}
export const GetInspiredCard2 = {
  title: `KONE and Yunji collaborate to provide service robots for the hospitality industry`,
}
export const GetInspiredCard3 = {
  title: 'Smarter cities with KONE API-powered ecosystem',
}
export const GetInspiredCard4 = {
  title: 'An ecosystem of partners',
}
export const SEOProps: TitleWrapperProps = {
  title: 'KONE API Portal - Improve People Flow® with KONE APIs',
  metaTags: [
    {
      name: 'description',
      content: 'Welcome to the KONE API Portal where you can innovate and create smart building solutions.',
    },
  ],
}
