import React from 'react'
import {
  SolutionDevelopment1Svg,
  SolutionDevelopment2Svg,
  SolutionDevelopment3Svg,
  SolutionDevelopment4Svg,
} from './Styles'

export const apiOverview = {
  tittle: 'APIs overview',
  imageBottomTittle: 'API',
  svgImage: <SolutionDevelopment1Svg />,
  content: [
    {
      component: '',
      description:
        'KONE provides API products with our authentication APIs which are REST APIs and conforms to the OpenID Connect standard.',
    },
    {
      component: '',
      description:
        'This pages goes through guidance on authentication scopes, which are essential to successful responses.',
    },
  ],
}

export const authenticationScope = {
  tittle: 'Authentication Scopes',
  imageBottomTittle: 'KONE',
  svgImage: <SolutionDevelopment2Svg />,
  content: [
    {
      component: '',
      description:
        'Client application’s access scope should always be limited to what is required for it to function. The scope consist of API product identifier and resource identifier, ' +
        'separated by a slash character.',
    },
    {
      component: '',
      description:
        'The format to utilize is {product}/{resource}, where product is the API product identifier and resource is the hierarchical resource identifier' +
        ' (Note: it is no longer recommended to use wide card).',
    },
    {
      component: '',
      description:
        'The following scopes should be included in the body of the token generation request in order to retrieve an access token with the correct authentication scopes',
    },
    {
      component: '',
      description: (
        <>
          <li>
            {`callgiving/group:{buildingId}:{groupId}, scope to be used to gain access to the Elevator Call API and to a
            specific building.`}
          </li>
          <li>
            {`robotcall/group:{buildingId}:{groupId}, scope to be used to gain access to the Service Robot API and to a specific building.`}
          </li>
          <li>
            {`rtm/ken:{equipmentId}, scope to be used to gain access to the Equipment Status API and to a specific KONE equipment.`}
          </li>
          <li>
            {`serviceinfo/ken:{equipmentId}, scope to be used to gain access to the Service Info API and to a specific KONE equipment.`}
          </li>
          <li>
            {`application/inventory, allows the user to retrieve the list of resources accessible by the application. 
            This specific scope can also be obtained by calling the token generation endpoint without a scope.`}
          </li>
        </>
      ),
    },
    {
      component: '',
      description:
        'To receive the correct Elevator Call authentication scope for building 4TFxWRCv23D, you need to generate the token with the following scope in the body of the request:',
    },
    {
      component: '',
      description: (
        <>
          <li>
            callgiving/group:4TFxWRCv23D:1, This specific case provides access to the config (building layout) of
            building 4TFxWRCv23D and to open a WebSocket to make all type elevator calls.
          </li>
        </>
      ),
    },
    {
      component: '',
      description: `It’s possible to also monitor the state of the made call, allocated lift deck, allocated lift deck's doors and information about the estimated arrival.`,
    },
    {
      component: '',
      description:
        'To receive a token with multiple scopes and thus access multiple APIs or buildings you should make a token request with multiple space separated scopes.',
    },
    {
      component: '',
      description: (
        <>
          <li>
            callgiving/group:4TFxWRCv23D:1 callgiving/group:fWlfHyPlaca:1 callgiving/group:HxKjGc3knnh:1
            callgiving/group:L1QinntdEOg:1
          </li>
          <li>
            rtm/ken:211111112 rtm/ken:3111111113 rtm/ken:411111114 rtm/ken:511111115 serviceinfo/ken:211111112
            serviceinfo/ken:3111111113
          </li>
        </>
      ),
    },
  ],
}

export const developInSandBox = {
  tittle: 'Develop in sandbox',
  svgImage: <SolutionDevelopment3Svg />,
  content: [
    {
      component: '',
      description:
        'Sandbox provides a safe way to develop your solution before connecting it to KONE preproduction, pilot or site equipment or data. Our sandbox is available for every registered KONE API portal user.',
    },
    {
      component: '',
      description:
        'Create a new application of type SANDBOX and you are ready to start integrating your solution with our APIs. You can find out what resources (buildings and equipment) sandbox can access by calling API /resources endpoint with your sandbox application credentials. With the returned IDs you can then make next requests.',
    },

    {
      component: '',
      description: <b>Sandbox for Elevator Call API and Service Robot API</b>,
    },
    {
      component: '',
      description:
        'Sandbox for Elevator Call APIs and Service Robot API is a virtual building environment. Virtual building simulates how elevators operate in a real building with one or more elevators and multiple areas. You can make elevator calls to travel from one area to another while monitoring the elevator and door movement just like you would with real equipment.',
    },
    {
      component: '',
      description: <b>Sandbox for Equipment Status API and Service Info API</b>,
    },
    {
      component: '',
      description:
        'Equipment Status API and Service Info API return static data responses. The example data mimics real responses',
    },
  ],
}

export const apiVersioning = {
  tittle: 'API Versioning',
  svgImage: <SolutionDevelopment4Svg />,
  content: [
    {
      component: '',
      description:
        'We use versioning with all KONE APIs to identify API versions. With most KONE APIs, the version that should be used is indicated in the path segment as v1 is in this example POST /api/v1/oauth2/token With Elevator WebSocket API, ' +
        'the version is part of the first path segment like this: /stream-v1',
    },
    {
      component: '',
      description:
        'The version identifier indicates the major version of the API. The major versions of the APIs may evolve with changes that are backwards compatible. If there are changes that are not backwards compatible, this results in a new major version of the API.',
    },
    {
      component: '',
      description:
        'For example, if the major version v1 requires a change that is not backwards compatible, we would publish a new major version, v2. Both versions will work simultaneously, until the scheduled deprecation of the previous version v1.',
    },
    {
      component: '',
      description: 'Backwards compatibility',
    },
    {
      component: '',
      description:
        'The list below contains the changes that either don’t break or break the backwards compatibility. ' +
        'Make sure your application continues to work after non-breaking changes.',
    },
    {
      component: '',
      description: 'Non-breaking changes:',
    },
    {
      component: '',
      description: (
        <>
          <li>Addition of a new REST API endpoint.</li>
          <li>Addition of a new WebSocket request message type.</li>
          <li>Addition of a new incoming WebSocket event message type.</li>
          <li>Addition of a new field in a REST API response.</li>
          <li>Addition of a new field in an incoming WebSocket event.</li>
          <li>Addition of a new non-required field or query parameter in a REST API request.</li>
          <li>Addition of a new non-required field in a WebSocket request.</li>
          <li>Addition of a new enum value.</li>
        </>
      ),
    },
    {
      component: '',
      description: 'Breaking changes:',
    },
    {
      component: '',
      description: (
        <>
          <li>
            Removal or renaming of an existing REST API endpoint, WebSocket message, field, query parameter, or enum
            value.
          </li>
          <li>Changing an optional field to required.</li>
          <li>Changing the type of a field.</li>
          <li>Changing the visible behavior or the semantics of the API.</li>
        </>
      ),
    },
  ],
}
