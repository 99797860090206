import React, { FC, ChangeEvent, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSpring, animated } from 'react-spring'
import { Form, Input } from 'antd'
import AuthFormsConfirmationForm from '../ConfirmationForm'
import { LoginData, SignUpData } from '../../../../shared/models/auth'
import {
  EMAIL_REG_EXP,
  PASSWORD_DESCRIPTION,
  PASSWORD_REG_EXP,
  REQUIRED_FIELD,
} from '../../../../shared/constants/auth'
import { useAuth } from '../../../../shared/contexts/authContext'
import { AuthFormLabel, Button, Text3, ErrorMessage, Toast } from '../../../../shared/components'
import { Feature, isFeatureEnabled } from '../../../../shared/utils/featureFlags'
import {
  RegisterForm,
  DescriptionContainer,
  RegisterButton,
  CheckboxLabel,
  OpenModalLink,
  FormCheckboxesContainer,
  FormCheckboxContainer,
  FormCheckbox,
  ModalCheckbox,
  MobileModalGlobalStyle,
  StyledModal,
  FooterContainer,
  CheckboxErrorMessage,
  PrivacyStatementInfo,
} from './Styles'
import TermsAndConditionsContent from '../../../../shared/content/TermsAndConditionsContent'
import PrivacyStatementContent from '../../../../shared/content/PrivacyStatementContent'
import PrivacyStatementContentChina from '../../../../shared/content/PrivacyStatementContentChina'
import { ErrorStrings, RegisterFormStrings } from '../../../../shared/strings/AuthFormContent'
import { PrivacyStatementStrings } from '../../../../shared/strings/PrivacyStatementContent'
import { GeneralFormStrings } from '../../../../shared/strings/GeneralFormContent'
import { EmailInput } from '../Styles'

const PRIVACY_FIELD_NAME = 'isPrivacyChecked'
const TERMS_FIELD_NAME = 'isTermsChecked'

const AuthFormsRegisterForm: FC = () => {
  const { signUp, getConsent } = useAuth()
  const [loginData, setLoginData] = useState<LoginData | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isTermsChecked, setIsTermsChecked] = useState(false)
  const [isConsentChecked, setIsConsentChecked] = useState(false)
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false)
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false)
  const [isPrivacyModalVisible, setIsPrivacyModalVisible] = useState(false)
  const [signUpError, setSignUpError] = useState<Error | undefined>()
  const [isSignUpErrorVisible, setIsSignUpErrorVisible] = useState(false)

  const animationProps = useSpring({ config: { duration: 500 }, opacity: 1, from: { opacity: 0 } })

  const {
    handleSubmit,
    control,
    watch,
    register,
    trigger,
    setValue,
    formState: { isSubmitted, errors },
  } = useForm()

  const showTermsModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    setIsTermsModalVisible(true)
  }

  const showPrivacyModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    setIsPrivacyModalVisible(true)
  }

  const hideTermsModal = () => {
    setIsTermsModalVisible(false)
  }

  const hidePrivacyModal = () => {
    setIsPrivacyModalVisible(false)
  }

  const toggleTermsAndConditions = () => {
    setValue(TERMS_FIELD_NAME, !isTermsChecked, { shouldValidate: true })
    setIsTermsChecked(!isTermsChecked)
  }

  const toggleGdprConsent = () => {
    setIsConsentChecked(!isConsentChecked)
    getConsent(!isConsentChecked, 'v1') // v1 is passed here
    setIsConsentChecked(!isConsentChecked)
  }

  const togglePrivacyStatement = () => {
    setValue(PRIVACY_FIELD_NAME, !isPrivacyChecked, { shouldValidate: true })
    setIsPrivacyChecked(!isPrivacyChecked)
  }

  async function onSubmit(data: SignUpData) {
    setIsSignUpErrorVisible(false)
    setIsLoading(true)

    try {
      await signUp(data.email, data.password, data.firstName, data.lastName)
      setIsLoading(false)
      setLoginData({
        email: data.email.toLowerCase(),
        password: data.password,
      })
    } catch (e) {
      console.error(e)
      if (e.code === 'UsernameExistsException') {
        e.message =
          e.message + ' Please try to login with your password. If that does not help contact api-support@kone.com'
      }
      setSignUpError(e)
      setIsSignUpErrorVisible(true)
      setIsLoading(false)
    }
  }

  const privacyModalFooter = isFeatureEnabled(Feature.IsChina) ? (
    <FooterContainer key="privacy-footer">
      <ModalCheckbox
        id="accept-privacy-statement-checkbox-js"
        checked={isPrivacyChecked}
        onClick={togglePrivacyStatement}
      >
        {RegisterFormStrings.understandPrivacyStatement}
      </ModalCheckbox>
      <Button
        id="close-privacy-statement-modal-js"
        disabled={!isPrivacyChecked}
        type="primary"
        onClick={hidePrivacyModal}
      >
        {RegisterFormStrings.acceptAndContinue}
      </Button>
    </FooterContainer>
  ) : (
    <Button id="close-privacy-statement-modal-js" type="primary" onClick={hidePrivacyModal}>
      {RegisterFormStrings.close}
    </Button>
  )

  const triggerPasswordConfirmationValidation = () => {
    if (isSubmitted) {
      trigger('passwordConfirmation')
    }
  }

  return (
    <animated.div style={animationProps}>
      <Toast
        isVisible={isSignUpErrorVisible}
        text={signUpError?.message}
        onDismiss={() => setIsSignUpErrorVisible(false)}
        type="error"
      />
      {!loginData ? (
        <RegisterForm onSubmit={handleSubmit(onSubmit)}>
          <DescriptionContainer>
            <Text3>{RegisterFormStrings.fillAllFieldsCheckEmail}</Text3>
          </DescriptionContainer>
          <Form.Item validateStatus={errors.firstName ? 'error' : ''}>
            <AuthFormLabel htmlFor="first-name-input-js">{GeneralFormStrings.firstName}</AuthFormLabel>
            <Controller
              render={({ field }) => <Input id="first-name-input-js" {...field} />}
              name="firstName"
              control={control}
              defaultValue=""
              rules={{ required: true }}
            />
            {errors.firstName && <ErrorMessage id="first-name-input-error-js">{REQUIRED_FIELD}</ErrorMessage>}
          </Form.Item>
          <Form.Item validateStatus={errors.lastName ? 'error' : ''}>
            <AuthFormLabel htmlFor="last-name-input-js">{GeneralFormStrings.lastName}</AuthFormLabel>
            <Controller
              render={({ field }) => <Input id="last-name-input-js" {...field} />}
              name="lastName"
              control={control}
              defaultValue=""
              rules={{ required: true }}
            />
            {errors.lastName && <ErrorMessage id="last-name-input-error-js">{REQUIRED_FIELD}</ErrorMessage>}
          </Form.Item>
          <Form.Item validateStatus={errors.email ? 'error' : ''}>
            <AuthFormLabel htmlFor="email-input-js">{GeneralFormStrings.email}</AuthFormLabel>
            <Controller
              render={({ field }) => <EmailInput id="email-input-js" {...field} />}
              name="email"
              control={control}
              defaultValue=""
              rules={{
                pattern: {
                  value: EMAIL_REG_EXP,
                  message: ErrorStrings.invalidEmailError,
                },
                required: true,
              }}
            />
            {errors.email && (
              <ErrorMessage id="email-input-error-js">{errors.email.message || REQUIRED_FIELD}</ErrorMessage>
            )}
          </Form.Item>
          <Form.Item validateStatus={errors.password ? 'error' : ''}>
            <AuthFormLabel htmlFor="password-input-js">{GeneralFormStrings.password}</AuthFormLabel>
            <Controller
              render={({ field }) => (
                <Input.Password
                  id="password-input-js"
                  {...field}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e)
                    triggerPasswordConfirmationValidation()
                  }}
                  autoComplete="off"
                  type="password"
                />
              )}
              name="password"
              control={control}
              defaultValue=""
              rules={{
                pattern: {
                  value: PASSWORD_REG_EXP,
                  message: PASSWORD_DESCRIPTION,
                },
                required: true,
              }}
            />
            {errors.password && (
              <ErrorMessage id="password-input-error-js">{errors.password.message || REQUIRED_FIELD}</ErrorMessage>
            )}
          </Form.Item>
          <Form.Item validateStatus={errors.passwordConfirmation ? 'error' : ''}>
            <AuthFormLabel htmlFor="password-confirmation-input-js">
              {RegisterFormStrings.confirmPassword}
            </AuthFormLabel>
            <Controller
              render={({ field }) => (
                <Input.Password id="password-confirmation-input-js" {...field} autoComplete="off" type="password" />
              )}
              name="passwordConfirmation"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                validate: (value) => value === watch('password') || ErrorStrings.passwordsDoNotMatchError,
              }}
            />
            {errors.passwordConfirmation && (
              <ErrorMessage id="password-confirmation-input-error-js">
                {errors.passwordConfirmation.message || REQUIRED_FIELD}
              </ErrorMessage>
            )}
          </Form.Item>
          <FormCheckboxesContainer>
            <FormCheckboxContainer>
              {
                <FormCheckbox
                  id="terms-conditions-checkbox-js"
                  {...register(TERMS_FIELD_NAME, { required: true })}
                  readOnly
                  onClick={toggleTermsAndConditions}
                  type="checkbox"
                  defaultChecked={false}
                />
              }
              <CheckboxLabel htmlFor="terms-conditions-checkbox-js">
                {RegisterFormStrings.understandAndAgree} &nbsp;
                <OpenModalLink id="open-terms-conditions-modal-js" onClick={showTermsModal}>
                  {RegisterFormStrings.termsConditions}
                </OpenModalLink>
              </CheckboxLabel>
            </FormCheckboxContainer>
            {errors[TERMS_FIELD_NAME] && !isTermsChecked && (
              <CheckboxErrorMessage>{REQUIRED_FIELD}</CheckboxErrorMessage>
            )}
            <FormCheckboxContainer>
              {
                <FormCheckbox
                  id="gdpr-consent-checkbox-js"
                  readOnly
                  onClick={toggleGdprConsent}
                  type="checkbox"
                  defaultChecked={false}
                />
              }
              <CheckboxLabel htmlFor="gdpr-consent-checkbox-js">
                I consent to subscribe to newsletters, surveys and information about KONE API products and services.
                &nbsp;
              </CheckboxLabel>
            </FormCheckboxContainer>
            <CheckboxLabel style={{ marginTop: '-8px', marginLeft: '25px', fontSize: '16px' }}>
              {' '}
              Please note that you can unsubscribe or modify your preferences at any time under your profile.
            </CheckboxLabel>
            {isFeatureEnabled(Feature.IsChina) && (
              <>
                <FormCheckboxContainer>
                  <FormCheckbox
                    id="privacy-statement-checkbox-js"
                    {...register(PRIVACY_FIELD_NAME, { required: true })}
                    readOnly
                    onClick={togglePrivacyStatement}
                    type="checkbox"
                    defaultChecked={false}
                  />
                  <CheckboxLabel htmlFor="privacy-statement-checkbox-js">
                    {RegisterFormStrings.understandAndAgree} &nbsp;
                    <OpenModalLink id="open-privacy-statement-modal-js" onClick={showPrivacyModal}>
                      {PrivacyStatementStrings.privacyStatement}
                    </OpenModalLink>
                  </CheckboxLabel>
                </FormCheckboxContainer>
                {errors[PRIVACY_FIELD_NAME] && !isPrivacyChecked && (
                  <CheckboxErrorMessage>{REQUIRED_FIELD}</CheckboxErrorMessage>
                )}
              </>
            )}
          </FormCheckboxesContainer>
          <RegisterButton id="sign-up-button-js" type="primary" loading={isLoading} htmlType="submit">
            {GeneralFormStrings.registerButton}
          </RegisterButton>
          {!isFeatureEnabled(Feature.IsChina) && (
            <PrivacyStatementInfo>
              {PrivacyStatementStrings.pleaseSeePrivacyStatement} &nbsp;
              <OpenModalLink id="open-privacy-statement-modal-js" onClick={showPrivacyModal}>
                {PrivacyStatementStrings.privacyStatement}
              </OpenModalLink>
            </PrivacyStatementInfo>
          )}
          <MobileModalGlobalStyle />
          <StyledModal
            key="terms-and-conditions-modal"
            width={760}
            title={'Terms & Conditions'}
            visible={isTermsModalVisible}
            onOk={hideTermsModal}
            bodyStyle={{ height: '65vh', overflowY: 'scroll' }}
            onCancel={hideTermsModal}
            footer={[
              <FooterContainer key="terms-and-conditions-footer">
                <ModalCheckbox
                  id="accept-terms-conditions-checkbox-js"
                  checked={isTermsChecked}
                  onClick={toggleTermsAndConditions}
                >
                  {RegisterFormStrings.understandAndAgreeTermsConditions}
                </ModalCheckbox>
                <Button
                  id="close-terms-conditions-modal-js"
                  disabled={!isTermsChecked}
                  type="primary"
                  onClick={hideTermsModal}
                >
                  {RegisterFormStrings.acceptAndContinueButton}
                </Button>
              </FooterContainer>,
            ]}
          >
            <TermsAndConditionsContent isModal={true} />
          </StyledModal>
          <StyledModal
            key="privacy-statement-modal"
            width={760}
            title="Privacy statement"
            visible={isPrivacyModalVisible}
            onOk={hidePrivacyModal}
            bodyStyle={{ height: '65vh', overflowY: 'scroll' }}
            onCancel={hidePrivacyModal}
            footer={[privacyModalFooter]}
          >
            {isFeatureEnabled(Feature.IsChina) ? (
              <PrivacyStatementContentChina isModal={true} />
            ) : (
              <PrivacyStatementContent isModal={true} />
            )}
          </StyledModal>
        </RegisterForm>
      ) : (
        <AuthFormsConfirmationForm loginData={loginData} />
      )}
    </animated.div>
  )
}

export default AuthFormsRegisterForm
