import styled from 'styled-components'
import { H3, H6 } from '../../../../shared/components/Typography'
import { color } from '../../../../shared/utils/styles'

const Pill = styled.span`
  display: inline-block;
  color: ${color.white};
  padding: 0 0.25em;
  margin-right: 0.25em;
  border-radius: 10px;
`

export const HTTPVerb = styled(Pill)`
  background-color: ${color.green};
`

export const HTTPStatusCode = styled(Pill)`
  background-color: ${(props) => statusCodeToColor(props.children)};
  cursor: pointer;
  color: ${(props) => (statusCodeToColor(props.children) === color.orange ? color.darkGray : color.white)};
  width: 2.5em;
  text-align: center;
`
export const SchemaOrSubSchemaContainer = styled.div<{ depth: number }>`
  background-color: rgba(255, 255, 255, 0.025);
  padding: ${(props) => (props.depth ? '0.5em 0.7em' : '')};
  margin-top: ${(props) => (props.depth ? '-10px' : '')};
  background-color: ${(props) => `rgba(255, 255, 255, ${0 + props.depth * 0.025})`};

  && p {
    margin-top: 0;
  }
`
export const ExpandResponseIconContainer = styled.div`
  float: right;
  vertical-align: middle;
  cursor: pointer;
`

export const EndpointTitle = styled(H3)`
  word-wrap: break-word;
`

export const ResponseTitle = styled(H6)`
  border-radius: 10px 4px 4px 10px;
  padding: 2px 10px 2px 0;
  cursor: pointer;
  transition: 0.5s ease-in;
  && :hover {
    background: ${color.gray85Opacity};
    transition: 0.25s ease-out;
  }
`

export const ExpandSchemaIconContainer = styled.div`
  cursor: pointer;
  padding: 4px 7px;
  position: absolute;
  right: 5%;
  margin-left: 10px;
  z-index: 10;
`

function statusCodeToColor(children: React.ReactNode | undefined) {
  if (!children) {
    throw new Error('Cannot map non-existing code to a color')
  }

  const statusCode = parseInt(children.toString(), 10)

  if (statusCode >= 200 && statusCode < 300) {
    return color.green
  } else if (statusCode >= 400 && statusCode < 500) {
    return color.orange
  } else if (statusCode >= 500) {
    return color.red
  } else {
    throw new Error(`Unmapped status code ${children?.toString()}`)
  }
}
