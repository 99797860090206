import React, { FC } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { Col, Row } from 'antd'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import Paths from '../../Paths'
import { H2, H3, H4, P, UL } from '../../../shared/components'
import { StyledCodeContainer, GettingStartedImage } from './Styles'

import GettingStarted from '../../../assets/getting-started.png'

const FirstAPICall: FC = () => {
  return (
    <Row>
      <Col md={24} lg={24} xl={16}>
        <H2>Get started with APIs</H2>
        <P size="small">Create a sandbox application, generate access token and start making API calls!</P>
        <UL size="small">
          <li>
            Go to <Link to={Paths.Dashboard.path}>Dashboard tab</Link>
          </li>
          <li>Click on &quot;Create application&quot;</li>
          <li>Name your application and add description</li>
          <li>Click on &quot;Create application&quot;</li>
          <li>Get your client ID and client secret – remember to store your client secret somewhere safe</li>
        </UL>
        <P size="small">Sample</P>
        <GettingStartedImage src={GettingStarted} preview={false} />
        <P size="small">
          You have now created your application! Before generating your first access token,{' '}
          <b>make sure you have gone through the technical API documentation.</b>
        </P>
        <H3>Generate an access token</H3>
        <P size="small">
          Access token grants you the right to make API calls. API documentation gives you specific guidance on how to
          use each of our APIs. In these examples variables are marked with double brackets, make sure to replace those
          with real values.
        </P>
        <P size="small">
          For an access token, you need your client ID and client secret. They need to be included in the request
          authorization header as base64-encoded string. If needed, you can check your client ID and reset the client
          secret on the <Link to={Paths.Dashboard.path}>Dashboard tab</Link>
        </P>
        <P size="small">
          With the following OAuth2 client credentials request, you will gain access to <code>callgiving</code> and{' '}
          <code>equipmentstatus</code> operations to all resources the application can access. In addition the{' '}
          <code>application/inventory</code> allows programmatically listing of the available resources.
        </P>
        <StyledCodeContainer style={dracula}>
          {`curl --request POST https://${window.location.hostname}/api/v2/oauth2/token --user {{ client ID }}:{{ client secret }} --header`}
        </StyledCodeContainer>
        <P size="small">
          If you leave the scope empty you will automatically get the inventory rights. You can also request access to
          specific resources by specifying the resource identifier after the scope type instead of using <code>*</code>.
          More information about authentication APIs is in the{' '}
          <Link to={Paths.AuthenticationAPI.path}>API documentation</Link>.
        </P>
        <H3>Start making API calls</H3>
        <P size="small">
          With the token that was generated in the previous step you can list all the resources (buildings or equipment)
          your application can access. Building Ids start with <b>building</b> prefix and equipment Ids with <b>ken</b>{' '}
          prefix.
          <br />
          <li> Ex - ken:111111111 , building:Qt8lIGiut3t</li>
          <li> Building IDs are needed for Elevator call API & Service Robot API products.</li>
          <li> KEN numbers (Equipment IDs) are needed for Equipment status API or Service Info APIs.</li>
        </P>
        <StyledCodeContainer style={dracula}>
          {`
curl --request GET https://${window.location.hostname}/api/v2/application/self/resources --header
`}
        </StyledCodeContainer>

        <H4 id="scopes">Authentication scopes</H4>
        <P size="small">
          Client application’s access scope should always be limited to what is required for it to function. The scope
          consist of API product identifier and resource identifier, separated by a slash character. The format to
          utilize is {'{product}/{resource}'}, where product is the API product identifier and resource is the
          hierarchical resource identifier.
        </P>
        <P size="small">
          The following scopes should be included in the body of the token generation request{' '}
          <Link to={`${Paths.AuthenticationAPI.path}#getAccessToken`}>POST /api/v2/oauth2/token</Link> in order to
          retrieve an access token with the correct authentication scopes:
        </P>
        <UL size="small">
          <li>
            callgiving/{`group:{buildingId}:{groupId}`}, scope to be used to gain access to the Elevator Call API and to
            a specific building. e.g callgiving/group:9990000951:1
          </li>
          <li>
            robotcall/{`group:{buildingId}:{groupId}`}, scope to be used to gain access to the Service Robot API and to
            a specific building. e.g robotcall/group:4TFxWRCv23D:1
          </li>
          <P size="small">The summary steps will be</P>
          <UL size="small">
            <li>Building topology</li>
            <UL size="small">
              <li>Get Configuration</li>
              <li>Get Actions</li>
            </UL>
            <li>Making elevator call</li>
          </UL>
          <P size="small">
            Now you’re able to make an elevator call using the received building topology. Find a practical example of
            an elevator call <Link to={`${Paths.ElevatorWebsocketAPIV2.path}#usage-example`}>here</Link> or clone our
            fully working{' '}
            <a href="https://github.com/konecorp/kone-api-examples" target="_blank" rel="noreferrer">
              Elevator Call example project
            </a>{' '}
            from github.
          </P>
          <li>
            rtm/ken:{'{equipmentId}'}, scope to be used to gain access to the Equipment Status API and to a specific
            KONE equipment. e.g rtm/ken:211111112
          </li>
          <li>
            serviceinfo/ken:{'{equipmentId}'}, scope to be used to gain access to the Service Info API and to a specific
            KONE equipment. e.g serviceinfo/ken:3111111113
          </li>
        </UL>
      </Col>
    </Row>
  )
}

export default FirstAPICall
