import React, { FC, useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd'
import { H2, TabsContainer, Toast, SidePanel } from '../../shared/components'
import Profile from './Profile'
import Invitations from './Invitations'
import Notifications from './Notifications'
import { useAuth } from '../../shared/contexts/authContext'
import { InvitationsStrings } from '../../shared/strings/InvitationsContent'
import { NotificationStrings } from '../../shared/strings/NotificationContent'
import { MyProfileStrings } from '../../shared/strings/MyProfileContent'
import { InvitationsTabTitle, StyledNotificationIcon, PageTitleRow } from './Styles'
import { NotificationAPI } from '../../shared/api/notifications'
import { useQuery } from 'react-query'
import MemberList, { MemberListRef } from './MemberDetails'
import InviteMembersForm from './InviteMembersForm'

const { TabPane } = Tabs

const MyProfile: FC = () => {
  const { hasNewInvitation, currentUser, currentOrganization } = useAuth()

  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [errorDetail, setErrorDetail] = useState<string | undefined>()

  const [isSuccessVisible, setIsSuccessVisible] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string | undefined>()

  const subscriptionData = useQuery(NotificationAPI.queryNames.GET_SUBSCRIPTION, () =>
    NotificationAPI.getSubscription()
  )
  const subscriptionTypes = useQuery(NotificationAPI.queryNames.GET_ALL, () => NotificationAPI.getSubscriptionType())

  const memberListRef = useRef<MemberListRef>(null)
  const [isInviteMembersPanelOpen, setIsInviteMembersPanelOpen] = useState(false)

  const hideToastNotifications = () => {
    setIsErrorVisible(false)
    setIsSuccessVisible(false)
    memberListRef.current?.hideToastNotifications()
  }

  const onClickInviteMembers = () => {
    hideToastNotifications()
    setIsInviteMembersPanelOpen(true)
  }

  const closeInviteMembersPanel = () => {
    setIsInviteMembersPanelOpen(false)
  }

  useEffect(() => {
    if (currentUser?.isKoneOwner) {
      // Fetch member details for the current organization if needed
      // fetchMemberDetails(currentOrganization.id);
    }
  }, [currentUser, currentOrganization])

  const organizationHasOwner = currentUser?.organizations?.includes(`${currentOrganization?.externalId}_owner`) ?? false

  return (
    <>
      <PageTitleRow>
        <H2>{MyProfileStrings.myProfile}</H2>
        <Toast
          isVisible={isErrorVisible}
          position="absolute"
          text={errorDetail}
          onDismiss={() => setIsErrorVisible(false)}
          type="error"
        />
        <Toast
          isVisible={isSuccessVisible}
          position="absolute"
          text={successMessage}
          onDismiss={() => setIsSuccessVisible(false)}
          type="success"
        />
      </PageTitleRow>
      <TabsContainer id="my-profile-tabs-js" defaultActiveKey="1" centered={false}>
        <TabPane key="1" tab={MyProfileStrings.profileDetails}>
          <Profile />
        </TabPane>
        {organizationHasOwner ? (
          <TabPane key="2" tab={MyProfileStrings.memberDetails}>
            <MemberList
              ref={memberListRef}
              organizationId={currentOrganization?.id || 0}
              showListHeader
              onClickAdd={onClickInviteMembers}
              onStartAction={hideToastNotifications}
            />
          </TabPane>
        ) : null}
        <TabPane
          key="3"
          tab={
            <InvitationsTabTitle>
              {InvitationsStrings.myInvitations} {hasNewInvitation() && <StyledNotificationIcon />}
            </InvitationsTabTitle>
          }
        >
          <Invitations
            setIsErrorVisible={setIsErrorVisible}
            setErrorDetail={setErrorDetail}
            setIsSuccessVisible={setIsSuccessVisible}
            setSuccessMessage={setSuccessMessage}
          />
        </TabPane>
        <TabPane key="4" tab={NotificationStrings.notifiaction}>
          <Notifications subscription={subscriptionData.data} subscriptionTypes={subscriptionTypes.data} />
        </TabPane>
      </TabsContainer>
      <SidePanel
        key="open-invite-members-button-js"
        isOpen={!!isInviteMembersPanelOpen}
        title="Invite Member"
        showBackButton={true}
        onClose={closeInviteMembersPanel}
      >
        {currentOrganization && <InviteMembersForm organization={currentOrganization} />}
      </SidePanel>
    </>
  )
}

export default MyProfile
