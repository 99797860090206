import styled from 'styled-components'
import Button from '../../../shared/components/Button'

export const TableHeaderRow = styled.div`
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
`

export const RemoveContainer = styled.div`
  width: 100px;
`

export const RemoveButton = styled(Button)`
  width: 96px;
`
