import styled from 'styled-components'
import { color } from '../../../../shared/utils/styles'
import { Steps } from 'antd'
import Button from '../../../../shared/components/Button'
import { ReactComponent as ErrorIcon } from '../../../../assets/warning.svg'
import { H3 } from '../../../../shared/components/Typography'

import { ReactComponent as RightActiveArrow } from '../../../../assets/icons-pagination-next-active.svg'
import { ReactComponent as LeftActiveArrow } from '../../../../assets/icon-pagination-previous-active.svg'
import { ReactComponent as LeftInactiveArrow } from '../../../../assets/icon-pagination-previous-passive.svg'
import { ReactComponent as RightInactiveArrow } from '../../../../assets/icons-pagination-next-passive.svg'

const { Step } = Steps

export const HeadingText = styled(H3)`
  margin-top: 0px;
  border-bottom-style: solid;
  border-bottom-color: ${color.blueBase};
`

export const ArrowButton = styled(Button)`
  background: none;
  border: none;
`
export const SingleStep = styled(Step)`
  margin-top: 20px;
`
export const ContentContainer = styled.div`
  position: relative;
`

export const ButtonContainer = styled.div`
  margin-right: 30px;
`
export const ButtonContainer2 = styled.div`
  z-index: 999;
`
export const Container = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`
export const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StepContainer = styled.div`
  display: flex;
  flex-flow: column-wrap;
  justify-content: center;
`

export const WizardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 96px;
  .ant-btn {
    margin: 20px 5px 0px 5px;
  }
  .ant-steps {
    width: 70%;
  }

  .ant-steps-item-process > .ant-steps-item-container {
    color: ${color.gray15};
    font: normal normal normal 16px/22px KONE Information;
    margin-bottom: 4px;
    &:hover {
      color: ${color.gray15};
    }
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content {
    text-align: center;
    background-color: ${color.blueBase};
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content {
    text-align: center;
  }
  .ant-steps-item:last-child {
    flex: 1;
  }
  .ant-steps-item-process > .ant-steps-item-container:last-child {
    color: ${color.gray15};
    font: normal normal normal 16px/22px KONE Information;
  }
  .ant-steps-item-title::after {
    background-color: blue;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: ${color.gray15};
    font: normal normal normal 16px/22px KONE Information;
    border: 1px;
    border-style: solid;
    border-color: ${color.blueBase};
    padding: 5px 5px 5px 5px;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: ${color.gray30};
    font: normal normal normal 16px/22px KONE Information;
    margin-bottom: 4px;
    border: 1px;
    border-style: solid;
    border-color: ${color.blueBase};
    padding: 5px 5px 5px 5px;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: ${color.gray30};
    font: normal normal normal 16px/22px KONE Information;
    margin-bottom: 4px;
    border: 1px;
    border-style: solid;
    border-color: ${color.blueBase};
    padding: 5px 5px 5px 5px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: ${color.gray15};
    font: normal normal normal 12px/16px KONE Information;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: ${color.gray30};
    font: normal normal normal 12px/16px KONE Information;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: ${color.gray30};
    font: normal normal normal 12px/16px KONE Information;
  }

  .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
    font: normal normal normal 16px/22px KONE Information;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: ${color.white};
    border: 1px solid ${color.gray70};
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${color.gray30};
    font: normal normal normal 16px/22px KONE Information;
  }

  .ant-steps-item-icon .ant-steps-icon {
    top: 0;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: transparent;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    border: 1px solid ${color.gray70};
    margin-right: 15px;
    margin-left: 22px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    border: 1px solid ${color.gray70};
    width: 100px;
    margin-right: 15px;
    margin-left: 22px;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    border: 1px solid ${color.gray70};
    /* width: 100px; */
    margin-right: 15px;
    margin-left: 22px;
  }

  .ant-steps-item-error .ant-steps-item-icon {
    background-color: transparent;
  }

  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    border: 1px solid ${color.gray70};
    width: 100px;
    margin-right: 15px;
    margin-left: 22px;
  }

  .ant-steps-small .ant-steps-item-title {
    font: normal normal normal 16px/22px KONE Information;
  }

  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    font: normal normal normal 12px/16px KONE Information;
  }
`

export const ErrorIconStyled = styled(ErrorIcon)`
  text-align: center;
`

export const ErrorContainer = styled.div`
  border: 1px solid #ed555a;
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
`
export const NextActiveArrow = styled(RightActiveArrow)`
  margin-right: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-top: 18px;
`

export const PreviousActiveArrow = styled(LeftActiveArrow)`
  margin-left: 4px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: 18px;
`

export const NextPassiveArrow = styled(RightInactiveArrow)`
  margin-right: 4px;
  width: 40px;
  height: 40px;
  margin-top: 18px;
  .an {
    fill: none;
  }
  .bn {
    fill: #3d464c;
  }
`

export const PreviousPassiveArrow = styled(LeftInactiveArrow)`
  margin-left: 4px;
  width: 40px;
  height: 40px;
  margin-top: 18px;
  .ap {
    fill: none;
  }
  .bp {
    fill: #3d464c;
  }
`
export const StepFinal = styled.div`
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    width: 0px;
    margin-right: 15px;
  }
`
export const Title = styled.span`
  color: white;
`
