import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'

export const ReleaseNotesLogOct3 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        Latest Release - October 2024 - 3.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        October 3.0 release notes summarizes latest changes for Service Info API.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}> Service Info API</li>
          <UL>
            <li>
              The DateTime fields createdDateTime, workOrderPlannedStartDate, earliestStartDate, actualArrivalDateTime
              and finishedDateTime have been updated in the Service Info Sandbox response based on the service
              order­Status.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
