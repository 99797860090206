import React, { FC } from 'react'
import { Text5 } from '../../shared/components/Paragraphs'
import TabHandler from './TabHandler'
import { useAuth } from '../../shared/contexts/authContext'
import { useHistory } from 'react-router-dom'
import { SharedOrganizationsStrings } from '../../shared/strings/OrganizationsContent'

const OrganizationDetails: FC = () => {
  const { currentOrganization } = useAuth()
  const history = useHistory()

  if (currentOrganization?.name === 'Personal') {
    history.replace(`/dashboard`)
  }

  return (
    <div>
      <Text5>{currentOrganization ? currentOrganization.name : SharedOrganizationsStrings.noOrganizationFound}</Text5>
      <div>
        <TabHandler />
      </div>
    </div>
  )
}

export default OrganizationDetails
