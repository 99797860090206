export const PREFIX_BUILDING_ID = 'building:'

export type BuildingId = string
export type SimplifiedBuilding = {
  buildingId: string
  version: number
  timestamp: string
  groupCount: number
  areaCount: number
}
export type BuildingList = SimplifiedBuilding[]

export type RefreshResponse = {
  buildingId: BuildingId
}

export type BuildingName = {
  buildingId: string
  buildingName: string
}

type GroupData = {
  group_id: number
  device_id: string
}
export type DeviceMapping = {
  buildingId: string
  thingName: string
  deviceType: string
  businessAssetNumbers: string[]
  thingType: string
  clusterId: string
  groupId: string
  groupData?: GroupData[]
  timestamp: Date
}

export type DeviceMappingList = DeviceMapping[]
