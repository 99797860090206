import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { OrganizationsAPI } from '../../../../shared/api/organizations'
import { Organization } from '../../../../shared/models/organization'
import { NumberValue, NumberValueHeader } from '../Styles'

type Props = {
  organization: Organization
}

const MemberCount: FC<Props> = ({ organization }) => {
  const queryName = OrganizationsAPI.queryNames.GET_MEMBERS_ONLY
  const { isLoading, error, data } = useQuery([queryName, organization.id], () =>
    OrganizationsAPI.listMembersOnly(organization.id)
  )
  if (error) {
    return <p>Error</p>
  }
  return (
    <>
      <NumberValue id="org-member-count">{isLoading ? '–' : data.length}</NumberValue>
      <NumberValueHeader>{data && data.length > 1 ? 'Members' : 'Member'}</NumberValueHeader>
    </>
  )
}

export default MemberCount
