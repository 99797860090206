/* eslint-disable prettier/prettier */
import React, { FC, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { APILinkCard, H3, H5, SidePanel, Toast } from '../../shared/components'
import { UserOrganizationAPI } from '../../shared/api/organizationDetails'
import { useAuth } from '../../shared/contexts/authContext'
import { DashboardPageStrings, APIProductCardDescription } from '../../shared/strings/DashboardHomeContent'
import { CustomerApp } from '../../shared/models/application'
import TileCreate from './TileCreate'
import CreateForm from './CreateWizard'
import TileInfo from './TileInfo'
import { ApplicationsAPI } from '../../shared/api/applications'
import EditApp from './EditApp'
import ManageWebhooks from './ManageWebhooks'
import WebhookForm from './WebhookForm'
import Paths from '../../Dashboard/Paths'
import {
  DashboardTitle,
  TilesContainer,
  MessageContainer,
  IntroText,
  ProductCards,
  MfaAddExtraSecurityText,
  StyledTabs,
  TabsContainer,
} from './Styles'
import { useHistory } from 'react-router-dom'

const { TabPane } = StyledTabs

const DashboardHome: FC = () => {
  const { currentOrganization, isMfaEnabledForUser } = useAuth()
  const history = useHistory()

  const [isCreatePanelOpen, setIsCreatePanelOpen] = useState(false)
  const [currentEditApplication, setCurrentEditApplication] = useState<CustomerApp | null>(null)
  const [currentWebhookApplication, setCurrentWebhookApplication] = useState<any>(null)
  const [isMfaNotificationOpen, setIsMfaNotificationOpen] = useState(true)
  const [isWebhookPanelOpen, setIsWebhookPanelOpen] = useState(false)
  const [isCreateSubscriptionSuccessful, setIsCreateSubscriptionSuccessful] = useState(false)
  const [isSecureUrl, setIsSecureUrl] = useState<any>()

  const queryClient = useQueryClient()

  const {
    isLoading,
    error,
    data: applications,
  } = useQuery([ApplicationsAPI.queryNames.GET_ALL, currentOrganization?.externalId], () => ApplicationsAPI.getAll())

  const resourceData = useQuery(
    [UserOrganizationAPI.queryNamesResources.GET_ALL, currentOrganization?.externalId],
    () => UserOrganizationAPI.getResources()
  )

  const isHttpUrl = (endpointUrl: string): void => {
    setIsSecureUrl(endpointUrl.slice(0, 5) === 'https')
  }

  const openNewWebhookPanel = () => {
    setIsWebhookPanelOpen(true)
  }

  const closeNewWebhookPanel = () => {
    setIsWebhookPanelOpen(false)
    setCurrentWebhookApplication(null)
    closeBanner()
    queryClient.removeQueries('subscription-list')
  }

  const onClickEdit = (application: CustomerApp) => {
    setCurrentEditApplication(application)
    setIsMfaNotificationOpen(false)
  }

  const onClickCreate = () => {
    setIsCreatePanelOpen(true)
    setIsMfaNotificationOpen(false)
  }

  const closeCreateSidePanel = () => {
    setIsCreatePanelOpen(false)
  }

  const closeEditSidePanel = () => {
    setCurrentWebhookApplication(currentEditApplication)
    setCurrentEditApplication(null)
    closeBanner()
    queryClient.removeQueries('subscription-list')
  }

  const showSuccessfulCreation = () => {
    setIsCreateSubscriptionSuccessful(true)
  }

  const closeBanner = () => {
    setIsCreateSubscriptionSuccessful(false)
  }

  const showApplicationsList = () => {
    if (isLoading) {
      return <MessageContainer>{DashboardPageStrings.loadingApplications}</MessageContainer>
    }
    if (error) {
      return <MessageContainer>{DashboardPageStrings.getApplicationsError}</MessageContainer>
    }

    const infoTiles = applications.map((app: CustomerApp) => {
      return <TileInfo onClickEdit={onClickEdit} key={app.clientId} application={app} />
    })

    return (
      <TilesContainer>
        <TileCreate isFirstApplication={!applications.length} onClickCreate={onClickCreate} />
        {infoTiles}
      </TilesContainer>
    )
  }

  const onMfaSetting = () => {
    history.push({ pathname: './dashboard/my-profile', state: true })
  }

  const showMfaNotification = () => {
    return (
      <Toast
        isVisible={isMfaNotificationOpen}
        text={[
          <div key="">
            <MfaAddExtraSecurityText>
              {DashboardPageStrings.mfaAddExtraSecurityText}
              <span style={{ color: '#0C9FED', cursor: 'pointer' }} onClick={onMfaSetting}>
                {DashboardPageStrings.mfaSetupTwoFactorText}
              </span>
              !
            </MfaAddExtraSecurityText>
          </div>,
        ]}
        onDismiss={() => {
          setIsMfaNotificationOpen(false)
        }}
        type="info"
        position="absolute"
        top="120px"
        right="188px"
      />
    )
  }
  const getKenResources = () => {
    if (resourceData.data) {
      return resourceData.data.filter((r: any) => r.resourceId.type === 'ken')
    } else {
      return []
    }
  }
  return (
    <div>
      {!isMfaEnabledForUser() && showMfaNotification()}
      <DashboardTitle>{DashboardPageStrings.title}</DashboardTitle>
      <H5>{DashboardPageStrings.applications}</H5>
      {showApplicationsList()}
      {!isLoading && (
        <>
          <H3>{DashboardPageStrings.developWithAPIsTitle}</H3>
          <IntroText size="small">{DashboardPageStrings.developWithAPIsText}</IntroText>
          <H5>KONE APIs</H5>
          <ProductCards>
            <APILinkCard
              name={Paths.ElevatorCallAPI.name}
              description={APIProductCardDescription.elevatorCallAPI}
              path={Paths.ElevatorCallAPI.path}
            />
            <APILinkCard
              name={Paths.ServiceRobotAPI.name}
              description={APIProductCardDescription.serviceRobotAPI}
              path={Paths.ServiceRobotAPI.path}
            />
            <APILinkCard
              name={Paths.EquipmentStatusAPIProduct.name}
              description={APIProductCardDescription.equipmentStatusAPI}
              path={Paths.EquipmentStatusAPIProduct.path}
            />
            <APILinkCard
              name={Paths.ServiceInfoAPIProduct.name}
              description={APIProductCardDescription.serviceInfoAPI}
              path={Paths.ServiceInfoAPIProduct.path}
            />
          </ProductCards>
        </>
      )}
      <SidePanel
        key="create-application-side-panel"
        isOpen={isCreatePanelOpen}
        title="Create application"
        showBackButton={true}
        onClose={closeCreateSidePanel}
      >
        <CreateForm onCancelCreate={closeCreateSidePanel} />
      </SidePanel>

      {currentEditApplication && (
        <SidePanel
          key="edit-application-side-panel"
          isOpen={!!currentEditApplication}
          title="Edit application"
          showBackButton={true}
          onClose={closeEditSidePanel}
        >
          <TabsContainer>
            <StyledTabs centered={true} defaultActiveKey={'details'} destroyInactiveTabPane={true}>
              <TabPane tab="DETAILS" key="details">
                <EditApp application={currentEditApplication} />
              </TabPane>
              <TabPane tab="WEBHOOKS" key="Webhooks">
                <ManageWebhooks
                  application={currentEditApplication}
                  isCreatePanelOpen={isCreatePanelOpen}
                  closeEditSidePanel={closeEditSidePanel}
                  openNewWebhookPanel={openNewWebhookPanel}
                  successfulCreation={isCreateSubscriptionSuccessful}
                  closeBanner={closeBanner}
                  isSecureUrl={isSecureUrl}
                  kenResources={getKenResources()}
                />
              </TabPane>
            </StyledTabs>
          </TabsContainer>
        </SidePanel>
      )}
      {isWebhookPanelOpen && (
        <SidePanel
          key="webhook-side-panel"
          isOpen={!!currentEditApplication}
          title="New webhook"
          showBackButton={true}
          onClose={() => {
            closeNewWebhookPanel()
          }}
        >
          <WebhookForm
            application={currentEditApplication!}
            closeNewWebhookPanel={closeNewWebhookPanel}
            showSuccessfulCreation={showSuccessfulCreation}
            isHttpUrl={isHttpUrl}
            resourceData={resourceData.data}
          />
        </SidePanel>
      )}
    </div>
  )
}

export default DashboardHome
