const inviteMember = 'Invite members'

export const SharedOrganizationsStrings = {
  noMembersYet: 'No members yet',
  loading: 'Loading...',
  noResourcesFound: 'No resources found',
  noProductsFound: 'No products found',
  api: 'API',
  type: 'Type',
  id: 'id',
  description: 'Description',
  noOrganizationFound: 'No organization found',
  deleteOrganization: 'Are you sure you want to delete this organization ?',
  removeButton: 'Remove',
  deleteButtonText: 'Yes, Delete',
  removeResourceButton: 'Remove Resource',
  goBackButton: `No, let's go back`,
  ok: `Ok`,
  pleaseNoteModalTitle: 'Please note!',
  products: 'API products',
  msgForNoContract: 'Below kens dont have valid contracts, you need to add expiry date manually',
}

export const CreateFormStrings = {
  organizationName: 'Organization name',
  organizationNameRequiredError: 'Organization name is required',
  organizationCreatedSuccess: 'Organization created!',
  createOrganization: 'Create Organization',
  createOrganizationError: 'Create Organization error',
  externalOrgType: 'Customer',
  internalOrgType: 'Internal',
  sandboxOrgType: 'Sandbox',
  orgNameLengthError: 'Organization name must be between 1 to 64 characters',
  orgNameWhiteSpaceError: 'Whitespace is not allowed at the beginning and end of the organization name',
  distributionList: 'Organization distribution list email address',
}

export const EditProductsStrings = {
  productNamesByOrgIdError: 'Cannot get product list',
  productsListTitle: `Select organization's API`,
  changesSavedSuccess: 'Changes have been saved',
  changesSavedError: 'Error happened while saving changes',
}

export const EditResourcesStrings = {
  resourceCannotBeEmpty: 'Resource cannot be empty',
  getResourceError: 'Error happened while getting the resource',
  getContractError: 'Error happened while getting the contract',
  updateResourceError: 'Failed to update the resource!',
  productName: 'Owns',
  resourceAlreadyExistError: 'Resource already exists',
  errorOccurred: 'Something went wrong with resources',
  title: 'Add resources by selecting the resource type first',
  resourceType: 'Resource type',
  buildingOption: 'Building',
  kenOption: 'Ken',
  addButton: 'Add Resources',
  resourceAddedSuccess: 'Resources have been successfully added!',
  resourceUpdatedSuccess: 'Resource successfully updated!',
  expireLabel: 'Set expiration date',
  addedResource: 'Added Resources',
  backToResources: 'BACK TO RESOURCES',
  noResourcesAdded: 'No resources added yet',
  dateFormat: 'YYYY-MM-DD',
}

export const InviteMembersStrings = {
  emailCannotBeEmptyError: 'Email is required',
  notValidEmailError: `is not a valid email address`,
  isAlreadyOnTheListError: `is already in the list`,
  invitationFailedError: 'Sorry, something went wrong with the invitation',
  invitationTimeoutError: 'Sorry, invitation request timeout, please try again',
  title: 'Invite new members to the organization',
  viaEmail: 'Via email',
  invitationSentSuccess: 'Invitation sent!',
  members: 'Members',
  inviteMembersButton: inviteMember,
}

export const MemberListStrings = {
  statusTextSentInvitation: 'Sent invitation',
  statusTextExpiredInvitation: 'Expired invitation',
  statusTextActive: 'Invitation accepted',
  removeFromOrganizationStart: 'Are you sure you want to remove',
  removeFromOrganizationEnd: 'from the organization?',
  removeUserFromOrganizationError: 'Error while removing the user from the organization',
  enableUserFromOrganizationError: 'Error while enabling the user',
  resendButton: 'Resend',
  removeButton: 'Yes, remove',
  goBackButton: `No, let's go back`,
  memberListFetchError: 'Error while fetching the member list',
  noInvitedMembers: 'No members invited yet',
  resendInvitationSuccess: 'Invitation resent',
  resendInvitationError: 'Sorry, something went wrong when sending the invitation, please try again',
  status: 'Status',
  invitationStatus: 'Invitation Status',
  listHeaderText: 'Members',
  addButtonText: inviteMember,
  owner: 'Owner',
  title: 'Title',
  userName: 'User name / Primary email ID',
  firstName: 'First name',
  lastName: 'Last name',
  saveButton: 'SAVE',
  cancelButton: 'CANCEL',
}

export const ProductListStrings = {
  errorLoadingProducts: 'Cannot fetch the product list',
  noProductsAdded: 'No products added yet',
  listHeaderTitle: 'APIs',
  addButtonText: 'Edit APIs',
}

export const ResourceListStrings = {
  deleteResourceStart: 'Are you sure you want to delete resource',
  withId: 'with id',
  removeResourceProductName: 'Owns',
  removeResourceError: 'Sorry, something went wrong while removing the resource from the organization',
  id: 'ID',
  errorLoadingResources: 'Cannot fetch the resource list',
  noResourcesAdded: 'No resources added yet',
  listHeaderTitle: 'Resources',
  addButtonText: 'Add resources',
}

export const EditFormStrings = {
  organizationCreated: 'Organization was created!',
  organizationSaved: 'Organization was saved!',
  organizationName: 'Organization name',
  organizationNameRequired: 'Organization name is required',
  countryRequired: 'Country is required',
  salesforceAccountId: 'Salesforce account ID',
  saveOrganization: 'Save Organization',
  sidePanelEditResources: 'Edit resources',
  sidePanelInviteMembers: inviteMember,
  sidePanelEditApis: 'Edit APIs',
  closeButton: 'Close',
  editOrganizationError: 'Edit organization error',
  distributionList: 'Organization distribution list email address',
}

export const TileCreateStrings = {
  createNewOrganization: 'Create new organization',
  createOrganization: 'Create organization',
}

export const TileInfoStrings = {
  resourceError: 'Get resources error',
  editButton: 'Edit',
}

export const OrganizationsStrings = {
  organizations: 'Organizations',
  welcomeBack: 'Welcome back',
  loadingOrganizationsList: 'Loading organizations list...',
  organizationsListError: 'Cannot fetch organizations list',
  onlyCustomers: 'Only customers',
  yourOrganizations: 'Your organizations',
  selectOrganizationLabel: 'Organization',
  newOrganizationAdded: 'New organization added!',
  new: 'new',
}

export const TierPlanStrings = {
  empty: '',
  title: 'Tier Rate Limit',
  edit: 'Edit Plans',
  loading: 'Loading...',
  errorLoadTierPlan: 'Cannot fetch tier plan',
  saveSuccess: 'Tier plan has been saved sucessfully!',
  saveError: 'Error happens when saving changes',
  headerProduct: 'API Product',
  headerTier: 'Tier Plan',
  rowRtmProduct: 'Real Time Monitoring API',
  editTitle: 'Edit Tier Plan',
  light: 'light',
  standard: 'standard',
  premium: 'premium',
}
