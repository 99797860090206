import React, { FC, useState } from 'react'
import { Tabs } from 'antd'
import ResourceList from '../ResourceList'
import MemberList from '../MemberList'
import { TabsContainer } from '../../../shared/components'

const { TabPane } = Tabs

const TabHandler: FC = () => {
  const [resourcesWithCounter, setResourcesWithCounter] = useState('Resources')
  const [membersWithCounter, setMembersWithCounter] = useState('Members')

  const onDataListSuccess = (dataLength: number) => {
    setResourcesWithCounter(`Resources (${dataLength})`)
  }

  const onMemberListSuccess = (dataLength: number) => {
    setMembersWithCounter(`Members (${dataLength})`)
  }

  return (
    <TabsContainer id="organization-details-tabs-js" defaultActiveKey="1" centered={false}>
      <TabPane key="1" tab={membersWithCounter}>
        <MemberList onMemberListSuccess={onMemberListSuccess} />
      </TabPane>
      <TabPane tab={resourcesWithCounter} key="2" forceRender>
        <ResourceList onDataListSuccess={onDataListSuccess} />
      </TabPane>
    </TabsContainer>
  )
}

export default TabHandler
