import { Col, Row } from 'antd'
import Markdown from 'markdown-to-jsx'
import { OpenAPIV3 } from 'openapi-types'
import React, { FC } from 'react'
import { Button, H2, H4, P } from '../../../shared/components'
import useScrollToHash from '../../../shared/utils/scrollToHash'
import { CodeBlock, LeftColFirst, RightColEmpty } from '../Styles'
import RESTEndpoint from './components/RESTEndpoint'
import TableOfContents from './components/TableOfContents'
import { makeLocalLinksRelative, markdownOptions } from './open-api-utils'
import { Endpoint, Method } from './types'

type RestDocumentationProps = {
  openAPISpec: OpenAPIV3.Document
  apiName: string
}

const RestDocumentation: FC<RestDocumentationProps> = ({ openAPISpec, apiName }) => {
  useScrollToHash()

  const paths = openAPISpec.paths || ({} as OpenAPIV3.PathsObject)

  const getEndpoint = (method: Method, pattern: string, operationObject: OpenAPIV3.OperationObject): Endpoint => {
    return {
      method,
      pattern,
      ...operationObject,
    }
  }

  const endpoints = Object.entries(paths).flatMap(([pattern, pathObject]) => {
    const endpoints = [] as Endpoint[]

    if (pathObject?.get) {
      endpoints.push(getEndpoint('GET', pattern, pathObject.get))
    }
    if (pathObject?.post) {
      endpoints.push(getEndpoint('POST', pattern, pathObject.post))
    }
    if (pathObject?.patch) {
      endpoints.push(getEndpoint('PATCH', pattern, pathObject.patch))
    }
    if (pathObject?.put) {
      endpoints.push(getEndpoint('PUT', pattern, pathObject.put))
    }
    if (pathObject?.delete) {
      endpoints.push(getEndpoint('DELETE', pattern, pathObject.delete))
    }

    return endpoints
  })

  return (
    <Row>
      <Col span={24}>
        <Row>
          <LeftColFirst span="12">
            <H2>{openAPISpec.info.title}</H2>

            <P size="small">
              <a href={`/api/v1/spec/${apiName}.yaml`} download>
                <Button type="primary">Download OpenAPI 3.0 specification - YAML</Button>
              </a>
            </P>
            <P size="small">
              <a href={`/api/v1/spec/${apiName}.json`} download>
                <Button type="primary">Download OpenAPI 3.0 specification - JSON</Button>
              </a>
            </P>
            {openAPISpec.info.description && (
              <Markdown options={markdownOptions}>{makeLocalLinksRelative(openAPISpec.info.description)}</Markdown>
            )}
            <TableOfContents endpoints={endpoints} />

            <H4>Server</H4>

            {openAPISpec.servers?.map((server, i) => {
              if (server.url === 'https://%SERVER_HOST%') {
                return (
                  <CodeBlock
                    key={`codeblock-server-${i}`}
                    language="typescript"
                  >{`${window.location.protocol}//${window.location.host}`}</CodeBlock>
                )
              } else if (server.url) {
                return (
                  <CodeBlock key={`codeblock-server-${i}`} language="typescript">
                    {server.url}
                  </CodeBlock>
                )
              } else {
                return <CodeBlock key={`codeblock-server-${i}`} language="typescript"></CodeBlock>
              }
            })}
          </LeftColFirst>
          <RightColEmpty span="12"></RightColEmpty>
        </Row>
        {endpoints.map((endpoint) => (
          <RESTEndpoint key={endpoint.operationId} endpoint={endpoint} document={openAPISpec!} />
        ))}
      </Col>
    </Row>
  )
}

export default RestDocumentation
