import styled from 'styled-components'
import { animated } from 'react-spring'
import { ReactComponent as CloseSmall } from '../../../assets/icons-close-small.svg'
import { color } from '../../utils/styles'
import { ToastType } from './ToastType'

const colors: { [key in ToastType]: string } = {
  success: color.green,
  warning: color.orange,
  error: color.red,
  info: color.blueBase,
}

type Props = {
  type: ToastType
}

export const ToastWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const AnimatedDiv = styled(animated.div)<{ top: string; right: string }>`
  width: 348px;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  z-index: 1000;
`

export const ToastContent = styled.div`
  border-left: 8px solid ${(props: Props) => colors[props.type]};
  background-color: ${color.white};
  box-shadow: 0px 3px 6px ${color.blackShadow};
  border-radius: 4px;
  padding: 8px;
`

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  margin: 0 8px;
  display: flex;
  align-self: flex-start;
`

export const Text = styled.div`
  color: ${color.black};
  width: 260px;
  margin: 0;
  text-align: left;
`

export const CloseIcon = styled(CloseSmall)`
  margin-left: 8px;
  margin-top: 0px;
  cursor: pointer;
  align-self: flex-start;
`
