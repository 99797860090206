import React, { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { UserOrganizationAPI } from '../../../shared/api/organizationDetails'
import { TABLE_CONTENT_LENGTH } from '../../../shared/constants/common'
import { useAuth } from '../../../shared/contexts/authContext'
import { GeneralFormStrings } from '../../../shared/strings/GeneralFormContent'
import { SharedOrganizationsStrings } from '../../../shared/strings/OrganizationsContent'
import Pagination from '../Pagination'
import { NotLoadedTableText, HeaderRow, ContentRow, Column } from '../Styles'

type Props = {
  onMemberListSuccess: (dataLength: number) => void
}

const MemberList: FC<Props> = ({ onMemberListSuccess }) => {
  const { currentOrganization } = useAuth()
  const listOfMembers = useQuery([currentOrganization?.externalId], () => UserOrganizationAPI.getOrganizationMembers())

  const [page, setPage] = useState(0)

  if (listOfMembers.isLoading) {
    return <NotLoadedTableText>{SharedOrganizationsStrings.loading}</NotLoadedTableText>
  }

  if (listOfMembers.error) {
    return <NotLoadedTableText>`${listOfMembers.error}`</NotLoadedTableText>
  }

  if (!listOfMembers.data || listOfMembers.data.length === 0) {
    onMemberListSuccess(0)
    return <NotLoadedTableText>{SharedOrganizationsStrings.noMembersYet}</NotLoadedTableText>
  }

  const maxPage = Math.ceil(listOfMembers.data.length / TABLE_CONTENT_LENGTH)
  onMemberListSuccess(listOfMembers.data.length)
  const onNextPage = () => {
    if (page !== maxPage - 1) {
      setPage((page + 1) % maxPage)
    }
  }

  const onPrevPage = () => {
    if (page !== 0) {
      setPage((page - 1) % maxPage)
    }
  }

  const jumpToLastPage = () => {
    setPage(maxPage - 1)
  }

  const jumpToFirstPage = () => {
    setPage(0)
  }

  return (
    <>
      <HeaderRow>
        <Column>{GeneralFormStrings.name}</Column>
        <Column>{GeneralFormStrings.email}</Column>
      </HeaderRow>
      {listOfMembers.data
        .slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))
        .map((e: any, key: number) => (
          <ContentRow key={key}>
            <Column title={`${e.firstName} ${e.lastName}`}>{`${e.firstName} ${e.lastName}`}</Column>
            <Column id="member-email-js" title={e.email}>
              {e.email}
            </Column>
          </ContentRow>
        ))}
      <Pagination
        jumpToFirstPage={jumpToFirstPage}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        jumpToLastPage={jumpToLastPage}
        page={page}
        maxPage={maxPage}
        data={listOfMembers.data}
      />
    </>
  )
}

export default MemberList
