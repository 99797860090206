import { OpenAPIV3 } from 'openapi-types'
import React, { FC } from 'react'
import RestDocumentation from '../RestDocumentation'

const BuildingAPIDocumentation: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const openAPISpec = require(`./building-api.json`) as OpenAPIV3.Document

  return <RestDocumentation apiName={'building-api'} openAPISpec={openAPISpec} />
}

export default BuildingAPIDocumentation
