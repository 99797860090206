import React, { FC } from 'react'
import { HeadingText } from './Styles'
import { Row } from 'antd'
import CardContent from './CardContent'
import APICardContent from './ApiOverView'
import { apiOverview, authenticationScope, developInSandBox, apiVersioning } from './Common'
const SolutionDevelopment: FC = () => {
  return (
    <>
      <Row>
        <HeadingText>Solution Development</HeadingText>
      </Row>
      <APICardContent contentDetail={apiOverview} />
      <CardContent contentDetail={authenticationScope} />
      <CardContent contentDetail={developInSandBox} />
      <CardContent contentDetail={apiVersioning} />
    </>
  )
}

export default SolutionDevelopment
