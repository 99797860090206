/**
 * This file maintains all Adobe Environment ids
 * which will be attached with each event to identify the env
 */

const environmentStageIdMap = {
  EN97d7daed342644e89664312986af1317: 'master',
  ENa8f2c45c64584a388bfbc2fcc999013f: 'qa',
  ENa33b6a593c434f3395d53430960ed3ac: 'Global Prod - dev.kone.com',
  ENbbd068273c894e9cbceb79e2bacc6e1f: 'china-qa - china-qa.dev.kone.cn',
  ENcfc75d3870bc480487857cef1201a26a: 'China Prod - dev.kone.cn',
}

export const analyticEnvironment = () =>
  window._satellite && window._satellite._container
    ? environmentStageIdMap[window._satellite._container.environment.id]
    : ''
