import { AuthStrings } from '../../shared/strings/AuthContent'
import { ErrorStrings } from '../../shared/strings/AuthFormContent'

export const EMAIL_REG_EXP =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//Minimum 12 characters, at least one uppercase letter, one lowercase letter and one number
export const PASSWORD_REG_EXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{12,}$/
export const PASSWORD_REG_EXP_DIGITS = /^(?=.*\d)$/
export const PASSWORD_REG_EXP_UPPERCASE = /^(?=.*[A-Z])$/
export const PASSWORD_REG_EXP_LOWERCASE = /^(?=.*[a-z])$/
export const COGNITO_PASSWORD_MIN_LENGTH = 6
export const COGNITO_PASSWORD_PATTERN = /^[\S]+.*[\S]+$/
export const BID_REG = /^\S*$/
export const BID_MAX_LENGTH = 55
export const BID_MIN_LENGTH = 10
export const APP_NAME_MAX_LENGTH = 64
export const APP_NAME_MIN_LENGTH = 1
export const APP_NAME_REG = /^\S$|^\S[\s\S]*\S$/
export const OTP_REG = /^[0-9]+$/
export const OTP_LENGTH = 6
export const GEO_COORDINATE = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/
export const ZIP_CODE = /^\d{6}(?:[-\s]\d{4})?$/
export const VALID_URL =
  /^(?:(?:https?|http):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const NO_CURRENT_USER_AMPLIFY_ERROR = AuthStrings.noCurrentUserAmplifyError
export const REQUIRED_FIELD = AuthStrings.requiredField
export const PASSWORD_DESCRIPTION = AuthStrings.passwordDescription
export const PASSWORD_MISMATCHED = ErrorStrings.passwordsDoNotMatchError
export const KONE_ADMIN_GROUP = AuthStrings.koneAdmin
export const FORGOT_PASSWORD_DESCRIPTION = AuthStrings.forgotPassword

export const NEW_PASSWORD_DESCRIPTION = AuthStrings.checkYourEmail
export const NEW_PASSWORD_INSTRUCTION_ON_ERROR = AuthStrings.errorInPasswordReset

//Webhooks
export const WEBHOOK_NAME_MAX_LENGTH = 64
export const WEBHOOK_NAME_MIN_LENGTH = 1

//Salespage
export const PARTNER_NAME_MAX_LENGTH = 64
export const PHONE_NUMBER_REGEX = /^([+]?)+\d{8,15}(,[\s\s]?([+]?)+\d{8,15})*$/
export const PARTNER_NAME_MIN_LENGTH = 1
export const PARTNER_WEBSITE_LINK_REGX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
export const COMPANY_INFO_MAX_LENGTH = 1000
export const COMPANY_INFO_MIN_LENGTH = 1
export const YOUTUBE_REGEX = /^((http(s)?:\/\/)?)(www\.)?((youtube\.com\/)|(youtu.be\/))[\S]+/

//Feedback
export const KEN_MAX_LENGTH = 40
export const BUILDING_MAX_LENGTH = 60
