import styled from 'styled-components'
import Button from '../../../../shared/components/Button'
import { color } from '../../../../shared/utils/styles'

export const ForgotYourPasswordLink = styled.a`
  float: right;
  color: ${color.blueTint20};

  &:hover {
    color: ${color.blueBase};
  }
`

export const LoginButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  height: 40px;
`

export const KoneAdminButtonContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`

export const KoneAdminLoginButton = styled(Button)`
  color: ${color.blueTint20};
  text-transform: none;

  &:hover {
    color: ${color.blueBase};
  }
`
