import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthFormWrapper } from '../../../shared/components'
import { ServiceBreakStrings } from '../../../shared/strings/AuthFormContent'
import { Feature, isFeatureEnabled } from '../../../shared/utils/featureFlags'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import MFAInfo from './MFAInfo'
import { ServiceBreakMessage, StyledTabs, TabsContainer } from './Styles'
import { useAuth } from '../../../shared/contexts/authContext'
import { MfaSetting } from '../../../shared/models/mfa'

const { TabPane } = StyledTabs

const HeaderAuthForms: FC = () => {
  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search)
  const auth = queryParams.get('auth')
  const { isAuthenticated, isMfaNeeded } = useAuth()

  const onChange = (key: string) => {
    queryParams.set('auth', key)
    history.replace({
      search: queryParams.toString(),
    })
  }

  return (
    <>
      {isAuthenticated() && isMfaNeeded() ? (
        <MFAInfo setting={MfaSetting.REGISTRATION} />
      ) : (
        <TabsContainer>
          <AuthFormWrapper>
            <StyledTabs
              centered={true}
              defaultActiveKey={auth || 'login'}
              destroyInactiveTabPane={true}
              onChange={onChange}
            >
              <TabPane tab="LOG IN" key="login">
                {isFeatureEnabled(Feature.ServiceBreak) ? (
                  <ServiceBreakMessage>{ServiceBreakStrings.loginDisabled}</ServiceBreakMessage>
                ) : (
                  <LoginForm />
                )}
              </TabPane>
              <TabPane tab="REGISTER" key="register">
                {isFeatureEnabled(Feature.ServiceBreak) ? (
                  <ServiceBreakMessage>{ServiceBreakStrings.registrationDisabled}</ServiceBreakMessage>
                ) : (
                  <RegisterForm />
                )}
              </TabPane>
            </StyledTabs>
          </AuthFormWrapper>
        </TabsContainer>
      )}
    </>
  )
}

export default HeaderAuthForms
