import React, { FC } from 'react'
import { CardContainer, ContentHeadingLeft, DownloadTittle } from './Styles'
import { Row, Col } from 'antd'
import SecurityAccess from '../Download/DownloadSecurity'
import ProductionAccess from '../Download'

const DocumentGuideForTesting: FC = () => {
  return (
    <CardContainer>
      <Row justify="center" align="middle">
        <Col xs={2} sm={4} md={6} lg={8} xl={24}>
          <ContentHeadingLeft>Documentation guide for testing</ContentHeadingLeft>
          <DownloadTittle>Self-validation template</DownloadTittle>
          <p>
            {' '}
            This is mandatory document! All test cases should be tested and the actual response from your application
            should be saved in template.
          </p>
          <ProductionAccess />
          <DownloadTittle>Security & Privacy: Self-assessment</DownloadTittle>
          <p>This is mandatory document to evaluate the security level of your solution.</p>
          <SecurityAccess />
        </Col>
      </Row>
    </CardContainer>
  )
}

export default DocumentGuideForTesting
