export const MFAStrings = {
  accountCreated: 'Account is now created!',
  userQuestionToAddMfa:
    'One more thing, would you like to add an extra security layer to your account with two-factor authentication?',
  howItWorksStep1:
    'When you log in to KONE API Portal and have the multi-factor authentication enabled, you’ll start logging in by entering your email and password, as usual.',
  howItWorksStep2:
    'Next, you’ll need to enter an additional code that can be found from the authentication application of your choice.',
  howItWorksStep3: 'After you have entered the authentication code to the website, you’re logged in',
  howItWorks: 'How it works?',
  hideDetails: 'Hide details',
  noThanks: 'No thanks, go to dashboard',
  setupMfa: 'Yes, setup using an app',
  commonlyUsedAppHeading: 'Commonly used applications:',
  scanQrCode: 'Scan this QR code with your app',
  enterSecretKey: 'Or enter this secret key',
  onQrCodeScanUserInfo: 'After scanning the QR code, the app will generate a 6-digit code that you can enter below.',
  enter6digitCode: 'Enter 6-digit code',
  cancelAndGotoDashboard: 'cancel and go to dashboard',
  cancel: 'Cancel',
  enableAuthentication: 'Enable authentication',
  enableThisLaterAlsoFromProfileText: 'This can also be enabled later on from your profile settings.',
  enable2FALaterAlsoFromProfileText:
    'Two-factor authentication can also be enabled later on from your profile settings.',
  twoFactorSuccessfullyEnabledText: 'Two-factor authentication is successfully enabled!',
  goToDashboard: 'Continue to dashboard',
  twoFactorNotEnabled: 'You haven’t yet enabled two-factor authentication',
  twoFactorDisable: 'Are you sure you want to disable two-factor authentication?',
  addExtraSecurityText: 'Add an extra security layer to your account by setting up the two-factor authentication',
  onDisableReducesSecurityText: 'Remember that removing the authentication will reduce your user account security',
  disableMfaButton: 'Yes, I want to disable it',
  verifyAndDisableMfaButton: 'Verify and disable authentication',
  verifyDisableHeaderText: 'Verify disabling with two-factor authentication',
  openTwoFactorAuthApp: 'Open your two-factor authentication app to view the authentication code',
  twoFactorIsDisabledText: 'Two-factor authentication is now disabled!',
  returnToProfilePageButton: 'Return to profile page',
  input6digitFieldError: 'Code must be 6 digit long',
  inputFieldNumbersError: 'Only numbers are allowed',
  contactAdmin:
    'If you want to disable two-factor authentication for your account, please send an email at api-support@kone.com',
}

export const MFALoginStrings = {
  twoFactorText: 'Two-factor authentication',
  enter6digitCode: 'Enter 6-digit code',
  openAuthAppText: 'Open your authentication app to view the authentication code.',
  verifyButtonText: 'verify',
}

const contactSupport = 'Please contact api-support@kone.com.'
export const MFAErrorStrings = {
  invalidCode: 'Invalid code. Please provide a valid 6 digit code.',
  internalServerError: 'There is some trouble with the service while authenticating.' + contactSupport,
  invalidParams: 'Invalid parameters are passed to service. ' + contactSupport,
  invalidUserPoolConfiguration: 'Invalid user pool configuration. ' + contactSupport,
  userNotAuthorized: 'User is not authorized to perform this action. ' + contactSupport,
  passwordResetNeeded: 'Password reset is required.',
  resourceNotFound: 'Resource not found. ' + contactSupport,
  mfaNotEnabled: 'Multi factor authentication is not enabled in the service. ' + contactSupport,
  tooManyAttempts: 'Too many attempts made. Please try again later.',
  userNotConfirmed: 'User is not confirmed. Please confirm your email',
  userNotFound: 'User is not found. ' + contactSupport,
}
