import React, { FC, ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTransition, animated } from 'react-spring'
import { Overlay, PanelContainer, Header, BackButtonContainer, Title, Content } from './Styles'
import BackButton from '../BackButton'

const DEFAULT_WIDTH = '800px'
const ANIMATION_DURATION_MLS = 300

type Props = {
  children: ReactNode
  isOpen: boolean
  title: string
  showBackButton?: boolean
  backButtonId?: string
  width?: string
  stacked?: boolean
  onClose: () => void
}

const SidePanel: FC<Props> = ({
  children,
  isOpen,
  title,
  showBackButton,
  backButtonId = 'panel-close-button-js',
  width = DEFAULT_WIDTH,
  stacked,
  onClose,
}) => {
  const [isShowOverlay, setIsShowOverlay] = useState(false)
  const [mountingPoint, setMountingPoint] = useState<HTMLElement | null | undefined>()

  useEffect(() => {
    const m = document.getElementById('side-panel-mounting-point')

    if (!m) {
      return console.warn('Cannot get SidePanel mounting point')
    }

    setMountingPoint(m)

    if (isOpen) {
      setIsShowOverlay(true)
      // prevent background scrolling when modal is open
      document.body.style.overflow = 'hidden'
    } else if (isShowOverlay && !isOpen) {
      // hide overlay after panel hiding animation is finished
      const removeOverlayTimeout = setTimeout(() => {
        // set body back to scrollable state when non-stacked panel is closed
        if (!stacked) {
          document.body.style.overflow = 'unset'
        }
        setIsShowOverlay(false)
      }, ANIMATION_DURATION_MLS)

      return () => {
        clearTimeout(removeOverlayTimeout)
      }
    }

    return () => {
      if (!stacked) {
        // set body back to scrollable state when non-stacked side panel is unmounted
        document.body.style.overflow = 'unset'
      }
    }
  }, [isOpen])

  const transitions = useTransition(isOpen, {
    from: { opacity: 0, transform: `translate3d(${width}, 0, 0)` },
    enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
    leave: { opacity: 0, transform: `translate3d(${width}, 0, 0)` },
    config: { duration: ANIMATION_DURATION_MLS },
  })

  if (!mountingPoint) {
    return null
  }

  return createPortal(
    <Overlay show={isShowOverlay} transparent={stacked}>
      {transitions(
        (styles, item) =>
          item && (
            <animated.div style={styles}>
              <PanelContainer width={width}>
                <Header>
                  {showBackButton && (
                    <BackButtonContainer>
                      <BackButton id={backButtonId} onClick={onClose} />
                    </BackButtonContainer>
                  )}
                  <Title>{title}</Title>
                </Header>
                <Content>{children}</Content>
              </PanelContainer>
            </animated.div>
          )
      )}
    </Overlay>,
    mountingPoint
  )
}

export default SidePanel
