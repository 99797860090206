export const ChangePasswordStrings = {
  changePasswordError: 'Change password error',
  oldPassword: 'Old password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm new password',
  passwordsNotMatchError: 'The passwords do not match',
  updatePassword: 'Update password',
  passwordUpdatedSuccess: 'Password is updated',
  returnToDashboardButton: 'Return to dashboard',
  oldPasswordTooShort: 'Old password must be at least 6 characters long',
  oldPasswordNotTrimmed: 'Old password must not begin or end with whitespace characters.',
  notAuthorizedException: 'Incorrect old password.',
}

export const MyProfileStrings = {
  myProfile: 'My profile',
  profileDetails: 'Profile details',
  memberDetails: `Member's details`,
  change: 'Change',
  changePassword: 'Change password',
  organizations: 'Organizations',
  deleteAccount: 'Delete',
  modalTitle: 'Please note!',
  confirmDeleteAccountButton: 'Yes, delete account',
  goBackButton: `No, let's go back`,
  okGoBackButton: `Ok, let's go back`,
  successDeletedTitleMessage: 'Your account has been successfully deleted',
  successDeletedBodyMessage:
    'Also your personal applications have been removed from the service. To recover you account contact api-support@kone.com',
  returnToHomePageButton: 'Ok, return to home page',
  confirmAccountDeleteTopMessage: 'Are you sure you want to delete your account?',
  confirmAccountDeleteMiddleMessage:
    'By deleting your account, also your personal applications are removed from the service. Access to any other organization applications is also removed.',
  acknowledgement:
    'I acknowledge this, after 7 day recoverable period I will permanently lose access to all organization resources and applications',
  lastMember: 'Seems that you are the last member in organizations:',
  removeApplications:
    'Before you can delete your account, you’ll need to remove all active applications from your organizations.',
  internalError: 'Refresh the page and try again.',
  accountSettings: 'Account settings',
  disableMFAButton: 'disable',
  enableMFAButton: 'enable',
  twoFactorAuthHeaderText: 'Two-factor authentication',
  AccountEnabledWith2FAText: 'The account is protected with two-factor authentication!',
  ProtectAccountWith2FAText: 'Protect your account with two-factor authentication!',
}
