import styled from 'styled-components'
import { color, size } from '../../utils/styles'

export const Overlay = styled.div<{ show: boolean; transparent?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ transparent }) => (transparent ? 'transparent' : 'rgba(0, 0, 0, 0.85)')};
  z-index: 2;
`

export const PanelContainer = styled.div<{ width: string }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 100;
  background-color: ${color.gray80};
  width: ${({ width }) => width};
  overflow-y: auto;

  @media (max-width: ${size.medium}) {
    width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 240px;
  background-color: ${color.gray90};

  @media (max-width: ${size.medium}) {
    height: 160px;
  }
`

export const BackButtonContainer = styled.div`
  position: absolute;
  margin-left: 48px;

  @media (max-width: ${size.medium}) {
    margin-left: 16px;
  }
`

export const Title = styled.div`
  color: ${color.white};
  font-size: 48px;
  text-align: center;
  width: 100%;

  @media (max-width: ${size.medium}) {
    font-size: 32px;
    margin-left: 74px;
    margin-right: 58px;
    line-height: 1.2;
  }
`

export const Content = styled.div`
  padding: 50px 48px;
  font-size: 16px;
  color: ${color.gray20};

  @media (max-width: ${size.medium}) {
    padding: 50px 10px;
  }
`
