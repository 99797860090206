import React, { FC } from 'react'
import { ApiOverviewContentSection, CardContainer, ImageContainer, SvgBottomText, ContentHeading } from './Styles'
import { Row, Col } from 'antd'

type Props = {
  contentDetail: any
}
const APICardContent: FC<Props> = ({ contentDetail }) => {
  return (
    <CardContainer>
      <Row justify="space-around">
        <Col xs={2} sm={16} md={12} lg={8} xl={8}>
          <ImageContainer>
            {contentDetail.svgImage}
            <SvgBottomText>{contentDetail.imageBottomTittle}</SvgBottomText>
          </ImageContainer>
        </Col>
        <Col xs={2} sm={4} md={6} lg={8} xl={14}>
          <ContentHeading>{contentDetail.tittle}</ContentHeading>
          <ApiOverviewContentSection>
            {contentDetail.content.map((details: any, i: number) => (
              <>
                <span key={`${i} text`}>{details.description}</span>
                <br />
              </>
            ))}
          </ApiOverviewContentSection>
        </Col>
      </Row>
    </CardContainer>
  )
}

export default APICardContent
