import styled, { css } from 'styled-components'
import { color, size } from '../utils/styles'
import { H1 as SharedH1, H4 as SharedH4, H6 as SharedH6, P as SharedP } from '../components'

const modalColors = css`
  h1,
  h4,
  h6,
  li,
  p {
    color: ${color.gray90};
  }
`

export const Container = styled.div<{ isModal?: boolean }>`
  ${({ isModal }) => isModal && modalColors}
`

export const H1 = styled(SharedH1)<{ isModal?: boolean }>`
  margin-top: ${(props) => (props.isModal ? '2.5rem' : '4.5rem')};
  margin-bottom: ${(props) => (props.isModal ? '3rem' : '1.5rem')};

  @media (max-width: ${size.large}) {
    margin-top: 2.5rem;
    line-height: 1.2;
  }
`

export const H4 = styled(SharedH4)`
  margin-top: 3rem;
  font-weight: bold;
`

export const H6 = styled(SharedH6)`
  font-weight: bold;
`

export const P = styled(SharedP)`
  line-height: 1.5;
  margin-bottom: 0.5rem;

  &.translation {
    margin-top: 0;
  }

  &.info {
    margin-top: 0.5rem;
  }
`

export const Line = styled.hr`
  width: 96px;
  border: 2px solid ${color.gray10};
  margin-bottom: 4rem;
`
