import { ORGANIZATIONS_PATH, RESOURCES_PATH } from '../api/organizations'
import { Resource, ResourceId, ResourceInfo } from '../models/resource'
import { BackendTokenAPI } from './backend'
import { API } from './API'
import axios from 'axios'

const DEVICE_SEARCH_PATH = '/admin/devices'
const CONTRACT_EXPIRY_PATH = '/api/admin/contracts'

const ADMIN_SCOPE = ['application/inventory']

const queryNames = {
  GET_BY_ORGANIZATION_ID: 'resources-get-by-organization-id-',
  SEARCH_DEVICE_BY_THING: 'device-search-by-thing-name-',
}

const getByOrganizationId = async (organizationId: number): Promise<any> => {
  return await API.get(`${ORGANIZATIONS_PATH}/${organizationId}/owns`)
}

const deviceSearchByThingName = async (thingName: string): Promise<any> => {
  const params = {
    body: {
      queryName: thingName,
    },
  }
  return await API.post(`${DEVICE_SEARCH_PATH}`, params)
}

const getResourcesByTypeAndId = async (type: string, uniqueId: string): Promise<any> => {
  return await API.get(`${RESOURCES_PATH}/${encodeURIComponent(type)}/${encodeURIComponent(uniqueId)}`)
}
const getOrgNameByResourceTypeAndId = async (type: string): Promise<any> => {
  return await API.get(`${RESOURCES_PATH}/${encodeURIComponent(type)}`, {
    queryStringParameters: {
      includeOrg: 'true',
    },
  })
}

export type ContractResponse = {
  contractsFound: boolean
  contractData: string[]
}

const getResourceContractExpiry = async (resourceType: string, resourceId: string): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const body = {
    equipment: `${resourceType}:${resourceId}`,
  }

  const method = 'POST'
  const response = await axios(`${CONTRACT_EXPIRY_PATH}`, {
    method,
    headers: authHeader,
    data: JSON.stringify(body),
  })

  return response.data as ContractResponse
}

const link = async (organizationId: number, data: Resource): Promise<any> => {
  const parameters = {
    body: data,
  }
  return await API.post(`${ORGANIZATIONS_PATH}/${organizationId}/owns`, parameters)
}

const createResource = async (data: { id: ResourceId; info?: ResourceInfo }): Promise<any> => {
  const parameters = {
    body: data,
  }
  return await API.post(`${RESOURCES_PATH}`, parameters)
}

const createResourceWithParent = async (data: any): Promise<any> => {
  const parameters = {
    body: data,
  }
  return await API.post(`${RESOURCES_PATH}`, parameters)
}

const updateResource = async (data: any): Promise<any> => {
  const parameters = {
    body: data,
  }
  return await API.patch(`${RESOURCES_PATH}`, parameters)
}

type RemoveResourceParams = {
  organizationId: number
  data: any
}

const remove = async (params: RemoveResourceParams): Promise<any> => {
  const parameters = {
    body: params.data,
  }
  return await API.remove(`${ORGANIZATIONS_PATH}/${params.organizationId}/owns`, parameters)
}

export const ResourcesAPI = {
  queryNames,
  getByOrganizationId,
  link,
  remove,
  createResource,
  createResourceWithParent,
  getResourcesByTypeAndId,
  getResourceContractExpiry,
  getOrgNameByResourceTypeAndId,
  updateResource,
  deviceSearchByThingName,
}
