import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogMay24 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        May 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        May release notes summarizes latest changes for Service Info API and API Portal documentation fix.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              <q>workTime</q> field from
              <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}> Service Info Webhook API</Link> is now available
              as number.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              <q>previousState</q> field added in
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentAvailabilityByIds'}>
                {' '}
                Equipment Availability REST API
              </Link>{' '}
              documentation.
            </li>
            <li>
              Passenger Guidance details are removed from <Link to={Paths.Glossary.path}> Glossary</Link> section as
              this feature is not available with V2 APIs.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
