import { TitleWrapperProps } from '../components/TitleWrapper'

export const PrivacyStatementPage = {
  title: 'Privacy Statement',
}

export const PrivacyStatementStrings = {
  privacyStatement: 'privacy statement',
  pleaseSeePrivacyStatement:
    'Please note, that when you submit this form, we will be collecting your personal data. For more information about personal data processing, please see our ',
}

export const SEOProps: TitleWrapperProps = {
  title: PrivacyStatementPage.title,
  metaTags: [],
}
