import React, { FC } from 'react'
import styled from 'styled-components'
import { Tabs } from 'antd'

import { color } from '../../../shared/utils/styles'

export const StyledTabs = styled(Tabs)`
  /* border under the tabs */
  .ant-tabs-nav::before {
    border-bottom: 1px solid #3d464c;
  }

  /* tab buttons */
  .ant-tabs-tab-btn {
    color: ${color.gray20};
  }

  /* selected tab color */
  .ant-tabs-tab-btn[aria-selected='true'] {
    color: ${color.gray20};
    font-weight: bold;
  }

  .ant-tabs-tab-active {
    color: ${color.gray20};
  }
`

interface TabbedCodeContainerProps {
  onChange?: () => void
}

export const TabbedCodeContainer: FC<TabbedCodeContainerProps> = ({ children, onChange }) => (
  <StyledTabs defaultActiveKey="1" onChange={onChange}>
    {children}
  </StyledTabs>
)
