import styled from 'styled-components'
import { color } from '../../../../shared/utils/styles'
import { H6 } from '../../../../shared/components'

export const DownloadButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2%;
`

export const DownloadButtonName = styled.div`
  padding: 1%;
  border-bottom-style: solid;
  cursor: pointer;
  border-bottom-color: transparent;
  &:hover {
    border-bottom-style: solid;
    border-bottom-color: ${color.blueBase2};
  }
`
export const DownloadTittle = styled(H6)``
