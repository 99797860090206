import Modal from 'antd/lib/modal/Modal'
import React, { FC, ReactNode } from 'react'
import { ModalGlobalStyle } from './Styles'

type Props = {
  title: string
  width?: number
  visible: boolean
  onOk?: () => void
  onCancel?: () => void
  confirmLoading?: boolean
  closable?: boolean
  maskClosable?: boolean
  keyboard?: boolean
  footer?: ReactNode
  content?: ReactNode
}

const StyledModal: FC<Props> = ({
  title,
  width,
  visible,
  onOk,
  onCancel,
  confirmLoading,
  closable,
  maskClosable,
  keyboard,
  footer,
  children,
}) => {
  return (
    <>
      <ModalGlobalStyle />
      <Modal
        title={title}
        width={width}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        focusTriggerAfterClose={false}
        closable={closable}
        maskClosable={maskClosable}
        keyboard={keyboard}
        footer={footer}
        style={{ top: '25vh' }}
      >
        {children}
      </Modal>
    </>
  )
}

export default StyledModal
