import React, { FC } from 'react'
import {
  CardContainer,
  ContentHeading,
  TestForSolutionValidationSubTittle,
  GuidedTestSvg,
  SelfIllustrationSvg,
  CardInnerContainer,
  KnowMoreButton,
  PilotTestSvg,
  PreProductionTestImage,
  PopUpContainer,
  NewPopUpTextContainer,
} from './Styles'
import { sandboxSelfTest, preProductionSelfTest, preProductionGuideTest, selfPilotTest } from './Common'
import Modal from '../../../../shared/components/ModalPopUp'
import { Row, Col } from 'antd'

const TestForSolutionValidation: FC = () => {
  const [openSelfPilotTest, setOpenSelfPilotTest] = React.useState(false)
  const [openPreProductionTest, setOpenPreProductionTest] = React.useState(false)
  const [openSandboxSelfTest, setOpenSandboxSelfTest] = React.useState(false)
  const [openSelfGuidedTest, setOpenSelfGuidedTest] = React.useState(false)

  const closeSelfPilotTest = () => {
    setOpenSelfPilotTest(false)
  }

  const openSelfPilotTestPopUp = () => {
    setOpenSelfPilotTest(true)
  }

  const openSelfGuidedTestPopUp = () => {
    setOpenSelfGuidedTest(true)
  }

  const closeSelfGuidedTest = () => {
    setOpenSelfGuidedTest(false)
  }

  const openPreProductionTestPopUp = () => {
    setOpenPreProductionTest(true)
  }

  const closePreProductionTest = () => {
    setOpenPreProductionTest(false)
  }

  const openSandboxTestPopup = () => {
    setOpenSandboxSelfTest(true)
  }

  const closeSandboxTestPopup = () => {
    setOpenSandboxSelfTest(false)
  }

  return (
    <>
      <CardContainer>
        <Row justify="center" align="middle">
          <Col xs={2} sm={4} md={6} lg={8} xl={24}>
            <ContentHeading>Tests for Solution validation</ContentHeading>
            <CardContainer>
              {`As a user, there are serval tests available to help you gain a deeper understanding of the API's capabilities. 
              These tests are designed to guide you through multiple steps and provide you with a comprehensive overview. 
              You can explore the API's functionality by conducting basic tests to familiarize`}
            </CardContainer>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <CardInnerContainer xs={2} sm={4} md={6} lg={8} xl={10}>
            <ContentHeading>Sandbox Self-Test</ContentHeading>
            <SelfIllustrationSvg />
            <KnowMoreButton onClick={openSandboxTestPopup} type="primary">
              Know More
            </KnowMoreButton>
          </CardInnerContainer>
          <CardInnerContainer xs={2} sm={4} md={6} lg={8} xl={10}>
            <ContentHeading>Preproduction Self-Test</ContentHeading>
            <PreProductionTestImage />
            <KnowMoreButton onClick={openPreProductionTestPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardInnerContainer>
        </Row>
        <Row justify="center" align="middle">
          <CardInnerContainer xs={2} sm={4} md={6} lg={8} xl={10}>
            <ContentHeading>Preproduction Guided Test</ContentHeading>
            <GuidedTestSvg />
            <KnowMoreButton onClick={openSelfGuidedTestPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardInnerContainer>
          <CardInnerContainer xs={2} sm={4} md={6} lg={8} xl={10}>
            <ContentHeading>Self-Pilot test</ContentHeading>
            <PilotTestSvg />
            <KnowMoreButton onClick={openSelfPilotTestPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardInnerContainer>
        </Row>
      </CardContainer>
      <Modal key="self-pilot-test" title={'Self-Pilot test'} isOpen={openSelfPilotTest} onClose={closeSelfPilotTest}>
        <PopUpContainer>
          <PilotTestSvg />
          <NewPopUpTextContainer>
            {selfPilotTest.content.map((details: any, i: number) => (
              <>
                <div key={`${i}-text-sandbox`}>{details.description}</div>
                <br />
              </>
            ))}
          </NewPopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="preproduction-guided-test"
        title={'Preproduction Guided Test'}
        isOpen={openSelfGuidedTest}
        onClose={closeSelfGuidedTest}
      >
        <PopUpContainer>
          <GuidedTestSvg />
          <NewPopUpTextContainer>
            {preProductionGuideTest.content.map((details: any, i: number) => (
              <>
                <div key={`${i}-text-sandbox`}>{details.description}</div>
                <br />
              </>
            ))}
          </NewPopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="production-self-test"
        title={'Pre production Self-Test'}
        isOpen={openPreProductionTest}
        onClose={closePreProductionTest}
      >
        <PopUpContainer>
          <PreProductionTestImage />
          <NewPopUpTextContainer>
            {preProductionSelfTest.content.map((details: any, i: number) => (
              <>
                <div key={`${i}-text-sandbox`}>{details.description}</div>
                <br />
              </>
            ))}
          </NewPopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="sandbox-self-test"
        title={'Sandbox Self-Tests'}
        width={'800px'}
        height={'300px'}
        isOpen={openSandboxSelfTest}
        onClose={closeSandboxTestPopup}
      >
        <PopUpContainer>
          <SelfIllustrationSvg />
          <NewPopUpTextContainer>
            {sandboxSelfTest.content.map((details: any, i: number) => (
              <>
                <div key={`${i}-text-sandbox`}>{details.description}</div>
                <br />
              </>
            ))}
          </NewPopUpTextContainer>
        </PopUpContainer>
      </Modal>
    </>
  )
}

export default TestForSolutionValidation
