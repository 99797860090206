export const ErrorPageStrings = {
  title: 'Sorry, something went wrong',
  tryReload: 'Refresh the page or try later. If that doesn’t help, ',
  contactApiSupport: 'contact KONE API Support',
  and: 'and',
  signOut: 'sign out',
  defaultServiceBreakMessage: 'KONE API Portal is under maintenance and will be back online shortly.',
  tryAgain: 'Refresh the page and try again.',
  ApiSupport: 'KONE API Support',
  otherOption: 'If that doesn’t help contact',
}
