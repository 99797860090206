import styled from 'styled-components'
import { color } from '../../shared/utils/styles'

export const MenuContainer = styled.div`
  .ant-menu-root.ant-menu-inline,
  .ant-menu-submenu.ant-menu-submenu-inline {
    color: ${color.gray20};
    background-color: transparent;
    border-right-width: 0;
  }

  .ant-menu-item {
    background-color: transparent;
    margin: 0;

    a {
      color: ${color.gray20};

      &:hover {
        color: ${color.lightBlue};
      }
    }

    &:after {
      left: 4px;
      right: initial;
      border-right-width: 4px;
    }
  }

  .ant-menu-submenu {
    background-color: transparent;
  }

  .ant-menu .ant-menu-item-selected {
    background-color: transparent;

    a {
      color: ${color.white};
      &:hover {
        color: ${color.lightBlue};
      }
    }
  }

  .ant-menu > .ant-menu-item-divider {
    background-color: ${color.gray70};
    margin: 14px 16px 14px 24px;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: transparent;

    > .ant-menu-item,
    > .ant-menu-submenu {
      background-color: transparent;
      padding-left: 48px !important;
    }

    > .ant-menu-item-selected {
      background-color: transparent;
    }
  }

  .ant-menu-submenu-selected {
    color: ${color.white};
    > div > svg path {
      fill: ${color.white};
    }
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    padding-left: 24px !important;
    width: 100%;
    margin: 0;
    height: 48px;
    line-height: 48px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      background-image: none;
      background-color: ${color.gray20};
    }

    > svg {
      margin-right: 10px;

      path {
        transition: fill 0.3s ease;
      }
    }

    &:hover {
      color: ${color.lightBlue};

      .ant-menu-submenu-arrow:before,
      .ant-menu-submenu-arrow:after {
        background-image: none;
        background-color: ${color.lightBlue};
      }

      > svg path {
        fill: ${color.lightBlue};
      }
    }

    &:active {
      background-color: transparent;
    }
  }

  .ant-menu-submenu .ant-menu-submenu > .ant-menu-submenu-title {
    padding-left: 0px !important;
  }

  .ant-menu-submenu .ant-menu-submenu .ant-menu-item {
    padding-left: 24px !important;
  }
`
