import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Title, Text, ButtonContainer, Icon, ArrowIcon, Circle } from './Styles'
import isInternalURL from '../../utils/isInternalURL'
import ReadMoreButton from '../ReadMoreButton'
import { NavHashLink } from 'react-router-hash-link'

type Props = {
  title: string
  body: string
  icon?: string
  iconDimension: string
  button?: boolean
  image?: string
  targetPath: string
  height: string
  onClick?: () => void
  id: string
}

const CardContent: FC<Props> = ({ title, body, icon, iconDimension, button, image, targetPath, height, id }) => {
  const history = useHistory()

  const navigateTo = () => {
    const isInternalPath = isInternalURL(targetPath)
    if (isInternalPath) {
      history.push({ pathname: targetPath + `/`, hash: `${id}` })
    } else {
      window.open(targetPath)
    }
  }

  return (
    <Container id={id} height={height} sm={24} onClick={navigateTo} image={image}>
      <Title className="card-title">{title}</Title>
      <Text id={id} className="card-text">
        {body}
      </Text>
      {icon && (
        <Circle className="card-icon">
          <Icon icon={icon} iconDimension={iconDimension} />
        </Circle>
      )}
      {button !== false && (
        <ButtonContainer className="card-button">
          <NavHashLink smooth to={`/kone-apis/#${id}`}>
            <ReadMoreButton />
          </NavHashLink>
        </ButtonContainer>
      )}
      <NavHashLink smooth to={`/kone-apis/#${id}`}>
        <ArrowIcon />
      </NavHashLink>
    </Container>
  )
}

export default CardContent
