import React, { FC } from 'react'
import {
  InputName,
  ResetIcon,
  IconContainer,
  CenterLoader,
  CenterContainer,
  StyledTooltipReset,
  CopyIconStyle,
  StyledTooltip,
  FormLabelSecret,
} from './Styles'
import LoadingComponent from '../../../shared/components/LoadingAnimated'
import { Tooltip } from 'antd'
import { AppSecret } from '../../../shared/models/application'
import { SharedApplicationStrings } from '../../../shared/strings/ApplicationsContent'

type Props = {
  handleSecretGeneration: () => void
  loadingSecret: boolean
  isSecretLoadingTimerFinished: boolean
  secretGenerated: AppSecret
  isTooltipVisible: boolean
  handleOnCopyClick: (fieldDefinition: string) => void
  isCopySecretTooltipVisible: boolean
  resetTooltipVisibility: () => void
}

const EditAppSecret: FC<Props> = ({
  handleSecretGeneration,
  loadingSecret,
  isSecretLoadingTimerFinished,
  secretGenerated,
  isTooltipVisible,
  handleOnCopyClick,
  isCopySecretTooltipVisible,
  resetTooltipVisibility,
}) => {
  if (secretGenerated && isSecretLoadingTimerFinished) {
    resetTooltipVisibility()
    return (
      <>
        <FormLabelSecret>{SharedApplicationStrings.clientSecret}</FormLabelSecret>
        <CenterContainer>
          <Tooltip
            placement="left"
            title={SharedApplicationStrings.reset}
            visible={isTooltipVisible}
            getPopupContainer={(trigger) => {
              return trigger
            }}
          >
            <StyledTooltipReset>
              <InputName value={secretGenerated.clientSecret} type="text" disabled readOnly />
            </StyledTooltipReset>
          </Tooltip>
          <IconContainer>
            <Tooltip
              placement="right"
              title={SharedApplicationStrings.copied}
              visible={isCopySecretTooltipVisible}
              getPopupContainer={(trigger) => {
                return trigger
              }}
            >
              <StyledTooltip>
                <CopyIconStyle onClick={() => handleOnCopyClick('secret')} />
              </StyledTooltip>
            </Tooltip>
          </IconContainer>
        </CenterContainer>
      </>
    )
  }

  if (loadingSecret || !isSecretLoadingTimerFinished) {
    return (
      <CenterContainer>
        <CenterLoader>
          <LoadingComponent />
        </CenterLoader>
      </CenterContainer>
    )
  }
  return (
    <>
      {!loadingSecret && isSecretLoadingTimerFinished && !secretGenerated && (
        <>
          <FormLabelSecret>{SharedApplicationStrings.clientSecret}</FormLabelSecret>
          <CenterContainer>
            <InputName disabled value={Array(37).join('\u2022')} type="text" readOnly />
            <IconContainer>
              <ResetIcon id="reset-secret-button-js" onClick={handleSecretGeneration} />
            </IconContainer>
          </CenterContainer>
        </>
      )}
    </>
  )
}

export default EditAppSecret
