import React from 'react'
import ReactDOM from 'react-dom'
import { Amplify } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import './index.less'
import App from './App'
import { REST_API_NAME, DOWNLOAD_API_NAME, UPDATE_HEADERS_AMPLIFY_PROP } from './shared/api/constants'
import { readCurrentOrganization } from './shared/utils/storageUtils'
import config from './shared/utils/awsConfig'
import initReportingObserver from './shared/utils/initReportingObserver'

const basePath = new URL(window.location.href).origin

const uiPath = `${basePath}/api-portal`

const getJwtToken = async () => {
  return (await Auth.currentSession()).getAccessToken().getJwtToken()
}

const getCurrentOrganizationExternalId = () => {
  const currentOrganization = readCurrentOrganization()
  return currentOrganization?.externalId
}

const restAPI = {
  name: REST_API_NAME,
  endpoint: `${basePath}/api`,
  [UPDATE_HEADERS_AMPLIFY_PROP]: async () => {
    const headers = {
      Authorization: 'Bearer ' + (await getJwtToken()),
    }

    const organizationId = getCurrentOrganizationExternalId()
    if (organizationId) {
      headers['x-org'] = organizationId
    }

    return headers
  },
}

const downloadAPI = {
  name: DOWNLOAD_API_NAME,
  endpoint: `${basePath}/download`,
  [UPDATE_HEADERS_AMPLIFY_PROP]: async () => {
    const headers = {
      Authorization: 'Bearer ' + (await getJwtToken()),
    }
    return headers
  },
}

const amplifyConfig = {
  Auth: {
    region: config.userPoolId.split('_')[0],
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    oauth: {
      domain: config.cognito,
      scope: ['openid'],
      redirectSignIn: `${uiPath}/login`,
      redirectSignOut: `${uiPath}/logout`,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [restAPI, downloadAPI],
  },
}

initReportingObserver()
Amplify.configure(amplifyConfig)
ReactDOM.render(<App />, document.getElementById('root'))
