import React, { FC } from 'react'
import { ElevatorCallApiV2 } from '../../../shared/strings/KONEAPIsTextContent'
import Paths from '../../../Dashboard/Paths'
import KONEAPIPage from '../KONEAPIPage'
import { PathNames } from '../../../shared/strings/PathsContent'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize
const apis = [Paths.AuthenticationAPI, Paths.BuildingAPI, Paths.ElevatorWebsocketAPI]
const apis_v2 = [Paths.AuthenticationAPIV2, Paths.ElevatorWebsocketAPIV2]

const ElevatorCallAPIPage: FC = () => {
  return (
    <>
      <KONEAPIPage title={PathNames.elevatorCallAPIV2} apis={apis_v2}>
        <div dangerouslySetInnerHTML={{ __html: sanitizer(ElevatorCallApiV2.body) }} />
      </KONEAPIPage>
    </>
  )
}

export default ElevatorCallAPIPage
