import React from 'react'
import { H4, P, UL, OL } from '../../../../shared/components/Typography'

export const ReleaseNotesLogAugust4 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        August 2023
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        August release notes summarizes updates in API Portal along with removal for deprecated APIs.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>serviceContractNumber added to service order details v2 Documentation guide</li>
            <li>Deprecated APIs are removed from API portal</li>
          </UL>

          <li style={{ marginTop: '30px' }}>Equipment Status API 2 and Service Info 2 API</li>
          <UL>
            <li style={{ marginTop: '30px' }}>Email content is updated with reason when the webhook is disabled.</li>
          </UL>
        </OL>
      </P>
    </>
  )
}
