import React, { FC, useEffect, useState } from 'react'
import { PartnerStrings } from '../../../../shared/strings/PartnersContent'
import Modal from '../../../../shared/components/ModalPopUp'
import { ButtonContainer, SaveButton, CancelButton, FormButton, AddMoreVideo, StyledTabs } from './Styles'
import { Container, FormLabel, InputName } from '../EditSolutionInfo/Styles'
import { TabsContainer, TitleLabel, RemoveIconPartner } from '../../AddNewParnter/IntegratorLinks/Styles'
import { Video, Document } from '../../Models'
import { ErrorMessage, Toast } from '../../../../shared/components'
import { Col, Row } from 'antd'
import { SalesPageAPI } from '../../../../shared/api/salespage'
import { PARTNER_WEBSITE_LINK_REGX, YOUTUBE_REGEX } from '../../../../shared/constants/auth'

const { TabPane } = StyledTabs

interface EditIntegratorLinksProps {
  isEditIntegratorLinkOpen: boolean
  closeIntegratorLink: () => void
  integratorInfo: any
  partnerId: string
  invalidateIntegratorData: () => void
}

const EditIntegratorLinks: FC<EditIntegratorLinksProps> = (props) => {
  const { isEditIntegratorLinkOpen, closeIntegratorLink, integratorInfo, partnerId, invalidateIntegratorData } = props
  const [isShowSuccessView, setIsShowSuccessView] = useState(false)
  const [isUrl, setIsUrlMessage] = useState(false)
  const [isValidYoutubeUrl, setIsValidYoutubeMessage] = useState(false)
  const [isValidDocUrl, setIsValidDocMessage] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean | false>(false)
  const [err, setErr] = useState<string[]>([])
  const [errDoc, setErrDoc] = useState<string[]>([])

  const defaultVideo: Video = {
    fileName: '',
    videoUrl: '',
  }

  const defaultDocument: Document = {
    fileName: '',
    documentUrl: '',
  }

  const [videoList, setVideoList] = useState<Video[]>([])
  const [docList, setDocList] = useState<Document[]>([])
  const [button, setButtonVisible] = useState(true)

  useEffect(() => {
    const videoLinks: Video[] = []
    const docLinks: Document[] = []

    integratorInfo?.videos.map((data: Video) => {
      videoLinks?.push({
        fileName: data?.fileName,
        videoUrl: data?.videoUrl,
      })
    })
    integratorInfo?.documents.map((data: Document) => {
      docLinks?.push({
        fileName: data?.fileName,
        documentUrl: data?.documentUrl,
      })
    })

    if (videoLinks.length === 0) {
      setVideoList([{ ...defaultVideo }])
    }

    if (docLinks.length === 0) {
      setDocList([{ ...defaultDocument }])
    }

    videoLinks.length > 0 && setVideoList(videoLinks)
    docLinks.length > 0 && setDocList(docLinks)
  }, [])

  const onClickAddMoreVideo = () => {
    setButtonVisible(false)
    setVideoList((preState: any) => [...preState, { ...defaultVideo }])
  }

  const onRemoveButtonClickVideo = (index: number) => {
    const tempLinks = [...videoList]
    tempLinks.splice(index, 1)
    setVideoList(tempLinks)
  }

  function testYoutubeRegex(value: string) {
    const regex = YOUTUBE_REGEX.test(value)
    return regex
  }

  const onInputChangeVideo = (e: any, index: number, key: string) => {
    setIsValidYoutubeMessage(false)
    const { value } = e.target

    const tempVideos: Video[] = [...videoList]
    tempVideos[index] = { ...tempVideos[index], [key]: value }

    // validate correct youtube url
    if (tempVideos[index].videoUrl && tempVideos[index].videoUrl.length !== 0) {
      if (!testYoutubeRegex(tempVideos[index].videoUrl)) {
        setIsValidYoutubeMessage(true)
        err[index] = 'error'
      } else {
        err[index] = ''
      }
    }
    setVideoList(tempVideos)
  }

  const getIntegratorComponent = (video: Video, index: number) => {
    return (
      <>
        <div style={{ paddingLeft: '25px', paddingBottom: '15px' }}>
          <Row gutter={8} wrap={false}>
            <Col span={10}>
              <FormLabel>{PartnerStrings.fileLabel}</FormLabel>
              <InputName
                id={`fileName_${index}`}
                placeholder={'Enter File Name'}
                value={video?.fileName}
                onChange={(e: any) => onInputChangeVideo(e, index, 'fileName')}
              />
            </Col>
            <Col style={{ marginLeft: '14px', marginRight: '14px' }} span={10}>
              <FormLabel>{PartnerStrings.urlLabel}</FormLabel>
              <InputName
                id={`videoUrl_${index}`}
                placeholder={PartnerStrings.enterVideoUrlPlaceHolder}
                value={video?.videoUrl}
                onChange={(e: any) => onInputChangeVideo(e, index, 'videoUrl')}
              />
              {isValidYoutubeUrl && err[index] === 'error' && (
                <ErrorMessage>{PartnerStrings.isValidYoutube}</ErrorMessage>
              )}
            </Col>
            <Col span={12}>
              <Row gutter={2}>
                <Col span={2}>
                  <FormButton
                    type="primary"
                    onClick={onClickAddMoreVideo}
                    disabled={
                      index + 1 < videoList.length ||
                      videoList.length > 4 ||
                      isValidYoutubeUrl ||
                      isVideoListEmpty(videoList)
                    }
                  >
                    <AddMoreVideo />
                  </FormButton>
                </Col>
                <Col span={3}>
                  <FormButton
                    type="primary"
                    onClick={() => onRemoveButtonClickVideo(index)}
                    disabled={videoList.length === 1}
                    style={{ marginLeft: '8px' }}
                  >
                    <RemoveIconPartner />
                  </FormButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  const onClickAddMoreDocument = () => {
    setButtonVisible(false)
    setDocList((preState: any) => [...preState, { ...defaultDocument }])
  }

  const onRemoveButtonClickDocument = (index: number) => {
    const tempLinks = [...docList]
    tempLinks.splice(index, 1)
    setDocList(tempLinks)
  }

  function testDocumentLinkRegex(value: string) {
    const regex = PARTNER_WEBSITE_LINK_REGX.test(value)
    return regex
  }

  const onInputChangeDocument = (e: any, index: number, key: string) => {
    setIsValidDocMessage(false)
    const { value } = e.target

    const tempDocs: Document[] = [...docList]
    tempDocs[index] = { ...tempDocs[index], [key]: value }

    // validate correct youtube url
    if (tempDocs[index].documentUrl && tempDocs[index].documentUrl.length !== 0) {
      if (!testDocumentLinkRegex(tempDocs[index].documentUrl)) {
        setIsValidDocMessage(true)
        errDoc[index] = 'error'
      } else {
        errDoc[index] = ''
      }
    }

    setDocList(tempDocs)
  }

  function isDocListEmpty(docList: Document[]) {
    for (let document = 0; document < docList.length; document++) {
      if (docList[document].fileName === '' || docList[document].documentUrl === '') {
        return true
      }
    }
    return false
  }

  //TODO:: to improvise this later
  function isAnythingIncomplete() {
    for (let document = 0; document < docList.length; document++) {
      if (!(docList[document].fileName && docList[document].documentUrl)) {
        if (document == 0 && docList[document].fileName === '' && docList[document].documentUrl === '') {
          // do not return false as it is needed for UI to show editable components
        } else {
          return true
        }
      }
    }
    for (let video = 0; video < videoList.length; video++) {
      if (!(videoList[video].fileName && videoList[video].videoUrl)) {
        if (video == 0 && videoList[video].fileName === '' && videoList[video].videoUrl === '') {
          // do not return false as it is needed for UI to show editable components
        } else {
          return true
        }
      }
    }
    return false
  }

  const getIntegratorDocumentComponent = (document: Document, index: number) => {
    return (
      <>
        <div style={{ paddingLeft: '25px', paddingBottom: '15px' }}>
          <Row gutter={8} wrap={false}>
            <Col span={10}>
              <FormLabel>{PartnerStrings.fileLabel}</FormLabel>
              <InputName
                id={`fileName_${index}`}
                placeholder={PartnerStrings.enterFileName}
                value={document?.fileName}
                onChange={(e: any) => onInputChangeDocument(e, index, 'fileName')}
              />
            </Col>
            <Col style={{ marginLeft: '14px', marginRight: '14px' }} span={10}>
              <FormLabel>{PartnerStrings.urlLabel}</FormLabel>
              <InputName
                id={`documentUrl_${index}`}
                placeholder={PartnerStrings.enterDocumentUrlPlaceHolder}
                value={document?.documentUrl.trim()}
                onChange={(e: any) => onInputChangeDocument(e, index, 'documentUrl')}
              />
              {isValidDocUrl && errDoc[index] === 'error' && (
                <ErrorMessage>{PartnerStrings.isValidDocUrl}</ErrorMessage>
              )}
            </Col>
            <Col span={12}>
              <Row gutter={2}>
                <Col span={2}>
                  <FormButton
                    type="primary"
                    onClick={onClickAddMoreDocument}
                    disabled={
                      index + 1 < docList.length || docList.length > 4 || isValidDocUrl || isDocListEmpty(docList)
                    }
                  >
                    <AddMoreVideo />
                  </FormButton>
                </Col>
                <Col span={3}>
                  <FormButton
                    type="primary"
                    onClick={() => onRemoveButtonClickDocument(index)}
                    disabled={docList.length === 1}
                    style={{ marginLeft: '8px' }}
                  >
                    <RemoveIconPartner />
                  </FormButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  async function onSubmit() {
    setIsLoading(true)

    const data = {
      document_object_id: {
        videos: isVideoListEmpty(videoList) === true ? [] : videoList,
        documents: isDocListEmpty(docList) === true ? [] : docList,
      },
      partnerStep: 4,
    }

    try {
      const res = await SalesPageAPI.editIntegrator(data, partnerId)
      setIsLoading(false)
      setIsUrlMessage(false)

      // when the url entered is invalid
      if (!res.ok) {
        setIsUrlMessage(true)
        return
      }

      setIsShowSuccessView(true)
      setTimeout(() => {
        closeIntegratorLink()
        invalidateIntegratorData()
      }, 2000)
    } catch (e: any) {
      setIsShowSuccessView(false)
      setIsUrlMessage(true)
    }
  }

  function isVideoListEmpty(videoList: Video[]) {
    for (let video = 0; video < videoList.length; video++) {
      if (videoList[video].fileName === '' || videoList[video].videoUrl === '') {
        return true
      }
    }
    return false
  }

  return (
    <>
      <Toast
        isVisible={isShowSuccessView && !isUrl}
        text={isShowSuccessView ? 'Links Updated Successfully' : 'Something went wrong'}
        onDismiss={() => setIsShowSuccessView(false)}
        type="success"
        allowWithTime={true}
      />
      <Toast
        isVisible={isUrl && !isShowSuccessView}
        text={PartnerStrings.fileNameError}
        onDismiss={() => setIsUrlMessage(false)}
        type="error"
        allowWithTime={true}
      />
      <Modal
        key="edit-integrator-links"
        isOpen={isEditIntegratorLinkOpen}
        title={PartnerStrings.editIntegratorLinks}
        onClose={closeIntegratorLink}
        width={'1200px'}
        height={'300px'}
      >
        <Container>
          <TabsContainer>
            <StyledTabs centered={true} defaultActiveKey={'video'} destroyInactiveTabPane={true}>
              <TabPane tab="Videos" key="video">
                <TitleLabel style={{ marginLeft: '50px' }}>{PartnerStrings.enterVideoUrl}</TitleLabel>
                {videoList?.map((value: Video, index: number) => getIntegratorComponent(value, index))}
              </TabPane>
              <TabPane tab="Documents" key="documents">
                <TitleLabel style={{ marginLeft: '50px' }}>{PartnerStrings.enterDocumentUrl}</TitleLabel>
                {docList?.map((value: Document, index: number) => getIntegratorDocumentComponent(value, index))}
              </TabPane>
            </StyledTabs>
          </TabsContainer>
        </Container>
        <ButtonContainer>
          <CancelButton onClick={closeIntegratorLink}>{PartnerStrings.cancel}</CancelButton>
          <SaveButton
            onClick={onSubmit}
            disabled={
              (isVideoListEmpty(videoList) === true && isDocListEmpty(docList) === true) ||
              isAnythingIncomplete() ||
              isValidYoutubeUrl ||
              isValidDocUrl
            }
          >
            {PartnerStrings.saveChanges}
          </SaveButton>
        </ButtonContainer>
      </Modal>
    </>
  )
}

export default EditIntegratorLinks
