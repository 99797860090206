import { Tooltip } from 'antd'
import React, { FC, useState } from 'react'
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg'
import { ReactComponent as EyeIcon } from '../../../assets/icons-eye.svg'
import Tile from '../../../shared/components/Tile'
import { TOOLTIP_VISIBILITY_MLS } from '../../../shared/constants/common'
import { CustomerApp } from '../../../shared/models/application'
import { color } from '../../../shared/utils/styles'
import {
  ClientForm,
  ClientInput,
  ClientInputTop,
  CopyIconStyle,
  Description,
  EditButton,
  EditContainer,
  TileHeader,
  EnvText,
  FormLabel,
  IconContainer,
  LockIcon,
  Name,
  NameContainer,
  SecretInput,
  StyledTooltip,
  TileForm,
  TileInfoContainer,
} from './Styles'
import { TileInfoStrings, SharedApplicationStrings } from '../../../shared/strings/ApplicationsContent'

type Props = {
  application: CustomerApp
  onClickEdit: (application: CustomerApp) => void
}

const ApplicationsTileInfo: FC<Props> = ({ application, onClickEdit: onClickEditParentHandler }) => {
  const [isToolTipVisible, setIsTooltipVisible] = useState(false)
  const onClickEdit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    onClickEditParentHandler(application)
  }

  const handleOnCopyClick = () => {
    application.clientId ? navigator.clipboard.writeText(application.clientId) : navigator.clipboard.writeText('')
    setIsTooltipVisible(true)
    setTimeout(() => {
      setIsTooltipVisible(false)
    }, TOOLTIP_VISIBILITY_MLS)
  }

  const cuttingOrNotDescription = (appDescription: string) => {
    if (appDescription && appDescription.length >= 40) {
      return `${appDescription.slice(0, 40)}...`
    } else {
      return appDescription
    }
  }

  return (
    <Tile backgroundColor={color.gray90}>
      <TileHeader>
        <EnvText id="app-env-js" $sandbox={!!application.sandbox}>
          {application.sandbox ? TileInfoStrings.envTextSandbox : TileInfoStrings.envTextProduction}
        </EnvText>
        <EditContainer id="open-edit-app-button-js" onClick={onClickEdit}>
          <EditIcon />
          <EditButton>{TileInfoStrings.editButton}</EditButton>
        </EditContainer>
      </TileHeader>
      <TileInfoContainer>
        <NameContainer>
          <Name>{application.name}</Name>
          <Description>
            {application.description ? cuttingOrNotDescription(application.description) : TileInfoStrings.noDescription}
          </Description>
        </NameContainer>
        <TileForm>
          <FormLabel>{SharedApplicationStrings.clientId}</FormLabel>
          <ClientForm>
            <ClientInputTop>
              <ClientInput.Password iconRender={() => <EyeIcon />} type="text" value={application.clientId} />
            </ClientInputTop>
            <IconContainer>
              <Tooltip
                placement="right"
                title={SharedApplicationStrings.copied}
                visible={isToolTipVisible}
                getPopupContainer={(trigger) => {
                  return trigger
                }}
              >
                <StyledTooltip>
                  <CopyIconStyle onClick={handleOnCopyClick} />
                </StyledTooltip>
              </Tooltip>
            </IconContainer>
          </ClientForm>
          <FormLabel>{SharedApplicationStrings.clientSecret}</FormLabel>
          <SecretInput>
            <ClientInput.Password
              iconRender={() => <LockIcon />}
              value={Array(37).join('\u2022')}
              type="text"
              readOnly
            />
          </SecretInput>
        </TileForm>
      </TileInfoContainer>
    </Tile>
  )
}

export default ApplicationsTileInfo
