import { Input, Select } from 'antd'
import styled from 'styled-components'
import Button from '../../../shared/components/Button'
import { color } from '../../../shared/utils/styles'

export const Line = styled.hr`
  margin-bottom: 48px;
  border: 1px solid ${color.gray70};
  opacity: 1;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const FormLabel = styled.label`
  margin-bottom: 8px;
  display: block;
`

export const InputName = styled(Input)`
  width: 408px;
  color: ${color.gray75};
  margin-right: 32px;
`

export const SalesForceInput = styled(Input)`
  width: 250px;
  color: ${color.gray75};
`

export const CancelButton = styled(Button)`
  width: 250px;
`

export const CreateButton = styled(Button)`
  width: 208px;
`

export const TypeSelector = styled(Select)`
  width: 250px;
  margin-bottom: 35px;
  overflow-y: 'scroll';
`

export const InputsContainer = styled.div`
  flex-grow: 1;
  margin-right: 16px;
`

export const InputRowContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
`

export const InputColumn = styled.div`
  width: 100%;
  margin: 0 auto;
`
