import React, { FC, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import ListHeader from '../ListHeader'
import ListMessageContainer from '../ListMessageContainer'
import TableHeaderRow from '../ListTableHeaderRow'
import TableContentRow from '../ListTableContentRow'
import { OrganizationsAPI } from '../../../../shared/api/organizations'
import { UsersAPI } from '../../../../shared/api/users'
import {
  EmailContainer,
  DeletedEmailContainer,
  StatusContainer,
  TypeSelector,
  RoleContainer,
  Symbol,
  RowArrow,
  ResendIcon,
  ProfileContainer,
  InvitationStatusContainer,
  CheckIcon,
  ExpiredIcon,
  InvitationStatusPopup,
  RemovePopup,
  ExpandedFormContainer,
  Label,
  InputBox,
  ButtonContainer,
  TitleRow,
  CenteredButton,
  FieldRow,
  SaveButton,
} from './Styles'
import Pagination from '../../../../shared/components/Pagination'
import { TABLE_CONTENT_LENGTH } from '../../../../shared/constants/common'
import { ResendInviteButton, EnableButton } from '../InviteMembersForm/Styles'
import { UpdateModal } from '../ModalComponent'
import { Button, Modal, LoadingAnimated, Toast, Table } from '../../../../shared/components'
import { MemberListStrings, SharedOrganizationsStrings } from '../../../../shared/strings/OrganizationsContent'
import { GeneralFormStrings } from '../../../../shared/strings/GeneralFormContent'
import { Select } from 'antd'
const { Option } = Select

type Props = {
  organizationId: number
  showListHeader: boolean
  onClickAdd?: () => void
  onStartAction: () => void
}

type Member = {
  email: string
  status: string
  firstName?: string
  lastName?: string
  invitationId?: number
  username?: string
  enabled?: string
  isOwner: boolean
}

const statusText = {
  SENT_INVITATION: MemberListStrings.statusTextSentInvitation,
  EXPIRED_INVITATION: MemberListStrings.statusTextExpiredInvitation,
  ACTIVE: MemberListStrings.statusTextActive,
}

type ListItemProps = {
  organizationId: number
  member: Member
  onRemoveStart: () => void
  onRemoveComplete: () => void
  resendInvitation: (email: string, itemKey: number) => Promise<void>
  itemKey: number
  id: number
}

const ListItem: FC<ListItemProps> = ({
  organizationId,
  member,
  onRemoveStart,
  onRemoveComplete,
  resendInvitation,
  itemKey,
  id,
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [firstName, setFirstName] = useState(member.firstName)
  const [lastName, setLastName] = useState(member.lastName)
  const [email, setEmail] = useState(member.email)
  const [formRole, setFormRole] = useState(() => (member.isOwner ? 'Owner' : 'Member'))
  const [role, setRole] = useState(() => (member.isOwner ? 'Owner' : 'Member'))
  const { mutateAsync: removeMember, isLoading: isMemberRemoving } = useMutation(
    OrganizationsAPI.deleteUserFromOrganization
  )

  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)

  const { mutateAsync: updateMember, isLoading: isMemberUpdating } = useMutation(OrganizationsAPI.updateMemberDetails)

  useEffect(() => {
    setRole(member.isOwner ? 'Owner' : 'Member')
    setFormRole(member.isOwner ? 'Owner' : 'Member')
  }, [member])

  const handleSave = async () => {
    try {
      const response = await updateMember({
        organizationId,
        data: {
          email,
          firstName,
          lastName,
          role: formRole,
        },
      })
      setApiResponse(response) // Store API response
      setUpdateModalVisible(true) // Show modal
      setRole(formRole) // Update the displayed role after successful API call
      setExpanded(false) // Collapse the form
      onRemoveComplete()
    } catch (err) {
      console.error(err)
      setIsErrorVisible(true)
    }
  }

  const { mutateAsync: removeInvite, isLoading: isInviteRemoving } = useMutation(OrganizationsAPI.deleteInvite)

  const isRemoving = isMemberRemoving || isInviteRemoving

  const showModal = () => {
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const RemoveSymbol = ({ onClick, id }: { onClick: () => void; id: string }) => {
    const [isHoveringRemove, setIsHoveringRemove] = useState(false)

    const handleMouseEnter = () => {
      setIsHoveringRemove(true)
    }

    const handleMouseLeave = () => {
      setIsHoveringRemove(false)
    }

    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Symbol id={id} onClick={onClick} />
        {isHoveringRemove && <RemovePopup>Remove</RemovePopup>}
      </div>
    )
  }

  const onClickRemove = async () => {
    onRemoveStart()
    setIsErrorVisible(false)

    try {
      if (member.status === 'ACTIVE') {
        await removeMember({
          organizationId,
          data: { userName: member.email },
        })
      } else {
        await removeInvite({ organizationId, invitationId: member.invitationId! })
      }
      onRemoveComplete()
    } catch (err) {
      console.error(err)
      setIsErrorVisible(true)
    } finally {
      setModalVisible(false)
    }
  }
  const toggleExpansion = () => {
    setExpanded(!expanded) // Toggle the expansion state
  }

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  const handleCancelExpansion = () => {
    setExpanded(false)
  }

  const handleTitleChange = (value: string) => {
    // Update formRole only if the selected value is different from the current formRole
    if (value !== formRole) {
      setFormRole(value)
    }
  }

  return (
    <>
      <Toast
        isVisible={isErrorVisible}
        text={MemberListStrings.removeUserFromOrganizationError}
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <TableContentRow isRemoving={isRemoving}>
        <RowArrow onClick={toggleExpansion} /> {/* Arrow for expanding/collapsing */}
        <EmailContainer id="member-invitation-email-js">{member.email}</EmailContainer>
        <ProfileContainer>{role}</ProfileContainer>
        <InvitationStatusContainer>
          {member.status === 'EXPIRED_INVITATION' ? (
            <div style={{ position: 'relative' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <ExpiredIcon />
              {isHovering && <InvitationStatusPopup>Expired</InvitationStatusPopup>}
            </div>
          ) : member.status !== 'ACTIVE' ? (
            <div style={{ position: 'relative' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <ResendIcon onClick={() => resendInvitation(member.email, id)} />
              {isHovering && <InvitationStatusPopup>Resend</InvitationStatusPopup>}
            </div>
          ) : (
            <div style={{ position: 'relative' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <CheckIcon />
              {isHovering && <InvitationStatusPopup>Accepted</InvitationStatusPopup>}
            </div>
          )}
        </InvitationStatusContainer>
        <div style={{ visibility: role === 'Owner' ? 'hidden' : 'visible', marginRight: '20px', marginBottom: '-3px' }}>
          <RemoveSymbol
            onClick={showModal}
            id={role === 'Owner' ? 'remove-item-button-disabled-js' : 'remove-item-button-js'}
          />
        </div>
      </TableContentRow>
      {expanded && (
        <ExpandedFormContainer>
          <TitleRow>
            <Label style={{ marginBottom: '10px' }}>{MemberListStrings.title}</Label>
            <Select
              value={formRole}
              onChange={handleTitleChange}
              style={{ width: '30%' }}
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {role === 'Owner' ? (
                <Option value="Owner">{MemberListStrings.owner}</Option>
              ) : (
                <Option value="Owner">{MemberListStrings.owner}</Option>
              )}
            </Select>
          </TitleRow>
          <FieldRow>
            <Label>{MemberListStrings.userName}</Label>
            <Label>{MemberListStrings.firstName}</Label>
            <Label>{MemberListStrings.lastName}</Label>
          </FieldRow>
          <FieldRow>
            <InputBox type="text" value={email} disabled />
            <InputBox type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            <InputBox type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </FieldRow>
          <ButtonContainer>
            <SaveButton id="save-member-button-js" type="primary" onClick={handleSave} loading={isMemberUpdating}>
              {MemberListStrings.saveButton}
            </SaveButton>
            <EnableButton id="cancel-details-button-js" ghost onClick={handleCancelExpansion}>
              {MemberListStrings.cancelButton}
            </EnableButton>
          </ButtonContainer>
        </ExpandedFormContainer>
      )}
      <UpdateModal visible={updateModalVisible} response={apiResponse} onClose={() => setUpdateModalVisible(false)} />
      <Modal
        title={SharedOrganizationsStrings.pleaseNoteModalTitle}
        width={672}
        visible={modalVisible}
        onOk={onClickRemove}
        confirmLoading={isRemoving}
        onCancel={handleCancel}
        closable={false}
        footer={[
          <Button
            id="confirm-remove-member-button-js"
            size="large"
            style={{ width: '304px', marginRight: '24px' }}
            key="submit"
            ghost={true}
            onClick={onClickRemove}
            loading={isRemoving}
          >
            {MemberListStrings.removeButton}
          </Button>,
          <Button size="large" style={{ width: '272px' }} key="back" type="primary" onClick={handleCancel}>
            {MemberListStrings.goBackButton}
          </Button>,
        ]}
      >
        {`${MemberListStrings.removeFromOrganizationStart} ${
          member.firstName && member.lastName ? `${member.firstName} ${member.lastName}` : member.email
        }  ${MemberListStrings.removeFromOrganizationEnd}`}
      </Modal>
    </>
  )
}

type DeleteListItemProps = {
  organizationId: number
  member: Member
  onEnableUser: () => void
  onEnableComplete: () => void
  itemKey: number
  id: number
}

const DeletedListItem: FC<DeleteListItemProps> = ({
  organizationId,
  member,
  onEnableUser,
  onEnableComplete,
  itemKey,
  id,
}) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onClickEnable = async () => {
    setIsLoading(true)
    onEnableUser()
    setIsErrorVisible(false)

    try {
      await UsersAPI.enableUser(member.username ? member.username : '', [])
    } catch (e) {
      setIsErrorVisible(true)
    }
    onEnableComplete()
    setIsLoading(false)
  }

  return (
    <>
      <Toast
        isVisible={isErrorVisible}
        text={MemberListStrings.enableUserFromOrganizationError}
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <Table.ContentRow>
        <DeletedEmailContainer id="member-email-js">{member.email}</DeletedEmailContainer>
        <EnableButton ghost loading={isLoading} onClick={onClickEnable}>
          Enable
        </EnableButton>
      </Table.ContentRow>
    </>
  )
}

export type MemberListRef = {
  hideToastNotifications: () => void
}

const MemberList = forwardRef<MemberListRef | undefined, Props>(
  ({ organizationId, showListHeader, onClickAdd, onStartAction: onStartActionParent }, ref) => {
    const [page, setPage] = useState(0)
    const [itemKey, setItemKey] = useState(-1)
    const [isShowResendInvitationSuccess, setIsShowResendInvitationSuccess] = useState(false)
    const [isShowResendInvitationError, setIsShowResendInvitationError] = useState(false)
    const queryName = OrganizationsAPI.queryNames.GET_ORG_MEMBERS_AND_INVITED
    const { isLoading, error, data } = useQuery([queryName, organizationId], () =>
      OrganizationsAPI.listMembersAndInvitedUsers(organizationId)
    )
    const { mutateAsync: inviteUsers } = useMutation(OrganizationsAPI.inviteUsers)
    const queryClient = useQueryClient()

    useImperativeHandle(ref, () => ({ hideToastNotifications }))

    const hideToastNotifications = () => {
      setIsShowResendInvitationError(false)
      setIsShowResendInvitationSuccess(false)
    }

    const onStartAction = () => {
      hideToastNotifications()
      onStartActionParent()
    }

    const resendInvitation = async (email: string, itemKey: number) => {
      onStartAction()
      setItemKey(itemKey)
      try {
        await inviteUsers({
          orgId: organizationId,
          emails: [email],
        })
        setIsShowResendInvitationSuccess(true)
        invalidateQuery()
      } catch (err: any) {
        setIsShowResendInvitationError(true)
      } finally {
        setItemKey(-1)
      }
    }

    const invalidateQuery = () => {
      queryClient.invalidateQueries([queryName, organizationId])
    }

    const showMembersAndInvitedUsers = () => {
      if (isLoading) {
        return <LoadingAnimated />
      }
      if (error) {
        return <p>{MemberListStrings.memberListFetchError}</p>
      }
      if (data.length === 0) {
        return <ListMessageContainer>{MemberListStrings.noInvitedMembers}</ListMessageContainer>
      }
      return (
        <>
          <Toast
            key="resend-invitation-success"
            isVisible={isShowResendInvitationSuccess}
            text={MemberListStrings.resendInvitationSuccess}
            onDismiss={() => setIsShowResendInvitationSuccess(false)}
            type="success"
          />
          <Toast
            key="resend-invitation-error"
            isVisible={isShowResendInvitationError}
            text={MemberListStrings.resendInvitationError}
            onDismiss={() => setIsShowResendInvitationError(false)}
            type="error"
          />
          <TableHeaderRow>
            <EmailContainer>{GeneralFormStrings.email}</EmailContainer>
            <RoleContainer>{`Title`}</RoleContainer>
            <StatusContainer>{MemberListStrings.invitationStatus}</StatusContainer>
          </TableHeaderRow>
          {data
            .filter((user: Member) => user.enabled != 'false')
            .slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))
            .map((member: Member, i: number) => (
              <ListItem
                key={i}
                id={i}
                organizationId={organizationId}
                member={member}
                onRemoveStart={onStartAction}
                onRemoveComplete={invalidateQuery}
                resendInvitation={resendInvitation}
                itemKey={itemKey}
              />
            ))}
          <Pagination dataLength={data.length} page={page} setPage={setPage} />
          {data && data.filter((user: Member) => user.enabled === 'false').length > 0 && (
            <>
              <br />
              <TableHeaderRow>
                <EmailContainer>Deleted users</EmailContainer>
              </TableHeaderRow>
            </>
          )}
          {data &&
            data
              .filter((user: Member) => user.enabled === 'false')
              .slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))
              .map((member: Member, i: number) => (
                <DeletedListItem
                  key={member.email}
                  id={i}
                  organizationId={organizationId}
                  member={member}
                  onEnableUser={onStartAction}
                  onEnableComplete={invalidateQuery}
                  itemKey={itemKey}
                />
              ))}
        </>
      )
    }

    return (
      <>
        {showListHeader && (
          <ListHeader
            title={MemberListStrings.listHeaderText}
            onClickAdd={onClickAdd}
            disableAddButton={false}
            addButtonId="open-invite-members-button-js"
            addButtonText={MemberListStrings.addButtonText}
          />
        )}
        {showMembersAndInvitedUsers()}
      </>
    )
  }
)

MemberList.displayName = 'MemberList'

export default MemberList
