import React, { FC, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Form } from 'antd'
import { EMAIL_REG_EXP, FORGOT_PASSWORD_DESCRIPTION, REQUIRED_FIELD } from '../../../../shared/constants/auth'
import { useAuth } from '../../../../shared/contexts/authContext'
import { AuthFormLabel, AuthFormWrapper, ErrorMessage, Toast } from '../../../../shared/components'
import { ButtonContainer, CancelButton, Description, SendResetLinkButton, StyledForm } from './Styles'
import NewPasswordForm from '../NewPasswordForm'
import { ForgotPasswordStrings, ErrorStrings } from '../../../../shared/strings/AuthFormContent'
import { GeneralFormStrings } from '../../../../shared/strings/GeneralFormContent'
import { EmailInput } from '../Styles'

type Props = {
  onClosePanel: () => void
}

const AuthFormsForgotPasswordForm: FC<Props> = ({ onClosePanel }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm()
  const { forgotPassword } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isNewPassword, setIsNewPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [sendCodeErrorMessage, setSendCodeErrorMessage] = useState<string | undefined>()
  const [isErrorVisible, setIsErrorVisible] = useState(false)

  async function onSendResetClick(data: { email: string }) {
    setIsErrorVisible(false)
    setIsLoading(true)
    setSendCodeErrorMessage(undefined)
    try {
      await forgotPassword(data.email.toLowerCase())
      setEmail(data.email.toLowerCase())
      setIsNewPassword(true)
    } catch (err) {
      setSendCodeErrorMessage(ErrorStrings.verificationError)
      setIsErrorVisible(true)
    } finally {
      setIsLoading(false)
    }
  }

  if (isNewPassword) {
    return <NewPasswordForm email={email} />
  }

  return (
    <AuthFormWrapper>
      <Toast
        isVisible={isErrorVisible}
        text={sendCodeErrorMessage}
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <StyledForm onSubmit={handleSubmit(onSendResetClick)}>
        <Description>{FORGOT_PASSWORD_DESCRIPTION}</Description>
        <Form.Item validateStatus={errors.email ? 'error' : ''}>
          <AuthFormLabel htmlFor="email-input-js">{GeneralFormStrings.email}</AuthFormLabel>
          <Controller
            render={({ field }) => <EmailInput id="reset-password-email-input-js" {...field} />}
            name="email"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: EMAIL_REG_EXP,
                message: ErrorStrings.invalidEmailError,
              },
              required: true,
            }}
          />
          {errors.email && <ErrorMessage>{errors.email.message || REQUIRED_FIELD}</ErrorMessage>}
        </Form.Item>
        <ButtonContainer>
          <CancelButton ghost={true} onClick={onClosePanel}>
            {GeneralFormStrings.cancel}
          </CancelButton>
          <SendResetLinkButton id="send-reset-code-button-js" type="primary" htmlType="submit" loading={isLoading}>
            {ForgotPasswordStrings.sendResetCode}
          </SendResetLinkButton>
        </ButtonContainer>
      </StyledForm>
    </AuthFormWrapper>
  )
}

export default AuthFormsForgotPasswordForm
