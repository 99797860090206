import { API } from './API'

const queryNames = {
  TOKEN: 'backend-token',
}

const TOKEN_VALIDITY_SECONDS = 120

const BACKEND_TOKEN_PATH = '/apitoken'

export type AuthHeader = Record<string, string>

type TokenRequest = {
  maxAgeSeconds: number
  scopes: string[]
  applicationId?: string
}

type CacheEntry = {
  validUntil: number
  header: AuthHeader
}

const tokenCache = new Map()

const convertFrontTokenToBackendToken = async (scopes: string[], applicationId?: string): Promise<AuthHeader> => {
  const cacheKey = (applicationId ?? '') + scopes.join(' ')
  let cacheEntry = tokenCache.get(cacheKey) as CacheEntry
  const now = Date.now()
  if (!cacheEntry || cacheEntry.validUntil < now) {
    const req = {
      maxAgeSeconds: TOKEN_VALIDITY_SECONDS,
      scopes,
      applicationId,
    } as TokenRequest
    const resp = await API.post(`${BACKEND_TOKEN_PATH}`, {
      body: req,
    })
    cacheEntry = {
      // temporary hack to add version here.. TODO later
      header: { Authorization: `Bearer ${resp.access_token}`, version: 'v2' },
      validUntil: now + 1000 * (TOKEN_VALIDITY_SECONDS - 10),
    }
    tokenCache.set(cacheKey, cacheEntry)
  }
  return cacheEntry.header
}

export const BackendTokenAPI = {
  queryNames,
  convertFrontTokenToBackendToken,
}
