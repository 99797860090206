import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogApril2 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        April 2024 - 2.0
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        April 2.0 release notes summarizes latest changes for Equipment Status API , Service Info API, API Portal
        documentation changes and bug fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Equipment Status API</li>
          <UL>
            <li>
              <q>serviceOrderNumber</q> is now available in{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#service-status-event'}>Equipment Status API</Link>.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              <q>createdDateTime</q> is now enabled for unplanned service order in ENA region for
              <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrdersByEquipmentId'}>
                {' '}
                Service Info List API
              </Link>
              ,<Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrderById'}> Service Info Details API.</Link>
            </li>
            <li>
              The bug related to the <q>createdDateTime</q> field being empty for planned service orders in the Service
              Info List API has been fixed and <q>createdDateTime</q> is now available.
            </li>
            <li>Sandbox events are now available for Service Info Webhook API. </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Description updated for{' '}
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentAvailabilityByIds'}>
                {' '}
                Get equipment availability information
              </Link>{' '}
              and{' '}
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentEntrapmentByIds'}>
                {' '}
                Get equipment status information.
              </Link>
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
