import styled from 'styled-components'
import Button from '../../../shared/components/Button'

export const Container = styled.div`
  width: 710px;
  margin: 0 auto;
`

export const Title = styled.div`
  text-align: left;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 42px auto;
`

export const Label = styled.div`
  margin-bottom: 6px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const InviteButton = styled(Button)`
  width: 195px;
`

export const ResendInviteButton = styled(Button)`
  width: 100px;
  margin-right: 10px;
`

export const EnableButton = styled(Button)`
  width: 100px;
  margin-right: 10px;
`

export const MessageContainer = styled.p`
  margin: 0;
`
