import React, { FC } from 'react'
import InspirationArticleTemplate from '../InspirationArticleTemplate'
import { Ecosystem, EcosystemSEOProps } from '../../../shared/strings/InspirationPageContent'
import ecosystemOfPartners from '../../../assets/ecosystem-of-partners.jpg'
import ecosystemOfPartnersLarge from '../../../assets/ecosystem-of-partners-2x.jpg'

const EcosystemArticle: FC = () => {
  return (
    <InspirationArticleTemplate
      title={Ecosystem.title}
      metaTags={EcosystemSEOProps.metaTags}
      firstTextBlock={Ecosystem.firstTextBlock}
      subtitle={Ecosystem.subtitle}
      secondTextBlock={Ecosystem.secondTextBlock}
      findOutMoreText={Ecosystem.findOutMoreText}
      contactUsLink={Ecosystem.contactUsLink}
      articleImage={{
        small: ecosystemOfPartners,
        large: ecosystemOfPartnersLarge,
      }}
      imageAlt={Ecosystem.imageAlt}
    />
  )
}

export default EcosystemArticle
