import { API } from './API'
import { ProductList } from '../models/product'
import { Resources } from '../models/resource'
import { OrganizationMembers } from '../models/organizationMember'

const RESOURCES_PATH = '/customer/resources'
const ORG_CUSTOMER_PATH = '/customer/me'

const queryNames = {
  GET_ALL: 'organizations-get-all',
}

const queryNamesResources = {
  GET_ALL: 'resources-get-all',
}

const queryNamesProducts = {
  GET_ALL: 'products-get-all',
}

const getResources = async (): Promise<Resources> => {
  return await API.get(RESOURCES_PATH)
}

const getProducts = async (): Promise<ProductList> => {
  return await API.get(`${ORG_CUSTOMER_PATH}/products`)
}

const getOrganizationMembers = async (): Promise<OrganizationMembers> => {
  return await API.get(`${ORG_CUSTOMER_PATH}/peerUsers`)
}

export const UserOrganizationAPI = {
  queryNames,
  getResources,
  getOrganizationMembers,
  getProducts,
  queryNamesResources,
  queryNamesProducts,
}
