import { color } from '../../../../shared/utils/styles'
import styled from 'styled-components'
import { Button, Input, Select } from 'antd'
import ReactQuill from 'react-quill'
import { ReactComponent as RemoveIcon } from '../../../../assets/icons_remove.svg'
export {}
export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`

export const FormLabel = styled.label`
  margin-bottom: 3px;
  margin-top: 10px;
  display: block;
  text-aligh: left;
`
export const HeaderSolutionContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5px;
`

export const InputName = styled(Input)`
  color: ${color.gray75};
  height: 24px;
`

export const InputsContainer = styled.div`
  margin-bottom: 32px;
`

export const ContactHeader = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
`

export const Underline = styled.div`
  text-decoration: underline;
  text-decoration-color: ${color.gray70};
`
export const RemoveIconSolution = styled(RemoveIcon)`
  height: 20px;
  cursor: pointer;
`
export const InputColumn = styled.div`
  //width: 100%;
  margin: 0 auto;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5px;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  margin-top: 10px;
  opacity: 0.4;
  width: 100%;
`

export const AddContactButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 250px;
  margin-top: 2px;
  margin-right: 10px;
  font-weight: 100;
  background-color: transparent;
  color: ${color.white};
  border: 1px solid ${color.white};
  cursor: pointer;

  &hover: {
    background-color: transparent;
    color: ${color.white};
  }
`

export const CancelButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 150px;
  margin-top: 2px;
  margin-right: 10px;
  font-weight: 100;
  background-color: transparent;
  color: ${color.white};
  border: 1px solid ${color.white};
  cursor: pointer;

  &hover: {
    background-color: transparent;
    color: ${color.white};
  }
`
export const SaveButton = styled(Button)`
  box-sizing: border-box;
  font-size: 1em;
  height: 32px;
  width: 150px;
  margin-top: 2px;
  font-weight: 100;
  background-color: ${color.blueBase};
  color: ${color.white};
  border: 0px;
  cursor: pointer;

  &hover: {
    background-color: ${color.blueBase};
    color: ${color.white};
  }
`

export const PartnerP = styled.p`
  margin-bottom: 4px;
  text-align: left;
  margin-top: 14px;
`

export const RequiredFieldsText = styled.div`
  font-size: 11px;
  margin-top: 4px;
`
export const FormButton = styled(Button)`
  margin-right: 10px;
  background-color: ${color.blueBase};
`
export const Editor = styled(ReactQuill)`
  border: 1px solid ${color.gray20};
  height: 100px;
  width: 84%;
  margin-bottom: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .ql-container {
    background-color: white;
    color: black;
    overflow: visible;
    overflow-y: auto;
    max-height: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ql-formats {
    color: white;
  }

  .ql-toolbar {
    background-color: transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 2px;
    border: 0px solid transparent;
  }
`
export const EditorColumn = styled.div`
  margin-bottom: 50px;
`
export const InputColumnSecond = styled.div``

export const TypeSelector = styled(Select)`
  width: 100%;
  overflow-y: 'scroll';
  font-size: 0.8em;
  .ant-select-selection-item-content {
    font-size: 0.8em;
  }

  .ant-select-item-option-content {
    font-size: 0.8em;
  }
`
export const InputColumnRight = styled.div``

export const SolutionContainerItem = styled.div``

export const RemoveSolutionButton = styled(Button)`
  background-color: transparent;
`
