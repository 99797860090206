import { Organization } from '../models/organization'
import { Resources } from '../../shared/models/resource'

const sortOrganizationsAlphabetically = (org1: Organization, org2: Organization) => {
  const name1 = org1.name.toLowerCase()
  const name2 = org2.name.toLowerCase()
  if (name1 < name2) {
    return -1
  }
  if (name1 > name2) {
    return 1
  }

  return 0
}

const sortOrganizationsForSelectControl = (organizations: Organization[]): Organization[] => {
  const personal = organizations.find((org) => org.type.toLowerCase() === 'personal')
  const nonPersonal = organizations.filter((org) => org.type.toLowerCase() !== 'personal') || []

  if (nonPersonal) {
    nonPersonal.sort(sortOrganizationsAlphabetically)
  }

  if (personal) {
    personal.name = 'Personal'
    return [personal, ...nonPersonal]
  } else {
    return nonPersonal
  }
}

const sortedResources = (rawResources: Resources): Resources => {
  rawResources.sort((a: any, b: any) => {
    const resourceOne = a.resourceId.uniqueId.toLowerCase()
    const resourceTwo = b.resourceId.uniqueId.toLowerCase()

    return resourceOne.localeCompare(resourceTwo.toLowerCase())
  })

  return rawResources
}

const sortedResourcesZA = (rawResources: Resources): Resources => {
  rawResources.sort((x: any, y: any) => {
    const resourceOne = x.resourceId.uniqueId.toLowerCase()
    const resourceTwo = y.resourceId.uniqueId.toLowerCase()

    return resourceTwo.localeCompare(resourceOne.toLowerCase())
  })

  return rawResources
}

export { sortOrganizationsForSelectControl, sortedResources, sortedResourcesZA }
