import React, { FC } from 'react'
import { PageContainer } from './Styles'
import { DarkLayoutContainer } from '../../App/Styles'
import TermsAndConditionsContent from '../../shared/content/TermsAndConditionsContent'

const TermsAndConditions: FC = () => {
  return (
    <DarkLayoutContainer>
      <PageContainer>
        <TermsAndConditionsContent />
      </PageContainer>
    </DarkLayoutContainer>
  )
}

export default TermsAndConditions
