import styled from 'styled-components'
import { Row } from 'antd'
import { color } from '../../../../shared/utils/styles'
import { H3 } from '../../../../shared/components'
import Button from '../../../../shared/components/Button'
import { ReactComponent as SolutionCreation } from '../../../../shared/assets/img-solution-creation.svg'

export const TypeContainer = styled.div`
  width: 224px;
`
export const ListTableHeaderRow = styled.div`
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
  margin-top: 20px;
`

export const TableContentRow = styled.div`
  display: flex;
  background-color: ${color.black};
  align-items: center;
  padding: 14px 10px;
  margin-bottom: 5px;
  width: 500px;
`
export const SolutionCreationSvg = styled(SolutionCreation)`
  max-width: 70%;
  max-height: 70vh;
  object-fit: cover;
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonContainer = styled(Row)`
  display: flex;
  justify-content: center;
  margin-top: 5%;
`

export const GetStartedButton = styled(Button)``

export const ContentHeading = styled(H3)`
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: ${color.blueBase};
`
