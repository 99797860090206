import React, { FC } from 'react'
import { ServiceInfoApiV2 } from '../../../shared/strings/KONEAPIsTextContent'
import Paths from '../../../Dashboard/Paths'
import KONEAPIPage from '../KONEAPIPage'
import dompurify from 'dompurify'

const sanitizer = dompurify.sanitize

const apisV2 = [Paths.AuthenticationAPIV2, Paths.ServiceInfoAPIV2ApiPageDocs, Paths.ServiceInfoWebhookAPIApiPageDocs]

const ServiceInfoAPIPage: FC = () => {
  return (
    <>
      <KONEAPIPage title={Paths.ServiceInfoAPIV2Product.name} apis={apisV2}>
        <div dangerouslySetInnerHTML={{ __html: sanitizer(ServiceInfoApiV2.body) }} />
      </KONEAPIPage>
    </>
  )
}

export default ServiceInfoAPIPage
