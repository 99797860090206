import React, { FC } from 'react'
import { StyledSwitch, AntSwitchWebhookContainer } from './Styles'

type Props = {
  id: string
  checked?: boolean
  onChange?: (checked: boolean) => void
}

const SwitchWebhook: FC<Props> = ({ id, checked, onChange }) => {
  return (
    <AntSwitchWebhookContainer>
      <StyledSwitch id={id} checked={checked} onChange={onChange} />
    </AntSwitchWebhookContainer>
  )
}

export default SwitchWebhook
