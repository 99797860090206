import { BackendTokenAPI } from './backend'
import axios from 'axios'
import { CreateWebhookSubscription } from '../../shared/models/webhookSubscription'

export const queryNamesWebhook = {
  LIST: 'subscription-list',
}

const ADMIN_SCOPE = ['admin/webhook']
const Scope = 'application/subscriptions'

export const listWebhookSubscriptions = async (clientId: string) => {
  const accessToken = await BackendTokenAPI.convertFrontTokenToBackendToken(['rtm/*', Scope], clientId)
  const response: any = await axios.get('/api/v1/application/self/webhooks', {
    method: 'GET',
    headers: { Authorization: accessToken.Authorization },
  })

  return response.data
}

export const createSubscription = async (data: CreateWebhookSubscription) => {
  const accessToken = await BackendTokenAPI.convertFrontTokenToBackendToken(['rtm/*', Scope], data.clientId)
  const response = await axios('/api/v1/application/self/webhooks', {
    method: 'POST',
    data: JSON.stringify(data.body),
    headers: accessToken,
  })

  if (response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new Error()
}

export const removeKenFromSubscription = async (data: any) => {
  console.log('Remove from subscription')
  const accessToken = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const method = 'DELETE'
  const response = await axios(`/api/v1/application/self/webhooks`, {
    method,
    headers: accessToken,
    data: JSON.stringify(data),
  })

  if (response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new Error()
}

export const editSubscription = async (data: any) => {
  const accessToken = await BackendTokenAPI.convertFrontTokenToBackendToken(['rtm/*', Scope], data.clientID)

  const response = await axios(`/api/v1/application/self/webhooks/${data.id}`, {
    method: 'PATCH',
    data: JSON.stringify(data.body),
    headers: accessToken,
  })

  if (response.status >= 200 && response.status < 300) {
    return response.data
  }

  throw new Error()
}

export const triggerEvent = async (data: any) => {
  const accessToken = await BackendTokenAPI.convertFrontTokenToBackendToken(['rtm/*', Scope], data.application.clientId)

  const response = await axios(`/api/v1/application/self/webhooks/${data.subscription.id}/test`, {
    method: 'POST',
    headers: accessToken,
  })

  return response.data
}

export const deleteSubscription = async (data: any) => {
  const accessToken = await BackendTokenAPI.convertFrontTokenToBackendToken(['rtm/*', Scope], data.clientId)

  await axios(`/api/v1/application/self/webhooks/${data.subscriptionId}`, {
    method: 'DELETE',
    headers: accessToken,
  })
}
