import styled from 'styled-components'
import { color } from '../../shared/utils/styles'
import { H6 } from '../../shared/components/Typography'

export const TilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 28px;
`
export const TextTile = styled.div`
  width: 220px;
  padding: 1em 1.5em;
  margin-right: 1em;
  margin-bottom: 1em;
  color: ${color.gray10};
  background-color: ${color.black};
  text-align: center;
`

export const NumberText = styled.div`
  font-size: 40px;
`

export const DescriptionText = styled(H6)`
  text-align: center;
`

export const MessageContainer = styled.div`
  margin-top: 30px;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  opacity: 0.4;
  width: 100%;
`

export const StyledLineBottom = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-left: auto;
  opacity: 0.4;
  width: 94%;
`

export const StyledLineDivide = styled.hr`
  opacity: 0;
  margin: 30px;
`
export const Stats = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  H4 {
    margin: 0px 10px;
  }
`
