import { Form, Input } from 'antd'
import React, { ChangeEvent, FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactCodeInput from 'react-code-input'
import { AuthFormLabel, AuthFormWrapper, ErrorMessage, SuccessView, Toast } from '../../../../shared/components'
import {
  NEW_PASSWORD_DESCRIPTION,
  NEW_PASSWORD_INSTRUCTION_ON_ERROR,
  PASSWORD_DESCRIPTION,
  PASSWORD_MISMATCHED,
  PASSWORD_REG_EXP,
  REQUIRED_FIELD,
} from '../../../../shared/constants/auth'
import { useAuth } from '../../../../shared/contexts/authContext'
import { MfaMethod } from '../../../../shared/models/mfa'
import { ErrorStrings, NewPasswordStrings } from '../../../../shared/strings/AuthFormContent'
import { ButtonContainer, Description } from '../ForgotPassword/Styles'
import MFAAuthForm from '../MFAAuthForm'
import { ResetPasswordAndLoginButton, StyledForm, ResetCodeContainer, props } from './Styles'

type Props = {
  email: string
}

const AuthFormsNewPasswordForm: FC<Props> = ({ email }) => {
  const {
    control,
    getValues,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitted },
  } = useForm()
  const { forgotPasswordSubmit, signInAndCheckMfa } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isConfirmation, setIsConfirmation] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [isMfa, setIsMfa] = useState(false)

  async function onSubmit(data: { password: string; verificationCode: string }) {
    setIsErrorVisible(false)
    setIsLoading(true)
    setNewPassword(data.password)

    try {
      await forgotPasswordSubmit(email, data.verificationCode, data.password)
      setIsConfirmation(true)
    } catch (err) {
      let errorMsg = ErrorStrings.failedToResetPasswordError
      if (err.code) {
        switch (err.code) {
          case 'UserNotFoundException':
            errorMsg = ErrorStrings.userDoesNotFoundError
            break
          case 'CodeMismatchException':
            errorMsg = ErrorStrings.codeMismatchError
            break
          case 'ExpiredCodeException':
            errorMsg = ErrorStrings.codeExpiredError
            break
          case 'LimitExceededException':
            errorMsg = ErrorStrings.limitExceededError
            break
          default:
            break
        }
      }
      setErrorMessage(errorMsg)
      setIsErrorVisible(true)
    } finally {
      setIsLoading(false)
    }
  }

  const onConfirmation = () => {
    setIsSuccess(true)
  }

  const triggerPasswordConfirmationValidation = () => {
    if (isSubmitted) {
      trigger('passwordConfirmation')
    }
  }

  const InputForm = (
    <>
      <Description>
        <p>
          {NEW_PASSWORD_DESCRIPTION} <strong>{email}</strong>.
        </p>
        <p>{NEW_PASSWORD_INSTRUCTION_ON_ERROR}</p>
      </Description>
      <StyledForm onSubmit={handleSubmit(onConfirmation)}>
        <Form.Item>
          <AuthFormLabel htmlFor="new-password-input-js">{NewPasswordStrings.newPassword}</AuthFormLabel>
          <Controller
            render={({ field }) => (
              <Input.Password
                id="new-password-input-js"
                {...field}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e)
                  triggerPasswordConfirmationValidation()
                }}
                autoComplete="off"
                type="password"
              />
            )}
            name="password"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: PASSWORD_REG_EXP,
                message: PASSWORD_DESCRIPTION,
              },
              required: true,
            }}
          />
          {errors.password && <ErrorMessage>{errors.password.message || REQUIRED_FIELD}</ErrorMessage>}
        </Form.Item>
        <Form.Item>
          <AuthFormLabel htmlFor="confirm-new-password-input-js">{NewPasswordStrings.confirmNewPassword}</AuthFormLabel>
          <Controller
            render={({ field }) => (
              <Input.Password id="confirm-new-password-input-js" {...field} autoComplete="off" type="password" />
            )}
            name="passwordConfirmation"
            control={control}
            defaultValue=""
            rules={{
              validate: (value) => {
                if (value && value === getValues('password')) {
                  return true
                }
                return false
              },
            }}
          />
          {errors.passwordConfirmation && <ErrorMessage>{PASSWORD_MISMATCHED}</ErrorMessage>}
        </Form.Item>
        <ButtonContainer>
          <ResetPasswordAndLoginButton
            id="reset-password-button-js"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {NewPasswordStrings.resetPassword}
          </ResetPasswordAndLoginButton>
        </ButtonContainer>
      </StyledForm>
    </>
  )

  const ResetCodeForm = (
    <>
      <Description>
        {NEW_PASSWORD_DESCRIPTION} &nbsp; {email}
      </Description>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <ResetCodeContainer>
          <AuthFormLabel htmlFor="reset-code-input-js">{NewPasswordStrings.resetCode}</AuthFormLabel>
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <ReactCodeInput
                name={name}
                value={value}
                onChange={onChange}
                inputMode="numeric"
                type="number"
                fields={6}
                inputStyle={props}
              />
            )}
            name="verificationCode"
            control={control}
            defaultValue=""
            rules={{ required: true }}
          />
          {errors.verificationCode && <ErrorMessage>{errors.verificationCode.message || REQUIRED_FIELD}</ErrorMessage>}
        </ResetCodeContainer>
        <ButtonContainer>
          <ResetPasswordAndLoginButton
            id="reset-password-button-js"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {NewPasswordStrings.verify}
          </ResetPasswordAndLoginButton>
        </ButtonContainer>
      </StyledForm>
    </>
  )

  const onSuccessClick = async () => {
    setIsErrorVisible(false)
    setIsLoading(true)
    try {
      const mfa = await signInAndCheckMfa(email, newPassword)
      if (mfa === MfaMethod.TOTP) {
        setIsMfa(true)
      }
    } catch (err) {
      console.error(err)
      setErrorMessage(err?.message || ErrorStrings.loginError)
      setIsErrorVisible(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AuthFormWrapper>
      <Toast isVisible={isErrorVisible} text={errorMessage} onDismiss={() => setIsErrorVisible(false)} type="error" />
      {isSuccess ? (
        isConfirmation ? (
          !isMfa ? (
            <SuccessView
              successText={NewPasswordStrings.passwordResetSuccess}
              successTextId="reset-password-message-js"
              buttonText={NewPasswordStrings.successViewLoginText}
              buttonOnClick={onSuccessClick}
              isLoading={isLoading}
            />
          ) : (
            <MFAAuthForm loginData={{ email: email, password: newPassword }} />
          )
        ) : (
          ResetCodeForm
        )
      ) : (
        InputForm
      )}
    </AuthFormWrapper>
  )
}

export default AuthFormsNewPasswordForm
