import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { H4, P } from '../../../../shared/components/Typography'
import { PayloadProperty } from '../../components/PayloadProperty'
import { CodeBlock, LeftCol, Paragraph, RightCol, SectionRow, StyledEventName } from '../../Styles'

export const CommonCommands: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H4 id="common-commands">Common Commands</H4>

        <Row>
          <Col span={5}>
            <StyledEventName>common-api</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              <p>Common api type supports mainly 3 call types</p>
              <li style={{ listStyleType: 'none' }}>
                <strong>Config</strong>: Building configuration such as floors, areas etc. can be fetched using config
                call type. Config can be fetched once and on-demand as it is very less likely to change.
              </li>
              <br></br>
              <li style={{ listStyleType: 'none' }}>
                <strong>Ping</strong>: Simple ping API that doesnt affect the system in any way, but can be used to
                check that API is still alive and responsive. Also allows client to figure out network delay and
                difference in system clocks, which will be very helpful to sync the real time movement.
              </li>
              <br></br>
              <li style={{ listStyleType: 'none' }}>
                <strong>Actions</strong>: List of action supported by the group controller. Actions can be fetched once
                and on-demand as it is very less likely to change.
              </li>
            </P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="type"
              type="string"
              required={true}
              description={`Common commands to get the building configuration, get actions supported by a building and ping building to check if it is online`}
              stringEnums={['common-api']}
            />
            <PayloadProperty
              name="callType"
              type="string"
              required={true}
              description={`call type to get building config`}
              stringEnums={['config', 'ping', 'actions']}
              stringEnumDescriptions={[
                {
                  value: 'config',
                  description: `Call type to get the building configuration`,
                },
                {
                  value: 'ping',
                  description: `Request to check if the building is online`,
                },
                {
                  value: 'actions',
                  description: `Request to get the available actions from the building`,
                },
              ]}
              format={`config`}
            />

            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:BUILDING_ID`}
              example={`building:100000123`}
            />

            <PayloadProperty
              name="requestId"
              type="string"
              description={`REQUIRED for action and config commands`}
              example={`23456673`}
            />

            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the group`}
              example={`"1"`}
              comment={'groupId can be any integer. Refer MultiGroup in Glossary'}
            />

            <PayloadProperty name="payload" type="object" description={`REQUIRED only for ping command`} />
            <Row style={{ marginLeft: '60px' }}>
              <Col span="22">
                <PayloadProperty
                  name="request_id"
                  type="number"
                  description={`REQUIRED only for ping command. Unique request ID to pair response to correct request`}
                  example={`1234`}
                  comment={'request ID is of Type: Integer and  Max 10 digits. Max Value: 2147483647'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Request example for building config</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "common-api",
  "requestId": "5e9e1d31-3d2c-42c7-907a-d8874117fb27",
  "buildingId": "building:99900009301",
  "callType": "config",
  "groupId": "1"
}`}
        </CodeBlock>
        <Paragraph>Request example for get building actions</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "common-api",
  "requestId": "5e9e1d31-3d2c-42c7-907a-d8874117fb27",
  "buildingId": "building:99900009301",
  "callType": "actions",
  "groupId": "1"
}`}
        </CodeBlock>
        <Paragraph>Request example to ping the building</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "common-api",
  "buildingId": "building:99900009301",
  "callType": "ping",
  "groupId": "1",
  "payload": {
     "request_id": 1352 
   }
}`}
        </CodeBlock>
        <Paragraph>Acknowledgement response for ping command</Paragraph>

        <CodeBlock language="json">
          {`{
  "connectionId": "PzIhJfgdjoECG1A=",
  "requestId": 1352,
  "statusCode": 201 ,
 "data": {
    "time": "2024-01-12T04:42:59.834Z"
 }
}`}
        </CodeBlock>
        <Paragraph>Acknowledgement response for actions and config commands</Paragraph>

        <CodeBlock language="json">
          {`{
  "connectionId": "PzIhJfgdjoECG1A=",
  "requestId": 5e9e1d31-3d2c-42c7-907a-d8874117fb27,
  // Note that requestId is uuidv4 for actions and config
  "statusCode": 201,
  "data": {
    "time": "2024-01-12T04:42:59.834Z"
 }
}`}
        </CodeBlock>

        <Paragraph>Request payload validation error</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "statusCode": 400,
  "data": { 
    "error": "'callType' is required" 
  }
}`}
        </CodeBlock>
        <Paragraph>Event payload example for ping</Paragraph>

        <CodeBlock language="json">
          {`{
 "data":{
     "request_id":1352222,
     "api_version": "1.1",
     "application_version": "4.4.6.40661",
     "time":"2022-04-08T10:27:08.272Z"
  },
  "callType":"ping",
  "buildingId":"building:1000000831",
  "groupId":"1"
}`}
        </CodeBlock>
        <Paragraph>Event payload example for config</Paragraph>

        <CodeBlock language="json" large={true}>
          {`{
    "requestId":"5e9e1d31-3d2c-42c7-907a-d8874117fb27",
    "callType":"config",
    "buildingId":"building:1000000831",
    "groupId":"1"
    "data" : {       
    "version_major": 3,                                             
    "version_minor": 0,                                             
    "groups": [                                                     
        {
            "group_id": 1,                                          
            "terminals": [ 11, 12, 13 ],                            
            "lifts": [                                              
                {
                    "lift_id": 1,                                   
                    "lift_name": "A",                               
                    "decks": [                                      
                        {
                            "deck": 0,                              
                            "area_id": 1001010,                     
                            "doors": [ 0 ],                         
                            "terminals": [ 1 ]                      
                        },
                        {
                            "deck": 1,
                            "area_id": 1001011,
                            "doors": [ 0 ],
                            "terminals": [ 1 ]
                        }
                    ],
                    "floors": [                                     
                        {
                            "lift_floor_id": 1,                     
                            "group_floor_id": 1,                    
                            "height": 4300,                         
                            "sides": [                              
                                {  
                                    "lift_side": 0,                 
                                    "group_side": 1,                
                                    "decks": [ 0 ]                  
                                }
                            ]
                        },
                        {
                            "lift_floor_id": 2,
                            "group_floor_id": 2,
                            "height": 4300,
                            "sides": [
                                {
                                    "lift_side": 0,
                                    "group_side": 1,
                                    "decks": [ 0, 1 ]               
                                }
                            ]
                        },
                        {
                           "lift_floor_id": 3,
                           "group_floor_id": 4,
                           "height": 4300,
                           "sides": []            // dummy/express floor, no doors here
                        }
                    ]
                },
            ]
        }
    ],
    "destinations": [            
        {
            "area_id": 1010,                                        
            "group_floor_id": 1,                                    
            "group_side": 1,                                        
            "short_name": "G",                                      
            "exit": true,               // BOOLEAN. OPTIONAL. Default = false.
            "terminals": [ 11, 12, 13 ] // ARRAY of Terminal IDs. OPTIONAL. Landing terminals that can be found on this area
        },
    ],
  }
}`}
        </CodeBlock>
        <Paragraph>Event payload example for actions</Paragraph>

        <CodeBlock language="json" large={true}>
          {`{
  "data": {
      "version_major": 1,
      "version_minor": 1,
      "action_set": [],
      "call_types": [
          {
              "action_id": 5000,
              "action_type": "CarCall",
              "direct_allowed": true,
              "enabled": true,
              "group_call_allowed": false,
              "key": 0,
              "name": "CarCall",
              "preferred_allowed": false
          },
          {
              "action_id": 2002,
              "action_type": "LandingCall",
              "direct_allowed": true,
              "enabled": true,
              "group_call_allowed": false,
              "key": 1,
              "name": "LdgCall DOWN",
              "preferred_allowed": false
          },
          {
              "action_id": 2001,
              "action_type": "LandingCall",
              "direct_allowed": true,
              "enabled": true,
              "group_call_allowed": false,
              "key": 2,
              "name": "LdgCall UP",
              "preferred_allowed": false
          },
          {
              "action_id": 2,
              "action_type": "DestinationCall",
              "direct_allowed": true,
              "enabled": true,
              "group_call_allowed": true,
              "key": 10,
              "name": "DcsCall",
              "preferred_allowed": true
          },
          {
              "action_id": 3,
              "action_type": "DestinationCall",
              "direct_allowed": true,
              "enabled": true,
              "group_call_allowed": false,
              "key": 11,
              "name": "DcsCall HAN D",
              "preferred_allowed": true
          }    
      ],
      "functions": [
          {
              "action_id": 4001,
              "action_type": "LandingFunction",
              "enabled": true,
              "name": "Out Of Service function"
          },
          {
              "action_id": 4000,
              "action_type": "LandingFunction",
              "enabled": true,
              "name": "Cleaning function"
          }
      ]
  },
  "requestId": "5e9wee",
  "callType": "actions",
  "buildingId": "building:99900009301",
  "groupId": "1"
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}

export const LiftDoorState: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <Row>
          <Col span={5}>
            <StyledEventName>lift-door-state</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Elevator door state update. There are four possible states, which inform if the door is either closing,
              opening, closed or open.
            </P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty name="type" type="string" required={true} stringEnums={['lift-door-state']} />

            <PayloadProperty
              name="timestamp"
              type="string"
              required={true}
              description={`ISO formatted timestamp of the message`}
            />

            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={` Unique identifier for the building`}
              format={`building:{buildingId}`}
            />

            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the lift group `}
              format={`group:BUILDING_ID:GROUP_ID`}
            />

            <PayloadProperty
              name="liftId"
              type="string"
              required={true}
              description={`Unique identifier for the lift`}
              format={`lift:BUILDING_ID:GROUP_ID:LIFT_ID`}
            />

            <PayloadProperty
              name="deckId"
              type="string"
              required={true}
              description={`Unique identifier for the deck`}
              format={`deck:BUILDING_ID:GROUP_ID:LIFT_ID:DECK_ID`}
            />

            <PayloadProperty
              name="doorId"
              type="string"
              required={true}
              description={`Unique identifier for the door`}
              format={`deck:{buildingId}:{groupId}:{liftId}:{deckId}:{doorId}`}
            />

            <PayloadProperty
              name="state"
              type="string"
              required={true}
              description={`Door state`}
              stringEnums={['closing', 'closed', 'opening', 'open']}
              stringEnumDescriptions={[
                {
                  value: 'closing',
                  description: `When the door is closing`,
                },
                {
                  value: 'closed',
                  description: `The door has already been closed`,
                },
                {
                  value: 'opening',
                  description: `When the door is opening`,
                },
                {
                  value: 'open',
                  description: `When the door is open`,
                },
              ]}
            />

            <PayloadProperty
              name="currentAreaId"
              type="string"
              required={true}
              description={`The area id where the elevator is at a specific moment in time`}
              format={`area:{buildingId}:{areaId}`}
            />
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="typescript">
          {`{
  type: 'lift-door-state',
  timestamp: '2021-01-21T05:56:22.519Z',
  buildingId: 'building:99900009301',
  groupId: 'group:99900009301:1',
  liftId: 'lift:99900009301:1:1',
  deckId: 'deck:99900009301:1:1:0',
  doorId: 'door:99900009301:1:1:0:1',
  state: 'closed',
  currentAreaId: 'area:99900009301:7000'
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}

export const DeckState: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <Row>
          <Col span={5}>
            <StyledEventName>deck-state</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Elevator deck state update. Gives information about its movement state (moving, standing or none), informs
              if the movement is towards the top or the bottom of the shaft (up or down) and gives information about the
              load state of the lift (normal, full, overload).
            </P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty name="type" type="string" required={true} stringEnums={['deck-state']} />

            <PayloadProperty
              name="timestamp"
              type="string"
              required={true}
              description={`ISO formatted timestamp of the message`}
            />

            <PayloadProperty
              name="buildingId"
              type="string"
              required={true}
              description={`Unique identifier for the building`}
              format={`building:{buildingId}`}
            />

            <PayloadProperty
              name="groupId"
              type="string"
              required={true}
              description={`Unique identifier for the lift group `}
              format={`group:BUILDING_ID:GROUP_ID`}
            />

            <PayloadProperty
              name="liftId"
              type="string"
              required={true}
              description={`Unique identifier for the lift`}
              format={`lift:BUILDING_ID:GROUP_ID:LIFT_ID`}
            />

            <PayloadProperty
              name="deckId"
              type="string"
              required={true}
              description={`Unique identifier for the deck`}
              format={`deck:BUILDING_ID:GROUP_ID:LIFT_ID:DECK_ID`}
            />

            <PayloadProperty
              name="movingState"
              type="string"
              required={true}
              description={`The state of the lift in a specific moment in time`}
              stringEnums={['standing', 'moving', 'none']}
            />

            <PayloadProperty
              name="movingDirection"
              type="string"
              required={true}
              description={`The direction of the elevator`}
              stringEnums={['up', 'down']}
            />

            <PayloadProperty
              name="loadState"
              type="string"
              required={true}
              description={`loadState defines the amount of weight that the elevator is currently handling`}
              stringEnums={['normal', 'full', 'overload']}
            />

            <PayloadProperty
              name="loadPercentage"
              type="number"
              required={true}
              description={`The load expressed as a percentage`}
            />

            <PayloadProperty
              name="currentLevelMillimeters"
              type="number"
              required={true}
              description={`The vertical position of the elevator specified in millimeters`}
            />
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "deck-state",
  "timestamp": "2021-01-21T05:56:22.519Z",
  "buildingId": "building:99900009301",
  "groupId": "group:99900009301:1",
  "liftId": "lift:99900009301:1:1",
  "deckId": "deck:99900009301:1:1:0",
  "movingState": "moving",
  "movingDirection": "up",
  "loadState": "normal",
  "loadPercentage": 0,
  "currentLevelMillimeters": 21000
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}

export const OkEvent: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <Row>
          <Col span={5}>
            <StyledEventName>ok</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              Success response for a request identified with the <strong>requestId</strong>. Asynchronous operation is
              accepted and proceeds in the background.
            </P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty name="type" type="string" required={true} description={``} stringEnums={['ok']} />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Matches the requestId specified in the request`}
            />
            <PayloadProperty
              name="connectionId"
              type="string"
              required={true}
              description={`Identifier for a connection from which request was made. Same for all of the requests made within the connection.`}
            />
            <PayloadProperty
              name="statusCode"
              type="number"
              required={true}
              description={`HTTP status code, 200 or 201 for ok type`}
            />
            <PayloadProperty
              name="time"
              type="string (Date-Time)"
              required={true}
              description={`Acknowledgement timestamp of the request received`}
            />
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "ok",
  "requestId": "08c5ff6c-a8fe-405b-bde3-ffcd6935573b",
  "connectionId": "ZLOG3c0SDoECFnQ=",
  "statusCode": 201,
  "time":"2022-04-08T10:27:08.272Z"
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}

export const ErrorEvent: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <Row>
          <Col span={5}>
            <StyledEventName>error</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">Error response if something went wrong with the request</P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty name="type" type="string" required={true} description={``} stringEnums={['error']} />
            <PayloadProperty
              name="requestId"
              type="string"
              required={true}
              description={`Matches the requestId specified in the request`}
            />
            <PayloadProperty
              name="connectionId"
              type="string"
              required={true}
              description={`Identifier for a connection from which request was made. Same for all of the requests made within the connection.`}
            />
            <PayloadProperty
              name="statusCode"
              type="number"
              required={true}
              description={`HTTP status code. (400, 401, 403, 404, 409, 500)`}
            />
            <PayloadProperty name="message" type="string" required={true} description={`Error message`} />
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="json">
          {`{
  "type": "error",
  "requestId": "3ed49fce-a55f-4675-9b79-849a1cab6ec6",
  "connectionId": "amAcVfcBjoECFxg=",
  "statusCode": 409,
  "message": "requestId must by unique within connection"
}`}
        </CodeBlock>
      </RightCol>
    </SectionRow>
  )
}
