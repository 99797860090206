import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'
import { Button } from '../../../shared/components'
import { ReactComponent as FalafelMenu } from '../../../assets/icons-falafel-menu.svg'
import { ReactComponent as Refresh } from '../../../assets/icon-refresh.svg'

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const AppName = styled.p`
  color: ${color.gray20};
  font: normal normal normal 28px/42px KONE Information;
  margin-bottom: 25px;
`

export const CreateButton = styled(Button)`
  margin-top: 10px;
  width: 234px;
`

export const ProfileDetails = styled.div`
  color: ${color.gray10};
`

export const MoreItem = styled(FalafelMenu)`
  cursor: pointer;
  vertical-align: middle;
`

export const ContainerTab = styled.div`
  width: 100%;
`

export const CreateButtonList = styled(Button)`
  margin-top: 10px;
  width: 170px;
  height: 35px;
  margin-left: 370px;
`
export const RefreshTableButton = styled(Button)`
  margin-top: 10px;
  width: 35px;
  height: 35px;
  margin-left: 8px;
`

export const RefreshIcon = styled(Refresh)`
  width: 22px;
  height: 22px;
  margin-top: 6px;
  cursor: pointer;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
