import { OpenAPIV3 } from 'openapi-types'
import React, { FC } from 'react'
import RestDocumentation from '../RestDocumentation'

const EquipmentStatusAPIDocumentation: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const openAPISpec = require(`./equipment-status-api.json`) as OpenAPIV3.Document

  return <RestDocumentation apiName="equipment-status-api" openAPISpec={openAPISpec} />
}

export default EquipmentStatusAPIDocumentation
