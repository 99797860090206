import styled from 'styled-components'

export const StyleLoading = styled.div`
  svg {
    /*svg sizes, layouts*/
    height: 128px;
    width: 128px;
    display: inline-block;
    vertical-align: middle;
    fill: #0071b9;
  }
  #logo {
    transform: translate(18px, 48px);
  }
  .rect {
    fill: #fff /* #0071B9*/;
    height: 22px;
  }
  .txt {
    fill: #fff;
    opacity: 1;
  }
  #k_rect {
    opacity: 0;
    animation: drop1 2s cubic-bezier(0, 0.64, 0.69, 1) 0s infinite normal;
  }
  #o_rect {
    opacity: 0;
    animation: rise1 2s cubic-bezier(0, 0.64, 0.69, 1) 0.5s infinite normal;
  }
  #n_rect {
    opacity: 0;
    animation: drop1 2s cubic-bezier(0, 0.64, 0.69, 1) 1s infinite normal;
  }
  #e_rect {
    opacity: 0;
    animation: rise1 2s cubic-bezier(0, 0.64, 0.69, 1) 1.5s infinite normal;
  }

  @keyframes drop1 {
    0% {
      opacity: 0;
      transform: translateY(-36px);
    }
    20% {
      opacity: 0;
      transform: translateY(-36px);
      -webkit-filter: url('#blur1');
      filter: url('#blur1');
    }
    54% {
      transform: translateY(0);
      -webkit-filter: url('#blur2');
      filter: url('#blur2');
    }
    68% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes rise1 {
    0% {
      opacity: 0;
      transform: translateY(36px);
    }
    20% {
      opacity: 0;
      transform: translateY(36px);
      -webkit-filter: url('#blur1');
      filter: url('#blur1');
    }
    54% {
      transform: translateY(0);
      -webkit-filter: url('#blur2');
      filter: url('#blur2');
    }
    68% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #k_txt {
    opacity: 0;
    animation: fade1 10s cubic-bezier(0, 0.64, 0.69, 1) 0s infinite normal;
  }
  #o_txt {
    opacity: 0;
    animation: fade2 10s cubic-bezier(0, 0.64, 0.69, 1) 0s infinite normal;
  }
  #n_txt {
    opacity: 0;
    animation: fade3 10s cubic-bezier(0, 0.64, 0.69, 1) 0s infinite normal;
  }
  #e_txt {
    opacity: 0;
    animation: fade4 10s cubic-bezier(0, 0.64, 0.69, 1) 0s infinite normal;
  }
  @keyframes fade1 {
    72% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade2 {
    77% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade3 {
    82% {
      opacity: 0;
    }
    85% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade4 {
    88% {
      opacity: 0;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
