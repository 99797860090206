import styled from 'styled-components'
import Button from '../../../shared/components/Button'
import { color } from '../../../shared/utils/styles'
import { Input } from 'antd'

export const Line = styled.hr`
  margin-top: 34px;
  margin-bottom: 48px;
  border: 1px solid ${color.gray70};
  opacity: 1;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FormLabel = styled.label`
  margin-bottom: 8px;
  display: block;
`

export const InputId = styled(Input)`
  width: 50%;
  color: ${color.gray75};
`

export const CancelButton = styled(Button)`
  width: 194px;
`

export const CreateButton = styled(Button)`
  width: 208px;
`
