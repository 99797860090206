import styled from 'styled-components'
import { Button } from '../../../shared/components'
import { color, size } from '../../../shared/utils/styles'
import { Tabs } from 'antd'

export const UpperPageContainer = styled.div`
  max-width: 1234px;
  margin: 0 auto;
  background-color: ${color.gray90};
  color: ${color.gray20};
  min-height: 280px;
  margin-top: 74px;

  @media (max-width: ${size.large}) {
    padding-bottom: 20px;
    margin: 54px 30px 0 30px;
  }
`

export const Sticky = styled.div`
  position: sticky;
  top: 50px;
  z-index: 2;
`

export const InnerPageContainer = styled.div`
  max-width: 920px;
  justify-content: center;
  margin: -70px auto 56px auto;
  position: relative;
`

export const CardContainer = styled.div`
  background-color: ${color.gray80};
  opacity: 0.92;
  margin-bottom: 80px;
  padding-top: 52px;
  padding-bottom: 56px;

  @media (max-width: ${size.large}) {
    margin-bottom: 20px;
  }
`

export const CardContent = styled.div`
  max-width: 772px;
  margin: 0 auto;

  @media (max-width: ${size.large}) {
    margin: 0 30px;
  }
`

export const ContactButtonArea = styled.div`
  padding-top: 50px;
  text-align: center;
  @media (max-width: ${size.large}) {
    font: normal normal normal 24px/30px KONE Information;
    padding-top: 25px;
  }
`

export const ContactUsButton = styled(Button)`
  width: 160px;
  height: 40px;
  text-transform: uppercase;
`

export const StyledTabs = styled(Tabs)`
  width: 100%;
  color: ${color.gray20};

  .ant-tabs-tab {
    margin-right: 50px;
    margin-left: 50px;
    padding-bottom: 5px;
  }

  .ant-tabs-nav-list {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .ant-tabs-ink-bar {
    background: ${color.blueBase};
    height: 3px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${color.white} !important;
  }
  .ant-tabs-tab:hover {
    color: ${color.white};
  }
  .ant-tabs-tab-btn:active {
    color: ${color.gray10};
  }

  &.ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }

  &.ant-tabs-nav-operations-hidden {
    display: none;
  }
`

export const TabsContainer = styled.div`
  margin-top: -50px;
`
