import styled from 'styled-components'
import { color } from '../../../../shared/utils/styles'

export const TypeContainer = styled.div`
  width: 100px;
`

export const InfoContainer = styled.div`
  display: flex;
  cursor: pointer;
  span {
    color: ${color.blueTint20};
  }

  svg .a {
    fill: ${color.blueTint20};
  }
`

export const NameContainer = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
`
export const IDContainer = styled.div`
  width: 130px;
`

export const EditContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 15px;
  span {
    color: ${color.blueTint20};
  }

  svg .a {
    fill: ${color.blueTint20};
  }
`

export const RemoveContainer = styled.div`
  display: flex;
  margin-left: 15px;
  cursor: pointer;
  span {
    color: ${color.apiRed};
  }

  svg .a {
    fill: ${color.apiRed};
  }
`

export const EditButton = styled.span`
  font-size: 14px;
  padding-left: 6px;
  padding-right: 0;
  text-transform: uppercase;
  color: ${color.blueBase};
`

export const ProductsContainer = styled.div`
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 15px;
`

export const ProductNumber = styled.div`
  margin-right: 4px;
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  background: #0071b9;
  color: white;
  text-align: center;
  box-sizing: content-box;
  white-space: nowrap;
  font-size: 14px;
`

export const ProductsText = styled.div`
  position: absolute;
  background: #3d464cf7;
  text-align: center;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  margin-top: -22px;
  margin-left: 50px;
  padding: 0px 5px;
  z-index: 1;
  white-space: normal;
  width: 220px;
`

export const DescriptionText = styled.div`
  position: absolute;
  background: #3d464cf7;
  text-align: center;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  margin-top: -22px;
  margin-left: 50px;
  padding: 0px 5px;
  z-index: 1;
  white-space: normal;
  width: 220px;
`

export const View = styled.span`
  font-size: 14px;
  padding-left: 6px;
  padding-right: 0;
  text-transform: uppercase;
  color: ${color.blueBase};
  display: flex;
  cursor: pointer;
`

export const Edit = styled.span`
  font-size: 14px;
  padding-left: 2px;
  padding-right: 0;
  text-transform: uppercase;
  color: ${color.blueBase};
  display: flex;
  cursor: pointer;
`

export const Remove = styled.span`
  font-size: 14px;
  padding-left: 2px;
  padding-right: 0;
  text-transform: uppercase;
  color: ${color.blueBase};
  display: flex;
  cursor: pointer;
`
