import { Tabs } from 'antd'
import React, { FC } from 'react'
import { AsyncApiProps } from '..'
import { TabbedCodeContainer } from '../../components/TabbedCodeContainer'
import { CodeBlock, LeftCol, RightCol, SectionRow, Text } from '../../Styles'
import { H4, P, UL, OL } from '../../../../shared/components/Typography'

const TabPane = Tabs.TabPane

export interface UsageExampleProps {
  asyncApiProps: AsyncApiProps
}

export const UsageExample: FC<UsageExampleProps> = ({ asyncApiProps }) => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H4 id="usage-example">Usage example</H4>
        <P size="small">
          Once you have acquired the proper access token with the client credential flow, you can start making requests
          to {asyncApiProps.apiName}. The steps are simple:
          <OL size="small">
            <li>Open up the WebSocket connection using the accessToken as a query parameter</li>
            <li>Generate call payload, and within the WebSocket connection send the payload down the stream</li>
            <li>
              Start receiving event updates about the call. Received events can be configured with the monitorEvents
              property in the call payload.
            </li>
          </OL>
        </P>

        <P size="small">{`Important note: Upon WebSocket handshake, client must provide Sec-WebSocket-Protocol header. This is currently a constant string: 'koneapi'. 
        See the usage example code.`}</P>

        <P size="small">
          <UL size="small">
            <li>
              <Text code>{`https://${window.location.hostname}/stream-v1`}</Text>
            </li>
          </UL>
        </P>
      </LeftCol>
      <RightCol span="12">
        <TabbedCodeContainer>
          <TabPane tab="Typescript" key="1">
            <CodeBlock language="typescript">
              {`// open the connection
const ws = new WebSocket('wss://${window.location.hostname}/stream-v1?accessToken=ACCESS_TOKEN', 'koneapi')

// create payload
const liftCallPayload = {
  type: 'lift-call',
  callType: '${asyncApiProps.callType}',
  callAction: 'destination',
  requestId: '01841d1c-f4ba-4f9c-a348-6f679bfae86e',
  buildingId: 'building:99900009301',
  sourceId: 'area:99900009301:1000',
  destinationId: 'area:99900009301:2000',
  monitorEvents: ['call'] // 'deck', 'door'
}

// within the connection, send the payload
ws.send(JSON.stringify(liftCallPayload))

ws.on('message', (data: any) => {
  // messages received from the device
})`}
            </CodeBlock>
          </TabPane>
        </TabbedCodeContainer>

        <P size="small">
          The websocket connection will be automatically disconnect after 60 seconds of inactivity. And after 2 hours
          even it has been active.
        </P>
      </RightCol>
    </SectionRow>
  )
}
