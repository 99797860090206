import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { ApiError } from '../../../shared/models/error'
import { OrganizationsAPI } from '../../../shared/api/organizations'
import { ErrorMessage, Toast } from '../../../shared/components'
import { CreateOrganizationData, Organization } from '../../../shared/models/organization'
import countries from 'country-code-lookup'

import {
  ButtonsContainer,
  CancelButton,
  CreateButton,
  FormLabel,
  InputName,
  Line,
  TypeSelector,
  InputsContainer,
  InputRowContainer,
  InputColumn,
  SalesForceInput,
} from './Styles'
import { CreateFormStrings } from '../../../shared/strings/OrganizationsContent'
import { GeneralFormStrings } from '../../../shared/strings/GeneralFormContent'
import { APP_NAME_MAX_LENGTH, APP_NAME_MIN_LENGTH, APP_NAME_REG, EMAIL_REG_EXP } from '../../../shared/constants/auth'
import { ErrorStrings } from '../../../shared/strings/AuthFormContent'

const { Option } = TypeSelector
type Props = {
  onCancelCreate: () => void
  onCreateSuccessful: (organization: Organization) => void
}

const OrganizationsCreateForm: FC<Props> = ({ onCancelCreate, onCreateSuccessful }) => {
  const [isCreateErrorVisible, setIsCreateErrorVisible] = useState(false)
  const [createOrgErrorMessage, setCreateOrgErrorMessage] = useState<string | undefined>()
  const [orgType, setOrgType] = useState<string>('')
  const [countryCode, setCountryCode] = useState<number>(0)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      salesforceAccountId: '',
      distributionListEmailAddress: '',
    },
  })
  const {
    mutateAsync: createOrganization,
    isLoading,
    data,
  } = useMutation<Organization, ApiError, CreateOrganizationData>(OrganizationsAPI.create)
  const queryClient = useQueryClient()

  const onTypeChange = (data: any) => {
    setOrgType(data)
  }

  const onCountryChange = (isoNo: any, value: any) => {
    setCountryCode(Number(isoNo))
    setSelectedCountry(value)
  }

  const filterOption = (input: any, option: any) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    }
    return false
  }

  const getCountries = () => {
    return countries.countries.map((countryItem) => {
      return (
        <Option
          key={countryItem.isoNo}
          id={countryItem.country.replace('/ ./g', '-') + '.js'}
          value={countryItem.isoNo}
        >
          {countryItem.country}
        </Option>
      )
    })
  }
  const onSubmit = async (data: CreateOrganizationData) => {
    setIsCreateErrorVisible(false)
    data.type = orgType ?? 'external'
    data.country = countryCode ?? undefined
    setIsSubmitted(true)

    if (!selectedCountry) {
      return
    }

    try {
      const newOrganization = await createOrganization(data)
      queryClient.invalidateQueries(OrganizationsAPI.queryNames.GET_ALL)
      onCreateSuccessful(newOrganization)
      reset()
    } catch (err) {
      let message = ''
      err?.response?.data?.errors?.forEach((val: any) => {
        message = val.message + ' '
      })
      setCreateOrgErrorMessage((message || err?.response?.data?.message) ?? CreateFormStrings.createOrganizationError)
      console.error(err)
      setIsCreateErrorVisible(true)
    }
  }

  return (
    <div>
      <Toast
        isVisible={isCreateErrorVisible}
        text={createOrgErrorMessage}
        onDismiss={() => setIsCreateErrorVisible(false)}
        type="error"
      />
      <form id="org-create-form-js" onSubmit={handleSubmit(onSubmit)}>
        <InputsContainer>
          <FormLabel>Organization Type</FormLabel>
          <TypeSelector
            id="organization-type-selector-js"
            defaultValue="Select type"
            onChange={onTypeChange}
            disabled={isLoading}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Option id="selector-customer-option-js" value="external">
              {CreateFormStrings.externalOrgType}
            </Option>
            <Option id="selector-internal-option-js" value="internal">
              {CreateFormStrings.internalOrgType}
            </Option>
            <Option id="selector-internal-option-js" value="sandbox">
              {CreateFormStrings.sandboxOrgType}
            </Option>
          </TypeSelector>
          <InputRowContainer>
            <InputColumn>
              <FormLabel htmlFor="organization-input-js" id="organization-input-label-js">
                {CreateFormStrings.organizationName}
              </FormLabel>
              <Controller
                render={({ field }) => <InputName id="organization-input-js" {...field} disabled={!orgType ?? true} />}
                name="name"
                control={control}
                rules={{
                  required: true,
                  pattern: { value: APP_NAME_REG, message: CreateFormStrings.orgNameWhiteSpaceError },
                  maxLength: { value: APP_NAME_MAX_LENGTH, message: CreateFormStrings.orgNameLengthError },
                  minLength: { value: APP_NAME_MIN_LENGTH, message: CreateFormStrings.orgNameLengthError },
                }}
              />
              {errors.name && (
                <ErrorMessage>{errors.name.message || CreateFormStrings.organizationNameRequiredError}</ErrorMessage>
              )}
              {data && !isDirty && <p>{CreateFormStrings.organizationCreatedSuccess}</p>}
            </InputColumn>
            <InputColumn>
              <FormLabel htmlFor="salesforce-input-js" id="salesforce-input-label-js">
                KONE Customer ID in Salesforce CRM
              </FormLabel>
              <div>
                <Controller
                  render={({ field }) => (
                    <SalesForceInput id="salesforce-input-js" {...field} disabled={!orgType ?? true} />
                  )}
                  name="salesforceAccountId"
                  control={control}
                />
                {errors.salesforceAccountId && <ErrorMessage>{errors.salesforceAccountId.message}</ErrorMessage>}
              </div>
            </InputColumn>
          </InputRowContainer>
          <InputRowContainer>
            <InputColumn>
              <FormLabel>Country</FormLabel>
              <TypeSelector
                showSearch
                id="country-selector-js"
                defaultValue="Select Country"
                onChange={onCountryChange}
                disabled={!orgType ?? true}
                getPopupContainer={(trigger) => trigger.parentNode}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                {getCountries()}
              </TypeSelector>
              {isSubmitted && !selectedCountry && <div style={{ color: 'red' }}>Please Select Country</div>}
            </InputColumn>
            <InputColumn>
              <InputColumn>
                <div style={{ marginLeft: '30px' }}>
                  <FormLabel htmlFor="distribution-list-email-address" id="distribution-list-email-address-id">
                    {CreateFormStrings.distributionList}
                  </FormLabel>
                  <div>
                    <Controller
                      render={({ field }) => <InputName id="distribution-list-email-address-input" {...field} />}
                      name="distributionListEmailAddress"
                      control={control}
                      rules={{
                        pattern: {
                          value: EMAIL_REG_EXP,
                          message: ErrorStrings.invalidURL,
                        },
                      }}
                    />
                    {errors.distributionListEmailAddress && (
                      <ErrorMessage id="email-error-message-js">
                        {errors.distributionListEmailAddress.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>
              </InputColumn>
            </InputColumn>
          </InputRowContainer>
        </InputsContainer>
        <Line />
        <ButtonsContainer>
          <CancelButton id="cancel-create-org-button-js" onClick={onCancelCreate} ghost disabled={isLoading}>
            {GeneralFormStrings.cancelAndReturnToDashboard}
          </CancelButton>
          <CreateButton
            id="create-org-button-js"
            type="primary"
            htmlType="submit"
            disabled={!isDirty}
            loading={isLoading}
          >
            {CreateFormStrings.createOrganization}
          </CreateButton>
        </ButtonsContainer>
      </form>
    </div>
  )
}

export default OrganizationsCreateForm
