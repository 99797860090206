import styled, { css } from 'styled-components'
import { Button } from '../../../../../shared/components'
import { color } from '../../../../../shared/utils/styles'
import { ReactComponent as CaretDown } from '../../../../../assets/icon-caret-down.svg'
import { ReactComponent as CaretUp } from '../../../../../assets/icon-caret-up.svg'

export const ProductSelectionBase = styled.div`
  .ant-select-arrow {
    top: 30%;
    right: 18px;
    color: #0071b9;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 34px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    bottom: 2px;
    font-size: 12px;
  }
`

export const ProductSelectionAuto = styled(ProductSelectionBase)`
  margin-top: -16px;
  overflow: visible !important;
  position: absolute;
  text-align: left;
  margin-bottom: 35px;
  width: 200px;
`

const rowCommonStyle = css`
  color: ${color.gray10};
  max-width: 1388px;
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
`

export const RowContentBase = styled.div`
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`

export const RowContentSmall = styled(RowContentBase)`
  flex-basis: 12%;
`

export const RowContentLarge = styled(RowContentBase)`
  flex-basis: 40%;
`

export const RowContentMedium = styled(RowContentBase)`
  flex-basis: 28%;
`

export const RowContentSmallL = styled(RowContentBase)`
  flex-basis: 20%;
`

export const HeaderRow = styled.div`
  ${rowCommonStyle};
  background: ${color.gray80} 0% 0% no-repeat padding-box;
  font-size: 14px;
  border-top: 1px solid ${color.gray70};
  margin-bottom: 0px;
  padding: 8px 8px 8px 40px;
`

export const RowExpand = styled.div`
  padding-right:12px;
}
`

export const AddRowButton = styled(Button)`
  width: 40px;
  padding: 0px;
`

export const SwitchContainer = styled.div`
  display: flex;
  margin-left: 15px;
  align-items: center;
`

export const TableRow = styled.div`
  color: ${color.gray10};
  margin-top: 0px;
`

export const Row = styled.div`
  ${rowCommonStyle};
  background-color: ${color.gray70};
  min-height: 48px;
  margin-bottom: 2px;
  border-radius: 4px;
  display: flex;
`

export const DatePickerContainer = styled.div`
  margin-left: 10px;
`

export const IconExpand = styled(CaretDown)`
  cursor: pointer;
  vertical-align: middle;
`

export const IconHide = styled(CaretUp)`
  cursor: pointer;
  vertical-align: middle;
`

export const AddContainer = styled.div`
  width: 90px;
  display: flex;
  align-content: space-around;
  flex-direction: row;
  justify-content: space-evenly;
  cursor: pointer;
  margin-left: 15px;
  span {
    color: ${color.blueTint20};
  }

  svg .a {
    fill: ${color.blueTint20};
  }

  svg {
    height: 30px;
    width: 30px;
  }
`

export const Loader = styled.div`
  margin-left: 1px;
`
