import React, { FC, useContext, useState } from 'react'
import { PartnerStrings } from '../../../../shared/strings/PartnersContent'
import {
  TitleLabel,
  PartnerP,
  StyledLine,
  StyledTabs,
  TabsContainer,
  Container,
  FormButton,
  AddMoreVideo,
  RemoveIconPartner,
  SaveChanges,
  CancelButton,
} from './Styles'
import { ErrorMessage, Toast } from '../../../../shared/components'
import { Col, Row } from 'antd'
import { FormLabel, InputName } from '../Styles'
import { Video, Document } from '../../Models'
import { useForm } from 'react-hook-form'
import { SalesPageAPI } from '../../../../shared/api/salespage'
import { PartnerContext } from '../state'
import { useHistory } from 'react-router-dom'
import { PARTNER_WEBSITE_LINK_REGX } from '../../../../shared/constants/auth'

const { TabPane } = StyledTabs

export const IntegratorLinks: FC = () => {
  const defaultVideo: Video = {
    fileName: '',
    videoUrl: '',
  }

  const defaultDocument: Document = {
    fileName: '',
    documentUrl: '',
  }

  const [videoList, setVideoList] = useState<Video[]>([{ ...defaultVideo }])
  const [docList, setDocList] = useState<Document[]>([{ ...defaultDocument }])
  const [button, setButtonVisible] = useState(true)
  const [isUrl, setIsUrlMessage] = useState(false)
  const [isValidYoutubeUrl, setIsValidYoutubeMessage] = useState(false)
  const [isValidDocUrl, setIsValidDocMessage] = useState(false)
  const [isShowSuccessView, setIsShowSuccessView] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean | false>(false)
  const { handleSubmit } = useForm()
  const { partner, setPartner } = useContext(PartnerContext)
  const [err, setErr] = useState<string[]>([])
  const [errDoc, setErrDoc] = useState<string[]>([])

  const history = useHistory()

  const onClickAddMoreVideo = () => {
    setButtonVisible(false)
    setVideoList((preState: any) => [...preState, { ...defaultVideo }])
  }

  const onRemoveButtonClickVideo = (index: number) => {
    const tempLinks = [...videoList]
    tempLinks.splice(index, 1)
    setVideoList(tempLinks)
  }

  const onInputChangeVideo = (e: any, index: number, key: string) => {
    setIsValidYoutubeMessage(false)
    const { value } = e.target

    const tempVideos: Video[] = [...videoList]
    tempVideos[index] = { ...tempVideos[index], [key]: value }

    // validate correct youtube url
    if (tempVideos[index].videoUrl && tempVideos[index].videoUrl.length !== 0) {
      if (!testYoutubeRegex(tempVideos[index].videoUrl)) {
        setIsValidYoutubeMessage(true)
        err[index] = 'error'
      } else {
        err[index] = ''
      }
    }

    setVideoList(tempVideos)
  }

  function isVideoListEmpty(videoList: Video[]) {
    for (let video = 0; video < videoList.length; video++) {
      if (videoList[video].fileName.trim() === '' || videoList[video].videoUrl.trim() === '') {
        return true
      }
    }
    return false
  }

  function testYoutubeRegex(value: string) {
    const regex = /^((http(s)?:\/\/)?)(www\.)?((youtube\.com\/)|(youtu.be\/))[\S]+/.test(value)
    return regex
  }

  const getIntegratorVideoComponent = (video: Video, index: number) => {
    return (
      <>
        <Container>
          <Row gutter={34} wrap={false}>
            <Col span={9}>
              <FormLabel>{PartnerStrings.fileLabel}</FormLabel>
              <InputName
                id={`fileName${index}`}
                placeholder={'Enter File Name'}
                value={video?.fileName}
                onChange={(e: any) => onInputChangeVideo(e, index, 'fileName')}
              />
            </Col>
            <Col span={18}>
              <FormLabel>{PartnerStrings.urlLabel}</FormLabel>
              <InputName
                id={`videoUrl${index}`}
                placeholder={PartnerStrings.enterVideoUrlPlaceHolder}
                value={video?.videoUrl.trim()}
                onChange={(e: any) => onInputChangeVideo(e, index, 'videoUrl')}
              />
              {isValidYoutubeUrl && err[index] === 'error' && (
                <ErrorMessage>{PartnerStrings.isValidYoutube}</ErrorMessage>
              )}
            </Col>
            <Col span={8}>
              <Row gutter={20}>
                <Col span={5}>
                  <FormButton
                    type="primary"
                    onClick={onClickAddMoreVideo}
                    disabled={
                      index + 1 < videoList.length ||
                      videoList.length > 4 ||
                      isValidYoutubeUrl ||
                      isVideoListEmpty(videoList)
                    }
                  >
                    <AddMoreVideo />
                  </FormButton>
                </Col>
                <Col span={5}>
                  <FormButton
                    type="primary"
                    onClick={() => onRemoveButtonClickVideo(index)}
                    disabled={videoList.length === 1}
                    style={{ marginLeft: '8px' }}
                  >
                    <RemoveIconPartner />
                  </FormButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  const onClickAddMoreDocument = () => {
    setButtonVisible(false)
    setDocList((preState: any) => [...preState, { ...defaultDocument }])
  }

  const onRemoveButtonClickDocument = (index: number) => {
    const tempLinks = [...docList]
    tempLinks.splice(index, 1)
    setDocList(tempLinks)
  }

  function testDocumentLinkRegex(value: string) {
    const regex = PARTNER_WEBSITE_LINK_REGX.test(value)
    return regex
  }

  const onInputChangeDocument = (e: any, index: number, key: string) => {
    setIsValidDocMessage(false)

    const { value } = e.target

    const tempDocs: Document[] = [...docList]
    tempDocs[index] = { ...tempDocs[index], [key]: value }

    // validate correct youtube url
    if (tempDocs[index].documentUrl && tempDocs[index].documentUrl.length !== 0) {
      if (!testDocumentLinkRegex(tempDocs[index].documentUrl)) {
        setIsValidDocMessage(true)
        errDoc[index] = 'error'
      } else {
        errDoc[index] = ''
      }
    }

    setDocList(tempDocs)
  }

  function isDocListEmpty(docList: Document[]) {
    for (let document = 0; document < docList.length; document++) {
      if (docList[document].fileName === '' || docList[document].documentUrl === '') {
        return true
      }
    }
    return false
  }

  const getIntegratorDocumentComponent = (document: Document, index: number) => {
    return (
      <>
        <Container>
          <Row gutter={34} wrap={false}>
            <Col span={9}>
              <FormLabel>{PartnerStrings.fileLabel}</FormLabel>
              <InputName
                id={`fileName${index}`}
                placeholder={PartnerStrings.enterFileName}
                value={document?.fileName}
                onChange={(e: any) => onInputChangeDocument(e, index, 'fileName')}
              />
            </Col>
            <Col span={18}>
              <FormLabel>{PartnerStrings.urlLabel}</FormLabel>
              <InputName
                id={`documentUrl${index}`}
                placeholder={PartnerStrings.enterDocumentUrlPlaceHolder}
                value={document?.documentUrl}
                onChange={(e: any) => onInputChangeDocument(e, index, 'documentUrl')}
              />
              {isValidDocUrl && errDoc[index] === 'error' && (
                <ErrorMessage>{PartnerStrings.isValidDocUrl}</ErrorMessage>
              )}
            </Col>
            <Col span={8}>
              <Row gutter={20}>
                <Col span={5}>
                  <FormButton
                    type="primary"
                    onClick={onClickAddMoreDocument}
                    disabled={
                      index + 1 < docList.length || docList.length > 4 || isDocListEmpty(docList) || isValidDocUrl
                    }
                  >
                    <AddMoreVideo />
                  </FormButton>
                </Col>
                <Col span={5}>
                  <FormButton
                    type="primary"
                    onClick={() => onRemoveButtonClickDocument(index)}
                    disabled={docList.length === 1}
                    style={{ marginLeft: '8px' }}
                  >
                    <RemoveIconPartner />
                  </FormButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  //TODO:: to improvise this later
  function isAnythingIncomplete() {
    for (let document = 0; document < docList.length; document++) {
      if (!(docList[document].fileName && docList[document].documentUrl)) {
        if (document == 0 && docList[document].fileName === '' && docList[document].documentUrl === '') {
          // do not return false as it is needed for UI to show editable components
        } else {
          return true
        }
      }
    }
    for (let video = 0; video < videoList.length; video++) {
      if (!(videoList[video].fileName && videoList[video].videoUrl)) {
        if (video == 0 && videoList[video].fileName === '' && videoList[video].videoUrl === '') {
          // do not return false as it is needed for UI to show editable components
        } else {
          return true
        }
      }
    }
    return false
  }

  async function onSubmit() {
    setIsLoading(true)
    const data = {
      document_object_id: {
        videos: isVideoListEmpty(videoList) === true ? [] : videoList,
        documents: isDocListEmpty(docList) === true ? [] : docList,
      },
      partnerStep: 4,
    }
    try {
      const res = await SalesPageAPI.editIntegrator(data, partner.partnerId)
      setIsLoading(false)

      // when the url entered is invalid
      if (!res.ok) {
        setIsUrlMessage(true)
        return
      }

      setIsShowSuccessView(true)
      setTimeout(() => {
        history.push(`/dashboard/partner-details/${partner.partnerId}`)
      }, 2000)
    } catch (e: any) {
      setIsShowSuccessView(false)
    }
  }

  const onBack = () => {
    history.push('/dashboard/partners')
  }

  return (
    <>
      <Toast
        isVisible={isShowSuccessView}
        text={isShowSuccessView ? 'Links Updated Successfully' : 'Something went wrong'}
        onDismiss={() => setIsShowSuccessView(false)}
        type="success"
        allowWithTime={true}
      />
      <Toast
        isVisible={isUrl && !isShowSuccessView}
        text={PartnerStrings.fileNameError}
        onDismiss={() => setIsUrlMessage(false)}
        type="error"
        allowWithTime={true}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <PartnerP>{PartnerStrings.integratorLinks}</PartnerP>
        <StyledLine />
        <TabsContainer>
          <StyledTabs centered={true} defaultActiveKey={'video'} destroyInactiveTabPane={true}>
            <TabPane tab="Videos" key="video">
              <TitleLabel style={{ marginLeft: '50px' }}>{PartnerStrings.enterVideoUrl}</TitleLabel>
              {videoList?.map((value: Video, index: number) => getIntegratorVideoComponent(value, index))}
            </TabPane>
            <TabPane tab="Documents" key="documents">
              <TitleLabel style={{ marginLeft: '50px' }}>{PartnerStrings.enterDocumentUrl}</TitleLabel>
              {docList?.map((value: Document, index: number) => getIntegratorDocumentComponent(value, index))}
            </TabPane>
          </StyledTabs>
        </TabsContainer>
        <div>
          <CancelButton type="primary" onClick={onBack}>
            Cancel
          </CancelButton>
          <SaveChanges
            id="open-create-org-button-js"
            htmlType="submit"
            type="primary"
            loading={isLoading}
            disabled={
              (isVideoListEmpty(videoList) === true && isDocListEmpty(docList) === true) ||
              isAnythingIncomplete() ||
              isValidDocUrl ||
              isValidYoutubeUrl
            }
          >
            Save Changes
          </SaveChanges>
        </div>
      </form>
    </>
  )
}
