import { useEffect, useState } from 'react'

export const useIsDashboard = (): boolean => {
  const [isDashboardView, setIsDashboardView] = useState(false)

  useEffect(() => {
    const pathname = location.pathname.toLowerCase()
    const isDashboard = pathname.includes('dashboard')

    setIsDashboardView(isDashboard)
  }, [location.pathname])

  return isDashboardView
}
