import React from 'react'
import ReactECharts from 'echarts-for-react'

interface PieChartProps {
  data: any
  objmap: any
}

const PieChart: React.FC<PieChartProps> = ({ data, objmap }) => {
  if (!data) {
    return <div>Loading...</div>
  }

  const seriesData = Object.keys(data).map((primaryFilter) => ({
    name: objmap[primaryFilter] || primaryFilter,
    value: data[primaryFilter],
  }))

  // Formatter function based on conditions
  const getFormatterSuffix = (name: string) => {
    if (name === 'Elevator Call API' || name === 'Service Robot API') {
      return ' {Group}'
    } else if (name === 'Equipment Status API' || name === 'Service Info API') {
      return ' {ken}'
    }
    return ''
  }

  const option = {
    color: ['#e3e5e6', '#6e99b4', '#0071b9', '#338dc7', '#aaafb3'],
    tooltip: {
      trigger: 'item',
      formatter: (params: any) => {
        const suffix = getFormatterSuffix(params.name)
        return `${params.seriesName} <br/>${params.name}: ${params.value}${suffix} (${params.percent}%)`
      },
    },
    legend: {
      textStyle: {
        color: '#e3e5e6',
      },
      selectedMode: false,
    },
    series: [
      {
        name: 'RingChart',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: 'outside',
          formatter: (params: any) => {
            const suffix = getFormatterSuffix(params.name)
            return `${params.name}: ${params.value}${suffix}`
          },
          color: '#e3e5e6',
        },
        labelLine: {
          show: true, // Ensure the label lines are shown
          length: 30, // Control the length of the first segment of the line
          length2: 5, // Control the length of the second segment (after the turning point)
          smooth: false, // Optionally make the line curved or straight
          lineStyle: {
            width: 2,
            color: '#e3e5e6',
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '20',
            fontWeight: 'bold',
          },
        },
        data: seriesData,
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '500px' }} />
}

export default PieChart
