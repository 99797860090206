import styled from 'styled-components'
import { size, color } from '../../shared/utils/styles'

export const Circle = styled.div`
  color: ${color.white};
  height: 82px;
  width: 82px;
  background-color: ${color.blueBase};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  @media (max-width: ${size.small}) {
    height: 42px;
    width: 42px;
  }
`

export const StepBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 518px;
  margin: 50px auto;
  @media (max-width: ${size.small}) {
    max-width: initial;
    margin: 50px 0px;
  }
`

export const NumberText = styled.div`
  font: normal normal normal 40px/42px KONE Information;
  @media (max-width: ${size.small}) {
    font: normal normal normal 24px/36px KONE Information;
  }
`

export const VerticalText = styled.div`
  overflow: auto;
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 0 20px 50px;
  max-width: 385px;

  && h3 {
    margin-top: 1.2rem;
  }

  @media (max-width: ${size.small}) {
    max-width: 250px;
    margin: 0 0 0 30px;

    && h3 {
      margin-top: 0.3rem;
    }
  }
`

export const PageContainer = styled.div`
  max-width: 830px;
  justify-content: center;
  margin: 20px auto 0px;
  align-items: stretch;
  @media (max-width: ${size.small}) {
    justify-content: flex-start;
  }
`
