import React, { FC, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Dropdown } from 'antd'
import {
  Row,
  OrgRowContent,
  DropdownGlobalStyle,
  StyledDropdownMenu,
  OrgRowContentLastCell,
  OrgRowContentLarge,
  MoreItem,
} from '../Styles'
import { SimplifiedBuilding } from '../../../shared/models/building'
import { BuildingsAPI } from '../../../shared/api/buildings'
import { Toast } from '../../../shared/components'
import { BuildingsStrings } from '../../../shared/strings/BuildingsContent'
import { ResourceOrg, ResourceOrgList } from '../../../shared/models/resource'

type Props = {
  building: SimplifiedBuilding
  resourceOrg: ResourceOrgList
}

const BuildingTable: FC<Props> = ({ building, resourceOrg }) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const { mutateAsync: refreshBuildingConfiguration, isLoading } = useMutation(
    BuildingsAPI.refreshBuildingConfiguration
  )
  const queryClient = useQueryClient()

  const onClickRefresh = async (building: SimplifiedBuilding) => {
    setIsErrorVisible(false)

    try {
      await refreshBuildingConfiguration(building.buildingId)
      queryClient.invalidateQueries(BuildingsAPI.queryNames.LIST)
    } catch (err) {
      setIsErrorVisible(true)
    }
  }

  const getMoreMenuItems = () => {
    return (
      <StyledDropdownMenu>
        <Toast
          position="absolute"
          isVisible={isErrorVisible}
          text={BuildingsStrings.refreshConfigurationError}
          onDismiss={() => setIsErrorVisible(false)}
          type="error"
        />
        <StyledDropdownMenu.Item
          id="refresh-building-button-js"
          key="refresh-building"
          onClick={() => onClickRefresh(building)}
        >
          Refresh building data
        </StyledDropdownMenu.Item>
      </StyledDropdownMenu>
    )
  }
  const getOrgName = (buildingId: string) => {
    return resourceOrg
      .filter((resource: ResourceOrg) => {
        return buildingId.split(':')[0] === 'building'
          ? resource.uniqueId === buildingId.split(':')[1]
          : resource.uniqueId === buildingId
      })
      .map((resource: ResourceOrg) => {
        return resource.organization
      })
  }

  const getName = (buildingId: string) => {
    return resourceOrg
      .filter((resource: ResourceOrg) => {
        return buildingId.split(':')[0] === 'building'
          ? resource.uniqueId === buildingId.split(':')[1]
          : resource.uniqueId === buildingId
      })
      .map((resource: ResourceOrg) => {
        return resource.resource_name
      })
  }

  return (
    <Row>
      <OrgRowContentLarge>{building.buildingId}</OrgRowContentLarge>
      <OrgRowContent id="tile-info-organization-name-js">{getName(building.buildingId)?.toString()}</OrgRowContent>
      <OrgRowContent>{getOrgName(building.buildingId)?.toString()}</OrgRowContent>
      <OrgRowContent>{building.groupCount}</OrgRowContent>
      <OrgRowContent>{building.areaCount}</OrgRowContent>
      <OrgRowContent>{new Date(building.timestamp).toISOString().slice(0, 10)}</OrgRowContent>
      <OrgRowContent>
        <OrgRowContentLastCell>
          <DropdownGlobalStyle />
          <Dropdown overlay={getMoreMenuItems()} placement="bottomRight" trigger={['click']}>
            <MoreItem id="open-edit-org-button-js" />
          </Dropdown>
        </OrgRowContentLastCell>
      </OrgRowContent>
    </Row>
  )
}

export default BuildingTable
