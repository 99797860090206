import styled, { css } from 'styled-components'
import { size } from '../../utils/styles'
import { Link } from 'react-router-dom'
import { H2 } from '../Typography'
import backArrow from '../../../assets/icons-back-arrow.svg'

export const Title = styled(H2)`
  transform: translateY(70%);
  transition: transform 300ms ease-out;

  @media (min-width: ${size.large}) {
    transform: translateY(300px);
  }
`

const linkContainerStyle = css<{ image: string }>`
  position: relative;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  transition: transform 300ms ease-in, box-shadow 300ms ease-in;
  max-width: 344px;
  max-height: 344px;
  margin: 8px;
  padding: 20px;
  cursor: pointer;

  @media (min-width: ${size.large}) {
    flex: 1;
    max-width: initial;
    flex-basis: 33%;
    max-height: initial;
    padding-bottom: 40px;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.8) 5px 5px 30px;
      z-index: 2;
      transform: scale(1.05);

      .get-inspired-card-title {
        transform: translateY(240px);
      }

      .get-inspired-card-button {
        opacity: 1;
      }
    }
  }
`

export const InternalLinkContainer = styled(Link)<{ image: string }>`
  ${linkContainerStyle}
`

export const ExternalLinkContainer = styled.a<{ image: string }>`
  ${linkContainerStyle}
`

export const ButtonContainer = styled.div`
  position: relative;
  opacity: 0;
  padding-top: 300px;
  transition: opacity 300ms ease-out;
`

export const ArrowIcon = styled.div`
  background-image: url(${backArrow});
  background-repeat: no-repeat;
  width: 28px;
  height: 25px;
  background-size: 28px;
  right: 16px;
  bottom: 14px;
  position: absolute;
  transform: scaleX(-1);
  @media (min-width: ${size.large}) {
    background-size: 42px;
    width: 42px;
    height: 38px;
    right: 22px;
    bottom: 22px;
  }
`
