import { Form, Input } from 'antd'
import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { animated, useSpring } from 'react-spring'
import { EMAIL_REG_EXP, REQUIRED_FIELD } from '../../../../shared/constants/auth'
import { useAuth } from '../../../../shared/contexts/authContext'
import { LoginData } from '../../../../shared/models/auth'
import AuthFormsConfirmationForm from '../ConfirmationForm'
import { LoginButton, KoneAdminButtonContainer, KoneAdminLoginButton, ForgotYourPasswordLink } from './Styles'
import ForgotPasswordForm from '../ForgotPassword'
import { SidePanel, ErrorMessage, AuthFormLabel, Toast } from '../../../../shared/components'
import { ErrorStrings, LoginFormStrings } from '../../../../shared/strings/AuthFormContent'
import { GeneralFormStrings } from '../../../../shared/strings/GeneralFormContent'
import { EmailInput } from '../Styles'
import MFAAuthForm from '../MFAAuthForm'

const AuthFormsLoginForm: FC = () => {
  const animationProps = useSpring({ config: { duration: 500 }, opacity: 1, from: { opacity: 0 } })
  const [isConfirmationMissing, setIsConfirmationMissing] = useState(false)
  const { signIn, resendSignUp, openADSignInPage, isMfaNeeded, signInWithNewPassword } = useAuth()
  const [loginData, setLoginData] = useState<LoginData | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginData>()
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false)
  const [loginError, setLoginError] = useState<Error | undefined>()
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [isNewPassword, setIsNewPassword] = useState(false)

  async function onSubmit(data: LoginData) {
    setIsErrorVisible(false)
    setIsLoading(true)

    setLoginData({
      email: data.email.toLowerCase(),
      password: data.password,
    })

    try {
      if (data.newPassword && data.newPassword?.length > 0) {
        await signInWithNewPassword(data.email, data.password, data.newPassword)
      } else {
        const signResponse: any = await signIn(data.email, data.password)
        if (signResponse?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsNewPassword(true)
          setIsErrorVisible(true)
          setLoginError(new Error('Please set a new password.'))
        }
      }
      setIsLoading(false)
    } catch (e: any) {
      if (e.code === 'UserNotConfirmedException') {
        await resendSignUp(data.email)
        setIsLoading(false)
        setIsConfirmationMissing(true)
      } else {
        console.error(e)
        setLoginError(e)
        setIsErrorVisible(true)
        setIsLoading(false)
      }
    }
  }

  async function onLoginAsAdmin() {
    openADSignInPage()
  }

  function onForgotPassword() {
    setIsErrorVisible(false)
    setIsForgotPasswordOpen(true)
  }

  function closeForgotPasswordPanel() {
    setIsForgotPasswordOpen(false)
  }

  return (
    <>
      <Toast
        isVisible={isErrorVisible}
        text={loginError?.message}
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <animated.div style={animationProps}>
        {!isConfirmationMissing ? (
          <>
            {!isMfaNeeded() ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Form.Item validateStatus={errors.email ? 'error' : ''}>
                  <AuthFormLabel htmlFor="email-input-js">{GeneralFormStrings.email}</AuthFormLabel>
                  <Controller
                    render={({ field }) => <EmailInput id="email-input-js" {...field} />}
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      pattern: {
                        value: EMAIL_REG_EXP,
                        message: ErrorStrings.invalidEmailError,
                      },
                      required: true,
                    }}
                  />
                  {errors.email && (
                    <ErrorMessage id="email-error-message-js">{errors.email.message || REQUIRED_FIELD}</ErrorMessage>
                  )}
                </Form.Item>
                <Form.Item validateStatus={errors.password ? 'error' : ''}>
                  <AuthFormLabel htmlFor="password-input-js">{GeneralFormStrings.password}</AuthFormLabel>
                  <ForgotYourPasswordLink id="forgot-your-password-link-js" onClick={onForgotPassword}>
                    {LoginFormStrings.forgotPassword}
                  </ForgotYourPasswordLink>
                  <Controller
                    render={({ field }) => <Input.Password id="password-input-js" {...field} autoComplete="off" />}
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                  />
                  {errors.password && <ErrorMessage id="password-error-message-js">{REQUIRED_FIELD}</ErrorMessage>}
                </Form.Item>
                {isNewPassword && (
                  <Form.Item validateStatus={errors.newPassword ? 'error' : ''}>
                    <AuthFormLabel htmlFor="password-input-js">{GeneralFormStrings.newPassword}</AuthFormLabel>
                    <Controller
                      render={({ field }) => (
                        <Input.Password id="new-password-input-js" {...field} autoComplete="off" />
                      )}
                      name="newPassword"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                    />
                    {errors.newPassword && (
                      <ErrorMessage id="new-password-error-message-js">{REQUIRED_FIELD}</ErrorMessage>
                    )}
                  </Form.Item>
                )}

                <LoginButton loading={isLoading} id="login-button-js" type="primary" htmlType="submit">
                  {GeneralFormStrings.loginButton}
                </LoginButton>
                <KoneAdminButtonContainer>
                  <KoneAdminLoginButton id="admin-login-button-js" type="link" onClick={onLoginAsAdmin}>
                    {LoginFormStrings.koneAdminButton}
                  </KoneAdminLoginButton>
                </KoneAdminButtonContainer>
              </form>
            ) : (
              <MFAAuthForm loginData={loginData!} />
            )}
          </>
        ) : (
          <AuthFormsConfirmationForm loginData={loginData!} />
        )}
      </animated.div>
      <SidePanel
        isOpen={isForgotPasswordOpen}
        title="Reset password"
        showBackButton={true}
        onClose={closeForgotPasswordPanel}
        stacked={true}
      >
        <ForgotPasswordForm onClosePanel={closeForgotPasswordPanel} />
      </SidePanel>
    </>
  )
}

export default AuthFormsLoginForm
