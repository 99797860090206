import styled from 'styled-components'
import { Tabs } from 'antd'
import { color } from '../../utils/styles'

const TabsContainer = styled(Tabs)`
  .ant-tabs-nav {
    width: 900px;
  }

  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-tab-btn {
    color: ${color.gray20};
    font: normal normal normal 24px/28px KONE Information;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${color.white};
    font: normal normal normal 24px/28px KONE Information;
  }

  .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
    width: 80px;
  }

  .ant-tabs-tab .ant-tabs-tab-active {
    margin-right: 60px;
  }
`

export default TabsContainer
