import React, { ChangeEvent, useState } from 'react'
import { H4 } from '../../shared/components'
import { ReactComponent as SearchIcon } from '../../assets/icon-search-find.svg'
import { ReactComponent as ClearIcon } from '../../assets/icon-search-clear.svg'
import Pagination from '../../shared/components/Pagination'
import { TABLE_CONTENT_LENGTH } from '../../shared/constants/common'
import {
  SearchCreateRow,
  FilterContainer,
  SearchInput,
  MessageContainer,
  CountHeading,
  HeaderRow,
  OrgRowContentLarge,
  OrgRowContent,
  PaginationPlacing,
  Row,
  ProfileDetails,
  OrgRowContentMedium,
} from './Styles'
import { useQuery } from 'react-query'
import { ResourcesAPI } from '../../shared/api/resources'
import { ResourceOrgObject, ResourceOrgKen } from '../../shared/models/resource'
import { EquipmentStrings } from '../../shared/strings/EquipmentContent'

const Equipment = () => {
  const { isLoading, error, data } = useQuery(ResourcesAPI.queryNames.GET_BY_ORGANIZATION_ID, () =>
    ResourcesAPI.getOrgNameByResourceTypeAndId('ken')
  )
  const [equipmentFilter, setEquipmentFilter] = useState('')
  const [page, setPage] = useState(0)
  const filResult: ResourceOrgKen[] = []
  const showContentRows = () => {
    let filteredResObject
    data.forEach((obj: ResourceOrgObject) => {
      filteredResObject = filResult.filter((val) => val.resourceId === obj.resourceId)
      if (filteredResObject.length === 0) {
        filResult.push({
          resourceId: obj.resourceId,
          uniqueId: obj.uniqueId,
          type: obj.type,
          ken: obj.ken,
          added_date: obj.added_date,
          organization: obj.name ? [obj.name] : [],
          resource_name: obj.resource_name,
        })
      } else {
        filResult.forEach((ob) => {
          if (ob.resourceId === obj.resourceId && obj.name) {
            ob.organization?.push(obj.name)
          }
        })
      }
    })
  }
  if (data?.length > 0) {
    showContentRows()
  }

  const onEquipmentFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    setEquipmentFilter(event.target.value.toLowerCase())
  }

  const showEquipmentContent = (ken: ResourceOrgKen) => {
    return (
      <ProfileDetails>
        <Row>
          <OrgRowContentMedium>{ken.uniqueId}</OrgRowContentMedium>
          <OrgRowContent id="tile-info-equipment-name-js">{ken.resource_name}</OrgRowContent>
          <OrgRowContentLarge>{ken.organization?.toString()}</OrgRowContentLarge>
          <OrgRowContent>{ken.added_date ? new Date(ken.added_date).toISOString().substring(0, 10) : ''}</OrgRowContent>
        </Row>
      </ProfileDetails>
    )
  }
  const showEquipmentsList = () => {
    if (isLoading) {
      return <MessageContainer>{EquipmentStrings.loadingEquipmentList}</MessageContainer>
    }
    if (error) {
      return <MessageContainer>{EquipmentStrings.equipmentListError}</MessageContainer>
    }
    const infoTable = filResult
      .filter((ken: ResourceOrgKen) => {
        return equipmentFilter !== undefined
          ? (ken?.uniqueId.includes(equipmentFilter) ||
              ken?.organization?.toString().toLowerCase().includes(equipmentFilter) ||
              ken?.type?.toString().toLowerCase().includes(equipmentFilter) ||
              ken?.ken?.includes(equipmentFilter)) &&
              ken?.organization!.length > 0
          : true
      })
      .map((ken: ResourceOrgKen) => {
        return showEquipmentContent(ken)
      })
    return (
      <>
        <SearchCreateRow>
          <FilterContainer>
            <SearchInput
              id="search-equipment-input-js"
              placeholder="Search equipment"
              onChange={onEquipmentFilterChange}
              suffix={equipmentFilter ? <ClearIcon onClick={() => setEquipmentFilter('')} /> : <SearchIcon />}
              value={equipmentFilter}
            />
          </FilterContainer>
        </SearchCreateRow>
        <CountHeading></CountHeading>
        <HeaderRow>
          <OrgRowContentMedium>{EquipmentStrings.kenNumber}</OrgRowContentMedium>
          <OrgRowContent>{EquipmentStrings.name}</OrgRowContent>
          <OrgRowContentLarge>{EquipmentStrings.organizations}</OrgRowContentLarge>
          <OrgRowContent>{EquipmentStrings.added}</OrgRowContent>
        </HeaderRow>
        {infoTable.slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))}
        <PaginationPlacing>
          <Pagination dataLength={infoTable.length == 0 ? 1 : infoTable.length} page={page} setPage={setPage} />
        </PaginationPlacing>
      </>
    )
  }

  return (
    <div>
      <H4>{EquipmentStrings.equipments}</H4>
      {showEquipmentsList()}
    </div>
  )
}

export default Equipment
