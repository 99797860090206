import React, { FC, useEffect } from 'react'
import { CenteredButton } from './Styles'
import { Modal } from '../../../shared/components'

const TransferOwnershipModal: FC<{ visible: boolean; response: any; onClose: () => void }> = ({
  visible,
  response,
  onClose,
}) => {
  useEffect(() => {
    if (visible) {
      // Set the flag in local storage when the modal is visible
      localStorage.setItem('ownershipTransferred', 'true')
    }
  }, [visible])

  return (
    <Modal
      title="Please note!"
      width={672}
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      closable={false}
      footer={[
        <CenteredButton key="ok" size="large" type="primary" onClick={onClose}>
          Log out
        </CenteredButton>,
      ]}
    >
      {response ? (
        <div>
          <p>{response}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </Modal>
  )
}

const UpdateModal: FC<{ visible: boolean; response: any; onClose: () => void }> = ({ visible, response, onClose }) => (
  <Modal
    title="Please note!"
    width={672}
    visible={visible}
    onOk={onClose}
    onCancel={onClose}
    closable={false}
    footer={[
      <CenteredButton key="ok" size="large" type="primary" onClick={onClose}>
        OK
      </CenteredButton>,
    ]}
  >
    {response ? (
      <div>
        <p>{response}</p>
      </div>
    ) : (
      <p>Loading...</p>
    )}
  </Modal>
)

export { TransferOwnershipModal, UpdateModal }
