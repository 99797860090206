import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'
import { ReactComponent as RightActiveArrow } from '../../../assets/icons-pagination-next-active.svg'
import { ReactComponent as LeftActiveArrow } from '../../../assets/icon-pagination-previous-active.svg'
import { ReactComponent as LeftInactiveArrow } from '../../../assets/icon-pagination-previous-passive.svg'
import { ReactComponent as RightInactiveArrow } from '../../../assets/icons-pagination-next-passive.svg'
import { ReactComponent as RightInactiveLastArrow } from '../../../assets/icon-pagination-last-passive.svg'
import { ReactComponent as LeftInactiveLastArrow } from '../../../assets/icon-pagination-first-passive.svg'
import { ReactComponent as LeftActiveLastArrow } from '../../../assets/icon-pagination-first-active.svg'
import { ReactComponent as RightActiveLastArrow } from '../../../assets/icon-pagination-last-active.svg'
import { Pagination } from 'antd'

export const NextActiveArrow = styled(RightActiveArrow)`
  margin-right: 4px;
  cursor: pointer;
`

export const PreviousActiveArrow = styled(LeftActiveArrow)`
  margin-left: 4px;
  cursor: pointer;
`

export const NextPassiveArrow = styled(RightInactiveArrow)`
  margin-right: 4px;
  .an {
    fill: none;
  }
  .bn {
    fill: #3d464c;
  }
`

export const PreviousPassiveArrow = styled(LeftInactiveArrow)`
  margin-left: 4px;

  .ap {
    fill: none;
  }
  .bp {
    fill: #3d464c;
  }
`

export const VerticalLineLeft = styled.div`
  border-right: 1px solid ${color.gray70};
  padding: 6px 4px;
`

export const LastInactiveArrow = styled(RightInactiveLastArrow)`
  .anp {
    fill: none;
  }
  .bnp {
    fill: #3d464c;
  }
`

export const LastActiveArrow = styled(RightActiveLastArrow)`
  cursor: pointer;
`

export const VerticalLineRight = styled.div`
  border-left: 1px solid ${color.gray70};
  padding: 6px 4px;
`

export const FirstInactiveArrow = styled(LeftInactiveLastArrow)`
  .app {
    fill: none;
  }
  .bpp {
    fill: #3d464c;
  }
`

export const FirstActiveArrow = styled(LeftActiveLastArrow)`
  cursor: pointer;
`

export const TablePagination = styled(Pagination)`
  .ant-pagination-simple .ant-pagination-prev .ant-pagination-simple .ant-pagination-next {
    margin-top: 5px;
  }

  .ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: transparent;
    border: none;
    width: 12px;
    padding: 0;
    margin-right: 0;
  }

  .ant-pagination-slash {
    margin: 0;
  }

  .ant-pagination-simple .ant-pagination-simple-pager {
    color: ${color.gray15};
  }

  .ant-pagination-disabled {
    cursor: default;
  }
`

export const AlignArrows = styled.div`
  display: flex;
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 642px;

  .ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: transparent;
    border: none;
    width: 25px;
    padding: 0;
    margin-right: 0;
  }

  .ant-pagination-slash {
    margin: 0;
  }

  .ant-pagination-simple .ant-pagination-simple-pager {
    color: ${color.gray15};
    margin-top: 1px;
  }

  .ant-pagination-next {
    margin-top: 5px;
  }

  .ant-pagination-prev {
    margin-top: 5px;
  }

  .ant-pagination-slash {
    margin-left: -2px;
    cursor: default;
  }

  .ant-pagination-simple-pager {
    cursor: default;
  }

  .ant-pagination-simple-pager > input {
    cursor: default;
    caret-color: transparent;
  }
`
