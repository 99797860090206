import styled, { css } from 'styled-components'
import { NotificationIcon, Table } from '../../shared/components'

const pageWidthMixin = css`
  max-width: 95%;
  width: 920px;
`

export const InvitationsTabTitle = styled.span`
  position: relative;
`

export const StyledNotificationIcon = styled(NotificationIcon)`
  top: 3px;
  right: -5px;
`

export const HeaderRow = styled(Table.HeaderRow)`
  ${pageWidthMixin}
`

export const ContentRow = styled(Table.ContentRow)`
  ${pageWidthMixin}
`

export const PageTitleRow = styled.div`
  position: relative;
  ${pageWidthMixin}
`
