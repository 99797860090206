import React, { FC } from 'react'
import {
  DropdownGlobalStyle,
  OrgRowContent,
  OrgRowContentLarge,
  OrgRowContentLastCell,
  Row,
  ProfileDetails,
  StyledDropdownMenu,
} from '../../../Organizations/Styles'
import { MoreItem } from '../Styles'
import { Dropdown } from 'antd'
import { triggerEvent } from '../../../../shared/api/webhookAPI'
import { useMutation } from 'react-query'
import { CustomerApp } from '../../../../shared/models/application'
import { WebhookSubscription } from '../../../../shared/models/webhookSubscription'

type Props = {
  subscription: WebhookSubscription
  onClickEdit: (organization: any) => void
  application: CustomerApp
  setTriggerResponseBanner: () => void
  setTriggerResponseBannerError: () => void
  closeBanner: () => void
  closeEditSuccessfulBanner: () => void
}

const WebhookTable: FC<Props> = ({
  subscription,
  onClickEdit: onClickEditParentHandler,
  application,
  setTriggerResponseBanner,
  setTriggerResponseBannerError,
  closeBanner,
  closeEditSuccessfulBanner,
}) => {
  const { mutateAsync: triggerSubscriptionEvent, isLoading, data } = useMutation(triggerEvent)

  const hideToastNotifications = () => {
    closeBanner()
    closeEditSuccessfulBanner()
  }

  const handleEventTrigger = () => {
    closeBanner()
    try {
      triggerSubscriptionEvent({ application, subscription })
      setTriggerResponseBanner()
    } catch (e) {
      console.log(e)
      setTriggerResponseBannerError()
    }
  }

  const getMoreMenuItems = () => {
    return (
      <StyledDropdownMenu>
        {!application.sandbox ? (
          <StyledDropdownMenu.Item id="more-menuitem-js" key="moreMenu2" onClick={onClickEdit}>
            Edit subscription
          </StyledDropdownMenu.Item>
        ) : (
          <>
            <StyledDropdownMenu.Item id="more-menuitem1-js" key="moreMenu1" onClick={() => handleEventTrigger()}>
              Trigger webhook event
            </StyledDropdownMenu.Item>
            <StyledDropdownMenu.Item id="more-menuitem-js" key="moreMenu2" onClick={onClickEdit}>
              Edit subscription
            </StyledDropdownMenu.Item>
          </>
        )}
      </StyledDropdownMenu>
    )
  }

  const onClickEdit = () => {
    hideToastNotifications()
    onClickEditParentHandler(subscription)
  }

  return (
    <ProfileDetails>
      <Row>
        <OrgRowContent>{subscription.subscriptionName}</OrgRowContent>
        <OrgRowContent>{subscription.webhookEndpoint}</OrgRowContent>
        <OrgRowContentLarge id="tile-info-organization-name-js">{subscription.status}</OrgRowContentLarge>
        <OrgRowContent>
          <OrgRowContentLastCell>
            <DropdownGlobalStyle />
            <Dropdown
              getPopupContainer={(triggerElement) => triggerElement.parentNode as HTMLElement}
              overlay={getMoreMenuItems()}
              placement="bottomRight"
              trigger={['click']}
            >
              <MoreItem id="open-edit-org-button-js" />
            </Dropdown>
          </OrgRowContentLastCell>
        </OrgRowContent>
      </Row>
    </ProfileDetails>
  )
}

export default WebhookTable
