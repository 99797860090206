import Markdown from 'markdown-to-jsx'
import { OpenAPIV3 } from 'openapi-types'
import React, { FC, useState } from 'react'
import { ReactComponent as IconExpand } from '../../../../assets/icons-sort-chevron-down.svg'
import { ReactComponent as IconHide } from '../../../../assets/icons-sort-chevron-up.svg'
import { P } from '../../../../shared/components/Typography'
import useIsPrint from '../../../../shared/utils/isPrint'
import { PayloadProperty } from '../../components/PayloadProperty'
import {
  formatType,
  getSubSchemaName,
  isArraySchema,
  isObjectSchema,
  isRequiredPropertyInSchema,
  makeLocalLinksRelative,
  markdownOptions,
  needsSubSchema,
  parseDescriptionAndEnumDescriptions,
  solveSchemaReference,
  solveSubSchema,
} from '../open-api-utils'
import { ExpandSchemaIconContainer, SchemaOrSubSchemaContainer } from './Styles'

type SchemaProps = {
  schemaOrRef: OpenAPIV3.ReferenceObject | OpenAPIV3.SchemaObject
  document: OpenAPIV3.Document
  depth?: number
  expandChildren?: boolean
}

function getChildSchemaName(
  propertyOrReference: OpenAPIV3.ReferenceObject | OpenAPIV3.SchemaObject,
  property: OpenAPIV3.SchemaObject,
  childSchema: OpenAPIV3.SchemaObject
) {
  const childSchemaName = getSubSchemaName(propertyOrReference) ?? getSubSchemaName(property)

  if (isArraySchema(property)) {
    return `${property.type} of ${childSchemaName ?? ''} ${childSchema.type}s`
  } else if (isObjectSchema(childSchema) && childSchemaName) {
    return `${property.type} of type ${childSchemaName ?? ''}`
  } else {
    return property.type!
  }
}

const Schema: FC<SchemaProps> = ({ schemaOrRef, document, depth = 0, expandChildren = false }: SchemaProps) => {
  const isPrint = useIsPrint()
  const [isChildSchemaExpanded, setIsChildSchemaExpanded] = useState<{ [schemaName in string]: boolean }>({})

  function isShowExpanded(name: string): boolean {
    if (isChildSchemaExpanded[name] !== undefined) {
      return isChildSchemaExpanded[name]
    } else {
      return expandChildren
    }
  }

  const toggleChildSchemaVisibility = (name: string) => () => {
    const currentState = isChildSchemaExpanded[name] !== undefined ? isChildSchemaExpanded[name] : expandChildren

    const newState = {}
    newState[name] = !currentState

    setIsChildSchemaExpanded(Object.assign({}, isChildSchemaExpanded, newState))
  }

  const schema = solveSchemaReference(schemaOrRef, document)
  const schemaOrSubSchema = needsSubSchema(schema) ? solveSubSchema(schema, document) : schema

  const [schemaDescription, schemaEnumDescriptions] = parseDescriptionAndEnumDescriptions(schema)

  let schemaMasthead = <P size="small">{schemaDescription}</P>

  if (schema.type && schema.type === 'object') {
    schemaMasthead = (
      <>
        <P size="small">
          <strong>Type:</strong> {schema.type}
        </P>
        <P size="small">{schemaDescription}</P>
      </>
    )
  } else if (schema.type && !['object', 'array'].includes(schema.type)) {
    schemaMasthead = (
      <PayloadProperty
        name={''}
        type={schema.type}
        description={schemaDescription}
        stringEnums={schema.enum}
        stringEnumDescriptions={schemaEnumDescriptions}
      />
    )
  }

  return (
    <SchemaOrSubSchemaContainer depth={depth}>
      {schemaMasthead}

      {schemaOrSubSchema.properties &&
        Object.entries(schemaOrSubSchema.properties).map(([name, propertyOrReference]) => {
          const property = solveSchemaReference(propertyOrReference, document)
          const childSchema = needsSubSchema(property) ? solveSubSchema(property, document) : undefined

          const [description, enumDescriptions] = parseDescriptionAndEnumDescriptions(property)

          return (
            <div key={name}>
              {childSchema && (
                <ExpandSchemaIconContainer onClick={toggleChildSchemaVisibility(name)}>
                  {isShowExpanded(name) || isPrint ? <IconHide /> : <IconExpand />}
                </ExpandSchemaIconContainer>
              )}
              <PayloadProperty
                name={name}
                type={
                  childSchema ? getChildSchemaName(propertyOrReference, property, childSchema) : formatType(property)
                }
                required={isRequiredPropertyInSchema(name, schemaOrSubSchema)}
                description={
                  description ? (
                    <Markdown options={markdownOptions}>{makeLocalLinksRelative(description)}</Markdown>
                  ) : (
                    ''
                  )
                }
                stringEnums={property.enum}
                stringEnumDescriptions={enumDescriptions}
              />
              {childSchema && (isShowExpanded(name) || isPrint) && (
                <Schema schemaOrRef={childSchema} document={document} depth={depth + 1} />
              )}
            </div>
          )
        })}
    </SchemaOrSubSchemaContainer>
  )
}

export default Schema
