import styled, { css, createGlobalStyle } from 'styled-components'
import { color, size } from '../../shared/utils/styles'
import { Menu, Input } from 'antd'
import { Button } from '../../shared/components'
import { ReactComponent as FalafelMenu } from '../../assets/icons-falafel-menu.svg'

export const MessageContainer = styled.div`
  margin-top: 50px;
`
export const FilterContainer = styled.div`
  display: flex;
  width: 92%;
  justify-content: space-between;
  font-size: 16px;
  color: white;
  flex-wrap: wrap;
  .switch-disabled {
    color: ${color.gray30};
  }
  #icons_search_clear {
    cursor: pointer;
  }
`
const rowCommonStyle = css`
  color: ${color.gray10};
  max-width: 1388px;
  width: 100%;
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
`
export const HeaderRow = styled.div`
  ${rowCommonStyle};
  background: ${color.gray80} 0% 0% no-repeat padding-box;
  font-size: 14px;
  border-top: 1px solid ${color.gray70};
`
export const Row = styled.div`
  ${rowCommonStyle};
  background-color: ${color.gray85};
  min-height: 48px;
  margin-bottom: 2px;
  border-radius: 4px;
`
export const RowExpand = styled.div`
  padding-right:25px;
}
`
export const RowContent = styled.div`
  padding-right: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const OrgRowContent = styled(RowContent)`
  flex-basis: 33%;
`
export const OrgRowContentLarge = styled(RowContent)`
  flex-basis: 70%;
`
export const OrgRowContentMedium = styled(RowContent)`
  flex-basis: 50%;
`

export const DropdownGlobalStyle = createGlobalStyle`
  .ant-dropdown-menu {
    width: 240px;
    background-color: ${color.gray90};
    border: 1px solid ${color.gray70};
    @media (max-width: ${size.small}) {
      width: 200px;
    }
  }
  .ant-dropdown-menu-item {
    color: ${color.gray15};
    padding: 15px 12px;
    &> a {
      color: ${color.gray15};
      &:hover {
        color: ${color.gray15};
      }
    }
    &:hover {
      background-color: ${color.gray80};
    }
  }
`
export const StyledDropdownMenu = styled(Menu)`
  .ant-dropdown-menu-item {
    position: relative;
  }
`
export const OrgRowContentLastCell = styled(RowContent)`
  float: right;
  padding-right: 0px;
`
export const SearchCreateRow = styled.div`
  max-width: 1388px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const CreateButton = styled(Button)`
  width: 234px;
`
export const CountHeading = styled.div`
  margin-bottom: 8px;
  margin-top: 15px;
`
export const PaginationPlacing = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1388px;
  width: 100%;
`
export const RefreshButton = styled(Button)`
  width: 60px;
  height: 40px;
  margin-left: 4px;
  padding-top: 7px;
`
export const SearchInput = styled(Input)`
  width: 265px;
  height: 40px;
`
export const MoreItem = styled(FalafelMenu)`
  cursor: pointer;
  vertical-align: middle;
`
export const ProfileDetails = styled.div`
  color: ${color.gray10};
`
