import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'

export const ReleaseNotesLogSeptember23 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        September 2023
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        September release notes summarizes updates for Equipment Status API 2, Authentication API 2 and API Portal
        documentation.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Movement Completed multiple response payloads that are being received by some kens are now included in the
              updated REST Documentation. Checkout latest{' '}
              <Link to={Paths.EquipmentStatus2RestApiPageDocs.path + '#getEquipmentMovementByIds'}>
                Movement API REST{' '}
              </Link>{' '}
              documentations to learn more about movement responses.
            </li>
            <li>
              subscribedResourceIds field is updated inside the request body example of{' '}
              <Link to={Paths.WebhookManagementAPIDocs.path + '#createWebhookSubscription'}>
                Create webhook subscription.{' '}
              </Link>{' '}
            </li>
          </UL>

          <li style={{ marginTop: '30px' }}>Equipment Status API 2</li>
          <UL>
            <li>
              Equipment Status Infomation API now returns correct error message to the end users.
              <ul>
                <li>Previous error : Internal server error</li>
                <li>
                  Handled error : This will include an error message such as contract inactive for equipment,
                  statusCode, and equipment id.
                </li>
              </ul>
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Authentication 2 API</li>
          <UL>
            <li>
              New field apiKens is now available in the
              <Link to={Paths.AuthenticationAPIV2.path + '#listResources'}> List Resources API </Link> which will
              contain kens for building.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
