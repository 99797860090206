import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'

export const ReleaseNotesLogOctober23 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        October 2023
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        October release notes summarizes documentation fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              <q> requestId </q> is updated as a required field in all{' '}
              <Link to={Paths.ElevatorWebsocketAPIV2.path + '#monitoring-commands'}>Site Monitoring </Link> related
              documentations.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
