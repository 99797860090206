import styled from 'styled-components'
import { color } from '../../../shared/utils/styles'
import { P, H6, Button, Table } from '../../../shared/components'

export const ProfileDetails = styled.div`
  color: ${color.gray10};
`

export const Section = styled.div`
  margin-bottom: 54px;
`

export const SubscriptionTypeRowContent = styled(Table.Column)`
  flex-basis: 33%;
`

export const RowButton = styled(Button)`
  min-width: 88px;
  margin-left: auto;
`

export const SwitchContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  float: right;
  padding-left: 70%;
`

export const SwitchLabel = styled.label`
  padding-left: 5px;
  padding-right: 10px;
`

export const SubscriptionInfo = styled(H6)`
  padding: 0 150px;
  text-align: center;
`

export const NotificationToastText = styled(P)`
  color: ${color.gray75};
  margin-top: 0;
`
