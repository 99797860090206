import React, { FC } from 'react'
import { GetInspiredCardContent, TilesContainer } from '../../../shared/components'
import {
  GetInspiredCard1,
  GetInspiredCard2,
  GetInspiredCard3,
  GetInspiredCard4,
} from '../../../shared/strings/MainPageTextContent'
import connectedImage from '../../../assets/bg-image-connected.jpg'
import yunjiImage from '../../../assets/api-image-yunji.jpg'
import ecosystemOfPartners from '../../../assets/bg-ecosystem-of-partners.jpg'
import apiPoweredEcosystem from '../../../assets/bg-api-powered-ecosystem.jpg'

const InspirationCards: FC = () => {
  return (
    <>
      <TilesContainer>
        <GetInspiredCardContent
          title={GetInspiredCard3.title}
          image={apiPoweredEcosystem}
          targetPath="/inspiration/smarter-cities"
        />
        <GetInspiredCardContent
          title={GetInspiredCard4.title}
          image={ecosystemOfPartners}
          targetPath="/inspiration/ecosystem"
        />
      </TilesContainer>
      <TilesContainer>
        <GetInspiredCardContent
          title={GetInspiredCard1.title}
          image={connectedImage}
          targetPath="https://www.kone.com/en/news-and-insights/stories/connected-elevators-boost-accessibility-for-students.aspx"
        />
        <GetInspiredCardContent
          title={GetInspiredCard2.title}
          image={yunjiImage}
          targetPath="https://www.kone.fr/kone-ascensoriste/presse/KONE-et-Yunji-collaborent-pour-offrir-des-services-innovants-dans-le-secteur-de-lhospitalite.aspx"
        />
      </TilesContainer>
    </>
  )
}

export default InspirationCards
