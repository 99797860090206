import styled from 'styled-components'
import Button from '../../../../shared/components/Button'

export const AddButton = styled(Button)`
  width: 132px;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
`
