import React from 'react'
import { H2, P } from '../../../../shared/components'
import { LeftCol, RightColServiceStatus, SectionRow } from '../../Styles'
import { Link } from 'react-router-dom'
import Paths from '../../../Paths'

export const Introduction = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H2 style={{ marginLeft: '-40px', marginTop: '-30px' }}>Service Info 2 - Webhooks</H2>

        <P style={{ marginLeft: '-40px' }} size="small">
          Service Info API 2 - Webhooks allows you to receive and inspect enhanced KONE maintenance service information
          in near real-time and integrate it into your chosen building management systems and dashboards. It supports
          both KONE and NON-KONE equipments. Webhook gives an advantage that the consumer does not need to request for
          an equipment information manually and receives an events automatically as soon as there is any change in the
          information at API Ecosystem.{' '}
        </P>
        <P style={{ marginLeft: '-40px' }} size="small">
          To start receiving an events automatically, the consumer needs to create a webhook subscription for an API
          application. The consumer can create a webhook subscription by using{' '}
          <Link to={Paths.WebhookManagementAPIDocs.path + '#createWebhookSubscription'}>
            Create webhook subscription API
          </Link>
          . Also consumer can use different APIs listed on{' '}
          <Link to={Paths.WebhookManagementAPIDocs.path}>{Paths.WebhookManagementAPIDocs.name}</Link> to fetch, update
          or delete the webhook subscription for an API application.{' '}
        </P>
      </LeftCol>
      <RightColServiceStatus span="12"></RightColServiceStatus>
    </SectionRow>
  )
}
