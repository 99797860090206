export const FeedbackPageTitles = {
  title: 'Send feedback',
  description: 'How can we improve the KONE API portal? \n Do you have a question about KONE APIs or API portal?',
}
export const FeedbackAreas = {
  title: 'In which area you want to give feedback? *',
  area1: 'General',
  area2: 'Dashboard',
  area3: 'Developer guide',
  area4: 'API Products',
  area5: 'Something else',
  errorMessage: 'Select at least one feedback area',
}

export const ApiInfo = {
  rest: 'Rest',
  webhook: 'Webhook',
  sandbox: 'Sandbox',
  production: 'Production',
}
export const FeedbackInformation = {
  message: 'Your message *',
  name: 'Your name *',
  email: 'Email address for getting back to you *',
  company: 'Your company',
  privacyStatement: 'Privacy Statement.',
  invalidEmailAddressError: 'Invalid email address',
  invalidPhoneNumber: 'Invalid phone number',
  sendFormButton: 'Send',
  sendingFeedbackFailed: 'Sending feedback failed.',
  addKen: 'Please mention Ken ID',
  addBuilding: 'Please mention Building ID',
  selectApplication: 'Please select application type',
  selectApi: 'Please select API type',
}

export const MaxLenght = {
  kenLength: 'Please add valid Ken Id ',
  buildingLength: 'Please add valid Building Id',
}

export const FeedbackSuccessViewStrings = {
  title: 'Thank you for your feedback!',
  message: 'We have received your message and will get back to you as needed.',
}
