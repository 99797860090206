import { Tabs } from 'antd'
import React, { FC } from 'react'
import { AsyncApiProps } from '..'
import { TabbedCodeContainer } from '../../components/TabbedCodeContainer'
import { CodeBlock, LeftCol, RightCol, SectionRow, Text } from '../../Styles'
import { H4, P, UL, OL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'
import { Link } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'

const TabPane = Tabs.TabPane

export interface UsageExampleProps {
  asyncApiProps: AsyncApiProps
}

export const UsageExample: FC<UsageExampleProps> = ({ asyncApiProps }) => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H4 id="usage-example">Usage example</H4>
        <P size="small">
          Once you have acquired the proper access token with the client credential flow, you can start making requests
          to {asyncApiProps.apiName}. The steps are simple:
          <OL size="small">
            <li>Open up the WebSocket connection using the accessToken as a query parameter</li>
            <li>
              Generate call payload, and within the WebSocket connection send the payload down the stream
              <UL size="small">
                <li>
                  buildingId and groupId can be derived from{' '}
                  <Link to={{ pathname: Paths.AuthenticationAPIV2.path, hash: 'listResources' }}>
                    List Resources API{'. '}
                  </Link>{' '}
                  From the group object property group.id <span>{`{buildingId}:{groupId}`}</span>. e.g{' '}
                  <span>{`9990000951:1`}</span>
                </li>
                <li>
                  Common API provides areas and supported actions. Area, destination and terminal id can be derived from
                  config command and action id from actions command. Common commands are mentioned in the section{` `}
                  <NavHashLink smooth to="#common-commands">
                    Common commands.
                  </NavHashLink>
                </li>
              </UL>
            </li>
            <li>
              Start receiving event updates about the call. Received events can be configured with the monitorEvents
              property in the call payload.
            </li>
          </OL>
        </P>

        <P size="small">{`Important note: Upon WebSocket handshake, client must provide Sec-WebSocket-Protocol header. This is currently a constant string: 'koneapi'. 
         See the usage example code.`}</P>

        <P size="small">
          <UL size="small">
            <li>
              <Text code>{`https://${window.location.hostname}/stream-v2`}</Text>
            </li>
          </UL>
        </P>
      </LeftCol>
      <RightCol span="12">
        <TabbedCodeContainer>
          <TabPane tab="Typescript" key="1">
            <CodeBlock language="typescript">
              {`
  // Acquire accessToken 
  // Open WebSocket connection
  const ws = new WebSocket('wss://${window.location.hostname}/stream-v2?accessToken=ACCESS_TOKEN', 'koneapi')
  // Within the connection, make the lift-call
  const liftCallPayload = {
    type: 'lift-call-api-v2',
    buildingId: 'building:9990000951',
    callType: 'action',
    groupId: '1',
    payload: {
      request_id: 252390420,
      area: 3000,
      time: '2022-03-10T07:17:33.298515Z',
      terminal: 1,
      call: { 
        action: 2, 
        destination: 5000 
      }
    }
  } 
    
  ws.send(JSON.stringify(liftCallPayload))
  
  ws.on('message', (data: any) => {
 // message received from the device
 })
              `}
            </CodeBlock>
          </TabPane>
        </TabbedCodeContainer>

        <P size="small">
          The websocket connection will be automatically disconnect after 60 seconds of inactivity. And after 2 hours
          even it has been active.
        </P>
      </RightCol>
    </SectionRow>
  )
}
