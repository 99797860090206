import styled, { css } from 'styled-components'
import { Button, Text3 } from '../../../../../shared/components'
import { Select } from 'antd'
import { color } from '../../../../../shared/utils/styles'
import { ReactComponent as CaretDown } from '../../../../../assets/icon-caret-down.svg'
import { ReactComponent as CaretUp } from '../../../../../assets/icon-caret-up.svg'

export const SelectContainer = styled.div`
  .ant-select-multiple .ant-select-selection-placeholder {
    font-size: 16px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    bottom: 2px;
    font-size: 12px;
  }
  .ant-select-item {
    font-size: 14px;
  }
  text-align: left;
`
export const Title = styled(Text3)`
  margin-bottom: 26px;
`
export const TableRow = styled.div`
  color: ${color.gray10};
  margin-top: 0px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputsContainer = styled.div`
  margin-top: 10px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const AddButton = styled(Button)`
  width: 202px;
  margin-top: auto;
`

export const SaveButton = styled(Button)`
  width: 60px;
  padding: 0px;
  margin-top: 15px;
`
export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 30px;
`
export const SearchButton = styled(Button)`
  width: 120px;
  padding: 0px;
`
export const AddManuallyButton = styled(Button)`
  width: 120px;
  padding: 0px;
`
export const Line = styled.hr`
  margin-top: 34px;
  margin-bottom: 48px;
  border: 1px solid #3d464c;
  opacity: 1;
`

export const LineTop = styled.hr`
  margin-top: 30px;
  margin-bottom: 20px;
  border: 0.5px solid #3d464c;
  opacity: 1;
`

export const FormLabel = styled.p`
  margin-bottom: 8px;
  display: block;
`

export const TypeSelector = styled(Select)`
  width: 150px;
`

export const MessageContainer = styled.p`
  margin: 0;
  max-width: 100%;
`

export const BidInputContainer = styled.div`
  width: 150px;
`

export const ProductSelectionBase = styled.div`
  .ant-select-arrow {
    top: 30%;
    right: 18px;
    color: #0071b9;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 34px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    bottom: 2px;
    font-size: 12px;
  }
`

export const ProductSelectionAuto = styled(ProductSelectionBase)`
  margin-bottom: 35px;
  width: 200px;
`

export const ProductSelectionContainer = styled(ProductSelectionBase)`
  margin-left: 20px;
  width: 250px;
`

export const InputRowContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-top: 15px;
`

export const SearchDeviceInputCol = styled.div`
  flex-basis: 30%;
  margin-top: 0px;
`

export const InputRowContainerButton = styled.div`
  display: flex;
  margin-bottom: 0px;
  align-content: flex-end;
  padding-left: 70%;
  padding-top: 10px;
`

export const SearchSelectionCol = styled.div`
  flex-basis: 28%;
  margin-left: 0px;
`

export const InputColumn = styled.div`
  flex-basis: 32%;
  margin: 0 auto;
`

export const ExpiryInputColumn = styled.div`
  margin: 0 10px;
  width: 140px;
`
export const SwitchColumn = styled.div`
  display: flex;
`

export const SwitchRow = styled.div`
  display: flex;
`

export const ExpiryLabel = styled.p`
  margin-bottom: 8px;
  display: block;
  padding-left: 100px;
`

export const InputColumnButton = styled.div`
  width: 30%;
  margin: 0 auto;
  display: flex;
  padding-top: 10px;
  align-content: flex-end;
`
export const SwitchLabel = styled.label`
  font-size: 16px;
  margin-right: 5px;
`

export const SwitchContainer = styled.div`
  display: flex;
  margin-left: 15px;
  align-items: center;
`
export const DatePickerContainer = styled.div`
  margin-left: 10px;
`
export const OrgListContainer = styled.p`
  margin: 0;
  max-width: 700px;
  overflow-wrap: break-word;
`
export const CancelButton = styled(Button)`
  width: 250px;
`
export const BackButtonContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`

const rowCommonStyle = css`
  color: ${color.gray10};
  max-width: 1388px;
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
`
export const Row = styled.div`
  ${rowCommonStyle};
  background-color: ${color.gray85};
  min-height: 48px;
  margin-bottom: 2px;
  border-radius: 4px;
  display: flex;
`

export const RowContentBase = styled.div`
  padding-right: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`

export const RowContent = styled(RowContentBase)`
  flex-basis: 33%;
`

export const RowContentSmall = styled(RowContentBase)`
  flex-basis: 12%;
`

export const RowContentLarge = styled(RowContentBase)`
  flex-basis: 40%;
`

export const RowContentMedium = styled(RowContentBase)`
  flex-basis: 28%;
`

export const HeaderRow = styled.div`
  ${rowCommonStyle};
  background: ${color.gray80} 0% 0% no-repeat padding-box;
  font-size: 14px;
  border-top: 1px solid ${color.gray70};
  margin-bottom: 0px;
  padding: 8px 8px 8px 40px;
`

export const RowExpand = styled.div`
  padding-right:12px;
}
`
export const IconExpand = styled(CaretDown)`
  cursor: pointer;
  vertical-align: middle;
`

export const IconHide = styled(CaretUp)`
  cursor: pointer;
  vertical-align: middle;
`

export const AddRowButton = styled(Button)`
  width: 40px;
  padding: 0px;
`
