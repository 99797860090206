import React, { FC, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { ResourceObject } from '../../../../shared/models/resource'
import { ApiProductMappings } from '../../../../shared/models/product'
import { ResourcesAPI } from '../../../../shared/api/resources'
import { removeKenFromSubscription } from '../../../../shared/api/webhookAPI'
import ListHeader from '../ListHeader'
import ListMessageContainer from '../ListMessageContainer'
import TableHeaderRow from '../ListTableHeaderRow'
import TableContentRow from '../ListTableContentRow'
import { ReactComponent as EditIcon } from '../../../../assets/edit-icon.svg'
import { ReactComponent as RemoveIcon } from '../../../../assets/icon-remove.svg'
import { ReactComponent as InfoIcon } from '../../../../assets/icons_toaster_ok_circle.svg'
import {
  TypeContainer,
  IDContainer,
  EditContainer,
  InfoContainer,
  RemoveContainer,
  ProductsContainer,
  ProductNumber,
  ProductsText,
  NameContainer,
  DescriptionText,
} from './Styles'
import { Button, Modal, Toast, SidePanel } from '../../../../shared/components'
import EditResourceForm from '../EditResourceForm'
import { TABLE_CONTENT_LENGTH } from '../../../../shared/constants/common'
import Pagination from '../../../../shared/components/Pagination'
import { ResourceListStrings, SharedOrganizationsStrings } from '../../../../shared/strings/OrganizationsContent'
import AddResourcesForm from '../AddResourcesForm'
import { GetResourceExpiryFlag } from '../../../../shared/components/ContractExpiry'

type ListItemProps = {
  organizationId: number
  resource: ResourceObject
  onRemoveStart: () => void
  onRemoveComplete: () => void
}

const ListItem: FC<ListItemProps> = ({ organizationId, resource, onRemoveStart, onRemoveComplete }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const { mutateAsync: removeResource, isLoading: isRemoving } = useMutation(ResourcesAPI.remove)
  const { mutateAsync: removeWebhookSubscription, isLoading: isSubRemoving } = useMutation(removeKenFromSubscription)
  const [isEditResourcesPanelOpen, setIsEditResourcePanelOpen] = useState(false)
  const [isResourceInfoPanelOpen, setIsResourceInfoPanelOpen] = useState(false)
  const [isShowProducts, setIsShowProducts] = useState(false)
  const [isShowDescription, setIsShowDescription] = useState(false)

  const showModal = () => {
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const onClickRemove = async () => {
    onRemoveStart()
    setIsErrorVisible(false)

    try {
      await removeResource({
        organizationId,
        data: {
          products: resource.products,
          resourceId: { type: resource.resourceId.type, uniqueId: resource.resourceId.uniqueId },
        },
      })
      if (resource.resourceId.type === 'ken') {
        const data = {
          orgId: organizationId,
          resourceIds: ['ken:' + resource.resourceId.uniqueId],
        }
        await removeWebhookSubscription(data)
      }
      onRemoveComplete()
      await setModalVisible(false)
    } catch (err) {
      setModalVisible(false)
      setIsErrorVisible(true)
      console.error(err)
    }
  }

  const openEdit = () => {
    setIsEditResourcePanelOpen(true)
  }
  const closeEditResourcePanel = () => {
    setIsEditResourcePanelOpen(false)
  }

  const openInfo = () => {
    setIsResourceInfoPanelOpen(true)
  }
  const closeResourceInfoPanel = () => {
    setIsResourceInfoPanelOpen(false)
  }

  const getProductMappings = (productsWithVersions: string[]): string => {
    const productNames = productsWithVersions.map((productsWithVersion): any => {
      // removing the v1 tag from v1 products as api returns versions with all products
      if (productsWithVersion.includes('v1')) {
        productsWithVersion = productsWithVersion.split('-')[0]
      }
      if (ApiProductMappings.has(productsWithVersion)) {
        return ApiProductMappings.get(productsWithVersion)
      }
    })
    return productNames.toString().replaceAll(',', ', ')
  }

  const getProductMappingsAsList = (productsWithVersions: string[]) => {
    const productNames = productsWithVersions.map((productsWithVersion): any => {
      // removing the v1 tag from v1 products as api returns versions with all products
      if (productsWithVersion.includes('v1')) {
        productsWithVersion = productsWithVersion.split('-')[0]
      }
      if (ApiProductMappings.has(productsWithVersion)) {
        return ApiProductMappings.get(productsWithVersion)
      }
    })
    return (
      <ul style={{ marginTop: '5px' }}>
        {productNames.map((productsWithVersion) => {
          return (
            <li style={{ listStyleType: 'number', textAlign: 'left' }} key={productsWithVersion}>
              {productsWithVersion}
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <>
      <Toast
        isVisible={isErrorVisible}
        text={ResourceListStrings.removeResourceError}
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <TableContentRow>
        <TypeContainer>{resource.resourceId.type}</TypeContainer>
        <IDContainer>
          {resource.resourceId.uniqueId.split(':').reverse().join(' - ')}
          {resource.expiredDate && resource.expiredDate?.length > 0 && (
            <GetResourceExpiryFlag expiredDate={resource.expiredDate} />
          )}
        </IDContainer>
        <ProductsContainer onMouseEnter={() => setIsShowProducts(true)} onMouseLeave={() => setIsShowProducts(false)}>
          {
            <>
              {isShowProducts && <ProductsText> {getProductMappingsAsList(resource.version)} </ProductsText>}
              <ProductNumber>{resource.version.length}</ProductNumber>
              {getProductMappings(resource.version)}
            </>
          }
        </ProductsContainer>
        <NameContainer onMouseEnter={() => setIsShowDescription(true)} onMouseLeave={() => setIsShowDescription(false)}>
          {
            <>
              {isShowDescription && <DescriptionText>{resource.resourceInfo?.description}</DescriptionText>}
              {resource.resourceInfo?.description}
            </>
          }
        </NameContainer>
        <InfoContainer>
          <InfoIcon id="org-resource-info-icon-js" onClick={openInfo} />
          <SidePanel
            key="res-info"
            isOpen={isResourceInfoPanelOpen}
            title="Resource Info"
            showBackButton={true}
            backButtonId="resource-info-back-button-js"
            onClose={closeResourceInfoPanel}
            stacked={true}
          >
            <EditResourceForm
              resource={resource}
              organizationId={organizationId}
              onRemoveStart={onRemoveStart}
              onRemoveComplete={onRemoveComplete}
              onCloseEdit={closeEditResourcePanel}
            />
          </SidePanel>
        </InfoContainer>
        <EditContainer>
          <EditIcon id="org-resource-edit-icon-js" onClick={openEdit} />
          <SidePanel
            key="edit-res"
            isOpen={isEditResourcesPanelOpen}
            title="Edit Resources"
            showBackButton={true}
            backButtonId="edit-resource-back-button-js"
            onClose={closeEditResourcePanel}
            stacked={true}
          >
            <AddResourcesForm
              isEdit={true}
              editMode={'single'}
              resource={resource}
              organizationId={organizationId}
              onCloseAdd={closeEditResourcePanel}
            />
          </SidePanel>
        </EditContainer>
        <RemoveContainer>
          <RemoveIcon id="org-resource-delete-icon-js" onClick={showModal} />
        </RemoveContainer>
      </TableContentRow>
      <Modal
        title={SharedOrganizationsStrings.pleaseNoteModalTitle}
        width={672}
        visible={modalVisible}
        onOk={onClickRemove}
        confirmLoading={isRemoving || isSubRemoving}
        onCancel={handleCancel}
        closable={false}
        footer={[
          <Button
            id="remove-resource-button-js"
            size="large"
            style={{ width: '304px', marginRight: '24px' }}
            key="submit"
            ghost={true}
            onClick={onClickRemove}
            loading={isRemoving || isSubRemoving}
          >
            Yes, remove
          </Button>,
          <Button
            id="cancel-remove-resource-button-js"
            size="large"
            style={{ width: '272px' }}
            key="back"
            type="primary"
            onClick={handleCancel}
          >
            {SharedOrganizationsStrings.goBackButton}
          </Button>,
        ]}
      >
        {`${ResourceListStrings.deleteResourceStart} ${resource.resourceId.type} ${ResourceListStrings.withId} ${resource.resourceId.uniqueId}`}
      </Modal>
    </>
  )
}

type ListProps = {
  organizationId: number
  onClickAdd: () => void
  onStartAction: () => void
}

const ResourceList: FC<ListProps> = ({ organizationId, onClickAdd, onStartAction }) => {
  const [page, setPage] = useState(0)
  const queryName = `${ResourcesAPI.queryNames.GET_BY_ORGANIZATION_ID}${organizationId}`
  const {
    isLoading,
    error,
    data: resources,
  } = useQuery(queryName, () => ResourcesAPI.getByOrganizationId(organizationId))
  const queryClient = useQueryClient()

  const invalidateQuery = () => {
    queryClient.invalidateQueries(queryName)
  }

  const createResourceTable = () => {
    return (
      <>
        <TableHeaderRow>
          <TypeContainer id="resource-type-js">{SharedOrganizationsStrings.type}</TypeContainer>
          <IDContainer id="resource-id-js">{ResourceListStrings.id + ' - Parent Id'}</IDContainer>
          <ProductsContainer>No.Products</ProductsContainer>
          <NameContainer>Description</NameContainer>
          <EditContainer>Action</EditContainer>
        </TableHeaderRow>
        {resources
          .slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))
          .map((resource: ResourceObject) => (
            <ListItem
              key={resource.resourceId.type.concat('-', resource.resourceId.uniqueId)}
              organizationId={organizationId}
              resource={resource}
              onRemoveStart={onStartAction}
              onRemoveComplete={invalidateQuery}
            />
          ))}
        <Pagination dataLength={resources.length} page={page} setPage={setPage} />
      </>
    )
  }

  const showResources = () => {
    if (isLoading) {
      return <ListMessageContainer>{SharedOrganizationsStrings.loading}</ListMessageContainer>
    }
    if (error) {
      return <ListMessageContainer>{ResourceListStrings.errorLoadingResources}</ListMessageContainer>
    }

    if (!resources.length) {
      return <ListMessageContainer>{ResourceListStrings.noResourcesAdded}</ListMessageContainer>
    }

    return createResourceTable()
  }

  return (
    <>
      <ListHeader
        title={ResourceListStrings.listHeaderTitle}
        onClickAdd={onClickAdd}
        disableAddButton={isLoading}
        addButtonId="open-edit-resources-button-js"
        addButtonText={ResourceListStrings.addButtonText}
      />
      {showResources()}
    </>
  )
}

export default ResourceList
