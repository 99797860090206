import styled from 'styled-components'
import { color, size } from '../../../shared/utils/styles'
import { Button, Line } from '../../../shared/components'

export const UpperPageContainer = styled.div`
  max-width: 1234px;
  margin: 0 auto;
  background-color: ${color.gray90};
  color: ${color.gray20};
  min-height: 280px;
  margin-top: 74px;
  @media (max-width: ${size.large}) {
    padding-bottom: 20px;
    margin: 54px 30px 0 30px;
  }
`

export const Sticky = styled.div`
  position: sticky;
  top: 50px;
  z-index: 2;
`

export const InnerPageContainer = styled.div`
  max-width: 920px;
  justify-content: center;
  margin: -70px auto 0 auto;
  position: relative;
`
export const HeaderLine = styled(Line)`
  margin-bottom: 60px;
`

export const ContactButtonArea = styled.div`
  padding-bottom: 25px;
  padding-top: 15px;
  text-align: center;
`

export const ContactUsButton = styled(Button)`
  height: 40px;
  text-transform: uppercase;
`

export const Image = styled.img`
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 30px;

  @media (max-width: ${size.large}) {
    margin-top: 5px;
    margin-bottom: 45px;
  }
`

export const CardContainer = styled.div`
  background-color: ${color.gray80};
  opacity: 0.92;
  padding-top: 52px;
  padding-bottom: 56px;
  margin-top: 70px;
`

export const CardContent = styled.div`
  max-width: 772px;
  margin: 0 auto;

  @media (max-width: ${size.large}) {
    margin: 0 30px;
  }
`
