import React, { FC } from 'react'
import { NavHashLink } from 'react-router-hash-link'
import { TableOfContentsStyles } from '../../Styles'
import { Endpoint } from '../types'

type TableOfContentsProps = {
  endpoints: Endpoint[]
}

const { Items, Item, Name, Path } = TableOfContentsStyles

const TableOfContents: FC<TableOfContentsProps> = ({ endpoints }: TableOfContentsProps) => (
  <Items>
    {endpoints.map((endpoint) => (
      <Item key={endpoint.operationId}>
        <Name>
          <NavHashLink smooth to={`#${endpoint.operationId}`}>
            {endpoint.summary}
          </NavHashLink>
        </Name>
        <Path>
          <strong>{endpoint.method}</strong> {endpoint.pattern}
        </Path>
      </Item>
    ))}
  </Items>
)

export default TableOfContents
