import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogOctober24 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        October 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        October release notes summarizes latest changes for Operational APIs.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Operational APIs</li>
          <UL>
            <li>
              It is now prerequisite to first create a webhook subscription before making REST calls to the
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path}> Equipment Status APIs</Link> and{' '}
              <Link to={Paths.ServiceInfoAPIV2Docs.path}> Service Info APIs</Link>.
            </li>
            <li>Improvement in Sandbox Rest response for Equipment Status and Service Info APIs.</li>
          </UL>
        </OL>
      </P>
    </>
  )
}
