import React from 'react'
import { H4, P, UL, OL } from '../../../../shared/components/Typography'
import Paths from '../../../Paths'
import { HashLink as Link } from 'react-router-hash-link'

export const ReleaseNotesLogJuly = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        July 2023
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        July release notes summarizes the new APIs released and bug fixes for the existing products.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Equipment Status API 2</li>
          <UL>
            <li>
              Three new APIs are now available inside Equipment Status 2 - Escalator Movement, Elevator Door Event and
              Elevator Button Event.
            </li>
            <li>These APIs can be used through REST as well as Webhooks.</li>
            <li style={{ fontWeight: 'bold' }}>Escalator Movement API: </li>
            <UL>
              <li style={{ fontWeight: 'bold' }}> Endpoints/Event Types for Escalator Movement API :</li>
              <ul style={{ fontWeight: 'bold' }}> Rest - POST /api/v2/equipment/escalator/movement/direction</ul>
              <ul style={{ fontWeight: 'bold' }}> Webhooks - Escalator Movement Type </ul>
              <li>
                Checkout latest{' '}
                <Link to={Paths.EquipmentStatus2RestApiPageDocs.path + '#getEscalatorMovementByIds'}>
                  Escalator Movement API REST{' '}
                </Link>{' '}
                and{' '}
                <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#escalator-movement'}>
                  Escalator Movement API Webhooks{' '}
                </Link>{' '}
                documentations to learn more about the newly added API.
              </li>
            </UL>
            <li style={{ fontWeight: 'bold' }}>Elevator Door API: </li>
            <UL>
              <li style={{ fontWeight: 'bold' }}> Endpoints/Event Types for Elevator Door API :</li>
              <ul style={{ fontWeight: 'bold' }}> Rest - POST /api/v2/equipment/elevator/door</ul>
              <ul style={{ fontWeight: 'bold' }}> Webhooks - Elevator Door Type </ul>
              <li>
                Checkout latest{' '}
                <Link to={Paths.EquipmentStatus2RestApiPageDocs.path + '#getElevatorDoorEventByIds'}>
                  Elevator Door API REST{' '}
                </Link>{' '}
                and{' '}
                <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#elevator-doorevent'}>
                  Elevator Door API Webhooks{' '}
                </Link>{' '}
                documentations to learn more about the newly added API.
              </li>
            </UL>
            <li style={{ fontWeight: 'bold' }}>Elevator Button API: </li>
            <UL>
              <li style={{ fontWeight: 'bold' }}> Endpoints/Event Types for Elevator Button API :</li>
              <ul style={{ fontWeight: 'bold' }}> Rest - POST /api/v2/equipment/elevator/button</ul>
              <ul style={{ fontWeight: 'bold' }}> Webhooks - Elevator Button Type </ul>
              <li>
                Checkout latest{' '}
                <Link to={Paths.EquipmentStatus2RestApiPageDocs.path + '#getElevatorButtonEventByIds'}>
                  Elevator Button API REST{' '}
                </Link>{' '}
                and{' '}
                <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#elevator-buttonevent'}>
                  Elevator Button API Webhooks{' '}
                </Link>{' '}
                documentations to learn more about the newly added API.
              </li>
            </UL>
            <li>
              Specversion description is now added to existing webhooks documentation for all Equipment Status 2
              products.
            </li>
          </UL>

          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>REST and Webhooks Documentations are updated for the new APIs.</li>
          </UL>
          <li style={{ marginTop: '30px' }}>
            {' '}
            No product level bug fixes applicable and no new feature release for Service Robot, Elevator Call and
            Service Info 2 APIs.
          </li>
        </OL>
      </P>
    </>
  )
}
