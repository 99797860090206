import styled from 'styled-components'
import { H2, P } from '../../shared/components'
import { color, size } from '../../shared/utils/styles'
import { Tabs } from 'antd'

export const DashboardTitle = styled(H2)`
  margin-bottom: 0.7em;
`

export const TilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  margin-bottom: 2.5em;
`

export const MessageContainer = styled.div`
  margin-top: 2em;
`

export const IntroText = styled(P)`
  max-width: 764px;
  margin-bottom: 2.5em;
`

export const ProductCards = styled.div`
  display: flex;
  flex-wrap: wrap;

  > a {
    height: 195px;
    flex: 0 0 250px;
  }
`

export const MfaAddExtraSecurityText = styled(P)`
  color: ${color.gray75};
  margin-top: 0;
`

export const ServiceBreakMessage = styled(P)`
  text-align: center;
`

export const StyledTabs = styled(Tabs)`
  width: 100%;
  color: ${color.gray20};

  .ant-tabs-tab {
    margin-right: 50px;
    margin-left: 50px;
    padding-bottom: 5px;
  }

  .ant-tabs-nav-list {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .ant-tabs-ink-bar {
    background: ${color.blueBase};
    height: 3px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${color.white} !important;
  }
  .ant-tabs-tab:hover {
    color: ${color.white};
  }
  .ant-tabs-tab-btn:active {
    color: ${color.gray10};
  }

  &.ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }

  &.ant-tabs-nav-operations-hidden {
    display: none;
  }
`

export const TabsContainer = styled.div`
  margin-top: -122px;
`

const WebhookAuthFormWrapper = styled.div`
  width: 500px;
  margin: 0 auto;

  .ant-form-item {
    margin-bottom: 30px;

    @media (max-width: ${size.medium}) {
      margin-bottom: 15px;
    }
  }
`
