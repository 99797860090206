import React, { FC } from 'react'
import {
  BuildingTerminologySvg,
  EquipmentTerminologySvg,
  UseCaseSvg,
  DoubleDeckSvg,
  TransferFloorSvg,
  MediaIdSvg,
  HeadingText,
  HeadingBellowText,
  CardColumnInnerSection,
  HeadingSection,
  CardColumnSection,
  KnowMoreButton,
  ContentHeading,
  SpecialCallSvg,
  PopUpContainer,
  PopUpTextContainer,
  EventsSvg,
  TierSvg,
} from './Styles'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../Paths'
import Modal from '../../../shared/components/ModalPopUp'
import { H3, P, UL } from '../../../shared/components/Typography'
import { Row } from 'antd'

const Glossary: FC = () => {
  const [openEvents, setOpenEvents] = React.useState(false)
  const [openBuildingTeminology, setOpenBuildingTeminology] = React.useState(false)
  const [openEquipmentTerminology, setOpenEquipmentTerminology] = React.useState(false)
  const [openApiCallTerminology, setOpenApiCallTerminology] = React.useState(false)
  const [openTerminologyToApi, setOpenTerminologyToApi] = React.useState(false)
  const [openDoubleDeck, setOpenDoubleDeck] = React.useState(false)
  const [openFlowElevator, setOpenFlowElevator] = React.useState(false)
  const [openSpecialCall, setOpenSpecialCall] = React.useState(false)
  const [openTier, setOpenTier] = React.useState(false)

  const openEventsPopUp = () => {
    setOpenEvents(true)
  }
  const closeEventssPopUp = () => {
    setOpenEvents(false)
  }
  const openBuildingTeminologyPopUp = () => {
    setOpenBuildingTeminology(true)
  }
  const closeBuildingTeminologyPopUp = () => {
    setOpenBuildingTeminology(false)
  }

  const openEquipmentTerminologyPopUp = () => {
    setOpenEquipmentTerminology(true)
  }
  const closeEquipmentTerminologyPopUp = () => {
    setOpenEquipmentTerminology(false)
  }

  const openApiCallTerminologyPopUp = () => {
    setOpenApiCallTerminology(true)
  }
  const closeApiCallTerminologyPopUp = () => {
    setOpenApiCallTerminology(false)
  }

  const openTerminologyToApiPopUp = () => {
    setOpenTerminologyToApi(true)
  }
  const closeTerminologyToApiPopUp = () => {
    setOpenTerminologyToApi(false)
  }

  const openDoubleDeckPopUp = () => {
    setOpenDoubleDeck(true)
  }
  const closeDoubleDeckPopUp = () => {
    setOpenDoubleDeck(false)
  }

  const openFlowElevatorPopUp = () => {
    setOpenFlowElevator(true)
  }
  const closeFlowElevatorPopUp = () => {
    setOpenFlowElevator(false)
  }

  const openSpecialCallPopUp = () => {
    setOpenSpecialCall(true)
  }
  const closeSpecialCallPopUp = () => {
    setOpenSpecialCall(false)
  }
  const openTierPopUp = () => {
    setOpenTier(true)
  }
  const closeTierPopUp = () => {
    setOpenTier(false)
  }
  return (
    <>
      <HeadingSection>
        <HeadingText>Glossary</HeadingText>
        <HeadingBellowText>
          {`The following section will introduce the terminology and concepts used regarding KONE API's you will learn
          more about buildings, areas, floors and elevators(sometimes referred to as lifts)`}
        </HeadingBellowText>
      </HeadingSection>
      <Row>
        <CardColumnSection xs={2} sm={4} md={6} lg={8} xl={8}>
          <CardColumnInnerSection>
            <EquipmentTerminologySvg />
            <ContentHeading>Equipment terminology</ContentHeading>
            <KnowMoreButton onClick={openEquipmentTerminologyPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardColumnInnerSection>
        </CardColumnSection>
        <CardColumnSection xs={2} sm={4} md={6} lg={8} xl={8}>
          <CardColumnInnerSection>
            <BuildingTerminologySvg />
            <ContentHeading>Building terminology</ContentHeading>
            <KnowMoreButton onClick={openBuildingTeminologyPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardColumnInnerSection>
        </CardColumnSection>
        <CardColumnSection xs={2} sm={4} md={6} lg={8} xl={8}>
          <CardColumnInnerSection>
            <TransferFloorSvg />
            <ContentHeading>Transfer-Flow Elevator</ContentHeading>
            <KnowMoreButton onClick={openFlowElevatorPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardColumnInnerSection>
        </CardColumnSection>
      </Row>
      <Row>
        <CardColumnSection xs={2} sm={4} md={6} lg={8} xl={8}>
          <CardColumnInnerSection>
            <DoubleDeckSvg />
            <ContentHeading>Double-Deck Elevator</ContentHeading>
            <KnowMoreButton onClick={openDoubleDeckPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardColumnInnerSection>
        </CardColumnSection>
        <CardColumnSection xs={2} sm={4} md={6} lg={8} xl={8}>
          <CardColumnInnerSection>
            <MediaIdSvg />
            <ContentHeading>API calls terminology</ContentHeading>
            <KnowMoreButton onClick={openApiCallTerminologyPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardColumnInnerSection>
        </CardColumnSection>
        <CardColumnSection xs={2} sm={4} md={6} lg={8} xl={8}>
          <CardColumnInnerSection>
            <SpecialCallSvg />
            <ContentHeading>Special calls</ContentHeading>
            <KnowMoreButton onClick={openSpecialCallPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardColumnInnerSection>
        </CardColumnSection>
      </Row>
      <Row>
        <CardColumnSection xs={2} sm={4} md={6} lg={8} xl={8}>
          <CardColumnInnerSection>
            <UseCaseSvg />
            <ContentHeading>Terminology applied to APIs</ContentHeading>
            <KnowMoreButton onClick={openTerminologyToApiPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardColumnInnerSection>
        </CardColumnSection>
        <CardColumnSection xs={2} sm={4} md={6} lg={8} xl={8}>
          <CardColumnInnerSection>
            <EventsSvg />
            <ContentHeading>Events</ContentHeading>
            <KnowMoreButton onClick={openEventsPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardColumnInnerSection>
        </CardColumnSection>
        <CardColumnSection xs={2} sm={4} md={6} lg={8} xl={8}>
          <CardColumnInnerSection>
            <TierSvg />
            <ContentHeading> REST Tier</ContentHeading>
            <KnowMoreButton onClick={openTierPopUp} type="primary">
              Know More
            </KnowMoreButton>
          </CardColumnInnerSection>
        </CardColumnSection>
      </Row>
      <Modal key="events" title={'Events'} isOpen={openEvents} onClose={closeEventssPopUp}>
        <PopUpContainer>
          <EventsSvg />
          <PopUpTextContainer>
            <P size="small">
              Events provide a capability to be notified when something interesting happens with your equipment. Events
              are delivered using the webhook mechanism.
            </P>
            <P size="small">
              Events are categorized by type. When you subscribe to receive events, you must select which types of
              events you are interested in and to which endpoint we should deliver them. Note that you don&apos;t need
              to specify the equipment you are interested in. You will receive events from all equipment available to
              you, and you will automatically receive events about new equipment added to your account.
            </P>
            <P size="small">
              Events are part of APIs. You can find the event types associated with an API from the API page, for
              example, <Link to={Paths.EquipmentStatusAPIProduct.path}>Real-time Equipment API</Link>. You can only
              receive events that are associated with an API that has been enabled in your account.
            </P>
            <P size="small">
              To receive the events, you must first create the application to receive the webhook calls or add the
              required endpoint to your existing application. The endpoint you must implement is documented at Webhook
              Callback API. The webhooks are considered backend-to-backend integration mechanisms, and the endpoint you
              create must be accessible from the public internet using the specified address. Make sure to implement
              also the authentication part of the{' '}
              <Link to={Paths.WebhookCallbackAPIDocs.path}>Webhook Callback API</Link> to guard against malicious 3rd
              parties trying to send fake messages to your endpoint. We also recommended that you create the endpoint in
              a way that enables you to monitor the calls that it receives, for example, by logging them.
            </P>
            <P size="small">
              After you have implemented the endpoint, you can test it out in the{' '}
              <Link to={Paths.SandboxApplication.path}>sandbox</Link>. You can create the webhook subscription either
              from the Dashboard under the selected application or by using the{' '}
              <Link to={Paths.WebhookManagementAPIDocs.path}>Webhook Management API</Link>. The creation of a webhook
              subscription triggers the test event to your endpoint. You can use the Portal or the API to trigger
              sending <Link to={`${Paths.WebhookManagementAPIDocs.path}#triggerTestEvent`}>test events</Link> if you
              need to test things in your endpoint implementation. When you are happy with your endpoint&apos;s
              technical implementation, you can subscribe to the actual events in the sandbox and start creating your
              application based on those.
            </P>
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="building-terminology"
        title={'Building Terminology'}
        isOpen={openBuildingTeminology}
        onClose={closeBuildingTeminologyPopUp}
      >
        <PopUpContainer>
          <BuildingTerminologySvg />
          <PopUpTextContainer>
            <P size="small">
              Buildings are the highest level of abstraction and can contain different elements: <br />
              <br />
              <b>Area</b>: the actual area that contains one or multiple elevators, such as a lobby. A lobby may contain
              many elevators, but not all elevators necessarily share the same area. For example, elevators can be
              located on different sides of a building. An area is identified with an areaId. <br />
              <br />
              It{"'"}s possible to make two different elevator calls:
              <br />
              <b>Destination call</b>: requests the elevator to take the passenger from a specific source area to a
              specific destination area.
              <br />
              <b>Landing call</b>: requests the elevator to go to specific source area without a set destination. This
              call is similar than when making an elevator call by pressing a button located in a source area.
              <br />
              <br />
              <b>SourceId</b>: area from where the elevator ride begins <br />
              <br />
              <b>DestinationId</b>: area of destination for an elevator call <br />
              <br />
              <b>Direction</b>: direction of the elevator ride from the source area <br />
              <br />
            </P>
            <H3>Area vs Floor</H3>
            <P size="small">
              The technical concept area and the material concept floor are strongly linked. In the illustration on the
              right, there are two distinct areas and both areas are located on the same floor. Consider a building user
              who makes an elevator call.
            </P>
            <P size="small">
              The usage of each distinct area could be:
              <br />
              <b>Green area</b>: marked as sourceId, this area is where the passenger will onboard the elevator
              <br></br>
              <b>Pink area</b>: marked as destinationId, this area is where the passenger will exit the elevator
              <br></br>
              <b>Yellow area</b>: not used for this specific elevator call
            </P>
            <P size="small">
              Notice that there are no references to specific floors when utilizing the Elevator Call functionality.
              Buildings may have multiple elevators on the same floor, which is why floors cannot be used as the
              identifiers for elevator movement. Areas are used as guide for the elevator journey. The concept of an
              area allows for the possibility to target elevators in a precise location. When designing your
              application, you can utilize area short names and level information to display physical floors.
            </P>
            <P size="small">
              The following key points are very important:
              <UL size="small">
                <li>Elevators that belong to an area may or may not be part of the same elevator group</li>
                <li>
                  AreaIds are not in any way bound to the concept of floor, even if the areaId contains a digit that
                  matches the floor number. This is not and must NOT be considered as a reliable way of identifying the
                  location of an area in a building.
                </li>
              </UL>
            </P>
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="equipment-terminology"
        title={'Equipment Terminology'}
        isOpen={openEquipmentTerminology}
        onClose={closeEquipmentTerminologyPopUp}
      >
        <PopUpContainer>
          <EquipmentTerminologySvg />
          <PopUpTextContainer>
            <P size="small">
              To correctly use KONE APIs, familiarize yourself with the following terms:
              <br />
              <br />
              <b>KEN</b>: KONE equipment number, equipment’s unique identifier
              <br />
              <br />
              <b>Lift</b>: an elevator that is composed of Shaft and Deck, identified by liftId
              <br />
              <br />
              <b>Shaft</b>: the place in which the deck moves, can have multiple sides
              <br />
              <br />
              <b>Deck</b>: elevator car, the moving part of the elevator, identified by the deckId
              <br />
              <br />
              <b>Lift group</b>: a set of elevators, identified by the groupId
            </P>
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="api-calls-terminology"
        title={'API calls terminology'}
        isOpen={openApiCallTerminology}
        onClose={closeApiCallTerminologyPopUp}
      >
        <PopUpContainer>
          <MediaIdSvg />
          <PopUpTextContainer>
            <P size="small">
              The following explanations help understand some of the central concepts of the elevator journey:
              <br />
              <br />
              <b>MonitorEvents</b>: The passenger and API application can monitor the status of the made elevator call
              and assigned elevator with three different events:
              <UL size="small">
                <li>
                  <b>call</b>: describes the state of the placed elevator call
                </li>
                <li>
                  <b>deck</b>: describes the position of the deck during the elevator journey
                </li>
                <li>
                  <b>door</b>: describes the door state, either open/opening/closed/closing
                </li>
              </UL>
              <br />
              <br />
            </P>
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="terminology-applied-to-apis"
        title={'Terminology applied to APIs'}
        isOpen={openTerminologyToApi}
        onClose={closeTerminologyToApiPopUp}
      >
        <PopUpContainer>
          <UseCaseSvg />
          <PopUpTextContainer>
            <P size="small">
              <b>Below is an example of a destination call.</b>
              <br />
              <br />
              Hotel Hissi, which has the buildingId:99900009301, has an application in use that is connected through
              Elevator Call API to the building’s elevators. A hotel visitor wants to move from the ground floor to the
              fourth floor and calls the elevator using the application. As explained above, KONE APIs do not use floor
              numbers as location identifiers. Therefore, the specific areas and areaIds correspond to the ground and
              fourth floors. In this case, the areaIds are area:1000 and area:3100. <br />
              <br />
              As the call is made, the deck starts moving inside of the shaft towards the sourceId, in this case is
              area:1000. The status of the call, deck and doors change following the events.
              <br />
              <br />
              Once the deck arrives at the sourceId, the door state changes first to {'"opening"'} and then {'"open"'}.
              <br />
              <br />
              <b>Below is an example of a landing call.</b>
              <br />
              <br />
              Hotel Hissi also has connected building elevators through Elevator Call API to the building{"'"}s access
              management system. An automated landing call conveniently brings an elevator to the lobby whenever an
              employee enters the building using their access card. With a landing call the elevator can be called to a
              specific area without knowing the destination. Therefore, every time an employee accesses the building,
              the elevator will be ready, waiting for the onboarding.
            </P>
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="double-deck-elevator"
        title={'Double-Deck Elevator'}
        isOpen={openDoubleDeck}
        onClose={closeDoubleDeckPopUp}
      >
        <PopUpContainer>
          <DoubleDeckSvg />
          <PopUpTextContainer>
            <P size="small">
              Double-deck lift is an elevator where one lift deck is stacked on top of another. This allows passengers
              on two consecutive floors to be able to use the elevator simultaneously, significantly increasing the
              passenger capacity of an elevator shaft and improve efficiency in buildings. For instance, a passenger may
              board the lower deck which serves only odd-numbered floors at basement level while another passenger may
              board the upper deck which serves even-numbered floors on the ground floor—the lift deck serving even
              floors is on top of the lift deck serving odd floors.
            </P>
            <H3>Multi group</H3>
            <P size="small">
              {' '}
              A group contains one or more lifts operating together. Similarly, multiple groups can be part of a single
              building and each group has an id which can be used to make elevator calls. API clients can fetch only one
              group configuration (lifts, decks, floors, areas etc) at a time. Configuration of individual lifts are
              maintained at group level and can be further modified to allow or disallow specific call actions.
            </P>
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="transfer-flow-elevator"
        title={'Transfer-Flow Elevator'}
        isOpen={openFlowElevator}
        onClose={closeFlowElevatorPopUp}
      >
        <PopUpContainer>
          <TransferFloorSvg />
          <PopUpTextContainer>
            <P size="small">
              In some buildings, all elevators under a single group may not serve all the available floors. For e.g One
              set of elevators can be configured to serve the lowest floors and another set of elevators to serve top
              most floors. A transfer floor is a common floor for the users to change from one lift to another lift to
              reach the desired destinations. The picture above shows an example of such a scenario, where there are two
              two lifts A and B. Lift A always serves floors 1, 2 and lift B serves only floors 2, 3. So users trying to
              reach floor 3 from floor 1 has to change the lift in floor 2 which is the transfer floor in this case.{' '}
              <br /> If a destination call is made with a source and destination floor and if no single lift serves both
              the floors, then the lift which can serve to source floor is allocated first and destination will be
              automatically modified by the system to the common floor. The modified destination is notified via
              websocket events.
            </P>
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal key="special-calls" title={'Special calls'} isOpen={openSpecialCall} onClose={closeSpecialCallPopUp}>
        <PopUpContainer>
          <SpecialCallSvg />
          <PopUpTextContainer>
            <P size="small">
              With elevator call API, a simple use case is to make a landing call or a destination call specifying
              source and destination floor. From version 2, many other call types are possible for various use cases.
              API clients can fetch the list of calls types that are enabled at the site and make use of below mentioned
              special features.
              <br />
              <br />
              <b>Cloud Api Calls</b>
              <br />
              <UL size="small" style={{ marginLeft: '-24px' }}>
                <b>Normal Calls : - </b>
                <br />
                <br />
                <li>
                  <b>Destination Call (DcsCall)</b>
                  <br />
                  Call allocation happens based on destination floor. Users can choose their destination floor before
                  hand. Once the allocated lift reaches source floor, indication inside the lift car shows the
                  destination floor. Since the users destination is known before, lift allocation is more efficient and
                  best possible lift is allocated.
                </li>
                <br />
                <li>
                  <b>Landing Call UP (LdgCall UP)</b>
                  <br />
                  When an up landing call is registered to a source floor, an elevator will be allocated which is
                  traveling upwards. Once the car is arrived, user can make a call inside manually to reach to the
                  destination.
                </li>
                <br />
                <li>
                  <b>Landing Call DOWN (LdgCall DOWN)</b>
                  <br />
                  When a down landing call is registered to a source floor, an elevator will be allocated which is
                  traveling downwards. Once the car is arrived, user can make a call inside manually to reach to the
                  destination.
                </li>
                <br />
                <li>
                  <b>Car Call (CarCall)</b>
                  <br />A known car can be requested to the source floor with this call type. Once the car is arrived,
                  user can make a call inside manually to reach to the destination.
                </li>
                <br />

                <hr />
                <br />
                <b>Special Calls : - </b>
                <br />
                <br />
                <li>
                  <b>Destination Handicap Call (DcsCall HAN D)</b>
                  <br />
                  Certain elevator(s) can be defined for handicap use at the site. When the API users makes this type of
                  call, the system allocates handicap call to one of the defined handicap elevators and reserves extra
                  space from the car. Handicap functionality will be activated at floor and the system provides audible
                  guidance to the allocated elevator. Also the voice announcers in car are activated and operative
                  during the travel of handicapped person.
                </li>
                <br />
                <li>
                  <b>Destination Priority Call (DcsCall PRL D)</b>
                  <br />
                  Elevator call can be placed on priority with this call type. Elevator serves the priority call as soon
                  possible when there is room in the car for the prioritized passenger(s), but serves first the calls
                  that that are on the way to destination.
                </li>
                <br />
                <li>
                  <b>Destination Priority Call Empty car (DcsCall PRL E)</b>
                  <br />
                  With this call type an empty car can be requested for priority use cases. The allocated lift will
                  first serves all the calls that are already registered and do not accept new calls. Once the car is
                  empty, it comes to the source floor user has requested and user can use it for one trip.
                </li>
                <br />
                <li>
                  <b>Extra Space Reservation Call (DcsCall PRL S)</b>
                  <br />
                  This Option can be used to call an elevator and reserve extra space from the car. The required space
                  is in the control system (full car, half car).
                </li>
                <br />
                <li>
                  <b>Hospital Service at Landings (LdgCall HEL)</b>
                  <br />
                  Hospital service calls has highest priority for emergency use cases. When this call type is used, the
                  first available priority service car is allocated to the call. If the elevator is traveling towards
                  the the priority call, it will ignore all landing and car calls and only stop when reaching the
                  priority floor. If the elevator is traveling in the opposite direction, it will stop at the nearest
                  possible landing, reverse its direction without opening its doors and travel non-stop to the required
                  floor by-passing all other landing and car calls in the system.
                </li>
                <br />
                <li>
                  <b>Priority Call at Landings, High Priority (LdgCall PRL H)</b>
                  <br />
                  When a priority call is registered, the closest available priority service car is allocated to the
                  call if the elevator is traveling towards the priority call, it will ignore all landing and car calls
                  and only stop when reaching the priority floor; if the elevator is traveling into the opposite
                  direction, it will stop at the nearest possible landing and it will open the doors, if allowed. A
                  buzzer in the car will indicate to the passengers to leave the car. After closing its doors, the
                  elevator reverses its direction, starts non-stop travel to the required floor and shuts off the
                  buzzer.
                </li>
                <br />
                <li>
                  <b>Priority Call at Landings, Low Priority (LdgCall PRL L)</b>
                  <br />
                  When a priority call is registered, the first available priority service car is allocated to the call
                  The elevator will not serve any other landing calls and will not accept any new car calls but will
                  serve all existing car calls before traveling non-stop directly to the floor where the priority call
                  has been registered.
                  <br />
                </li>
                <br />
                <li>
                  <b>Landing Call Basement Floor Extension (LdgCall FEB)</b>
                  <br />
                  Some buildings exists with additional basement floors where only few lifts are served and other lifts
                  serves common floors. This type of landing call allocates the lifts that are configured to serve to
                  the additional floors.
                </li>
                <br />
                <li>
                  <b>Landing Call Top Floor Extension (LdgCall FET)</b>
                  <br />
                  Some buildings exists with additional top floors where only few lifts are served and other lifts
                  serves common floors. This type of landing call allocates the lifts that are configured to serve the
                  additional floors.
                </li>
                <br />
              </UL>
            </P>
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal key="REST Tier" title={'REST Tier'} isOpen={openTier} onClose={closeTierPopUp}>
        <PopUpContainer>
          <TierSvg />
          <PopUpTextContainer>
            <P size="small">
              The REST API endpoints are used mainly to get authentication values, description and resource information.
              It can also be used to get equipment information either as primary source of such information or backup
              when primary source such as webhook is unavailable. Currently there are no limitations on how often the
              REST endpoints can be called either for authentication or equipment information. However, tier logic will
              be implemented to future version of the API product when the recommended primary means of equipment data
              retrieval is fully implemented across all products.{' '}
            </P>
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
    </>
  )
}

export default Glossary
