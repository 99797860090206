import { Checkbox, Form, Input } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import React, { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { FeedbackAPI } from '../../shared/api/feedback'
import { H2, H4, Toast } from '../../shared/components'
import { EMAIL_REG_EXP, REQUIRED_FIELD, KEN_MAX_LENGTH, BUILDING_MAX_LENGTH } from '../../shared/constants/auth'
import { useAuth } from '../../shared/contexts/authContext'
import { FeedbackData } from '../../shared/models/auth'
import {
  FeedbackAreas,
  FeedbackInformation,
  FeedbackPageTitles,
  FeedbackSuccessViewStrings,
  ApiInfo,
  MaxLenght,
} from '../../shared/strings/FeedbackContent'
import { PrivacyStatementStrings } from '../../shared/strings/PrivacyStatementContent'
import {
  PageTitleRow,
  ErrorMessage,
  FeedbackCheckbox,
  FeedbackForm,
  FormElement,
  InfoLabel,
  InputDescription,
  Label,
  MessageFormElement,
  PageDescription,
  SendButton,
  FormElementForBuilding,
  SelectItem,
  FeedbackSubtopicCheckbox,
} from './Styles'

const FeedbackSuccessView: FC = () => {
  return (
    <>
      <H2 id="thank-you-for-your-feedback-message-js">{FeedbackSuccessViewStrings.title}</H2>
      <H4>{FeedbackSuccessViewStrings.message}</H4>
    </>
  )
}

const Feedback: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const { currentUser } = useAuth()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm()
  const [sendFeedbackError, setSendFeedbackError] = useState<string | undefined>()
  const [isSendFeedbackErrorVisible, setIsSendFeedbackErrorVisible] = useState(false)
  const [isShowSuccessView, setIsShowSuccessView] = useState(false)
  const [checkedApis, setCheckedApis] = useState<any[]>([])
  const [checkedApplication, setCheckedApplication] = useState<any[]>([])
  const [showServiceRobot, setShowServiceRobot] = useState(false)
  const [showEquipmentStatus, setShowEquipmentStatus] = useState(false)
  const [showElevatorCall, setShowElevatorCall] = useState(false)
  const [showServiceInfo, setShowServiceInfo] = useState(false)
  useEffect(() => {
    register('feedbackAreas', { validate: feedbackAreasValidation })
  }, [])

  const handleMainTopicChange = (e: CheckboxChangeEvent) => {
    const mainAreaTitle = e.target.value
    let updatedCheckedItems = [...checkedItems]

    if (e.target.checked) {
      // Only add the main area title if it's not already included
      if (!updatedCheckedItems.includes(mainAreaTitle)) {
        updatedCheckedItems.push(mainAreaTitle)
      }
    } else {
      // Remove main area title and its subtopics from checked items
      updatedCheckedItems = updatedCheckedItems.filter((item) => !item.startsWith(mainAreaTitle))
    }

    // Update visibility based on updatedCheckedItems
    updateVisibility(updatedCheckedItems)

    setCheckedItems(updatedCheckedItems)
    setValue('feedbackAreas', updatedCheckedItems, { shouldValidate: true })
  }

  const handleChange = (areaTitle: string, subtopic: string) => {
    const selectedItem = `${areaTitle}-${subtopic}`
    const updatedCheckedItems = [...checkedItems]
    const subtopicIndex = updatedCheckedItems.indexOf(selectedItem)

    if (subtopicIndex !== -1) {
      // If subtopic is already checked, uncheck it
      updatedCheckedItems.splice(subtopicIndex, 1)
    } else {
      // If subtopic is not checked, check it
      updatedCheckedItems.push(selectedItem)
    }

    // Update visibility based on updatedCheckedItems
    updateVisibility(updatedCheckedItems)

    setCheckedItems(updatedCheckedItems)
    setValue('feedbackAreas', updatedCheckedItems, { shouldValidate: true })
  }

  const updateVisibility = (checkedItems: string[]) => {
    // Update visibility for experience APIs
    setShowElevatorCall(checkedItems.some((item) => item.endsWith('Elevator Call API')))
    setShowServiceRobot(checkedItems.some((item) => item.endsWith('Service Robot API')))

    // Update visibility for operational APIs
    setShowEquipmentStatus(checkedItems.some((item) => item.endsWith('Equipment Status API')))
    setShowServiceInfo(checkedItems.some((item) => item.endsWith('Service Info API')))
  }

  const handleApiTypeChange = (e: CheckboxChangeEvent) => {
    let updatedCheckedItems = []

    if (e.target.checked) {
      updatedCheckedItems = [...checkedApis, e.target.value]
    } else {
      updatedCheckedItems = checkedApis.filter((element) => element !== e.target.value)
    }

    setCheckedApis(updatedCheckedItems)
    setValue('apiTypes', updatedCheckedItems, { shouldValidate: true })
  }

  const handleApplicationTypeChange = (e: CheckboxChangeEvent) => {
    let updatedCheckedItems = []

    if (e.target.checked) {
      updatedCheckedItems = [...checkedApplication, e.target.value]
    } else {
      updatedCheckedItems = checkedApplication.filter((element) => element !== e.target.value)
    }

    setCheckedApplication(updatedCheckedItems)
    setValue('applicationTypes', updatedCheckedItems, { shouldValidate: true })
  }

  const feedbackAreasValidation = (feedbackAreas?: string[]) => {
    if (feedbackAreas && feedbackAreas.length > 0) {
      return true
    }
    return REQUIRED_FIELD
  }

  async function onSend(data: FeedbackData) {
    setIsSendFeedbackErrorVisible(false)
    setIsLoading(true)
    const feedbackAreas = checkedItems
    const message = data.message
    const name = data.name
    const email = data.email
    const company = data.company
    const elevatorCallBuilding = data.elevatorCallBuilding
    const serviceRobotBuilding = data.serviceRobotBuilding
    const equipmentStatusKen = data.equipmentStatusKen
    const serviceInfoKen = data.serviceInfoKen
    const apiType = checkedApis
    const applicationType = checkedApplication
    try {
      await FeedbackAPI.sendFeedback({
        feedbackAreas,
        message,
        name,
        email,
        company,
        elevatorCallBuilding,
        serviceRobotBuilding,
        equipmentStatusKen,
        serviceInfoKen,
        apiType,
        applicationType,
      })
      setIsLoading(false)
      setIsShowSuccessView(true)
    } catch (e) {
      setSendFeedbackError(`${FeedbackInformation.sendingFeedbackFailed} ${e?.response?.data?.message || e?.message}`)
      setIsSendFeedbackErrorVisible(true)
      setIsLoading(false)
    }
  }

  const feedbackAreasData = [
    {
      number: 1,
      title: FeedbackAreas.area1,
    },
    {
      number: 2,
      title: FeedbackAreas.area2,
      subtopics: [
        { id: '1', title: 'Application' },
        { id: '2', title: 'Resource' },
      ],
    },
    {
      number: 3,
      title: FeedbackAreas.area3,
      subtopics: [
        { id: '1', title: 'Getting Started with APIs' },
        { id: '2', title: 'Solution Creation' },
        { id: '3', title: 'Glossary' },
      ],
    },
    {
      number: 4,
      title: FeedbackAreas.area4,
      subtopics: [
        { id: '1', title: 'Elevator Call API' },
        { id: '2', title: 'Service Robot API' },
        { id: '3', title: 'Equipment Status API' },
        { id: '4', title: 'Service Info API' },
      ],
    },
    {
      number: 5,
      title: FeedbackAreas.area5,
    },
  ]

  if (isShowSuccessView) {
    return <FeedbackSuccessView />
  }

  return (
    <>
      <PageTitleRow>
        <H2>{FeedbackPageTitles.title}</H2>
        <Toast
          isVisible={isSendFeedbackErrorVisible}
          position="absolute"
          text={sendFeedbackError}
          onDismiss={() => setIsSendFeedbackErrorVisible(false)}
          type="error"
        />
      </PageTitleRow>
      <PageDescription>{FeedbackPageTitles.description}</PageDescription>
      <FeedbackForm>
        <form onSubmit={handleSubmit(onSend)}>
          <Form.Item>
            <Label>{FeedbackAreas.title}</Label>
            {feedbackAreasData.map((feedbackArea) => (
              <React.Fragment key={feedbackArea.number}>
                <div>
                  <FeedbackCheckbox
                    name={feedbackArea.title}
                    value={feedbackArea.title}
                    onChange={handleMainTopicChange}
                  >
                    {feedbackArea.title}
                  </FeedbackCheckbox>
                  {checkedItems.includes(feedbackArea.title) &&
                    feedbackArea.subtopics &&
                    feedbackArea.subtopics.length > 0 && (
                      <SelectItem>
                        {feedbackArea.subtopics.map((subtopic, index) => (
                          <div key={subtopic.id}>
                            <FeedbackSubtopicCheckbox
                              checked={checkedItems.includes(`${feedbackArea.title}-${subtopic.title}`)}
                              onChange={() => handleChange(feedbackArea.title, subtopic.title)}
                            >
                              {subtopic.title}
                            </FeedbackSubtopicCheckbox>

                            {/* Render the subcategory if Elevator Call API is Selected */}

                            {showElevatorCall && index === 0 && feedbackArea.title === FeedbackAreas.area4 && (
                              <SelectItem>
                                <Label htmlFor="feedback-application-name-input-js">
                                  {FeedbackInformation.selectApplication}
                                </Label>
                                <FeedbackSubtopicCheckbox value="sandbox" onChange={handleApplicationTypeChange}>
                                  {ApiInfo.sandbox}
                                </FeedbackSubtopicCheckbox>
                                <FeedbackSubtopicCheckbox value="production" onChange={handleApplicationTypeChange}>
                                  {ApiInfo.production}
                                </FeedbackSubtopicCheckbox>

                                <Label htmlFor="feedback-resource-name-input-js">
                                  {FeedbackInformation.addBuilding}
                                </Label>
                                <FormElementForBuilding>
                                  <Controller
                                    render={({ field }) => <Input id="building-for-elevator-call" {...field} />}
                                    name="elevatorCallBuilding"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: false,
                                      maxLength: {
                                        value: BUILDING_MAX_LENGTH,
                                        message: MaxLenght.buildingLength,
                                      },
                                    }}
                                  />
                                </FormElementForBuilding>
                                {errors.elevatorCallBuilding && (
                                  <ErrorMessage>
                                    {errors.elevatorCallBuilding.message || MaxLenght.buildingLength}
                                  </ErrorMessage>
                                )}
                              </SelectItem>
                            )}

                            {/* Render the subcategory if Service Robot API is Selected */}

                            {showServiceRobot && index === 1 && feedbackArea.title === FeedbackAreas.area4 && (
                              <SelectItem>
                                <Label htmlFor="feedback-application-name-input-js">
                                  {FeedbackInformation.selectApplication}
                                </Label>
                                <FeedbackSubtopicCheckbox value="sandbox" onChange={handleApplicationTypeChange}>
                                  {ApiInfo.sandbox}
                                </FeedbackSubtopicCheckbox>
                                <FeedbackSubtopicCheckbox value="production" onChange={handleApplicationTypeChange}>
                                  {ApiInfo.production}
                                </FeedbackSubtopicCheckbox>

                                <Label htmlFor="feedback-resource-name-input-js">
                                  {FeedbackInformation.addBuilding}
                                </Label>
                                <FormElementForBuilding>
                                  <Controller
                                    render={({ field }) => <Input id="building-for-service-robot" {...field} />}
                                    name="serviceRobotBuilding"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: false,
                                      maxLength: {
                                        value: BUILDING_MAX_LENGTH,
                                        message: MaxLenght.buildingLength,
                                      },
                                    }}
                                  />
                                </FormElementForBuilding>
                                {errors.serviceRobotBuilding && (
                                  <ErrorMessage>
                                    {errors.serviceRobotBuilding.message || MaxLenght.buildingLength}
                                  </ErrorMessage>
                                )}
                              </SelectItem>
                            )}

                            {/* Render the subcategory if Equipment Status API is Selected */}

                            {showEquipmentStatus && index === 2 && feedbackArea.title === FeedbackAreas.area4 && (
                              <SelectItem>
                                <Label htmlFor="feedback-api-name-input-js">{FeedbackInformation.selectApi}</Label>
                                <div>
                                  <FeedbackSubtopicCheckbox value="rest" onChange={handleApiTypeChange}>
                                    {ApiInfo.rest}
                                  </FeedbackSubtopicCheckbox>
                                  <FeedbackSubtopicCheckbox value="webhook" onChange={handleApiTypeChange}>
                                    {' '}
                                    {ApiInfo.webhook}
                                  </FeedbackSubtopicCheckbox>
                                </div>
                                <Label htmlFor="feedack-application-name-input-js">
                                  {FeedbackInformation.selectApplication}
                                </Label>
                                <FeedbackSubtopicCheckbox value="sandbox" onChange={handleApplicationTypeChange}>
                                  {ApiInfo.sandbox}
                                </FeedbackSubtopicCheckbox>
                                <FeedbackSubtopicCheckbox value="production" onChange={handleApplicationTypeChange}>
                                  {ApiInfo.production}
                                </FeedbackSubtopicCheckbox>

                                <Label htmlFor="feedback-resource-name-input-js">{FeedbackInformation.addKen}</Label>
                                <FormElementForBuilding>
                                  <Controller
                                    render={({ field }) => <Input id="ken-for-equipment-status" {...field} />}
                                    name="equipmentStatusKen"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: false,
                                      maxLength: {
                                        value: KEN_MAX_LENGTH,
                                        message: MaxLenght.kenLength,
                                      },
                                    }}
                                  />
                                </FormElementForBuilding>
                                {errors.equipmentStatusKen && (
                                  <ErrorMessage>
                                    {errors.equipmentStatusKen.message || MaxLenght.kenLength}
                                  </ErrorMessage>
                                )}
                              </SelectItem>
                            )}

                            {/* Render the subcategory if Service Info API is Selected */}

                            {showServiceInfo && index === 3 && feedbackArea.title === FeedbackAreas.area4 && (
                              <SelectItem>
                                <Label htmlFor="feedback-api-name-input-js">{FeedbackInformation.selectApi}</Label>
                                <div>
                                  <FeedbackSubtopicCheckbox value="rest" onChange={handleApiTypeChange}>
                                    {ApiInfo.rest}
                                  </FeedbackSubtopicCheckbox>
                                  <FeedbackSubtopicCheckbox value="webhook" onChange={handleApiTypeChange}>
                                    {' '}
                                    {ApiInfo.webhook}
                                  </FeedbackSubtopicCheckbox>
                                </div>
                                <Label htmlFor="feedback-application-name-input-js">
                                  {FeedbackInformation.selectApplication}
                                </Label>
                                <FeedbackSubtopicCheckbox value="sandbox" onChange={handleApplicationTypeChange}>
                                  {ApiInfo.sandbox}
                                </FeedbackSubtopicCheckbox>
                                <FeedbackSubtopicCheckbox value="production" onChange={handleApplicationTypeChange}>
                                  {ApiInfo.production}
                                </FeedbackSubtopicCheckbox>

                                <Label htmlFor="feedback-resource-name-input-js">{FeedbackInformation.addKen}</Label>
                                <FormElementForBuilding>
                                  <Controller
                                    render={({ field }) => <Input id="ken-for-service-info" {...field} />}
                                    name="serviceInfoKen"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: false,
                                      maxLength: {
                                        value: KEN_MAX_LENGTH,
                                        message: MaxLenght.kenLength,
                                      },
                                    }}
                                  />
                                </FormElementForBuilding>
                                {errors.serviceInfoKen && (
                                  <ErrorMessage>{errors.serviceInfoKen.message || MaxLenght.kenLength}</ErrorMessage>
                                )}
                              </SelectItem>
                            )}
                          </div>
                        ))}
                      </SelectItem>
                    )}
                </div>
              </React.Fragment>
            ))}
            {errors.feedbackAreas && (
              <ErrorMessage id="feedback-areas-input-error-js">
                {errors.feedbackAreas.message || REQUIRED_FIELD}
              </ErrorMessage>
            )}
          </Form.Item>
          <Form.Item>
            <Label htmlFor="feedback-message-input-js">{FeedbackInformation.message}</Label>
            <MessageFormElement>
              <Controller
                render={({ field }) => <InputDescription id="feedback-message-input-js" {...field} rows={6} />}
                name="message"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
              />
              {errors.message && <ErrorMessage id="message-input-error-js">{REQUIRED_FIELD}</ErrorMessage>}
            </MessageFormElement>
          </Form.Item>
          <Form.Item>
            <Label htmlFor="name-input-js">{FeedbackInformation.name}</Label>
            <FormElement>
              <Controller
                render={({ field }) => <Input id="name-input-js" {...field} />}
                name="name"
                control={control}
                defaultValue={`${currentUser?.firstName} ${currentUser?.lastName}`.trim()}
                rules={{
                  required: true,
                }}
              />
            </FormElement>
            {errors.name && <ErrorMessage id="name-input-error-js">{REQUIRED_FIELD}</ErrorMessage>}
          </Form.Item>
          <Form.Item>
            <Label htmlFor="email-input-js">{FeedbackInformation.email}</Label>
            <FormElement>
              <Controller
                render={({ field }) => <Input id="email-input-js" {...field} />}
                name="email"
                control={control}
                defaultValue={currentUser?.email}
                rules={{
                  pattern: {
                    value: EMAIL_REG_EXP,
                    message: FeedbackInformation.invalidEmailAddressError,
                  },
                  required: true,
                }}
              />
            </FormElement>
            {errors.email && (
              <ErrorMessage id="email-input-error-js">{errors.email.message || REQUIRED_FIELD}</ErrorMessage>
            )}
          </Form.Item>
          <Form.Item>
            <Label htmlFor="company-name-input-js">{FeedbackInformation.company}</Label>
            <FormElement>
              <Controller
                render={({ field }) => <Input id="company-name-input-js" {...field} />}
                name="company"
                control={control}
                defaultValue=""
                rules={{
                  required: false,
                }}
              />
            </FormElement>
          </Form.Item>
          <SendButton block id="send-feedback-button-js" type="primary" loading={isLoading} htmlType="submit">
            {FeedbackInformation.sendFormButton}
          </SendButton>
        </form>
        <InfoLabel>
          {PrivacyStatementStrings.pleaseSeePrivacyStatement}
          <Link to="/privacy-statement" target="_blank" rel="noreferrer">
            {PrivacyStatementStrings.privacyStatement}
          </Link>
        </InfoLabel>
      </FeedbackForm>
    </>
  )
}

export default Feedback
